import { PatientDetailsTypeRes } from "./Model";
export const PATIENT_DETAILS_TYPE_LIST_LOADING = "PATIENT_DETAILS_TYPE_LIST_LOADING";
export const PATIENT_DETAILS_TYPE_LIST_SUCCESS = "PATIENT_DETAILS_TYPE_LIST_SUCCESS";
export const PATIENT_DETAILS_TYPE_LIST_FAIL = "PATIENT_DETAILS_TYPE_LIST_FAIL";
export const PATIENT_DETAILS_TYPE_LIST_UPDATE_API_MSG =
  "PATIENT_DETAILS_TYPE_LIST_UPDATE_API_MSG";
export const PATIENT_DETAILS_TYPE_LIST_UPDATE_API_RES =
  "PATIENT_DETAILS_TYPE_LIST_UPDATE_API_RES";

export interface PatientDetailsTypeListState {
  patientDetailsTypeListRes: PatientDetailsTypeRes;
  loading: boolean;
  successMsg: string;
  errorMsg: string;
  status: number;
}
export interface PatientDetailsTypeListLoading {
  type: typeof PATIENT_DETAILS_TYPE_LIST_LOADING;
  loading: boolean;
}
export interface PatientDetailsTypeListSuccess {
  type: typeof PATIENT_DETAILS_TYPE_LIST_SUCCESS;
  payload: PatientDetailsTypeRes;
  successMsg: string;
}
export interface PatientDetailsTypeListFail {
  type: typeof PATIENT_DETAILS_TYPE_LIST_FAIL;
  payload: PatientDetailsTypeRes;
  errorMsg: string;
  status: number;
}
export interface PatientDetailsTypeListUpdateAPIMsg {
  type: typeof PATIENT_DETAILS_TYPE_LIST_UPDATE_API_MSG;
  payload: PatientDetailsTypeRes;
  successMsg: string;
  errorMsg: string;
  status: number;
}
export interface PatientDetailsTypeListUpdateAPIRes {
  type: typeof PATIENT_DETAILS_TYPE_LIST_UPDATE_API_RES;
  payload: PatientDetailsTypeRes;
  successMsg: string;
  errorMsg: string;
  status: number;
}

export type PatientDetailsTypeListActionTypes =
  | PatientDetailsTypeListLoading
  | PatientDetailsTypeListSuccess
  | PatientDetailsTypeListFail
  | PatientDetailsTypeListUpdateAPIMsg
  | PatientDetailsTypeListUpdateAPIRes;
