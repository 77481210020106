import { MEETING_LIST, sharedPatientDetailsActionTypes } from "./ActionTypes";
import { sharedPatientList } from "./Model";

export const getSharedPatientDetailsAction = (
  GetSharedPatientDetails: sharedPatientList,
): sharedPatientDetailsActionTypes => {
  return {
    type: MEETING_LIST,
    payload: GetSharedPatientDetails,
  };
};
