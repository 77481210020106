import * as React from "react";
import {
  Card,
  Grid,
  Typography,
  Avatar,
  CardActions,
  Button,
  CardHeader,
  CardContent,
  Stack,
} from "@mui/material";
import { useSelector } from "react-redux";
import { AppState } from "../../../redux/store/Store";
import dayjs from "dayjs";
import ArrowRightAltIcon from "@mui/icons-material/ArrowRightAlt";
import { useHistory, useParams } from "react-router-dom";
import { AgeRange, NameGenerate } from "../../../components/Utility";
function Demographics() {
  const history = useHistory();
  const patientDemographicsDetails = useSelector(
    (state: AppState) => state.getDemographicsDetailsByPtId
  );

  const handleDemographicToggle = () => {
    history.push(`/demographicdetails/${patientid}`);
  };

  const { patientid } = useParams() as {
    patientid: string;
  };
  return (
    <Card style={{ borderRadius: 20 }}>
      <CardHeader
        title={
          <Grid
            container
            direction="row"
            justifyContent="flex-start"
            alignItems="flex-start"
          >
            <Grid item>
              <Typography variant="h6" sx={{ fontWeight: "bold" }}>
                Demographics
              </Typography>
            </Grid>
          </Grid>
        }
      />
      <CardContent>
        <Grid
          container
          direction="row"
          justifyContent="space-between"
          alignItems="center"
        >
          <Grid item xs={12} sm={6} md={4} lg={4}>
            {patientDemographicsDetails?.getDemographicsDetailsByPtIdRes
              ?.personalDetails?.profilePicture?.document ? (
              localStorage.getItem("togglename") === "true" ? (
                <img
                  src={localStorage.getItem("image" + patientid)?.toString()}
                  srcSet={localStorage.getItem("image" + patientid)?.toString()}
                  alt=""
                  loading="lazy"
                  style={{
                    maxWidth: "180px",
                    maxHeight: "180px",
                    flex: 1,
                  }}
                />
              ) : (
                <img
                  src={
                    patientDemographicsDetails?.getDemographicsDetailsByPtIdRes
                      ?.personalDetails?.profilePicture?.document
                  }
                  srcSet={
                    patientDemographicsDetails?.getDemographicsDetailsByPtIdRes
                      ?.personalDetails?.profilePicture?.document
                  }
                  alt=""
                  loading="lazy"
                  style={{
                    maxWidth: "180px",
                    maxHeight: "180px",
                    flex: 1,
                  }}
                />
              )
            ) : (
              <Avatar sx={{ width: 120, height: 120 }} />
            )}
          </Grid>
          <Grid item xs={12} sm={6} md={8} lg={8}>
            <Stack
              direction="row"
              justifyContent="flex-start"
              alignItems="stretch"
              spacing={2}
            >
              <Stack
                direction="column"
                justifyContent="center"
                alignItems="flex-start"
                spacing={2}
              >
                <Typography>Name:</Typography>
                <Typography>Gender:</Typography>
                <Typography>Age:</Typography>
              </Stack>
              <Stack
                direction="column"
                justifyContent="center"
                alignItems="flex-start"
                spacing={1}
              >
                <Typography
                  variant="h5"
                  color="primary"
                  style={{ fontWeight: "bold" }}
                >
                  {NameGenerate.changeName(
                    patientid,
                    patientDemographicsDetails?.getDemographicsDetailsByPtIdRes
                      ?.personalDetails?.firstname! +
                      " " +
                      patientDemographicsDetails
                        ?.getDemographicsDetailsByPtIdRes?.personalDetails
                        ?.lastname!
                  )}
                </Typography>
                <Typography
                  variant="h5"
                  color="primary"
                  style={{ fontWeight: "bold" }}
                >
                  {typeof patientDemographicsDetails
                    ?.getDemographicsDetailsByPtIdRes?.personalDetails?.gender
                    // eslint-disable-next-line valid-typeof
                    ?.label !== undefined
                    ? " " +
                      patientDemographicsDetails.getDemographicsDetailsByPtIdRes
                        ?.personalDetails?.gender?.label +
                      " "
                    : null}
                </Typography>
                <Typography
                  variant="h5"
                  color="primary"
                  style={{ fontWeight: "bold" }}
                >
                  {patientDemographicsDetails?.getDemographicsDetailsByPtIdRes
                    ?.personalDetails?.dob !== null ||
                  patientDemographicsDetails?.getDemographicsDetailsByPtIdRes
                    ?.personalDetails?.dob !== ""
                    ? localStorage.getItem("togglename") === "true"
                      ? AgeRange.ageRangeCheck(
                          dayjs(new Date()).diff(
                            patientDemographicsDetails
                              ?.getDemographicsDetailsByPtIdRes?.personalDetails
                              ?.dob,
                            "year"
                          )
                        )
                      : dayjs(new Date()).diff(
                          patientDemographicsDetails
                            ?.getDemographicsDetailsByPtIdRes?.personalDetails
                            ?.dob,
                          "year"
                        ) + " years old "
                    : ""}
                </Typography>
              </Stack>
            </Stack>
          </Grid>
        </Grid>
      </CardContent>
      <CardActions>
        <Grid
          container
          direction="row"
          justifyContent="flex-end"
          alignItems="flex-end"
          spacing={1}
        >
          <Grid item>
            <Button
              variant="text"
              endIcon={<ArrowRightAltIcon />}
              sx={{ textTransform: "none" }}
              onClick={() => handleDemographicToggle()}
            >
              View All
            </Button>
          </Grid>
        </Grid>
      </CardActions>
    </Card>
  );
}

export default Demographics;
