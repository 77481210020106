import React from "react";
import { TabContext, TabList, TabPanel } from "@mui/lab";
import { Tab, Box } from "@mui/material";
import MWLoader from "../../components/MWLoader";
import { useSelector } from "react-redux";
import { AppState } from "../../redux/store/Store";
import PageLayout from "../../components/PageLayout";
import Payment from "./Payment";
import NewPayment from "./NewPayment";
import CareGiverPackageStatusMainTabDetails from "./CareGiverPackageStatusMainTabDetails";

export default function CareGiverPackageStatus() {
  const [selected, setSelected] = React.useState("1");
  const handleTabChange = (_event: React.SyntheticEvent, newValue: string) => {
    setSelected(newValue);
  };
  const loadingMarkup = <MWLoader />;
  const getUserDetailsList = useSelector((state: AppState) => state.getUserDetailsList);
  return (
    <Box>
      {getUserDetailsList.loading ? loadingMarkup : ""}
      <PageLayout>
        <TabContext value={String(selected)}>
          <Box sx={{ borderBottom: 1, borderColor: "divider" }}>
            <TabList onChange={handleTabChange}>
              <Tab label="Package Status" value="1" />
              <Tab label="Payment Status" value="2" />
              <Tab label="New Payment" value="3" />
            </TabList>
          </Box>
          <TabPanel value="1">{<CareGiverPackageStatusMainTabDetails />}</TabPanel>
          <TabPanel value="2">{<Payment />}</TabPanel>
          <TabPanel value="3">{<NewPayment />}</TabPanel>
        </TabContext>
      </PageLayout>
    </Box>
  );
}
