import { GetDoctorCategoryListRes } from "./Model";

export const GET_DOCTOR_CATEGORY_LIST_LOADING = "GET_DOCTOR_CATEGORY_LIST_LOADING";
export const GET_DOCTOR_CATEGORY_LIST_SUCCESS = "GET_DOCTOR_CATEGORY_LIST_SUCCESS";
export const GET_DOCTOR_CATEGORY_LIST_FAIL = "GET_DOCTOR_CATEGORY_LIST_FAIL";
export const GET_DOCTOR_CATEGORY_LIST_DATA = "GET_DOCTOR_CATEGORY_LIST_DATA";

export interface DoctorCategoryListDetails {
  getDoctorCategoryListRes: GetDoctorCategoryListRes;
  loading: boolean;
  errRes: string;
}
export interface GetDoctorCategoryListActionLoading {
  type: typeof GET_DOCTOR_CATEGORY_LIST_LOADING;
}
export interface GetDoctorCategoryListActionSuccess {
  type: typeof GET_DOCTOR_CATEGORY_LIST_SUCCESS;
  payload: GetDoctorCategoryListRes;
  errRes: string;
}
export interface GetDoctorCategoryListActionFail {
  type: typeof GET_DOCTOR_CATEGORY_LIST_FAIL;
  payload: GetDoctorCategoryListRes;
  errRes: string;
}
interface GetDoctorCategoryListActionAction {
  type: typeof GET_DOCTOR_CATEGORY_LIST_DATA;
  payload: GetDoctorCategoryListRes;
  errRes: string;
}

export type GetDoctorCategoryListActionTypes =
  | GetDoctorCategoryListActionAction
  | GetDoctorCategoryListActionLoading
  | GetDoctorCategoryListActionSuccess
  | GetDoctorCategoryListActionFail;
