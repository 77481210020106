import {
  doctorlistDetails,
  doctorlistActionTypes,
  DOCTOR_LIST_FAIL,
  DOCTOR_LIST_LOADING,
  DOCTOR_LIST_SUCCESS,
} from "./ActionTypes";
import { DoctorlistResponse } from "./Model";

const initialStateGetPosts: doctorlistDetails = {
  loading: false,
  doctorsList: {} as DoctorlistResponse,
  errRes: "",
};

export const doctorListReducer = (
  state = initialStateGetPosts,
  action: doctorlistActionTypes,
): doctorlistDetails => {
  switch (action.type) {
    case DOCTOR_LIST_SUCCESS:
      return {
        ...state,
        loading: false,
        doctorsList: action.payload,
        errRes: "",
      };
    case DOCTOR_LIST_LOADING:
      return {
        ...state,
        loading: true,
        doctorsList: {} as DoctorlistResponse,
      };
    case DOCTOR_LIST_FAIL:
      return {
        ...state,
        doctorsList: {} as DoctorlistResponse,
        loading: false,
        errRes: action.errRes,
      };
    default:
      return state;
  }
};
