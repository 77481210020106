import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import {
  Button,
  Card,
  CardContent,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Divider,
  Grid,
  IconButton,
  Stack,
  Typography,
} from "@mui/material";
import { DataGrid, GridCellParams, GridColDef, GridToolbar } from "@mui/x-data-grid";
import { toggleAppBarLoading } from "redux/appLoader/Actions";
import MWExceptionList from "../components/MWExceptionList";
import MWPageTitle from "../components/MWPageTitle";
import dayjs from "dayjs";
import { AppState } from "redux/store/Store";
import DeleteOutlineIcon from "@mui/icons-material/DeleteOutline";
import CloseIcon from "@mui/icons-material/Close";
import { getPatientListValue } from "redux/effects/ApiCall";

export default function DeleteAccountRequestList() {
  const dispatch = useDispatch();
  const PatientListFromAPI = useSelector((state: AppState) => state.patientValue);

  useEffect(() => {
   dispatch(getPatientListValue());
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [dispatch]);

  const [listCount, setListCount] = useState("");
  const [dataGridArray, setDataArray] = React.useState([] as any[]);

  useEffect(() => {
    dispatch(toggleAppBarLoading(PatientListFromAPI?.loading));
    if (
      PatientListFromAPI?.patientValue !== undefined
    ) {
      setListCount(String(PatientListFromAPI?.patientValue?.length));
      let dataPointArr =
          PatientListFromAPI?.patientValue.map(
          (element: any) => ({
            id: element.id,
            patientId: element.id,
            patientName:
            element.firstname + " " + element.lastname,
            phoneNumber: element.phone,
            pinCode: element.pincode,
            birthDate: dayjs(element.dob).format(
              "ddd MMM DD, YYYY hh:mm A",
            ),
          }),
        );
      setDataArray(dataPointArr);
    } else {
      setDataArray([] as any[]);
      setListCount("0");
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [PatientListFromAPI]);

  const [modalActive, setModalActive] = useState(false);
  const handleToggle = () => {
    setModalActive(false);
  };

  const openDialog = (id: string) => {
    console.log(id);
    setModalActive(true);
  };

  const deleteListReload = () => {
    dispatch(getPatientListValue())
  };

  const columns: GridColDef[] = [
    {
      field: "patientId",
      headerName: "Patient Id",
      minWidth: 200,
      align: "left",
    },

    {
      field: "patientName",
      headerName: "Patient Name",
      minWidth: 300,
      disableColumnMenu: true,
      align: "left",
    },

    {
      field: "birthDate",
      headerName: "Date of Birth",
      type: "string",
      minWidth: 250,
      align: "left",
      disableColumnMenu: true,
    },
    {
      field: "phoneNumber",
      headerName: "Phone Number",
      type: "string",
      minWidth: 250,
      align: "left",
      disableColumnMenu: true,
    },
    {
      field: "id",
      headerName: "Action",
      editable: true,
      minWidth: 200,
      align: "left",
      renderCell: (params: GridCellParams) => (
        <Button
          variant="outlined"
          startIcon={<DeleteOutlineIcon />}
          onClick={() => {
            openDialog(params.value);
          }}
          sx={{ textTransform: "none" }}
        >
          Delete Account
        </Button>
      ),
    },
  ];

  const patientPointDataTable = (
    <Card>
      <CardContent>
        {dataGridArray && dataGridArray.length ? (
          <DataGrid
            rows={dataGridArray}
            columns={columns}
            hideFooter
            hideFooterPagination
            disableSelectionOnClick
            experimentalFeatures={{ newEditingApi: true }}
            autoHeight
            components={{ Toolbar: GridToolbar }}
            componentsProps={{
              toolbar: {
                csvOptions: { disableToolbarButton: true },
                printOptions: { disableToolbarButton: true },
                showQuickFilter: true,
                quickFilterProps: { debounceMs: 250 },
              },
            }}
            disableColumnFilter
            disableColumnSelector
            disableDensitySelector
          />
        ) : (
          <MWExceptionList />
        )}
      </CardContent>
    </Card>
  );

  const deleteAccountSubmit = () => {
    //delete api call here
  };
  const deleteAccount = (
    <Dialog open={modalActive} onClose={handleToggle} fullWidth>
      <DialogTitle>
        <Grid container direction="row" justifyContent="space-between" alignItems="flex-start">
          <Grid item>
            <Typography variant="h6">Delete Account</Typography>
          </Grid>
          <Grid item>
            <IconButton onClick={handleToggle}>
              <CloseIcon />
            </IconButton>
          </Grid>
        </Grid>
      </DialogTitle>
      <Divider />
      <DialogContent>
        <Typography variant="h6">Are you sure, you want to delete this account</Typography>
      </DialogContent>
      <Divider />
      <DialogActions>
        <Button variant="contained" onClick={deleteAccountSubmit}>
          Yes
        </Button>
      </DialogActions>
    </Dialog>
  );

  return (
      <Stack direction="column" justifyContent="flex-start" alignItems="stretch" spacing={2} pt={2}>
        <Grid container direction="row" justifyContent="space-between" alignItems="flex-start">
          <Grid item>
            <MWPageTitle
              title="Delete Account Request List"
              enableCount={true}
              count={listCount}
              reload={true}
              reloadAction={deleteListReload}
            />
          </Grid>
        </Grid>
        {patientPointDataTable}
        {deleteAccount}
      </Stack>
  );
}

