import {
  PatientDropdownListDetails,
  PatientDropdownListActionTypes,
  PATIENT_LIST_DROPDOWN_SUCCESS,
  PATIENT_LIST_DROPDOWN_LOADING,
  PATIENT_LIST_DROPDOWN_FAIL,
} from "./ActionTypes";
import { PatientDropdownListValue } from "./Model";

const initialStateGetPosts: PatientDropdownListDetails = {
  loading: false,
  patientDropdownListRes: {} as PatientDropdownListValue,
};
export const patientDropdownListReducer = (
  state = initialStateGetPosts,
  action: PatientDropdownListActionTypes,
): PatientDropdownListDetails => {
  switch (action.type) {
    case PATIENT_LIST_DROPDOWN_SUCCESS:
      return {
        ...state,
        loading: false,
        patientDropdownListRes: action.payload,
      };
    case PATIENT_LIST_DROPDOWN_LOADING:
      return {
        ...state,
        loading: true,
        patientDropdownListRes: {} as PatientDropdownListValue,
      };
    case PATIENT_LIST_DROPDOWN_FAIL:
      return {
        ...state,
        patientDropdownListRes: {} as PatientDropdownListValue,
        loading: false,
      };
    default:
      return state;
  }
};
