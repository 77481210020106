import {
  AddFavoritePatientActionTypes,
  ADD_FAVORITE_PATIENT_FAIL,
  ADD_FAVORITE_PATIENT_LOADING,
  ADD_FAVORITE_PATIENT_SUCCESS,
  AddPatientPointDataState,
} from "./ActionTypes";
import { AddFavoritePatientDetails } from "./Model";

const initialStateGetPosts: AddPatientPointDataState = {
  loading: false,
  addFavoritePatientRes: {} as AddFavoritePatientDetails,
  message: "",
  status: 0,
};
export const addFavoritePatientReducer = (
  state = initialStateGetPosts,
  action: AddFavoritePatientActionTypes,
): AddPatientPointDataState => {
  switch (action.type) {
    case ADD_FAVORITE_PATIENT_LOADING:
      return {
        ...state,
        loading: true,
        addFavoritePatientRes: {} as AddFavoritePatientDetails,
      };
    case ADD_FAVORITE_PATIENT_SUCCESS:
      return {
        ...state,
        loading: false,
        addFavoritePatientRes: action.payload,
      };
    case ADD_FAVORITE_PATIENT_FAIL:
      return {
        ...state,
        loading: false,
        addFavoritePatientRes: {} as AddFavoritePatientDetails,
        message: action.message,
        status: action.status,
      };
    default:
      return state;
  }
};
