import {
  AddPatientImportConfigDetails,
  addPatientImportConfigActionTypes,
  ADD_PATIENT_IMPORT_CONFIG_FAIL,
  ADD_PATIENT_IMPORT_CONFIG_LOADING,
  ADD_PATIENT_IMPORT_CONFIG_SUCCESS,
} from "./ActionTypes";
import { AddPatientImportConfigValue } from "./Model";

const initialStateGetPosts: AddPatientImportConfigDetails = {
  loading: false,
  addPatientImportConfigRes: {} as AddPatientImportConfigValue,
  errRes: "",
};
export const addPatientImportConfigReducer = (
  state = initialStateGetPosts,
  action: addPatientImportConfigActionTypes,
): AddPatientImportConfigDetails => {
  switch (action.type) {
    case ADD_PATIENT_IMPORT_CONFIG_SUCCESS:
      return {
        ...state,
        loading: false,
        addPatientImportConfigRes: action.payload,
        errRes: "",
      };
    case ADD_PATIENT_IMPORT_CONFIG_LOADING:
      return {
        ...state,
        loading: true,
        addPatientImportConfigRes: {} as AddPatientImportConfigValue,
      };
    case ADD_PATIENT_IMPORT_CONFIG_FAIL:
      return {
        ...state,
        addPatientImportConfigRes: {} as AddPatientImportConfigValue,
        loading: false,
        errRes: action.errRes,
      };
    default:
      return state;
  }
};
