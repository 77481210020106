import {
  GET_EXTRACT_OBSERVATION_FAIL,
  GET_EXTRACT_OBSERVATION_LOADING,
  GET_EXTRACT_OBSERVATION_SUCCESS,
  GET_EXTRACT_OBSERVATION_API_MSG,
  GET_EXTRACT_OBSERVATION_API_RES,
  GetExtractObservationDetailsActionTypes,
} from "./ActionTypes";
import { GetExtractObservationDetailsRes } from "./Model";

export const getExtractObservationDetailsLoadingAction = (
  loading: boolean,
): GetExtractObservationDetailsActionTypes => {
  return {
    type: GET_EXTRACT_OBSERVATION_LOADING,
    loading: loading,
  };
};

export const getExtractObservationDetailsSuccessAction = (
  updateDoctorDetailsResponse: GetExtractObservationDetailsRes,
): GetExtractObservationDetailsActionTypes => {
  return {
    type: GET_EXTRACT_OBSERVATION_SUCCESS,
    payload: updateDoctorDetailsResponse,
  };
};

export const getExtractObservationDetailsErrorAction = (
  updateDoctorDetailsResponse: GetExtractObservationDetailsRes,
  errMsg: string,
  status: number,
): GetExtractObservationDetailsActionTypes => {
  return {
    type: GET_EXTRACT_OBSERVATION_FAIL,
    payload: updateDoctorDetailsResponse,
    errorMsg: errMsg,
    status: status,
  };
};
export const getExtractObservationDetailsAPIMsgAction = (
  updateDoctorDetailsResponse: GetExtractObservationDetailsRes,
  errMsg: string,
  status: number,
): GetExtractObservationDetailsActionTypes => {
  return {
    type: GET_EXTRACT_OBSERVATION_API_MSG,
    payload: updateDoctorDetailsResponse,
    errorMsg: errMsg,
    status: status,
  };
};

export const getExtractObservationDetailsAPIResClearAction = (
  updateDoctorDetailsResponse: GetExtractObservationDetailsRes,
  errMsg: string,
  status: number,
): GetExtractObservationDetailsActionTypes => {
  return {
    type: GET_EXTRACT_OBSERVATION_API_RES,
    payload: updateDoctorDetailsResponse,
    errorMsg: errMsg,
    status: status,
  };
};
