import {
  EDIT_DOCTOR_TEAM_TYPE_FAIL,
  EDIT_DOCTOR_TEAM_TYPE_LOADING,
  EDIT_DOCTOR_TEAM_TYPE_SUCCESS,
  EDIT_DOCTOR_TEAM_TYPE_UPDATE_API_MSG,
  EDIT_DOCTOR_TEAM_TYPE_UPDATE_API_RES,
  EditDoctorTeamTypeListActionTypes,
} from "./ActionTypes";
import { EditDoctorTeamTypeListRes } from "./Model";

export const editDoctorTeamTypeLoadingAction = (
  loading: boolean,
): EditDoctorTeamTypeListActionTypes => {
  return {
    type: EDIT_DOCTOR_TEAM_TYPE_LOADING,
    loading: loading,
  };
};

export const editDoctorTeamTypeSuccessAction = (
  editDoctorTeamTypeResponse: EditDoctorTeamTypeListRes,
  successMsg: string,
): EditDoctorTeamTypeListActionTypes => {
  return {
    type: EDIT_DOCTOR_TEAM_TYPE_SUCCESS,
    payload: editDoctorTeamTypeResponse,
    successMsg: successMsg,
  };
};

export const editDoctorTeamTypeErrorAction = (
  editDoctorTeamTypeResponse: EditDoctorTeamTypeListRes,
  errMsg: string,
  status: number,
): EditDoctorTeamTypeListActionTypes => {
  return {
    type: EDIT_DOCTOR_TEAM_TYPE_FAIL,
    payload: editDoctorTeamTypeResponse,
    errorMsg: errMsg,
    status: status,
  };
};
export const editDoctorTeamTypeUpdateAPIMsgAction = (
  editDoctorTeamTypeResponse: EditDoctorTeamTypeListRes,
  successMsg: string,
  errMsg: string,
  status: number,
): EditDoctorTeamTypeListActionTypes => {
  return {
    type: EDIT_DOCTOR_TEAM_TYPE_UPDATE_API_MSG,
    payload: editDoctorTeamTypeResponse,
    successMsg: successMsg,
    errorMsg: errMsg,
    status: status,
  };
};

export const editDoctorTeamTypeAPIResClearAction = (
  editDoctorTeamTypeResponse: EditDoctorTeamTypeListRes,
  successMsg: string,
  errMsg: string,
  status: number,
): EditDoctorTeamTypeListActionTypes => {
  return {
    type: EDIT_DOCTOR_TEAM_TYPE_UPDATE_API_RES,
    payload: editDoctorTeamTypeResponse,
    successMsg: successMsg,
    errorMsg: errMsg,
    status: status,
  };
};
