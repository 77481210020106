import {
  AddResponse,
  ChiefComplaintList,
  QuestionList,
  SetChiefComplaint,
  SetDeleteDocument,
  Steps,
} from "./Model";

export const QUESTION_LIST_LOADING = "QUESTION_LIST_LOADING";
export const QUESTION_LIST_SUCCESS = "QUESTION_LIST_SUCCESS";
export const QUESTION_LIST_FAIL = "QUESTION_LIST_FAIL";
export const GET_QUESTION_LIST = "GET_QUESTION_LIST";

export interface GetQuestionStateType {
  questionValue: QuestionList[];
  loading: boolean;
}
export interface PatientQuestionListLoading {
  type: typeof QUESTION_LIST_LOADING;
}
export interface PatientQuestionListSuccess {
  type: typeof QUESTION_LIST_SUCCESS;
  payload: QuestionList[];
}
export interface PatientQuestionListFail {
  type: typeof QUESTION_LIST_FAIL;
}

interface GetQuestionActionType {
  type: typeof GET_QUESTION_LIST;
  payload: QuestionList[];
}

export const CC_LIST_LOADING = "CC_LIST_LOADING";
export const CC_LIST_SUCCESS = "CC_LIST_SUCCESS";
export const CC_LIST_FAIL = "CC_LIST_FAIL";
export const GET_CC_LIST = "GET_CC_LIST";

export interface GetChiefComplaintStateType {
  ccValue: ChiefComplaintList[];
  loading: boolean;
}
export interface CCListLoading {
  type: typeof CC_LIST_LOADING;
}
export interface CCListSuccess {
  type: typeof CC_LIST_SUCCESS;
  payload: ChiefComplaintList[];
}
export interface CCListFail {
  type: typeof CC_LIST_FAIL;
}

interface GetCCActionType {
  type: typeof GET_CC_LIST;
  payload: ChiefComplaintList[];
}

export const CC_ADD_LIST_LOADING = "CC_ADD_LIST_LOADING";
export const CC_ADD_LIST_SUCCESS = "CC_ADD_LIST_SUCCESS";
export const CC_ADD_LIST_FAIL = "CC_ADD_LIST_FAIL";
export const ADD_CC = "ADD_CC";

export interface SetAddChiefComplaintStateType {
  addCC: SetChiefComplaint[];
  loading: boolean;
  response: AddResponse;
}
export interface CCAddLoading {
  type: typeof CC_ADD_LIST_LOADING;
}
export interface CCAddSuccess {
  type: typeof CC_ADD_LIST_SUCCESS;
  payload: AddResponse;
}
export interface CCAddFail {
  type: typeof CC_ADD_LIST_FAIL;
}

interface SetAddChiefComplaintType {
  type: typeof ADD_CC;
  payload: SetChiefComplaint[];
}

export const DELETE_DOCUMENT_LIST_LOADING = "DELETE_DOCUMENT_LIST_LOADING";
export const DELETE_DOCUMENT_LIST_SUCCESS = "DELETE_DOCUMENT_LIST_SUCCESS";
export const DELETE_DOCUMENT_LIST_FAIL = "DELETE_DOCUMENT_LIST_FAIL";
export const DELETE_DOCUMENT = "DELETE_DOCUMENT";

export interface SetDeleteDocumentStateType {
  deleteDoc: SetDeleteDocument[];
  loading: boolean;
  response: AddResponse;
}
export interface DeleteDocumentLoading {
  type: typeof DELETE_DOCUMENT_LIST_LOADING;
}
export interface DeleteDocumentSuccess {
  type: typeof DELETE_DOCUMENT_LIST_SUCCESS;
  payload: AddResponse;
}
export interface DeleteDocumentFail {
  type: typeof DELETE_DOCUMENT_LIST_FAIL;
}

interface SetDeleteDocumentType {
  type: typeof DELETE_DOCUMENT;
  payload: SetDeleteDocument[];
}

export const STEPS_LIST_LOADING = "STEPS_LIST_LOADING";
export const STEPS_LIST_SUCCESS = "STEPS_LIST_SUCCESS";
export const STEPS_LIST_FAIL = "STEPS_LIST_FAIL";
export const STEPS_LIST = "STEPS_LIST";

export interface GetStepStateType {
  stepValue: Steps[];
  loading: boolean;
}
export interface StepListLoading {
  type: typeof STEPS_LIST_LOADING;
}
export interface StepListSuccess {
  type: typeof STEPS_LIST_SUCCESS;
  payload: Steps[];
}
export interface StepListFail {
  type: typeof STEPS_LIST_FAIL;
}

interface GetStepActionType {
  type: typeof STEPS_LIST;
  payload: Steps[];
}

// delete patient case
export const DELETE_CASE_LOADING = "DELETE_CASE_LOADING";
export const DELETE_CASE_SUCCESS = "DELETE_CASE_SUCCESS";
export const DELETE_CASE_FAIL = "DELETE_CASE_FAIL";
export const DELETE_CASE_TYPE = "DELETE_CASE_TYPE";

export interface DeleteCaseStateType {
  loading: boolean;
  message: string;
  status: number;
}
export interface DeleteCaseLoading {
  type: typeof DELETE_CASE_LOADING;
}
export interface DeleteCaseSuccess {
  type: typeof DELETE_CASE_SUCCESS;
  message: string;
  status: number;
}
export interface DeleteCaseFail {
  type: typeof DELETE_CASE_FAIL;
  message: string;
  status: number;
}

interface DeleteCaseType {
  type: typeof DELETE_CASE_TYPE;
  message: string;
  status: number;
}

export type QuestionGetActionTypes =
  | GetQuestionActionType
  | PatientQuestionListLoading
  | PatientQuestionListSuccess
  | PatientQuestionListFail;
export type ChiefComplaintGetActionTypes =
  | GetCCActionType
  | CCListLoading
  | CCListSuccess
  | CCListFail;
export type AddChiefComplaintTypes =
  | SetAddChiefComplaintType
  | CCAddLoading
  | CCAddSuccess
  | CCAddFail
  | GetCCActionType
  | CCListLoading
  | CCListSuccess
  | CCListFail;
export type DeleteDocumentTypes =
  | SetDeleteDocumentType
  | DeleteDocumentLoading
  | DeleteDocumentSuccess
  | DeleteDocumentFail
  | GetCCActionType
  | CCListLoading
  | CCListSuccess
  | CCListFail;
export type StepsGetActionTypes =
  | GetStepActionType
  | StepListLoading
  | StepListSuccess
  | StepListFail;
export type DeleteCaseTypes =
  | DeleteCaseType
  | DeleteCaseLoading
  | DeleteCaseSuccess
  | DeleteCaseFail;
