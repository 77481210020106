import {
  DELETE_DOCTOR_FOR_CASE_FAIL,
  DELETE_DOCTOR_FOR_CASE_LOADING,
  DELETE_DOCTOR_FOR_CASE_SUCCESS,
  DELETE_DOCTOR_FOR_CASE_UPDATE_API_MSG,
  DELETE_DOCTOR_FOR_CASE_UPDATE_API_RES,
  DeleteDoctorForCaseActionTypes,
} from "./ActionTypes";
import { DeleteDoctorForCaseRes } from "./Model";

export const deleteDoctorForCaseLoadingAction = (
  loading: boolean,
): DeleteDoctorForCaseActionTypes => {
  return {
    type: DELETE_DOCTOR_FOR_CASE_LOADING,
    loading: loading,
  };
};

export const deleteDoctorForCaseSuccessAction = (
  deleteDoctorForCaseResponse: DeleteDoctorForCaseRes,
  successMsg: string,
): DeleteDoctorForCaseActionTypes => {
  return {
    type: DELETE_DOCTOR_FOR_CASE_SUCCESS,
    payload: deleteDoctorForCaseResponse,
    successMsg: successMsg,
  };
};

export const deleteDoctorForCaseErrorAction = (
  deleteDoctorForCaseResponse: DeleteDoctorForCaseRes,
  errMsg: string,
  status: number,
): DeleteDoctorForCaseActionTypes => {
  return {
    type: DELETE_DOCTOR_FOR_CASE_FAIL,
    payload: deleteDoctorForCaseResponse,
    errorMsg: errMsg,
    status: status,
  };
};
export const deleteDoctorForCaseUpdateAPIMsgAction = (
  deleteDoctorForCaseResponse: DeleteDoctorForCaseRes,
  successMsg: string,
  errMsg: string,
  status: number,
): DeleteDoctorForCaseActionTypes => {
  return {
    type: DELETE_DOCTOR_FOR_CASE_UPDATE_API_MSG,
    payload: deleteDoctorForCaseResponse,
    successMsg: successMsg,
    errorMsg: errMsg,
    status: status,
  };
};

export const deleteDoctorForCaseAPIResClearAction = (
  deleteDoctorForCaseResponse: DeleteDoctorForCaseRes,
  successMsg: string,
  errMsg: string,
  status: number,
): DeleteDoctorForCaseActionTypes => {
  return {
    type: DELETE_DOCTOR_FOR_CASE_UPDATE_API_RES,
    payload: deleteDoctorForCaseResponse,
    successMsg: successMsg,
    errorMsg: errMsg,
    status: status,
  };
};
