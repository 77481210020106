import {
  OpinionTypeListData,
  Message,
  Status,
  SecondOpinionListData,
  ConsultationTypeListData,
} from "./Model";
import {
  AddPatientCaseActionTypes,
  ADD_PATIENT_CASE_STATUS,
  AdminGetConsultationTypeListActionTypes,
  AdminGetOpinionTypeListActionTypes,
  AdminGetSecondOpinionListActionTypes,
  CONSULTATION_TYPE_LIST,
  EditPatientCaseActionTypes,
  EDIT_PATIENT_CASE_STATUS,
  OPINIONTYPELIST_LIST,
  PatientCaseDetailsActionTypes,
  PATIENT_CASE_DETAILS_STATUS,
  ResponseTimeActionTypes,
  RESPONSE_TIME_STATUS,
  SECOND_OPINION_LIST,
} from "./ActionTypes";

export const getOpinionTypeListAction = (
  opinionTypeListData: OpinionTypeListData[],
  message: Message,
  status: Status,
): AdminGetOpinionTypeListActionTypes => {
  return {
    type: OPINIONTYPELIST_LIST,
    payload: opinionTypeListData,
    message: message,
    status: status,
  };
};
// SecondOpinionListData..
export const secondOpinionListAction = (
  secondOpinionListData: SecondOpinionListData[],
  message: Message,
  status: Status,
): AdminGetSecondOpinionListActionTypes => {
  return {
    type: SECOND_OPINION_LIST,
    payload: secondOpinionListData,
    message: message,
    status: status,
  };
};
// for Get consultation type ..
export const consultationTypeListAction = (
  consultationTypeListData: ConsultationTypeListData[],
  message: Message,
  status: Status,
): AdminGetConsultationTypeListActionTypes => {
  return {
    type: CONSULTATION_TYPE_LIST,
    payload: consultationTypeListData,
    message: message,
    status: status,
  };
};
// for Response Time ..
export const responseTimeListAction = (ResponseTimeResponse: any): ResponseTimeActionTypes => {
  return {
    type: RESPONSE_TIME_STATUS,
    payload: ResponseTimeResponse,
  };
};
// Add patient case
export const AddPatientCase = (AddPatientCaseResponse: any): AddPatientCaseActionTypes => {
  return {
    type: ADD_PATIENT_CASE_STATUS,
    payload: AddPatientCaseResponse,
  };
};
// Edit patient case
export const EditPatientCase = (EditPatientCaseResponse: any): EditPatientCaseActionTypes => {
  return {
    type: EDIT_PATIENT_CASE_STATUS,
    payload: EditPatientCaseResponse,
  };
};
// Get patient case details
export const GetPatientCaseDetails = (
  PatientCaseDetailsResponse: any,
  Status: any,
): PatientCaseDetailsActionTypes => {
  return {
    type: PATIENT_CASE_DETAILS_STATUS,
    patientCaseDetail: PatientCaseDetailsResponse,
    status: Status,
  };
};
