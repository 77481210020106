import React, { useState, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { emailOtpGenerationApi } from "../../redux/emailOtpGeneration/API";
import { emailOtpGenerationDetails } from "../../redux/emailOtpGeneration/Model";
import { AppState } from "../../redux/store/Store";
import { Alert, Box, Button, Dialog, DialogActions, DialogContent, DialogTitle, Typography } from "@mui/material";
import LoadingButton from '@mui/lab/LoadingButton';
import { useTranslation } from "react-i18next";
import MWTextField from "../../components/MWTextField";
import { emailOtpAuthDetails } from "../../redux/emailOtpAuth/Model";
import { setEmailOrPhoneApi } from "../../redux/setEmailOrPhone/API";

interface Props {
    toggleEditEmail: () => void;
    isEditEmailOpen: boolean;
};

type EmailOTPRes = {
    msg?: string;
    status?: string;
    loading?: boolean;
};
const AddUserEmail = ({ toggleEditEmail, isEditEmailOpen }: Props) => {
    const { t } = useTranslation(["home"]);
    const dispatch = useDispatch();

    const [emailForOtp, setEmailForOtp] = useState("");
    const [msgOfEmailForOtp, setMsgOfEmailForOtp] = useState("Please provide email or phone number");
    const [emailForOtpStatus, setEmailForOtpStatus] = useState("Success");

    const [emailForOtpRes, setEmailForOtpRes] = useState<EmailOTPRes>({
        msg: "",
        status: "",
        loading: false
    });

    const [isDisableOTPField, setIsDisableOTPField] = useState(true);
    const [, setOtpStatus] = useState("");
    const [otpinvlid, setOtpInvalid] = useState("");
    const [buttonText, setButtonText] = useState(
        `${t("sendotp", { ns: ["home"] })}`
    );
    const [validotp, setvalidOtp] = useState("");
    const [errOtp, setErrOtpMsg] = useState("");
    const [, setOtpLoading] = React.useState(false);
    const [loginLoading, setloginLoading] = React.useState(false);

    // const errorOtpEmail = msgOfEmailForOtp ? (
    //     <Typography>{msgOfEmailForOtp}</Typography>
    // ) : (
    //     ""
    // );

    // const closeSuccessBanner = () => {
    //     // setEmailForOtpRes("");
    // };

    const closeErrorBanner = () => {
        setOtpInvalid("");
    };

    // const OTPText = emailForOtpRes.loading ? (
    //     <Alert
    //         variant="standard"
    //         severity={otpStatus === 'Failed' ? "error" : "success"}
    //         onClose={() => closeSuccessBanner()}
    //     >
    //         <p>{emailForOtpRes}</p>
    //     </Alert>
    // ) : (
    //     ""
    // );

    const errorOtp = errOtp ? <Typography>{errOtp}</Typography> : "";

    const wrongOtp = otpinvlid ? (
        <Alert
            variant="standard"
            severity="error"
            title={errOtp}
            onClose={() => closeErrorBanner()}
        >
            <p>{otpinvlid}</p>
        </Alert>
    ) : (
        ""
    );

    const emailotpbody = {
        email: emailForOtp.toLowerCase(),
    } as emailOtpGenerationDetails;

    const otpbody = {
        email: emailForOtp.toLowerCase(),
        otp: validotp,
    } as emailOtpAuthDetails;

    //handle button click of otp generation
    const handleLoginwithotp = () => {
        if (emailForOtp === "") {
            setMsgOfEmailForOtp(`${t("loginpage.erroremail", { ns: ["home"] })}`);
            setEmailForOtpStatus("Error")
            return;
        } else if (
            new RegExp(/[a-z0-9._%+-]+@[a-z0-9.-]+\.[a-z]{2,15}/g).test(
                emailForOtp.toLowerCase()
            ) === false &&
            new RegExp(/^[0]?[6789]\d{9}$/).test(emailForOtp.toLowerCase()) === false
        ) {
            setMsgOfEmailForOtp(`${t("loginpage.validemail", { ns: ["home"] })}`);
            setEmailForOtpStatus("Error")
        } else {
            setMsgOfEmailForOtp("");
            setEmailForOtpStatus("Sucess")
            dispatch(emailOtpGenerationApi(emailotpbody));
        }
    };
    const emailOtpGen = useSelector(
        (state: AppState) => state.emailOtpGeneration
    );

    const setEmailPhone = useSelector(
        (state: AppState) => state.setEmailPhone
    );

    //const emailOtpAuth = useSelector((state: AppState) => state.emailOtpAuth);

    useEffect(() => {
        emailOtpGen?.loading
            ? setOtpLoading(true)
            : setOtpLoading(false);
    }, [emailOtpGen]);

    useEffect(() => {
        console.log("setEmailPhone > ", setEmailPhone);
        setEmailPhone?.loading
            ? setloginLoading(true)
            : setloginLoading(false);
        if (setEmailPhone?.errRes === '') {
            setErrOtpMsg(setEmailPhone?.setEmailPhoneRes?.message);
            setOtpStatus("success");
            setIsDisableOTPField(false);
        } else {
            setErrOtpMsg(emailOtpGen.errRes);
            setOtpStatus("failed");
            setloginLoading(false);
            setButtonText(`${t("loginpage.sendotp", { ns: ["home"] })}`);
        }
           // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [setEmailPhone]);

    useEffect(() => {
        if (emailOtpGen?.emailOtpGenerationRes?.message !== undefined) {
            setEmailForOtpRes({
                ...emailForOtpRes,
                msg: emailOtpGen?.emailOtpGenerationRes?.message,
                status: "success",
                loading: emailOtpGen.loading
            })
            setIsDisableOTPField(false);
            setButtonText(`${t("loginpage.resendotp", { ns: ["home"] })}`);
        } else {
            setEmailForOtpRes({
                ...emailForOtpRes,
                msg: emailOtpGen?.errRes,
                status: "error",
                loading: emailOtpGen.loading
            })
            setButtonText(`${t("loginpage.sendotp", { ns: ["home"] })}`);
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [emailOtpGen]);

    // otp validation
    const handleLoginwithValidotp = () => {
        if (validotp !== "" && new RegExp(/^\d{6}$/gm).test(validotp.toLowerCase())) {
            dispatch(setEmailOrPhoneApi(otpbody));
        } else {
            // setEmailForOtpRes(`${t("loginpage.entervalidotp", { ns: ["home"] })}`);
            return;
        }
    };

    return (
        <Dialog open={isEditEmailOpen} onClose={toggleEditEmail} fullWidth maxWidth="sm">
            <DialogTitle>{t("userprofile.addemail", { ns: ["home"] })!}</DialogTitle>
            <DialogContent>
                <Box>
                    <MWTextField
                        fullWidth
                        sx={{ my: 2 }}
                        error={emailForOtpStatus === "Error" ? true : false}
                        value={emailForOtp}
                        label={t("loginpage.emailorphno", { ns: ["home"] })}
                        placeholder={t("loginpage.emailorphno", { ns: ["home"] })!}
                        id="emailFieldID"
                        onChange={(event: React.ChangeEvent<HTMLInputElement>) => {
                            setEmailForOtp(event.target.value);
                            setMsgOfEmailForOtp("Please provide email or phone number");
                            setEmailForOtpStatus("Success");
                        }}
                        size="small"
                        helperText={msgOfEmailForOtp}
                    />
                    <LoadingButton
                        fullWidth
                        variant="contained"
                        color="primary"
                        loading={emailForOtpRes.loading}
                        loadingIndicator="Loading…"
                        onClick={handleLoginwithotp}
                    >
                        {buttonText}
                    </LoadingButton>
                    {emailForOtpRes.msg && <Alert sx={{ mt: 2 }} severity={emailForOtpRes.status === "failed" ? "error" : "success"}>
                        {emailForOtpRes.msg}
                    </Alert>}
                    {/*............ for validate otp code .............. */}
                    <MWTextField
                        fullWidth
                        sx={{ my: 2 }}
                        value={validotp}
                        error={errorOtp === "" ? false : true}
                        label={t("loginpage.otp", { ns: ["home"] })}
                        placeholder={t("loginpage.enterotp", { ns: ["home"] })!}
                        id="OTPFieldID"
                        onChange={(event: React.ChangeEvent<HTMLInputElement>) => {
                            setvalidOtp(event.target.value);
                            // setEmailForOtpRes('');
                        }}
                        disabled={isDisableOTPField}
                        size="small"
                        helperText={errorOtp === "" ? "Please provide OTP" : errorOtp}
                    />
                </Box>
                <Box>{wrongOtp}</Box>
            </DialogContent>
            <DialogActions>
                <Button onClick={toggleEditEmail}>Cancel</Button>
                <LoadingButton
                    variant="contained"
                    color="primary"
                    onClick={handleLoginwithValidotp}
                    disabled={isDisableOTPField}
                    loading={loginLoading}
                >
                    {t("loginpage.send", { ns: ["home"] })!}
                </LoadingButton>
            </DialogActions>
        </Dialog >
    );
}

export default AddUserEmail;