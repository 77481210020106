import { addQuestionSubmitValue } from "./Model";

export const QUESTION_SUBMIT_LOADING = "QUESTION_SUBMIT_LOADING";
export const QUESTION_SUBMIT_SUCCESS = "QUESTION_SUBMIT_SUCCESS";
export const QUESTION_SUBMIT_FAIL = "QUESTION_SUBMIT_FAIL";
export const ADD_QUESTION_SUBMIT_LIST = "ADD_QUESTION_SUBMIT_LIST";

export interface addQuestionSubmitDetails {
  addQuestionSubmitRes: addQuestionSubmitValue;
  loading: boolean;
}
export interface SharePatientListLoading {
  type: typeof QUESTION_SUBMIT_LOADING;
}
export interface SharePatientListSuccess {
  type: typeof QUESTION_SUBMIT_SUCCESS;
  payload: addQuestionSubmitValue;
}
export interface SharePatientListFail {
  type: typeof QUESTION_SUBMIT_FAIL;
}
interface SharePatientListAction {
  type: typeof ADD_QUESTION_SUBMIT_LIST;
  payload: addQuestionSubmitValue;
}

export type questionSubmitActionTypes =
  | SharePatientListAction
  | SharePatientListLoading
  | SharePatientListSuccess
  | SharePatientListFail;
