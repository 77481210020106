import {
  DoctorDetailsByIdDetails,
  DoctorDetailsByIdDetailsActionTypes,
  GET_APPOINTMENT_PAYMENT_LIST_FAIL,
  GET_APPOINTMENT_PAYMENT_LIST_LOADING,
  GET_APPOINTMENT_PAYMENT_LIST_SUCCESS,
} from "./ActionTypes";
import { AppointmentPaymentRes } from "./Model";

const initialStateGetPosts: DoctorDetailsByIdDetails = {
  loading: false,
  getAppointmentPaymentListRes: {} as AppointmentPaymentRes,
  errRes: "",
};
export const getAppointmentPaymentListReducer = (
  state = initialStateGetPosts,
  action: DoctorDetailsByIdDetailsActionTypes,
): DoctorDetailsByIdDetails => {
  switch (action.type) {
    case GET_APPOINTMENT_PAYMENT_LIST_SUCCESS:
      return {
        ...state,
        loading: false,
        getAppointmentPaymentListRes: action.payload,
        errRes: "",
      };
    case GET_APPOINTMENT_PAYMENT_LIST_LOADING:
      return {
        ...state,
        loading: true,
        getAppointmentPaymentListRes: {} as AppointmentPaymentRes,
      };
    case GET_APPOINTMENT_PAYMENT_LIST_FAIL:
      return {
        ...state,
        getAppointmentPaymentListRes: {} as AppointmentPaymentRes,
        loading: false,
        errRes: action.errRes,
      };
    default:
      return state;
  }
};
