import {
  PatientDashBoardLatestDataListActionTypes,
  PATIENT_DASHBOARD_LATEST_DATALIST_FAIL,
  PATIENT_DASHBOARD_LATEST_DATALIST_LOADING,
  PATIENT_DASHBOARD_LATEST_DATALIST_SUCCESS,
  PatientDashBoardLatestDataListState,
  PATIENT_DASHBOARD_LATEST_DATALIST_UPDATE_API_MSG,
  PATIENT_DASHBOARD_LATEST_DATALIST_UPDATE_API_RES,
} from "./ActionTypes";
import { LatestDataListRes } from "./Model";

const initialStateGetPosts: PatientDashBoardLatestDataListState = {
  loading: false,
  patientDashBoardLatestDataListRes: {} as LatestDataListRes,
  successMsg: "",
  errorMsg: "",
  status: 0,
};
export const getPatientDashboardLatestDataListReducer = (
  state = initialStateGetPosts,
  action: PatientDashBoardLatestDataListActionTypes,
): PatientDashBoardLatestDataListState => {
  switch (action.type) {
    case PATIENT_DASHBOARD_LATEST_DATALIST_LOADING:
      return {
        ...state,
        loading: action.loading,
      };
    case PATIENT_DASHBOARD_LATEST_DATALIST_SUCCESS:
      return {
        ...state,
        patientDashBoardLatestDataListRes: action.payload,
        successMsg: action.successMsg,
      };
    case PATIENT_DASHBOARD_LATEST_DATALIST_FAIL:
      return {
        ...state,
        patientDashBoardLatestDataListRes: action.payload,
        errorMsg: action.errorMsg,
        status: action.status,
      };
    case PATIENT_DASHBOARD_LATEST_DATALIST_UPDATE_API_MSG:
      return {
        ...state,
        patientDashBoardLatestDataListRes: action.payload,
        successMsg: action.successMsg,
        errorMsg: action.errorMsg,
        status: action.status,
      };
    case PATIENT_DASHBOARD_LATEST_DATALIST_UPDATE_API_RES:
      return {
        ...state,
        patientDashBoardLatestDataListRes: action.payload,
        successMsg: action.successMsg,
        errorMsg: action.errorMsg,
        status: action.status,
      };
    default:
      return state;
  }
};
