import {
  DATALIST_BY_OBSERVATION_TYPE_MAPPING_LIST_FAIL,
  DATALIST_BY_OBSERVATION_TYPE_MAPPING_LIST_LOADING,
  DATALIST_BY_OBSERVATION_TYPE_MAPPING_LIST_SUCCESS,
  DATALIST_BY_OBSERVATION_TYPE_MAPPING_LIST_UPDATE_API_MSG,
  DATALIST_BY_OBSERVATION_TYPE_MAPPING_LIST_UPDATE_API_RES,
  DataListByObservationTypeMappingListActionTypes,
} from "./ActionTypes";
import { DataListByObservationTypeMappingListRes } from "./Model";

export const dataListByObservationTypeMappingListLoadingAction = (
  loading: boolean,
): DataListByObservationTypeMappingListActionTypes => {
  return {
    type: DATALIST_BY_OBSERVATION_TYPE_MAPPING_LIST_LOADING,
    loading: loading,
  };
};

export const dataListByObservationTypeMappingListSuccessAction = (
  dataListByObservationTypeMappingListRes: DataListByObservationTypeMappingListRes,
  successMsg: string,
): DataListByObservationTypeMappingListActionTypes => {
  return {
    type: DATALIST_BY_OBSERVATION_TYPE_MAPPING_LIST_SUCCESS,
    payload: dataListByObservationTypeMappingListRes,
    successMsg: successMsg,
  };
};

export const dataListByObservationTypeMappingListErrorAction = (
  dataListByObservationTypeMappingListRes: DataListByObservationTypeMappingListRes,
  errMsg: string,
  status: number,
): DataListByObservationTypeMappingListActionTypes => {
  return {
    type: DATALIST_BY_OBSERVATION_TYPE_MAPPING_LIST_FAIL,
    payload: dataListByObservationTypeMappingListRes,
    errorMsg: errMsg,
    status: status,
  };
};
export const dataListByObservationTypeMappingListUpdateAPIMsgAction = (
  dataListByObservationTypeMappingListRes: DataListByObservationTypeMappingListRes,
  successMsg: string,
  errMsg: string,
  status: number,
): DataListByObservationTypeMappingListActionTypes => {
  return {
    type: DATALIST_BY_OBSERVATION_TYPE_MAPPING_LIST_UPDATE_API_MSG,
    payload: dataListByObservationTypeMappingListRes,
    successMsg: successMsg,
    errorMsg: errMsg,
    status: status,
  };
};

export const dataListByObservationTypeMappingListAPIResClearAction = (
  dataListByObservationTypeMappingListRes: DataListByObservationTypeMappingListRes,
  successMsg: string,
  errMsg: string,
  status: number,
): DataListByObservationTypeMappingListActionTypes => {
  return {
    type: DATALIST_BY_OBSERVATION_TYPE_MAPPING_LIST_UPDATE_API_RES,
    payload: dataListByObservationTypeMappingListRes,
    successMsg: successMsg,
    errorMsg: errMsg,
    status: status,
  };
};
