import React, { useState, useEffect } from "react";
import VisibilityIcon from "@mui/icons-material/Visibility";
import { useHistory, useParams } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { AppState } from "../../redux/store/Store";
import {
  Box,
  Button,
  Card,
  CardContent,
  CardHeader,
  Container,
  Grid,
  Pagination,
  Stack,
  TextField,
  Typography,
} from "@mui/material";
import { DataGrid, GridCellParams, GridColDef, GridToolbar } from "@mui/x-data-grid";
import dayjs from "dayjs";
import {
  GetOrganizationPatientListBody,
  PatientList,
} from "../../redux/PatientListByOrganization/Model";
import {
  clearOrgPatientListAPIRes,
  getPatientListByOrganization,
} from "../../redux/PatientListByOrganization/API";
import MWLoader from "../../components/MWLoader";
import MWExceptionList from "../../components/MWExceptionList";
import MWPageTitle from "../../components/MWPageTitle";
import { AdminOrganizationList } from "../../redux/interfaces/Model";

export default function PatientListByOrganization() {
  /*  create a piece of state (toSignin)
    with a method to update that state (setToHome)
    and give it a default value of false */
  const loadingMarkup = <MWLoader />;
  const dispatch = useDispatch();
  const { organizationid } = useParams() as {
    organizationid: string;
  };

  const AdminAllOrganizationList = useSelector((state: AppState) => state.organizationListValue);
  const history = useHistory();
  const [page, setPage] = useState(1);
  const [pageTitle, setPageTitle] = React.useState("");
  const [listCount, setListCount] = React.useState("");
  function gotoDetails(patientId: number) {
    history.push(`/patientdetails/${patientId}`);
  }
  const [ptId, setPtId] = useState("");
  const keyDownEvent = (event: React.KeyboardEvent<HTMLDivElement>) => {
    if (event.key === "Enter") {
      history.push(`/patientdetails/${ptId}`);
    }
  };
  const [pageListCount, setPageListCount] = React.useState("");

  // useEffect(() => {
  //   organizationName !== ""
  //     ? setPageTitle(`Patient List of ( ${organizationName} )`)
  //     : setPageTitle("");
  // }, [organizationName]);

  useEffect(() => {
    const patientListBody = {
      organizationId: organizationid,
    } as GetOrganizationPatientListBody;
    dispatch(getPatientListByOrganization(patientListBody, page));
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [dispatch]);

  useEffect(() => {
    if (AdminAllOrganizationList?.organizationListValue !== undefined) {
      var result = AdminAllOrganizationList?.organizationListValue!.find(
        (obj: AdminOrganizationList) => {
          return obj.id === Number(organizationid);
        },
      )?.displayName;
      if (result !== null) {
        setPageTitle(result!);
      }
    }
    // eslint-disable-next-line
  }, [AdminAllOrganizationList]);

  function organizationPatientListApi() {
    const patientListBody = {
      organizationId: organizationid,
    } as GetOrganizationPatientListBody;
    dispatch(getPatientListByOrganization(patientListBody, 1));
    setPage(1);
  }

  // call Store
  const AdminPatientList = useSelector((state: AppState) => state.organizationPatientListValue);
  const allcaseValues = AdminPatientList?.PatientListOfOrganizationIdRes?.patientList;

  const handleChange = (_event: React.ChangeEvent<unknown>, value: number) => {
    setPage(value);
    if (allcaseValues !== undefined) {
      const patientListBody = {
        organizationId: organizationid,
      } as GetOrganizationPatientListBody;
      dispatch(getPatientListByOrganization(patientListBody, value));
      dispatch(clearOrgPatientListAPIRes());
    }
  };
  const [dataGridArray, setDataGridArray] = useState([] as any[]);
  useEffect(() => {
    AdminPatientList?.PatientListOfOrganizationIdRes?.numberOfPages !== undefined
      ? setPageListCount(String(AdminPatientList?.PatientListOfOrganizationIdRes?.numberOfPages))
      : setPageListCount("0");
    if (allcaseValues !== undefined) {
      setListCount(String(AdminPatientList?.PatientListOfOrganizationIdRes?.count));
      let dataPointArr = allcaseValues.map((element: PatientList) => ({
        id: element.patientId,
        firstname: element.givenName,
        lastname: element.familyName,
        userName: element.userName,
        patientId: element.patientId,
        dob: dayjs(element.dob).format("MMMM D, YYYY"),
        phone: element.phone,
        pincode: element.pincode,
        createdDate: dayjs(element.createDate).format("MMMM D, YYYY"),
        modifiedDate: dayjs(element.modifiedDate).format("MMMM D, YYYY"),
      }));
      setDataGridArray(dataPointArr);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [AdminPatientList]);

  const columns: GridColDef[] = [
    {
      field: "id",
      headerName: "View",
      width: 100,
      align: "left",
      flex: 1,
      renderCell: (params: GridCellParams) => (
        <Button
          variant="outlined"
          startIcon={<VisibilityIcon />}
          onClick={() => {
            gotoDetails(params.value);
          }}
        >
          View
        </Button>
      ),
    },
    {
      field: "patientId",
      headerName: "Patient Id",
      width: 150,
      align: "left",
      flex: 1,
    },
    {
      field: "firstname",
      headerName: "First name",
      editable: true,
      width: 200,
      align: "left",
      flex: 1,
    },
    {
      field: "lastname",
      headerName: "Last name",
      editable: true,
      width: 200,
      align: "left",
      flex: 1,
    },
    {
      field: "dob",
      headerName: "DOB",
      type: "string",
      editable: true,
      width: 200,
      align: "left",
      flex: 1,
    },
    {
      field: "userName",
      headerName: "User Name",
      sortable: false,
      width: 100,
      align: "left",
      flex: 1,
    },
    {
      field: "pincode",
      headerName: "Pincode",
      editable: true,
      width: 100,
      align: "left",
      flex: 1,
    },
    {
      field: "createdDate",
      headerName: "Created Date",
      editable: true,
      width: 100,
      align: "left",
      flex: 1,
    },
    {
      field: "modifiedDate",
      headerName: "Modified Date",
      editable: true,
      width: 200,
      align: "left",
      flex: 1,
    },
  ];

  const PatientListOfOrganization = (
    <Card>
      <CardHeader
        title={
          <Grid container direction="row" justifyContent="flex-end" alignItems="center">
            <Grid item>
              <Grid
                container
                direction="row"
                justifyContent="space-between"
                alignItems="center"
                spacing={2}
              >
                <Grid item></Grid>
                <Grid item>
                  <Box onKeyDown={keyDownEvent}>
                    <TextField
                      label="Go To Patient ID"
                      size="small"
                      onChange={(event: any) => setPtId(event.target.value)}
                    />
                  </Box>
                </Grid>
                <Grid item>
                  <Typography>Page: {page}</Typography>
                </Grid>
                <Grid item>
                  <Pagination
                    color="primary"
                    count={Number(pageListCount) || 0}
                    page={page}
                    onChange={handleChange}
                  />
                </Grid>
              </Grid>
            </Grid>
          </Grid>
        }
      />
      <CardContent>
        <Box>
          {(allcaseValues && allcaseValues.length) ||
          (AdminPatientList?.PatientListOfOrganizationIdRes?.patientList &&
            AdminPatientList?.PatientListOfOrganizationIdRes?.patientList.length) ? (
            <DataGrid
              rows={dataGridArray}
              columns={columns}
              disableSelectionOnClick
              hideFooter
              hideFooterPagination
              experimentalFeatures={{ newEditingApi: true }}
              autoHeight
              components={{ Toolbar: GridToolbar }}
              componentsProps={{
                toolbar: {
                  csvOptions: { disableToolbarButton: true },
                  printOptions: { disableToolbarButton: true },
                  showQuickFilter: true,
                  quickFilterProps: { debounceMs: 250 },
                },
              }}
              disableColumnFilter
              disableColumnSelector
              disableDensitySelector
            />
          ) : (
            <MWExceptionList />
          )}
        </Box>
      </CardContent>
    </Card>
  );

  return (
    <Box>
      {AdminPatientList.loading === true ? loadingMarkup : ""}
      <Container maxWidth="xl">
        <Stack
          direction="column"
          justifyContent="flex-start"
          alignItems="stretch"
          spacing={2}
          pt={2}
        >
          <Grid container direction="row" justifyContent="space-between" alignItems="flex-start">
            <Grid item>
              <MWPageTitle
                backButton={true}
                title={pageTitle}
                enableCount={true}
                count={listCount}
                reload={true}
                reloadAction={organizationPatientListApi}
              />
            </Grid>
          </Grid>
          {PatientListOfOrganization}
        </Stack>
      </Container>
    </Box>
  );
}
