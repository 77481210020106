import { GET_LIST } from "../types/ActionTypes";
import { sharedPatientList } from "./Model";

export const SHARED_PATIENT_LIST_LOADING = "SHARED_PATIENT_LIST_LOADING";
export const SHARED_PATIENT_LIST_SUCCESS = "SHARED_PATIENT_LIST_SUCCESS";
export const SHARED_PATIENT_LIST_FAIL = "SHARED_PATIENT_LIST_FAIL";

export interface GetSharedPatientDetails {
  sharedPatientDetailsRes: sharedPatientList;
  loading: boolean;
}
export interface SharePatientListLoading {
  type: typeof SHARED_PATIENT_LIST_LOADING;
}
export interface SharePatientListSuccess {
  type: typeof SHARED_PATIENT_LIST_SUCCESS;
  payload: sharedPatientList;
}
export interface SharePatientListFail {
  type: typeof SHARED_PATIENT_LIST_FAIL;
}
interface SharePatientListAction {
  type: typeof GET_LIST;
  payload: sharedPatientList;
}

export type sharedPatientDetailsActionTypes =
  | SharePatientListAction
  | SharePatientListLoading
  | SharePatientListSuccess
  | SharePatientListFail;
