import {
  DOCTOR_TEAM_TYPE_LIST_FAIL,
  DOCTOR_TEAM_TYPE_LIST_LOADING,
  DOCTOR_TEAM_TYPE_LIST_SUCCESS,
  DOCTOR_TEAM_TYPE_LIST_UPDATE_API_MSG,
  DOCTOR_TEAM_TYPE_LIST_UPDATE_API_RES,
  GetDoctorTeamTypeListActionTypes,
} from "./ActionTypes";
import { GetDoctorTeamTypeListRes } from "./Model";

export const getDoctorTeamTypeListLoadingAction = (
  loading: boolean,
): GetDoctorTeamTypeListActionTypes => {
  return {
    type: DOCTOR_TEAM_TYPE_LIST_LOADING,
    loading: loading,
  };
};

export const getDoctorTeamTypeListSuccessAction = (
  getDoctorTeamTypeListResponse: GetDoctorTeamTypeListRes,
  successMsg: string,
): GetDoctorTeamTypeListActionTypes => {
  return {
    type: DOCTOR_TEAM_TYPE_LIST_SUCCESS,
    payload: getDoctorTeamTypeListResponse,
    successMsg: successMsg,
  };
};

export const getDoctorTeamTypeListErrorAction = (
  getDoctorTeamTypeListResponse: GetDoctorTeamTypeListRes,
  errMsg: string,
  status: number,
): GetDoctorTeamTypeListActionTypes => {
  return {
    type: DOCTOR_TEAM_TYPE_LIST_FAIL,
    payload: getDoctorTeamTypeListResponse,
    errorMsg: errMsg,
    status: status,
  };
};
export const getDoctorTeamTypeListUpdateAPIMsgAction = (
  getDoctorTeamTypeListResponse: GetDoctorTeamTypeListRes,
  successMsg: string,
  errMsg: string,
  status: number,
): GetDoctorTeamTypeListActionTypes => {
  return {
    type: DOCTOR_TEAM_TYPE_LIST_UPDATE_API_MSG,
    payload: getDoctorTeamTypeListResponse,
    successMsg: successMsg,
    errorMsg: errMsg,
    status: status,
  };
};

export const getDoctorTeamTypeListAPIResClearAction = (
  getDoctorTeamTypeListResponse: GetDoctorTeamTypeListRes,
  successMsg: string,
  errMsg: string,
  status: number,
): GetDoctorTeamTypeListActionTypes => {
  return {
    type: DOCTOR_TEAM_TYPE_LIST_UPDATE_API_RES,
    payload: getDoctorTeamTypeListResponse,
    successMsg: successMsg,
    errorMsg: errMsg,
    status: status,
  };
};
