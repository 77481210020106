import { GetObservationTypeResponse } from "./Model";
export const GET_OBSERVATION_TYPE_LOADING = "GET_OBSERVATION_TYPE_LOADING";
export const GET_OBSERVATION_TYPE_SUCCESS = "GET_OBSERVATION_TYPE_SUCCESS";
export const GET_OBSERVATION_TYPE_FAIL = "GET_OBSERVATION_TYPE_FAIL";
export const GET_OBSERVATION_TYPE_DATA = "GET_OBSERVATION_TYPE_DATA";
export const GET_OBSERVATION_TYPE_UPDATE_API_MSG = "GET_OBSERVATION_TYPE_UPDATE_API_MSG";
export const GET_OBSERVATION_TYPE_UPDATE_API_RES = "GET_OBSERVATION_TYPE_UPDATE_API_RES";

export interface GetObservationTypeState {
  GetObservationTypeResponse: GetObservationTypeResponse;
  loading: boolean;
  successMsg: string;
  errorMsg: string;
  status: number;
}
export interface GetObservationTypeLoading {
  type: typeof GET_OBSERVATION_TYPE_LOADING;
  loading: boolean;
}
export interface GetObservationTypeSuccess {
  type: typeof GET_OBSERVATION_TYPE_SUCCESS;
  payload: GetObservationTypeResponse;
  successMsg: string;
}
export interface GetObservationTypeFail {
  type: typeof GET_OBSERVATION_TYPE_FAIL;
  payload: GetObservationTypeResponse;
  errorMsg: string;
  status: number;
}
export interface GetObservationTypeUpdateAPIMsg {
  type: typeof GET_OBSERVATION_TYPE_UPDATE_API_MSG;
  payload: GetObservationTypeResponse;
  successMsg: string;
  errorMsg: string;
  status: number;
}
export interface GetObservationTypeUpdateAPIRes {
  type: typeof GET_OBSERVATION_TYPE_UPDATE_API_RES;
  payload: GetObservationTypeResponse;
  successMsg: string;
  errorMsg: string;
  status: number;
}

export type GetObservationTypeActionTypes =
  | GetObservationTypeLoading
  | GetObservationTypeSuccess
  | GetObservationTypeFail
  | GetObservationTypeUpdateAPIMsg
  | GetObservationTypeUpdateAPIRes;
