import { GetDoctorListForCaseRes } from "./Model";
export const GET_DOCTOR_LIST_FOR_CASE_LOADING = "GET_DOCTOR_LIST_FOR_CASE_LOADING ";
export const GET_DOCTOR_LIST_FOR_CASE_SUCCESS = "GET_DOCTOR_LIST_FOR_CASE_SUCCESS";
export const GET_DOCTOR_LIST_FOR_CASE_FAIL = "GET_DOCTOR_LIST_FOR_CASE_FAIL";
export const GET_DOCTOR_LIST_FOR_CASE_UPDATE_API_MSG = "GET_DOCTOR_LIST_FOR_CASE_UPDATE_API_MSG";
export const GET_DOCTOR_LIST_FOR_CASE_UPDATE_API_RES = "GET_DOCTOR_LIST_FOR_CASE_UPDATE_API_RES";

export interface GetDoctorListForCaseState {
  getDoctorListForCaseRes: GetDoctorListForCaseRes;
  loading: boolean;
  successMsg: string;
  errorMsg: string;
  status: number;
}
export interface GetDoctorListForCaseLoading {
  type: typeof GET_DOCTOR_LIST_FOR_CASE_LOADING;
  loading: boolean;
}
export interface GetDoctorListForCaseSuccess {
  type: typeof GET_DOCTOR_LIST_FOR_CASE_SUCCESS;
  payload: GetDoctorListForCaseRes;
  successMsg: string;
}
export interface GetDoctorListForCaseFail {
  type: typeof GET_DOCTOR_LIST_FOR_CASE_FAIL;
  payload: GetDoctorListForCaseRes;
  errorMsg: string;
  status: number;
}
export interface GetDoctorListForCaseUpdateAPIMsg {
  type: typeof GET_DOCTOR_LIST_FOR_CASE_UPDATE_API_MSG;
  payload: GetDoctorListForCaseRes;
  successMsg: string;
  errorMsg: string;
  status: number;
}
export interface GetDoctorListForCaseUpdateAPIRes {
  type: typeof GET_DOCTOR_LIST_FOR_CASE_UPDATE_API_RES;
  payload: GetDoctorListForCaseRes;
  successMsg: string;
  errorMsg: string;
  status: number;
}

export type GetDoctorListForCaseActionTypes =
  | GetDoctorListForCaseLoading
  | GetDoctorListForCaseSuccess
  | GetDoctorListForCaseFail
  | GetDoctorListForCaseUpdateAPIMsg
  | GetDoctorListForCaseUpdateAPIRes;
