import axios from "axios";
import { Dispatch } from "redux";
import Url from "../../../Url";
import { SleepScoreObservationTypeActionTypes } from "./ActionTypes";
import { SleepScoreObservationTypeBody, SleepScoreObservationTypeRes } from "./Model";
import {
  sleepScoreObservationTypeAPIResClearAction,
  sleepScoreObservationTypeErrorAction,
  sleepScoreObservationTypeLoadingAction,
  sleepScoreObservationTypeSuccessAction,
  sleepScoreObservationTypeUpdateAPIMsgAction,
} from "./Actions";
import { headers } from "../../../components/Utility";

let apiRes = {} as SleepScoreObservationTypeRes;
export const sleepScoreObservationType = (payload: SleepScoreObservationTypeBody) => {
  return function (dispatch: Dispatch<SleepScoreObservationTypeActionTypes>) {
    dispatch(sleepScoreObservationTypeLoadingAction(true));
    axios
      .post(
        Url.apiURL + `/patientapp/api/patientdashboard/patientDashboardDataListByObservationType`,
        payload,
        { headers: headers },
      )
      .then((res) => {
        apiRes = res.data;
        dispatch(sleepScoreObservationTypeLoadingAction(false));
        dispatch(
          sleepScoreObservationTypeSuccessAction(
            res.data,
            res.data.message !== undefined
              ? res.data.message
              : "patient sleep score is showing successfully",
          ),
        );
      })
      .catch((error) => {
        dispatch(sleepScoreObservationTypeLoadingAction(false));
        dispatch(
          sleepScoreObservationTypeErrorAction(
            {} as SleepScoreObservationTypeRes,
            error.response !== undefined
              ? error.response.data.message || error.response.data.detail
              : "Something went wrong",
            error.status !== undefined ? error.response.data.status || -1 : -1,
          ),
        );
      });
  };
};

export const updateSleepScoreObservationTypeAPIResMsg = () => {
  return function (dispatch: Dispatch<SleepScoreObservationTypeActionTypes>) {
    dispatch(
      sleepScoreObservationTypeUpdateAPIMsgAction(
        apiRes as SleepScoreObservationTypeRes,
        "",
        "",
        0,
      ),
    );
  };
};

export const clearSleepScoreObservationTypeAPIRes = () => {
  return function (dispatch: Dispatch<SleepScoreObservationTypeActionTypes>) {
    dispatch(
      sleepScoreObservationTypeAPIResClearAction({} as SleepScoreObservationTypeRes, "", "", 0),
    );
  };
};
