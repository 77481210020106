import React, { useState, useCallback, useEffect } from "react";
import RefreshIcon from "@mui/icons-material/Refresh";
import { useParams } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { t } from "i18next";
import {
  Box,
  Button,
  Card,
  CardActions,
  CardContent,
  CardHeader,
  Container,
  Dialog,
  DialogContent,
  Grid,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Typography,
} from "@mui/material";
import DescriptionIcon from "@mui/icons-material/Description";
import { AppState } from "../redux/store/Store";
import { getChiefComplaintList } from "../redux/FilePreparation/ApiCall";
import MWExceptionList from "../components/MWExceptionList";

export default function FinalOpinion(props: any) {
  const { caseid } = useParams() as {
    caseid: string;
  };
  const dispatch = useDispatch();

  const { appointmentFinalOpinionLoader } = props;
  const [imageInfo, setImageInfo] = useState("");
  const [docViwerActive, setDocViwerActive] = useState(false);
  const handleDocViewerChange = useCallback(
    () => setDocViwerActive(!docViwerActive),
    [docViwerActive],
  );

  const symptomsValue = useSelector((state: AppState) => state.ccValue);
  const PatientFinalOpinionLoadingValue = useSelector((state: AppState) => state.ccValue.loading);

  const [chiefComplaintListValue, setChiefComplaintListValue] = useState<any[]>([]);
  const truncate = (str: string) => {
    return str.length > 0 ? str.substring(0, 20) + "..." : str;
  };

  const getSecondOpinionBody = {
    caseId: caseid,
    secondOpinionUiSection: "finalOpinion",
  };
  const getSecondOpinionList = () => {
    dispatch(getChiefComplaintList(getSecondOpinionBody));
  };

  useEffect(() => {
    window.scrollTo(0, 0);
    getSecondOpinionList();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    let symptomConfig = [];
    for (var i = 0; i < symptomsValue.ccValue.length; i++) {
      // let patientDetailsID = resData[i].id;
      let description =
        symptomsValue.ccValue[i].description == null
          ? ""
          : truncate(symptomsValue.ccValue[i].description);
      console.log("Award Name " + description);
      let dateStart =
        symptomsValue.ccValue[i].startDate == null
          ? ""
          : new Date(symptomsValue.ccValue[i].startDate).toLocaleDateString("en-US", DATE_OPTIONS);
      let dateEnd =
        symptomsValue.ccValue[i].endDate == null
          ? ""
          : new Date(symptomsValue.ccValue[i].endDate).toLocaleDateString("en-US", DATE_OPTIONS);
      let supportingDocuments =
        symptomsValue.ccValue[i].patientDocument == null
          ? ""
          : symptomsValue.ccValue[i].patientDocument.document;
      symptomConfig.push([
        <Button
          variant="outlined"
          onClick={() => {
            setDocViwerActive(true);
            setImageInfo(supportingDocuments);
          }}
          startIcon={<DescriptionIcon />}
        >
          Pay Now
        </Button>,
        description,
        dateStart,
        dateEnd,
      ]);
      console.log("Symptoms List ====> ", symptomConfig);
    }
    setChiefComplaintListValue(symptomConfig);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [symptomsValue.ccValue]);

  const DATE_OPTIONS = {
    weekday: "short",
    year: "numeric",
    month: "short",
    day: "numeric",
  } as const;
  const secondOpinionTableBody = (
    <Box>
      <Card>
        <Grid container direction="row" justifyContent="space-between" alignItems="center">
          <Grid item>
            <CardHeader title={t("common.docs", { ns: ["home"] })}></CardHeader>
          </Grid>
          <Grid item>
            <CardActions>
              <Button
                variant="outlined"
                startIcon={<RefreshIcon />}
                onClick={() => getSecondOpinionList()}
              >
                {t("userprofile.reload", { ns: ["home"] })!}
              </Button>
            </CardActions>
          </Grid>
        </Grid>

        <CardContent>
          {chiefComplaintListValue && chiefComplaintListValue.length ? (
            <TableContainer>
              <Table size="small" aria-label="a dense table">
                <TableHead>
                  <TableRow>
                    <TableCell>{t("addpatientpage.doc", { ns: ["home"] })}</TableCell>
                    <TableCell>{t("appointment.enterDesc", { ns: ["home"] })}</TableCell>
                    <TableCell>{t("common.stDate", { ns: ["home"] })}</TableCell>
                    <TableCell>{t("appointment.enddate", { ns: ["home"] })}</TableCell>
                  </TableRow>
                </TableHead>
                <TableBody>
                  {chiefComplaintListValue.map((row: any) => (
                    <TableRow
                      key={row.description}
                      sx={{
                        "&:last-child td, &:last-child th": { border: 0 },
                      }}
                    >
                      <TableCell align="left">{row[0]}</TableCell>
                      <TableCell align="left">{row[1]}</TableCell>
                      <TableCell align="left">{row[2]}</TableCell>
                      <TableCell align="left"></TableCell>
                      {row[3]}
                      <TableCell align="left">{row[4]}</TableCell>
                    </TableRow>
                  ))}
                </TableBody>
              </Table>
            </TableContainer>
          ) : (
            <MWExceptionList />
          )}
        </CardContent>
      </Card>
    </Box>
  );

  const docViewerComponent = (
    <Dialog open={docViwerActive} onClose={handleDocViewerChange}>
      <DialogContent>
        <Container>
          <Typography>
            <iframe
              src={imageInfo}
              title="Small document"
              width="100%"
              height="700"
              frameBorder="0"
            >
              This is an embedded{" "}
              <a target="_blank" href="http://office.com" rel="noopener noreferrer">
                Microsoft Office
              </a>{" "}
              document, powered by{" "}
              <a target="_blank" href="http://office.com/webapps" rel="noopener noreferrer">
                Office Online
              </a>
              .
            </iframe>
          </Typography>
        </Container>
      </DialogContent>
    </Dialog>
  );

  return (
    <Box>
      {PatientFinalOpinionLoadingValue === true
        ? appointmentFinalOpinionLoader(true)
        : appointmentFinalOpinionLoader(false)}
      <Container maxWidth="xl">
        <Typography variant="h5">{t("finalOpinionPage.finalOp", { ns: ["home"] })!}</Typography>
        {secondOpinionTableBody}
        {docViewerComponent}
      </Container>
    </Box>
  );
}
