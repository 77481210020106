import * as React from "react";
import { Box, Button, Grid } from "@mui/material";
import { Link, useHistory, useParams } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { AppState } from "../../../redux/store/Store";
import { getPatientCaseList } from "../../../redux/effects/ApiCall";
import { useEffect } from "react";
import MWResourceList from "../../../components/MWResourceList";
import MWExceptionList from "../../../components/MWExceptionList";
import PageLayout from "../../../components/PageLayout";
import MWPageTitle from "../../../components/MWPageTitle";
import { toggleAppBarLoading } from "redux/appLoader/Actions";

export default function CaseListExpanded() {
  const dispatch = useDispatch();
  const history = useHistory();
  const { patientid } = useParams() as {
    patientid: string;
  };
  // Reload Patient Case List API
  const reloadPatientCaseList = () => {
    dispatch(getPatientCaseList(patientid));
  };
  const handleAddEncounterToggle = () => {
    history.push(`/casedetails/${patientid}`);
  };

  useEffect(() => {
    dispatch(getPatientCaseList(patientid));
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const caseHistory = useSelector((state: AppState) => state.caseValue);
  useEffect(() => {
    dispatch(toggleAppBarLoading(caseHistory?.loading));
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [caseHistory]);

  const pageBody = (
    <Box py={2}>
      {caseHistory?.caseValue !== undefined ? (
        caseHistory?.caseValue.length > 0 ? (
          caseHistory?.caseValue.map((element: any) => (
            <Box key={element.id} style={{ textDecoration: "none", textTransform: "none" }}>
              <Link
                to={`/casedetails/${element.patientId}/${element.caseId}`}
                style={{ textDecoration: "none" }}
              >
                <MWResourceList
                  icon={true}
                  title={element?.whyWant2ndOpinion}
                  showSubtitle={true}
                  subtitle={element?.secondOpinionReasons}
                  showReason={true}
                  reason={element?.consultationType}
                  showNavigation={true}
                />
              </Link>
            </Box>
          ))
        ) : (
          <MWExceptionList />
        )
      ) : (
        <MWExceptionList />
      )}
    </Box>
  );
  return (
    <PageLayout>
      <Grid container direction="row" justifyContent="space-between" alignItems="center">
        <Grid item>
          <MWPageTitle
            backButton={true}
            title="Patient Case List"
            enableCount={true}
            count={caseHistory?.caseValue !== undefined ? caseHistory?.caseValue.length : "0"}
            reload={true}
            reloadAction={reloadPatientCaseList}
          />
        </Grid>
        <Grid item>
          <Button
            variant="contained"
            onClick={() => handleAddEncounterToggle()}
            sx={{ textTransform: "none" }}
          >
            Add Case
          </Button>
        </Grid>
      </Grid>
      {pageBody}
    </PageLayout>
  );
}
