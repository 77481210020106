import React, { useEffect } from "react";
import { useHistory } from "react-router-dom";
import { useSelector, useDispatch } from "react-redux";
import { AppState } from "../../redux/store/Store";
import { getTaskList } from "../../redux/effects/ApiCall";
import { Button, Card, CardContent, CardHeader, Grid, Typography } from "@mui/material";
import { DataGrid, GridColDef, GridCellParams, GridToolbar } from "@mui/x-data-grid";
import PreviewIcon from "@mui/icons-material/Preview";
import MWExceptionList from "../../components/MWExceptionList";
import RefreshIcon from "@mui/icons-material/Refresh";
import MWPageTitle from "../../components/MWPageTitle";
import PageLayout from "../../components/PageLayout";
import { toggleAppBarLoading } from "redux/appLoader/Actions";

export default function Task() {
  const dispatch = useDispatch();
  const history = useHistory();
  function viewTaskDetails(rowIndex: any) {
    history.push(`/taskdetails/${rowIndex}`);
  }
  useEffect(() => {
    dispatch(getTaskList());
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [dispatch]);
  const refreshTaskList = () => {
    dispatch(getTaskList());
  };

  const columns: GridColDef[] = [
    {
      field: "id",
      headerName: "View",
      width: 150,
      renderCell: (params: GridCellParams) => (
        <Button
          variant="outlined"
          startIcon={<PreviewIcon />}
          onClick={() => {
            viewTaskDetails(params.value);
          }}
        >
          View
        </Button>
      ),
    },
    { field: "title", headerName: "Title", width: 350 },
    { field: "whyWant2ndOpinion", headerName: "Problem Name", width: 307 },
    { field: "patientName", headerName: "Patient name", width: 150 },

    {
      field: "consultationType",
      headerName: "Type",
      width: 307,
    },
  ];
  const taskReduxValue = useSelector((state: AppState) => state.taskValue);
  let taskList = taskReduxValue.taskValue;

  useEffect(() => {
    dispatch(toggleAppBarLoading(taskReduxValue?.loading));
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [taskReduxValue]);

  return (
    <PageLayout>
      <MWPageTitle title="Task List" />
      <Card>
        <CardHeader
          title={
            <Grid container direction="row" justifyContent="space-between" alignItems="center">
              <Grid item>
                <Typography variant="h6">Patient Task List</Typography>
              </Grid>
              <Grid item>
                <Button
                  variant="outlined"
                  startIcon={<RefreshIcon />}
                  onClick={() => refreshTaskList()}
                >
                  Reload Task List
                </Button>
              </Grid>
            </Grid>
          }
        />
        <CardContent>
          {taskList && taskList.length ? (
            <DataGrid
              rows={taskList}
              columns={columns}
              getRowId={(row) => row.id}
              hideFooter
              hideFooterPagination
              disableSelectionOnClick
              experimentalFeatures={{ newEditingApi: true }}
              autoHeight
              components={{ Toolbar: GridToolbar }}
              componentsProps={{
                toolbar: {
                  csvOptions: { disableToolbarButton: true },
                  printOptions: { disableToolbarButton: true },
                  showQuickFilter: true,
                  quickFilterProps: { debounceMs: 250 },
                },
              }}
              disableColumnFilter
              disableColumnSelector
              disableDensitySelector
            />
          ) : (
            <MWExceptionList />
          )}
        </CardContent>
      </Card>
    </PageLayout>
  );
}
