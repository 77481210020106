import {
  PaymentListDetails,
  PaymentListActionTypes,
  GET_PAYMENT_LIST_FAIL,
  GET_PAYMENT_LIST_LOADING,
  GET_PAYMENT_LIST_SUCCESS,
} from "./ActionTypes";
import { PaymentRes } from "./Model";

const initialStateGetPosts: PaymentListDetails = {
  loading: false,
  getPaymentListRes: {} as PaymentRes,
  errRes: "",
};
export const getPaymentListByOwnerReducer = (
  state = initialStateGetPosts,
  action: PaymentListActionTypes,
): PaymentListDetails => {
  switch (action.type) {
    case GET_PAYMENT_LIST_SUCCESS:
      return {
        ...state,
        loading: false,
        getPaymentListRes: action.payload,
        errRes: "",
      };
    case GET_PAYMENT_LIST_LOADING:
      return {
        ...state,
        loading: true,
        getPaymentListRes: {} as PaymentRes,
      };
    case GET_PAYMENT_LIST_FAIL:
      return {
        ...state,
        getPaymentListRes: {} as PaymentRes,
        loading: false,
        errRes: action.errRes,
      };
    default:
      return state;
  }
};
