import { LOG_OUT_FAIL, LOG_OUT_LOADING, LOG_OUT_SUCCESS, LogOutActionTypes } from "./ActionTypes";
import { LogOutResponse } from "./Model";

export const imageUploadLoadingAction = (loading: boolean): LogOutActionTypes => {
  return {
    type: LOG_OUT_LOADING,
    loading: loading,
  };
};

export const logOutSuccessAction = (imageUploadResponse: LogOutResponse): LogOutActionTypes => {
  return {
    type: LOG_OUT_SUCCESS,
    payload: imageUploadResponse,
  };
};

export const logOutErrorAction = (
  imageUploadResponse: LogOutResponse,
  errMsg: string,
  status: number,
): LogOutActionTypes => {
  return {
    type: LOG_OUT_FAIL,
    payload: imageUploadResponse,
    message: errMsg,
    status: status,
  };
};
