import { GetDoctorTeamTypeForCaseRes } from "./Model";
export const GET_DOCTOR_TEAM_TYPE_LOADING = "GET_DOCTOR_TEAM_TYPE_LOADING ";
export const GET_DOCTOR_TEAM_TYPE_SUCCESS = "GET_DOCTOR_TEAM_TYPE_SUCCESS";
export const GET_DOCTOR_TEAM_TYPE_FAIL = "GET_DOCTOR_TEAM_TYPE_FAIL";
export const GET_DOCTOR_TEAM_TYPE_UPDATE_API_MSG = "GET_DOCTOR_TEAM_TYPE_UPDATE_API_MSG";
export const GET_DOCTOR_TEAM_TYPE_UPDATE_API_RES = "GET_DOCTOR_TEAM_TYPE_UPDATE_API_RES";

export interface GetDoctorTeamTypeForCaseState {
  getDoctorTeamTypeForCaseRes: GetDoctorTeamTypeForCaseRes;
  loading: boolean;
  successMsg: string;
  errorMsg: string;
  status: number;
}
export interface GetDoctorTeamTypeForCaseLoading {
  type: typeof GET_DOCTOR_TEAM_TYPE_LOADING;
  loading: boolean;
}
export interface GetDoctorTeamTypeForCaseSuccess {
  type: typeof GET_DOCTOR_TEAM_TYPE_SUCCESS;
  payload: GetDoctorTeamTypeForCaseRes;
  successMsg: string;
}
export interface GetDoctorTeamTypeForCaseFail {
  type: typeof GET_DOCTOR_TEAM_TYPE_FAIL;
  payload: GetDoctorTeamTypeForCaseRes;
  errorMsg: string;
  status: number;
}
export interface GetDoctorTeamTypeForCaseUpdateAPIMsg {
  type: typeof GET_DOCTOR_TEAM_TYPE_UPDATE_API_MSG;
  payload: GetDoctorTeamTypeForCaseRes;
  successMsg: string;
  errorMsg: string;
  status: number;
}
export interface GetDoctorTeamTypeForCaseUpdateAPIRes {
  type: typeof GET_DOCTOR_TEAM_TYPE_UPDATE_API_RES;
  payload: GetDoctorTeamTypeForCaseRes;
  successMsg: string;
  errorMsg: string;
  status: number;
}

export type GetDoctorTeamTypeForCaseActionTypes =
  | GetDoctorTeamTypeForCaseLoading
  | GetDoctorTeamTypeForCaseSuccess
  | GetDoctorTeamTypeForCaseFail
  | GetDoctorTeamTypeForCaseUpdateAPIMsg
  | GetDoctorTeamTypeForCaseUpdateAPIRes;
