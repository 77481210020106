import * as React from "react";
import { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { AppState } from "../../redux/store/Store";
import Box from "@mui/material/Box";
import Grid from "@mui/material/Grid";
import { getHealthcampListValue } from "../../redux/healthcampList/API";
import {
  Button,
  Card,
  CardActionArea,
  CardActions,
  CardHeader,
  Container,
  Divider,
  Stack,
  Typography,
} from "@mui/material";
import HealthcampImage from "../../image/medicalcamp.png";
import { HealthCampList } from "../../redux/healthcampList/Model";
import MWExceptionList from "../../components/MWExceptionList";

type Props = {
  healthcampListLoader?: boolean | any;
};

export default function HealthcampList({ healthcampListLoader }: Props) {
  const dispatch = useDispatch();

  useEffect(() => {
    window.scrollTo(0, 0);
    dispatch(getHealthcampListValue());
  }, [dispatch]);

  // call Store
  const AdminAllHealthcampList = useSelector((state: AppState) => state.healthcampListValue);
  const healthcampValues = AdminAllHealthcampList.healthcampListValue;
  useEffect(() => {
    healthcampListLoader(AdminAllHealthcampList?.loading);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [AdminAllHealthcampList]);
  const actualPageMarkup = (
    <Box m={3}>
      <Grid container spacing={1}>
        <Grid container item sm={12} spacing={3}>
          {healthcampValues.HealthCampList !== undefined ? (
            <React.Fragment>
              {healthcampValues?.HealthCampList.map(
                (healthcampItem: HealthCampList, index: number) => (
                  <Grid item md={4} key={index}>
                    {healthcampItem.id && (
                      <Card>
                        <CardActionArea
                          href={`/patientlistbyhealthcamp/${healthcampItem.id}`}
                          key={"card-area-" + index}
                        >
                          <CardHeader
                            key={"card-header-" + index}
                            avatar={
                              <img
                                key={"card-image-" + index}
                                id={`${healthcampItem.id}`}
                                src={HealthcampImage}
                                alt=""
                                width="64"
                                height="64"
                                style={{
                                  objectFit: "cover",
                                  objectPosition: "center",
                                }}
                              />
                            }
                            title={
                              <Typography
                                key={"card-title-" + index}
                                variant="h5"
                                style={{ fontWeight: "medium" }}
                              >
                                {healthcampItem.displayName}
                              </Typography>
                            }
                          />
                          <Divider light />
                        </CardActionArea>
                        <CardActions key={"card-actions-" + index}>
                          <Button
                            href={`/patientlistbyhealthcamp/${healthcampItem.id}`}
                            fullWidth
                            variant="outlined"
                          >
                            View Healthcamp Patient
                          </Button>
                        </CardActions>
                      </Card>
                    )}
                  </Grid>
                ),
              )}
            </React.Fragment>
          ) : (
            <Box p={3}>
              <MWExceptionList />
            </Box>
          )}
        </Grid>
      </Grid>
    </Box>
  );

  return (
    <Box>
      <Container maxWidth="xl">
        <Stack
          direction="column"
          justifyContent="flex-start"
          alignItems="stretch"
          spacing={2}
          pt={2}
        >
          {actualPageMarkup}
        </Stack>
      </Container>
    </Box>
  );
}
