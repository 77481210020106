import {
  AppointmentConfirmTypes,
  AppointmentListTypes,
  APPOINTMENT_CONFIRM_TYPE,
  APPOINTMENT_LIST_TYPE,
  CheckPaymentStatusTypes,
  CHECK_PAYMENT_STATUS_TYPE,
  PaymentListTypes,
  PAYMENT_LIST_TYPE,
} from "./ActionTypes";

// Payment List Action
export const paymentListAction = (
  consultationType: any,
  responseTimeType: any,
  doctorTeamType: any,
  paymentList: [],
  message: any,
  status: number,
): PaymentListTypes => {
  return {
    type: PAYMENT_LIST_TYPE,
    paymentList: paymentList,
    consultationType: consultationType,
    responseTimeType: responseTimeType,
    doctorTeamType: doctorTeamType,
    message: message,
    status: status,
  };
};

// Payment List Action
export const appointmentListAction = (
  consultationType: any,
  responseTimeType: any,
  doctorTeamType: any,
  appointmentList: [],
  message: any,
  status: number,
): AppointmentListTypes => {
  return {
    type: APPOINTMENT_LIST_TYPE,
    appointmentList: appointmentList,
    consultationType: consultationType,
    responseTimeType: responseTimeType,
    doctorTeamType: doctorTeamType,
    message: message,
    status: status,
  };
};
// Check Payment Status Action
export const paymentStatusAction = (message: any, status: number): CheckPaymentStatusTypes => {
  return {
    type: CHECK_PAYMENT_STATUS_TYPE,
    message: message,
    status: status,
  };
};
// Payment List Action
export const confirmAppointmentAction = (message: any, status: number): AppointmentConfirmTypes => {
  return {
    type: APPOINTMENT_CONFIRM_TYPE,
    message: message,
    status: status,
  };
};
