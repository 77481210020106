import {
  Avatar,
  Card,
  CardActions,
  CardContent,
  CardHeader,
  Grid,
  Typography,
} from "@mui/material";
import React from "react";
import { EHRDataType } from "./DataList";
type Props = {
  data: EHRDataType;
};
export default function ConnectEHRList({ data }: Props) {
  return (
    <Card style={{ maxWidth: "250px", height: "250px" }}>
      <CardHeader
        title={
          <Grid container direction="row" justifyContent="center" alignItems="center" spacing={2}>
            <Grid item>
              <Typography variant="h6" fontWeight={"bold"}>
                {data.org}
              </Typography>
            </Grid>
          </Grid>
        }
      />
      <CardContent>
        <Grid container direction="row" justifyContent="center" alignItems="center" spacing={2}>
          <Grid item>
            <Avatar aria-label="recipe" sx={{ width: 65, height: 65 }}>
              {data.alias}
            </Avatar>
          </Grid>
        </Grid>
      </CardContent>
      <CardActions>
        <Grid container direction="row" justifyContent="center" alignItems="center" spacing={2}>
          <Grid item>{data.name}</Grid>
        </Grid>
      </CardActions>
    </Card>
  );
}
