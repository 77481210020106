import React from "react";
import { Route, Switch } from "react-router-dom";
import { PublicRoute } from "./routes/PublicRoute";
import { PrivateRoute } from "./routes/PrivateRoute";
import Login from "./pages/PatientOnBoarding/Login";
import PatientListByHealthcamp from "./pages/PatientListByHealthCamp/index";
import Signup from "./pages/PatientOnBoarding/Signup";
import NotFound from "./pages/NotFound";
import NeedHelp from "./pages/NeedHelp";
import DoctorDetails from "./pages/DoctorDetails";
import InsuranceDetails from "./pages/InsuranceDetails";
import MasterImportedData from "./pages/MasterImportedData/MasterImportedData";
import Dashboard from "./pages/Dashboard";
import Doctor from "./pages/Doctor";
import AddHealthRecord from "./pages/FilePreparation/AddHealthRecord";
import PackageStatus from "./pages/CaregiverPackageStatus";
import InformConsent from "./pages/FilePreparation/InformConsent";
import CaseHistory from "./pages/CaseHistory";
import FilePreparation from "./pages/FilePreparation";
import DemographicDetails from "./pages/FilePreparation/DemographicDetails";
import OrganizationPatientList from "./pages/OrganizationPatientList/index";
import Task from "./pages/Task/Task";
import TaskDetails from "./pages/Task/TaskDetails";
import AppointmentDetails from "./pages/AppoinmentDetails";
import Userdetails from "./pages/UserDetails";
import PatientDetails from "./pages/PatientDetails/PatientDetails";
import DataObservationList from "./pages/DataObservationList";
import CaseListExpanded from "./pages/PatientDashboard/CaseList/CaseListExpanded";
import ObservationDetails from "./pages/PatientDetails/ObservationDetails";
import PatientNotes from "./pages/Notes/PatientNote/PatientNotes";
import EHRDetails from "pages/PatientDetails/EHRDetails";
function ComponentRouter() {
  return (
    <Switch>
      <PublicRoute exact path="/" component={Login} />
      <PublicRoute exact path="/signin" component={Login} />
      <PublicRoute exact path="/signup" component={Signup} />
      <PrivateRoute path="/dashboard" component={Dashboard} />
      <PrivateRoute path="/appoinmentDetails" component={AppointmentDetails} />
      <PrivateRoute path="/doctor" component={Doctor} />
      <PrivateRoute
        path="/patientlistbyhealthcamp/:healthcampid?"
        component={PatientListByHealthcamp}
      />
      <PrivateRoute path="/task" component={Task} />
      <PrivateRoute path="/taskdetails/:rowIndex?" component={TaskDetails} />
      <PrivateRoute path="/packagestatus" component={PackageStatus} />
      <PrivateRoute path="/needhelp" component={NeedHelp} />
      <PrivateRoute path="/doctordetails/:id?" component={DoctorDetails} />
      <PrivateRoute path="/addhealthrecord/:patientid?" component={AddHealthRecord} />
      <PrivateRoute path="/informconsent" component={InformConsent} />
      <PrivateRoute path="/casehistory/:patientid?" component={CaseHistory} />
      <PrivateRoute path="/userdetails" component={Userdetails} />
      <PrivateRoute
        path="/filepreparation/:patientid?/:caseid?/:paymentRedirect?"
        component={FilePreparation}
      />
      <PrivateRoute
        path="/patientlistbyorganization/:organizationid?"
        component={OrganizationPatientList}
      />
      <PrivateRoute path="/demographicdetails/:patientid?" component={DemographicDetails} />
      <PrivateRoute path="/masterimporteddata" component={MasterImportedData} />
      <PrivateRoute path="/insurancedetails/:patientid?" component={InsuranceDetails} />
      <PrivateRoute path="/patientdetails/:patientid?" component={PatientDetails} />
      <PrivateRoute
        path="/patientdataobservationlist/:patientid?"
        component={DataObservationList}
      />
      <PrivateRoute path="/patientcaselist/:patientid?" component={CaseListExpanded} />
      <PrivateRoute path="/casedetails/:patientid?/:caseid?/" component={FilePreparation} />
      <PrivateRoute
        path="/observationdetails/:patientid?/:observationtype"
        component={ObservationDetails}
      />
      <PrivateRoute path="/patientnotes/:profileId?" component={PatientNotes} />
      <PrivateRoute path="/ehrdetails/:patientid?" component={EHRDetails} />
      <Route component={NotFound} />
    </Switch>
  );
}

export default ComponentRouter;
