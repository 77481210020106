import { ExistingAppointmentListRes } from "./Model";

export const GET_EXISTING_APPOINTMENT_LIST_LOADING = "GET_EXISTING_APPOINTMENT_LIST_LOADING";
export const GET_EXISTING_APPOINTMENT_LIST_SUCCESS = "GET_EXISTING_APPOINTMENT_LIST_SUCCESS";
export const GET_EXISTING_APPOINTMENT_LIST_FAIL = "GET_EXISTING_APPOINTMENT_LIST_FAIL";
export const GET_EXISTING_APPOINTMENT_LIST_DATA = "GET_EXISTING_APPOINTMENT_LIST_DATA";

export interface ExistingAppointmentListDetails {
  existingAppointmentListRes: ExistingAppointmentListRes;
  loading: boolean;
  errRes: string;
}
export interface ExistingAppointmentListDetailsActionLoading {
  type: typeof GET_EXISTING_APPOINTMENT_LIST_LOADING;
}
export interface ExistingAppointmentListDetailsActionSuccess {
  type: typeof GET_EXISTING_APPOINTMENT_LIST_SUCCESS;
  payload: ExistingAppointmentListRes;
  errRes: string;
}
export interface ExistingAppointmentListDetailsActionFail {
  type: typeof GET_EXISTING_APPOINTMENT_LIST_FAIL;
  payload: ExistingAppointmentListRes;
  errRes: string;
}
interface ExistingAppointmentListDetailsActionAction {
  type: typeof GET_EXISTING_APPOINTMENT_LIST_DATA;
  payload: ExistingAppointmentListRes;
  errRes: string;
}

export type ExistingAppointmentListDetailsActionTypes =
  | ExistingAppointmentListDetailsActionAction
  | ExistingAppointmentListDetailsActionLoading
  | ExistingAppointmentListDetailsActionSuccess
  | ExistingAppointmentListDetailsActionFail;
