import { PaymentRes } from "./Model";
export const GET_PAYMENT_LIST_LOADING = "GET_PAYMENT_LIST_LOADING";
export const GET_PAYMENT_LIST_SUCCESS = "GET_PAYMENT_LIST_SUCCESS";
export const GET_PAYMENT_LIST_FAIL = "GET_PAYMENT_LIST_FAIL";
export const GET_PAYMENT_LIST_DATA = "GET_PAYMENT_LIST_DATA";

export interface PaymentListDetails {
  getPaymentListRes: PaymentRes;
  loading: boolean;
  errRes: string;
}
export interface PaymentListActionLoading {
  type: typeof GET_PAYMENT_LIST_LOADING;
}
export interface PaymentListActionSuccess {
  type: typeof GET_PAYMENT_LIST_SUCCESS;
  payload: PaymentRes;
  errRes: string;
}
export interface PaymentListActionFail {
  type: typeof GET_PAYMENT_LIST_FAIL;
  payload: PaymentRes;
  errRes: string;
}
interface PaymentListActionAction {
  type: typeof GET_PAYMENT_LIST_DATA;
  payload: PaymentRes;
  errRes: string;
}

export type PaymentListActionTypes =
  | PaymentListActionAction
  | PaymentListActionLoading
  | PaymentListActionSuccess
  | PaymentListActionFail;
