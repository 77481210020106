import React, { useState, useEffect } from "react";
import { useParams } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { AppState } from "../../redux/store/Store";
import { getPatientInfortConfigListApi } from "../../redux/getPatientImportConfigList/API";
import { addPatientImportConfigApi } from "../../redux/addPatientImportConfig/API";
import { AddPatientImportConfigDetails } from "../../redux/addPatientImportConfig/Model";
import { EditPatientImportConfigDetails } from "../../redux/editPatientImportConfig/Model";
import { editPatientImportConfigApi } from "../../redux/editPatientImportConfig/API";
import {
  Typography,
  Box,
  Alert,
  Button,
  Grid,
  CardContent,
  CardHeader,
  Card,
  Dialog,
  DialogContent,
  DialogActions,
  DialogTitle,
} from "@mui/material";
import MWLoader from "../../components/MWLoader";
import VisibilityIcon from "@mui/icons-material/Visibility";
import MWExceptionList from "../../components/MWExceptionList";
import { DataGrid, GridCellParams, GridColDef, GridToolbar } from "@mui/x-data-grid";
// import MWTextField from "../../components/MWTextField";
import { t } from "i18next";
import MWTextField from "../../components/MWTextField";

export default function ImportedConfig() {
  const dispatch = useDispatch();
  const [importEmail, setImportEmail] = useState("");
  const [importWhatsapp, setImportWhatsapp] = useState("");
  const [isLoading, setIsLoading] = useState(false);
  const [editConfigId, seteditConfigId] = useState("");

  const [phonenumber] = useState("");
  const loadingMarkup = isLoading ? <MWLoader /> : null;

  const closeerror = () => {
    setError("");
  };
  const [masterConfigList, setMasterConfigList] = useState<any[]>([]);
  const { patientid } = useParams() as {
    patientid: string;
  };

  const [error, setError] = useState("");
  const errorDiv = error ? (
    <Alert onClick={closeerror} severity="error">
      <Typography>{error}</Typography>
    </Alert>
  ) : (
    ""
  );

  const [educationsuccess, setEducationssuccess] = useState("");
  const closeSuccess = () => {
    setEducationssuccess("");
  };

  const successDiv = educationsuccess ? (
    <Alert onClick={closeSuccess} severity="success">
      <Typography>{educationsuccess}</Typography>
    </Alert>
  ) : (
    ""
  );

  const [instituteerror] = useState("");
  const instituteErrordiv = instituteerror ? <Typography>{instituteerror}</Typography> : "";

  // objemail: any, objcreatedate: any, objmodifieddate: any, objphno: any, objwhatsappno:any
  function updateImportConfig(
    id: any,
    objemail: any,
    _objcreatedate: any,
    _objmodifieddate: any,
    _objphno: any,
    objwhatsappno: any,
  ) {
    setModalActive((modalActive) => !modalActive);
    seteditConfigId(id);
    setImportEmail(objemail);
    setImportWhatsapp(objwhatsappno);
    setEducationssuccess("");
    setError("");
  }

  const [modalActive, setModalActive] = useState(false);
  const handleToggle = () => {
    setModalActive((modalActive) => !modalActive);
    seteditConfigId("");
    setImportEmail("");
    setImportWhatsapp("");
    // setPhonenumber('');
    setEducationssuccess("");
    setError("");
  };

  let newAwardBody = {
    patientId: patientid,
    email: importEmail,
    phoneNumber: phonenumber,
    whatsappNo: importWhatsapp,
  } as AddPatientImportConfigDetails;

  // const getconfig = {
  //   patientId: patientid,
  // } as PatientImportConfigListDetails;

  const addSymptomSubmit = () => {
    if (editConfigId) {
      let editBody = Object.assign(newAwardBody, {
        masterConfigId: editConfigId,
      }) as EditPatientImportConfigDetails;
      dispatch(editPatientImportConfigApi(editBody));
    } else {
      dispatch(addPatientImportConfigApi(newAwardBody));
    }
  };
  const addPatientImportConfig = useSelector((state: AppState) => state.addPatientImportConfig);
  useEffect(() => {
    addPatientImportConfig?.loading ? setIsLoading(true) : setIsLoading(false);
  }, [addPatientImportConfig]);

  useEffect(() => {
    if (addPatientImportConfig?.addPatientImportConfigRes.message !== undefined) {
      setEducationssuccess(addPatientImportConfig?.addPatientImportConfigRes.message);
      setError("");
    } else {
      setError(addPatientImportConfig?.errRes);
      setEducationssuccess("");
    }
  }, [addPatientImportConfig]);

  const getPatientImportConfigList = useSelector(
    (state: AppState) => state.getPatientImportConfigList,
  );
  useEffect(() => {
    getPatientImportConfigList?.loading ? setIsLoading(true) : setIsLoading(false);
  }, [getPatientImportConfigList]);

  const editImportConfig = useSelector((state: AppState) => state.editImportConfig);
  useEffect(() => {
    editImportConfig?.loading ? setIsLoading(true) : setIsLoading(false);
  }, [editImportConfig]);

  useEffect(() => {
    if (editImportConfig?.editPatientImportConfigRes?.message !== undefined) {
      setEducationssuccess(editImportConfig?.editPatientImportConfigRes?.message);
      setError("");
      dispatch(getPatientInfortConfigListApi());
    } else {
      setError(editImportConfig?.errRes);
      setEducationssuccess("");
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [editImportConfig]);

  useEffect(() => {
    if (
      getPatientImportConfigList?.getPatientImportConfigListRes?.masterDataImportConfig !==
      undefined
    ) {
      if (
        getPatientImportConfigList?.getPatientImportConfigListRes?.masterDataImportConfig !==
        undefined
      ) {
        let masterConfig =
          getPatientImportConfigList?.getPatientImportConfigListRes?.masterDataImportConfig.map(
            (resData: any) => ({
              id: resData.id,
              objcreatedate: new Date(resData.createDate).toLocaleDateString("en-US", DATE_OPTIONS),
              objmodifieddate: new Date(resData.modifiedDate).toLocaleDateString(
                "en-US",
                DATE_OPTIONS,
              ),
              objemail: resData.email,
              objwhatsappno: resData.whatsappNo,
              objphno: resData.phoneNumber,
            }),
          );
        setMasterConfigList(masterConfig);
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [getPatientImportConfigList]);

  useEffect(() => {
    // get call here....
    dispatch(getPatientInfortConfigListApi());
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const DATE_OPTIONS = {
    weekday: "short",
    year: "numeric",
    month: "short",
    day: "numeric",
  } as const;
  const columns: GridColDef[] = [
    { field: "objemail", headerName: "Email", flex: 1 },
    { field: "objcreatedate", headerName: "Create Date", flex: 1 },
    { field: "objmodifieddate", headerName: "Modified date", flex: 1 },
    { field: "objphno", headerName: "Phone Number", flex: 1 },
    { field: "edit", headerName: "Edit", flex: 1 },
    {
      field: "id",
      headerName: "View",
      width: 150,
      renderCell: (params: GridCellParams) => (
        <Button
          startIcon={<VisibilityIcon />}
          variant="outlined"
          onClick={() => {
            updateImportConfig(
              params.row.id,
              params.row.objemail,
              params.row.objcreatedate,
              params.row.objmodifieddate,
              params.row.objphno,
              params.row.objwhatsappno,
            );
          }}
        >
          {t("appointment.view", { ns: ["home"] })}
        </Button>
      ),
    },
  ];

  // call Store
  const importConfigStoreValue = useSelector((state: AppState) => state.editImportConfig);
  console.log(importConfigStoreValue);
  const masterImportConfigList = (
    <Box sx={{ maxHeight: "340px", overflow: "auto" }}>
      <CardContent>
        {masterConfigList && masterConfigList.length ? (
          <DataGrid
            rows={masterConfigList}
            columns={columns}
            pageSize={10}
            hideFooter
            hideFooterPagination
            disableSelectionOnClick
            experimentalFeatures={{ newEditingApi: true }}
            autoHeight
            components={{ Toolbar: GridToolbar }}
            componentsProps={{
              toolbar: {
                csvOptions: { disableToolbarButton: true },
                printOptions: { disableToolbarButton: true },
                showQuickFilter: true,
                quickFilterProps: { debounceMs: 250 },
              },
            }}
            disableColumnFilter
            disableColumnSelector
            disableDensitySelector
          />
        ) : (
          <MWExceptionList />
        )}
      </CardContent>
    </Box>
  );

  const symptomsAddInfo = (
    <div>
      {loadingMarkup}
      <Card>
        <CardHeader
          title={
            <Grid container direction="row" justifyContent="space-between" alignItems="flex-start">
              <Grid item>
                <Typography gutterBottom variant="h6" sx={{ fontWeight: "bold" }}>
                  {t("impConfigPage.impCon", { ns: ["home"] })}
                </Typography>
              </Grid>
              <Grid item>
                <Typography gutterBottom variant="h6" sx={{ fontWeight: "bold" }}>
                  <Button variant="contained" onClick={handleToggle}>
                    {t("impConfigPage.addConf", { ns: ["home"] })}
                  </Button>
                </Typography>
              </Grid>
            </Grid>
          }
        ></CardHeader>
        {masterImportConfigList}
      </Card>
      <br />
      <br />
      <Dialog open={modalActive} onClose={handleToggle} fullWidth>
        <DialogTitle>
          {editConfigId
            ? `${t("impConfigPage.updateImpCon", { ns: ["home"] })}`
            : `${t("impConfigPage.addImpCon", { ns: ["home"] })}`}
        </DialogTitle>
        <DialogContent>
          <Grid
            container
            direction="column"
            justifyContent="space-between"
            alignItems="stretch"
            spacing={2}
            p={2}
          >
            <Grid item>
              <MWTextField
                fullWidth
                value={importEmail}
                onChange={(event) => setImportEmail(event?.target.value)}
                label={t("impConfigPage.emailImpPat", { ns: ["home"] })}
                type="email"
                placeholder={t("impConfigPage.enterEmailAdd", { ns: ["home"] })!}
                id="Emailname"
              />
            </Grid>
            <Typography>{instituteErrordiv}</Typography>
            <Grid item>
              <MWTextField
                fullWidth
                value={importWhatsapp}
                onChange={(event) => setImportWhatsapp(event.target.value)}
                label={t("impConfigPage.wpImpPatData", { ns: ["home"] })}
                type="number"
                placeholder={t("impConfigPage.enterWp", { ns: ["home"] })!}
              />
            </Grid>
            <div>{successDiv}</div>
            <div>{errorDiv}</div>
          </Grid>
        </DialogContent>
        <DialogActions>
          <Button variant="contained" onClick={addSymptomSubmit}>
            {t("needhelppage.submit", { ns: ["home"] })!}
          </Button>
        </DialogActions>
      </Dialog>
    </div>
  );
  return <Box>{symptomsAddInfo}</Box>;
}
