import React from "react";
import { Tab, Box, Grid, Paper, Tabs, Typography } from "@mui/material";
import MWLoader from "../../../components/MWLoader";
import { useSelector } from "react-redux";
import { AppState } from "../../../redux/store/Store";
import CaregiverVisit from "./CaregiverVisit";
import DoctorVisitByCaregiver from "./DoctorVisitByCaregiver";
import MedReconcilation from "./MedReconcilation";
import FoodHabit from "./FoodHabit";
import ExerciseReview from "./ExerciseReview";

interface TabPanelProps {
  children?: React.ReactNode;
  index: any;
  value: any;
}
function TabPanel(props: TabPanelProps) {
  const { children, value, index, ...other } = props;

  return (
    <Box
      role="tabpanel"
      hidden={value !== index}
      id={`vertical-tabpanel-${index}`}
      aria-labelledby={`vertical-tab-${index}`}
      {...other}
    >
      {value === index && (
        <Box pr={2}>
          <Typography>{children}</Typography>
        </Box>
      )}
    </Box>
  );
}

function a11yProps(index: any) {
  return {
    id: `vertical-tab-${index}`,
    "aria-controls": `vertical-tabpanel-${index}`,
  };
}

export default function CareGiverPackageStatusSabTabDetails() {
  // const [selected, setSelected] = React.useState("1");
  const [tabValue, setTabValue] = React.useState(0);
  const handleTabChange = (_event: React.ChangeEvent<{}>, newValue: number) => {
    setTabValue(newValue);
    // localStorage.setItem("selctedprofiletab", String(newValue));
    window.scrollTo(0, 0);
  };
  const loadingMarkup = <MWLoader />;
  const getUserDetailsList = useSelector((state: AppState) => state.getUserDetailsList);

  const ActualPageMarkup = (
    <Grid container direction="row" justifyContent="space-between" alignItems="flex-start">
      {/* Vertical Tab List */}
      <Grid
        item
        sx={{
          width: "250px",
        }}
      >
        <Paper
          variant="outlined"
          sx={{
            backgroundColor: "#D1CBF4",
            width: "230px",
            margin: "10px 0px",
          }}
        >
          <Tabs
            orientation="vertical"
            variant="scrollable"
            value={tabValue}
            onChange={handleTabChange}
            aria-label="Patient Package details"
            indicatorColor="secondary"
            TabIndicatorProps={{
              sx: {
                backgroundColor: "#0000FF",
                right: "0",
              },
            }}
            sx={{
              backgroundColor: "#D1CBF4",
              alignItems: "left",
              textTransform: "none",
              fontSize: "1.5rem",
              borderRadius: "4px",
            }}
          >
            <Tab label="Caregiver Visit" {...a11yProps(0)} />
            <Tab label="Doctor Visit by CareGiver" {...a11yProps(1)} />
            <Tab label="Review Health Record" {...a11yProps(2)} />
            <Tab label="Review food habits" {...a11yProps(3)} />
            <Tab label="Review Exercise" {...a11yProps(4)} />
          </Tabs>
        </Paper>
      </Grid>
      {/* Vertical Tab Content */}
      <Grid item xs>
        <Box>
          <Grid container direction="row" justifyContent="flex-end" alignItems="center"></Grid>
          <Box>
            <TabPanel value={tabValue} index={0}>
              <CaregiverVisit />
            </TabPanel>
            <TabPanel value={tabValue} index={1}>
              <DoctorVisitByCaregiver />
            </TabPanel>

            <TabPanel value={tabValue} index={2}>
              <MedReconcilation />
            </TabPanel>

            <TabPanel value={tabValue} index={3}>
              <FoodHabit />
            </TabPanel>

            <TabPanel value={tabValue} index={4}>
              <ExerciseReview />
            </TabPanel>
          </Box>
        </Box>
      </Grid>
    </Grid>
  );
  return (
    <Box>
      {getUserDetailsList.loading ? loadingMarkup : ""}
      <Typography variant="h5" sx={{ mb: 2 }}>
        Package Usage
      </Typography>
      {ActualPageMarkup}
    </Box>
  );
}
