import {
  SexualOrientationListDetails,
  GetSexualOrientationListActionTypes,
  GET_SEXUAL_ORIENTATION_LIST_FAIL,
  GET_SEXUAL_ORIENTATION_LIST_LOADING,
  GET_SEXUAL_ORIENTATION_LIST_SUCCESS,
} from "./ActionTypes";
import { GetSexualOrientationListRes } from "./Model";

const initialStateGetPosts: SexualOrientationListDetails = {
  loading: false,
  getSexualOrientationListRes: {} as GetSexualOrientationListRes,
  errRes: "",
};
export const getSexualOrientationListReducer = (
  state = initialStateGetPosts,
  action: GetSexualOrientationListActionTypes,
): SexualOrientationListDetails => {
  switch (action.type) {
    case GET_SEXUAL_ORIENTATION_LIST_SUCCESS:
      return {
        ...state,
        loading: false,
        getSexualOrientationListRes: action.payload,
        errRes: "",
      };
    case GET_SEXUAL_ORIENTATION_LIST_LOADING:
      return {
        ...state,
        loading: true,
        getSexualOrientationListRes: {} as GetSexualOrientationListRes,
      };
    case GET_SEXUAL_ORIENTATION_LIST_FAIL:
      return {
        ...state,
        getSexualOrientationListRes: {} as GetSexualOrientationListRes,
        loading: false,
        errRes: action.errRes,
      };
    default:
      return state;
  }
};
