import React from "react";
import PersonAddAltIcon from "@mui/icons-material/PersonAddAlt";
import PeopleIcon from "@mui/icons-material/People";
import AddHomeWorkIcon from "@mui/icons-material/AddHomeWork";
import PersonSearchIcon from "@mui/icons-material/PersonSearch";
import AccountBalanceWalletIcon from "@mui/icons-material/AccountBalanceWallet";
import HelpIcon from "@mui/icons-material/Help";
import LogoutIcon from "@mui/icons-material/Logout";
import ImportExportIcon from "@mui/icons-material/ImportExport";
import TaskIcon from "@mui/icons-material/Task";
import AccountCircleIcon from "@mui/icons-material/AccountCircle";
export const DrawerList = [
  {
    name: "patient",
    path: "/demographicdetails",
    icon: <PersonAddAltIcon style={{ color: "#6faeff", fontWeight: "bold" }} />,
  },
  {
    name: "mypatient",
    path: "/dashboard",
    icon: <PeopleIcon style={{ color: "#6faeff", fontWeight: "bold" }} />,
  },
  {
    name: "appoin",
    path: "/appoinmentDetails",
    icon: <AddHomeWorkIcon style={{ color: "#6faeff", fontWeight: "bold" }} />,
  },
  {
    name: "finddoctor",
    path: "/doctor",
    icon: <PersonSearchIcon style={{ color: "#6faeff", fontWeight: "bold" }} />,
  },
  {
    name: "payment",
    path: "/packagestatus",
    icon: <AccountBalanceWalletIcon style={{ color: "#6faeff", fontWeight: "bold" }} />,
  },
  {
    name: "importeddata",
    path: "/masterimporteddata",
    icon: <ImportExportIcon style={{ color: "#6faeff", fontWeight: "bold" }} />,
  },
  {
    name: "task",
    path: "/task",
    icon: <TaskIcon style={{ color: "#6faeff", fontWeight: "bold" }} />,
  },
  // {
  //   name: "callus",
  //   icon: <CallIcon style={{ color: "#6faeff", fontWeight: "bold" }} />,
  //   path: "/",
  // },
  {
    name: "help",
    path: "/needhelp",
    icon: <HelpIcon style={{ color: "#6faeff", fontWeight: "bold" }} />,
  },
  {
    name: "profile",
    path: "/userdetails",
    icon: <AccountCircleIcon style={{ color: "#6faeff", fontWeight: "bold" }} />,
  },
  {
    name: "logout",
    path: "/signin",
    icon: <LogoutIcon style={{ color: "#6faeff", fontWeight: "bold" }} />,
  },
];
