import { GetSelectedDoctorTeamTypeRes } from "./Model";

export const GET_DOCTOR_TEAM_TYPE_LIST_LOADING = "GET_DOCTOR_TEAM_TYPE_LIST_LOADING";
export const GET_DOCTOR_TEAM_TYPE_LIST_SUCCESS = "GET_DOCTOR_TEAM_TYPE_LIST_SUCCESS";
export const GET_DOCTOR_TEAM_TYPE_LIST_FAIL = "GET_DOCTOR_TEAM_TYPE_LIST_FAIL";
export const GET_DOCTOR_TEAM_TYPE_LIST_DATA = "GET_DOCTOR_TEAM_TYPE_LIST_DATA";

export interface SelectedDoctorTeamTypeDetails {
  getSelectedDoctorTeamTypeRes: GetSelectedDoctorTeamTypeRes;
  loading: boolean;
  errRes: string;
}
export interface GetSelectedDoctorTeamTypeActionLoading {
  type: typeof GET_DOCTOR_TEAM_TYPE_LIST_LOADING;
}
export interface GetSelectedDoctorTeamTypeActionSuccess {
  type: typeof GET_DOCTOR_TEAM_TYPE_LIST_SUCCESS;
  payload: GetSelectedDoctorTeamTypeRes;
  errRes: string;
}
export interface GetSelectedDoctorTeamTypeActionFail {
  type: typeof GET_DOCTOR_TEAM_TYPE_LIST_FAIL;
  payload: GetSelectedDoctorTeamTypeRes;
  errRes: string;
}
interface GetSelectedDoctorTeamTypeActionAction {
  type: typeof GET_DOCTOR_TEAM_TYPE_LIST_DATA;
  payload: GetSelectedDoctorTeamTypeRes;
  errRes: string;
}

export type GetSelectedDoctorTeamTypeActionTypes =
  | GetSelectedDoctorTeamTypeActionAction
  | GetSelectedDoctorTeamTypeActionLoading
  | GetSelectedDoctorTeamTypeActionSuccess
  | GetSelectedDoctorTeamTypeActionFail;
