import React, { useState, useCallback, useEffect } from "react";
import { useParams } from "react-router-dom";
import VisibilityIcon from "@mui/icons-material/Visibility";
import DescriptionIcon from "@mui/icons-material/Description";
import EditIcon from "@mui/icons-material/Edit";
import { useDispatch, useSelector } from "react-redux";
import { getMeetingList } from "../../redux/FilePreparation/ZoomMetting/API";
import { AppState } from "../../redux/store/Store";
import CloseIcon from "@mui/icons-material/Close";
import { deleteFilePreparationDocument } from "../../redux/FilePreparation/ApiCall";
import { addAskQuestionSubmit } from "../../redux/FilePreparation/askQuestionSubmit/API";
import { addQuestionSubmitDetails } from "../../redux/FilePreparation/askQuestionSubmit/Model";
import { getQuestionListDetails } from "../../redux/FilePreparation/getQuestionListForPatient/Model";
import { getQuestionListForPatient } from "../../redux/FilePreparation/getQuestionListForPatient/API";
import { answerQuestionSubmit } from "../../redux/FilePreparation/answerQuestion/API";
import { answerQuestionDetails } from "../../redux/FilePreparation/answerQuestion/Model";
import { t } from "i18next";
import {
  Alert,
  AlertTitle,
  Box,
  Button,
  Card,
  CardContent,
  CardHeader,
  Container,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Grid,
  IconButton,
  Snackbar,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Typography,
  useMediaQuery,
} from "@mui/material";
import { useTheme } from "@mui/material/styles";
import MWLoader from "../../components/MWLoader";
import MWTextField from "../../components/MWTextField";
import PrescriptionOrder from "./PrescriptionOrder";

export default function DoctorPatientInteraction() {
  const theme = useTheme();
  const fullScreen = useMediaQuery(theme.breakpoints.down("md"));

  const dispatch = useDispatch();
  const { caseid } = useParams() as {
    caseid: string;
  };
  const [patientDetailsId] = useState("");

  const [isLoading, setIsLoading] = useState(false);
  const loadingMarkup = isLoading ? <MWLoader /> : null;

  // call Store
  const zoomMeeting = useSelector((state: AppState) => state.meetingList);
  const getQuestListValue = useSelector((state: AppState) => state.questionList);
  const questionListForPatient = getQuestListValue?.getQuestionListRes?.questionList;
  const [joinUrl, setJoinUrl] = useState("");
  //const [imageId, setImageId] = useState("");

  const closeDeletePrescriptionModalSucessError = () => {
    setDeletePrescriptionSuccess("");
  };

  const [deletePrescriptionSuccess, setDeletePrescriptionSuccess] = useState("");
  const successDeleteProblemModalDiv = deletePrescriptionSuccess ? (
    <Alert
      severity="success"
      onClose={() => {
        closeDeletePrescriptionModalSucessError();
      }}
    >
      <Typography>{deletePrescriptionSuccess}</Typography>
    </Alert>
  ) : (
    ""
  );

  // Question section variable description

  const [questionImageInfo] = useState("");

  const [questionDescription, setQuestionDescription] = useState("");
  const handleQuestionDescriptionChange = useCallback((newValue: any) => {
    setQuestionDescription(newValue);
  }, []);

  const [questionDescriptionError, setQuestionDescriptionError] = useState("");
  const questionDescriptionErrordiv = questionDescriptionError ? (
    <Typography color="error">{questionDescriptionError}</Typography>
  ) : (
    ""
  );

  const closeQuestionError = () => {
    setQuestionnError("");
  };

  const [QuestionError, setQuestionnError] = useState("");
  const questionErrorDiv = QuestionError ? (
    <Alert
      severity="error"
      onClose={() => {
        closeQuestionError();
      }}
    >
      <Typography>{QuestionError}</Typography>
    </Alert>
  ) : (
    ""
  );

  const closeQuestionSuccess = () => {
    //setPrescriptionSuccess("");
    setQuestionSuccess("");
  };
  const [questionSuccess, setQuestionSuccess] = useState("");
  const questionSuccessDiv = questionSuccess ? (
    <Alert
      severity="success"
      onClose={() => {
        closeQuestionSuccess();
      }}
    >
      <Typography>{questionSuccess}</Typography>
    </Alert>
  ) : (
    ""
  );

  const [questionModalActive, setQuestionModalActive] = useState(false);
  const handleQuestionModalToggle = () => {
    setQuestionModalActive((questionModalActive) => !questionModalActive);
    setQuestionDescription("");
    setQuestionDescriptionError("");
    setQuestionSuccess("");
    setQuestionnError("");
    dispatch(getQuestionListForPatient(getAskQuestionBody));
  };

  const [questionDocViwerActive, setQuestionDocViwerActive] = useState(false);
  const handleQuestionDocViewerChange = useCallback(
    () => setQuestionDocViwerActive(!questionDocViwerActive),
    [questionDocViwerActive],
  );

  const [deleteQuestionModalActive, setDeleteQuestionModalActive] = useState(false);
  const handleDeleteQuestionModalChange = useCallback(
    () => setDeleteQuestionModalActive(!deleteQuestionModalActive),
    [deleteQuestionModalActive],
  );

  const closeDeleteRiskModalSucessError = () => {
    setDeleteQuestionModalActive((deleteQuestionModalActive) => !deleteQuestionModalActive);
    setDeleteQuestionSuccess("");
  };

  const [deleteQuestionSuccess, setDeleteQuestionSuccess] = useState("");
  const deleteQuestionSuccessDiv = deleteQuestionSuccess ? (
    <Alert
      severity="success"
      onClose={() => {
        closeDeleteRiskModalSucessError();
      }}
    >
      <Typography>{deleteQuestionSuccess}</Typography>
    </Alert>
  ) : (
    ""
  );

  const [toastSuccessMessage, setToastSuccessMessage] = useState("");
  const [toastSuccessActive, setToastSuccessActive] = useState(false);
  const toggleToastSuccessActive = useCallback(
    () => setToastSuccessActive((toastSuccessActive) => !toastSuccessActive),
    [],
  );

  const toastSuccessMarkup = toastSuccessActive ? (
    <Snackbar open={toastSuccessActive} autoHideDuration={6000} onClose={toggleToastSuccessActive}>
      <Alert onClose={toggleToastSuccessActive} severity="success" sx={{ width: "100%" }}>
        {toastSuccessMessage}
      </Alert>
    </Snackbar>
  ) : null;

  const [askedQuestionListValue, setAskedQuestionListValue] = useState<any[]>([]);
  const truncate = (str: string) => {
    return str.length > 10 ? str.substring(0, 20) + "..." : str;
  };

  const [question, setQuestion] = useState("");
  const [questionId, setQuestionId] = useState("");
  const [answer, setAnswer] = useState("");
  const [answerquestion, setAnswerquestion] = useState("");
  const handleAnswerQuestionChange = useCallback(
    (newValue: any) => setAnswerquestion(newValue),
    [],
  );

  const [answerModalActive, setAnswerModalActive] = useState(false);
  const handleAnswerModalActive = () => {
    setAnswerModalActive((answerModalActive) => !answerModalActive);
    setAnswerquestion("");
    setAsnwerSubmitError("");
    setAnswerSubmitSuccess("");
    dispatch(getQuestionListForPatient(getAskQuestionBody));
  };

  const [viewAnswerModalActive, setViewAnswerModalActive] = useState(false);
  const handleViewAnswerModalActive = () => {
    setViewAnswerModalActive((viewAnswerModalActive) => !viewAnswerModalActive);
    setAnswerquestion("");
    setAsnwerSubmitError("");
    setAnswerSubmitSuccess("");
  };

  const [telehealthBannerTitle] = useState(`${t("orderPresPage.telehealth", { ns: ["home"] })!}`);
  const [telehealthBannerBody] = useState(`${t("orderPresPage.accessLink", { ns: ["home"] })!}`);

  function answerById(questionID: any, questionText: any) {
    handleAnswerModalActive();
    setQuestionId(questionID);
    setQuestion(questionText);
  }

  function viewAnswerById(questionID: any, questionText: any, fullAnswer: any) {
    handleViewAnswerModalActive();
    setQuestionId(questionID);
    setQuestion(questionText);
    setAnswer(fullAnswer);
  }

  const closeAnswerSubmitError = () => {
    setAsnwerSubmitError("");
  };

  const [asnwerSubmitError, setAsnwerSubmitError] = useState("");
  const errorAnswerSubmitDiv = asnwerSubmitError ? (
    <Alert
      severity="error"
      onClose={() => {
        closeAnswerSubmitError();
      }}
    >
      <Typography>{asnwerSubmitError}</Typography>
    </Alert>
  ) : (
    ""
  );

  const [answerSubmitSuccess, setAnswerSubmitSuccess] = useState("");
  const closeAnswerSubmitSuccess = () => {
    setAnswerSubmitSuccess("");
  };
  const successAnswerSubmitDiv = answerSubmitSuccess ? (
    <Alert
      severity="success"
      onClose={() => {
        closeAnswerSubmitSuccess();
      }}
    >
      <Typography>{answerSubmitSuccess}</Typography>
    </Alert>
  ) : (
    ""
  );
  const [, setIsEdit] = useState(true);
  // Question Submit API body
  const questionBody = {
    caseId: caseid,
    questionText: questionDescription,
    questionType: "PatientToDoctor",
  } as addQuestionSubmitDetails;

  // Question Submit API
  const questionSubmit = () => {
    if (questionDescription === "") {
      setQuestionDescriptionError(`${t("orderPresPage.plsAddQues", { ns: ["home"] })!}`);
    } else {
      dispatch(addAskQuestionSubmit(questionBody));
      setQuestionModalActive((questionModalActive) => !questionModalActive);
      dispatch(getQuestionListForPatient(getAskQuestionBody));
    }
  };

  // Delete body....
  const deleteQuestionAPIBody = {
    caseId: caseid,
    patientDetailId: patientDetailsId,
  };
  // delete question api call here ....
  const DeleteQuestionSubmit = () => {
    dispatch(deleteFilePreparationDocument(deleteQuestionAPIBody));
  };

  // Get Question API Body
  const getAskQuestionBody = {
    caseId: caseid,
  } as getQuestionListDetails;

  // Get Question Answer List
  useEffect(() => {
    let askQuestionListValue: any = [];
    if (questionListForPatient !== undefined) {
      for (var i = 0; i < questionListForPatient.length; i++) {
        let questionID = questionListForPatient[i].id == null ? "" : questionListForPatient[i].id;
        let questionText =
          questionListForPatient[i].questionText === null ||
          questionListForPatient[i].questionText === null
            ? ""
            : questionListForPatient[i].questionText;
        let questionAskedBy =
          questionListForPatient[i].askedBy === null || questionListForPatient[i].askedBy === ""
            ? ""
            : questionListForPatient[i].askedBy;
        let answer = questionListForPatient[i].hasOwnProperty("answerText")
          ? questionListForPatient[i].questionText === null ||
            questionListForPatient[i].answerText === ""
            ? ""
            : truncate(questionListForPatient[i].answerText)
          : "";
        let fullAnswer = questionListForPatient[i].hasOwnProperty("answerText")
          ? questionListForPatient[i].answerText === null ||
            questionListForPatient[i].answerText === ""
            ? ""
            : questionListForPatient[i].answerText
          : "";
        let answerBy =
          questionListForPatient[i].answeredBy === null ||
          questionListForPatient[i].answeredBy === ""
            ? ""
            : questionListForPatient[i].answeredBy;
        let questionStatus =
          questionListForPatient[i].questionStatus === null ||
          questionListForPatient[i].questionStatus === ""
            ? ""
            : questionListForPatient[i].questionStatus;
        //setPatientDetailsId(questionID);
        askQuestionListValue.push([
          questionText,
          questionAskedBy,
          answer,
          answerBy,
          questionStatus,
          <Button
            variant="outlined"
            startIcon={<EditIcon />}
            onClick={() => answerById(questionID, questionText)}
          >
            {t("orderPresPage.ans", { ns: ["home"] })!}
          </Button>,
          <Button
            variant="outlined"
            startIcon={<VisibilityIcon />}
            onClick={() => viewAnswerById(questionID, questionText, fullAnswer)}
          >
            {t("orderPresPage.viewAns", { ns: ["home"] })!}
          </Button>,
        ]);
      }
      setAskedQuestionListValue(askQuestionListValue);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [questionListForPatient]);

  // Answer Submit Body
  const answerSubmitBody = {
    questionId: String(questionId),
    caseId: caseid,
    type: "patient",
    answerText: answerquestion,
  } as answerQuestionDetails;

  // Submit Answer by Question API
  const answerSubmit = () => {
    dispatch(answerQuestionSubmit(answerSubmitBody));
  };

  // Open Join Meeting URL in New Page
  const joinMeeting = () => {
    window.open(joinUrl);
  };

  useEffect(() => {
    window.scrollTo(0, 0);
    //getAskedQuestionList();
    dispatch(getQuestionListForPatient(getAskQuestionBody));
    dispatch(getMeetingList(caseid));
    //dispatch(getChiefComplaintList(getprescriptionBody));
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    if (zoomMeeting.sharedPatientDetailsRes.status === 1) {
      setIsLoading(false);
      setIsEdit(false);
      setToastSuccessActive(true);
      setToastSuccessMessage(zoomMeeting.sharedPatientDetailsRes.message);
      setJoinUrl(zoomMeeting.sharedPatientDetailsRes.joinUrl);
    } else if (zoomMeeting.sharedPatientDetailsRes.status === -1) {
      setIsLoading(false);
      setIsEdit(true);
      setToastSuccessActive(true);
      setToastSuccessMessage(zoomMeeting.sharedPatientDetailsRes.message);
    }
  }, [zoomMeeting]);

  const telehealthBannerMarkup = telehealthBannerBody ? (
    <Box p={1}>
      <Alert
        severity="info"
        variant="outlined"
        onClose={() => {
          closeAnswerSubmitSuccess();
        }}
        action={
          <Button variant="text" onClick={() => joinMeeting}>
            {t("orderPresPage.joinMeet", { ns: ["home"] })!}
          </Button>
        }
      >
        <AlertTitle>
          <strong>{telehealthBannerTitle}</strong>
        </AlertTitle>
        <Typography>{telehealthBannerBody}</Typography>
      </Alert>
    </Box>
  ) : (
    ""
  );

  // Question Table
  const questionTableBody = (
    <Box p={1}>
      <Card>
        <CardHeader
          title={
            <Grid container direction="row" justifyContent="space-between" alignItems="flex-start">
              <Grid item>{t("orderPresPage.qA", { ns: ["home"] })}</Grid>
              <Grid item>
                <Button variant="contained" onClick={() => handleQuestionModalToggle()}>
                  {t("orderPresPage.askQues", { ns: ["home"] })!}
                </Button>
              </Grid>
            </Grid>
          }
        ></CardHeader>
        <CardContent>
          {askedQuestionListValue && askedQuestionListValue.length ? (
            <TableContainer>
              <Table size="small" aria-label="a dense table">
                <TableHead>
                  <TableRow>
                    <TableCell>{t("appointment.desc", { ns: ["home"] })}</TableCell>
                    <TableCell>{t("orderPresPage.askby", { ns: ["home"] })}</TableCell>
                    <TableCell>{t("orderPresPage.ans", { ns: ["home"] })}</TableCell>
                    <TableCell>{t("orderPresPage.ansby", { ns: ["home"] })}</TableCell>
                    <TableCell>{t("appointment.status", { ns: ["home"] })}</TableCell>
                    <TableCell>{t("orderPresPage.subAns", { ns: ["home"] })}</TableCell>
                    <TableCell>{t("orderPresPage.viewFullAns", { ns: ["home"] })}</TableCell>
                  </TableRow>
                </TableHead>
                <TableBody>
                  {askedQuestionListValue.map((row: any) => (
                    <TableRow
                      key={row.description}
                      sx={{
                        "&:last-child td, &:last-child th": { border: 0 },
                      }}
                    >
                      <TableCell align="left">{row[0]}</TableCell>
                      <TableCell align="left">{row[1]}</TableCell>
                      <TableCell align="left">{row[2]}</TableCell>
                      <TableCell align="left">{row[3]}</TableCell>
                      <TableCell align="left">{row[4]}</TableCell>
                      <TableCell align="left">{row[5]}</TableCell>
                      <TableCell align="left">{row[6]}</TableCell>
                    </TableRow>
                  ))}
                </TableBody>
              </Table>
            </TableContainer>
          ) : (
            <Typography>
              <DescriptionIcon />
              &nbsp;&nbsp;
              {t("orderPresPage.noQuesDetailAvail", {
                ns: ["home"],
              })}
            </Typography>
          )}
        </CardContent>
      </Card>
    </Box>
  );
  const prescriptonorder = (
    <Box>
      <PrescriptionOrder />
    </Box>
  );

  // Questionn Modal Body
  const questionModalBody = (
    <Dialog open={questionModalActive} onClose={handleQuestionModalToggle} fullWidth>
      <DialogTitle>
        <Grid container direction="row" justifyContent="space-between" alignItems="flex-start">
          <Grid item>
            <Typography variant="h6">{t("orderPresPage.addQues", { ns: ["home"] })}</Typography>
          </Grid>
          <Grid item>
            <IconButton
              aria-label="close"
              onClick={handleQuestionModalToggle}
              sx={{
                position: "absolute",
                right: 8,
                top: 8,
                color: (theme) => theme.palette.grey[500],
              }}
            >
              <CloseIcon />
            </IconButton>
          </Grid>
        </Grid>
      </DialogTitle>

      <DialogContent>
        <Container maxWidth="xl">
          <Box pt={2}>
            <MWTextField
              label={t("orderPresPage.ques", { ns: ["home"] })}
              required
              placeholder={t("orderPresPage.enterQues", { ns: ["home"] })!}
              value={questionDescription}
              onChange={(e) => handleQuestionDescriptionChange(e.target.value)}
              multiline
              maxRows={4}
              id="questionDescriptionID"
              fullWidth
            />
            <Typography id="questionDescriptionID">{questionDescriptionErrordiv}</Typography>
          </Box>
          <Box>{questionSuccessDiv}</Box>
          <Box>{questionErrorDiv}</Box>
        </Container>
      </DialogContent>
      <DialogActions>
        <Button onClick={questionSubmit} variant="contained">
          {t("needhelppage.submit", { ns: ["home"] })}
        </Button>
      </DialogActions>
      <Box>{successDeleteProblemModalDiv}</Box>
    </Dialog>
  );

  const QuestionDocViewerComponent = (
    <Dialog open={questionDocViwerActive} fullWidth>
      <DialogTitle>
        <Grid container direction="row" justifyContent="flex-end" alignItems="flex-start">
          <Grid item>
            <IconButton onClick={handleQuestionDocViewerChange}>
              <CloseIcon />
            </IconButton>
          </Grid>
        </Grid>
      </DialogTitle>
      <DialogContent>
        <Typography>
          <iframe
            src={questionImageInfo}
            title="Small document"
            width="100%"
            height="700"
            frameBorder="0"
          >
            This is an embedded{" "}
            <a target="_blank" href="http://office.com" rel="noopener noreferrer">
              Microsoft Office
            </a>{" "}
            document, powered by{" "}
            <a target="_blank" href="http://office.com/webapps" rel="noopener noreferrer">
              Office Online
            </a>
            .
          </iframe>
        </Typography>
      </DialogContent>
    </Dialog>
  );
  // Delete Question Modal section ....
  const deleteQuestionBody = (
    <Dialog
      open={deleteQuestionModalActive}
      onClose={handleDeleteQuestionModalChange}
      fullScreen={fullScreen}
    >
      <DialogTitle>
        <Grid container direction="row" justifyContent="space-between" alignItems="flex-start">
          <Grid item>
            <Typography variant="h6">{t("orderPresPage.delSecOp", { ns: ["home"] })}</Typography>
          </Grid>
          <Grid item>
            <IconButton
              aria-label="close"
              onClick={handleDeleteQuestionModalChange}
              sx={{
                position: "absolute",
                right: 8,
                top: 8,
                color: (theme) => theme.palette.grey[500],
              }}
            >
              <CloseIcon />
            </IconButton>
          </Grid>
        </Grid>
      </DialogTitle>

      <DialogContent>
        <Typography variant="subtitle1">
          {t("orderPresPage.qSecOpDetail", { ns: ["home"] })}
        </Typography>
      </DialogContent>
      <DialogActions>
        <Button onClick={DeleteQuestionSubmit} variant="outlined">
          {t("common.yes", { ns: ["home"] })}
        </Button>
        <Button onClick={handleDeleteQuestionModalChange} variant="contained">
          {t("common.no", { ns: ["home"] })}
        </Button>
      </DialogActions>
      <Box>{successDeleteProblemModalDiv}</Box>
    </Dialog>
  );

  // Add Answer Submit section
  const answerQuestionComponent = (
    <Box>
      <Dialog open={answerModalActive} onClose={handleAnswerModalActive} fullWidth>
        <DialogTitle>
          <Grid container direction="row" justifyContent="space-between" alignItems="flex-start">
            <Grid item>
              <Typography variant="h6">{t("orderPresPage.addAns", { ns: ["home"] })}</Typography>
            </Grid>
            <Grid item>
              <IconButton
                aria-label="close"
                onClick={handleAnswerModalActive}
                sx={{
                  position: "absolute",
                  right: 8,
                  top: 8,
                  color: (theme) => theme.palette.grey[500],
                }}
              >
                <CloseIcon />
              </IconButton>
            </Grid>
          </Grid>
        </DialogTitle>

        <DialogContent>
          <Typography sx={{ fontWeight: "bold" }}>
            {" "}
            {t("orderPresPage.ques", { ns: ["home"] })}: {question}
          </Typography>
          <MWTextField
            label={t("orderPresPage.ans", { ns: ["home"] })}
            value={answerquestion}
            onChange={(e) => handleAnswerQuestionChange(e.target.value)}
            multiline
            maxRows={4}
            fullWidth
          />
          <br />
          <div>{errorAnswerSubmitDiv}</div>
          <div>{successAnswerSubmitDiv}</div>
        </DialogContent>
        <DialogActions>
          <Button onClick={answerSubmit} variant="contained">
            {t("needhelppage.submit", { ns: ["home"] })}
          </Button>
        </DialogActions>
        <Box>{deleteQuestionSuccessDiv}</Box>
      </Dialog>
    </Box>
  );

  const viewAnswerComponent = (
    <Box>
      <Dialog open={viewAnswerModalActive} onClose={handleViewAnswerModalActive} fullWidth>
        <DialogTitle>
          <Grid container direction="row" justifyContent="space-between" alignItems="flex-start">
            <Grid item>
              <Typography variant="h6">{t("orderPresPage.viewAns", { ns: ["home"] })}</Typography>
            </Grid>
            <Grid item>
              <IconButton
                aria-label="close"
                onClick={handleViewAnswerModalActive}
                sx={{
                  position: "absolute",
                  right: 8,
                  top: 8,
                  color: (theme) => theme.palette.grey[500],
                }}
              >
                <CloseIcon />
              </IconButton>
            </Grid>
          </Grid>
        </DialogTitle>

        <DialogContent>
          <Typography sx={{ fontWeight: "bold" }}>
            {" "}
            {t("orderPresPage.ques", { ns: ["home"] })}: {question}
          </Typography>
          <MWTextField
            label={t("orderPresPage.ans", { ns: ["home"] })}
            value={answer}
            onChange={(e) => handleAnswerQuestionChange(e.target.value)}
            multiline
            maxRows={4}
            fullWidth
            inputProps={{ readOnly: true }}
          />
          <br />
          <div>{errorAnswerSubmitDiv}</div>
          <div>{successAnswerSubmitDiv}</div>
        </DialogContent>
        <DialogActions>
          <Button onClick={handleViewAnswerModalActive} variant="contained">
            {t("common.close", { ns: ["home"] })}
          </Button>
        </DialogActions>
        <Box>{deleteQuestionSuccessDiv}</Box>
      </Dialog>
    </Box>
  );

  return (
    <Box>
      {loadingMarkup}
      <Container maxWidth="xl" sx={{ pt: 2 }}>
        {telehealthBannerMarkup}
        {prescriptonorder}
        <br />
        {questionTableBody}
        {questionModalBody}
        {QuestionDocViewerComponent}
        {deleteQuestionBody}
        {toastSuccessMarkup}
        {answerQuestionComponent}
        {viewAnswerComponent}
      </Container>
    </Box>
  );
}
