import {
  HEALTHCAMP_LIST_FAIL,
  HEALTHCAMP_LIST_LOADING,
  HEALTHCAMP_LIST_SUCCESS,
  GetHealthcampListStateType,
  AdminGetHealthcampListActionTypes,
} from "./ActionTypes";
import { AdminHealthcampList } from "./Model";

const initialStateGetHealthcamplist: GetHealthcampListStateType = {
  loading: false,
  healthcampListValue: {} as AdminHealthcampList,
};

export const getHealthcampListReducer = (
  state = initialStateGetHealthcamplist,
  action: AdminGetHealthcampListActionTypes,
): GetHealthcampListStateType => {
  switch (action.type) {
    case HEALTHCAMP_LIST_SUCCESS:
      return {
        ...state,
        loading: false,
        healthcampListValue: action.payload,
      };
    case HEALTHCAMP_LIST_LOADING:
      return {
        ...state,
        loading: true,
        healthcampListValue: {} as AdminHealthcampList,
      };
    case HEALTHCAMP_LIST_FAIL:
      return {
        ...state,
        healthcampListValue: {} as AdminHealthcampList,
        loading: false,
      };
    default:
      return state;
  }
};
