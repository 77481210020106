import { ANSWER_QUESTION_DATA, answerQuestionActionTypes } from "./ActionTypes";
import { answerQuestionValue } from "./Model";

export const answerQuestionAction = (
  answerQuestionDetails: answerQuestionValue,
): answerQuestionActionTypes => {
  return {
    type: ANSWER_QUESTION_DATA,
    payload: answerQuestionDetails,
  };
};
