import axios from "axios";
import { Dispatch } from "redux";
import Url from "../../Url";
import { GetPatientEncounterListActionTypes } from "./ActionTypes";
import { GetPatientEncounterListBody, GetPatientEncounterListRes } from "./Model";
import {
 patientEncounterListAPIResClearAction,
 patientEncounterListErrorAction,
 patientEncounterListLoadingAction,
 patientEncounterListSuccessAction,
 patientEncounterListUpdateAPIMsgAction,
} from "./Actions";
// import { setupInterceptorsTo } from "../../Authentication/Interceptors";
import { headers } from "components/Utility";

let apiRes = {} as GetPatientEncounterListRes;
export const getPatientEncounterListApi = (
  payload: GetPatientEncounterListBody,
) => {
  return function (dispatch: Dispatch<GetPatientEncounterListActionTypes>) {
    dispatch(patientEncounterListLoadingAction(true));
   axios
      .get(
        Url.apiURL +
          `/patientportalapp/api/patientEncounterList/get_patientPortal_Encounter_List?page=${Number(
            payload.pageNo,
          )}&size=50&ipp=${payload.patientId}`,        
        { headers: headers },
      )
      .then((res) => {
        apiRes = res.data;
        dispatch(patientEncounterListLoadingAction(false));
        dispatch(
         patientEncounterListSuccessAction(
            res.data !== undefined ? res.data : ({} as GetPatientEncounterListRes),
            res.data.message !== undefined
              ? res.data.message
              : "Patient Encounter list fetched successfully",
          ),
        );
      })
      .catch((error) => {
        dispatch(patientEncounterListLoadingAction(false));
        dispatch(
         patientEncounterListErrorAction(
            {} as GetPatientEncounterListRes,
            error.response !== undefined
              ? error.response.data.message || error.response.data.message
              : "Something went wrong",
            error.status !== undefined ? error.response.data.status || 0 : 0,
          ),
        );
      });
  };
};

export const updateGetPatientEncounterListAPIResMsg = () => {
  return function (dispatch: Dispatch<GetPatientEncounterListActionTypes>) {
    dispatch(
     patientEncounterListUpdateAPIMsgAction(
        apiRes as GetPatientEncounterListRes,
        "",
        "",
        0,
      ),
    );
  };
};

export const clearGetPatientEncounterListAPIRes = () => {
  return function (dispatch: Dispatch<GetPatientEncounterListActionTypes>) {
    dispatch(
     patientEncounterListAPIResClearAction({} as GetPatientEncounterListRes, "", "", 0),
    );
  };
};
