import React, { useEffect } from "react";
import { TabContext, TabList, TabPanel } from "@mui/lab";
import AllDoctor from "./AllDoctor";
import FindDoctor from "./FindDoctor";
import { Tab, Box } from "@mui/material";
import PageLayout from "../components/PageLayout";

export default function Doctor() {
  const [selected, setSelected] = React.useState("1");
  const handleTabChange = (_event: React.SyntheticEvent, newValue: string) => {
    setSelected(newValue);
  };
  useEffect(() => {});
  return (
    <PageLayout>
      <TabContext value={String(selected)}>
        <Box sx={{ borderBottom: 1, borderColor: "divider" }}>
          <TabList onChange={handleTabChange}>
            <Tab label="All Doctor" value="1" />
            <Tab label="Find Doctor" value="2" />
          </TabList>
        </Box>
        <TabPanel value="1">{<AllDoctor />}</TabPanel>
        <TabPanel value="2">{<FindDoctor />}</TabPanel>
      </TabContext>
    </PageLayout>
  );
}
