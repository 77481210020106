
const Url = {
  apiURL: process.env.REACT_APP_BE_URL,
  baseURL: process.env.REACT_APP_BASE_URL,
  zoomRedirectAuthBaseURL: process.env.REACT_APP_ZOOM_AUTH_BASE_URL,
  zoomClientId: process.env.REACT_APP_ZOOM_CLIENT_ID,
  zoomResponseType: process.env.REACT_APP_ZOOM_RESPONSE_TYPE,
  zoomRedirectURI: process.env.REACT_APP_ZOOM_REDIRECT_URI,
};
export default Url;
