import axios from "axios";
import { Dispatch } from "redux";
import Url from "../../../Url";
import { GovtIDProofActionTypes } from "./ActionTypes";
import { GovtIdProofResponse } from "./Model";
import {
  govtIDProofSuccessAction,
  govtIDProofErrorAction,
  govtIDProofLoadingAction,
} from "./Action";
import { setupInterceptorsTo } from "../../Authentication/Interceptors";
import { headers } from "components/Utility";

export const govtIdProofListApi = () => {
  return function (dispatch: Dispatch<GovtIDProofActionTypes>) {
    dispatch(govtIDProofLoadingAction(true));
    setupInterceptorsTo(axios)
      .get(Url.apiURL + `/indivoapp/api/govtIdType/dropdown_list`, { headers: headers })
      .then((res) => {
        dispatch(govtIDProofLoadingAction(false));
        dispatch(govtIDProofSuccessAction(res.data, res.data.message, res.data.status));
      })
      .catch((error) => {
        dispatch(govtIDProofLoadingAction(false));
        dispatch(
          govtIDProofErrorAction(
            {} as GovtIdProofResponse,
            error.response !== undefined
              ? error.response.data.message
              : "Could not upload the Image!",
            error.status !== undefined ? error.response.data.status || -1 : -1,
          ),
        );
      });
  };
};
