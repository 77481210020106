import { LoginRes } from "./Model";
export const LOGIN_LOADING = "LOGIN_LOADING";
export const LOGIN_SUCCESS = "LOGIN_SUCCESS";
export const LOGIN_FAIL = "LOGIN_FAIL";
export const LOGIN_UPDATE_API_MSG = "LOGIN_UPDATE_API_MSG";
export const LOGIN_UPDATE_API_RES = "LOGIN_UPDATE_API_RES";

export interface LoginState {
  loginRes: LoginRes;
  loading: boolean;
  successMsg: string;
  errorMsg: string;
  status: number;
}
export interface LoginLoading {
  type: typeof LOGIN_LOADING;
  loading: boolean;
}
export interface LoginSuccess {
  type: typeof LOGIN_SUCCESS;
  payload: LoginRes;
  successMsg: string;
}
export interface LoginFail {
  type: typeof LOGIN_FAIL;
  payload: LoginRes;
  errorMsg: string;
  status: number;
}
export interface LoginUpdateAPIMsg {
  type: typeof LOGIN_UPDATE_API_MSG;
  payload: LoginRes;
  successMsg: string;
  errorMsg: string;
  status: number;
}
export interface LoginUpdateAPIRes {
  type: typeof LOGIN_UPDATE_API_RES;
  payload: LoginRes;
  successMsg: string;
  errorMsg: string;
  status: number;
}

export type JwtTokenActionTypes =
  | LoginLoading
  | LoginSuccess
  | LoginFail
  | LoginUpdateAPIMsg
  | LoginUpdateAPIRes;
