import axios from "axios";
import { Dispatch } from "redux";
import Url from "../../Url";
import { AddFavoritePatientBody, AddFavoritePatientDetails } from "./Model";
import {
  AddFavoritePatientErrorAction,
  AddFavoritePatientLoadingAction,
  AddFavoritePatientSuccessAction,
} from "./Actions";
import { AddFavoritePatientActionTypes } from "./ActionTypes";
import { headers } from "components/Utility";

let apiRes = {} as AddFavoritePatientDetails;
export const addFavoritePatient = (payload: AddFavoritePatientBody) => {
  return function (dispatch: Dispatch<AddFavoritePatientActionTypes>) {
    dispatch(AddFavoritePatientLoadingAction(true));
    axios
      .post(Url.apiURL + `/patientapp/api/patient/favourite_patient`, payload, { headers: headers })
      .then((res) => {
        apiRes = res.data;
        console.log(apiRes);
        dispatch(AddFavoritePatientLoadingAction(false));
        dispatch(AddFavoritePatientSuccessAction(res.data));
      })
      .catch((error) => {
        dispatch(AddFavoritePatientLoadingAction(false));
        dispatch(
          AddFavoritePatientErrorAction(
            {} as AddFavoritePatientDetails,
            error.response !== undefined
              ? error.response.data.message || error.response.data.detail
              : "Something went wrong",
            error.status !== undefined ? error.response.data.status || 0 : 0,
          ),
        );
      });
  };
};
