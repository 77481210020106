import axios from "axios";
import { Dispatch } from "redux";
import Url from "../../../Url";
import { setupInterceptorsTo } from "../../Authentication/Interceptors";
import { answerQuestionAction } from "./Action";
import {
  answerQuestionActionTypes,
  ANSWER_QUESTION_FAIL,
  ANSWER_QUESTION_LOADING,
  ANSWER_QUESTION_SUCCESS,
} from "./ActionTypes";
import { answerQuestionDetails } from "./Model";
import { headers } from "components/Utility";

export const answerQuestionSubmit = (body: answerQuestionDetails) => {
  return function (dispatch: Dispatch<answerQuestionActionTypes>) {
    dispatch({
      type: ANSWER_QUESTION_LOADING,
    });

    setupInterceptorsTo(axios)
      .post(Url.apiURL + `/questionandanswerapp/api/question/answer_Question`, body, {
        headers: headers,
      })
      .then((res) => {
        dispatch(answerQuestionAction(res.data));
        dispatch({
          type: ANSWER_QUESTION_SUCCESS,
          payload: res.data,
        });
      })
      .catch((_error) => {
        dispatch({
          type: ANSWER_QUESTION_FAIL,
        });
      });
  };
};
