import {
  ExtractObservationDetailsActionTypes,
  EXTRACT_OBSERVATION_DETAILS_FAIL,
  EXTRACT_OBSERVATION_DETAILS_LOADING,
  EXTRACT_OBSERVATION_DETAILS_SUCCESS,
  ExtractObservationDetailsState,
  EXTRACT_OBSERVATION_DETAILS_API_MSG,
  EXTRACT_OBSERVATION_DETAILS_API_RES,
} from "./ActionTypes";
import { ExtractObservationDetailsRes } from "./Model";

const initialStateUpdatePosts: ExtractObservationDetailsState = {
  loading: false,
  extractObservationDetailsRes: {} as ExtractObservationDetailsRes,
  errorMsg: "",
  status: 0,
};
export const extractObservationByDetailsIdReducer = (
  state = initialStateUpdatePosts,
  action: ExtractObservationDetailsActionTypes,
): ExtractObservationDetailsState => {
  switch (action.type) {
    case EXTRACT_OBSERVATION_DETAILS_LOADING:
      return {
        ...state,
        loading: action.loading,
      };
    case EXTRACT_OBSERVATION_DETAILS_SUCCESS:
      return {
        ...state,
        extractObservationDetailsRes: action.payload,
      };
    case EXTRACT_OBSERVATION_DETAILS_FAIL:
      return {
        ...state,
        extractObservationDetailsRes: action.payload,
        errorMsg: action.errorMsg,
        status: action.status,
      };
    case EXTRACT_OBSERVATION_DETAILS_API_MSG:
      return {
        ...state,
        extractObservationDetailsRes: action.payload,
        errorMsg: action.errorMsg,
        status: action.status,
      };
    case EXTRACT_OBSERVATION_DETAILS_API_RES:
      return {
        ...state,
        extractObservationDetailsRes: action.payload,
        errorMsg: action.errorMsg,
        status: action.status,
      };
    default:
      return state;
  }
};
