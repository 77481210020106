import {
  SelectedDoctorTeamTypeDetails,
  GetSelectedDoctorTeamTypeActionTypes,
  GET_DOCTOR_TEAM_TYPE_LIST_FAIL,
  GET_DOCTOR_TEAM_TYPE_LIST_LOADING,
  GET_DOCTOR_TEAM_TYPE_LIST_SUCCESS,
} from "./ActionTypes";
import { GetSelectedDoctorTeamTypeRes } from "./Model";

const initialStateGetPosts: SelectedDoctorTeamTypeDetails = {
  loading: false,
  getSelectedDoctorTeamTypeRes: {} as GetSelectedDoctorTeamTypeRes,
  errRes: "",
};
export const getSelectedDoctorTeamTypeReducer = (
  state = initialStateGetPosts,
  action: GetSelectedDoctorTeamTypeActionTypes,
): SelectedDoctorTeamTypeDetails => {
  switch (action.type) {
    case GET_DOCTOR_TEAM_TYPE_LIST_SUCCESS:
      return {
        ...state,
        loading: false,
        getSelectedDoctorTeamTypeRes: action.payload,
        errRes: "",
      };
    case GET_DOCTOR_TEAM_TYPE_LIST_LOADING:
      return {
        ...state,
        loading: true,
        getSelectedDoctorTeamTypeRes: {} as GetSelectedDoctorTeamTypeRes,
      };
    case GET_DOCTOR_TEAM_TYPE_LIST_FAIL:
      return {
        ...state,
        getSelectedDoctorTeamTypeRes: {} as GetSelectedDoctorTeamTypeRes,
        loading: false,
        errRes: action.errRes,
      };
    default:
      return state;
  }
};
