import React, { useEffect, useState } from "react";
import {
  Box,
  Button,
  Container,
  FormControl,
  FormControlLabel,
  Grid,
  Radio,
  RadioGroup,
} from "@mui/material";
import { useDispatch, useSelector } from "react-redux";
import {
  addPatientCase,
  editPatientCase,
  getResponseTimeList,
  getcaseDetails,
} from "../../redux/ConsultationOption/ApiCall";
import { AppState } from "../../redux/store/Store";
import { useParams } from "react-router-dom";
import { toggleAppBarLoading } from "redux/appLoader/Actions";

export default function ResponseTimeType() {
  const dispatch = useDispatch();
  const { caseid } = useParams() as {
    caseid: string;
  };

  const ResponseTimeTypeListRes = useSelector((state: AppState) => state.responseTimeValue);
  const ResponseTimeTypeListValue = useSelector((state: AppState) => state.responseTimeValue);

  const PatientCaseDetailsRes = useSelector((state: AppState) => state.getPatientCaseDetails);
  const PatientCaseDetailsValue = useSelector(
    (state: AppState) => state.getPatientCaseDetails.patientCaseDetail,
  );

  const [ResponseTimeTypeValue, setResponseTimeTypeValue] = useState("");
  const [isEdit, setIsEdit] = useState(false);
  const [isEnableSubmit, setEnableSubmit] = useState(true);
  const [value, setValue] = React.useState("");

  const addBody = {
    caseId: caseid,
    responseTimeTypeId: value,
  };

  const createCase = async () => {
    if (caseid !== undefined) {
      await dispatch(editPatientCase(Object.assign(addBody, { caseId: caseid })));
      await setIsEdit(false);
      await setEnableSubmit((isEnableSubmit) => !isEnableSubmit);
      await dispatch(getcaseDetails(JSON.stringify({ caseId: caseid })));
    } else {
      await dispatch(addPatientCase(addBody));
      await dispatch(getcaseDetails(JSON.stringify({ caseId: caseid })));
    }
  };

  useEffect(() => {
    if (caseid !== undefined) {
      dispatch(
        getResponseTimeList(
          JSON.stringify({
            caseId: caseid,
          }),
        ),
      );
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [dispatch]);

  useEffect(() => {
    if (caseid !== undefined) {
      dispatch(
        getcaseDetails(
          JSON.stringify({
            caseId: caseid,
          }),
        ),
      );
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [dispatch]);

  useEffect(() => {
    setValue(
      String(
        PatientCaseDetailsValue === undefined
          ? ""
          : PatientCaseDetailsValue?.responseTimeType?.map((item) => item.value),
      ),
    );
    setResponseTimeTypeValue(PatientCaseDetailsValue?.consultationType?.label!);
  }, [PatientCaseDetailsValue]);

  useEffect(() => {
    dispatch(
      toggleAppBarLoading(
        ResponseTimeTypeListRes?.loading ||
          ResponseTimeTypeListRes?.loading ||
          PatientCaseDetailsRes?.loading,
      ),
    );
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [ResponseTimeTypeListRes, ResponseTimeTypeListRes, PatientCaseDetailsRes]);
  const thirdComponent = (
    <Grid container direction="column" justifyContent="center" alignItems="stretch" spacing={2}>
      <Grid item>
        <Box>
          {ResponseTimeTypeListValue?.responseTimeTypeList?.map((opinion: any) => (
            <FormControl key={opinion.value} disabled={ResponseTimeTypeValue ? !isEdit : isEdit}>
              <RadioGroup
                aria-labelledby="demo-controlled-radio-buttons-group"
                name="controlled-radio-buttons-group"
                value={value}
                onChange={(event: React.ChangeEvent<HTMLInputElement>) =>
                  setValue((event.target as HTMLInputElement).value)
                }
              >
                <FormControlLabel value={opinion.value} control={<Radio />} label={opinion.label} />
              </RadioGroup>
            </FormControl>
          ))}
        </Box>
      </Grid>
      <Grid item>
        {ResponseTimeTypeValue ? (
          <Grid container direction="row" justifyContent="flex-end" alignItems="center" spacing={1}>
            <Grid item>
              <Button
                size="large"
                variant="outlined"
                onClick={() => {
                  setIsEdit((isEdit) => !isEdit);
                  setEnableSubmit((isEnableSubmit) => !isEnableSubmit);
                }}
              >
                {isEdit ? "Cancel" : "Edit"}
              </Button>
            </Grid>
            <Grid item>
              <Button
                size="large"
                variant="contained"
                disabled={isEnableSubmit}
                onClick={createCase}
              >
                Update
              </Button>
            </Grid>
          </Grid>
        ) : (
          <Grid container direction="row" justifyContent="flex-end" alignItems="center">
            <Button size="large" variant="contained" onClick={createCase}>
              Add
            </Button>
          </Grid>
        )}
      </Grid>
    </Grid>
  );

  return <Container maxWidth="xl">{thirdComponent}</Container>;
}
