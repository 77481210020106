import axios from "axios";
import { Dispatch } from "redux";
import Url from "../../Url";
import { GetOrganizationPatientListActionTypes } from "./ActionTypes";
import { GetOrganizationPatientListBody, GetOrganizationPatientListDetails } from "./Model";
import {
  GetOrganizationPatientListAPIResClearAction,
  GetOrganizationPatientListErrorAction,
  GetOrganizationPatientListLoadingAction,
  GetOrganizationPatientListSuccessAction,
  GetOrganizationPatientListAPIMsgAction,
} from "./Actions";
import { headers } from "components/Utility";

let apiRes = {} as GetOrganizationPatientListDetails;
export const getPatientListByOrganization = (
  organizationId: GetOrganizationPatientListBody,
  pageNo: any,
) => {
  return function (dispatch: Dispatch<GetOrganizationPatientListActionTypes>) {
    dispatch(GetOrganizationPatientListLoadingAction(true));
    axios
      .post(
        Url.apiURL +
          `/adminapp/api/patientorganization/organization_patient_list?page=${Number(
            pageNo,
          )}&size=50`,
        organizationId,
        {
          headers: headers,
        },
      )
      .then((res) => {
        apiRes = res.data;
        dispatch(GetOrganizationPatientListLoadingAction(false));
        dispatch(GetOrganizationPatientListSuccessAction(res.data));
      })
      .catch((error) => {
        dispatch(GetOrganizationPatientListLoadingAction(false));
        dispatch(
          GetOrganizationPatientListErrorAction(
            {} as GetOrganizationPatientListDetails,
            error.response !== undefined
              ? error.response.data.message
              : "Could not upload the Image!",
            error.status !== undefined ? error.response.data.status || -1 : -1,
          ),
        );
      });
  };
};

export const organizationPatientListAPIResMsg = () => {
  return function (dispatch: Dispatch<GetOrganizationPatientListActionTypes>) {
    dispatch(
      GetOrganizationPatientListAPIMsgAction(apiRes as GetOrganizationPatientListDetails, "", 0),
    );
  };
};

export const clearOrgPatientListAPIRes = () => {
  return function (dispatch: Dispatch<GetOrganizationPatientListActionTypes>) {
    dispatch(
      GetOrganizationPatientListAPIResClearAction({} as GetOrganizationPatientListDetails, "", 0),
    );
  };
};
