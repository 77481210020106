import { GET_LIST } from "../types/ActionTypes";
import { sharedPatientDetailsActionTypes } from "./ActionTypes";
import { sharedPatientList } from "./Model";

export const getSharedPatientDetailsAction = (
  GetSharedPatientDetails: sharedPatientList,
): sharedPatientDetailsActionTypes => {
  return {
    type: GET_LIST,
    payload: GetSharedPatientDetails,
  };
};
