import { AddFavoritePatientDetails } from "./Model";
// Favorite Patient Loading State
export const ADD_FAVORITE_PATIENT_LOADING = "ADD_FAVORITE_PATIENT_LOADING";
export const ADD_FAVORITE_PATIENT_SUCCESS = "ADD_FAVORITE_PATIENT_SUCCESS";
export const ADD_FAVORITE_PATIENT_FAIL = "ADD_FAVORITE_PATIENT_FAIL";
export interface AddPatientPointDataState {
  addFavoritePatientRes: AddFavoritePatientDetails;
  loading: boolean;
  message: string;
  status: number;
}
export interface AddPatientDataPointLoading {
  type: typeof ADD_FAVORITE_PATIENT_LOADING;
  loading: boolean;
}
export interface AddPatientDataPointSuccess {
  type: typeof ADD_FAVORITE_PATIENT_SUCCESS;
  payload: AddFavoritePatientDetails;
}
export interface AddPatientDataPointFail {
  type: typeof ADD_FAVORITE_PATIENT_FAIL;
  payload: AddFavoritePatientDetails;
  message: string;
  status: number;
}

export type AddFavoritePatientActionTypes =
  | AddPatientDataPointLoading
  | AddPatientDataPointSuccess
  | AddPatientDataPointFail;
