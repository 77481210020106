import React, { useEffect, useState } from "react";
import SharePatientList from "./SharePatientList";
import PersonalHealthRecord from "./PersonalHealthRecord";
import { Box, Chip, Container, Grid, Stack, Tab, Typography } from "@mui/material";
import { TabContext, TabList, TabPanel } from "@mui/lab";
import { t } from "i18next";
import OrganizationList from "./OrganizationList/index";
import HealthcampList from "./HealthcampList";
import RefreshIcon from "@mui/icons-material/Refresh";
import { AppState } from "../redux/store/Store";
import { useDispatch, useSelector } from "react-redux";
import {
  getOrganizationListValue,
  getPatientListValue,
  getSharedPatientListValue,
} from "../redux/effects/ApiCall";
import { getHealthcampListValue } from "../redux/healthcampList/API";
import { toggleAppBarLoading } from "redux/appLoader/Actions";

export default function Dashboard() {
  const dispatch = useDispatch();
  const [selected, setSelected] = React.useState("1");
  const handleTabChange = (_event: React.SyntheticEvent, newValue: string) => {
    setSelected(newValue);
  };
  const [healthCampListLoader, setHealthCampListLoader] = useState(false);
  const [myPatientListLoader, setMyPatientListLoader] = useState<boolean | undefined>(false);
  const [sharedPatientListLoader, setSharedPatientListLoader] = useState<boolean | undefined>(
    false,
  );

  const [organizationListLoader, setOrganizationListLoader] = useState<boolean | undefined>(false);

  useEffect(() => {
    dispatch(
      toggleAppBarLoading(
        myPatientListLoader ||
          sharedPatientListLoader ||
          organizationListLoader ||
          healthCampListLoader,
      ),
    );
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [myPatientListLoader, sharedPatientListLoader, organizationListLoader, healthCampListLoader]);

  const PatientListFromAPI = useSelector((state: AppState) => state.patientValue);
  const SharedPatientListFromAPI = useSelector((state: AppState) => state.sharedPatientValue);
  const AllOrganizationList = useSelector((state: AppState) => state.organizationListValue);
  // call Store
  const AdminAllHealthcampList = useSelector((state: AppState) => state.healthcampListValue);

  useEffect(() => {
    dispatch(getPatientListValue());
    dispatch(getSharedPatientListValue());
    dispatch(getOrganizationListValue());
    dispatch(getHealthcampListValue());
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);
  
  const actualPageMarkup = (
    <Grid>
      <Grid item>
        <Box sx={{ width: "100%" }}>
          <TabContext value={String(selected)}>
            <Box sx={{ borderBottom: 1, borderColor: "divider" }}>
              <TabList onChange={handleTabChange}>
                <Tab
                  label={
                    <Grid
                      container
                      direction="row"
                      justifyContent="center"
                      alignItems="center"
                      spacing={1}
                    >
                      <Grid item>
                        <Typography>{t("menu.mypatient", { ns: ["home"] })}</Typography>
                      </Grid>
                      <Grid item>
                        <Chip
                          label={String(PatientListFromAPI?.patientValue?.length)}
                          variant="outlined"
                          size="small"
                        />
                      </Grid>
                    </Grid>
                  }
                  icon={
                    <RefreshIcon
                      onClick={() => {
                        dispatch(getPatientListValue());
                      }}
                    />
                  }
                  iconPosition="end"
                  value="1"
                />
                <Tab
                  label={
                    <Grid
                      container
                      direction="row"
                      justifyContent="center"
                      alignItems="center"
                      spacing={1}
                    >
                      <Grid item>
                        <Typography>{t("dashboard.sharepatlist", { ns: ["home"] })}</Typography>
                      </Grid>
                      <Grid item>
                        <Chip
                          label={String(SharedPatientListFromAPI?.sharedPatientValue?.length)}
                          variant="outlined"
                          size="small"
                        />
                      </Grid>
                    </Grid>
                  }
                  icon={
                    <RefreshIcon
                      onClick={() => {
                        dispatch(getSharedPatientListValue());
                      }}
                    />
                  }
                  iconPosition="end"
                  value="2"
                />
                {AllOrganizationList?.organizationListValue?.length > 0 ? (
                  <Tab
                    label={
                      <Grid
                        container
                        direction="row"
                        justifyContent="center"
                        alignItems="center"
                        spacing={1}
                      >
                        <Grid item>
                          <Typography>{t("dashboard.orglist", { ns: ["home"] })}</Typography>
                        </Grid>
                        <Grid item>
                          <Chip
                            label={String(AllOrganizationList?.organizationListValue?.length)}
                            variant="outlined"
                            size="small"
                          />
                        </Grid>
                      </Grid>
                    }
                    icon={
                      <RefreshIcon
                        onClick={() => {
                          dispatch(getOrganizationListValue());
                        }}
                      />
                    }
                    iconPosition="end"
                    value="3"
                  />
                ) : (
                  ""
                )}
                {AdminAllHealthcampList?.healthcampListValue?.count > 0 ? (
                  <Tab
                    label={
                      <Grid
                        container
                        direction="row"
                        justifyContent="center"
                        alignItems="center"
                        spacing={1}
                      >
                        <Grid item>
                          <Typography>{t("dashboard.healthcamplist", { ns: ["home"] })}</Typography>
                        </Grid>
                        <Grid item>
                          <Chip
                            label={String(AdminAllHealthcampList?.healthcampListValue?.count)}
                            variant="outlined"
                            size="small"
                          />
                        </Grid>
                      </Grid>
                    }
                    icon={
                      <RefreshIcon
                        onClick={() => {
                          dispatch(getHealthcampListValue());
                        }}
                      />
                    }
                    iconPosition="end"
                    value="4"
                  />
                ) : (
                  ""
                )}
              </TabList>
            </Box>
            <TabPanel value="1">
              {<PersonalHealthRecord myPatientListLoader={setMyPatientListLoader} />}
            </TabPanel>
            <TabPanel value="2">
              {<SharePatientList sharedPatientListLoader={setSharedPatientListLoader} />}
            </TabPanel>

            <TabPanel value="3">
              {<OrganizationList organizationListLoader={setOrganizationListLoader} />}
            </TabPanel>

            <TabPanel value="4">
              {<HealthcampList healthcampListLoader={setHealthCampListLoader} />}
            </TabPanel>
          </TabContext>
        </Box>
      </Grid>
    </Grid>
  );

  return (
    <Container maxWidth="xl" sx={{ paddingTop: 2 }}>
      <Stack direction="column" justifyContent="flex-start" alignItems="stretch" spacing={2}>
        <Typography variant="h5" sx={{ fontWeight: "bold" }}>
          {t("dashboard.patientlist", { ns: ["home"] })}
        </Typography>
        {actualPageMarkup}
      </Stack>
    </Container>
  );
}
