import React, { useEffect, useState } from "react";
import DeleteIcon from "@mui/icons-material/Delete";
import RefreshIcon from "@mui/icons-material/Refresh";
import { useHistory, useParams } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { AppState } from "../../../../redux/store/Store";
import { AlertColor, Box, Button, Card, CardContent, CardHeader } from "@mui/material";
import { DataGrid, GridColDef, GridCellParams, GridToolbar } from "@mui/x-data-grid";
import { GetDoctorListForCaseBody } from "../../../../redux/Patient/AppointmentSelectDoctor/DoctorListForCase/Model";
import { getDoctorListForCase } from "../../../../redux/Patient/AppointmentSelectDoctor/DoctorListForCase/ApiCall";
import { clearDeleteDoctorForCaseAPIRes } from "../../../../redux/Patient/AppointmentSelectDoctor/DeleteDoctor/API";
import DeleteDoctor from "./DeleteDoctor";
import MWExceptionList from "../../../../components/MWExceptionList";
import MWSnackbar from "../../../../components/MWSnackbar";
import { toggleAppBarLoading } from "redux/appLoader/Actions";

export default function SelectedDoctorList() {
  const dispatch = useDispatch();
  const history = useHistory();

  const { caseid } = useParams() as {
    caseid: string;
  };

  useEffect(() => {
    dispatch(getDoctorListForCase({ caseId: caseid } as GetDoctorListForCaseBody));
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const getDoctorListForCaseRes = useSelector((state: AppState) => state.getDoctorListForCaseRes);
  useEffect(() => {
    dispatch(toggleAppBarLoading(getDoctorListForCaseRes?.loading));
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [getDoctorListForCaseRes]);

  const columns: GridColDef[] = [
    {
      field: "DoctorName",
      headerName: "Dr. Name",
      flex: 1,
      renderCell: (params: GridCellParams) => (
        <Button
          variant="text"
          onClick={() => {
            history.push(`/doctordetails/${params.row.id}`);
          }}
        >
          {params.row.firstname || null} {params.row.lastname || null}
        </Button>
      ),
    },
    { field: "qualification", headerName: "Qualification", flex: 1 },
    { field: "speciality", headerName: "Speciality", flex: 1 },
    {
      field: "requestStatus",
      headerName: "Appoinment Status",
      minWidth: 150,
    },
    {
      field: "id",
      headerName: "Delete",
      renderCell: (params: GridCellParams) => (
        <Button
          variant="outlined"
          onClick={() => {
            deleteDoctor(params.row.id, params.row.firstname, params.row.lastname);
          }}
        >
          <DeleteIcon />
        </Button>
      ),
    },
  ];

  const [doctorName, setDoctorName] = useState("");

  const [deleteDoctorDialog, setDeleteDoctorDialog] = useState(false);
  const [selectedItem, setSelectedItem] = useState(0);
  const deleteDoctor = (doctorId: number, docFName: string, docLName: string) => {
    setSelectedItem(doctorId);
    setDeleteDoctorDialog((deleteDoctorDialog) => !deleteDoctorDialog);
    let doctorName = docFName + " " + docLName;
    setDoctorName(doctorName);
  };
  const updateDeleteDoctorDialogValFromChild = (passedVal: boolean) => {
    setDeleteDoctorDialog(passedVal);
  };

  const deleteDoctorForCaseRes = useSelector((state: AppState) => state.deleteDoctorForCaseRes);
  const [deleteDocSuccessMsg, setDeleteDocSuccessMsg] = useState("");
  const [snackBarType, setSnackBarType] = useState<AlertColor>("success");
  useEffect(() => {
    if (deleteDoctorForCaseRes?.successMsg !== "") {
      setDeleteDocSuccessMsg(deleteDoctorForCaseRes?.successMsg);
      setSnackBarType("success");
    }
    if (deleteDoctorForCaseRes?.successMsg === "") {
      setDeleteDocSuccessMsg("");
    }
  }, [deleteDoctorForCaseRes]);

  const checkAlertClose = (passedVal: boolean) => {
    if (passedVal) {
      dispatch(clearDeleteDoctorForCaseAPIRes());
    }
  };

  return (
    <Box>
      <Card>
        <CardHeader title="Selected Doctor List">
          <Button
            variant="outlined"
            size="medium"
            startIcon={<RefreshIcon />}
            //   onClick={() => getSelectedDoc}
            sx={{ textTransform: "none" }}
          >
            Reload Doctor List
          </Button>
        </CardHeader>
        <CardContent>
          {getDoctorListForCaseRes?.getDoctorListForCaseRes?.patientCaseDoctorlist !== undefined ? (
            getDoctorListForCaseRes?.getDoctorListForCaseRes?.patientCaseDoctorlist.length > 0 ? (
              <DataGrid
                rows={getDoctorListForCaseRes?.getDoctorListForCaseRes?.patientCaseDoctorlist}
                columns={columns}
                pageSize={10}
                hideFooter
                hideFooterPagination
                disableSelectionOnClick
                experimentalFeatures={{ newEditingApi: true }}
                autoHeight
                components={{ Toolbar: GridToolbar }}
                componentsProps={{
                  toolbar: {
                    csvOptions: { disableToolbarButton: true },
                    printOptions: { disableToolbarButton: true },
                    showQuickFilter: true,
                    quickFilterProps: { debounceMs: 250 },
                  },
                }}
                disableColumnFilter
                disableColumnSelector
                disableDensitySelector
              />
            ) : (
              <MWExceptionList />
            )
          ) : (
            <MWExceptionList />
          )}
        </CardContent>
      </Card>
      {deleteDoctorDialog ? (
        <DeleteDoctor
          itemId={selectedItem}
          name={doctorName}
          deleteDoctorDialogEnable={deleteDoctorDialog}
          deleteDoctorDialogUpdateState={updateDeleteDoctorDialogValFromChild}
        />
      ) : null}
      <MWSnackbar msg={deleteDocSuccessMsg} type={snackBarType} alertClose={checkAlertClose} />
    </Box>
  );
}
