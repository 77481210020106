import {
  AddPatientInsuranceDetails,
  addPatientInsuranceActionTypes,
  ADD_PATIENT_INSURANCE_FAIL,
  ADD_PATIENT_INSURANCE_LOADING,
  ADD_PATIENT_INSURANCE_SUCCESS,
} from "./ActionTypes";
import { AddPatientInsuranceValue } from "./Model";

const initialStateGetPosts: AddPatientInsuranceDetails = {
  loading: false,
  addPatientInsuranceRes: {} as AddPatientInsuranceValue,
  errRes: "",
};
export const addPatientInsuranceReducer = (
  state = initialStateGetPosts,
  action: addPatientInsuranceActionTypes,
): AddPatientInsuranceDetails => {
  switch (action.type) {
    case ADD_PATIENT_INSURANCE_SUCCESS:
      return {
        ...state,
        loading: false,
        addPatientInsuranceRes: action.payload,
        errRes: "",
      };
    case ADD_PATIENT_INSURANCE_LOADING:
      return {
        ...state,
        loading: true,
        addPatientInsuranceRes: {} as AddPatientInsuranceValue,
      };
    case ADD_PATIENT_INSURANCE_FAIL:
      return {
        ...state,
        addPatientInsuranceRes: {} as AddPatientInsuranceValue,
        loading: false,
        errRes: action.errRes,
      };
    default:
      return state;
  }
};
