import {
  AddRequestAppointmentStateType,
  AddRequestAppointmentTypes,
  ADD_REQUEST_APPOINTMENT_FAIL,
  ADD_REQUEST_APPOINTMENT_LOADING,
  ADD_REQUEST_APPOINTMENT_SUCCESS,
  RequestAppointmentStateType,
  RequestAppointmentTypes,
  REQUEST_APPOINTMENT_DETAILS_LIST_FAIL,
  REQUEST_APPOINTMENT_DETAILS_LIST_LOADING,
  REQUEST_APPOINTMENT_DETAILS_LIST_SUCCESS,
} from "./ActionTypes";

const initialSpecialistStatus: RequestAppointmentStateType = {
  loading: false,
  response: Object.assign({}),
  listData: [],
};
const initialAddRequestAppointmentStatus: AddRequestAppointmentStateType = {
  loading: false,
  response: Object.assign({}),
};

export const requestAppointmentDetails = (
  state = initialSpecialistStatus,
  action: RequestAppointmentTypes,
): RequestAppointmentStateType => {
  switch (action.type) {
    case REQUEST_APPOINTMENT_DETAILS_LIST_SUCCESS:
      return {
        ...state,
        loading: false,
        response: action.payload,
        listData: action.listData,
      };
    case REQUEST_APPOINTMENT_DETAILS_LIST_LOADING:
      return {
        ...state,
        loading: true,
      };
    case REQUEST_APPOINTMENT_DETAILS_LIST_FAIL:
      return {
        ...state,
        loading: false,
      };
    default:
      return state;
  }
};

export const addrequestAppointment = (
  state = initialAddRequestAppointmentStatus,
  action: AddRequestAppointmentTypes,
): AddRequestAppointmentStateType => {
  switch (action.type) {
    case ADD_REQUEST_APPOINTMENT_SUCCESS:
      return {
        ...state,
        loading: false,
        response: action.payload,
      };
    case ADD_REQUEST_APPOINTMENT_LOADING:
      return {
        ...state,
        loading: true,
      };
    case ADD_REQUEST_APPOINTMENT_FAIL:
      return {
        ...state,
        loading: false,
        response: action.payload,
      };
    default:
      return state;
  }
};
