import { AddPatientImportConfigValue } from "./Model";

// Patient Meeting Loading State
export const ADD_PATIENT_IMPORT_CONFIG_LOADING = "ADD_PATIENT_IMPORT_CONFIG_LOADING";
export const ADD_PATIENT_IMPORT_CONFIG_SUCCESS = "ADD_PATIENT_IMPORT_CONFIG_SUCCESS";
export const ADD_PATIENT_IMPORT_CONFIG_FAIL = "ADD_PATIENT_IMPORT_CONFIG_FAIL";
export const ADD_PATIENT_IMPORT_CONFIG_LIST = "ADD_PATIENT_IMPORT_CONFIG_LIST";

export interface AddPatientImportConfigDetails {
  addPatientImportConfigRes: AddPatientImportConfigValue;
  loading: boolean;
  errRes: string;
}
export interface addMasterConfigLoading {
  type: typeof ADD_PATIENT_IMPORT_CONFIG_LOADING;
}
export interface addMasterConfigSuccess {
  type: typeof ADD_PATIENT_IMPORT_CONFIG_SUCCESS;
  payload: AddPatientImportConfigValue;
  errRes: string;
}
export interface addMasterConfigFail {
  type: typeof ADD_PATIENT_IMPORT_CONFIG_FAIL;
  errRes: string;
}

interface addPatientImportConfigAction {
  type: typeof ADD_PATIENT_IMPORT_CONFIG_LIST;
  payload: AddPatientImportConfigValue;
  errRes: string;
}

export type addPatientImportConfigActionTypes =
  | addPatientImportConfigAction
  | addMasterConfigLoading
  | addMasterConfigSuccess
  | addMasterConfigFail;
