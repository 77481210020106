import { GetDoctorTeamTypeListRes } from "./Model";
export const DOCTOR_TEAM_TYPE_LIST_LOADING = "DOCTOR_TEAM_TYPE_LIST_LOADING ";
export const DOCTOR_TEAM_TYPE_LIST_SUCCESS = "DOCTOR_TEAM_TYPE_LIST_SUCCESS";
export const DOCTOR_TEAM_TYPE_LIST_FAIL = "DOCTOR_TEAM_TYPE_LIST_FAIL";
export const DOCTOR_TEAM_TYPE_LIST_UPDATE_API_MSG = "DOCTOR_TEAM_TYPE_LIST_UPDATE_API_MSG";
export const DOCTOR_TEAM_TYPE_LIST_UPDATE_API_RES = "DOCTOR_TEAM_TYPE_LIST_UPDATE_API_RES";

export interface GetDoctorTeamTypeListState {
  getDoctorTeamTypeListRes: GetDoctorTeamTypeListRes;
  loading: boolean;
  successMsg: string;
  errorMsg: string;
  status: number;
}
export interface GetDoctorTeamTypeListLoading {
  type: typeof DOCTOR_TEAM_TYPE_LIST_LOADING;
  loading: boolean;
}
export interface GetDoctorTeamTypeListSuccess {
  type: typeof DOCTOR_TEAM_TYPE_LIST_SUCCESS;
  payload: GetDoctorTeamTypeListRes;
  successMsg: string;
}
export interface GetDoctorTeamTypeListFail {
  type: typeof DOCTOR_TEAM_TYPE_LIST_FAIL;
  payload: GetDoctorTeamTypeListRes;
  errorMsg: string;
  status: number;
}
export interface GetDoctorTeamTypeListUpdateAPIMsg {
  type: typeof DOCTOR_TEAM_TYPE_LIST_UPDATE_API_MSG;
  payload: GetDoctorTeamTypeListRes;
  successMsg: string;
  errorMsg: string;
  status: number;
}
export interface GetDoctorTeamTypeListUpdateAPIRes {
  type: typeof DOCTOR_TEAM_TYPE_LIST_UPDATE_API_RES;
  payload: GetDoctorTeamTypeListRes;
  successMsg: string;
  errorMsg: string;
  status: number;
}

export type GetDoctorTeamTypeListActionTypes =
  | GetDoctorTeamTypeListLoading
  | GetDoctorTeamTypeListSuccess
  | GetDoctorTeamTypeListFail
  | GetDoctorTeamTypeListUpdateAPIMsg
  | GetDoctorTeamTypeListUpdateAPIRes;
