import * as React from "react";
import Box from "@mui/material/Box";
import { DataGrid, GridColDef } from "@mui/x-data-grid";
const columns: GridColDef[] = [
  {
    field: "item",
    headerName: "Count",
    width: 80,
    sortable: false,
    editable: false,
    align: "center",
  },
  {
    field: "cgvisit",
    headerName: "Caregiver Visit",
    width: 140,
    sortable: false,
    type: "number",
    editable: true,
    align: "center",
  },
  {
    field: "docvisit",
    headerName: "Doctor Visits Accompanied By the CareGiver",
    width: 300,
    sortable: false,
    editable: true,
    type: "number",
    align: "center",
  },
  {
    field: "healthrecordreview",
    headerName: "Doctor Med-reconcilation and Health Record Review",
    type: "number",
    width: 310,
    editable: true,
    sortable: false,
    align: "center",
  },
  {
    field: "reviewfood",
    headerName: "Review Food Habit",
    description: "This column has a value getter and is not sortable.",
    sortable: false,
    editable: true,
    width: 160,
    type: "number",
    align: "center",
  },
  {
    field: "reviewexercise",
    headerName: "Review Exercise",
    description: "This column has a value getter and is not sortable.",
    sortable: false,
    editable: true,
    width: 160,
    type: "number",
    align: "center",
  },
];

const rows = [
  {
    id: 1,
    item: "Total",
    docvisit: 3,
    cgvisit: 24,
    healthrecordreview: 3,
    reviewfood: 3,
    reviewexercise: 3,
  },
  {
    id: 2,
    item: "Due",
    docvisit: 3,
    cgvisit: 2,
    healthrecordreview: 3,
    reviewfood: 2,
    reviewexercise: 2,
  },
];
export default function PackageStatusGridTable() {
  return (
    <Box>
      <Box sx={{ pr: 2}}>
        <DataGrid
          rows={rows}
          columns={columns}
          hideFooter
          hideFooterPagination
          disableSelectionOnClick
          isCellEditable={(params) => params.id === 2}
          autoHeight
          density="compact"
        />
      </Box>
    </Box>
  );
}
