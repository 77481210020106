import { PatientDropdownListValue } from "./Model";

export const PATIENT_LIST_DROPDOWN_LOADING = "PATIENT_LIST_DROPDOWN_LOADING";
export const PATIENT_LIST_DROPDOWN_SUCCESS = "PATIENT_LIST_DROPDOWN_SUCCESS";
export const PATIENT_LIST_DROPDOWN_FAIL = "PATIENT_LIST_DROPDOWN_FAIL";
export const PATIENT_DROPDOWN_LIST = "PATIENT_DROPDOWN_LIST";

export interface PatientDropdownListDetails {
  patientDropdownListRes: PatientDropdownListValue;
  loading: boolean;
}
export interface SharePatientListLoading {
  type: typeof PATIENT_LIST_DROPDOWN_LOADING;
}
export interface SharePatientListSuccess {
  type: typeof PATIENT_LIST_DROPDOWN_SUCCESS;
  payload: PatientDropdownListValue;
}
export interface SharePatientListFail {
  type: typeof PATIENT_LIST_DROPDOWN_FAIL;
}
interface SharePatientListAction {
  type: typeof PATIENT_DROPDOWN_LIST;
  payload: PatientDropdownListValue;
}

export type PatientDropdownListActionTypes =
  | SharePatientListAction
  | SharePatientListLoading
  | SharePatientListSuccess
  | SharePatientListFail;
