import {
  SLEEP_TIME_OBSERVATION_TYPE_FAIL,
  SLEEP_TIME_OBSERVATION_TYPE_LOADING,
  SLEEP_TIME_OBSERVATION_TYPE_SUCCESS,
  SLEEP_TIME_OBSERVATION_TYPE_UPDATE_API_MSG,
  SLEEP_TIME_OBSERVATION_TYPE_UPDATE_API_RES,
  SleepTimeObservationTypeActionTypes,
} from "./ActionTypes";
import { SleepTimeObservationTypeRes } from "./Model";

export const sleepTimeObservationTypeLoadingAction = (
  loading: boolean,
): SleepTimeObservationTypeActionTypes => {
  return {
    type: SLEEP_TIME_OBSERVATION_TYPE_LOADING,
    loading: loading,
  };
};

export const sleepTimeObservationTypeSuccessAction = (
  sleepTimeObservationTypeRes: SleepTimeObservationTypeRes,
  successMsg: string,
): SleepTimeObservationTypeActionTypes => {
  return {
    type: SLEEP_TIME_OBSERVATION_TYPE_SUCCESS,
    payload: sleepTimeObservationTypeRes,
    successMsg: successMsg,
  };
};

export const sleepTimeObservationTypeErrorAction = (
  sleepTimeObservationTypeRes: SleepTimeObservationTypeRes,
  errMsg: string,
  status: number,
): SleepTimeObservationTypeActionTypes => {
  return {
    type: SLEEP_TIME_OBSERVATION_TYPE_FAIL,
    payload: sleepTimeObservationTypeRes,
    errorMsg: errMsg,
    status: status,
  };
};
export const sleepTimeObservationTypeUpdateAPIMsgAction = (
  sleepTimeObservationTypeRes: SleepTimeObservationTypeRes,
  successMsg: string,
  errMsg: string,
  status: number,
): SleepTimeObservationTypeActionTypes => {
  return {
    type: SLEEP_TIME_OBSERVATION_TYPE_UPDATE_API_MSG,
    payload: sleepTimeObservationTypeRes,
    successMsg: successMsg,
    errorMsg: errMsg,
    status: status,
  };
};

export const sleepTimeObservationTypeAPIResClearAction = (
  sleepTimeObservationTypeRes: SleepTimeObservationTypeRes,
  successMsg: string,
  errMsg: string,
  status: number,
): SleepTimeObservationTypeActionTypes => {
  return {
    type: SLEEP_TIME_OBSERVATION_TYPE_UPDATE_API_RES,
    payload: sleepTimeObservationTypeRes,
    successMsg: successMsg,
    errorMsg: errMsg,
    status: status,
  };
};
