import {
  BloodGroupListDetails,
  GetBloodGroupListActionTypes,
  GET_BLOOD_GROUP_LIST_FAIL,
  GET_BLOOD_GROUP_LIST_LOADING,
  GET_BLOOD_GROUP_LIST_SUCCESS,
} from "./ActionTypes";
import { GetBloodGroupListRes } from "./Model";

const initialStateGetPosts: BloodGroupListDetails = {
  loading: false,
  getBloodGroupListRes: {} as GetBloodGroupListRes,
  errRes: "",
};
export const getBloodGroupListReducer = (
  state = initialStateGetPosts,
  action: GetBloodGroupListActionTypes,
): BloodGroupListDetails => {
  switch (action.type) {
    case GET_BLOOD_GROUP_LIST_SUCCESS:
      return {
        ...state,
        loading: false,
        getBloodGroupListRes: action.payload,
        errRes: "",
      };
    case GET_BLOOD_GROUP_LIST_LOADING:
      return {
        ...state,
        loading: true,
        getBloodGroupListRes: {} as GetBloodGroupListRes,
      };
    case GET_BLOOD_GROUP_LIST_FAIL:
      return {
        ...state,
        getBloodGroupListRes: {} as GetBloodGroupListRes,
        loading: false,
        errRes: action.errRes,
      };
    default:
      return state;
  }
};
