import { UpdateAdminNoteByPatientRes } from "./Model";
export const UPDATE_NOTE_LOADING = "UPDATE_NOTE_LOADING";
export const UPDATE_NOTE_SUCCESS = "UPDATE_NOTE_SUCCESS";
export const UPDATE_NOTE_FAIL = "UPDATE_NOTE_FAIL";
export const UPDATE_NOTE_DATA = "UPDATE_NOTE_DATA";
export const UPDATE_NOTE_UPDATE_API_MSG = "UPDATE_NOTE_UPDATE_API_MSG";
export const UPDATE_NOTE_UPDATE_API_RES = "UPDATE_NOTE_UPDATE_API_RES";

export interface UpdateAdminNoteByPatientState {
  updateNoteResRes: UpdateAdminNoteByPatientRes;
  loading: boolean;
  successMsg: string;
  errorMsg: string;
  status: number;
}
export interface UpdateAdminNoteByPatientLoading {
  type: typeof UPDATE_NOTE_LOADING;
  loading: boolean;
}
export interface UpdateAdminNoteByPatientSuccess {
  type: typeof UPDATE_NOTE_SUCCESS;
  payload: UpdateAdminNoteByPatientRes;
  successMsg: string;
}
export interface UpdateAdminNoteByPatientFail {
  type: typeof UPDATE_NOTE_FAIL;
  payload: UpdateAdminNoteByPatientRes;
  errorMsg: string;
  status: number;
}
export interface UpdateAdminNoteByPatientUpdateAPIMsg {
  type: typeof UPDATE_NOTE_UPDATE_API_MSG;
  payload: UpdateAdminNoteByPatientRes;
  successMsg: string;
  errorMsg: string;
  status: number;
}
export interface UpdateAdminNoteByPatientUpdateAPIRes {
  type: typeof UPDATE_NOTE_UPDATE_API_RES;
  payload: UpdateAdminNoteByPatientRes;
  successMsg: string;
  errorMsg: string;
  status: number;
}

export type UpdateAdminNoteByPatientActionTypes =
  | UpdateAdminNoteByPatientLoading
  | UpdateAdminNoteByPatientSuccess
  | UpdateAdminNoteByPatientFail
  | UpdateAdminNoteByPatientUpdateAPIMsg
  | UpdateAdminNoteByPatientUpdateAPIRes;
