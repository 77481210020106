import React from "react";
import { Container, Stack } from "@mui/material";
import DoctorTeamType from "./FilePreparation/SelectDoctor/DoctorTeamType";
import SelectDoctorForCase from "./FilePreparation/SelectDoctor/SelectDoctorForCase";
import SelectedDoctorList from "./FilePreparation/SelectDoctor/SelectedDoctorList";

export default function AppointmentSelectDoctor() {
  return (
    <Container maxWidth="xl">
      <Stack direction="column" justifyContent="flex-start" alignItems="stretch" spacing={2}>
        <DoctorTeamType />
        <SelectDoctorForCase />
        <SelectedDoctorList />
      </Stack>
    </Container>
  );
}
