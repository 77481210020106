import axios from "axios";
import { Dispatch } from "redux";
import Url from "../../Url";
import { setupInterceptorsTo } from "../Authentication/Interceptors";
import {
  addPatientInsuranceActionTypes,
  ADD_PATIENT_INSURANCE_FAIL,
  ADD_PATIENT_INSURANCE_LOADING,
  ADD_PATIENT_INSURANCE_SUCCESS,
} from "./ActionTypes";
import { AddPatientInsuranceBody, AddPatientInsuranceValue } from "./Model";
import { headers } from "components/Utility";

export const addPatientInsuranceApi = (body: AddPatientInsuranceBody) => {
  return function (dispatch: Dispatch<addPatientInsuranceActionTypes>) {
    dispatch({
      type: ADD_PATIENT_INSURANCE_LOADING,
    });

    setupInterceptorsTo(axios)
      .post(Url.apiURL + `/patientapp/api/patient/add_patient_insurance`, body, {
        headers: headers,
      })
      .then((res) => {
        dispatch({
          type: ADD_PATIENT_INSURANCE_SUCCESS,
          payload: res.data,
          errRes: "",
        });
      })
      .catch((error) => {
        dispatch({
          type: ADD_PATIENT_INSURANCE_FAIL,
          payload: {} as AddPatientInsuranceValue,
          errRes: error.response.data.message,
        });
      });
  };
};
