import {
  GetPatientCaseNoteListActionTypes,
  PATIENT_CASE_NOTE_LIST_FAIL,
  PATIENT_CASE_NOTE_LIST_LOADING,
  PATIENT_CASE_NOTE_LIST_SUCCESS,
  GetPatientCaseNoteListState,
  PATIENT_CASE_NOTE_LIST_UPDATE_API_MSG,
  PATIENT_CASE_NOTE_LIST_UPDATE_API_RES,
} from "./ActionTypes";
import { GetPatientCaseNoteListRes } from "./Model";

const initialStateGetPosts: GetPatientCaseNoteListState = {
  loading: false,
  getPatientCaseNoteListRes: {} as GetPatientCaseNoteListRes,
  successMsg: "",
  errorMsg: "",
  status: 0,
};
export const getPatientCaseNoteListReducer = (
  state = initialStateGetPosts,
  action: GetPatientCaseNoteListActionTypes,
): GetPatientCaseNoteListState => {
  switch (action.type) {
    case PATIENT_CASE_NOTE_LIST_LOADING:
      return {
        ...state,
        loading: action.loading,
      };
    case PATIENT_CASE_NOTE_LIST_SUCCESS:
      return {
        ...state,
        getPatientCaseNoteListRes: action.payload,
      };
    case PATIENT_CASE_NOTE_LIST_FAIL:
      return {
        ...state,
        getPatientCaseNoteListRes: action.payload,
        errorMsg: action.errorMsg,
        status: action.status,
      };
    case PATIENT_CASE_NOTE_LIST_UPDATE_API_MSG:
      return {
        ...state,
        getPatientCaseNoteListRes: action.payload,
        errorMsg: action.errorMsg,
        status: action.status,
      };
    case PATIENT_CASE_NOTE_LIST_UPDATE_API_RES:
      return {
        ...state,
        getPatientCaseNoteListRes: action.payload,
        errorMsg: action.errorMsg,
        status: action.status,
      };
    default:
      return state;
  }
};
