import {
  SLEEP_SCHEDULE_OBSERVATION_TYPE_FAIL,
  SLEEP_SCHEDULE_OBSERVATION_TYPE_LOADING,
  SLEEP_SCHEDULE_OBSERVATION_TYPE_SUCCESS,
  SLEEP_SCHEDULE_OBSERVATION_TYPE_UPDATE_API_MSG,
  SLEEP_SCHEDULE_OBSERVATION_TYPE_UPDATE_API_RES,
  SleepScheduleObservationTypeActionTypes,
} from "./ActionTypes";
import { SleepScheduleObservationTypeRes } from "./Model";

export const sleepScheduleObservationTypeLoadingAction = (
  loading: boolean,
): SleepScheduleObservationTypeActionTypes => {
  return {
    type: SLEEP_SCHEDULE_OBSERVATION_TYPE_LOADING,
    loading: loading,
  };
};

export const sleepScheduleObservationTypeSuccessAction = (
  sleepScheduleObservationTypeRes: SleepScheduleObservationTypeRes,
  successMsg: string,
): SleepScheduleObservationTypeActionTypes => {
  return {
    type: SLEEP_SCHEDULE_OBSERVATION_TYPE_SUCCESS,
    payload: sleepScheduleObservationTypeRes,
    successMsg: successMsg,
  };
};

export const sleepScheduleObservationTypeErrorAction = (
  sleepScheduleObservationTypeRes: SleepScheduleObservationTypeRes,
  errMsg: string,
  status: number,
): SleepScheduleObservationTypeActionTypes => {
  return {
    type: SLEEP_SCHEDULE_OBSERVATION_TYPE_FAIL,
    payload: sleepScheduleObservationTypeRes,
    errorMsg: errMsg,
    status: status,
  };
};
export const sleepScheduleObservationTypeUpdateAPIMsgAction = (
  sleepScheduleObservationTypeRes: SleepScheduleObservationTypeRes,
  successMsg: string,
  errMsg: string,
  status: number,
): SleepScheduleObservationTypeActionTypes => {
  return {
    type: SLEEP_SCHEDULE_OBSERVATION_TYPE_UPDATE_API_MSG,
    payload: sleepScheduleObservationTypeRes,
    successMsg: successMsg,
    errorMsg: errMsg,
    status: status,
  };
};

export const sleepScheduleObservationTypeAPIResClearAction = (
  sleepScheduleObservationTypeRes: SleepScheduleObservationTypeRes,
  successMsg: string,
  errMsg: string,
  status: number,
): SleepScheduleObservationTypeActionTypes => {
  return {
    type: SLEEP_SCHEDULE_OBSERVATION_TYPE_UPDATE_API_RES,
    payload: sleepScheduleObservationTypeRes,
    successMsg: successMsg,
    errorMsg: errMsg,
    status: status,
  };
};
