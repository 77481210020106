import {
  ADD_PATIENT_DEMOGRAPHICS_FAIL,
  ADD_PATIENT_DEMOGRAPHICS_LOADING,
  ADD_PATIENT_DEMOGRAPHICS_SUCCESS,
  ADD_PATIENT_DEMOGRAPHICS_UPDATE_API_MSG,
  ADD_PATIENT_DEMOGRAPHICS_UPDATE_API_RES,
  AddDemographicsDetailsActionTypes,
} from "./ActionTypes";
import { AddDemographicsDetailsRes } from "./Model";

export const addPatientDemographicsDetailsLoadingAction = (
  loading: boolean,
): AddDemographicsDetailsActionTypes => {
  return {
    type: ADD_PATIENT_DEMOGRAPHICS_LOADING,
    loading: loading,
  };
};

export const addPatientDemographicsDetailsSuccessAction = (
  addPatientDemographicsDetailsResponse: AddDemographicsDetailsRes,
  successMsg: string,
): AddDemographicsDetailsActionTypes => {
  return {
    type: ADD_PATIENT_DEMOGRAPHICS_SUCCESS,
    payload: addPatientDemographicsDetailsResponse,
    successMsg: successMsg,
  };
};

export const addPatientDemographicsDetailsErrorAction = (
  addPatientDemographicsDetailsResponse: AddDemographicsDetailsRes,
  errMsg: string,
  status: number,
): AddDemographicsDetailsActionTypes => {
  return {
    type: ADD_PATIENT_DEMOGRAPHICS_FAIL,
    payload: addPatientDemographicsDetailsResponse,
    errorMsg: errMsg,
    status: status,
  };
};
export const addPatientDemographicsDetailsUpdateAPIMsgAction = (
  addPatientDemographicsDetailsResponse: AddDemographicsDetailsRes,
  successMsg: string,
  errMsg: string,
  status: number,
): AddDemographicsDetailsActionTypes => {
  return {
    type: ADD_PATIENT_DEMOGRAPHICS_UPDATE_API_MSG,
    payload: addPatientDemographicsDetailsResponse,
    successMsg: successMsg,
    errorMsg: errMsg,
    status: status,
  };
};

export const addPatientDemographicsDetailsAPIResClearAction = (
  addPatientDemographicsDetailsResponse: AddDemographicsDetailsRes,
  successMsg: string,
  errMsg: string,
  status: number,
): AddDemographicsDetailsActionTypes => {
  return {
    type: ADD_PATIENT_DEMOGRAPHICS_UPDATE_API_RES,
    payload: addPatientDemographicsDetailsResponse,
    successMsg: successMsg,
    errorMsg: errMsg,
    status: status,
  };
};
