import axios from "axios";
import { Dispatch } from "redux";
import Url from "../../Url";
import { getPatientDetailsById } from "./Actions";
import {
  PatientDetailsActionType,
  PATIENT_DETAILS_FAIL,
  PATIENT_DETAILS_LOADING,
  PATIENT_DETAILS_SUCCESS,
} from "./ActionTypes";
import { headers } from "components/Utility";

export const getPatientDataDetailsById = (patientDetailsItemid: string) => {
  return function (dispatch: Dispatch<PatientDetailsActionType>) {
    dispatch({
      type: PATIENT_DETAILS_LOADING,
    });

    axios
      .post(
        Url.apiURL + `/adminapp/api/patientDetails/getPatientDetailsFromDashboard`,
        { patientDetailsId: patientDetailsItemid },
        {
          headers: headers,
        },
      )
      .then((res) => {
        let resData = res.data;
        dispatch(getPatientDetailsById(resData));
        dispatch({
          type: PATIENT_DETAILS_SUCCESS,
          payload: resData,
        });
      })
      .catch((_error) => {
        dispatch({
          type: PATIENT_DETAILS_FAIL,
        });
      });
  };
};
