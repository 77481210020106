import { GET_LIST } from "../../types/ActionTypes";
import { PatientDemographicsDetails } from "./Model";

export const PATIENT_DATA_POINT_LOADING = "PATIENT_DATA_POINT_LOADING";
export const PATIENT_DATA_POINT_SUCCESS = "PATIENT_DATA_POINT_SUCCESS";
export const PATIENT_DATA_POINT_FAIL = "PATIENT_DATA_POINT_FAIL";

export interface AddPatientPointDataState {
  patientDemographicsRes: PatientDemographicsDetails;
  loading: boolean;
  pageCount: number;
}
export interface PatientPointDataLoading {
  type: typeof PATIENT_DATA_POINT_LOADING;
}
export interface PatientPointDataSuccess {
  type: typeof PATIENT_DATA_POINT_SUCCESS;
  payload: PatientDemographicsDetails;
  pageCount: number;
}
export interface PatientPointDataFail {
  type: typeof PATIENT_DATA_POINT_FAIL;
}
interface PatientPointDataAction {
  type: typeof GET_LIST;
  payload: PatientDemographicsDetails;
  pageCount: number;
}

export type PatientPointDataListActionType =
  | PatientPointDataAction
  | PatientPointDataLoading
  | PatientPointDataSuccess
  | PatientPointDataFail;
