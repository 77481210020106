import { sharedPatientList } from "./Model";

// Patient Meeting Loading State
export const MEETING_LIST_LOADING = "MEETING_LIST_LOADING";
export const MEETING_LIST_SUCCESS = "MEETING_LIST_SUCCESS";
export const MEETING_LIST_FAIL = "MEETING_LIST_FAIL";
export const MEETING_LIST = "MEETING_LIST";

export interface GetSharedPatientDetails {
  sharedPatientDetailsRes: sharedPatientList;
  loading: boolean;
}
export interface SharePatientListLoading {
  type: typeof MEETING_LIST_LOADING;
}
export interface SharePatientListSuccess {
  type: typeof MEETING_LIST_SUCCESS;
  payload: sharedPatientList;
}
export interface SharePatientListFail {
  type: typeof MEETING_LIST_FAIL;
}
interface SharePatientListAction {
  type: typeof MEETING_LIST;
  payload: sharedPatientList;
}

export type sharedPatientDetailsActionTypes =
  | SharePatientListAction
  | SharePatientListLoading
  | SharePatientListSuccess
  | SharePatientListFail;
