import {
  LanguageListDetails,
  GetLanguageListActionTypes,
  GET_LANGUAGE_LIST_FAIL,
  GET_LANGUAGE_LIST_LOADING,
  GET_LANGUAGE_LIST_SUCCESS,
} from "./ActionTypes";
import { GetLanguageListRes } from "./Model";

const initialStateGetPosts: LanguageListDetails = {
  loading: false,
  getLanguageListRes: {} as GetLanguageListRes,
  errRes: "",
};
export const getLanguageListReducer = (
  state = initialStateGetPosts,
  action: GetLanguageListActionTypes,
): LanguageListDetails => {
  switch (action.type) {
    case GET_LANGUAGE_LIST_SUCCESS:
      return {
        ...state,
        loading: false,
        getLanguageListRes: action.payload,
        errRes: "",
      };
    case GET_LANGUAGE_LIST_LOADING:
      return {
        ...state,
        loading: true,
        getLanguageListRes: {} as GetLanguageListRes,
      };
    case GET_LANGUAGE_LIST_FAIL:
      return {
        ...state,
        getLanguageListRes: {} as GetLanguageListRes,
        loading: false,
        errRes: action.errRes,
      };
    default:
      return state;
  }
};
