import { SleepTimeObservationTypeRes } from "./Model";
export const SLEEP_TIME_OBSERVATION_TYPE_LOADING = "SLEEP_TIME_OBSERVATION_TYPE_LOADING";
export const SLEEP_TIME_OBSERVATION_TYPE_SUCCESS = "SLEEP_TIME_OBSERVATION_TYPE_SUCCESS";
export const SLEEP_TIME_OBSERVATION_TYPE_FAIL = "SLEEP_TIME_OBSERVATION_TYPE_FAIL";
export const SLEEP_TIME_OBSERVATION_TYPE_UPDATE_API_MSG =
  "SLEEP_TIME_OBSERVATION_TYPE_UPDATE_API_MSG";
export const SLEEP_TIME_OBSERVATION_TYPE_UPDATE_API_RES =
  "SLEEP_TIME_OBSERVATION_TYPE_UPDATE_API_RES";

export interface SleepTimeObservationTypeState {
  sleepTimeObservationTypeRes: SleepTimeObservationTypeRes;
  loading: boolean;
  successMsg: string;
  errorMsg: string;
  status: number;
}
export interface SleepTimeObservationTypeLoading {
  type: typeof SLEEP_TIME_OBSERVATION_TYPE_LOADING;
  loading: boolean;
}
export interface SleepTimeObservationTypeSuccess {
  type: typeof SLEEP_TIME_OBSERVATION_TYPE_SUCCESS;
  payload: SleepTimeObservationTypeRes;
  successMsg: string;
}
export interface SleepTimeObservationTypeFail {
  type: typeof SLEEP_TIME_OBSERVATION_TYPE_FAIL;
  payload: SleepTimeObservationTypeRes;
  errorMsg: string;
  status: number;
}
export interface SleepTimeObservationTypeUpdateAPIMsg {
  type: typeof SLEEP_TIME_OBSERVATION_TYPE_UPDATE_API_MSG;
  payload: SleepTimeObservationTypeRes;
  successMsg: string;
  errorMsg: string;
  status: number;
}
export interface SleepTimeObservationTypeUpdateAPIRes {
  type: typeof SLEEP_TIME_OBSERVATION_TYPE_UPDATE_API_RES;
  payload: SleepTimeObservationTypeRes;
  successMsg: string;
  errorMsg: string;
  status: number;
}

export type SleepTimeObservationTypeActionTypes =
  | SleepTimeObservationTypeLoading
  | SleepTimeObservationTypeSuccess
  | SleepTimeObservationTypeFail
  | SleepTimeObservationTypeUpdateAPIMsg
  | SleepTimeObservationTypeUpdateAPIRes;
