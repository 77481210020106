import axios from "axios";
import { Dispatch } from "redux";
import Url from "../../../Url";
import { setupInterceptorsTo } from "../../Authentication/Interceptors";
import { UpdateDemographicsDetailsActionTypes } from "./ActionTypes";
import { UpdateDemographicsDetailsByPatientIdRes, UpdateDemographicsDetailsReq } from "./Model";
import {
  updatePatientDemographicsDetailsAPIResClearAction,
  updatePatientDemographicsDetailsErrorAction,
  updatePatientDemographicsDetailsLoadingAction,
  updatePatientDemographicsDetailsSuccessAction,
  updatePatientDemographicsDetailsUpdateAPIMsgAction,
} from "./Action";
import { headers } from "components/Utility";

let apiRes = {} as UpdateDemographicsDetailsByPatientIdRes;
export const updateDemographicsDetailsByPatientId = (payload: UpdateDemographicsDetailsReq) => {
  return function (dispatch: Dispatch<UpdateDemographicsDetailsActionTypes>) {
    dispatch(updatePatientDemographicsDetailsLoadingAction(true));
    setupInterceptorsTo(axios)
      .post(Url.apiURL + `/patientapp/api/patient/edit_patient_demographics_details`, payload, {
        headers: headers,
      })
      .then((res) => {
        apiRes =
          res.data !== undefined ? res.data : ({} as UpdateDemographicsDetailsByPatientIdRes);
        dispatch(updatePatientDemographicsDetailsLoadingAction(false));
        dispatch(
          updatePatientDemographicsDetailsSuccessAction(
            res.data !== undefined ? res.data : ({} as UpdateDemographicsDetailsByPatientIdRes),
            res.data.message !== undefined
              ? res.data.message
              : "Patient details has been updated successfully",
          ),
        );
      })
      .catch((error) => {
        dispatch(updatePatientDemographicsDetailsLoadingAction(false));
        dispatch(
          updatePatientDemographicsDetailsErrorAction(
            {} as UpdateDemographicsDetailsByPatientIdRes,
            error.response !== undefined
              ? error.response.data.message || error.response.data.detail
              : "Something went wrong",
            error.status !== undefined ? error.response.data.status || -1 : -1,
          ),
        );
      });
  };
};

export const updateEditDemographicsDetailsAPIResMsg = () => {
  return function (dispatch: Dispatch<UpdateDemographicsDetailsActionTypes>) {
    dispatch(
      updatePatientDemographicsDetailsUpdateAPIMsgAction(
        apiRes as UpdateDemographicsDetailsByPatientIdRes,
        "",
        "",
        0,
      ),
    );
  };
};

export const clearUpdateDemographicsDetailsAPIRes = () => {
  return function (dispatch: Dispatch<UpdateDemographicsDetailsActionTypes>) {
    dispatch(
      updatePatientDemographicsDetailsAPIResClearAction(
        {} as UpdateDemographicsDetailsByPatientIdRes,
        "",
        "",
        0,
      ),
    );
  };
};
