import {
  categoryDetails,
  categoryActionTypes,
  CATEGORY_LIST_FAIL,
  CATEGORY_LIST_LOADING,
  CATEGORY_LIST_SUCCESS,
} from "./ActionTypes";
import { CategoryResponse } from "./Model";

const initialStateGetPosts: categoryDetails = {
  loading: false,
  doctorCategoryList: {} as CategoryResponse,
  errRes: "",
};
export const categoryListReducer = (
  state = initialStateGetPosts,
  action: categoryActionTypes,
): categoryDetails => {
  switch (action.type) {
    case CATEGORY_LIST_SUCCESS:
      return {
        ...state,
        loading: false,
        doctorCategoryList: action.payload,
        errRes: "",
      };
    case CATEGORY_LIST_LOADING:
      return {
        ...state,
        loading: true,
        doctorCategoryList: {} as CategoryResponse,
      };
    case CATEGORY_LIST_FAIL:
      return {
        ...state,
        doctorCategoryList: {} as CategoryResponse,
        loading: false,
        errRes: action.errRes,
      };
    default:
      return state;
  }
};
