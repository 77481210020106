import { DeleteDoctorRes } from "./Model";
export const DELETE_DOCTOR_LOADING = "DELETE_DOCTOR_LOADING";
export const DELETE_DOCTOR_SUCCESS = "DELETE_DOCTOR_SUCCESS";
export const DELETE_DOCTOR_FAIL = "DELETE_DOCTOR_FAIL";
export const DELETE_DOCTOR_DATA = "DELETE_DOCTOR_DATA";

export interface DeleteDoctorDetails {
  getDeleteDoctorRes: DeleteDoctorRes;
  loading: boolean;
  errRes: string;
}
export interface DeleteDoctorActionLoading {
  type: typeof DELETE_DOCTOR_LOADING;
}
export interface DeleteDoctorActionSuccess {
  type: typeof DELETE_DOCTOR_SUCCESS;
  payload: DeleteDoctorRes;
  errRes: string;
}
export interface DeleteDoctorActionFail {
  type: typeof DELETE_DOCTOR_FAIL;
  payload: DeleteDoctorRes;
  errRes: string;
}
interface DeleteDoctorActionAction {
  type: typeof DELETE_DOCTOR_DATA;
  payload: DeleteDoctorRes;
  errRes: string;
}

export type DeleteDoctorActionTypes =
  | DeleteDoctorActionAction
  | DeleteDoctorActionLoading
  | DeleteDoctorActionSuccess
  | DeleteDoctorActionFail;
