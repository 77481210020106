import { GetInsuranceDetalsByPatientValue } from "./Model";

export const GET_INSURANCE_DETAILS_BY_PATIENT_LOADING = "GET_INSURANCE_DETAILS_BY_PATIENT_LOADING";
export const GET_INSURANCE_DETAILS_BY_PATIENT_SUCCESS = "GET_INSURANCE_DETAILS_BY_PATIENT_SUCCESS";
export const GET_INSURANCE_DETAILS_BY_PATIENT_FAIL = "GET_INSURANCE_DETAILS_BY_PATIENT_FAIL";
export const GET_INSURANCE_DETAILS_VALUE = "GET_INSURANCE_DETAILS_VALUE";

export interface GetInsuranceDetalsByPatientDetails {
  getInsuranceDetailsRes: GetInsuranceDetalsByPatientValue;
  loading: boolean;
  errRes: string;
}
export interface GetInsuranceDetailsByPatientLoading {
  type: typeof GET_INSURANCE_DETAILS_BY_PATIENT_LOADING;
}
export interface GetInsuranceDetailsByPatientSuccess {
  type: typeof GET_INSURANCE_DETAILS_BY_PATIENT_SUCCESS;
  payload: GetInsuranceDetalsByPatientValue;
  errRes: string;
}
export interface GetInsuranceDetailsByPatientFail {
  type: typeof GET_INSURANCE_DETAILS_BY_PATIENT_FAIL;
  errRes: string;
}

interface GetInsuranceDetailsByPatientAction {
  type: typeof GET_INSURANCE_DETAILS_VALUE;
  payload: GetInsuranceDetalsByPatientValue;
  errRes: string;
}

export type getInsuranceDetailsByPatientActionTypes =
  | GetInsuranceDetailsByPatientAction
  | GetInsuranceDetailsByPatientLoading
  | GetInsuranceDetailsByPatientSuccess
  | GetInsuranceDetailsByPatientFail;
