import { PaletteOptions } from "@mui/material";

export const lightPalette: PaletteOptions = {
  mode: "light",
  primary: {
    main: "#3f51b5",
  },
  secondary: {
    main: "#f50057",
  },
  background: {
    default: "rgb(244, 246, 254)",
    paper: "rgb(244, 246, 254)",
  },
  success: {
    main: "#67be23",
    contrastText: "#fff",
  },
  error: {
    main: "#ee2a1e",
    contrastText: "#fff",
  },
  warning: {
    main: "#fa8c16",
    contrastText: "#fff",
  },
  info: {
    main: "#1890ff",
    contrastText: "#fff",
  },
  text: {
    primary: "#000",
    secondary: "#3f51b5",
    disabled: "#d1d1d1",
  },
};
