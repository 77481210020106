import axios from "axios";
import { Dispatch } from "redux";
import Url from "../../../Url";
import { setupInterceptorsTo } from "../../Authentication/Interceptors";
import {
  specialistActionTypes,
  SPECIALIST_LIST_FAIL,
  SPECIALIST_LIST_LOADING,
  SPECIALIST_LIST_SUCCESS,
} from "./ActionTypes";
import { SpecialistResponse } from "./Model";
import { headers } from "components/Utility";

export const speciaListApi = () => {
  return function (dispatch: Dispatch<specialistActionTypes>) {
    dispatch({
      type: SPECIALIST_LIST_LOADING,
    });

    setupInterceptorsTo(axios)
      .get(Url.apiURL + `/doctorapp/api/onboarding/speciality_list`, { headers: headers })
      .then((res) => {
        dispatch({
          type: SPECIALIST_LIST_SUCCESS,
          payload: res.data,
          errRes: "",
        });
      })
      .catch((error) => {
        dispatch({
          type: SPECIALIST_LIST_FAIL,
          payload: {} as SpecialistResponse,
          errRes: error.response.data.message,
        });
      });
  };
};
