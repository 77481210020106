import {
  HealthcampPatientGetActionTypes,
  HCPATIENT_LIST_FAIL,
  HCPATIENT_LIST_LOADING,
  HCPATIENT_LIST_SUCCESS,
  GetHealthcampPatientStateType,
} from "./ActionTypes";
import { HealthcampPatientList } from "./Model";

const initialStateGetHealthcampPatient: GetHealthcampPatientStateType = {
  loading: false,
  healthcampPatientValue: {} as HealthcampPatientList,
  pageCount: Object.assign({}),
};

export const getHealthcampPatientReducer = (
  state = initialStateGetHealthcampPatient,
  action: HealthcampPatientGetActionTypes,
): GetHealthcampPatientStateType => {
  switch (action.type) {
    case HCPATIENT_LIST_SUCCESS:
      return {
        ...state,
        loading: false,
        healthcampPatientValue: action.payload,
        pageCount: action.pageCount,
      };
    case HCPATIENT_LIST_LOADING:
      return {
        ...state,
        loading: true,
        healthcampPatientValue: {} as HealthcampPatientList,
      };
    case HCPATIENT_LIST_FAIL:
      return {
        ...state,
        healthcampPatientValue: {} as HealthcampPatientList,
        loading: false,
      };
    default:
      return state;
  }
};
