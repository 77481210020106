import { SpecialistResponse } from "./Model";

export const SPECIALIST_LIST_LOADING = "SPECIALIST_LIST_LOADING";
export const SPECIALIST_LIST_SUCCESS = "SPECIALIST_LIST_SUCCESS";
export const SPECIALIST_LIST_FAIL = "SPECIALIST_LIST_FAIL";
export const SPECIALIST_LIST = "SPECIALIST_LIST";

export interface specialistDetails {
  specialistList: SpecialistResponse;
  loading: boolean;
  errRes: string;
}
export interface specialistLoading {
  type: typeof SPECIALIST_LIST_LOADING;
}
export interface specialistSuccess {
  type: typeof SPECIALIST_LIST_SUCCESS;
  payload: SpecialistResponse;
  errRes: string;
}
export interface specialistFail {
  type: typeof SPECIALIST_LIST_FAIL;
  errRes: string;
}

interface specialistAction {
  type: typeof SPECIALIST_LIST;
  payload: SpecialistResponse;
  errRes: string;
}

export type specialistActionTypes =
  | specialistAction
  | specialistLoading
  | specialistSuccess
  | specialistFail;
