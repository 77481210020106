import { GetDemographicsDetailsByPtIdRes } from "./Model";

export const GET_DEMOGRAPHICS_DETAILS_BY_PT_ID_LOADING =
  "GET_DEMOGRAPHICS_DETAILS_BY_PT_ID_LOADING";
export const GET_DEMOGRAPHICS_DETAILS_BY_PT_ID_SUCCESS =
  "GET_DEMOGRAPHICS_DETAILS_BY_PT_ID_SUCCESS";
export const GET_DEMOGRAPHICS_DETAILS_BY_PT_ID_FAIL = "GET_DEMOGRAPHICS_DETAILS_BY_PT_ID_FAIL";
export const GET_DEMOGRAPHICS_DETAILS_BY_PT_ID_DATA = "GET_DEMOGRAPHICS_DETAILS_BY_PT_ID_DATA";

export interface DemographicsDetailsByPtIdDetails {
  getDemographicsDetailsByPtIdRes: GetDemographicsDetailsByPtIdRes;
  loading: boolean;
  errRes: string;
}
export interface GetDemographicsDetailsByPtIdActionLoading {
  type: typeof GET_DEMOGRAPHICS_DETAILS_BY_PT_ID_LOADING;
}
export interface GetDemographicsDetailsByPtIdActionSuccess {
  type: typeof GET_DEMOGRAPHICS_DETAILS_BY_PT_ID_SUCCESS;
  payload: GetDemographicsDetailsByPtIdRes;
  errRes: string;
}
export interface GetDemographicsDetailsByPtIdActionFail {
  type: typeof GET_DEMOGRAPHICS_DETAILS_BY_PT_ID_FAIL;
  payload: GetDemographicsDetailsByPtIdRes;
  errRes: string;
}
interface GetDemographicsDetailsByPtIdActionAction {
  type: typeof GET_DEMOGRAPHICS_DETAILS_BY_PT_ID_DATA;
  payload: GetDemographicsDetailsByPtIdRes;
  errRes: string;
}

export type GetDemographicsDetailsByPtIdActionTypes =
  | GetDemographicsDetailsByPtIdActionAction
  | GetDemographicsDetailsByPtIdActionLoading
  | GetDemographicsDetailsByPtIdActionSuccess
  | GetDemographicsDetailsByPtIdActionFail;
