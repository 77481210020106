import React, { useEffect, useState } from "react";
import {
  Box,
  Button,
  Dialog,
  Grid,
  TextField,
  DialogTitle,
  IconButton,
  Typography,
  Alert,
  Stack,
  AlertColor,
  DialogContent,
  DialogActions,
  Autocomplete,
} from "@mui/material";
import { DesktopDatePicker, DesktopTimePicker, LocalizationProvider } from "@mui/x-date-pickers";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import { useDispatch, useSelector } from "react-redux";
import CloseIcon from "@mui/icons-material/Close";
import { AppState } from "../../../redux/store/Store";
import dayjs, { Dayjs } from "dayjs";
import { useParams } from "react-router-dom";
import {
  addPatientDataDetails,
  addPatientDataDetailsUpdateAPIResMsg,
  clearPatientDataPointClearAPIRes,
} from "../../../redux/patientDataDetails/addPatientDetails/API";
import { getPatientDataDetailsList } from "../../../redux/patientDataDetails/patientDataPointListByPatientID/API";
import { UpdatePatientDataDetailsBody } from "../../../redux/patientDataDetails/UpdatePatientDataPoint/Model";
import {
  clearPatientDataPointEditAPIRes,
  updatePatientDataDetailsApi,
  updatePatientDataDetailsEditAPIResMsg,
} from "../../../redux/patientDataDetails/UpdatePatientDataPoint/API";
import { AddPatientDataDetailsBody } from "../../../redux/patientDataDetails/addPatientDetails/Model";
import { clearImageUploadAPIRes } from "../../../redux/ImageUpload/API";
import MWSnackbar from "../../../components/MWSnackbar";
import MWUploader from "../../../components/MWUploader";
import { getPatientDataDetailsById } from "../../../redux/patientDetailsById/API";
import MWLoader from "../../../components/MWLoader";
import { ModifiedObservationTypeList } from "./Model";
import { formatTimeValue } from "components/Utility";
import { ObservationTypeList } from "redux/GetObservationTypeList/Model";
import { getObservationTypeDetails } from "../../../redux/GetObservationTypeList/API";
type Props = {
  itemId?: string | any;
  addDataPointDialogEnable?: boolean | any;
  addDataPointDialogUpdateState?: boolean | any;
  value?: any;
  page?: number | any;
};

export default function AddDataPointDialog({
  itemId,
  addDataPointDialogEnable,
  addDataPointDialogUpdateState,
  value,
  page,
}: Props) {
  const dispatch = useDispatch();
  const [imageId, setImageId] = React.useState("");
  const { patientid } = useParams() as {
    patientid: string;
  };

  const [dataPoint, setDataPoint] = React.useState("");

  const [selectedDate, setSelectedDate] = React.useState<Date | any>(new Date());
  const handleDateChange = (date: Date | null) => {
    setSelectedDate(date);
  };
  const [selectedItem, setSelectedItem] = React.useState("");
  const [dialogState, setDialogState] = React.useState(false);

  const handleDialogClose = () => {
    setDialogState(false);
    addDataPointDialogUpdateState(false);
    setDataPoint("");
    setSelectedDate(new Date());
    dispatch(clearPatientDataPointClearAPIRes());
    dispatch(clearPatientDataPointEditAPIRes());
    dispatch(clearImageUploadAPIRes());
  };

  const updateDataStateFromChild = async (passedval: any) => {
    setImageId(passedval);
  };

  const closeSummaryBanner = () => {
    dispatch(addPatientDataDetailsUpdateAPIResMsg());
    dispatch(updatePatientDataDetailsEditAPIResMsg());
  };

  const [updateDataPointSuccess, setUpdateDataPointSuccess] = React.useState("");
  const [alertType, setAlertType] = useState<AlertColor>("success");
  const [updateDataPointError, setUpdateDataPointError] = useState("");
  const updateDataPointSuccessDiv = updateDataPointError ? (
    <Alert
      variant="outlined"
      severity={alertType}
      onClose={() => {
        closeSummaryBanner();
      }}
    >
      <Typography>{updateDataPointError}</Typography>
    </Alert>
  ) : null;
  // Get PreviewImg Details
  const viewPreviewImageData = (passedVal: any) => {
    console.log("passedVal", passedVal);
  };

  const [observationList, setObservationList] = useState([] as ModifiedObservationTypeList[]);
  const getObservationTypeListRes = useSelector((state: AppState) => state.getObservationTypeValue);
  useEffect(() => {
    if (getObservationTypeListRes?.GetObservationTypeResponse?.observationTypeList !== undefined) {
      let observationTypeListItem =
        getObservationTypeListRes?.GetObservationTypeResponse?.observationTypeList.map(
          (element: ObservationTypeList) => ({
            value: element.id,
            label: element.displayValue !== "" ? element.displayValue : "",
            name: element.name,
          }),
        );
      setObservationList(observationTypeListItem);
    } else setObservationList([] as ModifiedObservationTypeList[]);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [getObservationTypeListRes]);
  const [selectedObservation, setSelectedObservation] =
    useState<ModifiedObservationTypeList | null>(null);
  const changeSelectedObservation = (passedVal: ModifiedObservationTypeList | null) => {
    setSelectedObservation(passedVal);
    setDataPoint(
      passedVal !== null
        ? dataPoint.length > 0
          ? dataPoint.concat("; ", `${passedVal!.name}: `)
          : `${passedVal!.name}: `
        : dataPoint,
    );
  };
  const [endTime, setEndTime] = React.useState<Dayjs | null | any>(dayjs().format());
  const [startTime, setStartTime] = React.useState<Dayjs | null | any>(dayjs().format());
  const [sleepScheduleTime, setSleepScheduleTime] = React.useState("");
  useEffect(() => {
    if (selectedObservation?.name === "sleepschedule") {
      let scheduleStartTime = formatTimeValue(startTime);
      let scheduleEndTime = formatTimeValue(endTime);
      const timeRange = `${scheduleStartTime} ~ ${scheduleEndTime}`;
      setSleepScheduleTime(timeRange);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [selectedObservation, startTime, endTime]);

  const dataPointBody = {
    patientId: patientid!,
    patientDocumentId: imageId!,
    startDate: dayjs(String(selectedDate!)).format("YYYY-MM-DDTHH:mm:ss"),
    description: dataPoint!,
  } as AddPatientDataDetailsBody;
  const [editImageId, setEditImageId] = useState("");

  useEffect(() => {
    setSelectedItem(itemId);
    setDialogState(addDataPointDialogEnable);
    if (addDataPointDialogEnable !== undefined) {
      setDataPoint("");
      setSelectedDate(new Date());
      dispatch(clearPatientDataPointClearAPIRes());
      dispatch(clearPatientDataPointEditAPIRes());
      dispatch(clearImageUploadAPIRes());
      dispatch(getObservationTypeDetails());
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [itemId, addDataPointDialogEnable]);

  const addDataDetailsStoreVal = useSelector((state: AppState) => state.addPointDataDetailsRes);
  useEffect(() => {
    console.log("valueDIalog", value);
    if (value !== undefined) {
      if (value.description !== undefined) {
        setDataPoint(value.description);
      } else setDataPoint("");
      setSelectedDate(value?.startDate !== undefined ? value?.startDate : null);
      setEditImageId(value?.imageId !== undefined ? value?.imageId : "");
    } else {
      setDataPoint("");
      setSelectedDate(new Date());
      setEditImageId("");
    }
  }, [value]);
  React.useEffect(() => {
    if (addDataDetailsStoreVal?.addPatientDataDetailsRes.message !== undefined) {
      setUpdateDataPointSuccess(addDataDetailsStoreVal?.addPatientDataDetailsRes.message);
      handleDialogClose();
      dispatch(getPatientDataDetailsList(patientid, page));
    }
    if (addDataDetailsStoreVal?.message !== "") {
      setAlertType("error");
      setUpdateDataPointError(addDataDetailsStoreVal?.message);
    } else {
      setAlertType("success");
      setUpdateDataPointSuccess(addDataDetailsStoreVal?.message);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [addDataDetailsStoreVal]);
  const updateDataDetailsVal = useSelector((state: AppState) => state.updatePatientDataDetailsRes);
  React.useEffect(() => {
    if (updateDataDetailsVal?.updatePatientDataDetailsRes?.message !== undefined) {
      setUpdateDataPointSuccess(updateDataDetailsVal?.updatePatientDataDetailsRes?.message);
      handleDialogClose();
      dispatch(getPatientDataDetailsList(patientid, page));
      if (selectedItem !== undefined) {
        dispatch(getPatientDataDetailsById(selectedItem));
      }
    }
    if (updateDataDetailsVal?.message !== "") {
      setAlertType("error");
      setUpdateDataPointError(updateDataDetailsVal?.message);
    } else {
      setAlertType("success");
      setUpdateDataPointSuccess(addDataDetailsStoreVal?.message);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [updateDataDetailsVal]);
  const imageUploadRes = useSelector((state: AppState) => state.imageUploading);

  let editImgId = imageId !== undefined ? imageId! : editImageId;
  const editDataPointBody = {
    patientDetailsId: String(value?.id!),
    description: `${dataPoint!}${sleepScheduleTime}`,
    startDate: dayjs(String(selectedDate)).format("YYYY-MM-DDTHH:mm:ss"),
    patientDocumentId: String(editImgId),
  } as UpdatePatientDataDetailsBody;

  const submitPatientData = () => {
    value?.id === undefined
      ? dispatch(addPatientDataDetails(dataPointBody))
      : dispatch(updatePatientDataDetailsApi(editDataPointBody));
  };
  const delImgAction = (passeVal: boolean) => {
    if (passeVal) {
      dispatch(clearImageUploadAPIRes());
    }
  };
  const checkAlertClose = (passedVal: boolean) => {
    if (passedVal) {
      dispatch(clearPatientDataPointClearAPIRes());
      dispatch(clearPatientDataPointEditAPIRes());
    }
  };

  return (
    <Box>
      <Dialog
        onClose={() => null}
        aria-labelledby="customized-dialog-title"
        open={dialogState}
        fullWidth
      >
        <Box>
          {updateDataDetailsVal?.loading || addDataDetailsStoreVal?.loading ? <MWLoader /> : null}
        </Box>
        <DialogTitle id="customized-dialog-title">
          <Grid
            container
            direction="row"
            justifyContent="space-between"
            alignItems="center"
            spacing={2}
          >
            <Grid item>
              <Typography variant="h4">
                {value?.id !== undefined ? "Update " : "Add "}Patient Details
              </Typography>
            </Grid>
            <Grid item>
              <IconButton
                aria-label="close"
                onClick={() => handleDialogClose()}
                sx={{
                  position: "absolute",
                  right: 8,
                  top: 8,
                  color: "grey",
                }}
              >
                <CloseIcon />
              </IconButton>
            </Grid>
          </Grid>
        </DialogTitle>
        <DialogContent dividers>
          <Grid
            container
            direction="row"
            justifyContent="space-between"
            alignItems="flex-start"
            spacing={2}
          >
            <Grid item xs={12} sm={12} md={6} lg={6} xl={6}>
              <Stack
                direction="column"
                justifyContent="space-between"
                alignItems="flex-start"
                spacing={2}
              >
                <Autocomplete
                  size="small"
                  fullWidth
                  disabled={addDataDetailsStoreVal?.loading || updateDataDetailsVal?.loading}
                  value={selectedObservation}
                  options={observationList}
                  onChange={(_event: any, newValue: any) => {
                    changeSelectedObservation(newValue);
                  }}
                  renderInput={(params: any) => (
                    <TextField {...params} label="Observation Type" variant="outlined" fullWidth />
                  )}
                  getOptionLabel={(option: any) => `${option?.label}`}
                  isOptionEqualToValue={(option: any, value: any) => option.value === value.value}
                />
                <TextField
                  fullWidth
                  autoFocus
                  id="data"
                  label="Data"
                  variant="outlined"
                  value={dataPoint}
                  onChange={(e) => {
                    setDataPoint(e.target.value);
                  }}
                  inputProps={{ style: { fontSize: 15 } }}
                  InputLabelProps={{ style: { fontSize: 15 } }}
                  size="small"
                />
                {selectedObservation?.name === "sleepschedule" && (
                  <LocalizationProvider dateAdapter={AdapterDayjs}>
                    <DesktopTimePicker
                      label="Start Time"
                      value={startTime}
                      onChange={(newValue) => {
                        setStartTime(newValue);
                      }}
                      renderInput={(params) => (
                        <TextField {...params} error={false} size="small" fullWidth />
                      )}
                    />
                  </LocalizationProvider>
                )}
                {selectedObservation?.name === "sleepschedule" && (
                  <LocalizationProvider dateAdapter={AdapterDayjs}>
                    <DesktopTimePicker
                      label="End Time"
                      value={endTime}
                      onChange={(newValue) => setEndTime(newValue)}
                      renderInput={(params) => (
                        <TextField {...params} error={false} size="small" fullWidth />
                      )}
                    />
                  </LocalizationProvider>
                )}
                <LocalizationProvider dateAdapter={AdapterDayjs}>
                  <DesktopDatePicker
                    inputFormat="DD/MM/YYYY"
                    value={selectedDate}
                    onChange={(date) => handleDateChange(date)}
                    renderInput={(params: any) => (
                      <TextField
                        {...params}
                        fullWidth
                        inputProps={{ ...params.inputProps, readOnly: true }}
                        size="small"
                      />
                    )}
                    InputAdornmentProps={{ position: "start" }}
                  />
                </LocalizationProvider>
              </Stack>
            </Grid>
            <Grid item xs={12} sm={12} md={6} lg={6} xl={6}>
              <MWUploader
                documentType="patient-image"
                criteria="Upload a file less than 10 mb."
                updatedDataState={updateDataStateFromChild}
                title={"Upload Patient Observation"}
                imageUrl={value?.patientDocument?.document}
                deleteImgAction={delImgAction}
                previewImageData={viewPreviewImageData}
              />
            </Grid>
          </Grid>
          <Box>{updateDataPointSuccessDiv}</Box>
        </DialogContent>
        <DialogActions>
          <Button
            autoFocus
            onClick={submitPatientData}
            variant="contained"
            disabled={
              imageUploadRes?.loading ||
              addDataDetailsStoreVal?.loading ||
              updateDataDetailsVal?.loading
            }
          >
            {value?.id !== undefined ? "Update" : "Save"}
          </Button>
        </DialogActions>
      </Dialog>
      <MWSnackbar msg={updateDataPointSuccess} type={alertType} alertClose={checkAlertClose} />
    </Box>
  );
}

// import React, { useEffect, useState } from "react";
// import Button from "@mui/material/Button";
// import Dialog from "@mui/material/Dialog";
// import {
//   Box,
//   Grid,
//   TextField,
//   DialogTitle,
//   IconButton,
//   Typography,
//   DialogContent,
//   DialogActions,
//   Autocomplete,
// } from "@mui/material";
// import { DesktopDatePicker, DesktopTimePicker, LocalizationProvider } from "@mui/x-date-pickers";
// import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
// import { useDispatch, useSelector } from "react-redux";
// import CloseIcon from "@mui/icons-material/Close";
// import { AppState } from "../../../redux/store/Store";
// import MWUploader from "../../../components/MWUploader";
// import dayjs, { Dayjs } from "dayjs";
// import { useLocation, useParams } from "react-router-dom";
// import MWLoader from "components/MWLoader";
// import {
//   addPatientDataDetails,
//   addPatientDataDetailsUpdateAPIResMsg,
//   clearPatientDataPointClearAPIRes,
// } from "../../../redux/patientDataDetails/addPatientDetails/API";
// import { getPatientDataDetailsList } from "../../../redux/patientDataDetails/patientDataPointListByPatientID/API";
// import { UpdatePatientDataDetailsBody } from "../../../redux/patientDataDetails/UpdatePatientDataPoint/Model";
// import {
//   clearPatientDataPointEditAPIRes,
//   updatePatientDataDetailsApi,
//   updatePatientDataDetailsEditAPIResMsg,
// } from "../../../redux/patientDataDetails/UpdatePatientDataPoint/API";
// import { AddPatientDataDetailsBody } from "../../../redux/patientDataDetails/addPatientDetails/Model";
// import { clearImageUploadAPIRes } from "../../../redux/ImageUpload/API";
// import { getPatientDataDetailsById } from "../../../redux/patientDetailsById/API";
// import {
//   ModifiedObservationTypeList,
//   ModifiedPatientDetailsTypeList,
//   ModifiedPendingPatientDetailsTypeList,
//   SelectPatientDetailsTypeList,
// } from "./Model";
// import { getObservationTypeDetails } from "../../../redux/GetObservationTypeList/API";
// import { DataListByObservationTypeBody } from "redux/patientDashboardDataListByObservationType/Model";
// import { dataListByObservationType } from "redux/patientDashboardDataListByObservationType/ApiCall";
// // import { PatientDetailsFromDashboardBody } from "../../../redux/patientData" //"../../../redux/patientDataDetails/patientDetailsById/Model";
// import { PatientDetailsTypeList } from "../../../redux/GetPatientDetailsTypeOfSession/PatientDetailsTypeList/Model";
// import { patientDetailsTypeList } from "../../../redux/GetPatientDetailsTypeOfSession/PatientDetailsTypeList/API";
// import { formatTimeValue } from "../../../components/Utility";
// import { ObservationTypeList } from "redux/GetObservationTypeList/Model";

// type Props = {
//   itemId?: string | any;
//   addDataPointDialogEnable?: boolean | any;
//   addDataPointDialogUpdateState?: boolean | any;
//   value?: any;
//   page?: number | any;
//   sessionId?: any;
// };

// export default function AddDataPointDialog({
//   itemId,
//   addDataPointDialogEnable,
//   addDataPointDialogUpdateState,
//   value,
//   page,
//   sessionId,
// }: Props) {
//   const detailsTypeList = [
//     {
//       value: 1,
//       label: "Pending Madatory Patient Details Type",
//     },
//     {
//       value: 2,
//       label: "All Patient Details Type",
//     },
//   ] as SelectPatientDetailsTypeList[];
//   const dispatch = useDispatch();
//   const [imageId, setImageId] = React.useState("");
//   const { patientid } = useParams() as {
//     patientid: string;
//   };
//   const patientId = patientid.split("-")[0];

//   const { observationtype } = useParams() as {
//     observationtype: string;
//   };
//   const location = useLocation();
//   const [dataPoint, setDataPoint] = React.useState("");

//   const [selectedDate, setSelectedDate] = React.useState<Date | any>(new Date());
//   const handleDateChange = (date: Date | null) => {
//     setSelectedDate(date);
//   };
//   const [selectedItem, setSelectedItem] = React.useState("");
//   const [dialogState, setDialogState] = React.useState(false);
//   const [endTime, setEndTime] = React.useState<Dayjs | null | any>(dayjs().format());
//   const [startTime, setStartTime] = React.useState<Dayjs | null | any>(dayjs().format());
//   const [sleepScheduleTime, setSleepScheduleTime] = React.useState("");
//   const handleDialogClose = () => {
//     setDialogState(false);
//     addDataPointDialogUpdateState(false);
//     setDataPoint("");
//     setSelectedDate(new Date());
//     dispatch(clearPatientDataPointClearAPIRes());
//     dispatch(clearPatientDataPointEditAPIRes());
//     dispatch(clearImageUploadAPIRes());
//     dispatch(addPatientDataDetailsUpdateAPIResMsg());
//     dispatch(updatePatientDataDetailsEditAPIResMsg());
//   };

//   const updateDataStateFromChild = async (passedval: any) => {
//     setImageId(passedval);
//   };
//   const [patientDetailsTypeListValue, setPatientDetailsTypeListValue] = React.useState([] as any[]);
//   const [selectedPatientDetailsType, setSelectedPatientDetailsType] =
//     React.useState<ModifiedPatientDetailsTypeList | null>(null);
//   const handlePatientDetailsTypeChange = (value: ModifiedPatientDetailsTypeList | null) => {
//     setSelectedPatientDetailsType(value);
//   };
//   const [selectDetailsType, setSelectDetailsType] = React.useState<SelectPatientDetailsTypeList>({
//     value: 1,
//     label: "Pending Madatory Patient Details Type",
//   });
//   const handleSelectPatientDetailsTypeChange = (value: SelectPatientDetailsTypeList) => {
//     setSelectDetailsType(value);
//   };

//   const [selectedPendingPatientDetailsType, setSelectedPendingPatientDetailsType] =
//     React.useState<ModifiedPendingPatientDetailsTypeList | null>(null);
//   const handlePendingPatientDetailsTypeChange = (
//     value: ModifiedPendingPatientDetailsTypeList | null,
//   ) => {
//     setSelectedPendingPatientDetailsType(value);
//   };
//   const [observationList, setObservationList] = useState([] as ModifiedObservationTypeList[]);
//   const [selectedObservation, setSelectedObservation] =
//     useState<ModifiedObservationTypeList | null>(null);
//   useEffect(() => {
//     if (selectedObservation?.name === "sleepschedule") {
//       let scheduleStartTime = formatTimeValue(startTime);
//       let scheduleEndTime = formatTimeValue(endTime);
//       const timeRange = `${scheduleStartTime} ~ ${scheduleEndTime}`;
//       setSleepScheduleTime(timeRange);
//     }
//     // eslint-disable-next-line react-hooks/exhaustive-deps
//   }, [selectedObservation, startTime, endTime]);
//   const dataPointBody = {
//     patientId: patientId!,
//     patientDocumentId: imageId!,
//     startDate: dayjs(String(selectedDate!)).format("YYYY-MM-DDTHH:mm:ss"),
//     description: `${dataPoint!}${sleepScheduleTime}`,
//     patientDetailsType:
//       (selectedPatientDetailsType !== undefined && selectedPatientDetailsType?.value) ||
//       (selectedPendingPatientDetailsType !== undefined && selectedPendingPatientDetailsType?.value),
//   } as AddPatientDataDetailsBody;
//   const [editImageId, setEditImageId] = useState("");

//   useEffect(() => {
//     if (sessionId !== undefined) {
//       handleSelectPatientDetailsTypeChange({
//         value: 1,
//         label: "Pending Madatory Patient Details Type",
//       });
//     } else {
//       handleSelectPatientDetailsTypeChange({
//         value: 2,
//         label: "All Patient Details Type",
//       });
//     }
//     setSelectedItem(itemId);
//     setDialogState(addDataPointDialogEnable);
//     if (addDataPointDialogEnable !== undefined) {
//       setDataPoint("");
//       setSelectedDate(new Date());
//       dispatch(clearPatientDataPointClearAPIRes());
//       dispatch(clearPatientDataPointEditAPIRes());
//       dispatch(clearImageUploadAPIRes());
//       dispatch(getObservationTypeDetails());
//       dispatch(patientDetailsTypeList());
//     }
//     // eslint-disable-next-line react-hooks/exhaustive-deps
//   }, [itemId, addDataPointDialogEnable]);

//   const addDataDetailsStoreVal = useSelector((state: AppState) => state.addPointDataDetailsRes);
//   useEffect(() => {
//     if (value !== undefined) {
//       if (value.description !== undefined) {
//         setDataPoint(value.description);
//       }
//       setSelectedDate(value?.startDate !== undefined ? value?.startDate : null);
//       setEditImageId(value?.imageId !== undefined ? value?.imageId : "");
//       let pendingValues = {
//         value: value?.sectionTypeId,
//         label: value?.sectionType,
//       } as ModifiedPendingPatientDetailsTypeList;
//       setSelectedPendingPatientDetailsType(pendingValues);
//       let selectListValues = {
//         value: value?.sectionTypeId,
//         label: value?.sectionType,
//       } as ModifiedPatientDetailsTypeList;
//       setSelectedPatientDetailsType(selectListValues);
//     } else {
//       setDataPoint("");
//       setSelectedDate(new Date());
//       setEditImageId("");
//       setSelectedPendingPatientDetailsType(null);
//       setSelectedPatientDetailsType(null);
//     }
//   }, [value]);
//   const patientDetailsTypeListRes = useSelector((state: AppState) => state.patientDetailsType);
//   useEffect(() => {
//     if (
//       patientDetailsTypeListRes?.patientDetailsTypeListRes?.patientDetailsTypeList !== undefined
//     ) {
//       let patientDetailsTypeDropdownValue =
//         patientDetailsTypeListRes?.patientDetailsTypeListRes?.patientDetailsTypeList.map(
//           (element: PatientDetailsTypeList) => ({
//             value: element.id,
//             label: element.displayValue !== "" ? element.displayValue : "",
//           }),
//         );
//       setPatientDetailsTypeListValue(patientDetailsTypeDropdownValue);
//     } else setPatientDetailsTypeListValue([] as ModifiedPatientDetailsTypeList[]);
//     // eslint-disable-next-line react-hooks/exhaustive-deps
//   }, [patientDetailsTypeListRes]);

//   React.useEffect(() => {
//     if (addDataDetailsStoreVal?.message !== "" || undefined) {
//       handleDialogClose();

//       if (location?.pathname.includes("/observationdetails")) {
//         dispatch(
//           dataListByObservationType({
//             patientId: patientId,
//             observationType: observationtype,
//           } as DataListByObservationTypeBody),
//         );
//       } else {
//         dispatch(getPatientDataDetailsList(patientId, page));
//       }
//     }
//     // eslint-disable-next-line react-hooks/exhaustive-deps
//   }, [addDataDetailsStoreVal]);
//   const updateDataDetailsVal = useSelector((state: AppState) => state.updatePatientDataDetailsRes);
//   React.useEffect(() => {
//     if (updateDataDetailsVal?.updatePatientDataDetailsRes?.message !== undefined) {
//       handleDialogClose();
//       dispatch(getPatientDataDetailsList(patientId, page));
//       if (selectedItem !== undefined) {
//         dispatch(getPatientDataDetailsById(selectedItem));
//       }
//     }
//     // eslint-disable-next-line react-hooks/exhaustive-deps
//   }, [updateDataDetailsVal]);
//   const imageUploadRes = useSelector((state: AppState) => state.imageUploading);

//   let editImgId = imageId !== undefined ? imageId! : editImageId;
//   const editDataPointBody = {
//     patientDetailsId: String(value?.id!),
//     description: `${dataPoint!}${sleepScheduleTime}`,
//     startDate: dayjs(String(selectedDate)).format("YYYY-MM-DDTHH:mm:ss"),
//     patientDocumentId: String(editImgId),
//     // patientDetailsType:
//     //   sessionId !== undefined
//     //     ? selectDetailsType !== undefined && selectDetailsType?.value === 1
//     //       ? selectedPatientDetailsType !== undefined && selectedPendingPatientDetailsType?.value
//     //       : selectedPatientDetailsType !== undefined && selectedPatientDetailsType?.value
//     //     : selectedPatientDetailsType?.value,
//   } as UpdatePatientDataDetailsBody;

//   const submitPatientData = () => {
//     if (delImgAction) {
//       setDelImgAction(false);
//     }
//     value?.id === undefined
//       ? dispatch(addPatientDataDetails(dataPointBody))
//       : dispatch(updatePatientDataDetailsApi(editDataPointBody));
//   };
//   const [delImgAction, setDelImgAction] = useState(false);
//   const [checkImgUpload, setCheckImgUpload] = useState(false);
//   // Get PreviewImg Details
//   const viewPreviewImageData = (passedVal: boolean) => {
//     setCheckImgUpload(passedVal);
//   };

//   const changeSelectedObservation = (passedVal: ModifiedObservationTypeList | null) => {
//     setSelectedObservation(passedVal);
//     setDataPoint(
//       passedVal !== null
//         ? dataPoint.length > 0
//           ? dataPoint.concat("; ", `${passedVal!.name}: `)
//           : `${passedVal!.name}: `
//         : dataPoint,
//     );
//   };

//   const getObservationTypeListRes = useSelector((state: AppState) => state.getObservationTypeValue);
//   useEffect(() => {
//     if (getObservationTypeListRes?.GetObservationTypeResponse?.observationTypeList !== undefined) {
//       let observationTypeListItem =
//         getObservationTypeListRes?.GetObservationTypeResponse?.observationTypeList.map(
//           (element: ObservationTypeList) => ({
//             value: element.id,
//             label: element.displayValue !== "" ? element.displayValue : "",
//             name: element.name,
//           }),
//         );
//       setObservationList(observationTypeListItem);
//     } else setObservationList([] as ModifiedObservationTypeList[]);

//     // eslint-disable-next-line react-hooks/exhaustive-deps
//   }, [getObservationTypeListRes]);

//   return (
//     <Box>
//       <Dialog
//         onClose={() => handleDialogClose()}
//         aria-labelledby="customized-dialog-title"
//         open={dialogState}
//         PaperProps={{
//           style: {
//             minHeight: "20vh",
//             minWidth: "40vw",
//             overflow: "hidden",
//           },
//         }}
//       >
//         <Box>
//           {updateDataDetailsVal?.loading || addDataDetailsStoreVal?.loading ? <MWLoader /> : null}
//         </Box>
//         <DialogTitle id="customized-dialog-title">
//           <Grid
//             container
//             direction="row"
//             justifyContent="space-between"
//             alignItems="center"
//             spacing={2}
//           >
//             <Grid item>
//               <Typography variant="h4">
//                 {value?.id !== undefined ? "Update " : "Add "}Patient Details
//               </Typography>
//             </Grid>
//             <Grid item>
//               <IconButton
//                 aria-label="close"
//                 onClick={() => handleDialogClose()}
//                 sx={{
//                   position: "absolute",
//                   right: 8,
//                   top: 8,
//                   color: "grey",
//                 }}
//               >
//                 <CloseIcon />
//               </IconButton>
//             </Grid>
//           </Grid>
//         </DialogTitle>
//         <DialogContent dividers>
//           <Grid
//             container
//             direction="row"
//             justifyContent="space-between"
//             alignItems="flex-start"
//             spacing={2}
//           >
//             <Grid item xs={12} sm={6} md={6} lg={6} xl={6}>
//               <Grid
//                 container
//                 direction="column"
//                 justifyContent="space-between"
//                 alignItems="stretch"
//                 spacing={2}
//               >
//                 <Grid item xs={12} sm={6} md={6} lg={6} xl={6}>
//                   <Autocomplete
//                     size="small"
//                     fullWidth
//                     disabled={addDataDetailsStoreVal?.loading || updateDataDetailsVal?.loading}
//                     value={selectedObservation}
//                     options={observationList}
//                     onChange={(_event: any, newValue: any) => {
//                       changeSelectedObservation(newValue);
//                     }}
//                     renderInput={(params: any) => (
//                       <TextField {...params} label="Observation Type" variant="outlined" />
//                     )}
//                     getOptionLabel={(option: any) => `${option?.label}`}
//                     isOptionEqualToValue={(option: any, value: any) => option.value === value.value}
//                   />
//                 </Grid>
//                 <Grid item xs={12} sm={6} md={6} lg={6} xl={6}>
//                   <TextField
//                     fullWidth
//                     autoFocus
//                     id="data"
//                     label="Data"
//                     variant="outlined"
//                     value={dataPoint}
//                     onChange={(e) => {
//                       setDataPoint(e.target.value);
//                     }}
//                     size="small"
//                     multiline
//                   />
//                 </Grid>
//                 {selectedObservation?.name === "sleepschedule" ? (
//                   <Box m={2}>
//                     <Grid item xs={12} sm={12} md={12} lg={12} xl={12}>
//                       <Grid
//                         container
//                         direction="column"
//                         justifyContent="center"
//                         alignItems="stretch"
//                         spacing={2}
//                       >
//                         <Grid item xs={12} sm={12} md={12} lg={12} xl={12}>
//                           <LocalizationProvider dateAdapter={AdapterDayjs}>
//                             <DesktopTimePicker
//                               label="Start Time"
//                               value={startTime}
//                               onChange={(newValue) => {
//                                 setStartTime(newValue);
//                               }}
//                               renderInput={(params) => (
//                                 <TextField {...params} error={false} size="small" />
//                               )}
//                             />
//                           </LocalizationProvider>
//                         </Grid>
//                         <Grid item xs={12} sm={12} md={12} lg={12} xl={12}>
//                           <LocalizationProvider dateAdapter={AdapterDayjs}>
//                             <DesktopTimePicker
//                               label="End Time"
//                               value={endTime}
//                               onChange={(newValue) => setEndTime(newValue)}
//                               renderInput={(params) => (
//                                 <TextField {...params} error={false} size="small" />
//                               )}
//                             />
//                           </LocalizationProvider>
//                         </Grid>
//                       </Grid>
//                     </Grid>
//                   </Box>
//                 ) : null}
//                 <Grid item xs={12} sm={6} md={6} lg={6} xl={6}>
//                   <LocalizationProvider dateAdapter={AdapterDayjs}>
//                     <DesktopDatePicker
//                       inputFormat="DD/MM/YYYY"
//                       value={selectedDate}
//                       onChange={(date) => handleDateChange(date)}
//                       views={["year", "month", "day"]}
//                       renderInput={(params: any) => (
//                         <TextField
//                           {...params}
//                           fullWidth
//                           inputProps={{ ...params.inputProps, readOnly: true }}
//                           size="small"
//                         />
//                       )}
//                       InputAdornmentProps={{ position: "start" }}
//                     />
//                   </LocalizationProvider>
//                 </Grid>
//               </Grid>
//             </Grid>
//             <Grid item xs={12} sm={6} md={6} lg={6} xl={6}>
//               <MWUploader
//                 documentType="patient-image"
//                 criteria="Upload a file less than 25 mb."
//                 updatedDataState={updateDataStateFromChild}
//                 title={"Upload Patient Observation"}
//                 imageUrl={value?.patientDocument?.document}
//                 deleteImgAction={delImgAction}
//                 previewImageData={viewPreviewImageData}
//               />
//             </Grid>
//           </Grid>
//         </DialogContent>
//         <DialogActions>
//           <Button
//             autoFocus
//             onClick={submitPatientData}
//             variant="contained"
//             disabled={
//               imageUploadRes?.loading ||
//               addDataDetailsStoreVal?.loading ||
//               updateDataDetailsVal?.loading ||
//               checkImgUpload
//             }
//           >
//             {value?.id !== undefined ? "Update" : "Save"}
//           </Button>
//         </DialogActions>
//       </Dialog>
//     </Box>
//   );
// }
