import {
  Avatar,
  Box,
  Chip,
  IconButton,
  List,
  ListItem,
  ListItemButton,
  ListItemIcon,
  ListItemText,
  Paper,
  Typography,
} from "@mui/material";
import React from "react";
import VisibilityOutlinedIcon from "@mui/icons-material/VisibilityOutlined";
import ChevronRightIcon from "@mui/icons-material/ChevronRight";

type Props = {
  icon?: boolean | any;
  title?: string | any;
  showSubtitle?: boolean | any;
  subtitle?: boolean | any;
  showReason?: boolean | any;
  reason?: string | any;
  caseId?: number | any;
  patientId?: number | any;
  showNavigation?: boolean | any;
};

export default function MWResourceList({
  icon,
  title,
  showSubtitle,
  subtitle,
  showReason,
  reason,
  showNavigation,
}: Props) {
  return (
    <Paper sx={{ margin: 1 }}>
      <List disablePadding dense>
        <ListItem>
          <ListItemButton>
            <ListItemIcon>
              {icon ? (
                <VisibilityOutlinedIcon />
              ) : (
                <Avatar src="/broken-image.jpg" style={{ height: "25px", width: "25px" }} />
              )}
            </ListItemIcon>
            <ListItemText
              primary={
                <Typography fontWeight="bold">
                  {title !== undefined ? (title !== "" ? title : null) : null}
                </Typography>
              }
              secondary={
                <Box>
                  <Typography variant="subtitle2">
                    {showSubtitle
                      ? subtitle !== undefined
                        ? subtitle !== ""
                          ? subtitle
                          : null
                        : null
                      : null}
                  </Typography>
                  {showReason ? (
                    <Chip
                      size="small"
                      color="primary"
                      label={reason !== undefined ? (reason !== "" ? reason : null) : null}
                    />
                  ) : null}
                </Box>
              }
            />
            {
              <IconButton edge="end" aria-label="delete" disabled>
                {showNavigation ? <ChevronRightIcon /> : null}
              </IconButton>
            }
          </ListItemButton>
        </ListItem>
      </List>
    </Paper>
  );
}
