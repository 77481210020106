import {
  DoctorCategoryListDetails,
  GetDoctorCategoryListActionTypes,
  GET_DOCTOR_CATEGORY_LIST_FAIL,
  GET_DOCTOR_CATEGORY_LIST_LOADING,
  GET_DOCTOR_CATEGORY_LIST_SUCCESS,
} from "./ActionTypes";
import { GetDoctorCategoryListRes } from "./Model";

const initialStateGetPosts: DoctorCategoryListDetails = {
  loading: false,
  getDoctorCategoryListRes: {} as GetDoctorCategoryListRes,
  errRes: "",
};
export const getDoctorCategoryListReducer = (
  state = initialStateGetPosts,
  action: GetDoctorCategoryListActionTypes,
): DoctorCategoryListDetails => {
  switch (action.type) {
    case GET_DOCTOR_CATEGORY_LIST_SUCCESS:
      return {
        ...state,
        loading: false,
        getDoctorCategoryListRes: action.payload,
        errRes: "",
      };
    case GET_DOCTOR_CATEGORY_LIST_LOADING:
      return {
        ...state,
        loading: true,
        getDoctorCategoryListRes: {} as GetDoctorCategoryListRes,
      };
    case GET_DOCTOR_CATEGORY_LIST_FAIL:
      return {
        ...state,
        getDoctorCategoryListRes: {} as GetDoctorCategoryListRes,
        loading: false,
        errRes: action.errRes,
      };
    default:
      return state;
  }
};
