import React, { useState, useCallback, useEffect } from "react";
import CloseIcon from "@mui/icons-material/Close";
import Grid from "@mui/material/Grid";
import Box from "@mui/material/Box";
import Organization from "../../image/organization.png";
import { useDispatch, useSelector } from "react-redux";
import { AdminOrganizationList } from "../../redux/interfaces/Model";
import { AppState } from "../../redux/store/Store";
import { getOrganizationListValue } from "../../redux/effects/ApiCall";
import {
  Alert,
  Button,
  Card,
  CardActions,
  CardHeader,
  Container,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Divider,
  IconButton,
  TextField,
  Typography,
} from "@mui/material";
import { Link } from "react-router-dom";
import MWExceptionList from "../../components/MWExceptionList";

type Props = {
  organizationListLoader?: boolean | any;
};

export default function OrganizationList({ organizationListLoader }: Props) {
  /*  create a piece of state (toSignin)
    with a method to update that state (setToHome)
    and give it a default value of false */
  const [modalActive, setModalActive] = useState(false);
  const [shareHealtRecord, setShareHealtRecord] = useState("");
  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(getOrganizationListValue());
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [dispatch]);

  const shareRecord = () => {
    setModalActive((modalActive) => !modalActive);
    setShareHealtRecord("");
  };

  const [deleteRecordModalMarkup, setDeleteModalActive] = useState(false);
  const handleDeleteRecordModalChange = useCallback(
    () => setDeleteModalActive(!deleteRecordModalMarkup),
    [deleteRecordModalMarkup],
  );

  const resetgetPasswordApicall = () => {};

  const handleMessageChangeHr = useCallback((value: any) => setShareHealtRecord(value), []);

  const closeSuccess = () => {
    setDeleteRecordSuccess("");
    setDeleteRecordError("");
  };

  const [deleteRecordSuccess, setDeleteRecordSuccess] = useState("");
  const successDelRecordDiv = deleteRecordSuccess ? (
    <Alert variant="outlined" severity="success" onClose={closeSuccess}>
      <Typography>{deleteRecordSuccess}</Typography>
    </Alert>
  ) : (
    ""
  );

  const [recordDeleteError, setDeleteRecordError] = useState("");
  const errorDelRecordDiv = recordDeleteError ? (
    <Alert variant="outlined" severity="error" onClose={closeSuccess}>
      <Typography>{recordDeleteError}</Typography>
    </Alert>
  ) : (
    ""
  );

  const DeleteRecordSubmit = () => {
    setDeleteModalActive((deleteRecordModalMarkup) => !deleteRecordModalMarkup);
  };

  const emptyPageMarkup = (
    <Grid container direction="row" justifyContent="space-between" alignItems="flex-start">
      <Grid item>
        <Box pt={5} mt={5}>
          <Typography variant="h4" gutterBottom>
            Organization name is not available.
          </Typography>
        </Box>
      </Grid>
      <Grid item>
        <Box pt={5} mt={5}>
          <img
            width="100%"
            height="100%"
            style={{
              objectFit: "cover",
              objectPosition: "center",
            }}
            alt=""
          />
        </Box>
      </Grid>
    </Grid>
  );

  const organizationList = useSelector((state: AppState) => state.organizationListValue);

  const organizationListValues = organizationList.organizationListValue;
  useEffect(() => {
    organizationListLoader(organizationList?.loading);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [organizationList]);

  const actualPageMarkup = (
    <Box m={3}>
      <Grid container spacing={1}>
        <Grid container item sm={12} spacing={3}>
          {organizationListValues !== undefined ? (
            <React.Fragment>
              {organizationListValues.map(
                (organizationItem: AdminOrganizationList, index: number) => (
                  <Grid item md={4} key={index}>
                    {organizationItem.id && (
                      <Card>
                        <div>
                          <CardHeader
                            key={"card-header-" + index}
                            avatar={
                              <img
                                id={`${organizationItem.id}`}
                                src={Organization}
                                alt=""
                                width="64"
                                height="64"
                                style={{
                                  objectFit: "cover",
                                  objectPosition: "center",
                                  borderRadius: "32px",
                                }}
                              />
                            }
                            title={
                              <h5 key={"card-displayname-" + index}>
                                {organizationItem.displayName}
                              </h5>
                            }
                          />
                          <Divider light />
                          <CardActions disableSpacing key={"card-action-" + index}>
                            <Link to={`/patientlistbyorganization/${organizationItem.id}`}>
                              <Button variant="contained">View Organization Patients</Button>
                            </Link>
                          </CardActions>
                        </div>
                      </Card>
                    )}
                  </Grid>
                ),
              )}
            </React.Fragment>
          ) : (
            <Box p={3}>
              <MWExceptionList />
            </Box>
          )}
        </Grid>
      </Grid>
    </Box>
  );

  const modalMarkup = (
    <Dialog open={modalActive} onClose={shareRecord} fullWidth>
      <DialogTitle>
        <Grid container direction="row" justifyContent="space-between" alignItems="flex-start">
          <Grid item>
            <Typography variant="h6">Share health record</Typography>
          </Grid>
          <Grid item>
            <IconButton onClick={shareRecord}>
              <CloseIcon />
            </IconButton>
          </Grid>
        </Grid>
      </DialogTitle>
      <Divider />
      <DialogContent>
        <TextField
          label="Share with people and groups"
          placeholder="Add people and groups"
          value={shareHealtRecord}
          onChange={(e) => {
            handleMessageChangeHr(e.target.value);
          }}
          id="sharehealthrecord"
          fullWidth
        />
      </DialogContent>
      <Divider />
      <DialogActions>
        <Grid container direction="row" justifyContent="flex-end" alignItems="flex-end" spacing={1}>
          <Grid item>
            <Button variant="contained" onClick={resetgetPasswordApicall}>
              Share
            </Button>
          </Grid>
        </Grid>
      </DialogActions>
    </Dialog>
  );

  const deleteRecordModal = (
    <Dialog open={deleteRecordModalMarkup} onClose={handleDeleteRecordModalChange} fullWidth>
      <DialogTitle>
        <Grid container direction="row" justifyContent="space-between" alignItems="flex-start">
          <Grid item>
            <Typography variant="h6">Delete Patient</Typography>
          </Grid>
          <Grid item>
            <IconButton onClick={handleDeleteRecordModalChange}>
              <CloseIcon />
            </IconButton>
          </Grid>
        </Grid>
      </DialogTitle>
      <Divider />
      <DialogContent>
        <Typography variant="h6">Are you sure, you want to delete this Patient?</Typography>
      </DialogContent>
      <Divider />
      <DialogActions>
        <Grid container direction="row" justifyContent="flex-end" alignItems="flex-end" spacing={1}>
          <Grid item>
            <Button variant="contained" onClick={DeleteRecordSubmit}>
              Yes
            </Button>
          </Grid>
        </Grid>
      </DialogActions>
      <Box>{successDelRecordDiv}</Box>
      <Box>{errorDelRecordDiv}</Box>
    </Dialog>
  );
  return (
    <Box>
      {/* {organizationList.loading ? loadingMarkup : ""} */}
      <Container maxWidth="xl">
        {organizationList.organizationListValue.length === 0 ? emptyPageMarkup : actualPageMarkup}
        {modalMarkup}
        {deleteRecordModal}
      </Container>
    </Box>
  );
}
