import React from "react";
import { Route, Redirect, useLocation, useHistory } from "react-router-dom";

export const PublicRoute = ({ component: Component, ...rest }: any) => {
  const { search } = useLocation();
  const code = React.useMemo(() => new URLSearchParams(search), [search]);
  const history = useHistory();
  const previousURLForEHRConnect = localStorage.getItem("redirectURLfromFHIREPIC");

  React.useEffect(() => {
    if (code) {
      const filteredCode = String(code).slice(5);
      const index = filteredCode.indexOf("&state=");
      const substringBeforeState = filteredCode.substring(0, index);
      localStorage.setItem("authCode", substringBeforeState);
      substringBeforeState !== "" && console.log("fhir-epic_authCode", substringBeforeState);
      if (previousURLForEHRConnect) {
        history.push(previousURLForEHRConnect);
        localStorage.removeItem("redirectURLfromFHIREPIC");
      }
    }
  }, [code, history, previousURLForEHRConnect]);

  const token = localStorage.getItem("access");
  return (
    <Route
      {...rest}
      render={(props) => {
        if (token) {
          // User is logged in, redirect to dashboard
          return <Redirect to={{ pathname: "/dashboard", state: { from: props.location } }} />;
        }
        // User is not logged in, render the specified component
        return <Component {...props} />;
      }}
    />
  );
};
