import { Alert, AlertColor, Box, Collapse, IconButton } from "@mui/material";
import CloseIcon from "@mui/icons-material/Close";
import React from "react";

type Props = {
  bannerOpen?: boolean;
  bannerColor?: AlertColor | any;
  responseMsg?: string;
  bannerClose: any;
};

export default function MWResponseBanner({
  bannerOpen,
  bannerColor,
  responseMsg,
  bannerClose,
}: Props) {
  const [message, setMessage] = React.useState<string | null>(null);
  const [openalert, setOpenBanner] = React.useState(false);

  React.useEffect(() => {
    setOpenBanner(bannerOpen!);
    setMessage(responseMsg!);
  }, [bannerOpen, responseMsg]);

  return (
    <Box>
      <Collapse in={openalert}>
        <Alert
          severity={bannerColor!}
          action={
            <IconButton
              aria-label="close"
              color="inherit"
              size="small"
              onClick={() => {
                bannerClose(false);
              }}
            >
              <CloseIcon fontSize="inherit" />
            </IconButton>
          }
          sx={{ mb: 2 }}
        >
          {message}
        </Alert>
      </Collapse>
    </Box>
  );
}
