import axios from "axios";
import { Dispatch } from "redux";
import Url from "../../Url";
import { setupInterceptorsTo } from "../Authentication/Interceptors";
import {
  resetPasswordActionTypes,
  RESET_PASSWORD_FAIL,
  RESET_PASSWORD_LOADING,
  RESET_PASSWORD_SUCCESS,
} from "./ActionTypes";
import { ResetPasswordDetails, ResetPasswordValue } from "./Model";
import { headers } from "components/Utility";

export const resetPasswordApi = (resetbody: ResetPasswordDetails) => {
  return function (dispatch: Dispatch<resetPasswordActionTypes>) {
    dispatch({
      type: RESET_PASSWORD_LOADING,
    });

    setupInterceptorsTo(axios)
      .put(Url.apiURL + "/profileapp/reset-password/", resetbody, {
        headers: headers,
      })
      .then((res) => {
        dispatch({
          type: RESET_PASSWORD_SUCCESS,
          payload: res.data,
          errRes: "",
        });
      })
      .catch((error) => {
        dispatch({
          type: RESET_PASSWORD_FAIL,
          payload: {} as ResetPasswordValue,
          errRes: error.response.data.message || error.response.data.error,
        });
      });
  };
};
