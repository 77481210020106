import React, { useState, useEffect, useCallback } from "react";
import {} from "react-router-dom";
import "react-big-calendar/lib/css/react-big-calendar.css";
import { useDispatch } from "react-redux";
import { getPaymentListByOwner } from "../../../redux/payment/AppointmentPaymentList/API";
import { Typography, Box, Snackbar, Alert } from "@mui/material";
import MWLoader from "../../../components/MWLoader";
import PackageStatusGridTable from "./PackageStatusGridTable";
import CareGiverPackageStatusSabTabDetails from "../CareGiverPackageStatusSubTabDetails";
export default function CareGiverPackageStatusMainTabDetails() {
  const loadingMarkup = <MWLoader />;
  const [loading] = React.useState<boolean | undefined>(false);

  const [toastErrorMessage] = useState("");
  const [toastErrorActive, setToastErrorActive] = useState(false);
  const toggleToastErrorActive = useCallback(
    () => setToastErrorActive((toastErrorActive) => !toastErrorActive),
    [],
  );

  const toastErrorMarkup = toastErrorActive ? (
    <Snackbar open={toastErrorActive} autoHideDuration={6000} onClose={toggleToastErrorActive}>
      <Alert onClose={toggleToastErrorActive} severity="error" sx={{ width: "100%" }}>
        {toastErrorMessage}
      </Alert>
    </Snackbar>
  ) : null;

  const dispatch = useDispatch();
  useEffect(() => {
    dispatch(getPaymentListByOwner());
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <Box>
      {loading ? loadingMarkup : null}
      <Typography variant="h5" sx={{ mb: 1 }}>
        Quarterly plan (January 01,2024 - March 01,2024)
      </Typography>
      <Box sx={{ pt: 2 }}>
        <PackageStatusGridTable />
      </Box>
      <Box sx={{ pt: 5 }}>
        <CareGiverPackageStatusSabTabDetails />
      </Box>

      {toastErrorMarkup}
    </Box>
  );
}
