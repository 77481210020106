import ptimage1 from "../image/patientimage/patient-image-1.jpg";
import ptimage2 from "../image/patientimage/patient-image-2.jpg";
import ptimage3 from "../image/patientimage/patient-image-3.jpg";
import ptimage4 from "../image/patientimage/patient-image-4.jpg";
import ptimage5 from "../image/patientimage/patient-image-5.jpg";
import ptimage6 from "../image/patientimage/patient-image-6.jpg";
import ptimage7 from "../image/patientimage/patient-image-7.jpg";
import ptimage8 from "../image/patientimage/patient-image-8.jpg";
import ptimage9 from "../image/patientimage/patient-image-9.jpg";
import ptimage10 from "../image/patientimage/patient-image-10.jpg";
import ptimage11 from "../image/patientimage/patient-image-11.jpg";

export const ImageList = {
  imagevlaue: [
    ptimage1,
    ptimage2,
    ptimage3,
    ptimage4,
    ptimage5,
    ptimage6,
    ptimage7,
    ptimage8,
    ptimage9,
    ptimage10,
    ptimage11,
  ],
};
export default ImageList;
