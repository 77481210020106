import { ADD_QUESTION_SUBMIT_LIST, questionSubmitActionTypes } from "./ActionTypes";
import { addQuestionSubmitValue } from "./Model";

export const addQuestionSubmitAction = (
  addQuestionSubmitDetails: addQuestionSubmitValue,
): questionSubmitActionTypes => {
  return {
    type: ADD_QUESTION_SUBMIT_LIST,
    payload: addQuestionSubmitDetails,
  };
};
