import {
  specialistDetails,
  specialistActionTypes,
  SPECIALIST_LIST_FAIL,
  SPECIALIST_LIST_LOADING,
  SPECIALIST_LIST_SUCCESS,
} from "./ActionTypes";
import { SpecialistResponse } from "./Model";

const initialStateGetPosts: specialistDetails = {
  loading: false,
  specialistList: {} as SpecialistResponse,
  errRes: "",
};

export const speciaListReducer = (
  state = initialStateGetPosts,
  action: specialistActionTypes,
): specialistDetails => {
  switch (action.type) {
    case SPECIALIST_LIST_SUCCESS:
      return {
        ...state,
        loading: false,
        specialistList: action.payload,
        errRes: "",
      };
    case SPECIALIST_LIST_LOADING:
      return {
        ...state,
        loading: true,
        specialistList: {} as SpecialistResponse,
      };
    case SPECIALIST_LIST_FAIL:
      return {
        ...state,
        specialistList: {} as SpecialistResponse,
        loading: false,
        errRes: action.errRes,
      };
    default:
      return state;
  }
};
