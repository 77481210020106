import { answerQuestionValue } from "./Model";

export const ANSWER_QUESTION_LOADING = "ANSWER_QUESTION_LOADING";
export const ANSWER_QUESTION_SUCCESS = "ANSWER_QUESTION_SUCCESS";
export const ANSWER_QUESTION_FAIL = "ANSWER_QUESTION_FAIL";
export const ANSWER_QUESTION_DATA = "ANSWER_QUESTION_DATA";

export interface answerQuestionDetails {
  answerQuestionRes: answerQuestionValue;
  loading: boolean;
}
export interface SharePatientListLoading {
  type: typeof ANSWER_QUESTION_LOADING;
}
export interface SharePatientListSuccess {
  type: typeof ANSWER_QUESTION_SUCCESS;
  payload: answerQuestionValue;
}
export interface SharePatientListFail {
  type: typeof ANSWER_QUESTION_FAIL;
}
interface SharePatientListAction {
  type: typeof ANSWER_QUESTION_DATA;
  payload: answerQuestionValue;
}

export type answerQuestionActionTypes =
  | SharePatientListAction
  | SharePatientListLoading
  | SharePatientListSuccess
  | SharePatientListFail;
