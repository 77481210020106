import axios from "axios";
import { Dispatch } from "redux";
import Url from "../../../Url";
import { SleepQualityObservationTypeActionTypes } from "./ActionTypes";
import { SleepQualityObservationTypeBody, SleepQualityObservationTypeRes } from "./Model";
import {
  sleepQualityObservationTypeAPIResClearAction,
  sleepQualityObservationTypeErrorAction,
  sleepQualityObservationTypeLoadingAction,
  sleepQualityObservationTypeSuccessAction,
  sleepQualityObservationTypeUpdateAPIMsgAction,
} from "./Actions";
import { headers } from "../../../components/Utility";

let apiRes = {} as SleepQualityObservationTypeRes;
export const sleepQualityObservationType = (payload: SleepQualityObservationTypeBody) => {
  return function (dispatch: Dispatch<SleepQualityObservationTypeActionTypes>) {
    dispatch(sleepQualityObservationTypeLoadingAction(true));
    axios
      .post(
        Url.apiURL + `/patientapp/api/patientdashboard/patientDashboardDataListByObservationType`,
        payload,
        { headers: headers },
      )
      .then((res) => {
        apiRes = res.data;
        dispatch(sleepQualityObservationTypeLoadingAction(false));
        dispatch(
          sleepQualityObservationTypeSuccessAction(
            res.data,
            res.data.message !== undefined
              ? res.data.message
              : "patient sleep score is showing successfully",
          ),
        );
      })
      .catch((error) => {
        dispatch(sleepQualityObservationTypeLoadingAction(false));
        dispatch(
          sleepQualityObservationTypeErrorAction(
            {} as SleepQualityObservationTypeRes,
            error.response !== undefined
              ? error.response.data.message || error.response.data.detail
              : "Something went wrong",
            error.status !== undefined ? error.response.data.status || -1 : -1,
          ),
        );
      });
  };
};

export const updateSleepQualityObservationTypeAPIResMsg = () => {
  return function (dispatch: Dispatch<SleepQualityObservationTypeActionTypes>) {
    dispatch(
      sleepQualityObservationTypeUpdateAPIMsgAction(
        apiRes as SleepQualityObservationTypeRes,
        "",
        "",
        0,
      ),
    );
  };
};

export const clearSleepQualityObservationTypeAPIRes = () => {
  return function (dispatch: Dispatch<SleepQualityObservationTypeActionTypes>) {
    dispatch(
      sleepQualityObservationTypeAPIResClearAction({} as SleepQualityObservationTypeRes, "", "", 0),
    );
  };
};
