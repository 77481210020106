import { GET_LIST } from "../../types/ActionTypes";
import { PatientDemographicsActionTypes } from "./ActionTypes";
import { PatientDemographicsDetails } from "./Model";

export const getPatientDemographicsDetailsAction = (
  patientDemographicsDetails: PatientDemographicsDetails,
): PatientDemographicsActionTypes => {
  return {
    type: GET_LIST,
    payload: patientDemographicsDetails,
  };
};
