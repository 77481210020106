import * as am5 from "@amcharts/amcharts5";
import * as am5xy from "@amcharts/amcharts5/xy";
import am5themes_Animated from "@amcharts/amcharts5/themes/Animated";
import am5themes_Responsive from "@amcharts/amcharts5/themes/Responsive";
import am5themes_Material from "@amcharts/amcharts5/themes/Material";
import { BPData } from "../Model";
import { useEffect, useState } from "react";
import { Box } from "@mui/material";
import React from "react";

type Props = {
  data?: BPData[] | any;
  unit?: string | any;
};

export function BPChart({ data, unit }: Props) {
  const [chartData, setChartData] = useState([] as BPData[]);
  const [timeUnit, setTimeUnit] = useState<any>();
  useEffect(() => {
    setChartData(data);
    if (data !== undefined) {
      setChartData(data);
    }
    if (unit !== undefined) {
      setTimeUnit(unit);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [data, unit]);

  useEffect(() => {
    var root = am5.Root.new("bpchart");

    // Set themes
    root.setThemes([
      am5themes_Animated.new(root),
      am5themes_Responsive.new(root),
      am5themes_Material.new(root),
    ]);
    root.dateFormatter.setAll({
      dateFormat: "yyyy-MM-dd",
      dateFields: ["valueX"],
    });
    var chart = root.container.children.push(
      am5xy.XYChart.new(root, {
        panX: false,
        panY: false,
        wheelY: "zoomXY",
      }),
    );

    chart.get("colors")!.set("step", 2);

    // Create axes
    // https://www.amcharts.com/docs/v5/charts/xy-chart/axes/
    var xAxis = chart.xAxes.push(
      am5xy.DateAxis.new(root, {
        baseInterval: { timeUnit: timeUnit, count: 1 },
        autoZoom: false,
        renderer: am5xy.AxisRendererX.new(root, {
          minGridDistance: 50,
          minorGridEnabled: true,
        }),
        tooltip: am5.Tooltip.new(root, {}),
      }),
    );

    var yAxis = chart.yAxes.push(
      am5xy.ValueAxis.new(root, {
        renderer: am5xy.AxisRendererY.new(root, {}),
        autoZoom: false,
        tooltip: am5.Tooltip.new(root, {}),
      }),
    );

    // Create series
    // https://www.amcharts.com/docs/v5/charts/xy-chart/series/
    var series0 = chart.series.push(
      am5xy.LineSeries.new(root, {
        interactive: true,
        calculateAggregates: true,
        xAxis: xAxis,
        yAxis: yAxis,
        valueYField: "high",
        valueXField: "date",
        // valueField: "aValue",
        // tooltip: am5.Tooltip.new(root, {
        //   labelText: "Systolic: {valueY}",
        // }),
        fill: am5.Color.fromString('#ff2d57'),
        tooltip: am5.Tooltip.new(root, {
          pointerOrientation: "horizontal",
        }),
      }),
    );

    series0.get("tooltip")!.label.adapters.add("text", function (_text: any, _target) {
      var tooltipDataItem = series1.get("tooltipDataItem");
      let hoveredItem = {} as any;
      hoveredItem = tooltipDataItem?.dataContext;
      return `Systolic: ${hoveredItem?.high}`;
    });

    // Add bullet
    // https://www.amcharts.com/docs/v5/charts/xy-chart/series/#Bullets
    var circleTemplate = am5.Template.new({});
    series0.bullets.push(function () {
      var graphics = am5.Circle.new(
        root,
        {
          fill: series0.get("fill"),
        },
        circleTemplate as any,
      );
      return am5.Bullet.new(root, {
        sprite: graphics,
      });
    });

    // Add heat rule
    // https://www.amcharts.com/docs/v5/concepts/settings/heat-rules/
    series0.set("heatRules", [
      {
        target: circleTemplate,
        min: 3,
        max: 35,
        dataField: "value",
        key: "radius",
      },
    ]);

    var starTemplate = am5.Template.new({});
    // Create second series
    // https://www.amcharts.com/docs/v5/charts/xy-chart/series/
    var series1 = chart.series.push(
      am5xy.LineSeries.new(root, {
        calculateAggregates: true,
        xAxis: xAxis,
        yAxis: yAxis,
        valueYField: "low",
        valueXField: "date",
        // valueField: "bValue",
        fill: am5.Color.fromString('#3f51b5'),
        tooltip: am5.Tooltip.new(root, {
          labelText: "Diastolic: {valueY}",
        }),
      }),
    );

    // Add bullet
    // https://www.amcharts.com/docs/v5/charts/xy-chart/series/#Bullets
    series1.bullets.push(function () {
      var graphics = am5.Star.new(
        root,
        {
          fill: series1.get("fill"),
          spikes: 4,
          innerRadius: am5.percent(70),
        },
        starTemplate as any,
      );
      return am5.Bullet.new(root, {
        sprite: graphics,
      });
    });

    series0.strokes.template.set("strokeOpacity", 0);
    series1.strokes.template.set("strokeOpacity", 0);

    series0.data.processor = am5.DataProcessor.new(root, {
      dateFields: ["date"],
      dateFormat: "yyyy-MM-dd",
    });

    series1.data.processor = am5.DataProcessor.new(root, {
      dateFields: ["date"],
      dateFormat: "yyyy-MM-dd",
    });

    // Add cursor
    // https://www.amcharts.com/docs/v5/charts/xy-chart/cursor/
    chart.set(
      "cursor",
      am5xy.XYCursor.new(root, {
        xAxis: xAxis,
        yAxis: yAxis,
        behavior: "zoomXY",
        snapToSeries: [series0, series1],
      }),
    );

    // Add scrollbars
    // https://www.amcharts.com/docs/v5/charts/xy-chart/scrollbars/
    chart.set(
      "scrollbarX",
      am5.Scrollbar.new(root, {
        orientation: "horizontal",
      }),
    );

    chart.set(
      "scrollbarY",
      am5.Scrollbar.new(root, {
        orientation: "vertical",
      }),
    );

    series0.data.setAll(chartData);
    series1.data.setAll(chartData);

    // Make stuff animate on load
    // https://www.amcharts.com/docs/v5/concepts/animations/
    series0.appear(1000);
    series1.appear(1000);

    chart.appear(1000, 100);

    console.log("chartData", chartData);
    return () => root.dispose();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [chartData, timeUnit]);

  return <Box id="bpchart" style={{ width: "100%", height: "550px", borderRadius: 20 }} />;
}
