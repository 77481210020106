import { LatestDataListRes } from "./Model";
export const PATIENT_DASHBOARD_LATEST_DATALIST_LOADING =
  "PATIENT_DASHBOARD_LATEST_DATALIST_LOADING";
export const PATIENT_DASHBOARD_LATEST_DATALIST_SUCCESS =
  "PATIENT_DASHBOARD_LATEST_DATALIST_SUCCESS";
export const PATIENT_DASHBOARD_LATEST_DATALIST_FAIL = "PATIENT_DASHBOARD_LATEST_DATALIST_FAIL";
export const PATIENT_DASHBOARD_LATEST_DATALIST_UPDATE_API_MSG =
  "PATIENT_DASHBOARD_LATEST_DATALIST_UPDATE_API_MSG";
export const PATIENT_DASHBOARD_LATEST_DATALIST_UPDATE_API_RES =
  "PATIENT_DASHBOARD_LATEST_DATALIST_UPDATE_API_RES";

export interface PatientDashBoardLatestDataListState {
  patientDashBoardLatestDataListRes: LatestDataListRes;
  loading: boolean;
  successMsg: string;
  errorMsg: string;
  status: number;
}
export interface PatientDashBoardLatestDataListLoading {
  type: typeof PATIENT_DASHBOARD_LATEST_DATALIST_LOADING;
  loading: boolean;
}
export interface PatientDashBoardLatestDataListSuccess {
  type: typeof PATIENT_DASHBOARD_LATEST_DATALIST_SUCCESS;
  payload: LatestDataListRes;
  successMsg: string;
}
export interface PatientDashBoardLatestDataListFail {
  type: typeof PATIENT_DASHBOARD_LATEST_DATALIST_FAIL;
  payload: LatestDataListRes;
  errorMsg: string;
  status: number;
}
export interface PatientDashBoardLatestDataListUpdateAPIMsg {
  type: typeof PATIENT_DASHBOARD_LATEST_DATALIST_UPDATE_API_MSG;
  payload: LatestDataListRes;
  successMsg: string;
  errorMsg: string;
  status: number;
}
export interface PatientDashBoardLatestDataListUpdateAPIRes {
  type: typeof PATIENT_DASHBOARD_LATEST_DATALIST_UPDATE_API_RES;
  payload: LatestDataListRes;
  successMsg: string;
  errorMsg: string;
  status: number;
}

export type PatientDashBoardLatestDataListActionTypes =
  | PatientDashBoardLatestDataListLoading
  | PatientDashBoardLatestDataListSuccess
  | PatientDashBoardLatestDataListFail
  | PatientDashBoardLatestDataListUpdateAPIMsg
  | PatientDashBoardLatestDataListUpdateAPIRes;
