import React, { useEffect } from "react";
import { useHistory, useParams } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { appoinmentRequestListForOwner } from "../redux/effects/ApiCall";
import { AppState } from "../redux/store/Store";
import { Button, Card, CardContent, CardHeader, Grid, Typography } from "@mui/material";
import PageLayout from "../components/PageLayout";
import MWPageTitle from "../components/MWPageTitle";
import MWExceptionList from "../components/MWExceptionList";
import RefreshIcon from "@mui/icons-material/Refresh";
import PreviewIcon from "@mui/icons-material/Preview";
import { DataGrid, GridCellParams, GridColDef, GridToolbar } from "@mui/x-data-grid";
import VisibilityIcon from "@mui/icons-material/Visibility";
import { toggleAppBarLoading } from "redux/appLoader/Actions";
export default function AppointmentBooking(_props: any) {
  const { caseid } = useParams() as {
    caseid: string;
  };
  const history = useHistory();
  const gotoCaseDetails = (patientid: any, caseid: any) => {
    history.push(`filepreparation/${patientid}/${caseid}`);
  };
  const columns: GridColDef[] = [
    {
      field: "caseid",
      headerName: "View Case Details",
      align: "left",
      flex: 0.7,
      renderCell: (params: GridCellParams) => (
        <Button
          startIcon={<VisibilityIcon />}
          variant="outlined"
          onClick={() => {
            console.log("VIEWDATAFORAPP==", params.row);
            gotoCaseDetails(params.row.caseId, params.row.patientId);
          }}
        >
          View
        </Button>
      ),
    },
    {
      field: "name",
      headerName: "Description",
      type: "string",
      editable: true,
      sortable: false,
      disableColumnMenu: true,
      align: "left",
      flex: 1.5,
    },
    {
      field: "createDate",
      headerName: "Create Date",
      type: "string",
      editable: true,
      sortable: false,
      disableColumnMenu: true,
      align: "left",
      flex: 1,
    },
    {
      field: "end",
      headerName: "End Date",
      type: "string",
      editable: true,
      sortable: false,
      align: "left",
      disableColumnMenu: true,
      flex: 1,
    },
    {
      field: "modifiedDate",
      headerName: "Modified Date",
      type: "string",
      editable: true,
      sortable: false,
      align: "left",
      disableColumnMenu: true,
      flex: 1,
    },
    {
      field: "status",
      headerName: "Status",
      type: "string",
      editable: true,
      sortable: false,
      align: "left",
      disableColumnMenu: true,
      flex: 1,
    },
  ];

  const dispatch = useDispatch();

  function getAppoinmentRequestList() {
    dispatch(appoinmentRequestListForOwner(selectionTypeBody));
  }

  const DATE_OPTIONS = {
    weekday: "short",
    year: "numeric",
    month: "short",
    day: "numeric",
  } as const;

  const selectionTypeBody = {
    caseId: caseid,
  };
  // appoinment details from redux store....
  const ownerAppointmentListRes = useSelector((state: AppState) => state.AppoinmentListValue);
  useEffect(() => {
    dispatch(toggleAppBarLoading(ownerAppointmentListRes?.loading));
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [ownerAppointmentListRes]);

  let appoinmentList = ownerAppointmentListRes.OwnerAppoinmentListValue;
  let appoinmentValueList: any = [];
  for (var i = 0; i < appoinmentList.length; i++) {
    let caseid = appoinmentList[i].caseId === null ? "" : appoinmentList[i].caseId;
    let patientid = appoinmentList[i].patientId === null ? "" : appoinmentList[i].patientId;
    let description =
      appoinmentList[i].name === "" || appoinmentList[i].name === null
        ? "No Details Available"
        : appoinmentList[i].name;
    let createdDate =
      appoinmentList[i].createDate === null
        ? ""
        : new Date(appoinmentList[i].createDate).toLocaleDateString("en-US", DATE_OPTIONS);
    let endDate =
      appoinmentList[i].end === null
        ? ""
        : new Date(appoinmentList[i].end).toLocaleDateString("en-US", DATE_OPTIONS);
    let modifiedDate =
      appoinmentList[i].modifiedDate === null
        ? ""
        : new Date(appoinmentList[i].modifiedDate).toLocaleDateString("en-US", DATE_OPTIONS);
    let appoinmentStatus = appoinmentList[i].hasOwnProperty("status")
      ? appoinmentList[i].status === null || appoinmentList[i].status === ""
        ? ""
        : appoinmentList[i].status
      : "";
    appoinmentValueList.push([
      <Button
        variant="outlined"
        startIcon={<PreviewIcon />}
        onClick={() => gotoCaseDetails(patientid, caseid)}
      >
        View
      </Button>,
      description,
      createdDate,
      modifiedDate,
      endDate,
      appoinmentStatus,
    ]);
  }

  useEffect(() => {
    // Do api call here....
    dispatch(appoinmentRequestListForOwner(selectionTypeBody));
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [dispatch]);

  const appointmentTableBody = (
    <Card>
      <CardHeader
        title={
          <Grid container direction="row" justifyContent="space-between" alignItems="flex-start">
            <Grid item>
              <Typography variant="h6">Existing Appointment Details</Typography>
            </Grid>
            <Grid item>
              <Button
                variant="outlined"
                startIcon={<RefreshIcon />}
                onClick={() => getAppoinmentRequestList()}
                sx={{ textTransform: "none" }}
              >
                Reload Appointment List
              </Button>
            </Grid>
          </Grid>
        }
      />
      <CardContent>
        {appoinmentList && appoinmentList.length ? (
          <DataGrid
            rows={appoinmentList}
            columns={columns}
            hideFooter
            hideFooterPagination
            disableSelectionOnClick
            experimentalFeatures={{ newEditingApi: true }}
            autoHeight
            components={{ Toolbar: GridToolbar }}
            componentsProps={{
              toolbar: {
                csvOptions: { disableToolbarButton: true },
                printOptions: { disableToolbarButton: true },
                showQuickFilter: true,
                quickFilterProps: { debounceMs: 250 },
              },
            }}
            disableColumnFilter
            disableColumnSelector
            disableDensitySelector
          />
        ) : (
          <MWExceptionList />
        )}
      </CardContent>
    </Card>
  );

  return (
    <PageLayout>
      <MWPageTitle title="Appointment Details" />
      {appointmentTableBody}
    </PageLayout>
  );
}
