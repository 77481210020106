import React, { useCallback, useEffect, useState } from "react";
import EditIcon from "@mui/icons-material/Edit";
import DescriptionIcon from "@mui/icons-material/Description";
import { useParams } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { AppState } from "../redux/store/Store";
import {
  Container,
  Alert,
  Box,
  TextField,
  Dialog,
  DialogContent,
  DialogTitle,
  DialogActions,
  Button,
  Card,
  CardHeader,
  CardContent,
  TableContainer,
  Table,
  TableHead,
  TableRow,
  TableCell,
  TableBody,
  Grid,
  Typography,
  IconButton,
  Divider,
} from "@mui/material";
import { t } from "i18next";
import CloseIcon from "@mui/icons-material/Close";
import { DesktopDatePicker, LocalizationProvider } from "@mui/x-date-pickers";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import MWLoader from "../components/MWLoader";
import MWTextField from "../components/MWTextField";
import MWUploader from "../components/MWUploader";
import { PatientId } from "../redux/getInsuranceDetailsByPatient/Model";
import { AddPatientInsuranceBody } from "../redux/addPatientInsurance/Model";
import { getInsuranceDetailsByPatientApi } from "../redux/getInsuranceDetailsByPatient/API";
import { addPatientInsuranceApi } from "../redux/addPatientInsurance/API";
import { EditPatientInsuranceBody } from "../redux/editPatientInsurance/Model";
import { editPatientInsuranceApi } from "../redux/editPatientInsurance/API";
import MWExceptionList from "../components/MWExceptionList";
export default function InsuranceDetails() {
  const { patientid } = useParams() as {
    patientid: string;
  };
  const dispatch = useDispatch();
  const [isLoading, setIsLoading] = useState(false);
  const loadingMarkup = isLoading ? <MWLoader /> : null;

  const [imageInfo, setImageInfo] = useState("");
  const updateDataStateFromChild = async (passedval: any) => {
    setImageInfo(passedval);
  };
  const [imageID, setImageID] = useState("");
  const [insuranceno, setInsuranceno] = useState("");
  const handleInsuranceChange = useCallback((value: any) => {
    setInsuranceno(value);
  }, []);
  const [insuranceAmmount, setInsuranceAmmount] = useState("");
  const handleInsuranceAmmountChange = useCallback((value: any) => setInsuranceAmmount(value), []);

  const [selectedDate, setSelectedDate] = React.useState<Date | null>(new Date());
  const handleDateChange = (enddate: Date | null) => {
    setSelectedDate(enddate);
  };

  const [selectedEndDate, setSelectedEndDate] = React.useState<Date | null>(new Date());
  const handleEndDateChange = (enddate: Date | null) => {
    setSelectedEndDate(enddate);
  };

  const delImgAction = (passeVal: boolean) => {
    console.log("passeVal", passeVal);
  };

  const [insurancedetail, setInsuranceDetails] = useState("");
  const handleInsuranceDetailsChange = useCallback((value: any) => {
    setInsuranceDetails(value);
  }, []);

  const [insuranceNoError, setInsuranceNoError] = useState("");
  const inuranceNoErrordiv = insuranceNoError ? (
    <Alert variant="standard" severity="error" onClose={() => closeSuccess()}>
      <Typography>{insuranceNoError}</Typography>
    </Alert>
  ) : (
    ""
  );
  // Get PreviewImg Details
  const viewPreviewImageData = (passedVal: any) => {
    console.log("passedVal", passedVal);
  };

  const [insuranceList, setInsuranceList] = useState<any[]>([]);
  const [addInsurancesModalActive, setaddInsurancesModalActive] = useState(false);
  const [insuranceDetailId, setinsuranceDetailId] = useState("");

  const getInsDetailsByPtRequestBody = { patientId: patientid } as PatientId;

  const addInsuranceToggle = () => {
    setaddInsurancesModalActive((addInsurancesModalActive) => !addInsurancesModalActive);
    setInsuranceno("");
    setInsuranceNoError("");
    setInsuranceno("");
    setSelectedDate(new Date());
    setSelectedEndDate(new Date());
    setImageID("");
    setImageInfo("");
    setInsuranceDetails("");
    setinsuranceDetailId("");
    setinsuranceSubmitAPIRes("");
    setInsuranceAmmount("");
    setinsuranceSubmitAPIResError("");
  };

  let body = {
    patientId: patientid,
    insuranceNumber: insuranceno,
    amount: insuranceAmmount,
    fromDate: selectedDate,
    toDate: selectedEndDate,
    supportdocId: imageID,
    description: insurancedetail,
  } as AddPatientInsuranceBody;

  const closeSuccess = () => {
    setinsuranceSubmitAPIRes("");
    setinsuranceSubmitAPIResError("");
  };
  const [insuranceSubmitAPIRes, setinsuranceSubmitAPIRes] = useState("");
  const successDiv = insuranceSubmitAPIRes ? (
    <Alert variant="standard" severity="success" onClose={() => closeSuccess()}>
      <Typography>{insuranceSubmitAPIRes}</Typography>
    </Alert>
  ) : (
    ""
  );

  const [selectedError, setinsuranceSubmitAPIResError] = useState("");
  const selectedErrordiv = selectedError ? (
    <Alert variant="standard" severity="error" onClose={() => closeSuccess()}>
      <Typography>{selectedError}</Typography>
    </Alert>
  ) : (
    ""
  );

  const DATE_OPTIONS = {
    weekday: "short",
    year: "numeric",
    month: "short",
    day: "numeric",
  } as const;

  function editInsuranceDetails(response: any) {
    setaddInsurancesModalActive((addInsurancesModalActive) => !addInsurancesModalActive);
    setInsuranceNoError("");
    setinsuranceDetailId(response.id);
    setInsuranceno(response.insuranceNumber);
    setInsuranceAmmount(String(response.amount));
    setSelectedDate(response.fromDate);
    setSelectedEndDate(response.toDate);
    setInsuranceDetails(response.description);
    let docURL =
      response.supportingDocuments.length > 0
        ? response.supportingDocuments[0].document
        : "document.png";
    setImageID(response.supportingDocuments.id);
    setImageInfo(docURL);
    setinsuranceSubmitAPIRes("");
    setinsuranceSubmitAPIResError("");
  }

  const [docViwerActive, setDocViwerActive] = useState(false);
  const handleDocViewerChange = useCallback(
    () => setDocViwerActive(!docViwerActive),
    [docViwerActive],
  );

  // get document list

  useEffect(() => {
    dispatch(getInsuranceDetailsByPatientApi(getInsDetailsByPtRequestBody));
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  // insurance submit API
  const SubmitInsuranceDetails = () => {
    if (insuranceno === "") {
      setInsuranceNoError("Please enter Insurance Number");
    } else {
      setIsLoading(true);
      if (insuranceDetailId) {
        const editBody = Object.assign(body, {
          insuranceDetailId: insuranceDetailId,
        }) as EditPatientInsuranceBody;
        dispatch(editPatientInsuranceApi(editBody));
      } else {
        dispatch(addPatientInsuranceApi(body));
      }
    }
  };
  // store call
  const getInsuranceDetailsValue = useSelector((state: AppState) => state.getInsuranceDetails);

  const addPatientInsurance = useSelector((state: AppState) => state.addPatientInsurance);

  useEffect(() => {
    addPatientInsurance?.loading ? setIsLoading(true) : setIsLoading(false);
  }, [addPatientInsurance]);

  useEffect(() => {
    getInsuranceDetailsValue?.loading ? setIsLoading(true) : setIsLoading(false);
  }, [getInsuranceDetailsValue]);

  useEffect(() => {
    if (addPatientInsurance?.addPatientInsuranceRes.message !== undefined) {
      setInsuranceNoError("");
      setinsuranceSubmitAPIRes(addPatientInsurance?.addPatientInsuranceRes.message);
      addInsuranceToggle();
      dispatch(getInsuranceDetailsByPatientApi(getInsDetailsByPtRequestBody));
    } else {
      setinsuranceSubmitAPIResError(addPatientInsurance?.errRes);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [addPatientInsurance]);

  useEffect(() => {
    if (getInsuranceDetailsValue?.getInsuranceDetailsRes?.insuranceList !== undefined) {
      let response = getInsuranceDetailsValue?.getInsuranceDetailsRes?.insuranceList;
      let insuranceList = [];
      for (var i = 0; i < response.length; i++) {
        let supportingDocuments = "";
        let selectedResponse = response[i];
        response[i].supportingDocuments.length > 0
          ? (supportingDocuments = response[i].supportingDocuments[0].document)
          : (supportingDocuments = "document.png");
        let insuranceDescription = response[i].description == null ? "" : response[i].description;
        let insuranceNumber =
          response[i].insuranceNumber == null ? "" : response[i].insuranceNumber;
        let insuranceAmmount = response[i].amount == null ? "" : response[i].amount;
        let fromDate =
          response[i].fromDate === null
            ? ""
            : new Date(response[i].fromDate).toLocaleDateString("en-US", DATE_OPTIONS);
        let toDate =
          response[i].toDate === null
            ? ""
            : new Date(response[i].toDate).toLocaleDateString("en-US", DATE_OPTIONS);
        insuranceList.push([
          <Button
            variant="outlined"
            startIcon={<DescriptionIcon />}
            onClick={() => {
              setDocViwerActive(true);
              setImageInfo(supportingDocuments);
            }}
          ></Button>,
          insuranceNumber,
          insuranceAmmount,
          fromDate,
          toDate,
          insuranceDescription,
          <Button
            variant="outlined"
            startIcon={<EditIcon />}
            onClick={() => editInsuranceDetails(selectedResponse)}
          ></Button>,
        ]);
        setInsuranceList(insuranceList);
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [getInsuranceDetailsValue]);

  const docViewermodal = (
    <Dialog open={docViwerActive} fullWidth>
      <DialogTitle>
        <Grid container direction="row" justifyContent="flex-end" alignItems="flex-start">
          <Grid item>
            <IconButton onClick={handleDocViewerChange}>
              <CloseIcon />
            </IconButton>
          </Grid>
        </Grid>
      </DialogTitle>
      <DialogContent>
        <Typography>
          <iframe src={imageInfo} title="Small document" width="100%" height="700" frameBorder="0">
            This is an embedded{" "}
            <a target="_blank" href="http://office.com" rel="noopener noreferrer">
              Microsoft Office
            </a>{" "}
            document, powered by{" "}
            <a target="_blank" href="http://office.com/webapps" rel="noopener noreferrer">
              Office Online
            </a>
            .
          </iframe>
        </Typography>
      </DialogContent>
    </Dialog>
  );

  // ModalMarkUp
  const addinsurancemodalMarkup = (
    <Dialog open={addInsurancesModalActive} onClose={addInsuranceToggle} fullWidth>
      <DialogTitle>
        <Grid container direction="row" justifyContent="space-between" alignItems="flex-start">
          <Grid item>
            <Typography variant="h6">
              {insuranceDetailId
                ? `${t("insurancePage.editIns", { ns: ["home"] })!}`
                : `${t("insurancePage.insAdd", { ns: ["home"] })!}`}
            </Typography>
          </Grid>
          <Grid item>
            <IconButton
              aria-label="close"
              onClick={addInsuranceToggle}
              sx={{
                position: "absolute",
                right: 8,
                top: 8,
                color: (theme) => theme.palette.grey[500],
              }}
            >
              <CloseIcon />
            </IconButton>
          </Grid>
        </Grid>
      </DialogTitle>
      <Divider />
      <DialogContent>
        <Grid
          container
          direction="row"
          justifyContent="flex-start"
          alignItems="flex-start"
          spacing={2}
        >
          <Grid item xs={6} sm={6} md={6} lg={6}>
            <Box pt={2}>
              <MWTextField
                label={t("insurancePage.enterInsNum", { ns: ["home"] })!}
                required
                placeholder={t("insurancePage.plsEnterInsNum", { ns: ["home"] })!}
                value={insuranceno}
                onChange={(e) => handleInsuranceChange(e.target.value)}
                type="text"
                id="insuranceID"
                fullWidth
              />
              {inuranceNoErrordiv}
            </Box>
            <Box pt={2}>
              <MWTextField
                label={t("insurancePage.insCovAmt", { ns: ["home"] })!}
                required
                placeholder={t("insurancePage.enterInsCov", { ns: ["home"] })!}
                value={insuranceAmmount}
                onChange={(e) => handleInsuranceAmmountChange(e.target.value)}
                type="number"
                id="insuranceID"
                fullWidth
              />
            </Box>

            <Box pt={2}>
              <Typography style={{ marginBottom: "5px" }}>
                {t("insurancePage.insStartDate", { ns: ["home"] })!}
              </Typography>
              <LocalizationProvider dateAdapter={AdapterDayjs}>
                <DesktopDatePicker
                  inputFormat="DD/MM/YYYY"
                  value={selectedDate}
                  onChange={(date) => handleDateChange(date)}
                  renderInput={(params: any) => (
                    <TextField
                      {...params}
                      fullWidth
                      inputProps={{ ...params.inputProps, readOnly: true }}
                      size=""
                    />
                  )}
                  InputAdornmentProps={{ position: "start" }}
                />
              </LocalizationProvider>
            </Box>

            <Box pt={2}>
              <Typography>{t("insurancePage.insEndDate", { ns: ["home"] })!}</Typography>
              <LocalizationProvider dateAdapter={AdapterDayjs}>
                <DesktopDatePicker
                  inputFormat="DD/MM/YYYY"
                  value={selectedEndDate}
                  onChange={(date) => handleEndDateChange(date)}
                  renderInput={(params: any) => (
                    <TextField
                      {...params}
                      fullWidth
                      inputProps={{ ...params.inputProps, readOnly: true }}
                      size=""
                    />
                  )}
                  InputAdornmentProps={{ position: "start" }}
                />
              </LocalizationProvider>
            </Box>
          </Grid>
          <Grid item xs={6} sm={6} md={6} lg={6}>
            <Box>
              <Typography style={{ marginBottom: "5px" }}>
                {t("preCompPage.suptDocu", { ns: ["home"] })!}
              </Typography>
              <Box
                style={{
                  textAlign: "center",
                  border: "1px #6574c4 solid",
                  borderRadius: "3px",
                }}
                p={1}
              >
                <MWUploader
                  documentType={t("addpatientpage.patImg", { ns: ["home"] })!}
                  criteria={t("allergyPage.uploadFile", { ns: ["home"] })!}
                  updatedDataState={updateDataStateFromChild}
                  visibility={false}
                  image={imageInfo}
                  deleteImgAction={delImgAction}
                  previewImageData={viewPreviewImageData}
                />
              </Box>
              <Typography>
                <p style={{ color: "gray" }}>{t("preCompPage.docuSiz", { ns: ["home"] })!}.</p>
              </Typography>
            </Box>
          </Grid>
        </Grid>
        <Box pt={2}>
          <MWTextField
            label={t("insurancePage.enterInsDetail", { ns: ["home"] })!}
            required
            placeholder={t("insurancePage.enterInsDetail", { ns: ["home"] })!}
            value={insurancedetail}
            onChange={(e) => handleInsuranceDetailsChange(e.target.value)}
            multiline
            maxRows={2}
            id="insuranceDetailsID"
            fullWidth
          />
        </Box>

        <Box pt={2}>
          {successDiv}
          {selectedErrordiv}
        </Box>
      </DialogContent>
      <Divider />
      <DialogActions>
        <Button onClick={SubmitInsuranceDetails} variant="contained">
          Submit
        </Button>
      </DialogActions>
    </Dialog>
  );

  const legalList = (
    <Box>
      {insuranceList && insuranceList.length ? (
        <TableContainer>
          <Table size="small" aria-label="a dense table">
            <TableHead>
              <TableRow>
                <TableCell>{t("addpatientpage.doc", { ns: ["home"] })!}</TableCell>
                <TableCell>{t("insurancePage.insNum", { ns: ["home"] })!}</TableCell>
                <TableCell>{t("insurancePage.insCovAmt", { ns: ["home"] })!}</TableCell>
                <TableCell>{t("insurancePage.frmDate", { ns: ["home"] })!}</TableCell>
                <TableCell>{t("insurancePage.validDate", { ns: ["home"] })!}</TableCell>
                <TableCell>{t("appointment.desc", { ns: ["home"] })!}</TableCell>
                <TableCell>{t("common.edit", { ns: ["home"] })!}</TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {insuranceList.map((row: any) => (
                <TableRow
                  key={row.description}
                  sx={{
                    "&:last-child td, &:last-child th": { border: 0 },
                  }}
                >
                  <TableCell align="left">{row[0]}</TableCell>
                  <TableCell align="left">{row[1]}</TableCell>
                  <TableCell align="left">{row[2]}</TableCell>
                  <TableCell align="left">{row[3]}</TableCell>
                  <TableCell align="left">{row[4]}</TableCell>
                  <TableCell align="left">{row[5]}</TableCell>
                  <TableCell align="left">{row[6]}</TableCell>
                </TableRow>
              ))}
            </TableBody>
          </Table>
        </TableContainer>
      ) : (
        <MWExceptionList />
      )}
    </Box>
  );

  const actualPageMarkup = (
    <Box>
      <Card>
        <CardHeader
          title={
            <Grid container direction="row" justifyContent="space-between" alignItems="flex-start">
              <Grid item>
                <Typography variant="h6">
                  {insuranceDetailId
                    ? `${t("insurancePage.editIns", { ns: ["home"] })!}`
                    : `${t("insurancePage.insAdd", { ns: ["home"] })!}`}
                </Typography>
              </Grid>
              <Grid item>
                <Grid container direction="row" justifyContent="flex-end" alignItems="flex-end">
                  <Grid item>
                    <Button variant="contained" onClick={addInsuranceToggle}>
                      {t("insurancePage.insAdd", { ns: ["home"] })!}
                    </Button>
                  </Grid>
                </Grid>
              </Grid>
            </Grid>
          }
        />
        <CardContent>{legalList}</CardContent>
      </Card>
    </Box>
  );

  return (
    <Container maxWidth="xl" sx={{ pt: 2 }}>
      {loadingMarkup}
      {actualPageMarkup}
      {addinsurancemodalMarkup}
      {docViewermodal}
    </Container>
  );
}
