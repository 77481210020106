import axios from "axios";
import { Dispatch } from "redux";
import Url from "../../Url";
import { DataListByObservationTypeActionTypes } from "./ActionTypes";
import { DataListByObservationTypeBody, DataListByObservationTypeRes } from "./Model";
import {
  dataListByObservationTypeAPIResClearAction,
  dataListByObservationTypeErrorAction,
  dataListByObservationTypeLoadingAction,
  dataListByObservationTypeSuccessAction,
  dataListByObservationTypeUpdateAPIMsgAction,
} from "./Actions";
import { headers } from "components/Utility";

let apiRes = {} as DataListByObservationTypeRes;
export const dataListByObservationType = (payload: DataListByObservationTypeBody) => {
  return function (dispatch: Dispatch<DataListByObservationTypeActionTypes>) {
    dispatch(dataListByObservationTypeLoadingAction(true));
    axios
      .post(
        Url.apiURL + `/patientapp/api/patientdashboard/patientDashboardDataListByObservationType`,
        payload,
        {
          headers: headers,
        },
      )
      .then((res) => {
        apiRes = res.data;
        dispatch(dataListByObservationTypeLoadingAction(false));
        dispatch(
          dataListByObservationTypeSuccessAction(
            res.data,
            res.data.message !== undefined
              ? res.data.message
              : "patient dashboard datalist by observation showing successfully",
          ),
        );
      })
      .catch((error) => {
        dispatch(dataListByObservationTypeLoadingAction(false));
        dispatch(
          dataListByObservationTypeErrorAction(
            {} as DataListByObservationTypeRes,
            error.response !== undefined
              ? error.response.data.message || error.response.data.detail
              : "Something went wrong",
            error.status !== undefined ? error.response.data.status || -1 : -1,
          ),
        );
      });
  };
};

export const updatePatientDashBoardLatestDataListAPIResMsg = () => {
  return function (dispatch: Dispatch<DataListByObservationTypeActionTypes>) {
    dispatch(
      dataListByObservationTypeUpdateAPIMsgAction(
        apiRes as DataListByObservationTypeRes,
        "",
        "",
        0,
      ),
    );
  };
};

export const clearPatientDashBoardLatestDataListAPIRes = () => {
  return function (dispatch: Dispatch<DataListByObservationTypeActionTypes>) {
    dispatch(
      dataListByObservationTypeAPIResClearAction({} as DataListByObservationTypeRes, "", "", 0),
    );
  };
};
