import { getQuestionListValue } from "./Model";

export const GET_QUESTION_LIST_LOADING = "GET_QUESTION_LIST_LOADING";
export const GET_QUESTION_LIST_SUCCESS = "GET_QUESTION_LIST_SUCCESS";
export const GET_QUESTION_LIST_FAIL = "GET_QUESTION_LIST_FAIL";
export const GET_QUESTION_LIST = "GET_QUESTION_LIST";

export interface getQuestionListDetails {
  getQuestionListRes: getQuestionListValue;
  loading: boolean;
}
export interface SharePatientListLoading {
  type: typeof GET_QUESTION_LIST_LOADING;
}
export interface SharePatientListSuccess {
  type: typeof GET_QUESTION_LIST_SUCCESS;
  payload: getQuestionListValue;
}
export interface SharePatientListFail {
  type: typeof GET_QUESTION_LIST_FAIL;
}
interface SharePatientListAction {
  type: typeof GET_QUESTION_LIST;
  payload: getQuestionListValue;
}

export type getQuestionListActionTypes =
  | SharePatientListAction
  | SharePatientListLoading
  | SharePatientListSuccess
  | SharePatientListFail;
