import { GetDoctorDetailsValue } from "./Model";

export const GET_DOCTOR_DETAILS_LOADING = "GET_DOCTOR_DETAILS_LOADING";
export const GET_DOCTOR_DETAILS_SUCCESS = "GET_DOCTOR_DETAILS_SUCCESS";
export const GET_DOCTOR_DETAILS_FAIL = "GET_DOCTOR_DETAILS_FAIL";
export const GET_DOCTOR_DETAILS = "GET_DOCTOR_DETAILS";

export interface GetDoctorDetailsListDetails {
  getDoctorDetailsRes: GetDoctorDetailsValue;
  loading: boolean;
  errRes: string;
}
export interface GetDoctorDetailsListLoading {
  type: typeof GET_DOCTOR_DETAILS_LOADING;
}
export interface GetDoctorDetailsListSuccess {
  type: typeof GET_DOCTOR_DETAILS_SUCCESS;
  payload: GetDoctorDetailsValue;
  errRes: string;
}
export interface GetDoctorDetailsListFail {
  type: typeof GET_DOCTOR_DETAILS_FAIL;
  errRes: string;
}
interface GetDoctorDetailsListAction {
  type: typeof GET_DOCTOR_DETAILS;
  payload: GetDoctorDetailsValue;
  errRes: string;
}

export type getDoctorDetailsActionTypes =
  | GetDoctorDetailsListAction
  | GetDoctorDetailsListLoading
  | GetDoctorDetailsListSuccess
  | GetDoctorDetailsListFail;
