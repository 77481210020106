import * as React from "react";
import { Alert, AlertColor, IconButton, Snackbar } from "@mui/material";
import CloseIcon from "@mui/icons-material/Close";
import { Portal } from "@mui/base";

interface Props {
  msg?: string | any;
  type?: AlertColor | any;
  alertClose?: boolean | any;
}
export default function MWSnackbar({ msg, type, alertClose }: Props) {
  const [open, setOpen] = React.useState(false);
  const handleClick = () => {
    setOpen(true);
  };

  const handleClose = (_event: React.SyntheticEvent | Event, reason?: string) => {
    if (reason === "clickaway") {
      return;
    }
    setOpen(false);
    alertClose(true);
  };
  const action = (
    <React.Fragment>
      <IconButton size="small" aria-label="close" color="inherit" onClick={handleClose}>
        <CloseIcon fontSize="small" />
      </IconButton>
    </React.Fragment>
  );
  React.useEffect(() => {
    if (type !== undefined && msg !== "") {
      handleClick();
    }
  }, [type, msg]);

  return (
    <Portal>
      <Snackbar open={open} autoHideDuration={3000} onClose={handleClose} action={action}>
        <Alert severity={type!} variant="filled" onClose={handleClose}>
          {msg}
        </Alert>
      </Snackbar>
    </Portal>
  );
}
