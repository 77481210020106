import React, { useEffect, useState } from "react";
import {
  Box,
  Button,
  Checkbox,
  Container,
  FormControl,
  FormControlLabel,
  FormGroup,
  Grid,
  Radio,
  RadioGroup,
  Typography,
} from "@mui/material";
import { useDispatch, useSelector } from "react-redux";
import { t } from "i18next";
import { useHistory, useParams } from "react-router-dom";
import { SecondOpinionReasons } from "../../redux/ConsultationOption/Model";
import { AppState } from "../../redux/store/Store";
import {
  addPatientCase,
  editPatientCase,
  getOpinionTypeList,
  getcaseDetails,
  secondOpinionList,
} from "../../redux/ConsultationOption/ApiCall";
import MWTextField from "../../components/MWTextField";
import { toggleAppBarLoading } from "redux/appLoader/Actions";
export default function OpinionType() {
  const dispatch = useDispatch();
  const history = useHistory();

  const { patientid } = useParams() as {
    patientid: string;
  };

  const { caseid } = useParams() as {
    caseid: string;
  };

  const [isEdit, setIsEdit] = useState(caseid ? true : false);
  const [isEnableSubmit, setEnableSubmit] = useState(true);

  const [value, setValue] = useState("1");
  const [requestList, setRequestList] = useState<SecondOpinionReasons[]>([]);
  const [questionValue, setQuestion] = useState("");

  const handleRequest = (event: React.ChangeEvent<HTMLInputElement>) => {
    if (event.target.checked) {
      let checkedObj = SecondOpinionListValue?.find(
        (i) => i.value.toString() === event.target.value,
      ) as SecondOpinionReasons;
      setRequestList((oldArray) => (oldArray ? [...oldArray!, checkedObj] : [checkedObj]));
    } else {
      setRequestList(requestList.filter((i) => i.value.toString() !== event.target.value));
    }
  };

  const isRequestSelected = (request: any) => {
    return requestList?.find((i) => i.value === request.value) ? true : false;
  };

  const OpinionTypeListRes = useSelector((state: AppState) => state.opinionTypeValue);
  const OpinionTypeListValue = useSelector(
    (state: AppState) => state.opinionTypeValue.opinionTypeListValue,
  );

  const SecondOpinionListRes = useSelector((state: AppState) => state.secondOpinionValue);
  const SecondOpinionListValue = useSelector(
    (state: AppState) => state.secondOpinionValue.secondOpinionListValue,
  );

  const addPatientCaseIdvalue = useSelector((state: AppState) => state.addPatientCase);

  const PatientCaseDetailsValue = useSelector(
    (state: AppState) => state.getPatientCaseDetails.patientCaseDetail,
  );

  const getBody = JSON.stringify({
    caseId: caseid,
  });

  const addBody = {
    patientId: patientid,
    opinionTypeId: value,
    whyWant2ndOpinion: questionValue,
    secondOpinionReasons: requestList?.map((item) => item.value),
  };

  const createCase = () => {
    if (caseid !== undefined) {
      dispatch(editPatientCase(Object.assign(addBody, { caseId: caseid })));
      setIsEdit((isEdit) => !isEdit);
      setEnableSubmit((isEnableSubmit) => !isEnableSubmit);
    } else {
      dispatch(addPatientCase(addBody));
    }
  };

  useEffect(() => {
    dispatch(getOpinionTypeList());
    dispatch(secondOpinionList());
    if (caseid !== undefined) {
      dispatch(getcaseDetails(getBody));
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    if (addPatientCaseIdvalue?.response?.patientCaseId !== undefined) {
      history.push(`/patientdetails/${patientid}/${addPatientCaseIdvalue?.response.patientCaseId}`);
      window.location.reload();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [addPatientCaseIdvalue]);

  useEffect(() => {
    setValue(
      String(PatientCaseDetailsValue === undefined ? "1" : PatientCaseDetailsValue?.opinionType!),
    );
    setRequestList(PatientCaseDetailsValue?.secondOpinionReasons);
    setQuestion(
      PatientCaseDetailsValue === undefined
        ? questionValue
        : PatientCaseDetailsValue?.whyWant2ndOpinion!,
    );
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [PatientCaseDetailsValue]);

  useEffect(() => {
    dispatch(
      toggleAppBarLoading(
        OpinionTypeListRes?.loading ||
          SecondOpinionListRes?.loading ||
          addPatientCaseIdvalue?.loading,
      ),
    );
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [OpinionTypeListRes, SecondOpinionListRes, addPatientCaseIdvalue]);

  const firstComponent = (
    <Grid container direction="column" justifyContent="center" alignItems="stretch" spacing={2}>
      <Grid item>
        <Box>
          {OpinionTypeListValue?.map((opinion: any) => (
            <FormControl key={opinion.value} disabled={isEdit}>
              <RadioGroup
                aria-labelledby="demo-controlled-radio-buttons-group"
                name="controlled-radio-buttons-group"
                value={value}
                onChange={(event: React.ChangeEvent<HTMLInputElement>) =>
                  setValue((event.target as HTMLInputElement).value)
                }
              >
                <FormControlLabel value={opinion.value} control={<Radio />} label={opinion.label} />
              </RadioGroup>
            </FormControl>
          ))}
        </Box>
      </Grid>
      {value === "1" ? (
        <Grid item>
          <Box>
            <Typography variant="h6" fontWeight="bold">
              {t("consultOpPage.2ndOpReq", { ns: ["home"] })}
            </Typography>
            <Box py={2}>
              {SecondOpinionListValue?.map((request: any) => (
                <FormGroup key={request.value}>
                  <FormControlLabel
                    control={
                      <Checkbox
                        value={request.value}
                        onChange={(event) => handleRequest(event)}
                        checked={isRequestSelected(request)}
                      />
                    }
                    label={request.label}
                    disabled={isEdit}
                  />
                </FormGroup>
              ))}
            </Box>
          </Box>
        </Grid>
      ) : null}
      <Grid item>
        <Box>
          {value === "1" ? (
            <Typography variant="h6" fontWeight="bold">
              {OpinionTypeListValue[0]?.question}
            </Typography>
          ) : (
            <Typography variant="h6" fontWeight="bold">
              {OpinionTypeListValue[1]?.question}
            </Typography>
          )}
          <Box mt={3}>
            <MWTextField
              fullWidth
              label="What is the cause of your anxiety, concern, fear, worry, or stress."
              placeholder="Please explain in your own words"
              onChange={(event: React.ChangeEvent<HTMLInputElement>) => {
                setQuestion(event.target.value);
              }}
              value={questionValue}
              disabled={isEdit}
            />
          </Box>
        </Box>
      </Grid>
      <Grid item>
        {caseid ? (
          <Grid container direction="row" justifyContent="flex-end" alignItems="center" spacing={1}>
            <Grid item>
              <Button
                size="large"
                variant="outlined"
                onClick={() => {
                  setIsEdit((isEdit) => !isEdit);
                  setEnableSubmit((isEnableSubmit) => !isEnableSubmit);
                }}
              >
                {isEdit ? "Edit" : "Cancel"}
              </Button>
            </Grid>
            <Grid item>
              <Button
                size="large"
                variant="contained"
                disabled={isEnableSubmit}
                onClick={createCase}
              >
                Update
              </Button>
            </Grid>
          </Grid>
        ) : (
          <Grid container direction="row" justifyContent="flex-end" alignItems="center">
            <Button size="large" variant="contained" onClick={createCase}>
              Add
            </Button>
          </Grid>
        )}
      </Grid>
    </Grid>
  );

  return <Container maxWidth="xl">{firstComponent}</Container>;
}
