import { GET_LIST } from "../../types/ActionTypes";
import { PatientDemographicsDetails } from "./Model";

export const PATIENT_DEMOGRAPHICS_LOADING = "PATIENT_DEMOGRAPHICS_LOADING";
export const PATIENT_DEMOGRAPHICS_SUCCESS = "PATIENT_DEMOGRAPHICS_SUCCESS";
export const PATIENT_DEMOGRAPHICS_FAIL = "PATIENT_DEMOGRAPHICS_FAIL";

export interface GetPatientDemographicsState {
  patientDemographicsRes: PatientDemographicsDetails;
  loading: boolean;
}
export interface DoctorListLoading {
  type: typeof PATIENT_DEMOGRAPHICS_LOADING;
}
export interface DoctorListSuccess {
  type: typeof PATIENT_DEMOGRAPHICS_SUCCESS;
  payload: PatientDemographicsDetails;
}
export interface DoctorListFail {
  type: typeof PATIENT_DEMOGRAPHICS_FAIL;
}
interface GetDoctorAction {
  type: typeof GET_LIST;
  payload: PatientDemographicsDetails;
}

export type PatientDemographicsActionTypes =
  | GetDoctorAction
  | DoctorListLoading
  | DoctorListSuccess
  | DoctorListFail;
