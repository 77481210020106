import {
  PATIENT_LIST_BY_ORGANIZATION_ID_DATA,
  PATIENT_LIST_BY_ORGANIZATION_ID_FAIL,
  PATIENT_LIST_BY_ORGANIZATION_ID_LOADING,
  PATIENT_LIST_BY_ORGANIZATION_ID_SUCCESS,
  GetOrganizationPatientListActionTypes,
} from "./ActionTypes";
import { PageCount, GetOrganizationPatientListDetails } from "./Model";

export const patientSearchOrganizationIdAction = (
  GetOrganizationPatientListDetails: GetOrganizationPatientListDetails,
  pageCount: PageCount,
): GetOrganizationPatientListActionTypes => {
  return {
    type: PATIENT_LIST_BY_ORGANIZATION_ID_DATA,
    payload: GetOrganizationPatientListDetails,
    pageCount: pageCount,
  };
};

export const GetOrganizationPatientListLoadingAction = (
  loading: boolean,
): GetOrganizationPatientListActionTypes => {
  return {
    type: PATIENT_LIST_BY_ORGANIZATION_ID_LOADING,
    loading: loading,
  };
};

export const GetOrganizationPatientListSuccessAction = (
  organizationPatientListResponse: GetOrganizationPatientListDetails,
): GetOrganizationPatientListActionTypes => {
  return {
    type: PATIENT_LIST_BY_ORGANIZATION_ID_SUCCESS,
    payload: organizationPatientListResponse,
  };
};

export const GetOrganizationPatientListErrorAction = (
  organizationPatientListResponse: GetOrganizationPatientListDetails,
  errMsg: string,
  status: number,
): GetOrganizationPatientListActionTypes => {
  return {
    type: PATIENT_LIST_BY_ORGANIZATION_ID_FAIL,
    payload: organizationPatientListResponse,
    message: errMsg,
    status: status,
  };
};
export const GetOrganizationPatientListAPIMsgAction = (
  organizationPatientListResponse: GetOrganizationPatientListDetails,
  errMsg: string,
  status: number,
): GetOrganizationPatientListActionTypes => {
  return {
    type: PATIENT_LIST_BY_ORGANIZATION_ID_FAIL,
    payload: organizationPatientListResponse,
    message: errMsg,
    status: status,
  };
};

export const GetOrganizationPatientListAPIResClearAction = (
  organizationPatientListResponse: GetOrganizationPatientListDetails,
  errMsg: string,
  status: number,
): GetOrganizationPatientListActionTypes => {
  return {
    type: PATIENT_LIST_BY_ORGANIZATION_ID_FAIL,
    payload: organizationPatientListResponse,
    message: errMsg,
    status: status,
  };
};
