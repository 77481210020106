import { PATIENT_DROPDOWN_LIST, PatientDropdownListActionTypes } from "./ActionTypes";
import { PatientDropdownListValue } from "./Model";

export const requestBookAppoiontmentAction = (
  PatientDropdownListDetails: PatientDropdownListValue,
): PatientDropdownListActionTypes => {
  return {
    type: PATIENT_DROPDOWN_LIST,
    payload: PatientDropdownListDetails,
  };
};
