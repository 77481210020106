import React, { useState, useEffect, useCallback } from "react";
import { useParams } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import {
  Alert,
  Button,
  Card,
  CardContent,
  CardHeader,
  Divider,
  Grid,
  Stack,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  TextField,
  Typography,
  Box,
  Snackbar,
} from "@mui/material";
import RefreshIcon from "@mui/icons-material/Refresh";
import { requestAppointmentDetails } from "../redux/Patient/RequestAppointment/ApiCall";
import { AppState } from "../redux/store/Store";
import MWExceptionList from "../components/MWExceptionList";
import {
  clearCreateAppointmentAPIRes,
  createAppointmentReq,
} from "../redux/Appointment/RequestAppointment/API";
import PageLayout from "components/PageLayout";
import { toggleAppBarLoading } from "redux/appLoader/Actions";
export default function AppointmentBooking() {
  const dispatch = useDispatch();
  const { caseid } = useParams() as {
    caseid: string;
  };
  const [isBookAppointment, setIsBookAppoinment] = useState(false);
  console.log(isBookAppointment);
  const [title] = useState("Request Appointment");

  const [toastSuccessMessage] = useState("");
  const [successModalactive, setSuccessModalActive] = useState(false);
  const toggleSuccessModalActive = useCallback(
    () => setSuccessModalActive((successModalactive) => !successModalactive),
    [],
  );
  const toastSuccessMarkup = successModalactive ? (
    <Snackbar open={successModalactive} autoHideDuration={6000} onClose={toggleSuccessModalActive}>
      <Alert severity="success" onClose={toggleSuccessModalActive} sx={{ width: "100%" }}>
        {toastSuccessMessage}
      </Alert>
    </Snackbar>
  ) : null;

  // for req payment

  const closeError = () => {
    setReqAppoinmentError("");
    setReqAppoinmentSuccess("");
    dispatch(clearCreateAppointmentAPIRes());
  };
  const [reqAppoinmentError, setReqAppoinmentError] = useState("");
  const errorReqAppoinment = reqAppoinmentError ? (
    <Alert variant="standard" severity="error" onClose={() => closeError()}>
      {reqAppoinmentError}
    </Alert>
  ) : null;

  const [reqAppoinmentSuccess, setReqAppoinmentSuccess] = useState("");
  const successReqAppoinment = reqAppoinmentSuccess ? (
    <Alert variant="standard" severity="success" onClose={() => closeError()}>
      {reqAppoinmentSuccess}
    </Alert>
  ) : null;

  const [toastErrorMessage] = useState("");
  const [errorModalactive, setErrorModalActive] = useState(false);
  const toggleErrorModalActive = useCallback(
    () => setErrorModalActive((errorModalactive) => !errorModalactive),
    [],
  );
  const toastErrorMarkup = errorModalactive ? (
    <Snackbar open={errorModalactive} autoHideDuration={6000} onClose={toggleErrorModalActive}>
      <Alert severity="error" onClose={toggleErrorModalActive} sx={{ width: "100%" }}>
        {toastErrorMessage}
      </Alert>
    </Snackbar>
  ) : null;

  const [addPhoneNumber, setPhoneNumber] = useState("");
  const handleAddPhoneNumberChange = useCallback((event: any) => {
    setPhoneNumber(event.target.value);
  }, []);
  const [addEmailError] = useState("");
  const addEmailErrordiv = addEmailError ? <Typography>{addEmailError}</Typography> : "";

  const bookAppoinmentBody = {
    caseId: caseid,
    phoneNumberForPatientCase: addPhoneNumber,
  };

  // Create Book Appointment API
  const bookAppoinment = () => {
    setIsBookAppoinment(true);
    dispatch(createAppointmentReq(bookAppoinmentBody));
  };

  let addrequestAppointmentMsgFromAPI = useSelector(
    (state: AppState) => state.createAppointmentReq,
  );
  useEffect(() => {
    dispatch(toggleAppBarLoading(addrequestAppointmentMsgFromAPI?.loading));
    if (addrequestAppointmentMsgFromAPI?.CreateAppointmentRes !== undefined) {
      setReqAppoinmentSuccess(addrequestAppointmentMsgFromAPI?.CreateAppointmentRes?.message);
    }
    setReqAppoinmentError(addrequestAppointmentMsgFromAPI?.errorMsg);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [addrequestAppointmentMsgFromAPI]);

  const selectionTypeBody = {
    caseId: caseid,
  };

  //Selection Type GET API
  const getAppoinmentRequestList = () => {
    // appointmentBookLoader(true);
    dispatch(requestAppointmentDetails(selectionTypeBody));
  };

  const DATE_OPTIONS = {
    weekday: "short",
    year: "numeric",
    month: "short",
    day: "numeric",
  } as const;

  // RequestAppointment Api Value from store
  let requestAppointmentListresponse = useSelector(
    (state: AppState) => state.getrequestAppointmentDetails,
  );
  let requestAppointmentListFromAPI = useSelector(
    (state: AppState) => state.getrequestAppointmentDetails.response,
  );

  useEffect(() => {
    dispatch(toggleAppBarLoading(requestAppointmentListresponse?.loading));
    if (requestAppointmentListresponse?.response !== undefined) {
      setPhoneNumber(requestAppointmentListresponse?.response?.phoneNumberForPatientCase);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [requestAppointmentListresponse]);

  let requestAppointmentListDataFromAPI = useSelector(
    (state: AppState) => state.getrequestAppointmentDetails.listData,
  );

  // Appointment List Api Value from store
  const ApiValues = requestAppointmentListDataFromAPI;
  const appointmentListConfig: any = [];
  for (var i = 0; i < ApiValues.length; i++) {
    let description =
      ApiValues[i].name === "" || ApiValues[i].name === null
        ? "No Details Available"
        : ApiValues[i].name;
    let createdDate =
      ApiValues[i].createDate == null
        ? ""
        : new Date(ApiValues[i].createDate).toLocaleDateString("en-US", DATE_OPTIONS);
    let modifiedDate =
      ApiValues[i].modifiedDate == null
        ? ""
        : new Date(ApiValues[i].modifiedDate).toLocaleDateString("en-US", DATE_OPTIONS);
    let appoinmentStatus = ApiValues[i].hasOwnProperty("status")
      ? ApiValues[i].status === null || ApiValues[i].status === ""
        ? ""
        : ApiValues[i].status
      : "";
    let isActivestatus = ApiValues[i].isActive === true ? "True" : "False";
    appointmentListConfig.push([
      description,
      createdDate,
      isActivestatus,
      modifiedDate,
      appoinmentStatus,
    ]);
  }
  const doctorListValue: any[] = appointmentListConfig;

  useEffect(() => {
    // Do api call here....
    window.scrollTo(0, 0);
    getAppoinmentRequestList();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const selectionOptionMarkup = (
    <Card>
      <Grid
        container
        direction="column"
        justifyContent="flex-start"
        alignItems="stretch"
        spacing={2}
      >
        <Box pt={2} pl={5} pr={5}>
          <Grid item>
            <Typography component="h6" style={{ fontWeight: "bold" }}>
              Selected Options
            </Typography>
          </Grid>
          <Grid item>
            <Box p={3}>
              <Grid
                container
                direction="row"
                justifyContent="space-between"
                alignItems="flex-start"
              >
                <Stack>
                  <Typography component="h6" style={{ fontWeight: "bold" }}>
                    Consultation Type
                  </Typography>
                  <Divider />
                  <Box mt={2}>
                    <Alert variant="outlined" severity="info">
                      <Typography>{requestAppointmentListFromAPI.consultationType}</Typography>
                    </Alert>
                  </Box>
                </Stack>
                <Stack>
                  <Typography component="h6" style={{ fontWeight: "bold" }}>
                    Response Timing
                  </Typography>
                  <Divider />
                  <Box mt={2}>
                    <Alert variant="outlined" severity="info">
                      <Typography>{requestAppointmentListFromAPI.responseTimeType}</Typography>
                    </Alert>
                  </Box>
                </Stack>
                <Stack>
                  <Typography component="h6" style={{ fontWeight: "bold" }}>
                    Doctor Selection Type
                  </Typography>
                  <Divider />
                  <Box mt={2}>
                    <Alert variant="outlined" severity="info">
                      <Typography>{requestAppointmentListFromAPI.doctorTeamType}</Typography>
                    </Alert>
                  </Box>
                </Stack>
              </Grid>
            </Box>
          </Grid>
        </Box>
      </Grid>
    </Card>
  );

  const bannerPageBody = (
    <Box>
      <Card>
        <CardHeader
          title={
            <Grid container direction="row" justifyContent="space-between" alignItems="flex-start">
              <Grid item>{title}</Grid>
              <Grid item>
                <Button
                  variant="contained"
                  onClick={() => bookAppoinment()}
                  sx={{ textTransform: "none" }}
                >
                  Request Appointment
                </Button>
              </Grid>
            </Grid>
          }
        ></CardHeader>
        <CardContent>
          <TextField
            label="Phone Number"
            type="number"
            value={addPhoneNumber}
            onChange={handleAddPhoneNumberChange}
            placeholder="Enter Phone Number"
            fullWidth
            prefix="+91"
            id="emailID"
          />
          <Typography>{addEmailErrordiv}</Typography>
        </CardContent>
        <Box>{errorReqAppoinment}</Box>
        <Box>{successReqAppoinment}</Box>
      </Card>
    </Box>
  );

  const appointmentTableBody = (
    <Box>
      <Card>
        <CardHeader
          title={
            <Grid container direction="row" justifyContent="space-between" alignItems="flex-start">
              <Grid item>Existing Appointment Details</Grid>
              <Grid item>
                <Button
                  variant="outlined"
                  startIcon={<RefreshIcon />}
                  onClick={() => getAppoinmentRequestList()}
                  sx={{ textTransform: "none" }}
                >
                  Reload Appointment List
                </Button>
              </Grid>
            </Grid>
          }
        ></CardHeader>
        <CardContent>
          {doctorListValue && doctorListValue.length ? (
            <TableContainer>
              <Table size="small" aria-label="a dense table">
                <TableHead>
                  <TableRow>
                    <TableCell>Description</TableCell>
                    <TableCell>Create Date</TableCell>
                    <TableCell>Active</TableCell>
                    <TableCell>Modified Date</TableCell>
                    <TableCell>Status</TableCell>
                  </TableRow>
                </TableHead>
                <TableBody>
                  {doctorListValue.map((row: any) => (
                    <TableRow
                      key={row.description}
                      sx={{
                        "&:last-child td, &:last-child th": { border: 0 },
                      }}
                    >
                      <TableCell align="left">{row[0]}</TableCell>
                      <TableCell align="left">{row[1]}</TableCell>
                      <TableCell align="left">{row[2]}</TableCell>
                      <TableCell align="left">{row[3]}</TableCell>
                      <TableCell align="left">{row[4]}</TableCell>
                    </TableRow>
                  ))}
                </TableBody>
              </Table>
            </TableContainer>
          ) : (
            <Typography>
              <MWExceptionList />
            </Typography>
          )}
        </CardContent>
      </Card>
      <br />
    </Box>
  );

  return (
    <PageLayout>
      {selectionOptionMarkup}
      {bannerPageBody}
      {appointmentTableBody}
      {toastSuccessMarkup}
      {toastErrorMarkup}
    </PageLayout>
  );
}
