import React, { useEffect, useState } from "react";
import Box from "@mui/material/Box";
import MasterImportedDataList from "./MasterImportedDataList";
import MasterImportedConfigFile from "./MasterImportedConfig";
import { Tab, Tabs, Typography } from "@mui/material";
import { useDispatch, useSelector } from "react-redux";
import { AppState } from "../../redux/store/Store";
import PageLayout from "components/PageLayout";
import { toggleAppBarLoading } from "redux/appLoader/Actions";

interface TabPanelProps {
  children?: React.ReactNode;
  index: number;
  value: number;
}

function TabPanel(props: TabPanelProps) {
  const { children, value, index, ...other } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`simple-tabpanel-${index}`}
      aria-labelledby={`simple-tab-${index}`}
      {...other}
    >
      {value === index && (
        <Box sx={{ p: 3 }}>
          <Typography>{children}</Typography>
        </Box>
      )}
    </div>
  );
}

function a11yProps(index: number) {
  return {
    id: `simple-tab-${index}`,
    "aria-controls": `simple-tabpanel-${index}`,
  };
}

export default function ImportedData() {
  const dispatch = useDispatch();
  const [selected, setSelected] = useState(0);
  const handleTabChange = (_event: React.SyntheticEvent, newValue: number) => {
    setSelected(newValue);
  };
  const importedValue = useSelector((state: AppState) => state.ccValue);
  const getPatientImportConfigListValue = useSelector(
    (state: AppState) => state.getPatientImportConfigList,
  );

  useEffect(() => {
    dispatch(
      toggleAppBarLoading(importedValue?.loading || getPatientImportConfigListValue?.loading),
    );
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [importedValue, getPatientImportConfigListValue]);
  const symptomsAddInfo = (
    <Box>
      <Box>
        <Tabs value={selected} onChange={handleTabChange}>
          <Tab label="Master Imported Data" {...a11yProps(0)} />
          <Tab label="Master Import Config" {...a11yProps(1)} />
        </Tabs>
      </Box>
      <TabPanel value={selected} index={0}>
        <MasterImportedDataList />
      </TabPanel>
      <TabPanel value={selected} index={1}>
        <MasterImportedConfigFile />
      </TabPanel>
    </Box>
  );
  return (
    <PageLayout>
      <Typography variant="h5" sx={{ pt: 2 }}>
        Import Data
      </Typography>
      <Box>{symptomsAddInfo}</Box>
    </PageLayout>
  );
}
