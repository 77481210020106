import { useEffect, useState } from "react";
import * as am5 from "@amcharts/amcharts5";
import * as am5xy from "@amcharts/amcharts5/xy";
import am5themes_Animated from "@amcharts/amcharts5/themes/Animated";
import am5themes_Responsive from "@amcharts/amcharts5/themes/Responsive";
import am5themes_Material from "@amcharts/amcharts5/themes/Material";
import { Box } from "@mui/material";
import { BMIData } from "../Model";
import React from "react";

type Props = {
  data?: BMIData | any;
  unit?: string | any;
};

export default function BMI({ data, unit }: Props) {
  const [chartData, setChartData] = useState([] as BMIData[]);
  const [unitt, setUnitt] = useState<any>();
  useEffect(() => {
    setChartData(data);
    if (data !== undefined) {
      setChartData(data);
    }
    if (unit !== undefined) {
      setUnitt(unit);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [data, unit]);
  useEffect(() => {
    let root = am5.Root.new("bmichart");
    root.setThemes([
      am5themes_Animated.new(root),
      am5themes_Responsive.new(root),
      am5themes_Material.new(root),
    ]);
    // Create chart
    // https://www.amcharts.com/docs/v5/charts/xy-chart/
    let chart = root.container.children.push(
      am5xy.XYChart.new(root, {
        panX: true,
        panY: true,
        wheelX: "panX",
        wheelY: "zoomX",
      }),
    );

    // Create axes
    // https://www.amcharts.com/docs/v5/charts/xy-chart/axes/
    let xAxis = chart.xAxes.push(
      am5xy.DateAxis.new(root, {
        baseInterval: { timeUnit: unitt, count: 1 },
        renderer: am5xy.AxisRendererX.new(root, {
          minGridDistance: 20,
        }),
      }),
    );

    let yAxis = chart.yAxes.push(
      am5xy.ValueAxis.new(root, {
        renderer: am5xy.AxisRendererY.new(root, {}),
      }),
    );

    // Add series
    // https://www.amcharts.com/docs/v5/charts/xy-chart/series/
    let series = chart.series.push(
      am5xy.LineSeries.new(root, {
        minBulletDistance: 10,
        xAxis: xAxis,
        yAxis: yAxis,
        valueYField: "value",
        valueXField: "date",
        tooltip: am5.Tooltip.new(root, {
          pointerOrientation: "horizontal",
          labelText: "BMI: [bold]{valueY}[/]",
        }),
      }),
    );

    series.strokes.template.setAll({
      strokeWidth: 3,
    });

    series.data.setAll(chartData);

    series.bullets.push(function () {
      return am5.Bullet.new(root, {
        sprite: am5.Circle.new(root, {
          radius: 6,
          fill: am5.color(0x76b041),
          stroke: am5.color(0x76b041),
          strokeWidth: 2,
        }),
      });
    });

    // Add cursor
    // https://www.amcharts.com/docs/v5/charts/xy-chart/cursor/
    let cursor = chart.set(
      "cursor",
      am5xy.XYCursor.new(root, {
        xAxis: xAxis,
      }),
    );
    cursor.lineY.set("visible", false);

    // add scrollbar
    chart.set(
      "scrollbarX",
      am5.Scrollbar.new(root, {
        orientation: "horizontal",
      }),
    );

    // Function to add process control ranges
    function addLimits(lower: any, upper: any) {
      // Add range fill
      createRange(lower, upper, undefined, am5.color("#b4afba"), false);

      // Add upper/average/lower lines
      createRange(17, undefined, "Underweight", am5.color(0x4d00ff), false);
      createRange(29.9, undefined, "Overweight", am5.color("#fa7e02"), false);
      createRange(33.9, undefined, "Obesity", am5.color("#fa0702"), false);
      createRange(24.9, undefined, "Healthy Weight", am5.color("#2f752f"), true);
      createRange(18.5, undefined, "Healthy Weight", am5.color("#2f752f"), true);
    }

    addLimits(17, 33.9);

    function createRange(value: any, endValue: any, label: any, color: any, dashed: any) {
      let rangeDataItem = yAxis.makeDataItem({
        value: value,
        endValue: endValue,
      });

      let range = yAxis.createAxisRange(rangeDataItem);

      if (endValue) {
        range.get("axisFill")!.setAll({
          fill: color,
          fillOpacity: 0.2,
          visible: true,
        });
      } else {
        range.get("grid")!.setAll({
          stroke: color,
          strokeOpacity: 1,
          strokeWidth: 2,
          location: 1,
        });

        if (dashed) {
          range.get("grid")!.set("strokeDasharray", [5, 3]);
        }
      }

      if (label) {
        range.get("label")!.setAll({
          text: label,
          location: 1,
          fontSize: 19,
          inside: true,
          centerX: am5.p0,
          centerY: am5.p100,
        });
      }
    }
    chart.set(
      "scrollbarX",
      am5.Scrollbar.new(root, {
        orientation: "horizontal",
      }),
    );
    // Make stuff animate on load
    // https://www.amcharts.com/docs/v5/concepts/animations/
    series.appear(1000, 100);
    chart.appear(1000, 100);
    return () => root.dispose();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [chartData, unitt]);

  return <Box id="bmichart" style={{ width: "100%", height: "550px", borderRadius: 20 }} />;
}
