import {
  AppointmentSelectedDoctorListDetails,
  GetAppointmentSelectedDoctorListActionTypes,
  GET_APPOINTMENT_SELECTED_DOCTOR_LIST_FAIL,
  GET_APPOINTMENT_SELECTED_DOCTOR_LIST_LOADING,
  GET_APPOINTMENT_SELECTED_DOCTOR_LIST_SUCCESS,
} from "./ActionTypes";
import { GetAppointmentSelectedDoctorListRes } from "./Model";

const initialStateGetPosts: AppointmentSelectedDoctorListDetails = {
  loading: false,
  getAppointmentSelectedDoctorListRes: {} as GetAppointmentSelectedDoctorListRes,
  errRes: "",
};
export const getAppointmentSelectedDoctorListReducer = (
  state = initialStateGetPosts,
  action: GetAppointmentSelectedDoctorListActionTypes,
): AppointmentSelectedDoctorListDetails => {
  switch (action.type) {
    case GET_APPOINTMENT_SELECTED_DOCTOR_LIST_SUCCESS:
      return {
        ...state,
        loading: false,
        getAppointmentSelectedDoctorListRes: action.payload,
        errRes: "",
      };
    case GET_APPOINTMENT_SELECTED_DOCTOR_LIST_LOADING:
      return {
        ...state,
        loading: true,
        getAppointmentSelectedDoctorListRes: {} as GetAppointmentSelectedDoctorListRes,
      };
    case GET_APPOINTMENT_SELECTED_DOCTOR_LIST_FAIL:
      return {
        ...state,
        getAppointmentSelectedDoctorListRes: {} as GetAppointmentSelectedDoctorListRes,
        loading: false,
        errRes: action.errRes,
      };
    default:
      return state;
  }
};
