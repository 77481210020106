import { GetAppointmentReqListRes } from "./Model";

// Patient Meeting Loading State
export const GET_APPOINTMENT_REQ_LIST_LOADING = "GET_APPOINTMENT_REQ_LIST_LOADING";
export const GET_APPOINTMENT_REQ_LIST_SUCCESS = "GET_APPOINTMENT_REQ_LIST_SUCCESS";
export const GET_APPOINTMENT_REQ_LIST_FAIL = "GET_APPOINTMENT_REQ_LIST_FAIL";
export const GET_APPOINTMENT_REQ_LIST_DATA = "GET_APPOINTMENT_REQ_LIST_DATA";

export interface AppointmentListDetails {
  getAppointmentListRes: GetAppointmentReqListRes;
  loading: boolean;
  errRes: string;
}
export interface GetAppointmentListActionLoading {
  type: typeof GET_APPOINTMENT_REQ_LIST_LOADING;
}
export interface GetAppointmentListActionSuccess {
  type: typeof GET_APPOINTMENT_REQ_LIST_SUCCESS;
  payload: GetAppointmentReqListRes;
  errRes: string;
}
export interface GetAppointmentListActionFail {
  type: typeof GET_APPOINTMENT_REQ_LIST_FAIL;
  payload: GetAppointmentReqListRes;
  errRes: string;
}
interface GetAppointmentListActionAction {
  type: typeof GET_APPOINTMENT_REQ_LIST_DATA;
  payload: GetAppointmentReqListRes;
  errRes: string;
}

export type GetAppointmentListActionTypes =
  | GetAppointmentListActionAction
  | GetAppointmentListActionLoading
  | GetAppointmentListActionSuccess
  | GetAppointmentListActionFail;
