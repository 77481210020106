import {
  GET_OBSERVATION_TYPE_FAIL,
  GET_OBSERVATION_TYPE_LOADING,
  GET_OBSERVATION_TYPE_SUCCESS,
  GET_OBSERVATION_TYPE_UPDATE_API_MSG,
  GET_OBSERVATION_TYPE_UPDATE_API_RES,
  GetObservationTypeActionTypes,
} from "./ActionTypes";
import { GetObservationTypeResponse } from "./Model";

export const getObservationTypeLoadingAction = (
  loading: boolean,
): GetObservationTypeActionTypes => {
  return {
    type: GET_OBSERVATION_TYPE_LOADING,
    loading: loading,
  };
};

export const getObservationTypeSuccessAction = (
  GetObservationTypeResponse: GetObservationTypeResponse,
  successMsg: string,
): GetObservationTypeActionTypes => {
  return {
    type: GET_OBSERVATION_TYPE_SUCCESS,
    payload: GetObservationTypeResponse,
    successMsg: successMsg,
  };
};

export const getObservationTypeErrorAction = (
  GetObservationTypeResponse: GetObservationTypeResponse,
  errMsg: string,
  status: number,
): GetObservationTypeActionTypes => {
  return {
    type: GET_OBSERVATION_TYPE_FAIL,
    payload: GetObservationTypeResponse,
    errorMsg: errMsg,
    status: status,
  };
};
export const getObservationTypeUpdateAPIMsgAction = (
  GetObservationTypeResponse: GetObservationTypeResponse,
  successMsg: string,
  errMsg: string,
  status: number,
): GetObservationTypeActionTypes => {
  return {
    type: GET_OBSERVATION_TYPE_UPDATE_API_MSG,
    payload: GetObservationTypeResponse,
    successMsg: successMsg,
    errorMsg: errMsg,
    status: status,
  };
};

export const getObservationTypeAPIResClearAction = (
  GetObservationTypeResponse: GetObservationTypeResponse,
  successMsg: string,
  errMsg: string,
  status: number,
): GetObservationTypeActionTypes => {
  return {
    type: GET_OBSERVATION_TYPE_UPDATE_API_RES,
    payload: GetObservationTypeResponse,
    successMsg: successMsg,
    errorMsg: errMsg,
    status: status,
  };
};
