import { DataListByObservationTypeRes } from "./Model";
export const DATALIST_BY_OBSERVATION_TYPE_LOADING = "DATALIST_BY_OBSERVATION_TYPE_LOADING";
export const DATALIST_BY_OBSERVATION_TYPE_SUCCESS = "DATALIST_BY_OBSERVATION_TYPE_SUCCESS";
export const DATALIST_BY_OBSERVATION_TYPE_FAIL = "DATALIST_BY_OBSERVATION_TYPE_FAIL";
export const DATALIST_BY_OBSERVATION_TYPE_UPDATE_API_MSG =
  "DATALIST_BY_OBSERVATION_TYPE_UPDATE_API_MSG";
export const DATALIST_BY_OBSERVATION_TYPE_UPDATE_API_RES =
  "DATALIST_BY_OBSERVATION_TYPE_UPDATE_API_RES";

export interface DataListByObservationTypeState {
  dataListByObservationTypeRes: DataListByObservationTypeRes;
  loading: boolean;
  successMsg: string;
  errorMsg: string;
  status: number;
}
export interface DataListByObservationTypeLoading {
  type: typeof DATALIST_BY_OBSERVATION_TYPE_LOADING;
  loading: boolean;
}
export interface DataListByObservationTypeSuccess {
  type: typeof DATALIST_BY_OBSERVATION_TYPE_SUCCESS;
  payload: DataListByObservationTypeRes;
  successMsg: string;
}
export interface DataListByObservationTypeFail {
  type: typeof DATALIST_BY_OBSERVATION_TYPE_FAIL;
  payload: DataListByObservationTypeRes;
  errorMsg: string;
  status: number;
}
export interface DataListByObservationTypeUpdateAPIMsg {
  type: typeof DATALIST_BY_OBSERVATION_TYPE_UPDATE_API_MSG;
  payload: DataListByObservationTypeRes;
  successMsg: string;
  errorMsg: string;
  status: number;
}
export interface DataListByObservationTypeUpdateAPIRes {
  type: typeof DATALIST_BY_OBSERVATION_TYPE_UPDATE_API_RES;
  payload: DataListByObservationTypeRes;
  successMsg: string;
  errorMsg: string;
  status: number;
}

export type DataListByObservationTypeActionTypes =
  | DataListByObservationTypeLoading
  | DataListByObservationTypeSuccess
  | DataListByObservationTypeFail
  | DataListByObservationTypeUpdateAPIMsg
  | DataListByObservationTypeUpdateAPIRes;
