import {
    GetPatientEncounterListActionTypes,
    PATIENT_ENCOUNTER_LIST_FAIL,
    PATIENT_ENCOUNTER_LIST_LOADING,
    PATIENT_ENCOUNTER_LIST_SUCCESS,
    GetPatientEncounterListState,
    PATIENT_ENCOUNTER_LIST_UPDATE_API_MSG,
    PATIENT_ENCOUNTER_LIST_UPDATE_API_RES,
  } from "./ActionTypes";
  import { GetPatientEncounterListRes } from "./Model";
  
  const initialStateGetPosts: GetPatientEncounterListState = {
    loading: false,
    patientEncounterListRes: {} as GetPatientEncounterListRes,
    successMsg: "",
    errorMsg: "",
    status: 0,
  };
  export const getPatientEncounterListReducer = (
    state = initialStateGetPosts,
    action: GetPatientEncounterListActionTypes,
  ): GetPatientEncounterListState => {
    switch (action.type) {
      case PATIENT_ENCOUNTER_LIST_LOADING:
        return {
          ...state,
          loading: action.loading,
        };
      case PATIENT_ENCOUNTER_LIST_SUCCESS:
        return {
          ...state,
          patientEncounterListRes: action.payload,
          successMsg: action.successMsg,
        };
      case PATIENT_ENCOUNTER_LIST_FAIL:
        return {
          ...state,
          patientEncounterListRes: action.payload,
          errorMsg: action.errorMsg,
          status: action.status,
        };
      case PATIENT_ENCOUNTER_LIST_UPDATE_API_MSG:
        return {
          ...state,
          patientEncounterListRes: action.payload,
          successMsg: action.successMsg,
          errorMsg: action.errorMsg,
          status: action.status,
        };
      case PATIENT_ENCOUNTER_LIST_UPDATE_API_RES:
        return {
          ...state,
          patientEncounterListRes: action.payload,
          successMsg: action.successMsg,
          errorMsg: action.errorMsg,
          status: action.status,
        };
      default:
        return state;
    }
  };
  