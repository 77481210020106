import axios from "axios";
import { Dispatch } from "redux";
import Url from "../../../Url";
import { setupInterceptorsTo } from "../../Authentication/Interceptors";
import { getSharedPatientDetailsAction } from "./Action";
import {
  sharedPatientDetailsActionTypes,
  MEETING_LIST_FAIL,
  MEETING_LIST_LOADING,
  MEETING_LIST_SUCCESS,
} from "./ActionTypes";
import { headers } from "components/Utility";

export const getMeetingList = (caseid: string) => {
  return function (dispatch: Dispatch<sharedPatientDetailsActionTypes>) {
    dispatch({
      type: MEETING_LIST_LOADING,
    });

    setupInterceptorsTo(axios)
      .post(
        Url.apiURL + `/telehealthapp/api/telehealth/getInstantMeeting`,
        { caseId: caseid },
        { headers: headers },
      )
      .then((res) => {
        dispatch(getSharedPatientDetailsAction(res.data));
        dispatch({
          type: MEETING_LIST_SUCCESS,
          payload: res.data,
        });
      })
      .catch((_error) => {
        dispatch({
          type: MEETING_LIST_FAIL,
        });
      });
  };
};
