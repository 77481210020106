import {
  SLEEP_SCORE_OBSERVATION_TYPE_FAIL,
  SLEEP_SCORE_OBSERVATION_TYPE_LOADING,
  SLEEP_SCORE_OBSERVATION_TYPE_SUCCESS,
  SLEEP_SCORE_OBSERVATION_TYPE_UPDATE_API_MSG,
  SLEEP_SCORE_OBSERVATION_TYPE_UPDATE_API_RES,
  SleepScoreObservationTypeActionTypes,
} from "./ActionTypes";
import { SleepScoreObservationTypeRes } from "./Model";

export const sleepScoreObservationTypeLoadingAction = (
  loading: boolean,
): SleepScoreObservationTypeActionTypes => {
  return {
    type: SLEEP_SCORE_OBSERVATION_TYPE_LOADING,
    loading: loading,
  };
};

export const sleepScoreObservationTypeSuccessAction = (
  sleepScoreObservationTypeRes: SleepScoreObservationTypeRes,
  successMsg: string,
): SleepScoreObservationTypeActionTypes => {
  return {
    type: SLEEP_SCORE_OBSERVATION_TYPE_SUCCESS,
    payload: sleepScoreObservationTypeRes,
    successMsg: successMsg,
  };
};

export const sleepScoreObservationTypeErrorAction = (
  sleepScoreObservationTypeRes: SleepScoreObservationTypeRes,
  errMsg: string,
  status: number,
): SleepScoreObservationTypeActionTypes => {
  return {
    type: SLEEP_SCORE_OBSERVATION_TYPE_FAIL,
    payload: sleepScoreObservationTypeRes,
    errorMsg: errMsg,
    status: status,
  };
};
export const sleepScoreObservationTypeUpdateAPIMsgAction = (
  sleepScoreObservationTypeRes: SleepScoreObservationTypeRes,
  successMsg: string,
  errMsg: string,
  status: number,
): SleepScoreObservationTypeActionTypes => {
  return {
    type: SLEEP_SCORE_OBSERVATION_TYPE_UPDATE_API_MSG,
    payload: sleepScoreObservationTypeRes,
    successMsg: successMsg,
    errorMsg: errMsg,
    status: status,
  };
};

export const sleepScoreObservationTypeAPIResClearAction = (
  sleepScoreObservationTypeRes: SleepScoreObservationTypeRes,
  successMsg: string,
  errMsg: string,
  status: number,
): SleepScoreObservationTypeActionTypes => {
  return {
    type: SLEEP_SCORE_OBSERVATION_TYPE_UPDATE_API_RES,
    payload: sleepScoreObservationTypeRes,
    successMsg: successMsg,
    errorMsg: errMsg,
    status: status,
  };
};
