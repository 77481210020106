import {
  DATALIST_BY_OBSERVATION_TYPE_FAIL,
  DATALIST_BY_OBSERVATION_TYPE_LOADING,
  DATALIST_BY_OBSERVATION_TYPE_SUCCESS,
  DATALIST_BY_OBSERVATION_TYPE_UPDATE_API_MSG,
  DATALIST_BY_OBSERVATION_TYPE_UPDATE_API_RES,
  DataListByObservationTypeActionTypes,
} from "./ActionTypes";
import { DataListByObservationTypeRes } from "./Model";

export const dataListByObservationTypeLoadingAction = (
  loading: boolean,
): DataListByObservationTypeActionTypes => {
  return {
    type: DATALIST_BY_OBSERVATION_TYPE_LOADING,
    loading: loading,
  };
};

export const dataListByObservationTypeSuccessAction = (
  dataListByObservationTypeRes: DataListByObservationTypeRes,
  successMsg: string,
): DataListByObservationTypeActionTypes => {
  return {
    type: DATALIST_BY_OBSERVATION_TYPE_SUCCESS,
    payload: dataListByObservationTypeRes,
    successMsg: successMsg,
  };
};

export const dataListByObservationTypeErrorAction = (
  dataListByObservationTypeRes: DataListByObservationTypeRes,
  errMsg: string,
  status: number,
): DataListByObservationTypeActionTypes => {
  return {
    type: DATALIST_BY_OBSERVATION_TYPE_FAIL,
    payload: dataListByObservationTypeRes,
    errorMsg: errMsg,
    status: status,
  };
};
export const dataListByObservationTypeUpdateAPIMsgAction = (
  dataListByObservationTypeRes: DataListByObservationTypeRes,
  successMsg: string,
  errMsg: string,
  status: number,
): DataListByObservationTypeActionTypes => {
  return {
    type: DATALIST_BY_OBSERVATION_TYPE_UPDATE_API_MSG,
    payload: dataListByObservationTypeRes,
    successMsg: successMsg,
    errorMsg: errMsg,
    status: status,
  };
};

export const dataListByObservationTypeAPIResClearAction = (
  dataListByObservationTypeRes: DataListByObservationTypeRes,
  successMsg: string,
  errMsg: string,
  status: number,
): DataListByObservationTypeActionTypes => {
  return {
    type: DATALIST_BY_OBSERVATION_TYPE_UPDATE_API_RES,
    payload: dataListByObservationTypeRes,
    successMsg: successMsg,
    errorMsg: errMsg,
    status: status,
  };
};
