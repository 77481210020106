import {
  PATIENT_DASHBOARD_LATEST_DATALIST_FAIL,
  PATIENT_DASHBOARD_LATEST_DATALIST_LOADING,
  PATIENT_DASHBOARD_LATEST_DATALIST_SUCCESS,
  PATIENT_DASHBOARD_LATEST_DATALIST_UPDATE_API_MSG,
  PATIENT_DASHBOARD_LATEST_DATALIST_UPDATE_API_RES,
  PatientDashBoardLatestDataListActionTypes,
} from "./ActionTypes";
import { LatestDataListRes } from "./Model";

export const patientDashBoardLatestDataListLoadingAction = (
  loading: boolean,
): PatientDashBoardLatestDataListActionTypes => {
  return {
    type: PATIENT_DASHBOARD_LATEST_DATALIST_LOADING,
    loading: loading,
  };
};

export const patientDashBoardLatestDataListSuccessAction = (
  patientDashBoardLatestDataListRes: LatestDataListRes,
  successMsg: string,
): PatientDashBoardLatestDataListActionTypes => {
  return {
    type: PATIENT_DASHBOARD_LATEST_DATALIST_SUCCESS,
    payload: patientDashBoardLatestDataListRes,
    successMsg: successMsg,
  };
};

export const patientDashBoardLatestDataListErrorAction = (
  patientDashBoardLatestDataListRes: LatestDataListRes,
  errMsg: string,
  status: number,
): PatientDashBoardLatestDataListActionTypes => {
  return {
    type: PATIENT_DASHBOARD_LATEST_DATALIST_FAIL,
    payload: patientDashBoardLatestDataListRes,
    errorMsg: errMsg,
    status: status,
  };
};
export const patientDashBoardLatestDataListUpdateAPIMsgAction = (
  patientDashBoardLatestDataListRes: LatestDataListRes,
  successMsg: string,
  errMsg: string,
  status: number,
): PatientDashBoardLatestDataListActionTypes => {
  return {
    type: PATIENT_DASHBOARD_LATEST_DATALIST_UPDATE_API_MSG,
    payload: patientDashBoardLatestDataListRes,
    successMsg: successMsg,
    errorMsg: errMsg,
    status: status,
  };
};

export const patientDashBoardLatestDataListAPIResClearAction = (
  patientDashBoardLatestDataListRes: LatestDataListRes,
  successMsg: string,
  errMsg: string,
  status: number,
): PatientDashBoardLatestDataListActionTypes => {
  return {
    type: PATIENT_DASHBOARD_LATEST_DATALIST_UPDATE_API_RES,
    payload: patientDashBoardLatestDataListRes,
    successMsg: successMsg,
    errorMsg: errMsg,
    status: status,
  };
};
