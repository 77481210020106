import {
  AppointmentListDetails,
  GetAppointmentListActionTypes,
  GET_APPOINTMENT_REQ_LIST_FAIL,
  GET_APPOINTMENT_REQ_LIST_LOADING,
  GET_APPOINTMENT_REQ_LIST_SUCCESS,
} from "./ActionTypes";
import { GetAppointmentReqListRes } from "./Model";

const initialStateGetPosts: AppointmentListDetails = {
  loading: false,
  getAppointmentListRes: {} as GetAppointmentReqListRes,
  errRes: "",
};
export const getAppointmentReqListReducer = (
  state = initialStateGetPosts,
  action: GetAppointmentListActionTypes,
): AppointmentListDetails => {
  switch (action.type) {
    case GET_APPOINTMENT_REQ_LIST_SUCCESS:
      return {
        ...state,
        loading: false,
        getAppointmentListRes: action.payload,
        errRes: "",
      };
    case GET_APPOINTMENT_REQ_LIST_LOADING:
      return {
        ...state,
        loading: true,
        getAppointmentListRes: {} as GetAppointmentReqListRes,
      };
    case GET_APPOINTMENT_REQ_LIST_FAIL:
      return {
        ...state,
        getAppointmentListRes: {} as GetAppointmentReqListRes,
        loading: false,
        errRes: action.errRes,
      };
    default:
      return state;
  }
};
