import { EditPatientImportConfigValue } from "./Model";

export const EDIT_PATIENT_IMPORT_CONFIG_LOADING = "EDIT_PATIENT_IMPORT_CONFIG_LOADING";
export const EDIT_PATIENT_IMPORT_CONFIG_SUCCESS = "EDIT_PATIENT_IMPORT_CONFIG_SUCCESS";
export const EDIT_PATIENT_IMPORT_CONFIG_FAIL = "EDIT_PATIENT_IMPORT_CONFIG_FAIL";
export const EDIT_PATIENT_IMPORT_CONFIG_LIST = "EDIT_PATIENT_IMPORT_CONFIG_LIST";

export interface EditPatientImportConfigDetails {
  editPatientImportConfigRes: EditPatientImportConfigValue;
  loading: boolean;
  errRes: string;
}
export interface EditPatientImportConfigLoading {
  type: typeof EDIT_PATIENT_IMPORT_CONFIG_LOADING;
}
export interface EditPatientImportConfigSuccess {
  type: typeof EDIT_PATIENT_IMPORT_CONFIG_SUCCESS;
  payload: EditPatientImportConfigValue;
  errRes: string;
}
export interface EditPatientImportConfigFail {
  type: typeof EDIT_PATIENT_IMPORT_CONFIG_FAIL;
  errRes: string;
}

interface EditPatientImportConfigAction {
  type: typeof EDIT_PATIENT_IMPORT_CONFIG_LIST;
  payload: EditPatientImportConfigValue;
  errRes: string;
}

export type editPatientImportConfigActionTypes =
  | EditPatientImportConfigAction
  | EditPatientImportConfigLoading
  | EditPatientImportConfigSuccess
  | EditPatientImportConfigFail;
