import axios from "axios";
import { Dispatch } from "redux";
import Url from "../../Url";
import { getHealthcampPatientAction } from "./Actions";
import {
  HealthcampPatientGetActionTypes,
  HCPATIENT_LIST_FAIL,
  HCPATIENT_LIST_LOADING,
  HCPATIENT_LIST_SUCCESS,
} from "./ActionTypes";
import { headers } from "components/Utility";

export const getPatientListByHealthcamp = (healthCampId: string, pageNo: any) => {
  return function (dispatch: Dispatch<HealthcampPatientGetActionTypes>) {
    dispatch({
      type: HCPATIENT_LIST_LOADING,
    });

    axios
      .post(
        Url.apiURL +
          `/patientapp/api/patient/patient_list_of_healthcamp_where_as_owner?page=${Number(
            pageNo,
          )}&size=50`,
        { healthCampId: healthCampId },
        {
          headers: headers,
        },
      )
      .then((res) => {
        let resData = res.data;
        let pageCount = res.data.numberOfPages;
        dispatch(getHealthcampPatientAction(resData, pageCount));
        dispatch({
          type: HCPATIENT_LIST_SUCCESS,
          payload: resData,
          pageCount: pageCount,
        });
      })
      .catch((error) => {
        console.log("error record====", error);
        dispatch({
          type: HCPATIENT_LIST_FAIL,
        });
      });
  };
};
