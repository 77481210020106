import axios from "axios";
import { Dispatch } from "redux";
import Url from "../../Url";
import { setupInterceptorsTo } from "../Authentication/Interceptors";
import {
  getDoctorDetailsActionTypes,
  GET_DOCTOR_DETAILS_SUCCESS,
  GET_DOCTOR_DETAILS_LOADING,
  GET_DOCTOR_DETAILS_FAIL,
} from "./ActionTypes";
import { GetDoctorDetailsValue } from "./Model";
import { headers } from "components/Utility";

export const getDoctorDetailsApi = (doctorId: string) => {
  return function (dispatch: Dispatch<getDoctorDetailsActionTypes>) {
    dispatch({
      type: GET_DOCTOR_DETAILS_LOADING,
    });

    setupInterceptorsTo(axios)
      .get(Url.apiURL + `/landingeapp/api/doctor-details-by-id/${doctorId}`, { headers: headers })
      .then((res) => {
        dispatch({
          type: GET_DOCTOR_DETAILS_SUCCESS,
          payload: res.data,
          errRes: "",
        });
      })
      .catch((error) => {
        dispatch({
          type: GET_DOCTOR_DETAILS_FAIL,
          payload: {} as GetDoctorDetailsValue,
          errRes: error.response.data.message,
        });
      });
  };
};
