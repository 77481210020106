import axios from "axios";
import { Dispatch } from "redux";
import Url from "../../Url";
import { setupInterceptorsTo } from "../Authentication/Interceptors";
import {
  editPatientInsuranceActionTypes,
  EDIT_PATIENT_INSURANCE_FAIL,
  EDIT_PATIENT_INSURANCE_LOADING,
  EDIT_PATIENT_INSURANCE_SUCCESS,
} from "./ActionTypes";
import { EditPatientInsuranceBody, EditPatientInsuranceValue } from "./Model";
import { headers } from "components/Utility";

export const editPatientInsuranceApi = (body: EditPatientInsuranceBody) => {
  return function (dispatch: Dispatch<editPatientInsuranceActionTypes>) {
    dispatch({
      type: EDIT_PATIENT_INSURANCE_LOADING,
    });

    setupInterceptorsTo(axios)
      .post(Url.apiURL + `/patientapp/api/patient/edit_patient_insurance`, body, {
        headers: headers,
      })
      .then((res) => {
        dispatch({
          type: EDIT_PATIENT_INSURANCE_SUCCESS,
          payload: res.data,
          errRes: "",
        });
      })
      .catch((error) => {
        dispatch({
          type: EDIT_PATIENT_INSURANCE_FAIL,
          payload: {} as EditPatientInsuranceValue,
          errRes: error.response.data.message,
        });
      });
  };
};
