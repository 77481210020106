import {
  Alert,
  Box,
  Button,
  Card,
  CardContent,
  CardHeader,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Grid,
  IconButton,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  TextField,
  Typography,
  useMediaQuery,
} from "@mui/material";
import React, { useCallback, useEffect, useState } from "react";
import DescriptionIcon from "@mui/icons-material/Description";
import CloseIcon from "@mui/icons-material/Close";
import { t } from "i18next";
import MWTextField from "../../components/MWTextField";
import { useTheme } from "@mui/material/styles";
import { DesktopDatePicker, LocalizationProvider } from "@mui/x-date-pickers";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import { useDispatch, useSelector } from "react-redux";
import { useParams } from "react-router-dom";
import {
  addChiefComplaint,
  deleteFilePreparationDocument,
  getChiefComplaintList,
} from "../../redux/FilePreparation/ApiCall";
import { AppState } from "../../redux/store/Store";
import MWUploader from "../../components/MWUploader";

export default function PrescriptionOrder() {
  const DATE_OPTIONS = {
    weekday: "short",
    year: "numeric",
    month: "short",
    day: "numeric",
  } as const;
  const truncate = (str: string) => {
    return str.length > 10 ? str.substring(0, 20) + "..." : str;
  };
  const theme = useTheme();
  const fullScreen = useMediaQuery(theme.breakpoints.down("md"));
  const [prescriptionModalActive, setPrescriptionModalActive] = useState(false);
  const dispatch = useDispatch();
  // const { patientid } = useParams();
  const { patientid } = useParams() as {
    patientid: string;
  };
  // const { caseid } = useParams();
  const { caseid } = useParams() as {
    caseid: string;
  };
  const getprescriptionBody = {
    caseId: caseid,
    secondOpinionUiSection: "ordersAndPrescriptions",
  };
  const symptomsValue = useSelector((state: AppState) => state.ccValue);
  const [imageId, setImageId] = useState("");
  console.log(setImageId);
  const [documentListValue, setDocumentListValue] = useState<any[]>([]);
  const updateDataStateFromChild = async (passedval: any) => {
    setImageInfo(passedval);
  };
  const delImgAction = (passeVal: boolean) => {
    console.log("passeVal", passeVal);
  };
  // Prescription API Success
  const closePrescriptionSuccess = () => {
    setPrescriptionSuccess("");
  };
  const [prescriptionSuccess, setPrescriptionSuccess] = useState("");
  const prescriptionSuccessDiv = prescriptionSuccess ? (
    <Alert
      severity="success"
      onClose={() => {
        closePrescriptionSuccess();
      }}
    >
      <Typography>{prescriptionSuccess}</Typography>
    </Alert>
  ) : (
    ""
  );

  // Get PreviewImg Details
  const viewPreviewImageData = (passedVal: any) => {
    console.log("passedVal", passedVal);
  };

  // Prescription API Error
  const closePrescriptionError = () => {
    setPrescriptionError("");
  };

  const [prescriptionError, setPrescriptionError] = useState("");
  const prescriptionErrorDiv = prescriptionError ? (
    <Alert
      severity="error"
      onClose={() => {
        closePrescriptionError();
      }}
    >
      <Typography>{prescriptionError}</Typography>
    </Alert>
  ) : (
    ""
  );

  const [prescriptionDescriptionError, setPrescriptionDescriptionError] = useState("");
  const prescriptionDescriptionErrordiv = prescriptionDescriptionError ? (
    <Typography color="error">{prescriptionDescriptionError}</Typography>
  ) : (
    ""
  );
  const [patientDetailsId, setPatientDetailsId] = useState("");
  const [prescriptionStartDate, setPrescriptionStartDate] = React.useState<Date | null>(new Date());
  const handlePrescriptionStartChange = (prescriptionSDate: Date | null) => {
    setPrescriptionStartDate(prescriptionSDate);
  };

  const [docViwerActive, setDocViwerActive] = useState(false);
  const handleDocViewerChange = useCallback(
    () => setDocViwerActive(!docViwerActive),
    [docViwerActive],
  );

  const [prescriptionDescription, setPrescriptionDescription] = useState("");
  const handlePrescriptionDescriptionChange = useCallback((newValue: any) => {
    setPrescriptionDescription(newValue);
  }, []);

  const [prescriptionEndDate, setPrescriptionEndDate] = React.useState<Date | null>(new Date());
  const handlePrescriptionEndDateChange = (prescriptionEDate: Date | null) => {
    setPrescriptionEndDate(prescriptionEDate);
  };

  const [deletePrescriptionModalActive, setDeletePrescriptionModalActive] = useState(false);
  const handleDeletePrescriptionModalChange = useCallback(
    () => setDeletePrescriptionModalActive(!deletePrescriptionModalActive),
    [deletePrescriptionModalActive],
  );

  const [imageInfo, setImageInfo] = useState("");
  const handlePrescriptionModalToggle = () => {
    setPrescriptionModalActive((prescriptionModalActive) => !prescriptionModalActive);

    // Submit Orders & Prescription API
    setPrescriptionStartDate(new Date());
    setPrescriptionEndDate(null);
    setImageInfo("");
    setPrescriptionDescription("");
    setPrescriptionSuccess("");
    setPrescriptionError("");
    dispatch(getChiefComplaintList(getprescriptionBody));
  };

  useEffect(() => {
    let symptomConfig = [];
    for (var i = 0; i < symptomsValue.ccValue.length; i++) {
      let patientDetailsID = symptomsValue.ccValue[i].id;
      let description =
        symptomsValue.ccValue[i].description === null
          ? ""
          : truncate(symptomsValue.ccValue[i].description);
      let dateStart =
        symptomsValue.ccValue[i].startDate === null
          ? ""
          : new Date(symptomsValue.ccValue[i].startDate).toLocaleDateString("en-US", DATE_OPTIONS);
      let dateEnd =
        symptomsValue.ccValue[i].endDate === null
          ? ""
          : new Date(symptomsValue.ccValue[i].endDate).toLocaleDateString("en-US", DATE_OPTIONS);
      let supportingDocuments =
        symptomsValue.ccValue[i].patientDocument === null
          ? ""
          : symptomsValue.ccValue[i].patientDocument.document;
      setPatientDetailsId(patientDetailsID);
      symptomConfig.push([
        <Button
          variant="outlined"
          startIcon={<DescriptionIcon />}
          onClick={() => {
            setDocViwerActive(true);
            setImageInfo(supportingDocuments);
          }}
        ></Button>,
        description,
        dateStart,
        dateEnd,
        // <Button icon={DeleteMinor} size="slim" onClick={() => deleteMedicalSummary(selectedResponse)}></Button>
      ]);
    }
    setDocumentListValue(symptomConfig);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [symptomsValue.ccValue]);
  useEffect(() => {
    dispatch(getChiefComplaintList(getprescriptionBody));
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);
  // Delete body....
  const deletePrescriptionAPIBody = {
    caseId: caseid,
    patientDetailId: patientDetailsId,
  };
  // delete api call here ....
  const DeletePrescriptionSubmit = () => {
    dispatch(deleteFilePreparationDocument(deletePrescriptionAPIBody));
  };
  // Prescription & Order Table
  const prescriptionTable = (
    <Box p={1}>
      <Card>
        <CardHeader
          title={
            <Grid container direction="row" justifyContent="space-between" alignItems="flex-start">
              <Grid item>{t("orderPresPage.orderPres", { ns: ["home"] })}</Grid>
              <Grid item>
                <Button variant="contained" onClick={() => handlePrescriptionModalToggle()}>
                  {t("orderPresPage.addDoc", { ns: ["home"] })!}
                </Button>
              </Grid>
            </Grid>
          }
        ></CardHeader>
        <CardContent>
          {documentListValue && documentListValue.length ? (
            <TableContainer>
              <Table size="small" aria-label="a dense table">
                <TableHead>
                  <TableRow>
                    <TableCell>{t("orderPresPage.ques", { ns: ["home"] })}</TableCell>
                    <TableCell>{t("orderPresPage.doc", { ns: ["home"] })}</TableCell>
                    <TableCell>{t("orderPresPage.ans", { ns: ["home"] })}</TableCell>
                    <TableCell>{t("orderPresPage.dt", { ns: ["home"] })}</TableCell>
                  </TableRow>
                </TableHead>
                <TableBody>
                  {documentListValue.map((row: any) => (
                    <TableRow
                      key={row.description}
                      sx={{
                        "&:last-child td, &:last-child th": { border: 0 },
                      }}
                    >
                      <TableCell align="left">{row[0]}</TableCell>
                      <TableCell align="left">{row[1]}</TableCell>
                      <TableCell align="left">{row[2]}</TableCell>
                      <TableCell align="left">{row[3]}</TableCell>
                    </TableRow>
                  ))}
                </TableBody>
              </Table>
            </TableContainer>
          ) : (
            <Typography>
              <DescriptionIcon />
              &nbsp;&nbsp;
              {t("orderPresPage.NoOrPresAvail", {
                ns: ["home"],
              })}
            </Typography>
          )}
        </CardContent>
      </Card>
    </Box>
  );
  // Orders API Submit Body.....
  const prescriptionBody = {
    patientId: patientid,
    caseId: caseid,
    secondOpinionUiSection: "ordersAndPrescriptions",
    description: prescriptionDescription,
    patientDocumentId: imageId,
    startDate: prescriptionStartDate,
    endDate: prescriptionEndDate,
  };
  // Prescription & Order Table
  const docpataintPrescriptionSubmit = () => {
    if (prescriptionDescription === "") {
      setPrescriptionDescriptionError(`${t("preCompPage.plsAddDesc", { ns: ["home"] })!}`);
    } else {
      dispatch(addChiefComplaint(prescriptionBody));
      setPrescriptionModalActive((prescriptionModalActive) => !prescriptionModalActive);
      dispatch(getChiefComplaintList(getprescriptionBody));
    }
  };
  const addprescriptionDetails = (
    <Dialog
      open={prescriptionModalActive}
      onClose={handlePrescriptionModalToggle}
      fullScreen={fullScreen}
    >
      <DialogTitle>
        <Grid container direction="row" justifyContent="space-between" alignItems="flex-start">
          <Grid item>
            <Typography variant="h6">{`${t("orderPresPage.addDoc", { ns: ["home"] })}`}</Typography>
          </Grid>
          <Grid item>
            <IconButton
              aria-label="close"
              onClick={handlePrescriptionModalToggle}
              sx={{
                position: "absolute",
                right: 8,
                top: 8,
                color: (theme) => theme.palette.grey[500],
              }}
            >
              <CloseIcon />
            </IconButton>
          </Grid>
        </Grid>
      </DialogTitle>

      <DialogContent>
        <Grid
          container
          direction="row"
          justifyContent="flex-start"
          alignItems="flex-start"
          spacing={2}
        >
          <Grid item xs={6} sm={6} md={6} lg={6}>
            <Box pt={1}>
              <Typography>{t("appointment.desc", { ns: ["home"] })}</Typography>
              <MWTextField
                label={t("orderPresPage.presDesc", { ns: ["home"] })}
                required
                placeholder={t("orderPresPage.enterPresDesc", { ns: ["home"] })!}
                value={prescriptionDescription}
                onChange={(e) => handlePrescriptionDescriptionChange(e.target.value)}
                multiline
                maxRows={4}
                id="prescriptionDescriptionID"
                fullWidth
              />
              <Typography id="prescriptionDescriptionID">
                {prescriptionDescriptionErrordiv}
              </Typography>
            </Box>

            <Box>
              <p>{t("common.stDate", { ns: ["home"] })}</p>
              <LocalizationProvider dateAdapter={AdapterDayjs}>
                <DesktopDatePicker
                  inputFormat="DD/MM/YYYY"
                  value={prescriptionStartDate}
                  onChange={(date) => handlePrescriptionStartChange(date)}
                  renderInput={(params: any) => (
                    <TextField
                      {...params}
                      fullWidth
                      inputProps={{ ...params.inputProps, readOnly: true }}
                      size=""
                    />
                  )}
                  InputAdornmentProps={{ position: "start" }}
                />
              </LocalizationProvider>
            </Box>

            <Box>
              <p>{t("appointment.enddate", { ns: ["home"] })}</p>
              <LocalizationProvider dateAdapter={AdapterDayjs}>
                <DesktopDatePicker
                  inputFormat="DD/MM/YYYY"
                  value={prescriptionEndDate}
                  onChange={(date) => handlePrescriptionEndDateChange(date)}
                  renderInput={(params: any) => (
                    <TextField
                      {...params}
                      fullWidth
                      inputProps={{ ...params.inputProps, readOnly: true }}
                      size=""
                    />
                  )}
                  InputAdornmentProps={{ position: "start" }}
                />
              </LocalizationProvider>
            </Box>
          </Grid>
          <Grid item xs={6} sm={6} md={6} lg={6}>
            <Box>
              <Typography style={{ marginBottom: "5px" }}>
                {t("preCompPage.suptDocu", { ns: ["home"] })}
              </Typography>
              <Box
                style={{
                  textAlign: "center",
                  border: "1px #6574c4 solid",
                  borderRadius: "3px",
                }}
                p={1}
              >
                <MWUploader
                  documentType="patient-image"
                  criteria="Upload a file less than 50 mb."
                  updatedDataState={updateDataStateFromChild}
                  visibility={false}
                  image={imageInfo}
                  deleteImgAction={delImgAction}
                  previewImageData={viewPreviewImageData}
                />
              </Box>
              <Typography style={{ color: "gray" }}>
                {t("preCompPage.profSize", { ns: ["home"] })!}
              </Typography>
            </Box>
          </Grid>
        </Grid>

        <Box>{prescriptionSuccessDiv}</Box>
        <Box>{prescriptionErrorDiv}</Box>
      </DialogContent>

      <DialogActions>
        <Button onClick={() => docpataintPrescriptionSubmit()} variant="contained">
          {t("needhelppage.submit", { ns: ["home"] })}
        </Button>
      </DialogActions>
    </Dialog>
  );

  // Prescription Document View
  const prescriptionDocViewerComponent = (
    <Dialog open={docViwerActive} fullWidth>
      <DialogTitle>
        <Grid container direction="row" justifyContent="flex-end" alignItems="flex-start">
          <Grid item>
            <IconButton onClick={handleDocViewerChange}>
              <CloseIcon />
            </IconButton>
          </Grid>
        </Grid>
      </DialogTitle>
      <DialogContent>
        <Typography>
          <iframe src={imageInfo} title="Small document" width="100%" height="700" frameBorder="0">
            This is an embedded{" "}
            <a target="_blank" href="http://office.com" rel="noopener noreferrer">
              Microsoft Office
            </a>{" "}
            document, powered by{" "}
            <a target="_blank" href="http://office.com/webapps" rel="noopener noreferrer">
              Office Online
            </a>
            .
          </iframe>
        </Typography>
      </DialogContent>
    </Dialog>
  );

  // Delete Prescription Modal section ....
  const deletePrescriptionBody = (
    <Dialog
      open={deletePrescriptionModalActive}
      onClose={handleDeletePrescriptionModalChange}
      fullScreen={fullScreen}
    >
      <DialogTitle>
        <Grid container direction="row" justifyContent="space-between" alignItems="flex-start">
          <Grid item>
            <Typography variant="h6">{t("orderPresPage.delSecOp", { ns: ["home"] })}</Typography>
          </Grid>
          <Grid item>
            <IconButton
              aria-label="close"
              onClick={handlePrescriptionModalToggle}
              sx={{
                position: "absolute",
                right: 8,
                top: 8,
                color: (theme) => theme.palette.grey[500],
              }}
            >
              <CloseIcon />
            </IconButton>
          </Grid>
        </Grid>
      </DialogTitle>

      <DialogContent>
        <Typography variant="subtitle1">
          {t("orderPresPage.qSecOpDetail", { ns: ["home"] })}
        </Typography>
      </DialogContent>
      <DialogActions>
        <Button onClick={DeletePrescriptionSubmit} variant="outlined">
          {t("common.yes", { ns: ["home"] })}
        </Button>
        <Button onClick={handleDeletePrescriptionModalChange} variant="contained">
          {t("common.no", { ns: ["home"] })}
        </Button>
      </DialogActions>
    </Dialog>
  );
  return (
    <Box>
      {prescriptionTable}
      {addprescriptionDetails}
      {prescriptionDocViewerComponent}
      {deletePrescriptionBody}
    </Box>
  );
}
