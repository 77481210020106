import React, { useEffect, useState } from "react";
import { useHistory, useParams } from "react-router-dom";
import {
  Box,
  Button,
  Stepper,
  Step,
  StepButton,
  StepConnector,
  StepLabel,
  Grid,
  Card,
  Stack,
  IconButton,
  CardContent,
  CardActions,
} from "@mui/material";
import { StepIconProps } from "@mui/material/StepIcon";
import SettingsIcon from "@mui/icons-material/Settings";
import GroupAddIcon from "@mui/icons-material/GroupAdd";
import GroupIcon from "@mui/icons-material/Group";
import FileCopyIcon from "@mui/icons-material/FileCopy";
import PaymentIcon from "@mui/icons-material/Payment";
import PostAddIcon from "@mui/icons-material/PostAdd";
import ContactPhoneIcon from "@mui/icons-material/ContactPhone";
import EmojiPeopleIcon from "@mui/icons-material/EmojiPeople";
import CallIcon from "@mui/icons-material/Call";
import SettingsOutlinedIcon from "@mui/icons-material/SettingsOutlined";
import { Theme } from "@mui/material/styles";
import { makeStyles } from "tss-react/mui";
import { withStyles } from "tss-react/mui";
import MWPageTitle from "../components/MWPageTitle";
import PageLayout from "../components/PageLayout";
// import AdminLoader from "../AdminLoader";
import AppointmentSelectPatient from "./AppointmentSelectPatient";
import ConsultationOption from "./ConsultationOption";
// import FilePreparation from "./FilePreparation";
import AppointmentSelectDoctor from "./AppointmentSelectDoctor";
import AppointmentPayment from "./AppointmentPayment";
import AddPatientTeam from "./AddPatientTeam";
// import DoctorPatientInteraction from "./DoctorPatientInteraction";
import FinalOpinion from "./FinalOpinion";
import * as _ from "lodash";
import axios from "axios";
import Url from "../Url";
import { setupInterceptorsTo } from "../redux/Authentication/Interceptors";
import { useDispatch, useSelector } from "react-redux";
import { AppState } from "../redux/store/Store";
import { getPatientCaseList } from "../redux/effects/ApiCall";
import { CaseList } from "../redux/interfaces/Model";
// import { getSteps } from "../redux/Patient/FilePreparation/ApiCall";
// import { sectionandstepList } from "../../redux/StepsAndSection/API"; // This store is working but it is not using because the ui is loading before the api gets success so the steps is not updated
// import { SecondOpinionUi } from "../../redux/StepsAndSection/Model";
// import DeleteCaseDialog from "./DeleteCaseDialog";
// import RequestAppointment from "./RequestAppointment";
import AddhealthRecord from "./FilePreparation/AddHealthRecord";
import MWLoader from "../components/MWLoader";
import AppointmentBooking from "./AppointmentBooking";
import DoctorPatientInteraction from "./DoctorPatientInteraction/DoctorPatientInteraction";

declare module "@mui/material" {
  // eslint-disable-next-line @typescript-eslint/no-empty-interface
  interface DefaultTheme extends Theme {}
}

const ColorlibConnector = withStyles(StepConnector, (_theme, _params, classes) => ({
  alternativeLabel: {
    top: 22,
  },
  active: {
    [`& .${classes.line}`]: {
      backgroundImage:
        "linear-gradient( 95deg,rgb(101, 116, 196) 0%,rgb(121,142,207) 50%,rgb(147,172,250) 100%)",
    },
  },
  completed: {
    [`& .${classes.line}`]: {
      backgroundImage:
        "linear-gradient( 95deg,rgb(101, 116, 196) 0%,rgb(121,142,207) 50%,rgb(147,172,250) 100%)",
    },
  },
  line: {
    height: 3,
    border: 0,
    backgroundColor: "#eaeaf0",
    borderRadius: 1,
  },
}));

const useColorlibStepIconStyles = makeStyles()({
  root: {
    backgroundColor: "#ccc",
    zIndex: 1,
    color: "#fff",
    width: 50,
    height: 50,
    display: "flex",
    borderRadius: "50%",
    justifyContent: "center",
    alignItems: "center",
  },
  active: {
    backgroundImage: "linear-gradient( 136deg,rgb(101, 116, 196) 0%,rgb(147,172,250) 100%)",
    boxShadow: "0 4px 10px 0 rgba(0,0,0,.25)",
  },
  completed: {
    backgroundImage: "linear-gradient( 136deg,rgb(101, 116, 196) 60%,rgb(121,142,207) 100%)",
  },
});

function ColorlibStepIcon(props: StepIconProps) {
  const { classes, cx } = useColorlibStepIconStyles();
  const { active, completed } = props;
  const icons: { [index: string]: React.ReactElement } = {
    1: <SettingsIcon />,
    2: <GroupIcon />,
    3: <ContactPhoneIcon />,
    4: <FileCopyIcon />,
    5: <GroupAddIcon />,
    6: <PostAddIcon />,
    7: <PaymentIcon />,
    8: <EmojiPeopleIcon />,
    9: <CallIcon />,
  };
  return (
    <div
      className={cx(classes.root, {
        [classes.active]: active,
        [classes.completed]: completed,
      })}
    >
      {icons[String(props.icon)]}
    </div>
  );
}

const useStyles = makeStyles()((theme) => {
  return {
    root: {
      width: "100%",
      //backgroundColor: '#ccc',
    },
    button: {
      marginRight: theme.spacing(1),
    },
    instructions: {
      marginTop: theme.spacing(1),
      marginBottom: theme.spacing(1),
    },
  };
});

export default function CaseDetails() {
  const userToken = localStorage.getItem("access");
  const { patientid } = useParams() as {
    patientid: string;
  };
  const { caseid } = useParams() as {
    caseid: string;
  };
  const { paymentRedirect } = useParams() as {
    paymentRedirect: string;
  };
  const paymentTabConfirm = localStorage.getItem("paymentRedirect");
  const dispatch = useDispatch();

  const [isLoading, setIsLoading] = useState(false);
  const { classes } = useStyles();
  const [activeStep, setActiveStep] = useState(0);
  const [steps, setSteps] = useState<any[]>([]);
  const [stepDetails, setStepDetails] = useState<any[]>([]);

  const handleNext = () => {
    if (localStorage.getItem("consultationopt") === "Quick Video Consultation") {
      setActiveStep(3);
      localStorage.setItem("consultationopt", "");
    } else if (activeStep === steps.length - 1) {
      window.scrollTo(0, 0);
      history.goBack();
    } else {
      setActiveStep((prevActiveStep) => prevActiveStep + 1);
    }
  };
  // const [deleteDialog, setDeleteDialogOpen] = useState(false);
  // const handleClick = () => {
  //   setDeleteDialogOpen(true);
  // };
  // const updateDeleteCaseDialogValFromChild = (passedVal: boolean) => {
  //   setDeleteDialogOpen(passedVal);
  // };

  const handleBack = () => {
    setActiveStep((prevActiveStep) => prevActiveStep - 1);
  };

  const handleStep = (step: number) => () => {
    setActiveStep(step);
  };
  const [completed] = React.useState(new Set<number>());
  function isStepComplete(step: number) {
    return completed.has(step);
  }

  const PatientListFromAPI = useSelector((state: AppState) => state.caseValue);
  const caseDetails: CaseList = _.filter(PatientListFromAPI.caseValue, [
    "caseId",
    Number(caseid),
  ])[0];

  const casehistoryname = useSelector((state: AppState) => state.caseValue.patientname.patientName);

  const [appointmentPatientTeamLoader, setAppointmentPatientTeamLoader] = useState(false);
  const [appointmentSelectPatientLoader] = useState(false);
  // Get Loading value from Select Patient step section
  // const appointmentSelectPatientLoaderUpdatedState = (passedVal: boolean) => {
  //   setAppointmentSelectPatientLoader(passedVal);
  // };
  const [consultationLoader] = useState(false);
  const [filePreparationLoader] = useState(false);
  // Get Loading value from File Preparation step section
  // const filePreparationLoaderUpdatedState = (passedVal: boolean) => {
  //   setFilePreparationLoader(passedVal);
  // };

  const [
    appointmentDoctorPatientInteractionLoader,
    // setAppointmentDoctorPatientInteractionLoader,
  ] = useState(false);
  const [appointmentRequestLoader] = useState(false);
  // Get Loading value from Appointment Request step section
  // const appointmentRequestLoaderUpdatedState = (passedVal: boolean) => {
  //   setAppointmentRequestLoader(passedVal);
  // };
  const [appointmentPaymentLoader] = useState(false);
  // Get Loading value from Appointment Payment step section
  // const appointmentPaymentLoaderUpdatedState = (passedVal: boolean) => {
  //   setAppointmentPaymentLoader(passedVal);
  // };
  const [appointmentFinalOpinionLoader, setAppointmentFinalOpinionLoader] = useState(false);
  const [doctorId] = useState(false);
  const loadingMarkup = <MWLoader />;

  const getStepsAPI = () => {
    setIsLoading(true);
    setupInterceptorsTo(axios)
      .get(Url.apiURL + "/secondopinionapp/api/patientcaseui/steps_and_sections", {
        headers: {
          Accept: "application/json",
          "Content-type": "application/json",
          Authorization: `Bearer ${userToken}`,
        },
      })
      .then((res) => {
        setIsLoading(false);
        console.log(isLoading);
        setStepDetails(res.data.SecondOpinionUi);
        setSteps(
          res.data.SecondOpinionUi.map(function (el: { displayValue: any }) {
            return el.displayValue;
          }),
        );
        if (paymentRedirect === "1" && paymentTabConfirm === "1") {
          console.log("Get Value Condition True");
          setActiveStep(5);
        } else if (paymentRedirect === "2" && paymentTabConfirm === "2") {
          console.log("Get Value Condition True");
          setActiveStep(3);
        } else if (paymentTabConfirm === "0" && paymentTabConfirm === "0") {
          console.log("Condition True");
          setActiveStep(1);
        } else if (patientid) {
          setActiveStep(1);
        } else if (
          localStorage.getItem("opiniontype") === String(2) &&
          localStorage.getItem("responseTime") ===
            "Speedy Consult (First response by a doctor in 8 working hours)"
        ) {
          setActiveStep(2);
          localStorage.removeItem("paymentRedirect");
          localStorage.setItem("opiniontype", "");
          setActiveStep((prevActiveStep) => prevActiveStep + 1);
        }
      })
      .catch((error) => {
        setIsLoading(false);
        // Error 😨
        if (error.response) {
          /*
           * The request was made and the server responded with a
           * status code that falls out of the range of 2xx
           */
          console.log(error.response.data);
          console.log(error.response.status);
          console.log(error.response.headers);
          if (error.response.status === 401) {
            localStorage.removeItem("access");
          }
        } else if (error.request) {
          /*
           * The request was made but no response was received, `error.request`
           * is an instance of XMLHttpRequest in the browser and an instance
           * of http.ClientRequest in Node.js
           */
          console.log(error.request);
        } else {
          // Something happened in setting up the request and triggered an Error
          console.log("Error", error.message);
        }
        console.log(error.config);
      });
  };

  const getStepContent = (stepper: number) => {
    switch (stepDetails[stepper].name) {
      case "selectPatient":
        return <AppointmentSelectPatient />;
      case "consultationType":
        return <ConsultationOption />;
      case "filePreparation":
        return <AddhealthRecord />;
      case "selectDoctor":
        return <AppointmentSelectDoctor />;
      case "bookAppointments":
        return <AppointmentBooking />;
      case "payment":
        return <AppointmentPayment />;
      case "patientTeam":
        return <AddPatientTeam appointmentPatientTeamLoader={setAppointmentPatientTeamLoader} />;
      case "doctorpatientinteraction":
        return <DoctorPatientInteraction />;
      case "secondopinion":
        return <FinalOpinion appointmentFinalOpinionLoader={setAppointmentFinalOpinionLoader} />;
      default:
        return "Unknown step";
    }
  };
  const history = useHistory();

  // const StepResponse = useSelector((state: AppState) => state.stepAndSection);

  const pageBody = (
    <Card>
      <CardContent>
        <Stack direction="column" justifyContent="flex-start" alignItems="stretch" spacing={2}>
          <div className={classes.root}>
            <Stepper
              alternativeLabel
              nonLinear
              activeStep={activeStep}
              connector={<ColorlibConnector />}
              style={{ backgroundColor: "transparent" }}
            >
              {steps?.map((label, index) => {
                const stepProps: { completed?: boolean } = {};
                return (
                  <Step key={label} {...stepProps} completed={isStepComplete(index)}>
                    <StepButton onClick={handleStep(index)}>
                      <StepLabel StepIconComponent={ColorlibStepIcon}>{label}</StepLabel>
                    </StepButton>
                  </Step>
                );
              })}
            </Stepper>
          </div>
          {activeStep === steps.length ? null : <Box>{getStepContent(activeStep)}</Box>}
        </Stack>
      </CardContent>
      <CardActions>
        {activeStep === steps.length ? null : (
          <Grid
            container
            direction="row"
            justifyContent="flex-end"
            alignItems="flex-start"
            spacing={1}
          >
            <Grid item>
              <Button
                variant="outlined"
                disabled={activeStep === 0}
                onClick={handleBack}
                sx={{ textTransform: "none" }}
              >
                Back
              </Button>
            </Grid>
            <Grid item>
              <Button
                variant="contained"
                color="primary"
                onClick={handleNext}
                sx={{ textTransform: "none" }}
              >
                {activeStep === steps.length - 1 ? "Close" : "Next"}
              </Button>
            </Grid>
          </Grid>
        )}
      </CardActions>
    </Card>
  );

  // Call the following on page rendering
  useEffect(() => {
    window.scrollTo(0, 0);
    getStepsAPI();
    dispatch(getPatientCaseList(patientid));
    // dispatch(sectionandstepList()); // This store is working but it is not using because the ui is loading before the api gets success so the steps is not updated
    // dispatch(sectionandstepList());
    if (patientid) {
      setActiveStep(0);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <Box>
      {doctorId === true ||
      appointmentSelectPatientLoader ||
      consultationLoader === true ||
      appointmentPaymentLoader === true ||
      appointmentPatientTeamLoader === true ||
      filePreparationLoader ||
      appointmentDoctorPatientInteractionLoader === true ||
      appointmentFinalOpinionLoader === true ||
      appointmentRequestLoader ||
      isLoading
        ? loadingMarkup
        : null}
      <PageLayout>
        <Grid container direction="row" justifyContent="space-between" alignItems="center">
          <Grid item>
            <MWPageTitle
              backButton={true}
              title={`${
                caseDetails === undefined
                  ? `${casehistoryname === undefined ? "" : casehistoryname + "'s Details"}`
                  : `${casehistoryname === undefined ? "" : casehistoryname + "'s Details"}`
              }`}
              enableSubtitle={true}
              subtitle={`${caseDetails === undefined ? "" : caseDetails.whyWant2ndOpinion}`}
            />
          </Grid>
          <Grid item>
            <Grid
              container
              direction="row"
              justifyContent="flex-end"
              alignItems="center"
              spacing={2}
            >
              <Grid item>
                <IconButton>
                  <SettingsOutlinedIcon sx={{ color: "#8a877f" }} />
                </IconButton>
              </Grid>
            </Grid>
          </Grid>
        </Grid>
        {pageBody}
      </PageLayout>
      {/* <Box>
        {deleteDialog ? (
          <DeleteCaseDialog
            deleteCaseDialogEnable={deleteDialog}
            deleteCaseDialogUpdateState={updateDeleteCaseDialogValFromChild}
          />
        ) : null}
      </Box> */}
    </Box>
  );
}
