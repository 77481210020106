import React, { FC, ReactNode } from "react";
import PropTypes from "prop-types";
import { Box, Grid, Link, Typography } from "@mui/material";
import LoginScreen from "../../image/design-assets/featureimage-patient.png";
import logo from "../../image/logo/medicalWISDOM.png";
import MWSelectLang, { MobileSelectLang } from "../../components/MWSelectLang";
import ApiUrl from "../../Url";
interface OnboardingLayoutProps {
  children?: ReactNode;
}
const OnboardingLayout: FC<OnboardingLayoutProps> = ({ children }) => {
  return (
    <Grid container item direction="row" justifyContent="space-between" sx={{ height: "100vh" }}>
      <Grid container direction="row" justifyContent="space-between" alignItems="flex-start">
        <Link underline="none" href={ApiUrl?.baseURL}>
          <Box
            sx={{
              display: {
                xs: "flex",
                sm: "flex",
                md: "none",
                lg: "none",
                xl: "none",
              },
            }}
          >
            <img src={logo} alt="Logo" height="72" />
          </Box>
        </Link>
        <Box
          sx={{
            display: {
              xs: "none",
              sm: "flex",
              md: "flex",
              lg: "flex",
              xl: "flex",
            },
          }}
        >
          <MWSelectLang />
        </Box>
        <Box
          sx={{
            mt: 2,
            display: {
              xs: "flex",
              sm: "flex",
              md: "none",
              lg: "none",
              xl: "none",
            },
          }}
        >
          <MobileSelectLang />
        </Box>
      </Grid>
      <Grid xs={12} container item>
        <Grid xs={12} md={6} container item alignItems="center" justifyContent="center">
          <Grid container item alignItems="center" justifyContent="center">
            <Grid container item xs={11} sm={7} lg={7}>
              <Link underline="none" href={ApiUrl?.baseURL}>
                <Box
                  sx={{
                    display: {
                      xs: "none",
                      sm: "flex",
                      md: "flex",
                      lg: "flex",
                      xl: "flex",
                    },
                  }}
                >
                  <img src={logo} alt="Logo" height="80" />
                </Box>
              </Link>
              <Box my={3}>
                <Typography color="textPrimary" variant="h5" display="inline">
                  <b>Welcome to medicalWISDOM</b>
                </Typography>
                <Typography variant="subtitle2">
                  One stop solution for Health Consultation
                </Typography>
              </Box>
              {children}
            </Grid>
          </Grid>
        </Grid>
        <Grid
          md={6}
          container
          item
          alignItems="center"
          justifyContent="center"
          sx={{
            display: { xs: "none", md: "block" },
          }}
        >
          <Box>
            <img
              src={LoginScreen}
              alt=""
              width="100%"
              height="100%"
              style={{
                objectFit: "cover",
                objectPosition: "center",
              }}
            />
          </Box>
        </Grid>
      </Grid>
      <Grid container item direction="column" justifyContent="center" xs={12}>
        <Grid container item direction="row" justifyContent="space-between" alignItems="flex-start">
          <Typography variant="caption">
            © Copyright medicalWISDOM 2024. All rights reserved.
          </Typography>
          <Typography variant="caption">
            <Link
              underline="none"
              href={`${ApiUrl?.baseURL}/termsofuse/`}
              target="_blank"
              rel="noreferrer"
            >
              Terms of Use
            </Link>
            &nbsp;&nbsp;
            <Link
              underline="none"
              href={`${ApiUrl?.baseURL}/privacystatement/`}
              target="_blank"
              rel="noreferrer"
            >
              Privacy & Cookies
            </Link>
          </Typography>
        </Grid>
      </Grid>
    </Grid>
  );
};

OnboardingLayout.propTypes = {
  children: PropTypes.node,
};

export default OnboardingLayout;
