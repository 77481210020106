import * as React from "react";
import { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { AppState } from "../../../redux/store/Store";
import { useParams } from "react-router-dom";
import {
  Box,
  Grid,
  Container,
  Stack,
  Card,
  CardHeader,
  CardContent,
} from "@mui/material";
import dayjs from "dayjs";
import { DataGrid, GridColDef, GridToolbar } from "@mui/x-data-grid";
import { getPatientCaseList } from "../../../redux/effects/ApiCall";
import { ObservationList } from "../../../redux/GetViewExtractObservation/Model";
import MWPageTitle from "../../../components/MWPageTitle";
import MWExceptionList from "../../../components/MWExceptionList";
import { NameGenerate } from "../../../components/Utility";

type Props = {
  getExtractObservationDetailsValue?: any;
};

export default function ViewObservation({
  getExtractObservationDetailsValue,
}: Props) {
  const dispatch = useDispatch();
  const { patientid } = useParams() as {
    patientid: string;
  };
  const [getExtractObservationValue, setGetExtractObservationValue] =
    React.useState([] as any[]);
  React.useEffect(() => {
    setGetExtractObservationValue(getExtractObservationDetailsValue);
  }, [getExtractObservationDetailsValue]);
  const caseHistoryPtName = useSelector(
    (state: AppState) => state.caseValue.patientname.patientName
  );
  const [patientName, setPatientName] = React.useState("");
  const [pageTitle, setPageTitle] = React.useState("");
  const [listCount, setListCount] = React.useState("");

  useEffect(() => {
    patientName !== ""
      ? setPageTitle(
          `View Observation List of ${NameGenerate.changeName(
            patientid,
            caseHistoryPtName
          )}`
        )
      : setPageTitle("");
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [patientName]);

  useEffect(() => {
    if (caseHistoryPtName === undefined) {
      dispatch(getPatientCaseList(patientid));
    }
    if (caseHistoryPtName !== undefined) {
      setPatientName(caseHistoryPtName);
    } else setPatientName("");
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [caseHistoryPtName]);

  const [dataGridArray, setDataArray] = React.useState([] as any[]);
  useEffect(() => {
    getExtractObservationValue !== undefined
      ? setListCount(String(getExtractObservationValue?.length))
      : setListCount("0");

    if (getExtractObservationValue !== undefined) {
      let dataPointArr = getExtractObservationValue.map(
        (element: ObservationList) => ({
          id: element.id,
          value: element.value,
          startDate: dayjs(element.startDate).format("dddd, MMMM D, YYYY"),
          displayValue: element.patientObservationType.displayValue,
          modifiedDate: dayjs(element.modifiedDate).format(
            "dddd, MMMM D, YYYY"
          ),
        })
      );
      setDataArray(dataPointArr);
    } else {
      setDataArray([] as any[]);
    }
  }, [getExtractObservationValue]);

  const columns: GridColDef[] = [
    {
      field: "displayValue",
      headerName: "Observation Name",
      type: "string",
      width: 230,
      editable: true,
      sortable: false,
      align: "left",
      disableColumnMenu: true,
      flex: 1,
    },
    {
      field: "value",
      headerName: "Observation Value",
      type: "string",
      editable: true,
      sortable: false,
      disableColumnMenu: true,
      align: "left",
      flex: 1,
    },
    {
      field: "startDate",
      headerName: "Start Date",
      type: "string",
      editable: true,
      sortable: false,
      disableColumnMenu: true,
      align: "left",
      flex: 1,
    },
    {
      field: "modifiedDate",
      headerName: "Modified Date",
      type: "string",
      editable: true,
      sortable: false,
      disableColumnMenu: true,
      align: "left",
      flex: 1,
    },
  ];
  const patientPointDataTable = (
    <Card>
      <CardHeader
        title={
          <Grid
            container
            direction="row"
            justifyContent="flex-start"
            alignItems="flex-start"
            spacing={2}
            pt={2}
          >
            <Grid item>
              <MWPageTitle
                title={pageTitle}
                enableCount={true}
                count={listCount}
              />
            </Grid>
          </Grid>
        }
      />
      <CardContent>
        {dataGridArray && dataGridArray.length ? (
          <DataGrid
            rows={dataGridArray}
            columns={columns}
            hideFooter
            hideFooterPagination
            disableSelectionOnClick
            experimentalFeatures={{ newEditingApi: true }}
            autoHeight
            components={{ Toolbar: GridToolbar }}
            componentsProps={{
              toolbar: {
                csvOptions: { disableToolbarButton: true },
                printOptions: { disableToolbarButton: true },
                showQuickFilter: true,
                quickFilterProps: { debounceMs: 250 },
              },
            }}
            disableColumnFilter
            disableColumnSelector
            disableDensitySelector
          />
        ) : (
          <MWExceptionList />
        )}
      </CardContent>
    </Card>
  );
  return (
    <Box>
      <Container maxWidth="xl">
        <Stack
          direction="column"
          justifyContent="flex-start"
          alignItems="stretch"
          spacing={2}
          pt={2}
        >
          <Grid
            container
            direction="row"
            justifyContent="space-between"
            alignItems="flex-start"
          ></Grid>
          {patientPointDataTable}
        </Stack>
      </Container>
    </Box>
  );
}
