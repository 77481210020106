import axios from "axios";
import { Dispatch } from "redux";
import Url from "../../Url";
import { setupInterceptorsTo } from "../Authentication/Interceptors";
import {
  getInsuranceDetailsByPatientActionTypes,
  GET_INSURANCE_DETAILS_BY_PATIENT_FAIL,
  GET_INSURANCE_DETAILS_BY_PATIENT_LOADING,
  GET_INSURANCE_DETAILS_BY_PATIENT_SUCCESS,
} from "./ActionTypes";
import { PatientId, GetInsuranceDetalsByPatientValue } from "./Model";
import { headers } from "components/Utility";

export const getInsuranceDetailsByPatientApi = (id: PatientId) => {
  return function (dispatch: Dispatch<getInsuranceDetailsByPatientActionTypes>) {
    dispatch({
      type: GET_INSURANCE_DETAILS_BY_PATIENT_LOADING,
    });

    setupInterceptorsTo(axios)
      .post(
        Url.apiURL + `/patientapp/api/patient/get_insurance_details_by_patient`,
        { patientId: id.patientId },
        { headers: headers },
      )
      .then((res) => {
        dispatch({
          type: GET_INSURANCE_DETAILS_BY_PATIENT_SUCCESS,
          payload: res.data,
          errRes: "",
        });
      })
      .catch((error) => {
        dispatch({
          type: GET_INSURANCE_DETAILS_BY_PATIENT_FAIL,
          payload: {} as GetInsuranceDetalsByPatientValue,
          errRes: error.response.data.message,
        });
      });
  };
};
