import * as React from "react";
import { useEffect, useState } from "react";
import { getPatientCaseList } from "../../redux/effects/ApiCall";
import { AppState } from "../../redux/store/Store";
import { useHistory, useParams } from "react-router-dom";
import {
  Box,
  Button,
  Container,
  FormControlLabel,
  Grid,
  Stack,
  Switch,
  Tab,
  styled,
} from "@mui/material";
import { TabContext, TabList, TabPanel } from "@mui/lab";
import CaseList from "../PatientDashboard/CaseList/Index";
import CarePlan from "../PatientDashboard/CarePlan/Index";
import MedicationSummary from "../PatientDashboard/MedicationSummary/Index";
import HealthCondition from "../PatientDashboard/HealthCondition/Index";
import { getpatientDetails } from "../../redux/Patient/DemographicsDetails/ApiCall";
import { PatientDemographicsReq } from "../../redux/Patient/DemographicsDetails/Model";
import { useDispatch, useSelector } from "react-redux";
import PatientDataDetails from "../PatientDataDetails";
import CarePlanList from "../PatientDashboard/CarePlan/CustomCardDataList";
import {
  LatestDataListBody,
  PatientObservationsList,
} from "../../redux/patientDashboardLatestDataList/Model";
import { getPatientDashBoardLatestDataList } from "../../redux/patientDashboardLatestDataList/ApiCall";
import RefreshIcon from "@mui/icons-material/Refresh";
import MWLoader from "../../components/MWLoader";
import { NameGenerate } from "../../components/Utility";
import Demographics from "../PatientDashboard/Demographics/Index";
import CustomCard from "../../components/CardComponent";
import DataObservationList from "../DataObservationList";
import MWPageTitle from "../../components/MWPageTitle";
import { PatientVitalDetails } from "../PatientDashboard/CarePlan/Model";
import DefaultVital from "../../image/design-assets/medicalWISDOM-round-default.svg";
import PatientEncounterList from "pages/PatientEncounterList";
import SleepSummary from "pages/PatientDashboard/SleepSummary";

export interface MenuItemType {
  id: string | number;
  name: string;
  content: string;
  button: string;
  path: string;
  info: string;
  image: string;
}

const NameChangeSwitch = styled(Switch)(({ theme }) => ({
  padding: 8,
  "& .MuiSwitch-track": {
    borderRadius: 22 / 2,
    "&:before, &:after": {
      content: '""',
      position: "absolute",
      top: "50%",
      transform: "translateY(-50%)",
      width: 16,
      height: 16,
    },
    "&:before": {
      backgroundImage: `url('data:image/svg+xml;utf8,<svg xmlns="http://www.w3.org/2000/svg" height="16" width="16" viewBox="0 0 24 24"><path fill="${encodeURIComponent(
        theme.palette.getContrastText(theme.palette.primary.main),
      )}" d="M21,7L9,19L3.5,13.5L4.91,12.09L9,16.17L19.59,5.59L21,7Z"/></svg>')`,
      left: 12,
    },
    "&:after": {
      backgroundImage: `url('data:image/svg+xml;utf8,<svg xmlns="http://www.w3.org/2000/svg" height="16" width="16" viewBox="0 0 24 24"><path fill="${encodeURIComponent(
        theme.palette.getContrastText(theme.palette.primary.main),
      )}" d="M19,13H5V11H19V13Z" /></svg>')`,
      right: 12,
    },
  },
  "& .MuiSwitch-thumb": {
    boxShadow: "none",
    width: 16,
    height: 16,
    margin: 2,
  },
}));

export default function PatientDetails() {
  const menuItems = CarePlanList;

  const history = useHistory();
  const loadingMarkup = <MWLoader />;
  const dispatch = useDispatch();

  const { patientid } = useParams() as {
    patientid: string;
  };

  const handleViewNotesToggle = () => {
    history.push(`/patientnotes/${patientid}`);
  };

  const viewdemographic = () => {
    history.push(`/demographicdetails/${patientid}`);
  };

  const handleAddEncounterToggle = () => {
    history.push(`/casedetails/${patientid}`);
  };
  const handleViewPtDataPoint = () => {
    history.push(`/patientdataobservationlist/${patientid}`);
  };

  // Get Patient Demographics Details Body
  const getPatientDemographicsDetailsBody = {
    healthRecordId: patientid,
  } as PatientDemographicsReq;

  const patientDetailsValue = useSelector((state: AppState) => state.gethealthRecordvalue);

  const [toggleState, setToggleState] = useState(false);
  const handleNameChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    if (
      patientDetailsValue?.personalDetails?.firstname !== undefined &&
      patientDetailsValue?.personalDetails?.lastname !== undefined
    ) {
      localStorage.setItem("togglename", String(event.target.checked));
      setToggleState(event.target.checked);
      NameGenerate.changeName(
        String(patientDetailsValue?.personalDetails?.id),
        patientDetailsValue?.personalDetails?.firstname +
          " " +
          patientDetailsValue?.personalDetails?.lastname,
      );
    }
  };

  const [selected, setSelected] = React.useState("1");
  const handleTabChange = (_event: React.SyntheticEvent, newValue: string) => {
    setSelected(newValue);
    localStorage.setItem("selctedOnboardingtab", String(newValue));
  };

  useEffect(() => {
    dispatch(getPatientCaseList(patientid));
    dispatch(getpatientDetails(getPatientDemographicsDetailsBody));
    dispatch(
      getPatientDashBoardLatestDataList({
        patientId: patientid,
      } as LatestDataListBody),
    );
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [dispatch]);

  useEffect(() => {
    if (
      patientDetailsValue?.personalDetails?.firstname !== undefined &&
      patientDetailsValue?.personalDetails?.lastname !== undefined
    ) {
      if ("togglename" in localStorage) {
        NameGenerate.changeName(
          String(patientDetailsValue?.personalDetails?.id),
          patientDetailsValue?.personalDetails?.firstname +
            " " +
            patientDetailsValue?.personalDetails?.lastname,
        );
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [toggleState, patientDetailsValue]);

  useEffect(() => {
    if ("togglename" in localStorage) {
      setToggleState(localStorage.getItem("togglename") === "true");
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  // PatientDashboardLatestDataListstore value..

  const getPatientDashboardLatestDataListResponse = useSelector(
    (state: AppState) => state.getPatientDashboardLatestDataListRes,
  );
  const [observationList, setObservationList] = useState([] as PatientVitalDetails[]);

  // // PatientDashboardLatestDataListResponse watch ...
  // useEffect(() => {
  //   if (
  //     getPatientDashboardLatestDataListResponse?.patientDashBoardLatestDataListRes
  //       ?.patientObservationsList !== undefined
  //   ) {
  //     if (
  //       getPatientDashboardLatestDataListResponse?.patientDashBoardLatestDataListRes
  //         ?.patientObservationsList.length > 0
  //     ) {
  //       // map array item with selected fields
  //       let ptList =
  //         getPatientDashboardLatestDataListResponse?.patientDashBoardLatestDataListRes?.patientObservationsList.map(
  //           (element: PatientObservationsList) => ({
  //             name: element.PatientDashboardUiComponent.displayValue,
  //             content: element.LatestObservation.value,
  //             isActive: element.PatientDashboardUiComponent.isActive,
  //             type: element.LatestObservation.patientObservationType.name,
  //           }),
  //         ) as PatientVitalDetails[];
  //       // pickup matched items with API response array and static JSON
  //       const matchedItems = ptList.map((v) => ({
  //         ...menuItems.find((sp) => sp.path === v.type),
  //         ...v,
  //       })) as PatientVitalDetails[];
  //       // pickup items which doesn't have any image
  //       let matchedItemWithOutImg = matchedItems.filter((o1: PatientVitalDetails) => o1.image);
  //       // pickup items which doesn't exists in matched items
  //       let unMatchedItemArr = matchedItems.filter(
  //         (o1: PatientVitalDetails) => !matchedItemWithOutImg.some((o2) => o1.id === o2.id),
  //       );
  //       // Add missing property in unmatched items
  //       let modifiedUnmatchedItemArr = unMatchedItemArr.map((element: PatientVitalDetails) => {
  //         return {
  //           name: element.name,
  //           content: element.content,
  //           isActive: element.isActive !== undefined ? element.isActive : false,
  //           type: element.type !== undefined ? element.type : "",
  //           button: "View All",
  //           image: DefaultVital,
  //           path: element.type !== undefined ? element.type : "",
  //         };
  //       });
  //       // merge matched & unmatched item array & add id property for all item objects
  //       var totalDataSet = [...matchedItemWithOutImg, ...modifiedUnmatchedItemArr].map(
  //         (item, index) => ({ ...item, id: index + 1 }),
  //       );
  //       const finalDataSet = totalDataSet.filter((item) => item.isActive === true);
  //       setObservationList(finalDataSet);
  //     } else setObservationList([] as PatientVitalDetails[]);
  //   } else setObservationList([] as PatientVitalDetails[]);
  //   // eslint-disable-next-line react-hooks/exhaustive-deps
  // }, [getPatientDashboardLatestDataListResponse]);

  const [sleepObservationList, setSleepObservationList] = useState([] as any[]);

  useEffect(() => {
    if (
      getPatientDashboardLatestDataListResponse?.patientDashBoardLatestDataListRes
        ?.patientObservationsList !== undefined
    ) {
      if (
        getPatientDashboardLatestDataListResponse?.patientDashBoardLatestDataListRes
          ?.patientObservationsList.length > 0
      ) {
        // map array item with selected fields
        let ptList =
          getPatientDashboardLatestDataListResponse?.patientDashBoardLatestDataListRes?.patientObservationsList.map(
            (element: PatientObservationsList) => ({
              name: element.PatientDashboardUiComponent.displayValue,
              content: element.LatestObservation.value,
              isActive: element.PatientDashboardUiComponent.isActive,
              type: element.LatestObservation.patientObservationType.name,
            }),
          ) as PatientVitalDetails[];
        let values = ptList.filter(
          (person) =>
            person.type === "sleep" ||
            person.type === "sleepschedule" ||
            person.type === "sleephours" ||
            person.type === "sleeptracker" ||
            person.type === "sleepscore",
        );
        let obsTypeListValue = ptList.filter((a) => !values.map((b) => b.type).includes(a.type));
        // pickup matched items with API response array and static JSON
        const matchedItems = obsTypeListValue.map((v) => ({
          ...menuItems.find((sp) => sp.path === v.type),
          ...v,
        })) as PatientVitalDetails[];

        // pickup items which doesn't have any image
        let matchedItemWithOutImg = matchedItems.filter((o1: PatientVitalDetails) => o1.image);
        // pickup items which doesn't exists in matched items
        let unMatchedItemArr = matchedItems.filter(
          (o1: PatientVitalDetails) => !matchedItemWithOutImg.some((o2) => o1.id === o2.id),
        );
        // Add missing property in unmatched items
        let modifiedUnmatchedItemArr = unMatchedItemArr.map((element: PatientVitalDetails) => {
          return {
            name: element.name,
            content: element.content,
            isActive: element.isActive !== undefined ? element.isActive : false,
            type: element.type !== undefined ? element.type : "",
            button: "View All",
            image: DefaultVital,
            path: element.type !== undefined ? element.type : "",
          };
        });
        // merge matched & unmatched item array & add id property for all item objects
        var totalDataSet = [...matchedItemWithOutImg, ...modifiedUnmatchedItemArr].map(
          (item, index) => ({ ...item, id: index + 1 }),
        );
        const finalDataSet = totalDataSet.filter((item) => item.isActive === true);
        setObservationList(finalDataSet);
      } else setObservationList([] as PatientVitalDetails[]);
    } else setObservationList([] as PatientVitalDetails[]);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [getPatientDashboardLatestDataListResponse]);
  //check sleep type existance
  const keyToCheck = "type";
  const keyExists = sleepObservationList.some((obj) => typeof obj[keyToCheck] !== "undefined");
  useEffect(() => {
    if (
      getPatientDashboardLatestDataListResponse?.patientDashBoardLatestDataListRes
        ?.patientObservationsList !== undefined
    ) {
      if (
        getPatientDashboardLatestDataListResponse?.patientDashBoardLatestDataListRes
          ?.patientObservationsList.length > 0
      ) {
        // map array item with selected fields
        let ptList =
          getPatientDashboardLatestDataListResponse?.patientDashBoardLatestDataListRes?.patientObservationsList.map(
            (element: PatientObservationsList) => ({
              name: element.LatestObservation.patientObservationType.displayValue,
              value: element.LatestObservation.value,
              type: element.LatestObservation.patientObservationType.name,
            }),
          );
        let values = ptList.filter(
          (person) =>
            person.type === "sleep" ||
            person.type === "sleepschedule" ||
            person.type === "sleephours" ||
            person.type === "sleeptracker" ||
            person.type === "sleepscore",
        );
        setSleepObservationList(values);
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [getPatientDashboardLatestDataListResponse]);

  const pageBody = (
    <Grid container direction="column" rowSpacing={1} columnSpacing={1}>
      <Grid item>
        <Grid container spacing={1}>
          <Grid item xs={12} sm={12} md={8} lg={6}>
            <Demographics />
          </Grid>
          <Grid item xs={12} sm={12} md={4} lg={6}>
            <CaseList />
          </Grid>
        </Grid>
      </Grid>
      <Grid item xs={12} sm={12} md={8} lg={6}>
        <Button
          variant="outlined"
          size="small"
          onClick={() =>
            dispatch(
              getPatientDashBoardLatestDataList({
                patientId: patientid,
              } as LatestDataListBody),
            )
          }
          startIcon={<RefreshIcon />}
          sx={{ textTransform: "none" }}
        >
          Refresh Vitals
        </Button>
      </Grid>
      <Grid item container direction="row" justifyContent="space-between" alignItems="stretch">
        <Grid
          container
          direction="row"
          justifyContent="flex-start"
          alignItems="center"
          xs={12}
          sm={12}
          md={8}
          lg={8}
          rowSpacing={1}
          columnSpacing={1}
        >
          {observationList.map((element: PatientVitalDetails) => (
            <Grid item key={element.name} xs={6} sm={6} md={3} lg={3}>
              <CustomCard
                id={element.id}
                heading={element.name}
                subHeading={element.content}
                buttonText={element.button}
                onButtonClick={element?.path}
                image={element.image}
              />
            </Grid>
          ))}
          {keyExists === true ? (
            <Grid item xs={12} sm={12} md={12} lg={12}>
              <SleepSummary />
            </Grid>
          ) : null}
        </Grid>
        <Grid item xs={12} sm={12} lg={4}>
          <CarePlan />
        </Grid>
      </Grid>
      <Grid item>
        <Grid
          container
          direction="row"
          justifyContent="flex-start"
          alignItems="flex-start"
          spacing={1}
        >
          <Grid item xs={12} sm={12} md={12} lg={6}>
            <MedicationSummary />
          </Grid>
          <Grid item xs={12} sm={12} md={12} lg={6}>
            <HealthCondition />
          </Grid>
        </Grid>
      </Grid>
      <Grid item>
        <Grid container direction="column" justifyContent="flex-start" alignItems="stretch">
          <Grid item xs={12} sm={12} lg={12}>
            <TabContext value={selected}>
              <Box sx={{ borderBottom: 1, borderColor: "divider" }}>
                <TabList onChange={handleTabChange}>
                  <Tab label="Patient Details List" value="1" />
                  <Tab label="Patient Observation List" value="2" />
                  <Tab label="Patient Encounter List" value="3" />
                </TabList>
              </Box>
              <TabPanel value="1">{<PatientDataDetails toggleState={toggleState} />}</TabPanel>
              <TabPanel value="2">{<DataObservationList toggleState={toggleState} />}</TabPanel>
              <TabPanel value="3">{<PatientEncounterList toggleState={toggleState} />}</TabPanel>
            </TabContext>
          </Grid>
        </Grid>
      </Grid>
    </Grid>
  );

  return (
    <Box>
      {patientDetailsValue.loading === true || getPatientDashboardLatestDataListResponse.loading
        ? loadingMarkup
        : ""}
      <Container maxWidth="xl">
        <Stack direction="column" justifyContent="flex-start" alignItems="stretch" spacing={2}>
          <Grid container direction="row" justifyContent="space-between" alignItems="center" mt={2}>
            <Grid item>
              <Grid
                container
                direction="row"
                justifyContent="flex-start"
                alignItems="center"
                spacing={2}
              >
                <Grid item>
                  <MWPageTitle
                    title={`Patient Details of ${NameGenerate.changeName(
                      patientid,
                      patientDetailsValue?.personalDetails?.firstname !== undefined &&
                        patientDetailsValue?.personalDetails?.lastname !== undefined
                        ? patientDetailsValue?.personalDetails?.firstname +
                            " " +
                            patientDetailsValue?.personalDetails?.lastname
                        : "",
                    )} `}
                    backButton={true}
                  />
                </Grid>
                <Grid item>
                  <FormControlLabel
                    control={
                      <NameChangeSwitch
                        checked={toggleState}
                        onChange={handleNameChange}
                        name="Mode"
                      />
                    }
                    label={"Strict Mode"}
                    labelPlacement={"start"}
                  />
                </Grid>
              </Grid>
            </Grid>
            <Grid item>
              <Grid
                container
                direction="row"
                justifyContent="space-between"
                alignItems="flex-start"
              >
                <Grid item>
                  <Grid
                    container
                    direction="row"
                    justifyContent="flex-end"
                    alignItems="flex-start"
                    spacing={2}
                  >
                    <Grid item>
                      <Button
                        variant="text"
                        size="medium"
                        onClick={() => handleViewPtDataPoint()}
                        sx={{ textTransform: "none" }}
                      >
                        View Patient Observation
                      </Button>
                    </Grid>
                    <Grid item>
                      <Button
                        variant="text"
                        onClick={() => handleViewNotesToggle()}
                        sx={{ textTransform: "none" }}
                      >
                        View Note
                      </Button>
                    </Grid>
                    <Grid item>
                      <Button
                        variant="text"
                        onClick={() => viewdemographic()}
                        sx={{ textTransform: "none" }}
                      >
                        Demographic Details
                      </Button>
                    </Grid>
                    <Grid item>
                      <Button
                        variant="contained"
                        onClick={() => handleAddEncounterToggle()}
                        sx={{ textTransform: "none" }}
                      >
                        Add Case
                      </Button>
                    </Grid>
                  </Grid>
                </Grid>
              </Grid>
            </Grid>
          </Grid>
          {pageBody}
        </Stack>
      </Container>
    </Box>
  );
}
