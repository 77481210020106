import { CreateAppointmentReqRes } from "./Model";

export const CREATE_APPOINTMENT_DETAILS_FOR_CASE_LOADING =
  "CREATE_APPOINTMENT_DETAILS_FOR_CASE_LOADING ";
export const CREATE_APPOINTMENT_DETAILS_FOR_CASE_SUCCESS =
  "CREATE_APPOINTMENT_DETAILS_FOR_CASE_SUCCESS";
export const CREATE_APPOINTMENT_DETAILS_FOR_CASE_FAIL = "CREATE_APPOINTMENT_DETAILS_FOR_CASE_FAIL";
export const CREATE_APPOINTMENT_DETAILS_FOR_CASE_UPDATE_API_MSG =
  "CREATE_APPOINTMENT_DETAILS_FOR_CASE_UPDATE_API_MSG";
export const CREATE_APPOINTMENT_DETAILS_FOR_CASE_UPDATE_API_RES =
  "CREATE_APPOINTMENT_DETAILS_FOR_CASE_UPDATE_API_RES";

export interface CreateAppointmentState {
  CreateAppointmentRes: CreateAppointmentReqRes;
  loading: boolean;
  successMsg: string;
  errorMsg: string;
  status: number;
}
export interface CreateAppointmentLoading {
  type: typeof CREATE_APPOINTMENT_DETAILS_FOR_CASE_LOADING;
  loading: boolean;
}
export interface CreateAppointmentSuccess {
  type: typeof CREATE_APPOINTMENT_DETAILS_FOR_CASE_SUCCESS;
  payload: CreateAppointmentReqRes;
  successMsg: string;
}
export interface CreateAppointmentFail {
  type: typeof CREATE_APPOINTMENT_DETAILS_FOR_CASE_FAIL;
  payload: CreateAppointmentReqRes;
  errorMsg: string;
  status: number;
}
export interface CreateAppointmentUpdateAPIMsg {
  type: typeof CREATE_APPOINTMENT_DETAILS_FOR_CASE_UPDATE_API_MSG;
  payload: CreateAppointmentReqRes;
  successMsg: string;
  errorMsg: string;
  status: number;
}
export interface CreateAppointmentUpdateAPIRes {
  type: typeof CREATE_APPOINTMENT_DETAILS_FOR_CASE_UPDATE_API_RES;
  payload: CreateAppointmentReqRes;
  successMsg: string;
  errorMsg: string;
  status: number;
}

export type CreateAppointmentActionTypes =
  | CreateAppointmentLoading
  | CreateAppointmentSuccess
  | CreateAppointmentFail
  | CreateAppointmentUpdateAPIMsg
  | CreateAppointmentUpdateAPIRes;
