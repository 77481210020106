import axios from "axios";
import { Dispatch } from "redux";
import Url from "../../../Url";
import { setupInterceptorsTo } from "../../Authentication/Interceptors";
import { addQuestionSubmitAction } from "./Action";
import {
  questionSubmitActionTypes,
  QUESTION_SUBMIT_FAIL,
  QUESTION_SUBMIT_LOADING,
  QUESTION_SUBMIT_SUCCESS,
} from "./ActionTypes";
import { addQuestionSubmitDetails } from "./Model";
import { headers } from "components/Utility";

export const addAskQuestionSubmit = (addQuestionSubmitBody: addQuestionSubmitDetails) => {
  return function (dispatch: Dispatch<questionSubmitActionTypes>) {
    dispatch({
      type: QUESTION_SUBMIT_LOADING,
    });

    setupInterceptorsTo(axios)
      .post(Url.apiURL + `/questionandanswerapp/api/question/ask_Question`, addQuestionSubmitBody, {
        headers: headers,
      })
      .then((res) => {
        dispatch(addQuestionSubmitAction(res.data));
        dispatch({
          type: QUESTION_SUBMIT_SUCCESS,
          payload: res.data,
        });
      })
      .catch((_error) => {
        dispatch({
          type: QUESTION_SUBMIT_FAIL,
        });
      });
  };
};
