import { OrganizationPatientList } from "../interfaces/Model";
import {
  GetDoctorState,
  DoctorActionTypes,
  PatientActionTypes,
  GetPatientState,
  GetCaseStateType,
  CaseGetActionTypes,
  PATIENT_LIST_SUCCESS,
  PATIENT_LIST_LOADING,
  PATIENT_LIST_FAIL,
  DOCTOR_LIST_SUCCESS,
  DOCTOR_LIST_LOADING,
  DOCTOR_LIST_FAIL,
  CASE_LIST_SUCCESS,
  CASE_LIST_LOADING,
  CASE_LIST_FAIL,
  GetChiefComplaintStateType,
  ChiefComplaintGetActionTypes,
  CC_LIST_SUCCESS,
  CC_LIST_LOADING,
  CC_LIST_FAIL,
  AddChiefComplaintTypes,
  SetAddChiefComplaintStateType,
  CC_ADD_LIST_SUCCESS,
  CC_ADD_LIST_LOADING,
  CC_ADD_LIST_FAIL,
  GetQuestionStateType,
  QuestionGetActionTypes,
  QUESTION_LIST_SUCCESS,
  QUESTION_LIST_LOADING,
  QUESTION_LIST_FAIL,
  DeleteDocumentTypes,
  SetDeleteDocumentStateType,
  DELETE_DOCUMENT_LIST_SUCCESS,
  DELETE_DOCUMENT_LIST_LOADING,
  DELETE_DOCUMENT_LIST_FAIL,
  DeletePatientTypes,
  SetDeletePatientStateType,
  DELETE_PATIENT_LIST_SUCCESS,
  DELETE_PATIENT_LIST_LOADING,
  DELETE_PATIENT_LIST_FAIL,
  SHARED_PATIENT_LIST_SUCCESS,
  SHARED_PATIENT_LIST_LOADING,
  SHARED_PATIENT_LIST_FAIL,
  GetSharedPatientState,
  SharedPatientActionTypes,
  GetTaskStateType,
  TaskGetActionTypes,
  TASK_LIST_LOADING,
  TASK_LIST_SUCCESS,
  TASK_LIST_FAIL,
  OwnerAppoinmentListActionTypes,
  OwnerAppoinmentStateType,
  OWNER_APPOINMENT_LIST_LOADING,
  OWNER_APPOINMENT_LIST_SUCCESS,
  OWNER_APPOINMENT_LIST_FAIL,
  CASE_APPOINMENT_LIST_SUCCESS,
  CASE_APPOINMENT_LIST_LOADING,
  CASE_APPOINMENT_LIST_FAIL,
  CaseAppoinmentListActionTypes,
  CaseAppoinmentStateType,
  ORGANIZATION_LIST_SUCCESS,
  ORGANIZATION_LIST_LOADING,
  ORGANIZATION_LIST_FAIL,
  AdminGetOrganizationListActionTypes,
  GetOrganizationListStateType,
  GetOrganizationPatientStateType,
  ORGPATIENT_LIST_SUCCESS,
  ORGPATIENT_LIST_LOADING,
  ORGPATIENT_LIST_FAIL,
  OrganizationPatientGetActionTypes,
} from "../types/ActionTypes";

const initialStateGetPosts: GetDoctorState = { loading: false, doctorValue: [] };
const initialStateGetPatient: GetPatientState = { loading: false, patientValue: [] };
const initialStateGetSharedPatient: GetSharedPatientState = {
  loading: false,
  sharedPatientValue: [],
};
const initialStateGetCase: GetCaseStateType = {
  loading: false,
  caseValue: [],
  patientname: Object.assign({}),
};
const initialStateGetQuestion: GetQuestionStateType = { loading: false, questionValue: [] };
const initialStateGetCC: GetChiefComplaintStateType = { loading: false, ccValue: [] };
const initialStateGetCCAdd: SetAddChiefComplaintStateType = {
  loading: false,
  addCC: [],
  response: Object.assign({}),
};
const initialStateGetDeleteDocument: SetDeleteDocumentStateType = {
  loading: false,
  deleteDoc: [],
  response: Object.assign({}),
};
const initialStateGetDeletePatient: SetDeletePatientStateType = {
  loading: false,
  deletePatient: [],
  response: Object.assign({}),
};
// Initial Task State
const initialStateGetTask: GetTaskStateType = { loading: false, taskValue: [] };
// Initial owner Appoinment List State
const initialStateGetOwnerAppoinmentList: OwnerAppoinmentStateType = {
  loading: false,
  OwnerAppoinmentListValue: [],
};
// Initial patient case Appoinment List State
const initialStateGetCaseAppoinmentList: CaseAppoinmentStateType = {
  loading: false,
  CaseAppoinmentListValue: [],
};
const initialStateGetOrganizationlist: GetOrganizationListStateType = {
  loading: false,
  organizationListValue: [],
};
const initialStateGetOrganizationPatient: GetOrganizationPatientStateType = {
  loading: false,
  organizationPatientValue: {} as OrganizationPatientList,
};

export const getDoctorReducer = (
  state = initialStateGetPosts,
  action: DoctorActionTypes,
): GetDoctorState => {
  switch (action.type) {
    case DOCTOR_LIST_SUCCESS:
      return {
        ...state,
        loading: false,
        doctorValue: action.payload,
      };
    case DOCTOR_LIST_LOADING:
      return {
        ...state,
        loading: true,
        doctorValue: [],
      };
    case DOCTOR_LIST_FAIL:
      return {
        ...state,
        doctorValue: [],
        loading: false,
      };
    default:
      return state;
  }
};

export const getPatientReducer = (
  state = initialStateGetPatient,
  action: PatientActionTypes,
): GetPatientState => {
  switch (action.type) {
    case PATIENT_LIST_SUCCESS:
      return {
        ...state,
        loading: false,
        patientValue: action.payload,
      };
    case PATIENT_LIST_LOADING:
      return {
        ...state,
        loading: true,
        patientValue: [],
      };
    case PATIENT_LIST_FAIL:
      return {
        ...state,
        patientValue: [],
        loading: false,
      };
    default:
      return state;
  }
};

export const getSharedPatientReducer = (
  state = initialStateGetSharedPatient,
  action: SharedPatientActionTypes,
): GetSharedPatientState => {
  switch (action.type) {
    case SHARED_PATIENT_LIST_SUCCESS:
      return {
        ...state,
        loading: false,
        sharedPatientValue: action.payload,
      };
    case SHARED_PATIENT_LIST_LOADING:
      return {
        ...state,
        loading: true,
        sharedPatientValue: [],
      };
    case SHARED_PATIENT_LIST_FAIL:
      return {
        ...state,
        sharedPatientValue: [],
        loading: false,
      };
    default:
      return state;
  }
};

export const getOrganizationListReducer = (
  state = initialStateGetOrganizationlist,
  action: AdminGetOrganizationListActionTypes,
): GetOrganizationListStateType => {
  switch (action.type) {
    case ORGANIZATION_LIST_SUCCESS:
      return {
        ...state,
        loading: false,
        organizationListValue: action.payload,
      };
    case ORGANIZATION_LIST_LOADING:
      return {
        ...state,
        loading: true,
        organizationListValue: [],
      };
    case ORGANIZATION_LIST_FAIL:
      return {
        ...state,
        organizationListValue: [],
        loading: false,
      };
    default:
      return state;
  }
};

export const getOrganizationPatientReducer = (
  state = initialStateGetOrganizationPatient,
  action: OrganizationPatientGetActionTypes,
): GetOrganizationPatientStateType => {
  switch (action.type) {
    case ORGPATIENT_LIST_SUCCESS:
      return {
        ...state,
        loading: false,
        organizationPatientValue: action.payload,
      };
    case ORGPATIENT_LIST_LOADING:
      return {
        ...state,
        loading: true,
        organizationPatientValue: {} as OrganizationPatientList,
      };
    case ORGPATIENT_LIST_FAIL:
      return {
        ...state,
        organizationPatientValue: {} as OrganizationPatientList,
        loading: false,
      };
    default:
      return state;
  }
};

export const getCaseReducer = (
  state = initialStateGetCase,
  action: CaseGetActionTypes,
): GetCaseStateType => {
  switch (action.type) {
    case CASE_LIST_SUCCESS:
      return {
        ...state,
        loading: false,
        caseValue: action.payload,
        patientname: action.patientname,
      };
    case CASE_LIST_LOADING:
      return {
        ...state,
        loading: true,
        caseValue: [],
      };
    case CASE_LIST_FAIL:
      return {
        ...state,
        caseValue: [],
        loading: false,
      };
    default:
      return state;
  }
};

export const getQuestionReducer = (
  state = initialStateGetQuestion,
  action: QuestionGetActionTypes,
): GetQuestionStateType => {
  switch (action.type) {
    case QUESTION_LIST_SUCCESS:
      return {
        ...state,
        loading: false,
        questionValue: action.payload,
      };
    case QUESTION_LIST_LOADING:
      return {
        ...state,
        loading: true,
        questionValue: [],
      };
    case QUESTION_LIST_FAIL:
      return {
        ...state,
        questionValue: [],
        loading: false,
      };
    default:
      return state;
  }
};

export const getCCReducer = (
  state = initialStateGetCC,
  action: ChiefComplaintGetActionTypes,
): GetChiefComplaintStateType => {
  switch (action.type) {
    case CC_LIST_SUCCESS:
      return {
        ...state,
        loading: false,
        ccValue: action.payload,
      };
    case CC_LIST_LOADING:
      return {
        ...state,
        loading: true,
        ccValue: [],
      };
    case CC_LIST_FAIL:
      return {
        ...state,
        ccValue: [],
        loading: false,
      };
    default:
      return state;
  }
};

export const addCCReducer = (
  state = initialStateGetCCAdd,
  action: AddChiefComplaintTypes,
): SetAddChiefComplaintStateType => {
  switch (action.type) {
    case CC_ADD_LIST_SUCCESS:
      return {
        ...state,
        loading: false,
        response: action.payload,
      };
    case CC_ADD_LIST_LOADING:
      return {
        ...state,
        loading: true,
        addCC: [],
      };
    case CC_ADD_LIST_FAIL:
      return {
        ...state,
        addCC: [],
        response: action.payload,
        loading: false,
      };
    default:
      return state;
  }
};

export const deleteDocumentReducer = (
  state = initialStateGetDeleteDocument,
  action: DeleteDocumentTypes,
): SetDeleteDocumentStateType => {
  switch (action.type) {
    case DELETE_DOCUMENT_LIST_SUCCESS:
      return {
        ...state,
        loading: false,
        response: action.payload,
      };
    case DELETE_DOCUMENT_LIST_LOADING:
      return {
        ...state,
        loading: true,
        deleteDoc: [],
      };
    case DELETE_DOCUMENT_LIST_FAIL:
      return {
        ...state,
        deleteDoc: [],
        loading: false,
        response: action.payload,
      };
    default:
      return state;
  }
};

export const deletePatientReducer = (
  state = initialStateGetDeletePatient,
  action: DeletePatientTypes,
): SetDeletePatientStateType => {
  switch (action.type) {
    case DELETE_PATIENT_LIST_SUCCESS:
      return {
        ...state,
        loading: false,
        response: action.payload,
      };
    case DELETE_PATIENT_LIST_LOADING:
      return {
        ...state,
        loading: true,
        deletePatient: [],
      };
    case DELETE_PATIENT_LIST_FAIL:
      return {
        ...state,
        deletePatient: [],
        loading: false,
      };
    default:
      return state;
  }
};
// Get  Task List Reducer
export const getTaskReducer = (
  state = initialStateGetTask,
  action: TaskGetActionTypes,
): GetTaskStateType => {
  switch (action.type) {
    case TASK_LIST_SUCCESS:
      return {
        ...state,
        loading: false,
        taskValue: action.payload,
      };
    case TASK_LIST_LOADING:
      return {
        ...state,
        loading: true,
        taskValue: [],
      };
    case TASK_LIST_FAIL:
      return {
        ...state,
        taskValue: [],
        loading: false,
      };
    default:
      return state;
  }
};

// Get  Owner Appoinment List Reducer
export const getOwnerAppoinmentListReducer = (
  state = initialStateGetOwnerAppoinmentList,
  action: OwnerAppoinmentListActionTypes,
): OwnerAppoinmentStateType => {
  switch (action.type) {
    case OWNER_APPOINMENT_LIST_SUCCESS:
      return {
        ...state,
        loading: false,
        OwnerAppoinmentListValue: action.payload,
      };
    case OWNER_APPOINMENT_LIST_LOADING:
      return {
        ...state,
        loading: true,
        OwnerAppoinmentListValue: [],
      };
    case OWNER_APPOINMENT_LIST_FAIL:
      return {
        ...state,
        OwnerAppoinmentListValue: [],
        loading: false,
      };
    default:
      return state;
  }
};

// Get  patient case Appoinment List Reducer
export const getCaseAppoinmentListReducer = (
  state = initialStateGetCaseAppoinmentList,
  action: CaseAppoinmentListActionTypes,
): CaseAppoinmentStateType => {
  switch (action.type) {
    case CASE_APPOINMENT_LIST_SUCCESS:
      return {
        ...state,
        loading: false,
        CaseAppoinmentListValue: action.payload,
      };
    case CASE_APPOINMENT_LIST_LOADING:
      return {
        ...state,
        loading: true,
        CaseAppoinmentListValue: [],
      };
    case CASE_APPOINMENT_LIST_FAIL:
      return {
        ...state,
        CaseAppoinmentListValue: [],
        loading: false,
      };
    default:
      return state;
  }
};
