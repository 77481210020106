import React from "react";
import { CardMedia } from "@mui/material";

interface Props {
  image: any;
}

const CardLogo: React.FC<Props> = ({ image }) => {
  return (
    <CardMedia
      component="img"
      image={image}
      alt="Paella dish"
      style={{
        width: "auto",
        height: "50px",
        borderRadius: 2,
        padding: 2,
      }}
    />
  );
};

export default CardLogo;
