import React, { useState, useEffect } from "react";
import { useCallback } from "react";
import IconButton from "@mui/material/IconButton";
import SearchIcon from "@mui/icons-material/Search";
import OutlinedInput from "@mui/material/OutlinedInput";
import InputAdornment from "@mui/material/InputAdornment";
import Grid from "@mui/material/Grid";
import { useHistory } from "react-router-dom";
import Autocomplete from "@mui/material/Autocomplete";
import { useDispatch, useSelector } from "react-redux";
import { AppState } from "../redux/store/Store";
import { categoryListApi } from "../redux/findDoctor/category/API";
import { speciaListApi } from "../redux/findDoctor/specialist/API";
import { doctorListApi } from "../redux/findDoctor/searchDoctor/API";
import { getEncounterList } from "../redux/effects/ApiCall";
import {
  Typography,
  TextField,
  Box,
  InputLabel,
  Stack,
  CardHeader,
  Card,
  Snackbar,
  Dialog,
  DialogTitle,
  DialogActions,
  Button,
  Avatar,
} from "@mui/material";
import { t } from "i18next";
import PageLayout from "../components/PageLayout";
import AccountCircleIcon from "@mui/icons-material/AccountCircle";
import { toggleAppBarLoading } from "redux/appLoader/Actions";

type Props = {
  loading?: boolean | any;
};

// eslint-disable-next-line no-empty-pattern
export default function FindDoctor({}: Props) {
  const history = useHistory();
  const dispatch = useDispatch();
  interface State {
    doctorName: string;
  }
  const [doctorName, setDoctorName] = React.useState<State>({
    doctorName: "",
  });

  const gotoDoctorDetails = (doctorId: number) => {
    history.push(`/doctordetails/${doctorId}`);
  };

  const handleChange = (prop: keyof State) => (event: React.ChangeEvent<HTMLInputElement>) => {
    setDoctorName({ ...doctorName, [prop]: event.target.value });
  };

  // const clearDoctorName = () => {
  //   setDoctorName({ ...doctorName, doctorName: "" });
  // };
  const handleMouseDownPassword = (event: React.MouseEvent<HTMLButtonElement>) => {
    event.preventDefault();
  };

  const [specialistValues, setSpecialistValues] = useState([] as any[]);
  const [categoryValues, setCategoryValues] = useState([] as any[]);

  const [selectedCategory] = useState("");
  const [selectedSpeciality] = useState("");

  const [doctorListValue, setDoctorListValue] = useState<any[]>([]);

  const [bookAppointmentModalActive, setBookAppointmentModalActive] = useState(false);
  // const handleBookAppointmentToggle = (doctorId: any) => {
  //   localStorage.setItem("docotorId", doctorId);
  //   setBookAppointmentModalActive(
  //     (bookAppointmentModalActive) => !bookAppointmentModalActive
  //   );
  //   getPatientList();
  // };
  const closehandleBookAppointmentToggle = () => {
    setBookAppointmentModalActive((bookAppointmentModalActive) => !bookAppointmentModalActive);
  };
  const [patientCaseListValue, setPatientCaseListValue] = useState<any[]>([]);
  const [selectedPatient, setSelectedPatient] = useState("");
  const [patientListValue, setPatientListValue] = useState<any[]>([]);
  const [selectedPatientcase, setSelectedPatientCase] = useState("");

  const [categorySearchErrorActive, setCategorySearchErrorActive] = useState(false);
  const [categorySearchErrorMessage] = useState("");
  const toggleCategorySearchErrorActive = useCallback(
    () => setCategorySearchErrorActive((categorySearchErrorActive) => !categorySearchErrorActive),
    [],
  );
  const categorySearchToastMarkup = categorySearchErrorActive ? (
    <Snackbar
      autoHideDuration={6000}
      message={categorySearchErrorMessage}
      onClose={toggleCategorySearchErrorActive}
    />
  ) : null;

  const [specialitySearchErrorActive, setSpecialitySearchErrorActive] = useState(false);
  const [specialitySearchErrorMessage] = useState("");
  const toggleSpecialitySearchErrorActive = useCallback(
    () =>
      setSpecialitySearchErrorActive((specialitySearchErrorActive) => !specialitySearchErrorActive),
    [],
  );
  const specialitySearchToastMarkup = specialitySearchErrorActive ? (
    <Snackbar
      autoHideDuration={6000}
      message={specialitySearchErrorMessage}
      onClose={toggleSpecialitySearchErrorActive}
    />
  ) : null;

  const [drNameSearchErrorActive, setDrNameSearchErrorActive] = useState(false);
  const [drNameSearchErrorMessage] = useState("");
  const toggleDrNameSearchErrorActive = useCallback(
    () => setDrNameSearchErrorActive((drNameSearchErrorActive) => !drNameSearchErrorActive),
    [],
  );
  const drNameSearchToastMarkup = drNameSearchErrorActive ? (
    <Snackbar
      autoHideDuration={6000}
      message={drNameSearchErrorMessage}
      onClose={toggleDrNameSearchErrorActive}
    />
  ) : null;

  // Speciality List dropdown.........
  const getCategoryList = () => {
    dispatch(categoryListApi());
  };

  // Speciality List dropdown.........
  const getSpecialityList = () => {
    dispatch(speciaListApi());
  };

  ///// 1. ****mui category dropdown**** /////
  const [setselectedCategoryValue] = React.useState<string | null>(categoryValues[0]);
  const [inputValue, setInputValue] = React.useState("");

  const selectCategory = (selectedCategory: any) => {
    setInputValue(selectedCategory);
  };

  const selectCategoryOnChnage = (selectedCategory: any) => {
    dispatch(
      doctorListApi({
        searchKey: doctorName.doctorName,
        speciality: selectedSpeciality,
        doctorCategoryId: selectedCategory.value,
      }),
    );
  };
  ///// ************************ /////

  ///// 2. ****mui specialist dropdown**** /////
  const [setselectedspecialistValue] = React.useState<string | null>(specialistValues[0]);
  const [specialistinputValue, setSpecialistInputValue] = React.useState("");

  const selectSpecialist = (Specialist: any) => {
    setSpecialistInputValue(Specialist);
  };

  const selectSpecialistOnChnage = (Specialist: any) => {
    dispatch(
      doctorListApi({
        searchKey: doctorName.doctorName,
        speciality: Specialist.value,
        doctorCategoryId: selectedCategory,
      }),
    );
  };
  ///// 3. **** Text search **** /////

  const searchDoctor = () => {
    dispatch(
      doctorListApi({
        searchKey: doctorName.doctorName,
        speciality: selectedSpeciality,
        doctorCategoryId: selectedCategory,
      }),
    );
  };
  /////// ***** Modal dropdown ***** ///////
  // Patient List dropdown.........
  // const getPatientList = () => {
  //   dispatch(getPatientListValue());
  // };

  const [setselectedpatientValue] = React.useState<string | null>(patientListValue[0]);
  const [patientinputValue, setPatientInputValue] = React.useState("");

  const selectPatient = (patient: any) => {
    setPatientInputValue(patient);
  };

  const selectPatientOnChnage = (patient: any) => {
    onSelectPatient(patient.value);
    setSelectedPatient(patient.value);
  };

  // Search API call on select Category
  const onSelectPatient = (patientId: any) => {
    dispatch(getEncounterList(patientId));
  };

  const [setselectedCaseValue] = React.useState<string | null>(patientCaseListValue[0]);
  const [caseinputValue, setCaseInputValue] = React.useState("");

  const selectCase = (caseList: any) => {
    setCaseInputValue(caseList);
  };

  const selectCaseOnChnage = (caseId: any) => {
    setSelectedPatientCase(caseId.value);
  };

  const gotoCaseDetails = () => {
    const paymentRedirect = Number(2);
    localStorage.setItem("paymentRedirect", `${paymentRedirect}`);
    history.push(`/filepreparation/${selectedPatient}/${selectedPatientcase}/${paymentRedirect}`);
  };
  ///// ************************ /////

  useEffect(() => {
    getCategoryList();
    getSpecialityList();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const docCategoryList = useSelector((state: AppState) => state.categoryList?.doctorCategoryList);
  const docCategoryListLoading = useSelector((state: AppState) => state.categoryList);

  useEffect(() => {
    // loading(docCategoryListLoading.loading);
    if (docCategoryList?.doctorCategoryList !== undefined) {
      setCategoryValues(docCategoryList?.doctorCategoryList);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [docCategoryList, docCategoryListLoading]);

  const speciaList = useSelector((state: AppState) => state.speciaList?.specialistList);
  const speciaListLoading = useSelector((state: AppState) => state.speciaList);

  useEffect(() => {
    // loading(speciaListLoading.loading);
    if (speciaList?.doctorSpecialtyList !== undefined) {
      setSpecialistValues(speciaList?.doctorSpecialtyList);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [speciaList, speciaListLoading]);

  const doctorList = useSelector((state: AppState) => state.doctorList?.doctorsList);
  const doctorLoading = useSelector((state: AppState) => state.doctorList);

  useEffect(() => {
    // loading(doctorLoading.loading);
    if (doctorList?.doctorsList !== undefined) {
      setDoctorListValue(doctorList?.doctorsList);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [doctorList, doctorLoading]);

  const patientListApiValue = useSelector((state: AppState) => state.patientValue?.patientValue);
  useEffect(() => {
    let patientList: Array<{ label: string; value: string }> = [];
    patientListApiValue.forEach((element: { firstname: any; lastname: any; id: any }) => {
      patientList.push({
        label: element.firstname + " " + element.lastname,
        value: element.id,
      });
    });
    setPatientListValue(patientList);
  }, [patientListApiValue]);

  const caseListValue = useSelector((state: AppState) => state.caseValue);

  useEffect(() => {
    if (caseListValue?.caseValue !== undefined) {
      let values = caseListValue.caseValue;
      let patientCaseList: Array<{ label: string; value: string }> = [];
      values.forEach((element: { whyWant2ndOpinion: any; caseId: any }) => {
        patientCaseList.push({
          label: element.whyWant2ndOpinion,
          value: element.caseId,
        });
      });
      setPatientCaseListValue(patientCaseList);
      // eslint-disable-next-line react-hooks/exhaustive-deps
    }
  }, [caseListValue]);

  useEffect(() => {
    dispatch(
      toggleAppBarLoading(
        docCategoryListLoading?.loading ||
          speciaListLoading?.loading ||
          doctorLoading?.loading ||
          caseListValue?.loading,
      ),
    );
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [docCategoryListLoading, speciaListLoading, doctorLoading, caseListValue]);

  const actualPageMarkup = (
    <Stack direction="column" justifyContent="flex-start" alignItems="stretch" spacing={2}>
      <Grid
        container
        direction="row"
        justifyContent="flex-start"
        alignItems="flex-start"
        spacing={2}
      >
        <Grid item xs={12} sm={12} md={4} lg={4} xl={4}>
          <Typography variant="subtitle1">{t("finddoc.category", { ns: ["home"] })!}</Typography>
          <Autocomplete
            value={setselectedCategoryValue}
            inputValue={inputValue}
            onInputChange={(_event, newInputValue) => {
              selectCategory(newInputValue);
            }}
            id="controllable-states-demo"
            options={categoryValues}
            onChange={(_event: any, newValue: string | null) => {
              selectCategoryOnChnage(newValue);
            }}
            renderInput={(params) => (
              <TextField
                {...params}
                fullWidth
                placeholder={t("finddoc.selCat", { ns: ["home"] })!}
                variant="outlined"
              />
            )}
            disableClearable
          />
        </Grid>
        <Grid item xs={12} sm={12} md={4} lg={4} xl={4}>
          <Typography variant="subtitle1">{t("allDocPage.spcl", { ns: ["home"] })!}</Typography>
          <Autocomplete
            value={setselectedspecialistValue}
            inputValue={specialistinputValue}
            onInputChange={(_event, newInputValue) => {
              selectSpecialist(newInputValue);
            }}
            id="controllable-states-demo"
            options={specialistValues}
            onChange={(_event: any, newValue: string | null) => {
              selectSpecialistOnChnage(newValue);
            }}
            renderInput={(params) => (
              <TextField
                {...params}
                fullWidth
                placeholder={t("allDocPage.selSpecl", { ns: ["home"] })!}
                variant="outlined"
              />
            )}
            disableClearable
          />
        </Grid>
        <Grid item xs={12} sm={12} md={4} lg={4} xl={4}>
          <Typography variant="subtitle1">{t("finddoc.selBName", { ns: ["home"] })!}</Typography>
          <InputLabel htmlFor="outlined-adornment-doctorName"></InputLabel>
          <OutlinedInput
            id="doctorNameID"
            placeholder={t("finddoc.searcDoc", { ns: ["home"] })!}
            value={doctorName.doctorName}
            onChange={handleChange("doctorName")}
            fullWidth
            endAdornment={
              <InputAdornment position="end">
                {doctorName.doctorName === "" ? "" : null}
                <IconButton
                  aria-label={t("finddoc.searDocNam", { ns: ["home"] })!}
                  onClick={searchDoctor}
                  onMouseDown={handleMouseDownPassword}
                  edge="end"
                  size="large"
                >
                  <SearchIcon />
                </IconButton>
              </InputAdornment>
            }
          />
        </Grid>
      </Grid>
      <Grid
        container
        direction="row"
        justifyContent="flex-start"
        alignItems="flex-start"
        spacing={2}
      >
        {doctorListValue.map((doctorItem: any) => (
          <Grid item xs={12} sm={4} md={4} lg={4} xl={4} key={doctorItem.id}>
            <Card onClick={() => gotoDoctorDetails(doctorItem.id)} style={{ cursor: "pointer" }}>
              <div style={{ height: "500px" }}>
                <div style={{ height: "105px" }}>
                  <CardHeader
                    avatar={
                      <Avatar aria-label="recipe">
                        <AccountCircleIcon fontSize="large" />
                      </Avatar>
                    }
                    title={<Typography variant="body1">{doctorItem.fullName}</Typography>}
                    subheader={<small>{doctorItem.qualification}</small>}
                  />
                </div>
                <Grid container alignItems="center" justifyContent="center">
                  <div style={{ height: "300px", width: "300px" }}>
                    <img alt="" width="100%" height="100%" src={doctorItem.image?.document} />
                  </div>
                </Grid>
                <Box alignItems="center" my={1} display={"flex"}>
                  <Box justifyContent="center" p={2} flex={"auto"}>
                    <Typography>Specialty</Typography>
                    <Typography>{doctorItem.specialtyWithYearsOfExperience}</Typography>
                  </Box>
                </Box>
              </div>
            </Card>
          </Grid>
        ))}
      </Grid>
    </Stack>
  );
  const appointmentModalBody = (
    <Dialog open={bookAppointmentModalActive} onClose={closehandleBookAppointmentToggle}>
      <DialogTitle>{t("finddoc.bookAppo", { ns: ["home"] })!}</DialogTitle>
      <DialogActions>
        <Button onClick={gotoCaseDetails}>{t("needhelppage.submit", { ns: ["home"] })!}</Button>
        <Button onClick={closehandleBookAppointmentToggle}>
          {t("common.cancel", { ns: ["home"] })!}
        </Button>
      </DialogActions>
      <Stack direction="column" justifyContent="flex-start" alignItems="stretch" spacing={2}>
        <Box>
          <Typography style={{ marginBottom: "5px" }}>
            {t("addpatientpage.selpatient", { ns: ["home"] })!}
          </Typography>
          <Autocomplete
            fullWidth
            value={setselectedpatientValue}
            inputValue={patientinputValue}
            onInputChange={(_event, newInputValue) => {
              selectPatient(newInputValue);
            }}
            id="controllable-states-demo"
            options={patientListValue}
            onChange={(_event: any, newValue: string | null) => {
              selectPatientOnChnage(newValue);
            }}
            sx={{ width: 950 }}
            renderInput={(params) => (
              <TextField
                {...params}
                placeholder={t("finddoc.selCat", { ns: ["home"] })!}
                variant="outlined"
              />
            )}
          />
        </Box>
        <Box>
          <Typography style={{ marginBottom: "5px" }}>
            {t("finddoc.selPatCase", { ns: ["home"] })!}
          </Typography>
          <Autocomplete
            fullWidth
            value={setselectedCaseValue}
            inputValue={caseinputValue}
            onInputChange={(_event, newInputValue) => {
              selectCase(newInputValue);
            }}
            id="controllable-states-demo"
            options={patientCaseListValue}
            onChange={(_event: any, newValue: string | null) => {
              selectCaseOnChnage(newValue);
            }}
            sx={{ width: 950 }}
            renderInput={(params) => (
              <TextField
                {...params}
                placeholder={t("finddoc.chSelCase", { ns: ["home"] })!}
                variant="outlined"
              />
            )}
          />
        </Box>
      </Stack>
    </Dialog>
  );
  return (
    <PageLayout>
      {actualPageMarkup}
      {appointmentModalBody}
      {categorySearchToastMarkup}
      {specialitySearchToastMarkup}
      {drNameSearchToastMarkup}
    </PageLayout>
  );
}
