import { LanguageList, PersonalDetails, SexualOrientationList } from "./Model";
import { GenderList, RelationshipList, BloodGroupList } from "./Model";

// Case Status
export const GENDER_LIST_LOADING = "GENDER_LIST_LOADING";
export const GENDER_LIST_SUCCESS = "GENDER_LIST_SUCCESS";
export const GENDER_LIST_FAIL = "GENDER_LIST_FAIL";
export const GENDER_LIST = "GENDER_LIST";

export interface GenderListStateType {
  loading: boolean;
  genderList: GenderList[];
  message: string;
  status: number;
}
export interface GenderListLoading {
  type: typeof GENDER_LIST_LOADING;
}
export interface GenderListSuccess {
  type: typeof GENDER_LIST_SUCCESS;
  genderList: GenderList[];
  message: string;
  status: number;
}
export interface GenderListFail {
  type: typeof GENDER_LIST_FAIL;
  message: string;
  status: number;
}

interface GenderListType {
  type: typeof GENDER_LIST;
  genderList: GenderList[];
  message: string;
  status: number;
}

export type GenderListTypes =
  | GenderListType
  | GenderListLoading
  | GenderListSuccess
  | GenderListFail;
// Relationship list
export const RELATIONSHIP_LIST_LOADING = "RELATIONSHIP_LIST_LOADING";
export const RELATIONSHIP_LIST_SUCCESS = "RELATIONSHIP_LIST_SUCCESS";
export const RELATIONSHIP_LIST_FAIL = "RELATIONSHIP_LIST_FAIL";
export const RELATIONSHIP_LIST = "RELATIONSHIP_LIST";

export interface RelationshipListStateType {
  loading: boolean;
  relationList: RelationshipList[];
  message: string;
  status: number;
}
export interface RelationshipListLoading {
  type: typeof RELATIONSHIP_LIST_LOADING;
}
export interface RelationshipListSuccess {
  type: typeof RELATIONSHIP_LIST_SUCCESS;
  relationList: RelationshipList[];
  message: string;
  status: number;
}
export interface RelationshipListFail {
  type: typeof RELATIONSHIP_LIST_FAIL;
  message: string;
  status: number;
}

interface RelationshipListType {
  type: typeof RELATIONSHIP_LIST;
  relationList: RelationshipList[];
  message: string;
  status: number;
}

export type RelationshipListTypes =
  | RelationshipListType
  | RelationshipListLoading
  | RelationshipListSuccess
  | RelationshipListFail;
// Bloodgroup list
export const BLOODGROUP_LIST_LOADING = "BLOODGROUP_LIST_LOADING";
export const BLOODGROUP_LIST_SUCCESS = "BLOODGROUP_LIST_SUCCESS";
export const BLOODGROUP_LIST_FAIL = "BLOODGROUP_LIST_FAIL";
export const BLOODGROUP_LIST = "BLOODGROUP_LIST";

export interface BloodgroupListStateType {
  loading: boolean;
  bloodGroupList: BloodGroupList[];
  message: string;
  status: number;
}
export interface BloodgroupListLoading {
  type: typeof BLOODGROUP_LIST_LOADING;
}
export interface BloodgroupListSuccess {
  type: typeof BLOODGROUP_LIST_SUCCESS;
  bloodGroupList: BloodGroupList[];
  message: string;
  status: number;
}
export interface BloodgroupListFail {
  type: typeof BLOODGROUP_LIST_FAIL;
  message: string;
  status: number;
}

interface BloodgroupListType {
  type: typeof BLOODGROUP_LIST;
  bloodGroupList: BloodGroupList[];
  message: string;
  status: number;
}

export type BloodgroupTypes =
  | BloodgroupListType
  | BloodgroupListLoading
  | BloodgroupListSuccess
  | BloodgroupListFail;
// Marital Status List
export const MARITAL_STATUS_LIST_LOADING = "MARITAL_STATUS_LIST_LOADING";
export const MARITAL_STATUS_LIST_SUCCESS = "MARITAL_STATUS_LIST_SUCCESS";
export const MARITAL_STATUS_LIST_FAIL = "MARITAL_STATUS_LIST_FAIL";
export const MARITAL_STATUS_LIST = "MARITAL_STATUS_LIST";

export interface MaritalStatusListStateType {
  loading: boolean;
  maritialStatusList: BloodGroupList[];
  message: string;
  status: number;
}
export interface MaritalStatusListLoading {
  type: typeof MARITAL_STATUS_LIST_LOADING;
}
export interface MaritalStatusListSuccess {
  type: typeof MARITAL_STATUS_LIST_SUCCESS;
  maritialStatusList: BloodGroupList[];
  message: string;
  status: number;
}
export interface MaritalStatusListFail {
  type: typeof MARITAL_STATUS_LIST_FAIL;
  message: string;
  status: number;
}

interface MaritalStatusListType {
  type: typeof MARITAL_STATUS_LIST;
  maritialStatusList: BloodGroupList[];
  message: string;
  status: number;
}

export type MaritalStatusTypes =
  | MaritalStatusListType
  | MaritalStatusListLoading
  | MaritalStatusListSuccess
  | MaritalStatusListFail;
// Sexual Orientation List
export const SEXUAL_ORIENTATION_LIST_LOADING = "SEXUAL_ORIENTATION_LIST_LOADING";
export const SEXUAL_ORIENTATION_LIST_SUCCESS = "SEXUAL_ORIENTATION_LIST_SUCCESS";
export const SEXUAL_ORIENTATION_LIST_FAIL = "SEXUAL_ORIENTATION_LIST_FAIL";
export const SEXUAL_ORIENTATION_LIST = "SEXUAL_ORIENTATION_LIST";

export interface SexualOrientationListStateType {
  loading: boolean;
  sexualOrientationList: SexualOrientationList[];
  message: string;
  status: number;
}
export interface SexualOrientationListLoading {
  type: typeof SEXUAL_ORIENTATION_LIST_LOADING;
}
export interface SexualOrientationListSuccess {
  type: typeof SEXUAL_ORIENTATION_LIST_SUCCESS;
  sexualOrientationList: SexualOrientationList[];
  message: string;
  status: number;
}
export interface SexualOrientationListFail {
  type: typeof SEXUAL_ORIENTATION_LIST_FAIL;
  message: string;
  status: number;
}

interface SexualOrientationListType {
  type: typeof SEXUAL_ORIENTATION_LIST;
  sexualOrientationList: SexualOrientationList[];
  message: string;
  status: number;
}

export type SexualOrientationTypes =
  | SexualOrientationListType
  | SexualOrientationListLoading
  | SexualOrientationListSuccess
  | SexualOrientationListFail;
// Language List
export const LANGUAGE_LIST_LOADING = "LANGUAGE_LIST_LOADING";
export const LANGUAGE_ORIENTATION_LIST_SUCCESS = "LANGUAGE_ORIENTATION_LIST_SUCCESS";
export const LANGUAGE_ORIENTATION_LIST_FAIL = "LANGUAGE_ORIENTATION_LIST_FAIL";
export const LANGUAGE_ORIENTATION_LIST = "LANGUAGE_ORIENTATION_LIST";

export interface LanguageListStateType {
  loading: boolean;
  languageList: LanguageList[];
  message: string;
  status: number;
}
export interface LanguageListLoading {
  type: typeof LANGUAGE_LIST_LOADING;
}
export interface LanguageListSuccess {
  type: typeof LANGUAGE_ORIENTATION_LIST_SUCCESS;
  languageList: LanguageList[];
  message: string;
  status: number;
}
export interface LanguageListFail {
  type: typeof LANGUAGE_ORIENTATION_LIST_FAIL;
  message: string;
  status: number;
}

interface LanguageListType {
  type: typeof LANGUAGE_ORIENTATION_LIST;
  languageList: LanguageList[];
  message: string;
  status: number;
}

export type LanguageListTypes =
  | LanguageListType
  | LanguageListLoading
  | LanguageListSuccess
  | LanguageListFail;
// Language List
export const ADD_OR_UPDATE_HEALTH_RECORD_LOADING = "ADD_OR_UPDATE_HEALTH_RECORD_LOADING";
export const ADD_OR_UPDATE_HEALTH_RECORD_SUCCESS = "ADD_OR_UPDATE_HEALTH_RECORD_SUCCESS";
export const ADD_OR_UPDATE_HEALTH_RECORD_FAIL = "ADD_OR_UPDATE_HEALTH_RECORD_FAIL";
export const ADD_OR_UPDATE_HEALTH_RECORD_TYPE = "ADD_OR_UPDATE_HEALTH_RECORD_TYPE";

export interface AddOrUpdateHealthRecordStateType {
  loading: boolean;
  message: string;
  status: number;
}
export interface AddOrUpdateHealthRecordLoading {
  type: typeof ADD_OR_UPDATE_HEALTH_RECORD_LOADING;
}
export interface AddOrUpdateHealthRecordSuccess {
  type: typeof ADD_OR_UPDATE_HEALTH_RECORD_SUCCESS;
  message: string;
  status: number;
}
export interface AddOrUpdateHealthRecordFail {
  type: typeof ADD_OR_UPDATE_HEALTH_RECORD_FAIL;
  message: string;
  status: number;
}

interface AddOrUpdateHealthRecordType {
  type: typeof ADD_OR_UPDATE_HEALTH_RECORD_TYPE;
  message: string;
  status: number;
}

export type AddOrUpdateHealthRecordTypes =
  | AddOrUpdateHealthRecordType
  | AddOrUpdateHealthRecordLoading
  | AddOrUpdateHealthRecordSuccess
  | AddOrUpdateHealthRecordFail;
// Language List
export const GET_HEALTH_RECORD_LOADING = "GET_HEALTH_RECORD_LOADING";
export const GET_HEALTH_RECORD_SUCCESS = "GET_HEALTH_RECORD_SUCCESS";
export const GET_HEALTH_RECORD_FAIL = "GET_HEALTH_RECORD_FAIL";
export const GET_HEALTH_RECORD_TYPE = "GET_HEALTH_RECORD_TYPE";

export interface GetHealthRecordStateType {
  loading: boolean;
  personalDetails: PersonalDetails;
  message: string;
  status: number;
}
export interface GetHealthRecordLoading {
  type: typeof GET_HEALTH_RECORD_LOADING;
}
export interface GetHealthRecordSuccess {
  type: typeof GET_HEALTH_RECORD_SUCCESS;
  personalDetails: PersonalDetails;
  message: string;
  status: number;
}
export interface GetHealthRecordFail {
  type: typeof GET_HEALTH_RECORD_FAIL;
  message: string;
  status: number;
}

interface GetHealthRecordType {
  type: typeof GET_HEALTH_RECORD_TYPE;
  personalDetails: PersonalDetails;
  message: string;
  status: number;
}

export type GetHealthRecordTypes =
  | GetHealthRecordType
  | GetHealthRecordLoading
  | GetHealthRecordSuccess
  | GetHealthRecordFail;
