import {
  GET_LIST,
  DoctorActionTypes,
  PatientActionTypes,
  GET_PATIENT_LIST,
  GET_SHARED_PATIENT_LIST,
  CaseGetActionTypes,
  GET_CASE_LIST,
  ChiefComplaintGetActionTypes,
  GET_CC_LIST,
  AddChiefComplaintTypes,
  ADD_CC,
  QuestionGetActionTypes,
  GET_QUESTION_LIST,
  DELETE_DOCUMENT,
  DeleteDocumentTypes,
  DeletePatientTypes,
  DELETE_PATIENT,
  SharedPatientActionTypes,
  TaskGetActionTypes,
  GET_TASK_LIST,
  OwnerAppoinmentListActionTypes,
  OWNER_APPOINMENT_LIST,
  AdminGetOrganizationListActionTypes,
  GET_ORGANIZATION_LIST,
  OrganizationPatientGetActionTypes,
  GET_ORGANIZATIONPATIENT_LIST,
} from "../types/ActionTypes";

import {
  CaseHistory,
  CaseList,
  ChiefComplaintList,
  DoctorList,
  PatientList,
  SharedPatientList,
  QuestionList,
  TaskList,
  OwnerAppoinmentList,
  AdminOrganizationList,
  OrganizationPatientList,
} from "../interfaces/Model";

export const getDoctorAction = (doctors: DoctorList[]): DoctorActionTypes => {
  return {
    type: GET_LIST,
    payload: doctors,
  };
};

export const getPatientAction = (patients: PatientList[]): PatientActionTypes => {
  return {
    type: GET_PATIENT_LIST,
    payload: patients,
  };
};

export const getSharedPatientAction = (patients: SharedPatientList[]): SharedPatientActionTypes => {
  return {
    type: GET_SHARED_PATIENT_LIST,
    payload: patients,
  };
};

export const getOrganizationListAction = (
  organizationlist: AdminOrganizationList[],
): AdminGetOrganizationListActionTypes => {
  return {
    type: GET_ORGANIZATION_LIST,
    payload: organizationlist,
  };
};

export const getOrganizationPatientAction = (
  organizationpatient: OrganizationPatientList,
): OrganizationPatientGetActionTypes => {
  return {
    type: GET_ORGANIZATIONPATIENT_LIST,
    payload: organizationpatient,
  };
};

export const getCaseAction = (cases: CaseList[], patientName: CaseHistory): CaseGetActionTypes => {
  return {
    type: GET_CASE_LIST,
    payload: cases,
    patientname: patientName,
  };
};

export const getQuestionAction = (question: QuestionList[]): QuestionGetActionTypes => {
  return {
    type: GET_QUESTION_LIST,
    payload: question,
  };
};

export const getChiefComplaintAction = (cc: ChiefComplaintList[]): ChiefComplaintGetActionTypes => {
  return {
    type: GET_CC_LIST,
    payload: cc,
  };
};

export const AddChiefComplaintAction = (addCC: any): AddChiefComplaintTypes => {
  return {
    type: ADD_CC,
    payload: addCC,
  };
};

export const DeleteDocumentAction = (deletedocument: any): DeleteDocumentTypes => {
  return {
    type: DELETE_DOCUMENT,
    payload: deletedocument,
  };
};

export const DeletePatientAction = (deletepatient: any): DeletePatientTypes => {
  return {
    type: DELETE_PATIENT,
    payload: deletepatient,
  };
};
export const getTaskAction = (tasks: TaskList[]): TaskGetActionTypes => {
  return {
    type: GET_TASK_LIST,
    payload: tasks,
  };
};
export const getOwnerAppoinmentAction = (
  ownerAppoinment: OwnerAppoinmentList[],
): OwnerAppoinmentListActionTypes => {
  return {
    type: OWNER_APPOINMENT_LIST,
    payload: ownerAppoinment,
  };
};
