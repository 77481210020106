import axios from "axios";
import { Dispatch } from "redux";
import Url from "../../../Url";
import { UpdatePatientDataDetailsActionTypes } from "./ActionTypes";
import { UpdatePatientDataDetailsBody, UpdatePatientDataDetailsRes } from "./Model";
import {
  updatePatientDataPointAPIResClearAction,
  updatePatientDataPointErrorAction,
  updatePatientDataPointLoadingAction,
  updatePatientDataPointSuccessAction,
  updatePatientDataPointUpdateAPIMsgAction,
} from "./Actions";
import { headers } from "components/Utility";

let apiRes = {} as UpdatePatientDataDetailsRes;
export const updatePatientDataDetailsApi = (payload: UpdatePatientDataDetailsBody) => {
  return function (dispatch: Dispatch<UpdatePatientDataDetailsActionTypes>) {
    dispatch(updatePatientDataPointLoadingAction(true));
    axios
      .post(Url.apiURL + `/adminapp/api/patientDetails/edit_patientDetails`, payload, {
        headers: headers,
      })
      .then((res) => {
        apiRes = res.data;
        dispatch(updatePatientDataPointLoadingAction(false));
        dispatch(updatePatientDataPointSuccessAction(res.data));
      })
      .catch((error) => {
        dispatch(updatePatientDataPointLoadingAction(false));
        dispatch(
          updatePatientDataPointErrorAction(
            {} as UpdatePatientDataDetailsRes,
            error.response !== undefined
              ? error.response.data.message || error.response.data.detail
              : "Something went wrong",
            error.status !== undefined ? error.response.data.status || -1 : -1,
          ),
        );
      });
  };
};

export const updatePatientDataDetailsEditAPIResMsg = () => {
  return function (dispatch: Dispatch<UpdatePatientDataDetailsActionTypes>) {
    dispatch(
      updatePatientDataPointUpdateAPIMsgAction(apiRes as UpdatePatientDataDetailsRes, "", 0),
    );
  };
};

export const clearPatientDataPointEditAPIRes = () => {
  return function (dispatch: Dispatch<UpdatePatientDataDetailsActionTypes>) {
    dispatch(updatePatientDataPointAPIResClearAction({} as UpdatePatientDataDetailsRes, "", 0));
  };
};
