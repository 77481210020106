import {
  ADD_DOCTOR_FOR_CASE_FAIL,
  ADD_DOCTOR_FOR_CASE_LOADING,
  ADD_DOCTOR_FOR_CASE_SUCCESS,
  ADD_DOCTOR_FOR_CASE_UPDATE_API_MSG,
  ADD_DOCTOR_FOR_CASE_UPDATE_API_RES,
  AddDoctorForCaseActionTypes,
} from "./ActionTypes";
import { AddDoctorForCaseRes } from "./Model";

export const addDoctorForCaseLoadingAction = (loading: boolean): AddDoctorForCaseActionTypes => {
  return {
    type: ADD_DOCTOR_FOR_CASE_LOADING,
    loading: loading,
  };
};

export const addDoctorForCaseSuccessAction = (
  addDoctorForCaseResponse: AddDoctorForCaseRes,
  successMsg: string,
): AddDoctorForCaseActionTypes => {
  return {
    type: ADD_DOCTOR_FOR_CASE_SUCCESS,
    payload: addDoctorForCaseResponse,
    successMsg: successMsg,
  };
};

export const addDoctorForCaseErrorAction = (
  addDoctorForCaseResponse: AddDoctorForCaseRes,
  errMsg: string,
  status: number,
): AddDoctorForCaseActionTypes => {
  return {
    type: ADD_DOCTOR_FOR_CASE_FAIL,
    payload: addDoctorForCaseResponse,
    errorMsg: errMsg,
    status: status,
  };
};
export const addDoctorForCaseUpdateAPIMsgAction = (
  addDoctorForCaseResponse: AddDoctorForCaseRes,
  successMsg: string,
  errMsg: string,
  status: number,
): AddDoctorForCaseActionTypes => {
  return {
    type: ADD_DOCTOR_FOR_CASE_UPDATE_API_MSG,
    payload: addDoctorForCaseResponse,
    successMsg: successMsg,
    errorMsg: errMsg,
    status: status,
  };
};

export const addDoctorForCaseAPIResClearAction = (
  addDoctorForCaseResponse: AddDoctorForCaseRes,
  successMsg: string,
  errMsg: string,
  status: number,
): AddDoctorForCaseActionTypes => {
  return {
    type: ADD_DOCTOR_FOR_CASE_UPDATE_API_RES,
    payload: addDoctorForCaseResponse,
    successMsg: successMsg,
    errorMsg: errMsg,
    status: status,
  };
};
