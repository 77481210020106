export type EHRDataType = {
  name: string;
  alias: string;
  org: string;
};
export const DataList = [
  {
    name: "Arnold Palmer Hospital for Children and Women",
    alias: "Arnold",
    org: "Mychart",
  },
  {
    name: "Bascom Palmer Anne Bates",
    alias: "Bascom",
    org: "Mychart",
  },
  {
    name: "Bellevue Hospital Center",
    alias: "Bellevue",
    org: "Mychart",
  },
  {
    name: "Boston Children's Hospital",
    alias: "Boston",
    org: "Mychart",
  },
  {
    name: "California Pacific Medical Center",
    alias: "California",
    org: "Mychart",
  },
  {
    name: "Cleveland Clinic Hospital",
    alias: "Cleveland",
    org: "Mychart",
  },
] as EHRDataType[];
