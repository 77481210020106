import {
  GetDoctorTeamTypeForCaseActionTypes,
  GET_DOCTOR_TEAM_TYPE_FAIL,
  GET_DOCTOR_TEAM_TYPE_LOADING,
  GET_DOCTOR_TEAM_TYPE_SUCCESS,
  GetDoctorTeamTypeForCaseState,
  GET_DOCTOR_TEAM_TYPE_UPDATE_API_MSG,
  GET_DOCTOR_TEAM_TYPE_UPDATE_API_RES,
} from "./ActionTypes";
import { GetDoctorTeamTypeForCaseRes } from "./Model";

const initialStateGetPosts: GetDoctorTeamTypeForCaseState = {
  loading: false,
  getDoctorTeamTypeForCaseRes: {} as GetDoctorTeamTypeForCaseRes,
  successMsg: "",
  errorMsg: "",
  status: 0,
};
export const getDoctorTeamTypeForCaseReducer = (
  state = initialStateGetPosts,
  action: GetDoctorTeamTypeForCaseActionTypes,
): GetDoctorTeamTypeForCaseState => {
  switch (action.type) {
    case GET_DOCTOR_TEAM_TYPE_LOADING:
      return {
        ...state,
        loading: action.loading,
      };
    case GET_DOCTOR_TEAM_TYPE_SUCCESS:
      return {
        ...state,
        getDoctorTeamTypeForCaseRes: action.payload,
        successMsg: action.successMsg,
      };
    case GET_DOCTOR_TEAM_TYPE_FAIL:
      return {
        ...state,
        getDoctorTeamTypeForCaseRes: action.payload,
        errorMsg: action.errorMsg,
        status: action.status,
      };
    case GET_DOCTOR_TEAM_TYPE_UPDATE_API_MSG:
      return {
        ...state,
        getDoctorTeamTypeForCaseRes: action.payload,
        successMsg: action.successMsg,
        errorMsg: action.errorMsg,
        status: action.status,
      };
    case GET_DOCTOR_TEAM_TYPE_UPDATE_API_RES:
      return {
        ...state,
        getDoctorTeamTypeForCaseRes: action.payload,
        successMsg: action.successMsg,
        errorMsg: action.errorMsg,
        status: action.status,
      };
    default:
      return state;
  }
};
