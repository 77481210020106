import React, { useEffect, useState } from "react";
import { TextField } from "@mui/material";
import { DesktopDatePicker, LocalizationProvider } from "@mui/x-date-pickers";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import dayjs from "dayjs";

interface Props {
  label: string;
  dateState?: any;
  disabledValue: boolean;
  selectedDateValue?: Date | null;
}

const MWDatePicker: React.FC<Props> = ({ label, dateState, disabledValue, selectedDateValue }) => {
  const [labelValue, setLabel] = useState("");
  const [disabled, setDisabled] = useState(false);
  const [selectedDate, setSelectedDate] = React.useState<Date | null>(new Date());
  const handleDateChange = (date: Date | null) => {
    setSelectedDate(date);
  };

  useEffect(() => {
    setLabel(label);
    setDisabled(disabledValue);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [label, disabledValue]);

  useEffect(() => {
    if (selectedDateValue !== null || selectedDateValue !== undefined) {
      if (selectedDateValue !== null) {
        if (selectedDateValue !== new Date()) {
          setSelectedDate(selectedDateValue!);
        }
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    dateState(dayjs(selectedDate).format("YYYY-MM-DDTHH:mm:Z"));
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [selectedDate]);

  return (
    <div>
      <LocalizationProvider dateAdapter={AdapterDayjs}>
        <DesktopDatePicker
          inputFormat="DD/MM/YYYY"
          value={selectedDate}
          onChange={(date) => handleDateChange(date)}
          renderInput={(params: any) => (
            <TextField
              {...params}
              fullWidth
              inputProps={{ ...params.inputProps }}
              size="small"
              variant="outlined"
              label={labelValue}
            />
          )}
          InputAdornmentProps={{ position: "start" }}
          disabled={disabled}
        />
      </LocalizationProvider>
    </div>
  );
};

export default MWDatePicker;
