import axios from "axios";
import { Dispatch } from "redux";
import Url from "../../../../Url";
import { GetDoctorTeamTypeForCaseActionTypes } from "./ActionTypes";
import { GetDoctorTeamTypeForCaseBody, GetDoctorTeamTypeForCaseRes } from "./Model";
import {
  getDoctorTeamTypeForCaseAPIResClearAction,
  getDoctorTeamTypeForCaseErrorAction,
  getDoctorTeamTypeForCaseLoadingAction,
  getDoctorTeamTypeForCaseSuccessAction,
  getDoctorTeamTypeForCaseUpdateAPIMsgAction,
} from "./Actions";
import { headers } from "components/Utility";

let apiRes = {} as GetDoctorTeamTypeForCaseRes;
export const getDoctorTeamTypeForCase = (payload: GetDoctorTeamTypeForCaseBody) => {
  return function (dispatch: Dispatch<GetDoctorTeamTypeForCaseActionTypes>) {
    dispatch(getDoctorTeamTypeForCaseLoadingAction(true));
    axios
      .post(
        Url.apiURL + `/patientapp/api/patientMedicalData/doctorTeamType_for_patientCase`,
        payload,
        { headers: headers },
      )
      .then((res) => {
        apiRes = res.data;
        dispatch(getDoctorTeamTypeForCaseLoadingAction(false));
        dispatch(
          getDoctorTeamTypeForCaseSuccessAction(
            res.data,
            res.data.message !== undefined
              ? res.data.message
              : "Doctor team type list has been fetched successfully",
          ),
        );
      })
      .catch((error) => {
        dispatch(getDoctorTeamTypeForCaseLoadingAction(false));
        dispatch(
          getDoctorTeamTypeForCaseErrorAction(
            {} as GetDoctorTeamTypeForCaseRes,
            error.response !== undefined
              ? error.response.data.message || error.response.data.detail
              : "Something went wrong",
            error.status !== undefined ? error.response.data.status || -1 : -1,
          ),
        );
      });
  };
};

export const updateGetDoctorTeamTypeAPIResMsg = () => {
  return function (dispatch: Dispatch<GetDoctorTeamTypeForCaseActionTypes>) {
    dispatch(
      getDoctorTeamTypeForCaseUpdateAPIMsgAction(apiRes as GetDoctorTeamTypeForCaseRes, "", "", 0),
    );
  };
};

export const clearGetDoctorTeamTypeForCaseAPIRes = () => {
  return function (dispatch: Dispatch<GetDoctorTeamTypeForCaseActionTypes>) {
    dispatch(
      getDoctorTeamTypeForCaseAPIResClearAction({} as GetDoctorTeamTypeForCaseRes, "", "", 0),
    );
  };
};
