import { CategoryResponse } from "./Model";

export const CATEGORY_LIST_LOADING = "CATEGORY_LIST_LOADING";
export const CATEGORY_LIST_SUCCESS = "CATEGORY_LIST_SUCCESS";
export const CATEGORY_LIST_FAIL = "CATEGORY_LIST_FAIL";
export const CATEGORY_LIST = "CATEGORY_LIST";

export interface categoryDetails {
  doctorCategoryList: CategoryResponse;
  loading: boolean;
  errRes: string;
}
export interface categoryLoading {
  type: typeof CATEGORY_LIST_LOADING;
}
export interface categorySuccess {
  type: typeof CATEGORY_LIST_SUCCESS;
  payload: CategoryResponse;
  errRes: string;
}
export interface categoryFail {
  type: typeof CATEGORY_LIST_FAIL;
  errRes: string;
}

interface categoryAction {
  type: typeof CATEGORY_LIST;
  payload: CategoryResponse;
  errRes: string;
}

export type categoryActionTypes = categoryAction | categoryLoading | categorySuccess | categoryFail;
