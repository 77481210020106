import React from "react";
import {} from "react-router-dom";
import "react-big-calendar/lib/css/react-big-calendar.css";
import RefreshIcon from "@mui/icons-material/Refresh";

import { t } from "i18next";
import { Typography, Card, CardHeader, Grid, Button } from "@mui/material";
import PageLayout from "components/PageLayout";
import DeleteAccountRequestList from "./DeleteAccountRequestList";
export default function Settings() {
  const pageBody = (
    <Card>
      <CardHeader
        title={
          <Grid container direction="row" justifyContent="space-between" alignItems="flex-start">
            <Grid item>
              <Typography variant="h6"> {t("settings.usersetin", { ns: ["home"] })!}</Typography>
            </Grid>
            <Grid item>
              <Button variant="outlined" startIcon={<RefreshIcon />}>
                {t("settings.reload", { ns: ["home"] })!}
              </Button>
            </Grid>
          </Grid>
        }
      />
    </Card>
  );

  return (
    <PageLayout>
      <Typography variant="h5" sx={{ fontWeight: "bold", mb: 2 }}>
        {t("settings.seting", { ns: ["home"] })!}
      </Typography>
      {pageBody}
      <DeleteAccountRequestList/>
    </PageLayout>
  );
}
