import React, { useState, useEffect, useCallback } from "react";
import {} from "react-router-dom";
import "react-big-calendar/lib/css/react-big-calendar.css";
import VerifiedIcon from "@mui/icons-material/Verified";
import RefreshIcon from "@mui/icons-material/Refresh";
import PaymentIcon from "@mui/icons-material/Payment";
import { useDispatch, useSelector } from "react-redux";
import { AppState } from "../../redux/store/Store";
import { getPaymentListByOwner } from "../../redux/payment/AppointmentPaymentList/API";
import { CheckPaymentStatusReq } from "../../redux/payment/CheckPaymentStatusById/Model";
import { checkPaymentStatusById } from "../../redux/payment/CheckPaymentStatusById/API";
import { t } from "i18next";
import {
  Container,
  Typography,
  Box,
  Card,
  CardHeader,
  CardContent,
  TableContainer,
  Table,
  TableHead,
  TableRow,
  TableCell,
  TableBody,
  Grid,
  Button,
  Snackbar,
  Alert,
} from "@mui/material";
import MWLoader from "../../components/MWLoader";
import MWExceptionList from "../../components/MWExceptionList";

export default function Payment() {
  const loadingMarkup = <MWLoader />;
  const [loading, setIsLoading] = React.useState<boolean | undefined>(false);
  const [paymentList, setPaymentList] = useState<any[]>([]);
  const [toastSuccessMessage, setToastSuccessMessage] = useState("");
  const [toastSuccessActive, setToastSuccessActive] = useState(false);
  const toggleToastSuccessActive = useCallback(
    () => setToastSuccessActive((toastSuccessActive) => !toastSuccessActive),
    [],
  );

  const toastSuccessMarkup = toastSuccessActive ? (
    <Snackbar open={toastSuccessActive} autoHideDuration={6000} onClose={toggleToastSuccessActive}>
      <Alert onClose={toggleToastSuccessActive} severity="success" sx={{ width: "100%" }}>
        {toastSuccessMessage}
      </Alert>
    </Snackbar>
  ) : null;

  const [toastErrorMessage, setToastErrorMessage] = useState("");
  const [toastErrorActive, setToastErrorActive] = useState(false);
  const toggleToastErrorActive = useCallback(
    () => setToastErrorActive((toastErrorActive) => !toastErrorActive),
    [],
  );

  const toastErrorMarkup = toastErrorActive ? (
    <Snackbar open={toastErrorActive} autoHideDuration={6000} onClose={toggleToastErrorActive}>
      <Alert onClose={toggleToastErrorActive} severity="error" sx={{ width: "100%" }}>
        {toastErrorMessage}
      </Alert>
    </Snackbar>
  ) : null;
  const DATE_OPTIONS = {
    weekday: "short",
    year: "numeric",
    month: "short",
    day: "numeric",
  } as const;
  function paymentLink(response: any) {
    window.open(response);
  }
  // const [paymentDetailsId, setPaymentDetailsId] = useState('');
  const checkPaymentStatusDetails = (id: number) => {
    const checkPaymentStatusByIdBody = {
      paymentId: String(id),
    } as CheckPaymentStatusReq;
    dispatch(checkPaymentStatusById(checkPaymentStatusByIdBody));
  };

  const dispatch = useDispatch();
  useEffect(() => {
    dispatch(getPaymentListByOwner());
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const getPaymentListByOwnerRes = useSelector((state: AppState) => state.getPaymentListByOwner);
  useEffect(() => {
    getPaymentListByOwnerRes?.loading ? setIsLoading(true) : setIsLoading(false);

    if (getPaymentListByOwnerRes?.getPaymentListRes?.paymentList !== undefined) {
      let paymentListData = getPaymentListByOwnerRes?.getPaymentListRes?.paymentList;
      let paymentsValueList = [];
      for (var i = 0; i < paymentListData.length; i++) {
        let paymentId = paymentListData[i].id;
        let selectedResponse = paymentListData[i].paymentLink;
        let description =
          paymentListData[i].note === ""
            ? `${t("docDetailPage.noDetailAvail", { ns: ["home"] })}`
            : paymentListData[i].note;
        let amount =
          paymentListData[i].amount === ""
            ? `${t("payment.amtUnavail", { ns: ["home"] })}`
            : paymentListData[i].amount;
        let round_amount = Number(amount) / 100;
        let createDate =
          paymentListData[i].create_date === null
            ? ""
            : new Date(paymentListData[i].create_date).toLocaleDateString("en-US", DATE_OPTIONS);
        let appoinmentPaidTime = paymentListData[i].hasOwnProperty("paidTime")
          ? paymentListData[i].paidTime === "" || paymentListData[i].paidTime === null
            ? ""
            : new Date(paymentListData[i].paidTime).toLocaleDateString("en-US", DATE_OPTIONS) +
              " at " +
              new Date(paymentListData[i].paidTime).getHours() +
              ":" +
              new Date(paymentListData[i].paidTime).getMinutes()
          : "";
        let paymentstatus = paymentListData[i].status === null ? "" : paymentListData[i].status;
        paymentsValueList.push([
          description,
          createDate,
          "Rs. " + round_amount,
          <Button
            variant="outlined"
            onClick={() => paymentLink(selectedResponse)}
            startIcon={<PaymentIcon />}
          >
            Pay Now
          </Button>,
          appoinmentPaidTime,
          <Typography>{paymentstatus}</Typography>,
          <Button
            variant="outlined"
            onClick={() => checkPaymentStatusDetails(paymentId)}
            disabled={paymentstatus === "paid" || paymentstatus === "free" ? true : false}
            startIcon={<VerifiedIcon />}
          ></Button>,
        ]);
      }
      setPaymentList(paymentsValueList);
    } else setPaymentList([]);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [getPaymentListByOwnerRes]);
  const checkPaymentStatusByIdRes = useSelector((state: AppState) => state.checkPaymentStatusById);
  useEffect(() => {
    checkPaymentStatusByIdRes?.loading ? setIsLoading(true) : setIsLoading(false);
    checkPaymentStatusByIdRes?.checkPaymentStatusRes?.message !== undefined
      ? setToastSuccessMessage(checkPaymentStatusByIdRes?.checkPaymentStatusRes?.message)
      : setToastSuccessMessage("");
    if (checkPaymentStatusByIdRes?.checkPaymentStatusRes?.message !== undefined) {
      toggleToastSuccessActive();
      dispatch(getPaymentListByOwner());
    }
    checkPaymentStatusByIdRes?.errRes !== ""
      ? setToastErrorMessage(checkPaymentStatusByIdRes?.errRes)
      : setToastErrorMessage("");
    if (checkPaymentStatusByIdRes?.errRes !== "") {
      toggleToastErrorActive();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [checkPaymentStatusByIdRes]);
  const pageBody = (
    <Card>
      <CardHeader
        title={
          <Grid container direction="row" justifyContent="flex-end" alignItems="flex-end">
            <Grid item>
              <Button
                variant="outlined"
                startIcon={<RefreshIcon />}
                onClick={() => dispatch(getPaymentListByOwner())}
              >
                {t("payment.reloadPayList", { ns: ["home"] })!}
              </Button>
            </Grid>
          </Grid>
        }
      />
      <CardContent>
        <Box pl={3}>
          {paymentList && paymentList.length ? (
            <TableContainer>
              <Table size="small" aria-label="a dense table">
                <TableHead>
                  <TableRow>
                    <TableCell>{t("appointment.desc", { ns: ["home"] })}</TableCell>
                    <TableCell>{t("payment.cdate", { ns: ["home"] })}</TableCell>
                    <TableCell>{t("payment.amt", { ns: ["home"] })}</TableCell>
                    <TableCell>{t("payment.pay", { ns: ["home"] })}</TableCell>
                    <TableCell>{t("payment.paidon", { ns: ["home"] })}</TableCell>
                    <TableCell>{t("payment.payStat", { ns: ["home"] })}</TableCell>
                    <TableCell>{t("payment.chStat", { ns: ["home"] })}</TableCell>
                  </TableRow>
                </TableHead>
                <TableBody>
                  {paymentList.map((row: any) => (
                    <TableRow
                      key={row.description}
                      sx={{
                        "&:last-child td, &:last-child th": { border: 0 },
                      }}
                    >
                      <TableCell align="left">{row[0]}</TableCell>
                      <TableCell align="left">{row[1]}</TableCell>
                      <TableCell align="left">{row[2]}</TableCell>
                      <TableCell align="left">{row[3]}</TableCell>
                      <TableCell align="left">{row[4]}</TableCell>
                      <TableCell align="left">{row[5]}</TableCell>
                      <TableCell align="left">{row[6]}</TableCell>
                      <TableCell align="left">{row[7]}</TableCell>
                    </TableRow>
                  ))}
                </TableBody>
              </Table>
            </TableContainer>
          ) : (
            <MWExceptionList />
          )}
        </Box>
      </CardContent>
    </Card>
  );

  return (
    <Box>
      {loading ? loadingMarkup : null}
      <Container maxWidth="xl" sx={{ paddingTop: 2 }}>
        <Typography variant="h5" sx={{ fontWeight: "bold", mb: 2 }}>
          {t("payment.payStat", { ns: ["home"] })}
        </Typography>
        {pageBody}
        {toastSuccessMarkup}
        {toastErrorMarkup}
      </Container>
    </Box>
  );
}
