import { ImageUploadResponse } from "./Model";

export const IMAGE_UPLOAD_LOADING = "IMAGE_UPLOAD_LOADING";
export const IMAGE_UPLOAD_SUCCESS = "IMAGE_UPLOAD_SUCCESS";
export const IMAGE_UPLOAD_PROGRESS = "IMAGE_UPLOAD_PROGRESS";
export const IMAGE_UPLOAD_FAIL = "IMAGE_UPLOAD_FAIL";
export const IMAGE_UPLOAD = "IMAGE_UPLOAD";
export const IMAGE_UPLOAD_API_MSG = "IMAGE_UPLOAD_API_MSG";
export const IMAGE_UPLOAD_API_RES = "IMAGE_UPLOAD_API_RES";

export interface ImageUploadDetails {
  imageUploadResponse: ImageUploadResponse;
  progress: number;
  loading: boolean;
  successMsg: string;
  errMsg: string;
  status: string;
}
export interface ImageUploadLoading {
  type: typeof IMAGE_UPLOAD_LOADING;
  loading: boolean;
}
export interface ImageUploadSuccess {
  type: typeof IMAGE_UPLOAD_SUCCESS;
  payload: ImageUploadResponse;
  successMsg: string;
  status: string;
}
export interface ImageUploadProgress {
  type: typeof IMAGE_UPLOAD_PROGRESS;
  progress: number;
}
export interface ImageUploadFail {
  type: typeof IMAGE_UPLOAD_FAIL;
  payload: ImageUploadResponse;
  errMsg: string;
  status: string;
}
export interface ImageUploadUpdateAPIMsg {
  type: typeof IMAGE_UPLOAD_API_MSG;
  payload: ImageUploadResponse;
  successMsg: string;
  errMsg: string;
  status: string;
}
export interface ImageUploadUpdateAPIRes {
  type: typeof IMAGE_UPLOAD_API_RES;
  payload: ImageUploadResponse;
  successMsg: string;
  errMsg: string;
  status: string;
}

export type ImageUploadActionTypes =
  | ImageUploadLoading
  | ImageUploadSuccess
  | ImageUploadProgress
  | ImageUploadFail
  | ImageUploadUpdateAPIMsg
  | ImageUploadUpdateAPIRes;
