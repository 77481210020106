import { GetDoctorCategoryListRes } from "./Model";
export const GET_DOCTOR_CATEGORY_LIST_LOADING = "GET_DOCTOR_CATEGORY_LIST_LOADING";
export const GET_DOCTOR_CATEGORY_LIST_SUCCESS = "GET_DOCTOR_CATEGORY_LIST_SUCCESS";
export const GET_DOCTOR_CATEGORY_LIST_FAIL = "GET_DOCTOR_CATEGORY_LIST_FAIL";
export const GET_DOCTOR_CATEGORY_LIST_UPDATE_API_MSG = "GET_DOCTOR_CATEGORY_LIST_UPDATE_API_MSG";
export const GET_DOCTOR_CATEGORY_LIST_UPDATE_API_RES = "GET_DOCTOR_CATEGORY_LIST_UPDATE_API_RES";

export interface GetDoctorCategoryListState {
  getDoctorCategoryListRes: GetDoctorCategoryListRes;
  loading: boolean;
  successMsg: string;
  errorMsg: string;
  status: number;
}
export interface GetDoctorCategoryListLoading {
  type: typeof GET_DOCTOR_CATEGORY_LIST_LOADING;
  loading: boolean;
}
export interface GetDoctorCategoryListSuccess {
  type: typeof GET_DOCTOR_CATEGORY_LIST_SUCCESS;
  payload: GetDoctorCategoryListRes;
  successMsg: string;
}
export interface GetDoctorCategoryListFail {
  type: typeof GET_DOCTOR_CATEGORY_LIST_FAIL;
  payload: GetDoctorCategoryListRes;
  errorMsg: string;
  status: number;
}
export interface GetDoctorCategoryListUpdateAPIMsg {
  type: typeof GET_DOCTOR_CATEGORY_LIST_UPDATE_API_MSG;
  payload: GetDoctorCategoryListRes;
  successMsg: string;
  errorMsg: string;
  status: number;
}
export interface GetDoctorCategoryListUpdateAPIRes {
  type: typeof GET_DOCTOR_CATEGORY_LIST_UPDATE_API_RES;
  payload: GetDoctorCategoryListRes;
  successMsg: string;
  errorMsg: string;
  status: number;
}

export type GetDoctorCategoryListActionTypes =
  | GetDoctorCategoryListLoading
  | GetDoctorCategoryListSuccess
  | GetDoctorCategoryListFail
  | GetDoctorCategoryListUpdateAPIMsg
  | GetDoctorCategoryListUpdateAPIRes;
