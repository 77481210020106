import * as React from "react";
import { useCallback, useEffect, useState } from "react";
import InformConsent from "./InformConsent";
import ChiefComplaints from "./ChiefComplaints";
import SystemReview from "./SystemsReview";
import PersonalHistory from "./PersonalHistory";
import Importeddata from "./ImportedData";
import IdeasConcernsExpectations from "./IdeasConcernsExpectations";
import Allergy from "./Allergies";
import FamilyHistory from "./FamilyHistory";
import Immunization from "./Immunization";
import ProblemList from "./ProblemList";
import Diagnosis from "./Diagnosis";
import NutritionalHistory from "./NutritionalHistory";
import HealthMaintenanceHistory from "./HealthMaintenanceHistory";
import RiskFactors from "./RiskFactors";
import TestsProceduresAndInvestigations from "./TestsProceduresAndInvestigations";
import MedicationPrescriptions from "./MedicationPrescriptions";
import PastHistory from "./PastHistory";
import MedicalSummary from "./MedicalSummary";
import HistoryOfChiefPresentingComplaint from "./HistoryOfChiefPresentingComplaint";
import TreatmentPlan from "./TreatmentPlan";
import DemographicDetails from "./DemographicDetails";
import { useParams } from "react-router-dom";
import {
  Tabs,
  Tab,
  Typography,
  Grid,
  Paper,
  Box,
  Container,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  Button,
  Alert,
} from "@mui/material";
import { t } from "i18next";

interface TabPanelProps {
  children?: React.ReactNode;
  index: any;
  value: any;
}

function TabPanel(props: TabPanelProps) {
  const { children, value, index, ...other } = props;

  return (
    <Box
      role="tabpanel"
      hidden={value !== index}
      id={`vertical-tabpanel-${index}`}
      aria-labelledby={`vertical-tab-${index}`}
      {...other}
    >
      {value === index && (
        <Box p={3}>
          <Typography>{children}</Typography>
        </Box>
      )}
    </Box>
  );
}

function a11yProps(index: any) {
  return {
    id: `vertical-tab-${index}`,
    "aria-controls": `vertical-tabpanel-${index}`,
  };
}

export default function AddlHealthRecord(_props: any) {
  const { patientid } = useParams() as {
    patientid: string;
  };
  const [tabValue, setTabValue] = React.useState(1);
  const handleTabChange = (_event: React.ChangeEvent<{}>, newValue: number) => {
    setTabValue(newValue);
    localStorage.setItem("selctedprofiletab", String(newValue));
    window.scrollTo(0, 0);
  };
  //information modal fot patient consent.....
  const [active, setActive] = useState(false);
  const handleCosentChange = useCallback(() => setActive(!active), [active]);

  useEffect(() => {
    window.scrollTo(0, 0);
    if (localStorage.getItem("consultationopt") === "Comprehensive ( Recommended )") {
      setTabValue(19);
    } else if (localStorage.getItem("consultationopt") === "Medical Report Based") {
      setTabValue(1);
    }
  }, []);
  // Consent
  const ConsentModal = (
    <Dialog open={active} onClose={handleCosentChange} maxWidth="lg">
      <DialogTitle>
        <Grid
          container
          direction="row"
          justifyContent="space-between"
          alignItems="flex-start"
          spacing={2}
        >
          <Grid item>{t("addHealthRecPage.teleMed", { ns: ["home"] })}</Grid>
        </Grid>
      </DialogTitle>
      <DialogContent>
        <Box
          style={{
            border: "1px #6574c4 solid",
            backgroundColor: "#f0f3fe",
            borderRadius: "3px",
          }}
          p={3}
        >
          <Typography>
            <InformConsent />
          </Typography>
        </Box>
      </DialogContent>
      <DialogActions>
        <Button variant="contained" onClick={handleCosentChange}>
          {t("addHealthRecPage.agree", { ns: ["home"] })}
        </Button>
      </DialogActions>
    </Dialog>
  );
  const FilePreparationTabPageMarkup = (
    <Grid container direction="row" justifyContent="space-between" alignItems="flex-start">
      {/* Vertical Tab List */}
      <Grid
        item
        sx={{
          width: "275px",
        }}
      >
        {patientid ? (
          <Paper
            variant="outlined"
            sx={{
              backgroundColor: "#D1CBF4",
              width: "275px",
              margin: "25px 5px",
            }}
          >
            <Tabs
              orientation="vertical"
              variant="scrollable"
              value={tabValue}
              onChange={handleTabChange}
              aria-label="Patient Health Record"
              indicatorColor="secondary"
              TabIndicatorProps={{
                sx: {
                  backgroundColor: "#0000FF",
                  right: "0",
                },
              }}
              sx={{
                backgroundColor: "#D1CBF4",
                alignItems: "left",
                textTransform: "none",
                fontSize: "1.5rem",
                borderRadius: "4px",
              }}
            >
              <Tab label={t("addpatientpage.demodetail", { ns: ["home"] })} {...a11yProps(0)} />
              <Tab label={t("preCompPage.presComp", { ns: ["home"] })} {...a11yProps(1)} />
              <Tab label={t("ideaPage.ideaTitle", { ns: ["home"] })} {...a11yProps(2)} />
              <Tab label={t("PresentingCompPage.preComp", { ns: ["home"] })} {...a11yProps(3)} />
              <Tab label={t("reviewSysPage.revSys", { ns: ["home"] })} {...a11yProps(4)} />
              <Tab label={t("pastHisPage.pastHis", { ns: ["home"] })} {...a11yProps(5)} />
              <Tab label={t("testProcInvPage.testProcTitle", { ns: ["home"] })} {...a11yProps(6)} />
              <Tab label={t("medOrPresPage.medPres", { ns: ["home"] })} {...a11yProps(7)} />
              <Tab label={t("allergyPage.allergy", { ns: ["home"] })} {...a11yProps(8)} />
              <Tab label={t("immPage.immTitle", { ns: ["home"] })} {...a11yProps(9)} />
              <Tab label={t("famHisPage.famHis", { ns: ["home"] })} {...a11yProps(10)} />
              <Tab label={t("nutriHisPage.nutriHis", { ns: ["home"] })} {...a11yProps(11)} />
              <Tab label={t("perSocHisPage.perSocHis", { ns: ["home"] })} {...a11yProps(12)} />
              <Tab label={t("healthMainPage.healthMain", { ns: ["home"] })} {...a11yProps(13)} />
              <Tab label={t("riskFacPage.riskFac", { ns: ["home"] })} {...a11yProps(14)} />
              <Tab label={t("diagPage.diagTitle", { ns: ["home"] })} {...a11yProps(15)} />
              <Tab label={t("medSumPage.medSum", { ns: ["home"] })} {...a11yProps(16)} />
              <Tab label={t("probListPage.probList", { ns: ["home"] })} {...a11yProps(17)} />
              <Tab label={t("treatmentPage.trtTitle", { ns: ["home"] })} {...a11yProps(18)} />
              <Tab label={t("impDataPage.imptdData", { ns: ["home"] })} {...a11yProps(19)} />
            </Tabs>
          </Paper>
        ) : (
          <Box>
            <Alert>
              <Box sx={{ maxHeight: "340px", overflow: "auto" }}>
                <InformConsent />
              </Box>
            </Alert>
            <Box py="5">
              <Typography>{t("addpatientpage.subcon", { ns: ["home"] })}</Typography>
            </Box>
          </Box>
        )}
      </Grid>
      {/* Vertical Tab Content */}
      <Grid item xs>
        <Box>
          <Grid container direction="row" justifyContent="flex-end" alignItems="center"></Grid>
          {patientid ? (
            <Box>
              <TabPanel value={tabValue} index={0}>
                <DemographicDetails />
              </TabPanel>
              <TabPanel value={tabValue} index={1}>
                <ChiefComplaints />
              </TabPanel>

              <TabPanel value={tabValue} index={2}>
                <IdeasConcernsExpectations />
              </TabPanel>

              <TabPanel value={tabValue} index={3}>
                <HistoryOfChiefPresentingComplaint />
              </TabPanel>

              <TabPanel value={tabValue} index={4}>
                <SystemReview />
              </TabPanel>

              <TabPanel value={tabValue} index={5}>
                <PastHistory />
              </TabPanel>

              <TabPanel value={tabValue} index={6}>
                <TestsProceduresAndInvestigations />
              </TabPanel>

              <TabPanel value={tabValue} index={7}>
                <MedicationPrescriptions />
              </TabPanel>

              <TabPanel value={tabValue} index={8}>
                <Allergy />
              </TabPanel>

              <TabPanel value={tabValue} index={9}>
                <Immunization />
              </TabPanel>

              <TabPanel value={tabValue} index={10}>
                <FamilyHistory />
              </TabPanel>

              <TabPanel value={tabValue} index={11}>
                <NutritionalHistory />
              </TabPanel>

              <TabPanel value={tabValue} index={12}>
                <PersonalHistory />
              </TabPanel>

              <TabPanel value={tabValue} index={13}>
                <HealthMaintenanceHistory />
              </TabPanel>

              <TabPanel value={tabValue} index={14}>
                <RiskFactors />
              </TabPanel>

              <TabPanel value={tabValue} index={15}>
                <Diagnosis />
              </TabPanel>

              <TabPanel value={tabValue} index={16}>
                <MedicalSummary />
              </TabPanel>

              <TabPanel value={tabValue} index={17}>
                <ProblemList />
              </TabPanel>

              <TabPanel value={tabValue} index={18}>
                <TreatmentPlan />
              </TabPanel>

              <TabPanel value={tabValue} index={19}>
                <Importeddata />
              </TabPanel>
            </Box>
          ) : (
            ""
          )}
        </Box>
      </Grid>
    </Grid>
  );

  return (
    <Container maxWidth="xl">
      {FilePreparationTabPageMarkup}
      {ConsentModal}
    </Container>
  );
}
