import React, { useState, useCallback, useEffect } from "react";
import { AddFavoritePatientBody } from "../redux/addFavoritePatient/Model";
import { addFavoritePatient } from "../redux/addFavoritePatient/API";
import PatientFlow from "../image/design-assets/patient-flow-chart.png";
import { useHistory } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { deletePatient, getPatientListValue } from "../redux/effects/ApiCall";
import { AppState } from "../redux/store/Store";
import { PatientList } from "../redux/interfaces/Model";
import ShareIcon from "@mui/icons-material/Share";
import MoreVertIcon from "@mui/icons-material/MoreVert";
import FavoriteIcon from "@mui/icons-material/Favorite";
import FavoriteBorderIcon from "@mui/icons-material/FavoriteBorder";
import {
  Dialog,
  Link,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  OutlinedInput,
  Typography,
  IconButton,
  Card,
  CardHeader,
  CardContent,
  CardActions,
  Divider,
  Box,
  Button,
  Grid,
  Stack,
  Alert,
} from "@mui/material";
import CloseIcon from "@mui/icons-material/Close";
import {
  requestBookAppointmentApi,
  updateApiResponse,
} from "../redux/requestAppointmentByPatient/API";
import { t } from "i18next";
import { green } from "@mui/material/colors";

export default function PersonalHealthRecord(loader: boolean | any) {
  const { myPatientListLoader } = loader;
  const [modalActive, setModalActive] = useState(false);
  const [shareHealthRecord, setShareHealthRecord] = useState("");

  const history = useHistory();
  const requestBookAppointmentResult = useSelector(
    (state: AppState) => state.bookAppointmentRequest,
  );
  const truncate = (str: string) => {
    return str.length > 10 ? str.substring(0, 20) + "..." : str;
  };

  const DATE_OPTIONS = {
    year: "numeric",
    month: "short",
    day: "numeric",
  } as const;
  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(getPatientListValue());
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [dispatch]);

  const shareRecord = () => {
    setModalActive((modalActive) => !modalActive);
    setShareHealthRecord("");
  };

  function addFavoritePatientAction(patientId: any, isFavourite: any) {
    let apiBody = {
      patientId: patientId,
      isFavourite: !isFavourite,
    } as AddFavoritePatientBody;
    dispatch(addFavoritePatient(apiBody));
  }
  // Add Favorite Patient Store
  const addFavoritePatientRes = useSelector((state: AppState) => state.addFavoritePatientValue);

  useEffect(() => {
    if (addFavoritePatientRes?.addFavoritePatientRes?.message !== undefined) {
      dispatch(getPatientListValue());
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [addFavoritePatientRes]);

  const [deleteRecordModalMarkup, setDeleteModalActive] = useState(false);
  const handleDeleteRecordModalChange = useCallback(
    () => setDeleteModalActive(!deleteRecordModalMarkup),
    [deleteRecordModalMarkup],
  );

  let [patientId, setPatientId] = useState();

  function deleteRecord(patientId: any) {
    setDeleteModalActive((deleteRecordModalMarkup) => !deleteRecordModalMarkup);
    setPatientId(patientId);
  }

  const handleMessageChangeHr = useCallback((value: any) => setShareHealthRecord(value), []);

  const closeSuccess = () => {
    setDeleteRecordSuccess("");
    setDeleteRecordError("");
  };

  const resetGetPasswordApiCall = () => {
    console.log("Share api not implemented");
  };

  const [deleteRecordSuccess, setDeleteRecordSuccess] = useState("");
  const successDelRecordDiv = deleteRecordSuccess ? (
    <Alert variant="standard" severity="success" onClose={() => closeSuccess()}>
      {deleteRecordSuccess}
    </Alert>
  ) : null;

  const [recordDeleteError, setDeleteRecordError] = useState("");
  const errorDelRecordDiv = recordDeleteError ? (
    <Alert variant="standard" severity="error" onClose={() => closeSuccess()}>
      {recordDeleteError}
    </Alert>
  ) : null;

  const deleteBody = {
    patientId: patientId,
    isActive: false,
  };

  const DeleteRecordSubmit = () => {
    setDeleteModalActive((deleteRecordModalMarkup) => !deleteRecordModalMarkup);
    dispatch(deletePatient(deleteBody));
  };

  function BookAppointment(patientId: any) {
    dispatch(requestBookAppointmentApi(patientId));
  }

  useEffect(() => {
    if (
      requestBookAppointmentResult?.bookAppoinmentRes?.patientId !== undefined &&
      requestBookAppointmentResult?.bookAppoinmentRes?.caseId !== undefined &&
      requestBookAppointmentResult?.bookAppoinmentRes?.whyWant2ndOpinion !== undefined
    ) {
      history.push(
        `/filepreparation/${requestBookAppointmentResult.bookAppoinmentRes.patientId}/${requestBookAppointmentResult.bookAppoinmentRes.caseId}`,
      );
      dispatch(updateApiResponse());
    } else if (
      requestBookAppointmentResult?.bookAppoinmentRes?.patientId !== undefined &&
      requestBookAppointmentResult?.bookAppoinmentRes?.caseId === undefined
    ) {
      history.push(`/filepreparation/${requestBookAppointmentResult.bookAppoinmentRes.patientId}`);
      dispatch(updateApiResponse());
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [requestBookAppointmentResult]);

  const deletePatientValue = useSelector((state: AppState) => state.deletePatientValues);
  useEffect(() => {
    myPatientListLoader(deletePatientValue?.loading);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [deletePatientValue]);

  const emptyPageMarkup = (
    <Grid container spacing={{ xs: 2, md: 3 }}>
      <Grid item xs={6} md={6} container alignItems="center" style={{ height: "50vh" }}>
        <Box pt={5} mt={5}>
          <Typography variant="h3">{t("perHealthRecPage.seekCon", { ns: ["home"] })!}</Typography>
          <p>
            <br />
            {t("perHealthRecPage.oneStopSol", { ns: ["home"] })!}
            <br />
            <br />
          </p>
          <div>
            <Link href="/demographicdetails">
              <Button variant="contained">{t("menu.patient", { ns: ["home"] })!}</Button>
            </Link>
          </div>
        </Box>
      </Grid>
      <Grid item xs={6} md={6}>
        <Box pt={5} mt={5}>
          <div>
            <img
              width="100%"
              height="100%"
              style={{
                objectFit: "cover",
                objectPosition: "center",
              }}
              src={PatientFlow}
              alt=""
            />
          </div>
        </Box>
      </Grid>
    </Grid>
  );

  const PatientListFromAPI = useSelector((state: AppState) => state.patientValue);
  useEffect(() => {
    myPatientListLoader(PatientListFromAPI?.loading!);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [PatientListFromAPI]);
  const actualPageMarkup = (
    <Grid container direction="row" justifyContent="flex-start" alignItems="flex-start" spacing={2}>
      {PatientListFromAPI.patientValue.map((patientItem: PatientList, index: number) => (
        <Grid item xs={12} sm={4} lg={4} key={"patient-" + index}>
          <Card>
            <CardHeader
              key={"patient-header-" + index}
              avatar={
                <img
                  key={"patient-image-" + index}
                  id={`${patientItem.id}`}
                  src={
                    patientItem.profilePicture === null
                      ? "medical.png"
                      : patientItem.profilePicture.document
                  }
                  alt=""
                  width="84"
                  height="84"
                  style={{
                    objectFit: "cover",
                    objectPosition: "center",
                    borderRadius: "32px",
                  }}
                />
              }
              action={[
                <Box mt={1} key={"patient-action-" + index}>
                  <Link onClick={shareRecord}>
                    <IconButton>
                      <ShareIcon />
                    </IconButton>
                  </Link>
                  <Link
                    onClick={() =>
                      addFavoritePatientAction(patientItem.id, patientItem.isFavourite)
                    }
                  >
                    <IconButton aria-label="settings">
                      {patientItem.isFavourite === true ? (
                        <FavoriteIcon fontSize="large" style={{ color: green[500] }} />
                      ) : (
                        <FavoriteBorderIcon fontSize="large" />
                      )}
                    </IconButton>
                  </Link>
                  <Link onClick={() => deleteRecord(patientItem.id)}>
                    <IconButton>
                      <MoreVertIcon />
                    </IconButton>
                  </Link>
                </Box>,
              ]}
              title={
                <Typography variant="h6" fontWeight="bold" key={"patient-title-" + index}>
                  {patientItem?.firstname} {patientItem?.lastname}
                </Typography>
              }
              subheader={
                <Typography variant="subtitle1" key={"patient-sub-title" + index}>
                  {patientItem?.patientRelationshipWithOwner?.displayValue}
                </Typography>
              }
            />
            <CardContent>
              <Typography variant="body1" align="left">
                {patientItem?.description ? truncate(patientItem?.description) : "Not Set"}
              </Typography>
              <Box my={2}>
                <Divider />
              </Box>
              <Grid
                container
                direction="row"
                justifyContent="space-between"
                alignItems="flex-start"
              >
                <Grid item>
                  <Stack
                    direction="column"
                    justifyContent="space-between"
                    alignItems="center"
                    spacing={2}
                  >
                    <Typography>{t("perHealthRecPage.gen", { ns: ["home"] })!}</Typography>
                    <Typography fontWeight="bold">{patientItem?.gender?.label}</Typography>
                  </Stack>
                </Grid>
                <Divider orientation="vertical" flexItem />
                <Grid item>
                  <Stack
                    direction="column"
                    justifyContent="space-between"
                    alignItems="center"
                    spacing={2}
                  >
                    <Typography>
                      {
                        t("addpatientpage.bloodgroup", {
                          ns: ["home"],
                        })!
                      }
                    </Typography>
                    <Typography fontWeight="bold">
                      {patientItem?.bloodGroup === null
                        ? "Not Known"
                        : patientItem?.bloodGroup?.label}
                    </Typography>
                  </Stack>
                </Grid>
                <Divider orientation="vertical" flexItem />
                <Grid item>
                  <Stack
                    direction="column"
                    justifyContent="space-between"
                    alignItems="center"
                    spacing={2}
                  >
                    <Typography>{t("perHealthRecPage.patDob", { ns: ["home"] })!}</Typography>
                    <Typography fontWeight="bold">
                      {new Date(patientItem?.dob).toLocaleDateString("en-US", DATE_OPTIONS)}
                    </Typography>
                  </Stack>
                </Grid>
                <Divider orientation="vertical" flexItem />
                <Grid item>
                  <Stack
                    direction="column"
                    justifyContent="space-between"
                    alignItems="center"
                    spacing={2}
                  >
                    <Typography>Phone No</Typography>
                    <Typography fontWeight="bold">
                      {patientItem?.phone === null ? "Not Known" : patientItem?.phone}
                    </Typography>
                  </Stack>
                </Grid>
              </Grid>
              <Box my={2}>
                <Divider orientation="horizontal" flexItem />
              </Box>
            </CardContent>
            <CardActions>
              <Grid
                container
                direction="row"
                justifyContent="flex-start"
                alignItems="flex-start"
                spacing={2}
              >
                <Grid item>
                  <Link href={`/patientdetails/${patientItem.id}`}>
                    <Button variant="contained" size="medium">
                      {t("perHealthRecPage.caseDet", { ns: ["home"] })!}
                    </Button>
                  </Link>
                </Grid>
                <Grid item>
                  <Link>
                    <Button
                      variant="outlined"
                      size="medium"
                      onClick={() => BookAppointment(patientItem.id)}
                    >
                      {
                        t("finddoc.bookAppo", {
                          ns: ["home"],
                        })!
                      }
                    </Button>
                  </Link>
                </Grid>
              </Grid>
            </CardActions>
          </Card>
        </Grid>
      ))}
    </Grid>
  );

  const modalMarkup = (
    <Dialog open={modalActive} onClose={shareRecord} fullWidth>
      <DialogTitle>
        <Grid
          container
          direction="row"
          justifyContent="space-between"
          alignItems="flex-start"
          spacing={2}
        >
          <Grid item>
            <Typography>{t("perHealthRecPage.shareHealthRec", { ns: ["home"] })!}</Typography>
          </Grid>
          <Grid item>
            <IconButton
              aria-label="close"
              onClick={shareRecord}
              sx={{
                position: "absolute",
                right: 8,
                top: 8,
                color: (theme) => theme.palette.grey[500],
              }}
            >
              <CloseIcon />
            </IconButton>
          </Grid>
        </Grid>
      </DialogTitle>
      <Divider />
      <DialogContent>
        <DialogContentText>
          <Box style={{ marginBottom: "5px" }}>
            {t("perHealthRecPage.sharePeoGrp", { ns: ["home"] })!}
          </Box>
          <OutlinedInput
            value={shareHealthRecord}
            placeholder={t("perHealthRecPage.addPeoGrp", { ns: ["home"] })!}
            id="sharehealthrecord"
            onChange={handleMessageChangeHr}
            fullWidth
          />
        </DialogContentText>
      </DialogContent>
      <DialogActions>
        <Button variant="contained" size="medium" onClick={resetGetPasswordApiCall}>
          {t("perHealthRecPage.share", { ns: ["home"] })!}
        </Button>
      </DialogActions>
    </Dialog>
  );

  const deleteRecordModal = (
    <Dialog open={deleteRecordModalMarkup} onClose={handleDeleteRecordModalChange} fullWidth>
      <DialogTitle>
        <Grid container direction="row" justifyContent="space-between" alignItems="flex-start">
          <Grid item>
            <Typography>{t("perHealthRecPage.delPat", { ns: ["home"] })!}</Typography>
          </Grid>
          <Grid item>
            <IconButton
              aria-label="close"
              onClick={handleDeleteRecordModalChange}
              sx={{
                position: "absolute",
                right: 8,
                top: 8,
                color: (theme) => theme.palette.grey[500],
              }}
            >
              <CloseIcon />
            </IconButton>
          </Grid>
        </Grid>
      </DialogTitle>
      <Divider />
      <DialogContent>
        <DialogContentText>
          <Typography variant="h6">
            <strong>{t("perHealthRecPage.qdelPat", { ns: ["home"] })}</strong>
          </Typography>
        </DialogContentText>
      </DialogContent>
      <DialogActions>
        <Button variant="outlined" onClick={handleDeleteRecordModalChange}>
          No
        </Button>
        <Button variant="contained" size="medium" onClick={DeleteRecordSubmit}>
          Yes
        </Button>
      </DialogActions>
      <div>{successDelRecordDiv}</div>
      <div>{errorDelRecordDiv}</div>
    </Dialog>
  );
  return (
    <Box>
      {PatientListFromAPI.patientValue.length === 0 ? emptyPageMarkup : actualPageMarkup}
      {modalMarkup}
      {deleteRecordModal}
    </Box>
  );
}
