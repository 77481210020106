import { useEffect, useState } from "react";
import * as am5 from "@amcharts/amcharts5";
import * as am5xy from "@amcharts/amcharts5/xy";
import am5themes_Animated from "@amcharts/amcharts5/themes/Animated";
import am5themes_Responsive from "@amcharts/amcharts5/themes/Responsive";
import am5themes_Material from "@amcharts/amcharts5/themes/Material";
import { Box } from "@mui/material";
import { BMIData } from "../Model";
import React from "react";

type Props = {
  data?: BMIData | any;
  unit?: string | any;
};

export default function BloodSugar({ data, unit }: Props) {
  const [chartData, setChartData] = useState([] as BMIData[]);
  const [unitt, setUnitt] = useState<any>();
  useEffect(() => {
    setChartData(data);
    if (data !== undefined) {
      setChartData(data);
    }
    if (unit !== undefined) {
      setUnitt(unit);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [data, unit]);
  useEffect(() => {
    let root = am5.Root.new("sugarchart");
    root.setThemes([
      am5themes_Animated.new(root),
      am5themes_Responsive.new(root),
      am5themes_Material.new(root),
    ]);
    let chart = root.container.children.push(
      am5xy.XYChart.new(root, {
        panX: true,
        panY: true,
        wheelX: "panX",
        wheelY: "zoomX",
        pinchZoomX: true,
      }),
    );

    let cursor = chart.set(
      "cursor",
      am5xy.XYCursor.new(root, {
        behavior: "zoomX",
      }),
    );
    cursor.lineY.set("visible", false);

    let xAxis = chart.xAxes.push(
      am5xy.DateAxis.new(root, {
        maxDeviation: 0,
        baseInterval: {
          timeUnit: unitt,
          count: 1,
        },
        renderer: am5xy.AxisRendererX.new(root, {
          minGridDistance: 60,
        }),
        tooltip: am5.Tooltip.new(root, {}),
      }),
    );

    let yAxis = chart.yAxes.push(
      am5xy.ValueAxis.new(root, {
        renderer: am5xy.AxisRendererY.new(root, {}),
      }),
    );

    let series = chart.series.push(
      am5xy.ColumnSeries.new(root, {
        name: "Series",
        xAxis: xAxis,
        yAxis: yAxis,
        valueYField: "value",
        valueXField: "date",
        fill: am5.Color.fromString("#93cced"),
        tooltip: am5.Tooltip.new(root, {
          labelText: "Blood-Sugar: [bold]{valueY}[/]",
        }),
      }),
    );

    series.columns.template.setAll({ strokeOpacity: 0 });

    chart.set(
      "scrollbarX",
      am5.Scrollbar.new(root, {
        orientation: "horizontal",
      }),
    );
    // Set data
    series.data.setAll(chartData);
    series.appear(1000);
    chart.appear(1000, 100);
    return () => root.dispose();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [chartData, unitt]);

  return <Box id="sugarchart" style={{ width: "100%", height: "550px", borderRadius: 20 }} />;
}
