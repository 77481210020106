import {
  AppLoaderActionTypes,
  APP_LOADER_LOADING,
  AppLoaderState,
} from "./ActionTypes";

const initialStateGetPosts: AppLoaderState = {
  loading: false,
};
export const appLoaderReducer = (
  state = initialStateGetPosts,
  action: AppLoaderActionTypes,
): AppLoaderState => {
  switch (action.type) {
    case APP_LOADER_LOADING:
      return {
        ...state,
        loading: action.loading,
      };
    default:
      return state;
  }
};
