import axios from "axios";
import { Dispatch } from "redux";
import Url from "../../../Url";
import { setupInterceptorsTo } from "../../Authentication/Interceptors";
import { getPatientDemographicsDetailsAction } from "./Actions";
import {
  PatientDemographicsActionTypes,
  PATIENT_DEMOGRAPHICS_FAIL,
  PATIENT_DEMOGRAPHICS_LOADING,
  PATIENT_DEMOGRAPHICS_SUCCESS,
} from "./ActionTypes";
import { GetPatientDemographicsDetails } from "./Model";
import { headers } from "components/Utility";

export const getPatientDemographicsDetails = (patientDetails: GetPatientDemographicsDetails) => {
  return function (dispatch: Dispatch<PatientDemographicsActionTypes>) {
    dispatch({
      type: PATIENT_DEMOGRAPHICS_LOADING,
    });

    setupInterceptorsTo(axios)
      .post(
        Url.apiURL + `/patientapp/api/patient/get_patient_demographics_details`,
        patientDetails,
        {
          headers: headers,
        },
      )
      .then((res) => {
        dispatch(getPatientDemographicsDetailsAction(res.data));
        dispatch({
          type: PATIENT_DEMOGRAPHICS_SUCCESS,
          payload: res.data,
        });
      })
      .catch((_error) => {
        dispatch({
          type: PATIENT_DEMOGRAPHICS_FAIL,
        });
      });
  };
};
