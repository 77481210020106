import {
  GET_DOCTOR_CATEGORY_LIST_FAIL,
  GET_DOCTOR_CATEGORY_LIST_LOADING,
  GET_DOCTOR_CATEGORY_LIST_SUCCESS,
  GET_DOCTOR_CATEGORY_LIST_UPDATE_API_MSG,
  GET_DOCTOR_CATEGORY_LIST_UPDATE_API_RES,
  GetDoctorCategoryListActionTypes,
} from "./ActionTypes";
import { GetDoctorCategoryListRes } from "./Model";

export const getDoctorCategoryListLoadingAction = (
  loading: boolean,
): GetDoctorCategoryListActionTypes => {
  return {
    type: GET_DOCTOR_CATEGORY_LIST_LOADING,
    loading: loading,
  };
};

export const getDoctorCategoryListSuccessAction = (
  getDoctorCategoryListResponse: GetDoctorCategoryListRes,
  successMsg: string,
): GetDoctorCategoryListActionTypes => {
  return {
    type: GET_DOCTOR_CATEGORY_LIST_SUCCESS,
    payload: getDoctorCategoryListResponse,
    successMsg: successMsg,
  };
};

export const getDoctorCategoryListErrorAction = (
  getDoctorCategoryListResponse: GetDoctorCategoryListRes,
  errMsg: string,
  status: number,
): GetDoctorCategoryListActionTypes => {
  return {
    type: GET_DOCTOR_CATEGORY_LIST_FAIL,
    payload: getDoctorCategoryListResponse,
    errorMsg: errMsg,
    status: status,
  };
};
export const getDoctorCategoryListUpdateAPIMsgAction = (
  getDoctorCategoryListResponse: GetDoctorCategoryListRes,
  successMsg: string,
  errMsg: string,
  status: number,
): GetDoctorCategoryListActionTypes => {
  return {
    type: GET_DOCTOR_CATEGORY_LIST_UPDATE_API_MSG,
    payload: getDoctorCategoryListResponse,
    successMsg: successMsg,
    errorMsg: errMsg,
    status: status,
  };
};

export const getDoctorCategoryListAPIResClearAction = (
  getDoctorCategoryListResponse: GetDoctorCategoryListRes,
  successMsg: string,
  errMsg: string,
  status: number,
): GetDoctorCategoryListActionTypes => {
  return {
    type: GET_DOCTOR_CATEGORY_LIST_UPDATE_API_RES,
    payload: getDoctorCategoryListResponse,
    successMsg: successMsg,
    errorMsg: errMsg,
    status: status,
  };
};
