import { AddPatientRes } from "./Model";

export const ADD_DATA_POINT_LOADING = "ADD_DATA_POINT_LOADING";
export const ADD_DATA_POINT_SUCCESS = "ADD_DATA_POINT_SUCCESS";
export const ADD_DATA_POINT_FAIL = "ADD_DATA_POINT_FAIL";
export const ADD_PATIENT_DATA = "ADD_PATIENT_DATA";

export interface AddPatientDetails {
  addPatientRes: AddPatientRes;
  loading: boolean;
  errRes: string;
}
export interface AddPatientLoading {
  type: typeof ADD_DATA_POINT_LOADING;
}
export interface AddPatientSuccess {
  type: typeof ADD_DATA_POINT_SUCCESS;
  payload: AddPatientRes;
  errRes: string;
}
export interface AddPatientFail {
  type: typeof ADD_DATA_POINT_FAIL;
  payload: AddPatientRes;
  errRes: string;
}
interface AddPatientAction {
  type: typeof ADD_PATIENT_DATA;
  payload: AddPatientRes;
  errRes: string;
}

export type PatientAddPointDataActionTypes =
  | AddPatientAction
  | AddPatientLoading
  | AddPatientSuccess
  | AddPatientFail;
