import {
  requestBookAppoiontmentDetails,
  requestBookAppoiontmentActionTypes,
  REQUEST_BOOK_APPOINTMENT_FAIL,
  REQUEST_BOOK_APPOINTMENT_LOADING,
  REQUEST_BOOK_APPOINTMENT_SUCCESS,
  REQUEST_BOOK_APPOINTMENT_UPDATE_LIST,
} from "./ActionTypes";
import { requestBookAppoiontmentValue } from "./Model";

const initialStateGetPosts: requestBookAppoiontmentDetails = {
  loading: false,
  bookAppoinmentRes: {} as requestBookAppoiontmentValue,
};
export const requestBookAppointmentReducer = (
  state = initialStateGetPosts,
  action: requestBookAppoiontmentActionTypes,
): requestBookAppoiontmentDetails => {
  switch (action.type) {
    case REQUEST_BOOK_APPOINTMENT_SUCCESS:
      return {
        ...state,
        loading: false,
        bookAppoinmentRes: action.payload,
      };
    case REQUEST_BOOK_APPOINTMENT_LOADING:
      return {
        ...state,
        loading: true,
        bookAppoinmentRes: {} as requestBookAppoiontmentValue,
      };
    case REQUEST_BOOK_APPOINTMENT_FAIL:
      return {
        ...state,
        bookAppoinmentRes: {} as requestBookAppoiontmentValue,
        loading: false,
      };
    case REQUEST_BOOK_APPOINTMENT_UPDATE_LIST:
      return {
        ...state,
        bookAppoinmentRes: {} as requestBookAppoiontmentValue,
        loading: false,
      };
    default:
      return state;
  }
};
