import React, { useEffect } from "react";
import { Avatar, CardHeader, Box, Grid, Card, Typography } from "@mui/material";
import { useDispatch, useSelector } from "react-redux";
import { DoctorList } from "../redux/interfaces/Model";
import { AppState } from "../redux/store/Store";
import { getDoctorListValue } from "../redux/effects/ApiCall";
import { useHistory } from "react-router-dom";
import MWPageTitle from "../components/MWPageTitle";
import AccountCircleIcon from "@mui/icons-material/AccountCircle";
import { toggleAppBarLoading } from "redux/appLoader/Actions";

export default function AllDoctor() {
  const dispatch = useDispatch();
  const history = useHistory();

  const gotoDoctorDetails = (doctorId: number) => {
    history.push(`/doctordetails/${doctorId}`);
  };

  useEffect(() => {
    dispatch(getDoctorListValue());
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [dispatch]);

  const docotorList = useSelector((state: AppState) => state.doctorValue);
  useEffect(() => {
    dispatch(toggleAppBarLoading(docotorList?.loading));
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [docotorList]);
  const actualPageMarkup = (
    <Grid container spacing={1}>
      <Grid container item sm={12} spacing={1}>
        {docotorList !== undefined
          ? docotorList?.doctorValue?.map((doctorItem: DoctorList) => (
              <Grid item md={4} key={doctorItem.id}>
                <Card
                  onClick={() => gotoDoctorDetails(doctorItem.id)}
                  style={{ cursor: "pointer" }}
                >
                  <div style={{ height: "500px" }}>
                    <div style={{ height: "105px" }}>
                      <CardHeader
                        avatar={
                          <Avatar aria-label="recipe">
                            <AccountCircleIcon fontSize="large" />
                          </Avatar>
                        }
                        title={<Typography variant="body1">{doctorItem.fullName}</Typography>}
                        subheader={<small>{doctorItem.qualification}</small>}
                      />
                    </div>
                    <Grid container alignItems="center" justifyContent="center">
                      <div style={{ height: "300px", width: "300px" }}>
                        <img alt="" width="100%" height="100%" src={doctorItem.image?.document} />
                      </div>
                    </Grid>
                    <Box alignItems="center" my={1} display={"flex"}>
                      <Box justifyContent="center" p={2} flex={"auto"}>
                        <Typography>Specialty</Typography>
                        <Typography>{doctorItem.specialtyWithYearsOfExperience}</Typography>
                      </Box>
                    </Box>
                  </div>
                </Card>
              </Grid>
            ))
          : null}
      </Grid>
    </Grid>
  );

  return (
    <Box>
      <MWPageTitle title="Doctor's List" />
      {actualPageMarkup}
    </Box>
  );
}
