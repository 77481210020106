import {
  GetSharedPatientDetails,
  sharedPatientDetailsActionTypes,
  MEETING_LIST_FAIL,
  MEETING_LIST_LOADING,
  MEETING_LIST_SUCCESS,
} from "./ActionTypes";
import { sharedPatientList } from "./Model";

const initialStateGetPosts: GetSharedPatientDetails = {
  loading: false,
  sharedPatientDetailsRes: {} as sharedPatientList,
};
export const getInstantMeetingReducer = (
  state = initialStateGetPosts,
  action: sharedPatientDetailsActionTypes,
): GetSharedPatientDetails => {
  switch (action.type) {
    case MEETING_LIST_SUCCESS:
      return {
        ...state,
        loading: false,
        sharedPatientDetailsRes: action.payload,
      };
    case MEETING_LIST_LOADING:
      return {
        ...state,
        loading: true,
        sharedPatientDetailsRes: {} as sharedPatientList,
      };
    case MEETING_LIST_FAIL:
      return {
        ...state,
        sharedPatientDetailsRes: {} as sharedPatientList,
        loading: false,
      };
    default:
      return state;
  }
};
