import React, { useEffect } from "react";
import { useParams, useHistory } from "react-router-dom";
import { useSelector, useDispatch } from "react-redux";
import { AppState } from "../../redux/store/Store";
import { TaskList } from "../../redux/interfaces/Model";
import { getTaskList } from "../../redux/effects/ApiCall";
import * as _ from "lodash";
import MWLoader from "../../components/MWLoader";
import PreviewIcon from "@mui/icons-material/Preview";
import PaymentsIcon from "@mui/icons-material/Payments";
import {
  Box,
  Button,
  Card,
  CardContent,
  CardHeader,
  Grid,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableRow,
  Typography,
} from "@mui/material";
import MWPageTitle from "../../components/MWPageTitle";
import MWExceptionList from "../../components/MWExceptionList";
import PageLayout from "../../components/PageLayout";
export default function TaskDetails() {
  const loadingMarkup = <MWLoader />;
  const dispatch = useDispatch();
  const { rowIndex } = useParams() as {
    rowIndex: string;
  };

  const history = useHistory();
  // Call Store
  const taskReduxValue = useSelector((state: AppState) => state.taskValue);
  const taskDetails: TaskList = _.filter(taskReduxValue.taskValue, [
    "id",
    Number(rowIndex),
  ])[0];

  function gotoCaseDetails() {
    const paymentRedirect = Number(0);
    localStorage.setItem("paymentRedirect", `${paymentRedirect}`);
    history.push(
      `/filepreparation/${taskDetails.patientId}/${taskDetails.caseId}/${paymentRedirect}`
    );
  }
  function gotoPayment() {
    const paymentRedirect = Number(1);
    localStorage.setItem("paymentRedirect", `${paymentRedirect}`);
    history.push(
      `/filepreparation/${taskDetails.patientId}/${taskDetails.caseId}/${paymentRedirect}`
    );
    localStorage.setItem("paymentRedirect", `${paymentRedirect}`);
  }

  useEffect(() => {
    dispatch(getTaskList());
    localStorage.removeItem("paymentRedirect");
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [dispatch]);

  const taskDetailsView = (
    <Card>
      <CardHeader
        title={
          <Grid
            container
            direction="row"
            justifyContent="flex-end"
            alignItems="flex-start"
            spacing={2}
          >
            <Grid item>
              <Button
                variant="outlined"
                startIcon={<PaymentsIcon />}
                onClick={() => gotoPayment()}
              >
                Go for Payment
              </Button>
            </Grid>
            <Grid item>
              <Button
                variant="outlined"
                startIcon={<PreviewIcon />}
                onClick={() => gotoCaseDetails()}
              >
                View Case Details
              </Button>
            </Grid>
          </Grid>
        }
      />
      <CardContent>
        {taskDetails ? (
          <TableContainer>
            <Table>
              <TableBody>
                {[
                  {
                    term: "Title",
                    description: `${taskDetails.title}`,
                  },
                  {
                    term: "Patient Name",
                    description: `${taskDetails.patientName}`,
                  },
                  {
                    term: "Problem Name",
                    description: `${taskDetails.whyWant2ndOpinion}`,
                  },
                  {
                    term: "Reason for 2ndOpinion",
                    description: `${taskDetails.secondOpinionReasons}`,
                  },
                  {
                    term: "Consultation Type",
                    description: `${taskDetails.consultationType}`,
                  },
                  {
                    term: "Note",
                    description: `${taskDetails.note}`,
                  },
                  {
                    term: "Create Date",
                    description: `${taskDetails.created_date}`,
                  },
                  {
                    term: "Due Date",
                    description: `${taskDetails.due_date}`,
                  },
                  {
                    term: "Completed",
                    description: `${taskDetails.completed}`,
                  },
                ].map((row) => (
                  <TableRow key={row.term}>
                    <TableCell component="th" scope="row">
                      <Typography variant="h6">{row.term}</Typography>
                    </TableCell>
                    <TableCell>
                      <Typography variant="body2">{row.description}</Typography>
                    </TableCell>
                  </TableRow>
                ))}
              </TableBody>
            </Table>
          </TableContainer>
        ) : (
          <MWExceptionList />
        )}
      </CardContent>
    </Card>
  );

  return (
    <Box>
      {taskReduxValue.loading ? loadingMarkup : ""}
      <PageLayout>
        <MWPageTitle title="Task Details" />
        {taskDetailsView}
      </PageLayout>
    </Box>
  );
}
