import { GetLanguageListRes } from "./Model";

export const GET_LANGUAGE_LIST_LOADING = "GET_LANGUAGE_LIST_LOADING";
export const GET_LANGUAGE_LIST_SUCCESS = "GET_LANGUAGE_LIST_SUCCESS";
export const GET_LANGUAGE_LIST_FAIL = "GET_LANGUAGE_LIST_FAIL";
export const GET_LANGUAGE_LIST_DATA = "GET_LANGUAGE_LIST_DATA";

export interface LanguageListDetails {
  getLanguageListRes: GetLanguageListRes;
  loading: boolean;
  errRes: string;
}
export interface GetLanguageListActionLoading {
  type: typeof GET_LANGUAGE_LIST_LOADING;
}
export interface GetLanguageListActionSuccess {
  type: typeof GET_LANGUAGE_LIST_SUCCESS;
  payload: GetLanguageListRes;
  errRes: string;
}
export interface GetLanguageListActionFail {
  type: typeof GET_LANGUAGE_LIST_FAIL;
  payload: GetLanguageListRes;
  errRes: string;
}
interface GetLanguageListActionAction {
  type: typeof GET_LANGUAGE_LIST_DATA;
  payload: GetLanguageListRes;
  errRes: string;
}

export type GetLanguageListActionTypes =
  | GetLanguageListActionAction
  | GetLanguageListActionLoading
  | GetLanguageListActionSuccess
  | GetLanguageListActionFail;
