import React, { useState, useCallback, useEffect } from "react";
import { useParams } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { t } from "i18next";
import {
  Alert,
  Box,
  Button,
  Card,
  CardContent,
  CardHeader,
  Container,
  Grid,
  Stack,
  TextField,
  Typography,
} from "@mui/material";
import { AppState } from "../redux/store/Store";
import { getPatientTeam } from "../redux/PatientTeam/GetPatientTeam/API";
import { AddPatientTeamReq } from "../redux/PatientTeam/AddPatientTeam/Model";
import { GetPatientTeamReq } from "../redux/PatientTeam/GetPatientTeam/Model";
import { addPatientTeam } from "../redux/PatientTeam/AddPatientTeam/API";
import MWResourceList from "../components/MWResourceList";
import MWExceptionList from "../components/MWExceptionList";

export default function AddPatientTeam(props: any) {
  const [, setIsLoading] = useState(false);
  const [bannerBody, setBannerBody] = useState("");
  const [successBannerBody, setSuccessBannerBody] = useState("");
  const [patientTeamEmail, setPatientTeamEmail] = useState("");
  const handleOtherValueChange = useCallback((value: any) => setPatientTeamEmail(value), []);
  const { appointmentPatientTeamLoader } = props;
  const [patientTeamOwnerName, setPatientTeamOwnerName] = useState("");
  const [patientTeamOwnerEmail, setPatientTeamOwnerEmail] = useState("");
  const [patientTeamOwnerPhone, setPatientTeamOwnerPhone] = useState("");
  const [patientCasePhone, setPatientCasePhone] = useState("");
  console.log(patientCasePhone);
  const [selectedPatientTeamList, setSelectedPatientTeamList] = useState<any[]>([]);

  const closeBanner = () => {
    setBannerBody("");
    setSuccessBannerBody("");
  };

  const { caseid } = useParams() as {
    caseid: string;
  };

  const errorBannerMarkup = bannerBody ? (
    <Alert
      severity="error"
      onClose={() => {
        closeBanner();
      }}
    >
      {bannerBody}
    </Alert>
  ) : null;

  const successBannerMarkup = successBannerBody ? (
    <Alert
      severity="success"
      onClose={() => {
        closeBanner();
      }}
    >
      {successBannerBody}
    </Alert>
  ) : null;

  useEffect(() => {
    window.scrollTo(0, 0);
    dispatch(getPatientTeam(getPatientListBody));
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const getPatientTeamRes = useSelector((state: AppState) => state.getPatientTeamRes);
  const PatientTeamListLoadingvalue = useSelector(
    (state: AppState) => state.getPatientTeamRes.loading,
  );

  useEffect(() => {
    getPatientTeamRes?.loading ? setIsLoading(true) : setIsLoading(false);
    if (getPatientTeamRes?.getPatientTeamRes?.status !== undefined) {
      if (getPatientTeamRes?.getPatientTeamRes?.status === 1) {
        setPatientTeamOwnerName(getPatientTeamRes?.getPatientTeamRes?.ownerDetails?.name);
        setPatientTeamOwnerEmail(getPatientTeamRes?.getPatientTeamRes?.ownerDetails?.email);
        setPatientTeamOwnerPhone(getPatientTeamRes?.getPatientTeamRes?.ownerDetails?.phone);
        setPatientCasePhone(getPatientTeamRes?.getPatientTeamRes?.patientCasePhone);
        setSelectedPatientTeamList(getPatientTeamRes?.getPatientTeamRes?.patientTeam);
      } else {
        setSelectedPatientTeamList([]);
      }
    }
  }, [getPatientTeamRes]);

  const body = {
    caseId: caseid,
    email: patientTeamEmail,
  } as AddPatientTeamReq;

  const dispatch = useDispatch();
  const getPatientListBody = {
    caseId: caseid,
  } as GetPatientTeamReq;

  const handleAddPatientTeam = () => {
    if (patientTeamEmail === "") {
      setBannerBody(`${t("addPatTeam.EmToAdd", { ns: ["home"] })}`);
    } else {
      setBannerBody("");
      dispatch(addPatientTeam(body));
    }
  };
  const addPatientTeamRes = useSelector((state: AppState) => state.addPatientTeamRes);
  const addPatientTeamLoadingValue = useSelector(
    (state: AppState) => state.addPatientTeamRes.loading,
  );

  useEffect(() => {
    addPatientTeamRes?.loading ? setIsLoading(true) : setIsLoading(false);
    addPatientTeamRes?.getPatientTeamRes?.message !== undefined
      ? setSuccessBannerBody(addPatientTeamRes?.getPatientTeamRes?.message)
      : setSuccessBannerBody("");
    if (addPatientTeamRes?.getPatientTeamRes?.message !== undefined) {
      dispatch(getPatientTeam(getPatientListBody));
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [addPatientTeamRes]);

  const addPatientTeamMarkUp = (
    <Stack direction="column" justifyContent="flex-start" alignItems="stretch" spacing={2}>
      <Card>
        <CardHeader title={t("addPatTeam.patTeamDetail", { ns: ["home"] })} />
        <CardContent>
          <Grid container direction="row" justifyContent="space-between" alignItems="flex-start">
            <Grid item xs={12} sm={4} md={4} lg={4}>
              <Grid
                container
                direction="row"
                justifyContent="flex-start"
                alignItems="flex-start"
                spacing={1}
              >
                <Grid item>
                  <Typography variant="subtitle1" fontWeight="bold">
                    {t("addPatTeam.nm", { ns: ["home"] })}
                  </Typography>
                </Grid>
                <Grid item>
                  <Typography variant="body1">{patientTeamOwnerName}</Typography>
                </Grid>
              </Grid>
            </Grid>
            <Grid item xs={12} sm={4} md={4} lg={4}>
              <Grid
                container
                direction="row"
                justifyContent="flex-start"
                alignItems="flex-start"
                spacing={1}
              >
                <Grid item>
                  <Typography variant="subtitle1" fontWeight="bold">
                    {t("loginpage.emailorphno", { ns: ["home"] })}:
                  </Typography>
                </Grid>
                <Grid item>
                  <Typography variant="body1">{patientTeamOwnerEmail}</Typography>
                </Grid>
              </Grid>
            </Grid>
            <Grid item xs={12} sm={4} md={4} lg={4}>
              <Grid
                container
                direction="row"
                justifyContent="flex-start"
                alignItems="flex-start"
                spacing={1}
              >
                <Grid item>
                  <Typography variant="subtitle1" fontWeight="bold">
                    {t("addPatTeam.contNum", { ns: ["home"] })}:
                  </Typography>
                </Grid>
                <Grid item>
                  <Typography variant="body1">{patientTeamOwnerPhone}</Typography>
                </Grid>
              </Grid>
            </Grid>
          </Grid>
        </CardContent>
      </Card>

      <Grid
        container
        direction="row"
        justifyContent="space-between"
        alignItems="flex-start"
        spacing={2}
      >
        <Grid item xs={12} sm={6} md={6} lg={6}>
          <Box>
            <CardHeader
              title={
                <Typography variant="subtitle1" fontWeight="bold">
                  {t("addPatTeam.selPatTeamList", { ns: ["home"] })}
                </Typography>
              }
            />
            <Box>
              {selectedPatientTeamList !== undefined ? (
                selectedPatientTeamList.length > 0 ? (
                  selectedPatientTeamList.map((element: any) => (
                    <MWResourceList
                      icon={false}
                      title={element?.email}
                      showSubtitle={false}
                      showNavigation={false}
                    />
                  ))
                ) : (
                  <MWExceptionList />
                )
              ) : (
                <MWExceptionList />
              )}
            </Box>
          </Box>
        </Grid>
        <Grid item xs={12} sm={6} md={6} lg={6}>
          <Stack direction="column" justifyContent="flex-start" alignItems="stretch" spacing={2}>
            <Grid
              container
              direction="row"
              justifyContent="flex-end"
              alignItems="flex-start"
              spacing={2}
              pt={3}
            >
              <Grid item xs={12} sm={10} md={10} lg={10}>
                <Typography variant="subtitle2" fontWeight="bold">
                  {t("addPatTeam.patCaseContNum", { ns: ["home"] })}:
                </Typography>
                <Typography variant="subtitle2">
                  {t("addPatTeam.patTeamEm", { ns: ["home"] })}
                </Typography>
                <Grid
                  container
                  direction="row"
                  justifyContent="space-between"
                  alignItems="flex-start"
                  spacing={2}
                >
                  <Grid item xs={12} sm={10} md={10} lg={10}>
                    <TextField
                      fullWidth
                      multiline
                      rows={1}
                      value={patientTeamEmail}
                      type="email"
                      label=""
                      placeholder="e.g. abc@xyz.com"
                      id="patientteamEmail"
                      onChange={(e) => handleOtherValueChange(e.target.value)}
                    />
                  </Grid>
                  <Grid item xs={12} sm={2} md={2} lg={2} style={{ marginTop: "5px" }}>
                    <Button variant="outlined" onClick={handleAddPatientTeam}>
                      {t("common.add", { ns: ["home"] })!}
                    </Button>
                  </Grid>
                </Grid>
              </Grid>
            </Grid>
          </Stack>
        </Grid>
      </Grid>
    </Stack>
  );
  return (
    <Box>
      {PatientTeamListLoadingvalue === true || addPatientTeamLoadingValue === true
        ? appointmentPatientTeamLoader(true)
        : appointmentPatientTeamLoader(false)}
      <Container maxWidth="xl">
        <Typography variant="h5">{addPatientTeamMarkUp}</Typography>
        <Box mt={2}>
          {errorBannerMarkup}
          {successBannerMarkup}
        </Box>
      </Container>
    </Box>
  );
}
