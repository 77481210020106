import { EditPatientInsuranceValue } from "./Model";

// Patient Meeting Loading State
export const EDIT_PATIENT_INSURANCE_LOADING = "EDIT_PATIENT_INSURANCE_LOADING";
export const EDIT_PATIENT_INSURANCE_SUCCESS = "EDIT_PATIENT_INSURANCE_SUCCESS";
export const EDIT_PATIENT_INSURANCE_FAIL = "EDIT_PATIENT_INSURANCE_FAIL";
export const EDIT_PATIENT_INSURANCE_LIST = "EDIT_PATIENT_INSURANCE_LIST";

export interface EditPatientInsuranceDetails {
  editPatientInsuranceRes: EditPatientInsuranceValue;
  loading: boolean;
  errRes: string;
}
export interface EditPatientInsuranceLoading {
  type: typeof EDIT_PATIENT_INSURANCE_LOADING;
}
export interface EditPatientInsuranceSuccess {
  type: typeof EDIT_PATIENT_INSURANCE_SUCCESS;
  payload: EditPatientInsuranceValue;
  errRes: string;
}
export interface EditPatientInsuranceFail {
  type: typeof EDIT_PATIENT_INSURANCE_FAIL;
  errRes: string;
}

interface EditPatientInsuranceAction {
  type: typeof EDIT_PATIENT_INSURANCE_LIST;
  payload: EditPatientInsuranceValue;
  errRes: string;
}

export type editPatientInsuranceActionTypes =
  | EditPatientInsuranceAction
  | EditPatientInsuranceLoading
  | EditPatientInsuranceSuccess
  | EditPatientInsuranceFail;
