import axios from "axios";
import { Dispatch } from "redux";
import Url from "../../../Url";
import { setupInterceptorsTo } from "../../Authentication/Interceptors";
import {
  PaymentListActionTypes,
  GET_PAYMENT_LIST_FAIL,
  GET_PAYMENT_LIST_LOADING,
  GET_PAYMENT_LIST_SUCCESS,
} from "./ActionTypes";
import { PaymentRes } from "./Model";
import { headers } from "components/Utility";

export const getPaymentListByOwner = () => {
  return function (dispatch: Dispatch<PaymentListActionTypes>) {
    dispatch({
      type: GET_PAYMENT_LIST_LOADING,
    });
    setupInterceptorsTo(axios)
      .post(
        Url.apiURL + `/paymentapp/api/payment/get_payment_list_for_owner`,
        {},
        {
          headers: headers,
        },
      )
      .then((res) => {
        dispatch({
          type: GET_PAYMENT_LIST_SUCCESS,
          payload: res.data,
          errRes: "",
        });
      })
      .catch((error) => {
        dispatch({
          type: GET_PAYMENT_LIST_FAIL,
          payload: {} as PaymentRes,
          errRes: error.response.data.message,
        });
      });
  };
};
