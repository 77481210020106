import {
  getQuestionListDetails,
  getQuestionListActionTypes,
  GET_QUESTION_LIST_FAIL,
  GET_QUESTION_LIST_LOADING,
  GET_QUESTION_LIST_SUCCESS,
} from "./ActionTypes";
import { getQuestionListValue } from "./Model";

const initialStateGetPosts: getQuestionListDetails = {
  loading: false,
  getQuestionListRes: {} as getQuestionListValue,
};
export const getQuestionListForPatientReducer = (
  state = initialStateGetPosts,
  action: getQuestionListActionTypes,
): getQuestionListDetails => {
  switch (action.type) {
    case GET_QUESTION_LIST_SUCCESS:
      return {
        ...state,
        loading: false,
        getQuestionListRes: action.payload,
      };
    case GET_QUESTION_LIST_LOADING:
      return {
        ...state,
        loading: true,
        getQuestionListRes: {} as getQuestionListValue,
      };
    case GET_QUESTION_LIST_FAIL:
      return {
        ...state,
        getQuestionListRes: {} as getQuestionListValue,
        loading: false,
      };
    default:
      return state;
  }
};
