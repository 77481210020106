import { Box } from "@mui/material";
import dayjs from "dayjs";
import React from "react";
import { useSelector } from "react-redux";
import { PatientObservationsList } from "../../../redux/patientDashboardDataListByObservationType/Model";
import { AppState } from "../../../redux/store/Store";
import Sleep from "../Insight/Graph/SleepTrends";
import ECG from "../Insight/Graph/ECGTrends";
import ObservationsTrends from "../Insight/Graph/ObservationsTrends";
import { BPData, HearRateData, SleepData } from "../Insight/Model";
import HeartRateTrends from "../Insight/Graph/HeartRateTrends";
import { useParams } from "react-router-dom";
import { checkReturnValue, formatTimeRange } from "components/Utility";
import { BPChart } from "../Insight/Graph/BPChart";
import BMITrends from "../Insight/Graph/BMITrends";
import BloodSugarTrends from "../Insight/Graph/BloodSugarTrends";
import WeightTrends from "../Insight/Graph/WeightTrends";
import SPO2Trends from "../Insight/Graph/SPO2Trends";
import TemperatureTrends from "../Insight/Graph/TemperatureTrends";

type Props = {
  heading?: string | any;
  unit?: string | any;
};
export default function ObservationChart({ heading, unit }: Props) {
  const { observationtype } = useParams() as {
    observationtype: string;
  };
  const dataListByObservationTypeResponse = useSelector(
    (state: AppState) => state.dataListByObservationTypeRes,
  );
  const sleepScoreObservationTypeValueList = useSelector(
    (state: AppState) => state.sleepScoreObservationTypeValue,
  );
  const sleepTimeObservationTypeValueList = useSelector(
    (state: AppState) => state.sleepTimeObservationTypeValue,
  );
  const sleepScheduleObservationTypeValueList = useSelector(
    (state: AppState) => state.sleepScheduleTypeValue,
  );
  const [sleepScheduleObservationListByType, setSleepScheduleObservationListByType] =
    React.useState([] as any[]);
  const [bpListByType, setBpListByType] = React.useState([] as BPData[]);
  const [observationListByType, setObservationListByType] = React.useState([] as HearRateData[]);
  const [sleepScoreListByType, setSleepScoreListByType] = React.useState([] as any[]);
  const [sleepTimeListByType, setSleepTimeListByType] = React.useState([] as any[]);
  const [sleepDataListByType, setSleepDataListByType] = React.useState([] as any[]);
  const [sleepDataByType, setSleepDataByType] = React.useState([] as any[]);
  const [sleepListByType, setSleepListByType] = React.useState([] as SleepData[]);
  console.log("sleepListByType", sleepListByType);

  function getTotalMinutes(timeString: any) {
    const [hours, minutes] = timeString.split(/[h m]+/).map(Number);
    return hours * 60 + minutes;
  }
  function convertToMinutes(timeString: string) {
    let [hours, minutes] = timeString.split("h").map((part) => parseInt(part));
    if (hours === 12) {
      hours = 0;
    } else {
      // eslint-disable-next-line no-self-assign
      hours = hours;
    }
    return hours * 60 + minutes;
  }

  function convertTo24HourMinutes(timeString: string, _index: any) {
    if (typeof timeString !== "boolean") {
      if (timeString.includes("h")) {
        return convertToMinutes(timeString.split("-")[0]);
      } else {
        // Split the time string into time and period (AM/PM)
        const [time, period] = timeString.split(" ");
        // Split the time into hours and minutes
        const [hour, minute] = time.split(":");
        // Convert hour to integer
        let hours = parseInt(hour);
        // Convert to 24-hour format
        if (period === "PM" && hours < 12) {
          hours += 12;
        } else if (period === "AM" && hours === 12) {
          hours = 0;
        }
        // Calculate total minutes
        let totalMinutes = hours * 60 + parseInt(minute);
        if (totalMinutes < 720) {
          totalMinutes = totalMinutes + 720 + 720;
        }
        return totalMinutes;
      }
    }
  }
  React.useEffect(() => {
    if (sleepDataByType !== undefined) {
      let modifiedSleepItem = sleepDataByType.map((element: any, index: number) => {
        // Convert sleep time to minutes
        let sleepTimeInMinutes = getTotalMinutes(element.sleepTime);
        return {
          value: parseInt(element.value),
          date: element.startDate !== null ? element.startDate : "N/A",
          sleepTime: sleepTimeInMinutes,
          sleepSchedule:
            checkReturnValue(element.sleepSchedule) !== "Not Set" &&
            String(
              element.sleepSchedule.replace(
                /(\d{1,2})-(\d{2})\s([AP]M)\s~\s(\d{1,2})-(\d{2})\s([AP]M)/,
                "$1:$2 $3 to $4:$5 $6",
              ),
            ),
          sleepStartTimeInMin: convertTo24HourMinutes(
            checkReturnValue(element.sleepSchedule) !== "Not Set" &&
              formatTimeRange(String(element.sleepSchedule)),
            index,
          )!,
          sleepStartEndInMin:
            convertTo24HourMinutes(
              checkReturnValue(element.sleepSchedule) !== "Not Set" &&
                formatTimeRange(String(element.sleepSchedule)),
              index,
            )! + sleepTimeInMinutes,
          modifiedStartDate: element?.modifiedStartDate,
        };
      });
      setSleepListByType(modifiedSleepItem);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [sleepDataByType]);
  React.useEffect(() => {
    if (
      dataListByObservationTypeResponse?.dataListByObservationTypeRes?.patientObservationsList !==
      undefined
    ) {
      if (
        dataListByObservationTypeResponse?.dataListByObservationTypeRes?.patientObservationsList
          .length > 0
      ) {
        let modifiedObservationListItem =
          dataListByObservationTypeResponse?.dataListByObservationTypeRes?.patientObservationsList.map(
            (element: PatientObservationsList) =>
              ({
                value: Number(element.value),
                date: element.startDate !== null ? element.startDate : "N/A",
              } as any),
          );
        let sortArrayByLatestDate = modifiedObservationListItem.sort(
          (a, b) => new Date(a.updated_at).getTime() - new Date(b.updated_at).getTime(),
        );
        let arrayItemStartFromPastDate = sortArrayByLatestDate.reverse();
        let modifiedObservationLists = arrayItemStartFromPastDate.map(
          (element: any) =>
            ({
              value: Number(element.value),
              date:
                element.date !== null || element.date !== "N/A" ? dayjs(element.date).valueOf() : 0,
            } as HearRateData),
        );
        // remove object contains NaN as value in value key
        let finalArr: any = modifiedObservationLists.filter((item) => !Number.isNaN(item.value));
        setObservationListByType(finalArr);
        // calculation for bp
        let modifiedBpListItem =
          dataListByObservationTypeResponse?.dataListByObservationTypeRes?.patientObservationsList.map(
            (element: PatientObservationsList) =>
              ({
                high: Number(element.value.split("/")[0]),
                low: Number(element.value.split("/")[1]),
                open: Number(element.value.split("/")[0]),
                close: Number(element.value.split("/")[1]),
                date:
                  element.startDate !== null
                    ? dayjs(element.startDate).format("MMM D, YYYY")
                    : "N/A",
              } as any),
          );

        let sortBPArrayByLatestDate = modifiedBpListItem.sort(
          (a, b) => new Date(a.updated_at).getTime() - new Date(b.updated_at).getTime(),
        );
        let arrayBPItemStartFromPastDate = sortBPArrayByLatestDate.reverse();

        setBpListByType(arrayBPItemStartFromPastDate);
      } else {
        setObservationListByType([] as HearRateData[]);
      }
    } else {
      setObservationListByType([] as HearRateData[]);
    }
  }, [dataListByObservationTypeResponse]);
  //sleep values
  React.useEffect(() => {
    if (observationtype === "sleep") {
      if (
        sleepScoreObservationTypeValueList?.sleepScoreObservationTypeRes
          ?.patientObservationsList !== undefined
      ) {
        let modifiedSleepObservationList =
          sleepScoreObservationTypeValueList?.sleepScoreObservationTypeRes?.patientObservationsList.map(
            (element: PatientObservationsList) => ({
              id: element.id,
              name: element.patientObservationType.displayValue,
              value: element.value,
              startDate:
                element.startDate !== null ? dayjs(element.startDate).format("MMM D, YYYY") : "N/A",
              createDate:
                element.createDate !== null
                  ? dayjs(element.startDate).format("MMM D, YYYY")
                  : "N/A",
              modifiedDate:
                element.modifiedDate !== null
                  ? dayjs(element.startDate).format("MMM D, YYYY")
                  : "N/A",
              endDate:
                element.endDate !== null ? dayjs(element.startDate).format("MMM D, YYYY") : "N/A",
              isActive: element.isActive === true ? "Active" : "Inactive",
            }),
          );
        setSleepScoreListByType(modifiedSleepObservationList);
      }
    }
    if (
      sleepTimeObservationTypeValueList?.sleepTimeObservationTypeRes?.patientObservationsList !==
      undefined
    ) {
      let modifiedSleepTimeObservationList =
        sleepTimeObservationTypeValueList?.sleepTimeObservationTypeRes?.patientObservationsList.map(
          (element: PatientObservationsList) => ({
            id: element.id,
            name: element.patientObservationType.displayValue,
            sleepTime: element.value,
            startDate:
              element.startDate !== null ? dayjs(element.startDate).format("MMM D, YYYY") : "N/A",
            modifiedStartDate:
              element.startDate !== null && dayjs(element.startDate.split("T")[0]).valueOf(),
            createDate:
              element.createDate !== null ? dayjs(element.startDate).format("MMM D, YYYY") : "N/A",
            modifiedDate:
              element.modifiedDate !== null
                ? dayjs(element.startDate).format("MMM D, YYYY")
                : "N/A",
            endDate:
              element.endDate !== null ? dayjs(element.startDate).format("MMM D, YYYY") : "N/A",
            isActive: element.isActive === true ? "Active" : "Inactive",
          }),
        );
      setSleepTimeListByType(modifiedSleepTimeObservationList);
    }
    if (
      sleepScheduleObservationTypeValueList?.sleepScheduleObservationTypeRes
        ?.patientObservationsList !== undefined
    ) {
      let modifiedSleepObservationList =
        sleepScheduleObservationTypeValueList?.sleepScheduleObservationTypeRes?.patientObservationsList.map(
          (element: PatientObservationsList) => ({
            id: element.id,
            name: element.patientObservationType.displayValue,
            sleepSchedule: element.value,
            startDate:
              element.startDate !== null ? dayjs(element.startDate).format("MMM D, YYYY") : "N/A",
            modifiedStartDate:
              element.startDate !== null && dayjs(element.startDate.split("T")[0]).valueOf(),
            createDate:
              element.createDate !== null
                ? dayjs(element.createDate).format("ddd, DD/MM/YYYY")
                : "N/A",
            modifiedDate:
              element.modifiedDate !== null
                ? dayjs(element.modifiedDate).format("ddd, DD/MM/YYYY")
                : "N/A",
            endDate:
              element.endDate !== null ? dayjs(element.endDate).format("ddd, DD/MM/YYYY") : "N/A",
            isActive: element.isActive === true ? "Active" : "Inactive",
          }),
        );
      setSleepScheduleObservationListByType(modifiedSleepObservationList);
    } else {
      setSleepScheduleObservationListByType([] as any[]);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [
    sleepScoreObservationTypeValueList,
    sleepTimeObservationTypeValueList,
    sleepScheduleObservationTypeValueList,
  ]);

  React.useEffect(() => {
    const resultArray = [];
    if (sleepScoreListByType !== ([] as any[]) && sleepTimeListByType !== ([] as any[])) {
      for (const objA of sleepTimeListByType) {
        const matchingObjB = sleepScoreListByType.find(
          (objB: any) => objB.startDate === objA.startDate,
        );
        if (matchingObjB) {
          const mergedObj = {
            sleepTime: objA?.sleepTime?.trim(),
            startDate: objA.startDate,
            value: matchingObjB?.value.trim(),
            modifiedStartDate: matchingObjB?.modifiedStartDate,
          };

          resultArray.push(mergedObj);
        }
      }
      //let sleepMergeArray = Array.prototype.push.apply(sleepScoreListByType, sleepTimeListByType);
    }
    const newData = resultArray.map((item, index) => ({
      ...item,
      id: index + 1,
    }));
    setSleepDataListByType(newData);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [sleepScoreListByType, sleepTimeListByType]);

  React.useEffect(() => {
    const finalArray = [];
    if (
      sleepDataListByType !== ([] as any[]) &&
      sleepScheduleObservationListByType !== ([] as any[])
    ) {
      for (const objA of sleepDataListByType) {
        const matchingObjB = sleepScheduleObservationListByType.find(
          (objB: any) => objB.startDate === objA.startDate,
        );
        if (matchingObjB) {
          const mergedObj = {
            sleepTime: objA?.sleepTime?.trim(),
            sleepSchedule: matchingObjB?.sleepSchedule?.trim(),
            startDate: objA.startDate,
            value: objA?.value.trim(),
            modifiedStartDate: matchingObjB?.modifiedStartDate,
          };

          finalArray.push(mergedObj);
        }
      }
      //let sleepMergeArray = Array.prototype.push.apply(sleepScoreListByType, sleepTimeListByType);
    }
    const newData2 = finalArray.map((item, index) => ({
      ...item,
      id: index + 1,
    }));
    setSleepDataByType(newData2);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [sleepDataListByType, sleepScheduleObservationListByType]);

  return (
    <Box>
      {observationtype === "armbp" ? (
        <BPChart data={bpListByType} unit={unit}/>
      ) : observationtype === "bmi" ? (
        <BMITrends data={observationListByType} unit={unit} />
      ) : observationtype === "bloodsugar" ? (
        <BloodSugarTrends data={observationListByType} unit={unit} />
      ) : observationtype === "sleep" ? (
        <Sleep data={sleepListByType} unit={unit} />
      ) : observationtype === "bpm" ? (
        <HeartRateTrends data={observationListByType} unit={unit} />
      ) : observationtype === "spo2" ? (
        <SPO2Trends data={observationListByType} unit={unit} />
      ) : observationtype === "temp" ? (
        <TemperatureTrends data={observationListByType} unit={unit} />
      ) : observationtype === "weight" ? (
        <WeightTrends data={observationListByType} unit={unit} />
      ) : observationtype === "height" ||
        observationtype === "bodyfat" ||
        observationtype === "leanbodymass" ? (
        <ObservationsTrends data={observationListByType} type={heading} unit={unit} />
      ) : (
        <ECG />
      )}
    </Box>
  );
}
