import {
  GET_DOCTOR_TEAM_TYPE_FAIL,
  GET_DOCTOR_TEAM_TYPE_LOADING,
  GET_DOCTOR_TEAM_TYPE_SUCCESS,
  GET_DOCTOR_TEAM_TYPE_UPDATE_API_MSG,
  GET_DOCTOR_TEAM_TYPE_UPDATE_API_RES,
  GetDoctorTeamTypeForCaseActionTypes,
} from "./ActionTypes";
import { GetDoctorTeamTypeForCaseRes } from "./Model";

export const getDoctorTeamTypeForCaseLoadingAction = (
  loading: boolean,
): GetDoctorTeamTypeForCaseActionTypes => {
  return {
    type: GET_DOCTOR_TEAM_TYPE_LOADING,
    loading: loading,
  };
};

export const getDoctorTeamTypeForCaseSuccessAction = (
  getDoctorTeamTypeForCaseResponse: GetDoctorTeamTypeForCaseRes,
  successMsg: string,
): GetDoctorTeamTypeForCaseActionTypes => {
  return {
    type: GET_DOCTOR_TEAM_TYPE_SUCCESS,
    payload: getDoctorTeamTypeForCaseResponse,
    successMsg: successMsg,
  };
};

export const getDoctorTeamTypeForCaseErrorAction = (
  getDoctorTeamTypeForCaseResponse: GetDoctorTeamTypeForCaseRes,
  errMsg: string,
  status: number,
): GetDoctorTeamTypeForCaseActionTypes => {
  return {
    type: GET_DOCTOR_TEAM_TYPE_FAIL,
    payload: getDoctorTeamTypeForCaseResponse,
    errorMsg: errMsg,
    status: status,
  };
};
export const getDoctorTeamTypeForCaseUpdateAPIMsgAction = (
  getDoctorTeamTypeForCaseResponse: GetDoctorTeamTypeForCaseRes,
  successMsg: string,
  errMsg: string,
  status: number,
): GetDoctorTeamTypeForCaseActionTypes => {
  return {
    type: GET_DOCTOR_TEAM_TYPE_UPDATE_API_MSG,
    payload: getDoctorTeamTypeForCaseResponse,
    successMsg: successMsg,
    errorMsg: errMsg,
    status: status,
  };
};

export const getDoctorTeamTypeForCaseAPIResClearAction = (
  getDoctorTeamTypeForCaseResponse: GetDoctorTeamTypeForCaseRes,
  successMsg: string,
  errMsg: string,
  status: number,
): GetDoctorTeamTypeForCaseActionTypes => {
  return {
    type: GET_DOCTOR_TEAM_TYPE_UPDATE_API_RES,
    payload: getDoctorTeamTypeForCaseResponse,
    successMsg: successMsg,
    errorMsg: errMsg,
    status: status,
  };
};
