import { GetPatientEncounterListRes } from "./Model";
export const PATIENT_ENCOUNTER_LIST_LOADING = "PATIENT_ENCOUNTER_LIST_LOADING";
export const PATIENT_ENCOUNTER_LIST_SUCCESS = "PATIENT_ENCOUNTER_LIST_SUCCESS";
export const PATIENT_ENCOUNTER_LIST_FAIL = "PATIENT_ENCOUNTER_LIST_FAIL";
export const PATIENT_ENCOUNTER_LIST_UPDATE_API_MSG = "PATIENT_ENCOUNTER_LIST_UPDATE_API_MSG";
export const PATIENT_ENCOUNTER_LIST_UPDATE_API_RES = "PATIENT_ENCOUNTER_LIST_UPDATE_API_RES";

export interface GetPatientEncounterListState {
 patientEncounterListRes: GetPatientEncounterListRes;
  loading: boolean;
  successMsg: string;
  errorMsg: string;
  status: number;
}
export interface GetPatientEncounterListLoading {
  type: typeof PATIENT_ENCOUNTER_LIST_LOADING;
  loading: boolean;
}
export interface GetPatientEncounterListSuccess {
  type: typeof PATIENT_ENCOUNTER_LIST_SUCCESS;
  payload: GetPatientEncounterListRes;
  successMsg: string;
}
export interface GetPatientEncounterListFail {
  type: typeof PATIENT_ENCOUNTER_LIST_FAIL;
  payload: GetPatientEncounterListRes;
  errorMsg: string;
  status: number;
}
export interface GetPatientEncounterListUpdateAPIMsg {
  type: typeof PATIENT_ENCOUNTER_LIST_UPDATE_API_MSG;
  payload: GetPatientEncounterListRes;
  successMsg: string;
  errorMsg: string;
  status: number;
}
export interface GetPatientEncounterListUpdateAPIRes {
  type: typeof PATIENT_ENCOUNTER_LIST_UPDATE_API_RES;
  payload: GetPatientEncounterListRes;
  successMsg: string;
  errorMsg: string;
  status: number;
}

export type GetPatientEncounterListActionTypes =
  | GetPatientEncounterListLoading
  | GetPatientEncounterListSuccess
  | GetPatientEncounterListFail
  | GetPatientEncounterListUpdateAPIMsg
  | GetPatientEncounterListUpdateAPIRes;
