import {
  DataListByObservationTypeActionTypes,
  DATALIST_BY_OBSERVATION_TYPE_FAIL,
  DATALIST_BY_OBSERVATION_TYPE_LOADING,
  DATALIST_BY_OBSERVATION_TYPE_SUCCESS,
  DataListByObservationTypeState,
  DATALIST_BY_OBSERVATION_TYPE_UPDATE_API_MSG,
  DATALIST_BY_OBSERVATION_TYPE_UPDATE_API_RES,
} from "./ActionTypes";
import { DataListByObservationTypeRes } from "./Model";

const initialStateGetPosts: DataListByObservationTypeState = {
  loading: false,
  dataListByObservationTypeRes: {} as DataListByObservationTypeRes,
  successMsg: "",
  errorMsg: "",
  status: 0,
};
export const dataListByObservationTypeReducer = (
  state = initialStateGetPosts,
  action: DataListByObservationTypeActionTypes,
): DataListByObservationTypeState => {
  switch (action.type) {
    case DATALIST_BY_OBSERVATION_TYPE_LOADING:
      return {
        ...state,
        loading: action.loading,
      };
    case DATALIST_BY_OBSERVATION_TYPE_SUCCESS:
      return {
        ...state,
        dataListByObservationTypeRes: action.payload,
        successMsg: action.successMsg,
      };
    case DATALIST_BY_OBSERVATION_TYPE_FAIL:
      return {
        ...state,
        dataListByObservationTypeRes: action.payload,
        errorMsg: action.errorMsg,
        status: action.status,
      };
    case DATALIST_BY_OBSERVATION_TYPE_UPDATE_API_MSG:
      return {
        ...state,
        dataListByObservationTypeRes: action.payload,
        successMsg: action.successMsg,
        errorMsg: action.errorMsg,
        status: action.status,
      };
    case DATALIST_BY_OBSERVATION_TYPE_UPDATE_API_RES:
      return {
        ...state,
        dataListByObservationTypeRes: action.payload,
        successMsg: action.successMsg,
        errorMsg: action.errorMsg,
        status: action.status,
      };
    default:
      return state;
  }
};
