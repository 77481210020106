import React, { useEffect, useState } from "react";
import { AlertColor, Box, Button, Grid, Tab, Tabs } from "@mui/material";
import ActivePatientNoteList from "./ActivePatientNoteList";
import InActiveDrNotes from "./InactivePatientNoteList";
import MWPageTitle from "../../../components/MWPageTitle";
import { useDispatch, useSelector } from "react-redux";
import { AppState } from "../../../redux/store/Store";
import RefreshIcon from "@mui/icons-material/Refresh";
import { useParams } from "react-router-dom";
import { GetPatientNoteListBody } from "../../../redux/Notes/PatientNotesList/Model";
import { getAdminNoteListByPatient } from "../../../redux/Notes/PatientNotesList/API";
import AddPatientNote from "./AddPatientNote";
import MWSnackbar from "../../../components/MWSnackbar";
import { clearAddNoteAPIRes } from "../../../redux/Notes/addNotes/API";
import PageLayout from "../../../components/PageLayout";
import { toggleAppBarLoading } from "redux/appLoader/Actions";

interface TabPanelProps {
  children?: React.ReactNode;
  index: number;
  value: number;
}

function TabPanel(props: TabPanelProps) {
  const { children, value, index, ...other } = props;

  return (
    <Box
      role="tabpanel"
      hidden={value !== index}
      id={`simple-tabpanel-${index}`}
      aria-labelledby={`simple-tab-${index}`}
      {...other}
    >
      {value === index && <Box sx={{ mt: 1 }}>{children}</Box>}
    </Box>
  );
}
function a11yProps(index: number) {
  return {
    id: `simple-tab-${index}`,
    "aria-controls": `simple-tabpanel-${index}`,
  };
}

export default function PatientNotes() {
  const dispatch = useDispatch();
  const { profileId } = useParams() as {
    profileId: string;
  };
  const [selected, setSelected] = useState(0);
  const handleTabChange = (_event: React.SyntheticEvent, newValue: number) => {
    setSelected(newValue);
    localStorage.setItem("selctedOnboardingtab", String(newValue));
  };

  useEffect(() => {
    selected === 0
      ? dispatch(
          getAdminNoteListByPatient({
            patientId: profileId,
            isActive: true,
          } as GetPatientNoteListBody),
        )
      : dispatch(
          getAdminNoteListByPatient({
            patientId: profileId,
            isActive: false,
          } as GetPatientNoteListBody),
        );
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [selected]);

  const [addNoteDialog, setAddNoteDialog] = useState(false);
  const updateAddNoteDialogUpdateState = (passedVal: boolean) => {
    setAddNoteDialog(passedVal);
  };

  // Add Notes Api Value from store
  const addNotesVal = useSelector((state: AppState) => state.addNoteRes);
  const [addNoteAPIResMsg, setAddNoteAPIResMsg] = useState("");
  const [addNoteErrMsgType, setAddNoteErrMsgType] = useState<AlertColor>("error");
  useEffect(() => {
    if (addNotesVal?.successMsg !== "") {
      setAddNoteAPIResMsg(addNotesVal?.successMsg);
      setAddNoteErrMsgType("success");
    }
    if (addNotesVal?.errorMsg !== "") {
      setAddNoteErrMsgType("error");
      setAddNoteAPIResMsg(addNotesVal?.errorMsg);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [addNotesVal]);

  const closeModifyNoteStatusAlert = (passedVal: boolean) => {
    if (passedVal) {
      dispatch(clearAddNoteAPIRes());
    }
  };

  const [activeNoteLoader, setActiveNoteLoader] = useState(false);
  const updateActiveNoteLoaderUpdateState = (passedVal: boolean) => {
    setActiveNoteLoader(passedVal);
  };
  const [inactiveNoteLoader, setInactiveNoteLoader] = useState(false);
  const updateInactiveNoteLoaderUpdateState = (passedVal: boolean) => {
    setInactiveNoteLoader(passedVal);
  };

  useEffect(() => {
    dispatch(toggleAppBarLoading(activeNoteLoader || inactiveNoteLoader));
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [activeNoteLoader, inactiveNoteLoader]);

  return (
    <Box>
      <PageLayout>
        <Grid container direction="row" justifyContent="space-between" alignItems="center">
          <Grid item>
            <MWPageTitle backButton={true} title="Patient Notes" />
          </Grid>
          <Grid item>
            {selected === 0 ? (
              <Button
                variant="contained"
                sx={{ textTransform: "none" }}
                onClick={() => setAddNoteDialog(true)}
              >
                Add Note
              </Button>
            ) : null}
          </Grid>
        </Grid>
        <Box>
          <Tabs value={selected} onChange={handleTabChange}>
            <Tab
              label="Active Note List"
              {...a11yProps(0)}
              icon={
                <RefreshIcon
                  onClick={() =>
                    dispatch(
                      getAdminNoteListByPatient({
                        patientId: profileId,
                        isActive: true,
                      } as GetPatientNoteListBody),
                    )
                  }
                />
              }
              iconPosition="end"
            />
            <Tab
              label="Inactive Note List"
              {...a11yProps(1)}
              icon={
                <RefreshIcon
                  onClick={() =>
                    dispatch(
                      getAdminNoteListByPatient({
                        patientId: profileId,
                        isActive: false,
                      } as GetPatientNoteListBody),
                    )
                  }
                />
              }
              iconPosition="end"
            />
          </Tabs>
          <TabPanel value={selected} index={0}>
            {<ActivePatientNoteList activeNoteLoader={updateActiveNoteLoaderUpdateState} />}
          </TabPanel>
          <TabPanel value={selected} index={1}>
            {<InActiveDrNotes inactiveNoteLoader={updateInactiveNoteLoaderUpdateState} />}
          </TabPanel>
        </Box>
      </PageLayout>
      {addNoteDialog ? (
        <AddPatientNote
          addNoteDialog={addNoteDialog}
          updateAddNoteDialogUpdateState={updateAddNoteDialogUpdateState}
        />
      ) : null}
      <MWSnackbar
        msg={addNoteAPIResMsg}
        type={addNoteErrMsgType}
        alertClose={closeModifyNoteStatusAlert}
      />
    </Box>
  );
}
