import { GET_HEALTHCAMP_LIST } from "./ActionTypes";
import { AdminGetHealthcampListActionTypes } from "./ActionTypes";
import { AdminHealthcampList } from "./Model";

export const getHealthcampListAction = (
  healthcamplist: AdminHealthcampList,
): AdminGetHealthcampListActionTypes => {
  return {
    type: GET_HEALTHCAMP_LIST,
    payload: healthcamplist,
  };
};
