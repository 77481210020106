import {
  AddChiefComplaintAction,
  DeleteDocumentAction,
  DeletePatientAction,
  getCaseAction,
  getChiefComplaintAction,
  getDoctorAction,
  getPatientAction,
  getQuestionAction,
  getSharedPatientAction,
  getTaskAction,
  getOwnerAppoinmentAction,
  getOrganizationListAction,
} from "../actions/Actions";
import { Dispatch } from "redux";
import {
  AddChiefComplaintTypes,
  ADD_CC,
  CaseGetActionTypes,
  CASE_LIST_FAIL,
  CASE_LIST_LOADING,
  CASE_LIST_SUCCESS,
  CC_ADD_LIST_FAIL,
  CC_ADD_LIST_LOADING,
  CC_ADD_LIST_SUCCESS,
  CC_LIST_FAIL,
  CC_LIST_LOADING,
  CC_LIST_SUCCESS,
  ChiefComplaintGetActionTypes,
  DeleteDocumentTypes,
  DeletePatientTypes,
  DELETE_DOCUMENT,
  DELETE_DOCUMENT_LIST_FAIL,
  DELETE_DOCUMENT_LIST_LOADING,
  DELETE_DOCUMENT_LIST_SUCCESS,
  DELETE_PATIENT,
  DELETE_PATIENT_LIST_FAIL,
  DELETE_PATIENT_LIST_LOADING,
  DELETE_PATIENT_LIST_SUCCESS,
  DoctorActionTypes,
  DOCTOR_LIST_FAIL,
  DOCTOR_LIST_LOADING,
  DOCTOR_LIST_SUCCESS,
  PatientActionTypes,
  PATIENT_LIST_FAIL,
  PATIENT_LIST_LOADING,
  PATIENT_LIST_SUCCESS,
  QuestionGetActionTypes,
  QUESTION_LIST_FAIL,
  QUESTION_LIST_LOADING,
  QUESTION_LIST_SUCCESS,
  SharedPatientActionTypes,
  SHARED_PATIENT_LIST_FAIL,
  SHARED_PATIENT_LIST_LOADING,
  SHARED_PATIENT_LIST_SUCCESS,
  TaskGetActionTypes,
  TASK_LIST_SUCCESS,
  TASK_LIST_FAIL,
  TASK_LIST_LOADING,
  OwnerAppoinmentListActionTypes,
  OWNER_APPOINMENT_LIST_LOADING,
  OWNER_APPOINMENT_LIST_SUCCESS,
  OWNER_APPOINMENT_LIST_FAIL,
  ORGANIZATION_LIST_LOADING,
  ORGANIZATION_LIST_SUCCESS,
  ORGANIZATION_LIST_FAIL,
  AdminGetOrganizationListActionTypes,
} from "../types/ActionTypes";
import axios from "axios";
import Url from "../../Url";
import { setupInterceptorsTo } from "../Authentication/Interceptors";
import { headers } from "components/Utility";

let ccBody = {};

export const getDoctorListValue = () => {
  return function (dispatch: Dispatch<DoctorActionTypes>) {
    dispatch({
      type: DOCTOR_LIST_LOADING,
    });

    setupInterceptorsTo(axios)
      .get(Url.apiURL + "/doctorapp/api/doctor/active_doctor_list", { headers: headers })
      .then((res) => {
        let resData = res.data.doctorsList;
        dispatch(getDoctorAction(resData));
        dispatch({
          type: DOCTOR_LIST_SUCCESS,
          payload: resData,
        });
      })
      .catch((_error) => {
        dispatch({
          type: DOCTOR_LIST_FAIL,
        });
      });
  };
};

// Patient List API
export const getPatientListValue = () => {
  return function (dispatch: Dispatch<PatientActionTypes>) {
    dispatch({
      type: PATIENT_LIST_LOADING,
    });

    setupInterceptorsTo(axios)
      .get(Url.apiURL + "/patientapp/api/patient/patient_list", { headers: headers })
      .then((res) => {
        let patientresData = res.data.patientList;
        dispatch(getPatientAction(patientresData));
        dispatch({
          type: PATIENT_LIST_SUCCESS,
          payload: patientresData,
        });
      })
      .catch((_error) => {
        dispatch({
          type: PATIENT_LIST_FAIL,
        });
      });
  };
};

// Organization List
export const getOrganizationListValue = () => {
  return function (dispatch: Dispatch<AdminGetOrganizationListActionTypes>) {
    dispatch({
      type: ORGANIZATION_LIST_LOADING,
    });
    axios
      .get(Url.apiURL + "/adminapp/api/patientorganizationlist/org_list_where_as_administrator", {
        headers: headers,
      })
      .then((res) => {
        let organizationData = res.data.patientOrganizationList;
        dispatch(getOrganizationListAction(organizationData));
        dispatch({
          type: ORGANIZATION_LIST_SUCCESS,
          payload: organizationData,
        });
      })
      .catch((_error) => {
        dispatch({
          type: ORGANIZATION_LIST_FAIL,
        });
      });
  };
};

// Shared Patient List API
export const getSharedPatientListValue = () => {
  return function (dispatch: Dispatch<SharedPatientActionTypes>) {
    dispatch({
      type: SHARED_PATIENT_LIST_LOADING,
    });

    setupInterceptorsTo(axios)
      .get(Url.apiURL + "/patientapp/api/patient/shared_patient_list", { headers: headers })
      .then((res) => {
        let patientresData = res.data.patientList;
        dispatch(getSharedPatientAction(patientresData));
        dispatch({
          type: SHARED_PATIENT_LIST_SUCCESS,
          payload: patientresData,
        });
      })
      .catch((_error) => {
        dispatch({
          type: SHARED_PATIENT_LIST_FAIL,
        });
      });
  };
};

export const getEncounterList = (patientid: string) => {
  return function (dispatch: Dispatch<CaseGetActionTypes>) {
    dispatch({
      type: CASE_LIST_LOADING,
    });

    setupInterceptorsTo(axios)
      .post(
        Url.apiURL + "/patientapp/api/patient/patient_case_list",
        { patientId: patientid },
        { headers: headers },
      )
      .then((res) => {
        let resData = res.data.patientCaseList;
        let patientName = res.data;
        dispatch(getCaseAction(resData, patientName));
        dispatch({
          type: CASE_LIST_SUCCESS,
          payload: resData,
          patientname: patientName,
        });
      })
      .catch((error) => {
        console.log("error record====", error);
        dispatch({
          type: CASE_LIST_FAIL,
        });
      });
  };
};

export const getQuestionList = (getChiefComplaintBody: object) => {
  return function (dispatch: Dispatch<QuestionGetActionTypes>) {
    dispatch({
      type: QUESTION_LIST_LOADING,
    });

    setupInterceptorsTo(axios)
      .post(
        Url.apiURL + "/patientapp/api/patientMedicalData/get_patientQuestionnaireList",
        getChiefComplaintBody,
        { headers: headers },
      )
      .then((res) => {
        let resData = res.data.patientQuestionnaireList;
        dispatch(getQuestionAction(resData));
        dispatch({
          type: QUESTION_LIST_SUCCESS,
          payload: resData,
        });
      })
      .catch((error) => {
        console.log("error record====", error);
        dispatch({
          type: QUESTION_LIST_FAIL,
        });
      });
  };
};

export const getChiefComplaintList = (getChiefComplaintBody: object) => {
  return function (dispatch: Dispatch<ChiefComplaintGetActionTypes>) {
    ccBody = getChiefComplaintBody;

    dispatch({
      type: CC_LIST_LOADING,
    });

    setupInterceptorsTo(axios)
      .post(
        Url.apiURL + "/patientapp/api/patientMedicalData/get_patientDetailsList",
        getChiefComplaintBody,
        { headers: headers },
      )
      .then((res) => {
        let resData = res.data.patientDetailList;
        dispatch(getChiefComplaintAction(resData));
        dispatch({
          type: CC_LIST_SUCCESS,
          payload: resData,
        });
      })
      .catch((error) => {
        console.log("error record====", error);
        dispatch({
          type: CC_LIST_FAIL,
        });
      });
  };
};

export const addChiefComplaint = (state = {}) => {
  return function (dispatch: Dispatch<AddChiefComplaintTypes>) {
    dispatch({
      type: CC_ADD_LIST_LOADING,
    });
    dispatch(AddChiefComplaintAction(ADD_CC));
    setupInterceptorsTo(axios)
      .post(Url.apiURL + "/patientapp/api/patientMedicalData/add_patientDetails", state, {
        headers: headers,
      })
      .then((result) => {
        if (result) {
          dispatch({
            type: CC_ADD_LIST_SUCCESS,
            payload: result.data,
          });

          dispatch({
            type: CC_LIST_LOADING,
          });

          setupInterceptorsTo(axios)
            .post(
              Url.apiURL + "/patientapp/api/patientMedicalData/get_patientDetailsList",
              ccBody,
              { headers },
            )
            .then((res) => {
              let resData = res.data.patientDetailList;
              dispatch(getChiefComplaintAction(resData));
              dispatch({
                type: CC_LIST_SUCCESS,
                payload: resData,
              });
            })
            .catch((error) => {
              console.log("error record====", error);
              dispatch({
                type: CC_LIST_FAIL,
              });
            });
        }
      })
      .catch((error) => {
        dispatch({
          type: CC_ADD_LIST_FAIL,
          payload: error.response.data,
        });
      });
  };
};

export const deleteFilePreparationDocument = (state = {}) => {
  return function (dispatch: Dispatch<DeleteDocumentTypes>) {
    dispatch({
      type: DELETE_DOCUMENT_LIST_LOADING,
    });
    dispatch(DeleteDocumentAction(DELETE_DOCUMENT));
    setupInterceptorsTo(axios)
      .post(Url.apiURL + "/patientapp/api/patientMedicalData/delete_patientDetails", state, {
        headers: headers,
      })
      .then((result) => {
        if (result) {
          dispatch({
            type: DELETE_DOCUMENT_LIST_SUCCESS,
            payload: result.data,
          });

          dispatch({
            type: CC_LIST_LOADING,
          });

          setupInterceptorsTo(axios)
            .post(
              Url.apiURL + "/patientapp/api/patientMedicalData/get_patientDetailsList",
              ccBody,
              { headers },
            )
            .then((res) => {
              let resData = res.data.patientDetailList;
              dispatch(getChiefComplaintAction(resData));
              dispatch({
                type: CC_LIST_SUCCESS,
                payload: resData,
              });
            })
            .catch((error) => {
              console.log("error record====", error);
              dispatch({
                type: CC_LIST_FAIL,
              });
            });
        }
      })
      .catch((error) => {
        dispatch({
          type: DELETE_DOCUMENT_LIST_FAIL,
          payload: error.response.data,
        });
      });
  };
};

export const deletePatient = (state = {}) => {
  return function (dispatch: Dispatch<DeletePatientTypes>) {
    dispatch({
      type: DELETE_PATIENT_LIST_LOADING,
    });
    dispatch(DeletePatientAction(DELETE_PATIENT));
    setupInterceptorsTo(axios)
      .post(Url.apiURL + "/patientapp/api/patient/deactivate_patient", state, { headers: headers })
      .then((result) => {
        if (result) {
          dispatch({
            type: DELETE_PATIENT_LIST_SUCCESS,
            payload: result.data,
          });

          dispatch({
            type: PATIENT_LIST_LOADING,
          });

          setupInterceptorsTo(axios)
            .get(Url.apiURL + "/patientapp/api/patient/patient_list", { headers: headers })
            .then((res) => {
              let patientresData = res.data.patientList;
              dispatch(getPatientAction(patientresData));
              dispatch({
                type: PATIENT_LIST_SUCCESS,
                payload: patientresData,
              });
            })
            .catch((_error) => {
              dispatch({
                type: PATIENT_LIST_FAIL,
              });
            });
        }
      })
      .catch((_error) => {
        dispatch({
          type: DELETE_PATIENT_LIST_FAIL,
        });
      });
  };
};
// Get task List.........
export const getTaskList = () => {
  return function (dispatch: Dispatch<TaskGetActionTypes>) {
    dispatch({
      type: TASK_LIST_LOADING,
    });

    setupInterceptorsTo(axios)
      .get(Url.apiURL + "/patientapp/api/patient/patient_task_list", { headers: headers })
      .then((res) => {
        let resData = res.data.taskDataList;
        dispatch(getTaskAction(resData));
        dispatch({
          type: TASK_LIST_SUCCESS,
          payload: resData,
        });
      })
      .catch((error) => {
        console.log("Task List Error Record====", error);
        dispatch({
          type: TASK_LIST_FAIL,
        });
      });
  };
};
// Get Appointment list for Owner
export const appoinmentRequestListForOwner = (state = {}) => {
  return function (dispatch: Dispatch<OwnerAppoinmentListActionTypes>) {
    dispatch({
      type: OWNER_APPOINMENT_LIST_LOADING,
    });

    setupInterceptorsTo(axios)
      .post(Url.apiURL + "/appointmentapp/api/appointment/get_appointment_list_for_owner", state, {
        headers: headers,
      })
      .then((res) => {
        let resData = res.data.appointmentRequestList;
        dispatch(getOwnerAppoinmentAction(resData));
        dispatch({
          type: OWNER_APPOINMENT_LIST_SUCCESS,
          payload: resData,
        });
      })
      .catch((error) => {
        console.log("error record====", error);
        dispatch({
          type: OWNER_APPOINMENT_LIST_FAIL,
        });
      });
  };
};
// Get Appointment list for patient case
export const appoinmentRequestListForCase = (state = {}) => {
  return function (dispatch: Dispatch<OwnerAppoinmentListActionTypes>) {
    dispatch({
      type: OWNER_APPOINMENT_LIST_LOADING,
    });

    setupInterceptorsTo(axios)
      .post(
        Url.apiURL + "/appointmentapp/api/appointment/get_appointment_details_for_case",
        state,
        { headers: headers },
      )
      .then((res) => {
        let resData = res.data.appointmentRequestList;
        dispatch(getOwnerAppoinmentAction(resData));
        dispatch({
          type: OWNER_APPOINMENT_LIST_SUCCESS,
          payload: resData,
        });
      })
      .catch((error) => {
        console.log("error record====", error);
        dispatch({
          type: OWNER_APPOINMENT_LIST_FAIL,
        });
      });
  };
};
export const getPatientCaseList = (patientid: string) => {
  return function (dispatch: Dispatch<CaseGetActionTypes>) {
    dispatch({
      type: CASE_LIST_LOADING,
    });

    setupInterceptorsTo(axios)
      .post(
        Url.apiURL + "/adminapp/api/adminActions/patient_case_list",
        { patientId: patientid },
        { headers: headers },
      )
      .then((res) => {
        let resData = res.data.patientCaseList;
        let patientName = res.data;
        dispatch(getCaseAction(resData, patientName));
        dispatch({
          type: CASE_LIST_SUCCESS,
          payload: resData,
          patientname: patientName,
        });
      })
      .catch((error) => {
        console.log("error record====", error);
        dispatch({
          type: CASE_LIST_FAIL,
        });
      });
  };
};
