import {
  RelationshipListDetails,
  GetRelationshipListActionTypes,
  GET_RELATIONSHIP_LIST_FAIL,
  GET_RELATIONSHIP_LIST_LOADING,
  GET_RELATIONSHIP_LIST_SUCCESS,
} from "./ActionTypes";
import { GetRelationshipListRes } from "./Model";

const initialStateGetPosts: RelationshipListDetails = {
  loading: false,
  getRelationshipListRes: {} as GetRelationshipListRes,
  errRes: "",
};
export const getRelationshipListReducer = (
  state = initialStateGetPosts,
  action: GetRelationshipListActionTypes,
): RelationshipListDetails => {
  switch (action.type) {
    case GET_RELATIONSHIP_LIST_SUCCESS:
      return {
        ...state,
        loading: false,
        getRelationshipListRes: action.payload,
        errRes: "",
      };
    case GET_RELATIONSHIP_LIST_LOADING:
      return {
        ...state,
        loading: true,
        getRelationshipListRes: {} as GetRelationshipListRes,
      };
    case GET_RELATIONSHIP_LIST_FAIL:
      return {
        ...state,
        getRelationshipListRes: {} as GetRelationshipListRes,
        loading: false,
        errRes: action.errRes,
      };
    default:
      return state;
  }
};
