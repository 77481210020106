import axios from "axios";
import { Dispatch } from "redux";
import Url from "../../Url";
import { setupInterceptorsTo } from "../Authentication/Interceptors";
import { getSharedPatientDetailsAction } from "./Action";
import {
  sharedPatientDetailsActionTypes,
  SHARED_PATIENT_LIST_FAIL,
  SHARED_PATIENT_LIST_LOADING,
  SHARED_PATIENT_LIST_SUCCESS,
} from "./ActionTypes";
import { headers } from "components/Utility";

export const getSharedPatientDetails = (patientid: string) => {
  return function (dispatch: Dispatch<sharedPatientDetailsActionTypes>) {
    dispatch({
      type: SHARED_PATIENT_LIST_LOADING,
    });

    setupInterceptorsTo(axios)
      .post(
        Url.apiURL + `/patientapp/api/patient/patient_share_team_members`,
        { patientId: patientid },
        {
          headers: headers,
        },
      )
      .then((res) => {
        dispatch(getSharedPatientDetailsAction(res.data));
        dispatch({
          type: SHARED_PATIENT_LIST_SUCCESS,
          payload: res.data,
        });
      })
      .catch((_error) => {
        dispatch({
          type: SHARED_PATIENT_LIST_FAIL,
        });
      });
  };
};
