import {
  AddOrEditImportConfigStateType,
  AddOrEditImportConfigTypes,
  ADD_OR_EDIT_IMPORT_CONFIG_LIST_FAIL,
  ADD_OR_EDIT_IMPORT_CONFIG_LIST_LOADING,
  ADD_OR_EDIT_IMPORT_CONFIG_LIST_SUCCESS,
  ImportConfigListStateType,
  ImportConfigListTypes,
  ImportDataListStateType,
  ImportDataListTypes,
  IMPORT_CONFIG_LIST_FAIL,
  IMPORT_CONFIG_LIST_LOADING,
  IMPORT_CONFIG_LIST_SUCCESS,
  IMPORT_DATA_LIST_FAIL,
  IMPORT_DATA_LIST_LOADING,
  IMPORT_DATA_LIST_SUCCESS,
} from "./ActionTypes";

// get appointment payment list
const initialStatePaymentList: ImportConfigListStateType = {
  loading: false,
  patientDataImportConfigList: [],
  message: Object.assign({}),
  status: Object.assign({}),
};

export const getPatientDataImportConfigListReducer = (
  state = initialStatePaymentList,
  action: ImportConfigListTypes,
): ImportConfigListStateType => {
  switch (action.type) {
    case IMPORT_CONFIG_LIST_SUCCESS:
      return {
        ...state,
        loading: false,
        patientDataImportConfigList: action.patientDataImportConfigList,
        message: action.message,
        status: action.status,
      };
    case IMPORT_CONFIG_LIST_LOADING:
      return {
        ...state,
        loading: true,
      };
    case IMPORT_CONFIG_LIST_FAIL:
      return {
        ...state,
        loading: false,
        message: action.message,
        status: action.status,
      };
    default:
      return state;
  }
};
// Add or edit import config
const initialStateAddOrEditImportConfigData: AddOrEditImportConfigStateType = {
  loading: false,
  message: Object.assign({}),
  status: Object.assign({}),
};

export const addOrEditPatientDataImportConfigReducer = (
  state = initialStateAddOrEditImportConfigData,
  action: AddOrEditImportConfigTypes,
): AddOrEditImportConfigStateType => {
  switch (action.type) {
    case ADD_OR_EDIT_IMPORT_CONFIG_LIST_SUCCESS:
      return {
        ...state,
        loading: false,
        message: action.message,
        status: action.status,
      };
    case ADD_OR_EDIT_IMPORT_CONFIG_LIST_LOADING:
      return {
        ...state,
        loading: true,
      };
    case ADD_OR_EDIT_IMPORT_CONFIG_LIST_FAIL:
      return {
        ...state,
        loading: false,
        message: action.message,
        status: action.status,
      };
    default:
      return state;
  }
};

// get appointment payment list
const initialStateImportDataList: ImportDataListStateType = {
  loading: false,
  patientDetailList: [],
  message: Object.assign({}),
  status: Object.assign({}),
};

export const getPatientImportListReducer = (
  state = initialStateImportDataList,
  action: ImportDataListTypes,
): ImportDataListStateType => {
  switch (action.type) {
    case IMPORT_DATA_LIST_SUCCESS:
      return {
        ...state,
        loading: false,
        patientDetailList: action.patientDetailList,
        message: action.message,
        status: action.status,
      };
    case IMPORT_DATA_LIST_LOADING:
      return {
        ...state,
        loading: true,
      };
    case IMPORT_DATA_LIST_FAIL:
      return {
        ...state,
        loading: false,
        message: action.message,
        status: action.status,
      };
    default:
      return state;
  }
};
