import React, { useCallback, useState } from "react";
import {} from "react-router-dom";
import "react-big-calendar/lib/css/react-big-calendar.css";
import AddIcon from "@mui/icons-material/Add";
import RefreshIcon from "@mui/icons-material/Refresh";
import {
  Typography,
  Box,
  Card,
  CardHeader,
  CardContent,
  Grid,
  Button,
  Dialog,
  DialogTitle,
  IconButton,
  Divider,
  DialogContent,
  DialogActions,
  TextField,
} from "@mui/material";
import MWLoader from "../../components/MWLoader";
import MWTextField from "components/MWTextField";
import CloseIcon from "@mui/icons-material/Close";
import { DataGrid, GridColDef, GridToolbar } from "@mui/x-data-grid";
import { DesktopDatePicker, LocalizationProvider } from "@mui/x-date-pickers";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
export default function NewPayment() {
  const loadingMarkup = <MWLoader />;
  const [loading] = React.useState<boolean | undefined>(false);
  const columns: GridColDef[] = [
    {
      field: "id",
      headerName: "No of Payment",
      width: 150,
    },
    { field: "vDate", headerName: "Payment Date", width: 150 },
    { field: "patientName", headerName: "Amount", width: 207 },
    { field: "problemName", headerName: "Payment Status", width: 250 },
    { field: "remark", headerName: "Remark", width: 300 },
    { field: "balance", headerName: "Balance", width: 250 },
  ];
  const rows = [
    {
      id: 1,
      vDate: "2nd jan,2024",
      patientName: 1000,
      problemName: "Success",
      balance: 1000,
      remark: " Deducted for Quarterly healthcare plan ",
    },
    {
      id: 2,
      vDate: "2nd feb,2024",
      patientName: 500,
      problemName: "Failed",
      balance: 1500,
      remark: " Deducted for one month healthcare plan ",
    },
  ];
  const [addNewPaymentModalActive, setAddNewPaymentModalActive] = useState(false);
  const addInsuranceToggle = () => {
    setAddNewPaymentModalActive((addNewPaymentModalActive) => !addNewPaymentModalActive);
  };
  const [cardno, setCardno] = useState("");
  const handleCardNoChange = useCallback((value: any) => {
    setCardno(value);
  }, []);
  const [selectedDate, setSelectedDate] = React.useState<Date | null>(new Date());
  const handleDateChange = (enddate: Date | null) => {
    setSelectedDate(enddate);
  };

  const [cardHolderName, setCardHolderName] = useState("");
  const handleCardHolderNameChange = useCallback((value: any) => {
    setCardHolderName(value);
  }, []);

  const [cardCvv, setCardCvv] = useState("");
  const handleCardCvvChange = useCallback((value: any) => {
    setCardCvv(value);
  }, []);

  const addPayment = () => {
    setAddNewPaymentModalActive((addNewPaymentModalActive) => !addNewPaymentModalActive);
    setCardno("");
    setCardno("");
  };

  const caregivervisitBody = (
    <Card>
      <CardHeader
        title={
          <Grid container direction="row" justifyContent="space-between" alignItems="flex-start">
            <Grid item></Grid>
            <Grid item>
              <Button variant="outlined" startIcon={<AddIcon />} onClick={addPayment}>
                Add new payment
              </Button>
            </Grid>
          </Grid>
        }
      />
      <CardContent>
        <DataGrid
          rows={rows}
          columns={columns}
          getRowId={(row) => row.id}
          hideFooter
          hideFooterPagination
          disableSelectionOnClick
          experimentalFeatures={{ newEditingApi: true }}
          autoHeight
          components={{ Toolbar: GridToolbar }}
          componentsProps={{
            toolbar: {
              csvOptions: { disableToolbarButton: true },
              printOptions: { disableToolbarButton: true },
              showQuickFilter: true,
              quickFilterProps: { debounceMs: 250 },
            },
          }}
          disableColumnFilter
          disableColumnSelector
          disableDensitySelector
        />
      </CardContent>
    </Card>
  );

  // payment submit API
  const SubmitNewPaymentDetails = () => {};

  // ModalMarkUp
  const addNewPaymentMarkup = (
    <Dialog open={addNewPaymentModalActive} onClose={addInsuranceToggle} fullWidth>
      <DialogTitle>
        <Grid container direction="row" justifyContent="space-between" alignItems="flex-start">
          <Grid item>
            <Typography variant="h6">New Payment</Typography>
          </Grid>
          <Grid item>
            <IconButton
              aria-label="close"
              onClick={addInsuranceToggle}
              sx={{
                position: "absolute",
                right: 8,
                top: 8,
                color: (theme) => theme.palette.grey[500],
              }}
            >
              <CloseIcon />
            </IconButton>
          </Grid>
        </Grid>
      </DialogTitle>
      <Divider />
      <DialogContent>
        <Grid
          container
          direction="row"
          justifyContent="flex-start"
          alignItems="flex-start"
          spacing={2}
        >
          <Grid item xs={6} sm={6} md={6} lg={6}>
            <Box pt={2}>
              <MWTextField
                label="Enter card Holder Name"
                required
                placeholder="Please Enter card Holder Name"
                value={cardHolderName}
                onChange={(e) => handleCardHolderNameChange(e.target.value)}
                multiline
                maxRows={2}
                id="insuranceDetailsID"
                fullWidth
              />
            </Box>

            <Box pt={2}>
              <LocalizationProvider dateAdapter={AdapterDayjs}>
                <DesktopDatePicker
                  views={["year", "month"]}
                  minDate={new Date("2024-01-01")}
                  label="card expiry Date"
                  inputFormat="MM/YY"
                  value={selectedDate}
                  onChange={(date) => handleDateChange(date)}
                  renderInput={(params: any) => (
                    <TextField
                      {...params}
                      fullWidth
                      inputProps={{
                        ...params.inputProps,
                        readOnly: true,
                        placeholder: "Enter card expiry Date",
                      }}
                      size=""
                    />
                  )}
                  InputAdornmentProps={{ position: "start" }}
                />
              </LocalizationProvider>
            </Box>
          </Grid>
          <Grid item xs={6} sm={6} md={6} lg={6}>
            <Box pt={2}>
              <MWTextField
                label="Enter credit/debit card no"
                required
                placeholder="Please Enter credit/debit card no"
                value={cardno}
                onChange={(e) => handleCardNoChange(e.target.value)}
                type="text"
                id="insuranceID"
                fullWidth
              />
            </Box>

            <Box pt={2}>
              <MWTextField
                label="Enter CVV no"
                required
                placeholder="Please Enter CVV no"
                value={cardCvv}
                onChange={(e) => handleCardCvvChange(e.target.value)}
                type="number"
                id="cvvID"
                fullWidth
              />
            </Box>
          </Grid>
        </Grid>

        <Box pt={2}></Box>
      </DialogContent>
      <Divider />
      <DialogActions>
        <Button onClick={SubmitNewPaymentDetails} variant="contained">
          Submit
        </Button>
      </DialogActions>
    </Dialog>
  );

  return (
    <Box>
      {loading ? loadingMarkup : null}
      <Grid container direction="row" justifyContent="flex-start" alignItems="center" spacing={1}>
        <Grid item>
          <Typography variant="h5">New Payment</Typography>
        </Grid>
        <Grid item>
          <IconButton aria-label="refresh">
            <RefreshIcon />
          </IconButton>
        </Grid>
      </Grid>
      {caregivervisitBody}
      {addNewPaymentMarkup}
    </Box>
  );
}
