import React from "react";
import { Box, Grid, Tab } from "@mui/material";
import ImportedDataList from "./ImportedDataList";
import ImportedConfigFile from "./ImportedConfig";
import { t } from "i18next";
import { TabContext, TabList, TabPanel } from "@mui/lab";

export default function ImportedData() {
  const [selected, setSelected] = React.useState("1");
  const handleTabChange = (_event: React.SyntheticEvent, newValue: string) => {
    setSelected(newValue);
  };

  const actualPageMarkup = (
    <Grid>
      <Grid item>
        <Box sx={{ width: "100%" }}>
          <TabContext value={String(selected)}>
            <Box sx={{ borderBottom: 1, borderColor: "divider" }}>
              <TabList onChange={handleTabChange}>
                <Tab label={t("impDataPage.imptdData", { ns: ["home"] })} value="1" />
                <Tab label={t("impDataPage.impConfig", { ns: ["home"] })} value="2" />
              </TabList>
            </Box>
            <TabPanel value="1">{<ImportedDataList />}</TabPanel>
            <TabPanel value="2">{<ImportedConfigFile />}</TabPanel>
          </TabContext>
        </Box>
      </Grid>
    </Grid>
  );
  return (
    <Box>
      {/* <Typography>{t("impDataPage.impData", { ns: ["home"] })!}</Typography> */}
      {actualPageMarkup}
    </Box>
  );
}
