import {
  ADD_FAVORITE_PATIENT_FAIL,
  ADD_FAVORITE_PATIENT_LOADING,
  ADD_FAVORITE_PATIENT_SUCCESS,
  AddFavoritePatientActionTypes,
} from "./ActionTypes";
import { AddFavoritePatientDetails } from "./Model";

export const AddFavoritePatientLoadingAction = (
  loading: boolean,
): AddFavoritePatientActionTypes => {
  return {
    type: ADD_FAVORITE_PATIENT_LOADING,
    loading: loading,
  };
};

export const AddFavoritePatientSuccessAction = (
  addPatientDataPointResponse: AddFavoritePatientDetails,
): AddFavoritePatientActionTypes => {
  return {
    type: ADD_FAVORITE_PATIENT_SUCCESS,
    payload: addPatientDataPointResponse,
  };
};

export const AddFavoritePatientErrorAction = (
  addPatientDataPointResponse: AddFavoritePatientDetails,
  errMsg: string,
  status: number,
): AddFavoritePatientActionTypes => {
  return {
    type: ADD_FAVORITE_PATIENT_FAIL,
    payload: addPatientDataPointResponse,
    message: errMsg,
    status: status,
  };
};
