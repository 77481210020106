import {
    PatientDetailsTypeListActionTypes,
    PATIENT_DETAILS_TYPE_LIST_FAIL,
    PATIENT_DETAILS_TYPE_LIST_LOADING,
    PATIENT_DETAILS_TYPE_LIST_SUCCESS,
    PatientDetailsTypeListState,
    PATIENT_DETAILS_TYPE_LIST_UPDATE_API_MSG,
    PATIENT_DETAILS_TYPE_LIST_UPDATE_API_RES,
  } from "./ActionTypes";
  import { PatientDetailsTypeRes } from "./Model";
  
  const initialStateGetPosts: PatientDetailsTypeListState = {
    loading: false,
    patientDetailsTypeListRes: {} as PatientDetailsTypeRes,
    successMsg: "",
    errorMsg: "",
    status: 0,
  };
  export const patientDetailsTypeReducer = (
    state = initialStateGetPosts,
    action: PatientDetailsTypeListActionTypes
  ): PatientDetailsTypeListState => {
    switch (action.type) {
      case PATIENT_DETAILS_TYPE_LIST_LOADING:
        return {
          ...state,
          loading: action.loading,
        };
      case PATIENT_DETAILS_TYPE_LIST_SUCCESS:
        return {
          ...state,
          patientDetailsTypeListRes: action.payload,
          successMsg: action.successMsg,
        };
      case PATIENT_DETAILS_TYPE_LIST_FAIL:
        return {
          ...state,
          patientDetailsTypeListRes: action.payload,
          errorMsg: action.errorMsg,
          status: action.status,
        };
      case PATIENT_DETAILS_TYPE_LIST_UPDATE_API_MSG:
        return {
          ...state,
          patientDetailsTypeListRes: action.payload,
          successMsg: action.successMsg,
          errorMsg: action.errorMsg,
          status: action.status,
        };
      case PATIENT_DETAILS_TYPE_LIST_UPDATE_API_RES:
        return {
          ...state,
          patientDetailsTypeListRes: action.payload,
          successMsg: action.successMsg,
          errorMsg: action.errorMsg,
          status: action.status,
        };
      default:
        return state;
    }
  };
  