import { ConnectEndPointRes } from "./Model";
export const CONNECT_END_POINT_LOADING = "CONNECT_END_POINT_LOADING";
export const CONNECT_END_POINT_SUCCESS = "CONNECT_END_POINT_SUCCESS";
export const CONNECT_END_POINT_FAIL = "CONNECT_END_POINT_FAIL";
export const CONNECT_END_POINT_DATA = "CONNECT_END_POINT_DATA";
export const CONNECT_END_POINT_UPDATE_API_MSG = "CONNECT_END_POINT_UPDATE_API_MSG";
export const CONNECT_END_POINT_UPDATE_API_RES = "CONNECT_END_POINT_UPDATE_API_RES";

export interface ConnectEndPointState {
  connectOpenEpicRes: ConnectEndPointRes;
  loading: boolean;
  successMsg: string;
  errorMsg: string;
  status: number;
}
export interface ConnectEndPointLoading {
  type: typeof CONNECT_END_POINT_LOADING;
  loading: boolean;
}
export interface ConnectEndPointSuccess {
  type: typeof CONNECT_END_POINT_SUCCESS;
  payload: ConnectEndPointRes;
  successMsg: string;
  status: number;
}
export interface ConnectEndPointFail {
  type: typeof CONNECT_END_POINT_FAIL;
  payload: ConnectEndPointRes;
  errorMsg: string;
  status: number;
}
export interface ConnectEndPointUpdateAPIMsg {
  type: typeof CONNECT_END_POINT_UPDATE_API_MSG;
  payload: ConnectEndPointRes;
  errorMsg: string;
  status: number;
}
export interface ConnectEndPointUpdateAPIRes {
  type: typeof CONNECT_END_POINT_UPDATE_API_RES;
  payload: ConnectEndPointRes;
  errorMsg: string;
  status: number;
}
interface ConnectEndPointAction {
  type: typeof CONNECT_END_POINT_DATA;
  payload: ConnectEndPointRes;
}

export type ConnectEndPointActionTypes =
  | ConnectEndPointAction
  | ConnectEndPointLoading
  | ConnectEndPointSuccess
  | ConnectEndPointFail
  | ConnectEndPointUpdateAPIMsg
  | ConnectEndPointUpdateAPIRes;
