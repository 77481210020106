import React from "react";
import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
  Container,
  Typography,
} from "@mui/material";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import { t } from "i18next";

import FirstAccordian from "./OpinionType";
import SecondAccordian from "./ConsultationType";
import ThirdAccordian from "./ResponseTimeType";

export default function ConsultationOption(_props: any) {
  const accordian = (
    <div>
      <Accordion>
        <AccordionSummary
          expandIcon={<ExpandMoreIcon />}
          aria-controls="panel1a-content"
          id="panel1a-header"
        >
          <Typography>{t("consultOpPage.qwhyMedWis", { ns: ["home"] })}</Typography>
        </AccordionSummary>
        <AccordionDetails>
          <FirstAccordian />
        </AccordionDetails>
      </Accordion>
      <Accordion>
        <AccordionSummary
          expandIcon={<ExpandMoreIcon />}
          aria-controls="panel2a-content"
          id="panel2a-header"
        >
          <Typography>{t("consultOpPage.typeCon", { ns: ["home"] })}</Typography>
        </AccordionSummary>
        <AccordionDetails>
          <SecondAccordian />
        </AccordionDetails>
      </Accordion>
      <Accordion>
        <AccordionSummary
          expandIcon={<ExpandMoreIcon />}
          aria-controls="panel3a-content"
          id="panel3a-header"
        >
          <Typography>{t("consultOpPage.resTime", { ns: ["home"] })}</Typography>
        </AccordionSummary>
        <AccordionDetails>
          <ThirdAccordian />
        </AccordionDetails>
      </Accordion>
    </div>
  );

  return <Container maxWidth="xl">{accordian}</Container>;
}
