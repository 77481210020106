import { AddPatientInsuranceValue } from "./Model";

export const ADD_PATIENT_INSURANCE_LOADING = "ADD_PATIENT_INSURANCE_LOADING";
export const ADD_PATIENT_INSURANCE_SUCCESS = "ADD_PATIENT_INSURANCE_SUCCESS";
export const ADD_PATIENT_INSURANCE_FAIL = "ADD_PATIENT_INSURANCE_FAIL";
export const ADD_PATIENT_INSURANCE_LIST = "ADD_PATIENT_INSURANCE_LIST";

export interface AddPatientInsuranceDetails {
  addPatientInsuranceRes: AddPatientInsuranceValue;
  loading: boolean;
  errRes: string;
}
export interface AddPatientInsuranceLoading {
  type: typeof ADD_PATIENT_INSURANCE_LOADING;
}
export interface AddPatientInsuranceSuccess {
  type: typeof ADD_PATIENT_INSURANCE_SUCCESS;
  payload: AddPatientInsuranceValue;
  errRes: string;
}
export interface AddPatientInsuranceFail {
  type: typeof ADD_PATIENT_INSURANCE_FAIL;
  errRes: string;
}

interface AddPatientInsuranceAction {
  type: typeof ADD_PATIENT_INSURANCE_LIST;
  payload: AddPatientInsuranceValue;
  errRes: string;
}

export type addPatientInsuranceActionTypes =
  | AddPatientInsuranceAction
  | AddPatientInsuranceLoading
  | AddPatientInsuranceSuccess
  | AddPatientInsuranceFail;
