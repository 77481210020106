import { SleepScoreObservationTypeRes } from "./Model";
export const SLEEP_SCORE_OBSERVATION_TYPE_LOADING = "SLEEP_SCORE_OBSERVATION_TYPE_LOADING";
export const SLEEP_SCORE_OBSERVATION_TYPE_SUCCESS = "SLEEP_SCORE_OBSERVATION_TYPE_SUCCESS";
export const SLEEP_SCORE_OBSERVATION_TYPE_FAIL = "SLEEP_SCORE_OBSERVATION_TYPE_FAIL";
export const SLEEP_SCORE_OBSERVATION_TYPE_UPDATE_API_MSG =
  "SLEEP_SCORE_OBSERVATION_TYPE_UPDATE_API_MSG";
export const SLEEP_SCORE_OBSERVATION_TYPE_UPDATE_API_RES =
  "SLEEP_SCORE_OBSERVATION_TYPE_UPDATE_API_RES";

export interface SleepScoreObservationTypeState {
  sleepScoreObservationTypeRes: SleepScoreObservationTypeRes;
  loading: boolean;
  successMsg: string;
  errorMsg: string;
  status: number;
}
export interface SleepScoreObservationTypeLoading {
  type: typeof SLEEP_SCORE_OBSERVATION_TYPE_LOADING;
  loading: boolean;
}
export interface SleepScoreObservationTypeSuccess {
  type: typeof SLEEP_SCORE_OBSERVATION_TYPE_SUCCESS;
  payload: SleepScoreObservationTypeRes;
  successMsg: string;
}
export interface SleepScoreObservationTypeFail {
  type: typeof SLEEP_SCORE_OBSERVATION_TYPE_FAIL;
  payload: SleepScoreObservationTypeRes;
  errorMsg: string;
  status: number;
}
export interface SleepScoreObservationTypeUpdateAPIMsg {
  type: typeof SLEEP_SCORE_OBSERVATION_TYPE_UPDATE_API_MSG;
  payload: SleepScoreObservationTypeRes;
  successMsg: string;
  errorMsg: string;
  status: number;
}
export interface SleepScoreObservationTypeUpdateAPIRes {
  type: typeof SLEEP_SCORE_OBSERVATION_TYPE_UPDATE_API_RES;
  payload: SleepScoreObservationTypeRes;
  successMsg: string;
  errorMsg: string;
  status: number;
}

export type SleepScoreObservationTypeActionTypes =
  | SleepScoreObservationTypeLoading
  | SleepScoreObservationTypeSuccess
  | SleepScoreObservationTypeFail
  | SleepScoreObservationTypeUpdateAPIMsg
  | SleepScoreObservationTypeUpdateAPIRes;
