import axios from "axios";
import { Dispatch } from "redux";
import Url from "../../../Url";
import { setupInterceptorsTo } from "../../Authentication/Interceptors";
import {
  GetDemographicsDetailsByPtIdActionTypes,
  GET_DEMOGRAPHICS_DETAILS_BY_PT_ID_FAIL,
  GET_DEMOGRAPHICS_DETAILS_BY_PT_ID_LOADING,
  GET_DEMOGRAPHICS_DETAILS_BY_PT_ID_SUCCESS,
} from "./ActionTypes";
import { GetDemographicsDetailsByPtIdRequest, GetDemographicsDetailsByPtIdRes } from "./Model";
import { headers } from "components/Utility";

export const getDemographicsDetailsByPtId = (payload: GetDemographicsDetailsByPtIdRequest) => {
  return function (dispatch: Dispatch<GetDemographicsDetailsByPtIdActionTypes>) {
    dispatch({
      type: GET_DEMOGRAPHICS_DETAILS_BY_PT_ID_LOADING,
    });
    setupInterceptorsTo(axios)
      .post(Url.apiURL + `/patientapp/api/patient/get_patient_demographics_details`, payload, {
        headers: headers,
      })
      .then((res) => {
        dispatch({
          type: GET_DEMOGRAPHICS_DETAILS_BY_PT_ID_SUCCESS,
          payload: res.data,
          errRes: "",
        });
      })
      .catch((error) => {
        dispatch({
          type: GET_DEMOGRAPHICS_DETAILS_BY_PT_ID_FAIL,
          payload: {} as GetDemographicsDetailsByPtIdRes,
          errRes: error.response.data.message,
        });
      });
  };
};
