import { AddNoteRes } from "./Model";
export const ADD_NOTE_LOADING = "ADD_NOTE_LOADING";
export const ADD_NOTE_SUCCESS = "ADD_NOTE_SUCCESS";
export const ADD_NOTE_FAIL = "ADD_NOTE_FAIL";
export const ADD_NOTE_DATA = "ADD_NOTE_DATA";
export const ADD_NOTE_UPDATE_API_MSG = "ADD_NOTE_UPDATE_API_MSG";
export const ADD_NOTE_UPDATE_API_RES = "ADD_NOTE_UPDATE_API_RES";

export interface AddNoteState {
  AddNoteRes: AddNoteRes;
  loading: boolean;
  successMsg: string;
  errorMsg: string;
  status: number;
}
export interface AddNoteLoading {
  type: typeof ADD_NOTE_LOADING;
  loading: boolean;
}
export interface AddNoteSuccess {
  type: typeof ADD_NOTE_SUCCESS;
  payload: AddNoteRes;
  successMsg: string;
}
export interface AddNoteFail {
  type: typeof ADD_NOTE_FAIL;
  payload: AddNoteRes;
  errorMsg: string;
  status: number;
}
export interface AddNoteUpdateAPIMsg {
  type: typeof ADD_NOTE_UPDATE_API_MSG;
  payload: AddNoteRes;
  successMsg: string;
  errorMsg: string;
  status: number;
}
export interface AddNoteUpdateAPIRes {
  type: typeof ADD_NOTE_UPDATE_API_RES;
  payload: AddNoteRes;
  successMsg: string;
  errorMsg: string;
  status: number;
}

export type AddNoteActionTypes =
  | AddNoteLoading
  | AddNoteSuccess
  | AddNoteFail
  | AddNoteUpdateAPIMsg
  | AddNoteUpdateAPIRes;
