import {
  SleepQualityObservationTypeActionTypes,
  SLEEP_QUALITY_OBSERVATION_TYPE_FAIL,
  SLEEP_QUALITY_OBSERVATION_TYPE_LOADING,
  SLEEP_QUALITY_OBSERVATION_TYPE_SUCCESS,
  SleepQualityObservationTypeState,
  SLEEP_QUALITY_OBSERVATION_TYPE_UPDATE_API_MSG,
  SLEEP_QUALITY_OBSERVATION_TYPE_UPDATE_API_RES,
} from "./ActionTypes";
import { SleepQualityObservationTypeRes } from "./Model";

const initialStateGetPosts: SleepQualityObservationTypeState = {
  loading: false,
  sleepQualityObservationTypeRes: {} as SleepQualityObservationTypeRes,
  successMsg: "",
  errorMsg: "",
  status: 0,
};
export const sleepQualityObservationTypeReducer = (
  state = initialStateGetPosts,
  action: SleepQualityObservationTypeActionTypes,
): SleepQualityObservationTypeState => {
  switch (action.type) {
    case SLEEP_QUALITY_OBSERVATION_TYPE_LOADING:
      return {
        ...state,
        loading: action.loading,
      };
    case SLEEP_QUALITY_OBSERVATION_TYPE_SUCCESS:
      return {
        ...state,
        sleepQualityObservationTypeRes: action.payload,
        successMsg: action.successMsg,
      };
    case SLEEP_QUALITY_OBSERVATION_TYPE_FAIL:
      return {
        ...state,
        sleepQualityObservationTypeRes: action.payload,
        errorMsg: action.errorMsg,
        status: action.status,
      };
    case SLEEP_QUALITY_OBSERVATION_TYPE_UPDATE_API_MSG:
      return {
        ...state,
        sleepQualityObservationTypeRes: action.payload,
        successMsg: action.successMsg,
        errorMsg: action.errorMsg,
        status: action.status,
      };
    case SLEEP_QUALITY_OBSERVATION_TYPE_UPDATE_API_RES:
      return {
        ...state,
        sleepQualityObservationTypeRes: action.payload,
        successMsg: action.successMsg,
        errorMsg: action.errorMsg,
        status: action.status,
      };
    default:
      return state;
  }
};
