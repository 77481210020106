import * as React from "react";
import { useEffect } from "react";
import { useHistory } from "react-router-dom";
import { useParams } from "react-router-dom";
import PatientFlow from "../image/design-assets/patient-flow-chart.png";
import { useDispatch, useSelector } from "react-redux";
import { getEncounterList } from "../redux/effects/ApiCall";
import { AppState } from "../redux/store/Store";
import Demographics from "./PatientDashboard/Demographics/Index";
import CaseList from "./PatientDashboard/CaseList/Index";
import CarePlan from "./PatientDashboard/CarePlan/Index";
import MedicationSummary from "./PatientDashboard/MedicationSummary/Index";
import HealthCondition from "./PatientDashboard/HealthCondition/Index";
import { GetPatientDemographicsDetails } from "../redux/patientDemographicsDetails/patientDemographicsDetailsByPatientID/Model";
import { getPatientDemographicsDetails } from "../redux/patientDemographicsDetails/patientDemographicsDetailsByPatientID/API";
import { Box, Button, Container, CssBaseline, Grid, Typography } from "@mui/material";
import { getSharedPatientDetails } from "../redux/sharedPatientTeamDetailsByPatientId/API";
import MWLoader from "../components/MWLoader";
import MWPageTitle from "../components/MWPageTitle";

export default function CaseHistory() {
  const history = useHistory();
  const { patientid } = useParams() as {
    patientid: string;
  };
  // const { patientName } = useParams() as {
  //   patientName: string;
  // };
  const loadingMarkup = <MWLoader />;

  const handleAddEncounterToggle = () => {
    history.push(`/filepreparation/${patientid}`);
  };

  const handleDemographicToggle = () => {
    history.push(`/demographicdetails/${patientid}`);
  };

  const handleInsuranceToggle = () => {
    history.push(`/insurancedetails/${patientid}`);
  };

  const dispatch = useDispatch();

  // Get Patient Demographics Details Body
  const getPatientDemographicsDetailsBody = {
    healthRecordId: patientid,
  } as GetPatientDemographicsDetails;

  useEffect(() => {
    dispatch(getEncounterList(patientid));
    dispatch(getPatientDemographicsDetails(getPatientDemographicsDetailsBody));
    dispatch(getSharedPatientDetails(patientid));
    localStorage.removeItem("paymentRedirect");
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [dispatch]);

  // const gotoDetails = () => {
  //   history.push(`/filepreparation/${patientid}`);
  // }

  const emptyPageMarkup = (
    <Grid container direction="row" justifyContent="flex-start" alignItems="flex-start" spacing={2}>
      <Grid item xs={7} sm={7} md={7} lg={7}>
        <Box pt={5} mt={5}>
          <Typography variant="h4" gutterBottom>
            Seek Health Consultation from trained doctors
          </Typography>
          <Typography variant="h6" gutterBottom>
            <br />
            One-stop solution for 2nd opinion and other e-healthcare services from top-notch
            specialists
            <br />
            <br />
          </Typography>
          <Box>
            <Button variant="contained" onClick={() => handleAddEncounterToggle}>
              Add Case
            </Button>
          </Box>
        </Box>
      </Grid>
      <Grid item xs={5} sm={5} md={5} lg={5}>
        <Box pt={5} mt={5}>
          <div>
            <img
              width="100%"
              height="100%"
              style={{
                objectFit: "cover",
                objectPosition: "center",
              }}
              src={PatientFlow}
              alt=""
            />
          </div>
        </Box>
      </Grid>
    </Grid>
  );

  const casehistory = useSelector((state: AppState) => state.caseValue);

  const casehistoryname = useSelector((state: AppState) => state.caseValue.patientname.patientName);
  const pageTitle = `Case History of ${casehistoryname ? casehistoryname : ""}`;
  const reloadActionFromChild = (passedVal: boolean) => {
    if (passedVal) {
      dispatch(getEncounterList(patientid));
    }
  };
  const pagebody = (
    <Grid container direction="column" rowSpacing={1} columnSpacing={1}>
      {casehistory && casehistory.caseValue.length > 0 ? (
        <Grid container direction="column" rowSpacing={1} columnSpacing={1}>
          <Grid item container justifyContent="space-between" alignItems="stretch" spacing={2}>
            <Grid item>
              <Grid container justifyContent="space-between" alignItems="stretch" spacing={2}>
                <Grid item xs={12} sm={12} lg={8}>
                  <Box>
                    <CssBaseline />
                    <Demographics />
                  </Box>
                </Grid>
                <Grid item xs={12} sm={12} lg={4}>
                  <CaseList />
                </Grid>
              </Grid>
            </Grid>
            <Grid item xs={12} sm={12} lg={4}>
              <CarePlan />
            </Grid>
          </Grid>
          <Grid item>
            <Grid
              container
              direction="row"
              justifyContent="flex-start"
              alignItems="flex-start"
              spacing={2}
            >
              <Grid item xs={12} sm={12} lg={6}>
                <MedicationSummary />
              </Grid>
              <Grid item xs={12} sm={12} lg={6}>
                <HealthCondition />
              </Grid>
            </Grid>
          </Grid>
        </Grid>
      ) : (
        <Grid>{emptyPageMarkup}</Grid>
      )}
    </Grid>
  );

  return (
    <Box>
      {casehistory.loading ? loadingMarkup : ""}
      <Container maxWidth="xl">
        <Grid container direction="row" justifyContent="space-between" alignItems="center">
          <Grid item>
            <MWPageTitle
              backButton={true}
              title={pageTitle}
              reload={true}
              reloadAction={reloadActionFromChild}
            />
          </Grid>
          <Grid item>
            <Grid
              container
              direction="row"
              justifyContent="flex-end"
              alignItems="center"
              spacing={2}
            >
              <Grid item>
                <Button
                  variant="text"
                  size="medium"
                  onClick={() => handleInsuranceToggle()}
                  sx={{ textTransform: "none" }}
                >
                  Insurance Details
                </Button>
              </Grid>
              <Grid item>
                <Button
                  variant="text"
                  size="medium"
                  onClick={() => handleDemographicToggle()}
                  sx={{ textTransform: "none" }}
                >
                  Demographic Details
                </Button>
              </Grid>
              <Grid item>
                <Button
                  variant="contained"
                  size="medium"
                  onClick={() => handleAddEncounterToggle()}
                  sx={{ textTransform: "none" }}
                >
                  Add Case
                </Button>
              </Grid>
            </Grid>
          </Grid>
        </Grid>
        <Box sx={{ pt: 4 }}>{pagebody}</Box>
      </Container>
    </Box>
  );
}
