import { GET_LIST } from "../types/ActionTypes";
import { PatientDetailsActionType } from "./ActionTypes";
import { PatientDetailsById } from "./Model";

export const getPatientDetailsById = (
  patientDetailsById: PatientDetailsById,
): PatientDetailsActionType => {
  return {
    type: GET_LIST,
    payload: patientDetailsById,
  };
};
