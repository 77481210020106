import axios from "axios";
import { Dispatch } from "redux";
import Url from "../../Url";
import { setupInterceptorsTo } from "../Authentication/Interceptors";
import {
  emailOtpAuthActionTypes,
  EMAIL_OTP_AUTH_FAIL,
  EMAIL_OTP_AUTH_LOADING,
  EMAIL_OTP_AUTH_SUCCESS,
} from "./ActionTypes";
import { emailOtpAuthDetails, emailOtpAuthValue } from "./Model";
import { ApiHeader } from "components/Utility";

export const emailOtpAuthApi = (body: emailOtpAuthDetails) => {
  return function (dispatch: Dispatch<emailOtpAuthActionTypes>) {
    dispatch({
      type: EMAIL_OTP_AUTH_LOADING,
    });

    setupInterceptorsTo(axios)
      .post(Url.apiURL + `/patientapp/email-otp-auth/`, body, { headers: ApiHeader })
      .then((res) => {
        dispatch({
          type: EMAIL_OTP_AUTH_SUCCESS,
          payload: res.data,
          errRes: "",
        });
        localStorage.setItem("token", res.data.token);
        localStorage.setItem("email", res.data.user.email);
        localStorage.setItem("firstname", res.data.user.first_name);
        localStorage.setItem("lastname", res.data.user.last_name);
      })
      .catch((error) => {
        dispatch({
          type: EMAIL_OTP_AUTH_FAIL,
          payload: {} as emailOtpAuthValue,
          errRes: error.response.data.message,
        });
      });
  };
};
