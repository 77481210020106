import nameList from "./names.json";
import imageList from "./ImageList";

export class NameGenerate {
  static changeName(id: string, name: string) {
    let namevisibility = localStorage.getItem("togglename");
    if (namevisibility === "true") {
      let keyName = "patient" + id;
      let imageName = "image" + id;
      if (keyName in localStorage) {
        return localStorage.getItem(keyName);
      } else {
        localStorage.setItem(
          keyName,
          nameList.genres[Math.floor(Math.random() * nameList.genres.length)],
        );
        localStorage.setItem(
          imageName,
          imageList.imagevlaue[Math.floor(Math.random() * imageList.imagevlaue.length)],
        );
        return nameList.genres[Math.floor(Math.random() * nameList.genres.length)];
      }
    } else {
      return name;
    }
  }
}

export class AgeRange {
  static ageRangeCheck(age: number) {
    if (age >= 0 && age <= 10) {
      return "1-10 year old";
    } else if (age >= 10 && age <= 20) {
      return "10-20 year old";
    } else if (age >= 20 && age <= 30) {
      return "20-30 year old";
    } else if (age >= 30 && age <= 40) {
      return "30-40 year old";
    } else if (age >= 40 && age <= 50) {
      return "40-50 year old";
    } else if (age >= 50 && age <= 60) {
      return "50-60 year old";
    } else if (age >= 60 && age <= 70) {
      return "60-70 year old";
    } else if (age >= 70 && age <= 80) {
      return "70-80 year old";
    } else if (age >= 80 && age <= 90) {
      return "80-90 year old";
    } else if (age >= 90 && age <= 100) {
      return "90-100 year old";
    } else {
      return "";
    }
  }
}
let headers = {
  Accept: "application/json",
  "Content-type": "application/json",
  Authorization: `Bearer ${localStorage.getItem("access")}`,
  "Accept-Language": localStorage.getItem("lng") === null ? "en" : localStorage.getItem("lng"),
} as any;

let ApiHeader = {
  "Content-type": "application/json",
  "Accept-Language": localStorage.getItem("lng") === null ? "en" : localStorage.getItem("lng"),
} as any;

export { headers, ApiHeader };

export function checkReturnValue(value: any) {
  if (value == null) {
    // The value is either undefined or null
    // Handle the case accordingly
    return "Not Set";
  } else if (value === "") {
    // The value is either empty
    // Handle the case accordingly
    return "Not Set";
  } else {
    // The value is not undefined or null
    // Proceed with other logic
    return value;
  }
}

export function formatTimeString(timeString: any) {
  // Use a regular expression to insert a space between hours and minutes
  return timeString.replace(/(\d+h)(\d+m)/, "$1 $2");
}

export function formatTimeValue(time: any) {
  const startDateString = time;

  // Create a Date object
  const date = new Date(startDateString);

  // Extract hours and minutes
  let hours = date.getHours();
  const minutes = date.getMinutes();

  // Determine AM/PM
  const ampm = hours >= 12 ? "PM" : "AM";

  // Convert hours from 24-hour to 12-hour format
  hours = hours % 12;
  hours = hours ? hours : 12; // the hour '0' should be '12'

  // Format minutes to always be two digits
  const formattedMinutes = minutes < 10 ? "0" + minutes : minutes;

  // Combine into the final formatted time
  return `${hours}-${formattedMinutes} ${ampm}`;
}

export function formatTimeRange(timeRange: any) {
  return timeRange.replace(/(\d{1,2})-(\d{2})/g, "$1:$2");
}
