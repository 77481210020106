import { AppointmentPaymentRes } from "./Model";

export const GET_APPOINTMENT_PAYMENT_LIST_LOADING = "GET_APPOINTMENT_PAYMENT_LIST_LOADING";
export const GET_APPOINTMENT_PAYMENT_LIST_SUCCESS = "GET_APPOINTMENT_PAYMENT_LIST_SUCCESS";
export const GET_APPOINTMENT_PAYMENT_LIST_FAIL = "GET_APPOINTMENT_PAYMENT_LIST_FAIL";
export const GET_APPOINTMENT_PAYMENT_LIST_DATA = "GET_APPOINTMENT_PAYMENT_LIST_DATA";

export interface DoctorDetailsByIdDetails {
  getAppointmentPaymentListRes: AppointmentPaymentRes;
  loading: boolean;
  errRes: string;
}
export interface DoctorDetailsByIdDetailsActionLoading {
  type: typeof GET_APPOINTMENT_PAYMENT_LIST_LOADING;
}
export interface DoctorDetailsByIdDetailsActionSuccess {
  type: typeof GET_APPOINTMENT_PAYMENT_LIST_SUCCESS;
  payload: AppointmentPaymentRes;
  errRes: string;
}
export interface DoctorDetailsByIdDetailsActionFail {
  type: typeof GET_APPOINTMENT_PAYMENT_LIST_FAIL;
  payload: AppointmentPaymentRes;
  errRes: string;
}
interface DoctorDetailsByIdDetailsActionAction {
  type: typeof GET_APPOINTMENT_PAYMENT_LIST_DATA;
  payload: AppointmentPaymentRes;
  errRes: string;
}

export type DoctorDetailsByIdDetailsActionTypes =
  | DoctorDetailsByIdDetailsActionAction
  | DoctorDetailsByIdDetailsActionLoading
  | DoctorDetailsByIdDetailsActionSuccess
  | DoctorDetailsByIdDetailsActionFail;
