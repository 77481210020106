import {
  MaritalListListDetails,
  GetMaritalListListActionTypes,
  GET_MARITAL_STATUS_LIST_FAIL,
  GET_MARITAL_STATUS_LIST_LOADING,
  GET_MARITAL_STATUS_LIST_SUCCESS,
} from "./ActionTypes";
import { GetMaritalListListRes } from "./Model";

const initialStateGetPosts: MaritalListListDetails = {
  loading: false,
  getMaritalListListRes: {} as GetMaritalListListRes,
  errRes: "",
};
export const getMaritalListListReducer = (
  state = initialStateGetPosts,
  action: GetMaritalListListActionTypes,
): MaritalListListDetails => {
  switch (action.type) {
    case GET_MARITAL_STATUS_LIST_SUCCESS:
      return {
        ...state,
        loading: false,
        getMaritalListListRes: action.payload,
        errRes: "",
      };
    case GET_MARITAL_STATUS_LIST_LOADING:
      return {
        ...state,
        loading: true,
        getMaritalListListRes: {} as GetMaritalListListRes,
      };
    case GET_MARITAL_STATUS_LIST_FAIL:
      return {
        ...state,
        getMaritalListListRes: {} as GetMaritalListListRes,
        loading: false,
        errRes: action.errRes,
      };
    default:
      return state;
  }
};
