import React, { useCallback, useEffect, useState } from "react";
import {
  Alert,
  Box,
  Button,
  Card,
  CardContent,
  CardHeader,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Grid,
  IconButton,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Typography,
} from "@mui/material";
import { Redirect, useParams } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import DescriptionIcon from "@mui/icons-material/Description";
import VisibilityIcon from "@mui/icons-material/Visibility";
import DeleteIcon from "@mui/icons-material/Delete";
import CloseIcon from "@mui/icons-material/Close";
import MWLoader from "../../components/MWLoader";
import { AppState } from "../../redux/store/Store";
import MWToast from "../../components/MWToast";
import {
  addChiefComplaint,
  deleteFilePreparationDocument,
  getChiefComplaintList,
  getQuestionList,
} from "../../redux/effects/ApiCall";
import MWTextField from "../../components/MWTextField";
import MWUploader from "../../components/MWUploader";
import MWDatePicker from "../../components/MWDatePicker";

export default function Immunization() {
  const DATE_OPTIONS = {
    weekday: "short",
    year: "numeric",
    month: "short",
    day: "numeric",
  } as const;

  const [toSignin] = useState(false);

  const { patientid } = useParams() as {
    patientid: string;
  };
  const { caseid } = useParams() as {
    caseid: string;
  };
  const loadingMarkup = <MWLoader />;

  const updateDataStateFromChild = async (passedval: any) => {
    setImageId(passedval);
  };

  const updateStartDateFromChild = async (startDateValue: any) => {
    await setSelectedDate(startDateValue);
  };
  const updateEndDateFromChild = async (endDateValue: any) => {
    await setSelectedEndDate(endDateValue);
  };

  const [chiefDescription, setChiefDescription] = useState("");

  const [imageInfo, setImageInfo] = useState("");
  const [imageId, setImageId] = useState(0);

  const [patientDetailsId, setPatientDetailsId] = useState("");

  // Start Date
  const [selectedDate, setSelectedDate] = React.useState<Date | null>(new Date());

  // End Date
  const [selectedEndDate, setSelectedEndDate] = React.useState<Date | null>(null);

  const [uploadmodalActive, setuploadmodalActive] = useState(false);
  const uploadhandleToggle = () => {
    setuploadmodalActive((uploadmodalActive) => !uploadmodalActive);
    setChiefDescription("");
    setSelectedDate(new Date());
    setImageInfo("");
    setAddEncounterModalSuccess("");
    setChiefError("");
    // getQuestionnaitList();
  };

  const closeUpload = () => {
    setuploadmodalActive((uploadmodalActive) => !uploadmodalActive);
  };

  const closeSuccess = () => {
    setAddEncounterModalSuccess("");
    setDeleteChifModalSuccess("");
    setChiefDeleteError("");
  };
  const [addEncounterModalSuccess, setAddEncounterModalSuccess] = useState("");
  const successModalDiv = addEncounterModalSuccess ? (
    <Alert onClose={closeSuccess} severity="success">
      <p>{addEncounterModalSuccess}</p>
    </Alert>
  ) : (
    ""
  );
  const [chiefError, setChiefError] = useState("");
  const chiefErrorDiv = chiefError ? (
    <Alert onClose={closeSuccess} severity="error">
      <p>{chiefError}</p>
    </Alert>
  ) : (
    ""
  );

  const [deleteChifModalSuccess, setDeleteChifModalSuccess] = useState("");
  const successDelChifModalDiv = deleteChifModalSuccess ? (
    <Alert onClose={closeSuccess} severity="success">
      <p>{deleteChifModalSuccess}</p>
    </Alert>
  ) : (
    ""
  );

  const [chiefDescriptionError, setChiefDescriptionError] = useState("");
  const chiefDescriptionErrordiv = chiefDescriptionError ? (
    <Typography>{chiefDescriptionError}</Typography>
  ) : (
    ""
  );

  const [chiefDeleteError, setChiefDeleteError] = useState("");
  const chiefDeleteErrorDiv = chiefDeleteError ? (
    <Alert onClose={closeSuccess} severity="error">
      <p>{chiefDeleteError}</p>
    </Alert>
  ) : (
    ""
  );

  const [docViwerActive, setDocViwerActive] = useState(false);
  const handleDocViewerChange = useCallback(
    () => setDocViwerActive(!docViwerActive),
    [docViwerActive],
  );

  const [deleteModalActive, setDeleteModalActive] = useState(false);
  const handleDeleteChifModalChange = useCallback(
    () => setDeleteModalActive(!deleteModalActive),
    [deleteModalActive],
  );

  // Get PreviewImg Details
  const viewPreviewImageData = (passedVal: any) => {
    console.log("passedVal", passedVal);
  };

  const dispatch = useDispatch();

  // Call Add chief complaint store
  const AddChiefComplaintMessage = useSelector(
    (state: AppState) => state.ccAddVlaue.response.message,
  );
  const AddChiefComplaintStatus = useSelector(
    (state: AppState) => state.ccAddVlaue.response.status,
  );

  // Add Chief complaint success toast
  const [successAddChiefComplaintToastActive, setSuccessAddChiefComplaintToastActive] =
    useState(false);

  const toastAddChiefComplaintSuccessMarkup = successAddChiefComplaintToastActive ? (
    <MWToast message={AddChiefComplaintMessage} />
  ) : null;

  // Add Chief complaint error toast
  const [addChiefComplaintErrorToastActive, setAddChiefComplaintErrorToastActive] = useState(false);
  const toastAddChiefComplaintErrorMarkup = addChiefComplaintErrorToastActive ? (
    <MWToast message={AddChiefComplaintMessage} />
  ) : null;

  const deleteChiefComplaintStatus = useSelector(
    (state: AppState) => state.deleteDocumentValue.response.status,
  );

  // Delete Chief complaint success toast
  const [successDeleteChiefComplaintToastActive, setDeleteAddChiefComplaintToastActive] =
    useState(false);

  const toastDeleteChiefComplaintSuccessMarkup = successDeleteChiefComplaintToastActive ? (
    <MWToast message={AddChiefComplaintMessage} />
  ) : null;

  // Delete Chief complaint error toast
  const [deleteChiefComplaintErrorToastActive, setDeleteChiefComplaintErrorToastActive] =
    useState(false);

  const toastDeleteChiefComplaintErrorMarkup = deleteChiefComplaintErrorToastActive ? (
    <MWToast message={AddChiefComplaintMessage} />
  ) : null;

  const [viewDescriptionModalActive, setViewDescriptionModalActive] = useState(false);
  const viewDescription = (
    description: any,
    dateStart: any,
    dateEnd: any,
    supportingDocuments: any,
  ) => {
    setViewDescriptionModalActive((viewDescriptionModalActive) => !viewDescriptionModalActive);
    setChiefDescription(description);
    setChiefDescriptionError("");
    setSelectedDate(dateStart);
    setSelectedEndDate(dateEnd);
    setImageInfo(supportingDocuments);
  };
  const handleViewDescriptionToggle = () => {
    setViewDescriptionModalActive((viewDescriptionModalActive) => !viewDescriptionModalActive);
    setChiefDescriptionError("");
    setChiefDescription("");
    setSelectedDate(new Date());
    setImageInfo("");
  };

  const body = {
    patientId: patientid,
    caseId: caseid,
    secondOpinionUiSection: "immunization",
    description: chiefDescription,
    patientDocumentId: imageId,
    startDate: selectedDate,
    endDate: selectedEndDate,
  };

  const getChiefComplaintBody = {
    caseId: caseid,
    secondOpinionUiSection: "immunization",
  };

  const CCADDLISTTOAPI = useSelector((state: AppState) => state.ccAddVlaue);

  const DELETEDOCUMENTFROMAPI = useSelector((state: AppState) => state.deleteDocumentValue);

  const ChiefComplaintSubmit = () => {
    if (chiefDescription === "") {
      setChiefDescriptionError("Please add description");
    } else {
      setChiefDescriptionError("");
      dispatch(addChiefComplaint(body));
      setuploadmodalActive((uploadmodalActive) => !uploadmodalActive);
      setSuccessAddChiefComplaintToastActive(
        (successAddChiefComplaintToastActive) => !successAddChiefComplaintToastActive,
      );
      setAddChiefComplaintErrorToastActive(
        (addChiefComplaintErrorToastActive) => !addChiefComplaintErrorToastActive,
      );
      dispatch(getChiefComplaintList(getChiefComplaintBody));
    }
  };

  // Delete body....
  const deleteChiefComplaintBody = {
    caseId: caseid,
    patientDetailId: patientDetailsId,
  };
  // delete api call here ....
  const DeleteChiefComplaintSubmit = () => {
    dispatch(deleteFilePreparationDocument(deleteChiefComplaintBody));
    setDeleteModalActive((deleteModalActive) => !deleteModalActive);
    setDeleteAddChiefComplaintToastActive(
      (successDeleteChiefComplaintToastActive) => !successDeleteChiefComplaintToastActive,
    );
    setDeleteChiefComplaintErrorToastActive(
      (deleteChiefComplaintErrorToastActive) => !deleteChiefComplaintErrorToastActive,
    );
    dispatch(getChiefComplaintList(getChiefComplaintBody));
  };

  // Delete popup open ........
  function deleteChiefComplaints(patientId: any) {
    setDeleteModalActive((deleteModalActive) => !deleteModalActive);
    setDeleteChifModalSuccess("");
    setPatientDetailsId(patientId);
  }

  // QUESTION LIST
  const QUESTIONLISTFROMAPI = useSelector((state: AppState) => state.questionValue);
  const questionListValues = QUESTIONLISTFROMAPI.questionValue;
  const questionListConfig: any = [];
  for (var i = 0; i < questionListValues.length; i++) {
    let questionName = questionListValues[i].questionnaireType.displayValue;
    let supportingDocuments = questionListValues[i].templateUrl;
    questionListConfig.push([questionName, supportingDocuments]);
  }
  // const questionrows: any[] = questionListConfig;

  useEffect(() => {
    dispatch(getChiefComplaintList(getChiefComplaintBody));
    dispatch(getQuestionList(getChiefComplaintBody));
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const imageUploadRes = useSelector((state: AppState) => state.imageUploading);

  useEffect(() => {
    setImageId(imageUploadRes?.imageUploadResponse?.image?.id);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [imageUploadRes]);

  const truncate = (str: string) => {
    return str.length > 0 ? str.substring(0, 60) + "..." : str;
  };

  // CHIEF COMPLAINT LIST
  const CCLISTFROMAPI = useSelector((state: AppState) => state.ccValue);
  const chiefcomplaintValues = CCLISTFROMAPI.ccValue;
  const ccListConfig: any = [];
  for (var j = 0; j < chiefcomplaintValues.length; j++) {
    let selectedId = chiefcomplaintValues[j].id;
    let tableDescription =
      chiefcomplaintValues[j].description == null
        ? ""
        : truncate(chiefcomplaintValues[j].description);

    // let description =
    //   chiefcomplaintValues[j].description == null
    //     ? ""
    //     : chiefcomplaintValues[j].description;

    let tableDateStart =
      chiefcomplaintValues[j].startDate == null
        ? ""
        : new Date(chiefcomplaintValues[j].startDate).toLocaleDateString("en-US", DATE_OPTIONS);

    // let dateStart =
    //   chiefcomplaintValues[j].startDate == null
    //     ? ""
    //     : chiefcomplaintValues[j].startDate;

    let tablDateEnd =
      chiefcomplaintValues[j].endDate == null
        ? ""
        : new Date(chiefcomplaintValues[j].endDate).toLocaleDateString("en-US", DATE_OPTIONS);

    // let dateEnd =
    //   chiefcomplaintValues[j].endDate == null
    //     ? null
    //     : chiefcomplaintValues[j].endDate;

    let supportingDocuments =
      chiefcomplaintValues[j].patientDocument == null
        ? ""
        : chiefcomplaintValues[j].patientDocument.document;

    ccListConfig.push([
      supportingDocuments,
      tableDescription,
      tableDateStart,
      tablDateEnd,
      selectedId,
    ]);
  }
  const delImgAction = (passeVal: boolean) => {
    console.log("passeVal", passeVal);
  };
  const questionBody = (
    <Box sx={{ pt: 3 }}>
      <Card>
        <CardHeader title="Questionnaire"></CardHeader>
        <CardContent>
          {questionListConfig && questionListConfig.length ? (
            <TableContainer>
              <Table size="small" aria-label="a dense table">
                <TableHead>
                  <TableRow>
                    <TableCell>Question Name</TableCell>
                    <TableCell align="left">View</TableCell>
                  </TableRow>
                </TableHead>
                <TableBody>
                  {questionListConfig.map((row: any) => (
                    <TableRow
                      key={row.description}
                      sx={{ "&:last-child td, &:last-child th": { border: 0 } }}
                    >
                      <TableCell align="left">{row[0]}</TableCell>
                      <TableCell align="left">
                        <Button
                          onClick={() => {
                            setDocViwerActive(true);
                            setImageInfo(row[1]);
                          }}
                          variant="outlined"
                        >
                          View Question
                        </Button>
                      </TableCell>
                    </TableRow>
                  ))}
                </TableBody>
              </Table>
            </TableContainer>
          ) : (
            <Typography>No Documents is available!</Typography>
          )}
        </CardContent>
      </Card>
      <br />
    </Box>
  );

  const tableMarkup = (
    <Card>
      <CardHeader
        title="Documents"
        action={
          <Button variant="contained" onClick={uploadhandleToggle}>
            Upload Data
          </Button>
        }
      />
      <CardContent>
        {ccListConfig && ccListConfig.length ? (
          <TableContainer>
            <Table size="small" aria-label="a dense table">
              <TableHead>
                <TableRow>
                  <TableCell>Document</TableCell>
                  <TableCell align="left">View</TableCell>
                  <TableCell align="left">Description</TableCell>
                  <TableCell align="left">Start Date</TableCell>
                  <TableCell align="left">End Date</TableCell>
                  <TableCell align="left">Delete</TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {ccListConfig.map((row: any) => (
                  <TableRow
                    key={row.description}
                    sx={{ "&:last-child td, &:last-child th": { border: 0 } }}
                  >
                    <TableCell>
                      <Button
                        onClick={() => {
                          setDocViwerActive(true);
                          setImageInfo(row[0]);
                        }}
                        variant="outlined"
                        size="small"
                      >
                        <IconButton color="primary">
                          <DescriptionIcon />
                        </IconButton>
                      </Button>
                    </TableCell>
                    <TableCell align="left">
                      <Button
                        onClick={() => {
                          viewDescription(row[1], row[2], row[3], row[0]);
                        }}
                        variant="outlined"
                        size="small"
                      >
                        <IconButton color="primary">
                          <VisibilityIcon />
                        </IconButton>
                      </Button>
                    </TableCell>
                    <TableCell align="left">{row[1]}</TableCell>
                    <TableCell align="left">{row[2]}</TableCell>
                    <TableCell align="left">{row[3]}</TableCell>
                    <TableCell align="left">
                      <Button
                        onClick={() => deleteChiefComplaints(row[4])}
                        variant="outlined"
                        size="small"
                      >
                        <IconButton color="primary">
                          <DeleteIcon />
                        </IconButton>
                      </Button>
                    </TableCell>
                  </TableRow>
                ))}
              </TableBody>
            </Table>
          </TableContainer>
        ) : (
          <Typography> No Documents is available!</Typography>
        )}
      </CardContent>
    </Card>
  );

  const docViewerComponent = (
    <Dialog open={docViwerActive} fullWidth>
      <DialogTitle>
        <Grid container direction="row" justifyContent="flex-end" alignItems="flex-start">
          <Grid item>
            <IconButton onClick={handleDocViewerChange}>
              <CloseIcon />
            </IconButton>
          </Grid>
        </Grid>
      </DialogTitle>
      <DialogContent>
        <p>
          <iframe src={imageInfo} title="Small document" width="100%" height="700" frameBorder="0">
            This is an embedded{" "}
            <a target="_blank" href="http://office.com" rel="noopener noreferrer">
              Microsoft Office
            </a>{" "}
            document, powered by{" "}
            <a target="_blank" href="http://office.com/webapps" rel="noopener noreferrer">
              Office Online
            </a>
            .
          </iframe>
        </p>
      </DialogContent>
    </Dialog>
  );

  // Delete modal section ....
  const deletechiefComponent = (
    <Dialog open={deleteModalActive} title="Delete Immunization" fullWidth>
      <DialogTitle>
        <Grid container direction="row" justifyContent="space-between" alignItems="flex-start">
          <Grid item>Delete Immunization</Grid>
          <Grid item>
            <IconButton onClick={handleDeleteChifModalChange}>
              <CloseIcon />
            </IconButton>
          </Grid>
        </Grid>
      </DialogTitle>
      <DialogContent>Are you sure, you want to delete this Immunization ?</DialogContent>
      <div>{successDelChifModalDiv}</div>
      <div>{chiefDeleteErrorDiv}</div>
      <DialogActions>
        <Button variant="contained" onClick={DeleteChiefComplaintSubmit}>
          Delete
        </Button>
      </DialogActions>
    </Dialog>
  );

  const uploadChiefComplaintDetails = (
    <Dialog open={uploadmodalActive} onClose={closeUpload} maxWidth="lg">
      <DialogTitle>
        <Grid
          container
          direction="row"
          justifyContent="space-between"
          alignItems="flex-start"
          spacing={2}
        >
          <Grid item>Upload Data of Immunization</Grid>
          <Grid item>
            <IconButton onClick={closeUpload}>
              <CloseIcon />
            </IconButton>
          </Grid>
        </Grid>
      </DialogTitle>
      <DialogContent>
        <Grid
          container
          direction="row"
          justifyContent="flex-start"
          alignItems="flex-start"
          spacing={2}
        >
          <Grid item xs={12} sm={6} lg={6} xl={6}>
            <p style={{ marginBottom: "5px" }}>Mention your health problem</p>
            <MWTextField
              placeholder="Please enter description"
              onChange={(event: React.ChangeEvent<HTMLInputElement>) =>
                setChiefDescription(event.target.value)
              }
              disabled={false}
              multiline
              rows={12}
              fullWidth
            />
            <Typography sx={{ color: "#c62828" }}>{chiefDescriptionErrordiv}</Typography>
          </Grid>
          <Grid item xs={12} sm={6} lg={6} xl={6}>
            <Typography style={{ marginBottom: "5px" }}>Upload Document</Typography>
            <Box
              style={{
                textAlign: "center",
                border: "1px #6574c4 solid",
                borderRadius: "3px",
              }}
              p={1}
            >
              <MWUploader
                documentType="patient-image"
                criteria="Document size should be maximum 10MB."
                updatedDataState={updateDataStateFromChild}
                title="Update Immunization Data"
                visibility={false}
                image={imageInfo}
                deleteImgAction={delImgAction}
                previewImageData={viewPreviewImageData}
              />
            </Box>
          </Grid>
          <Grid item xs={12} sm={6} lg={6} xl={6}>
            <MWDatePicker
              label="Start Date"
              selectedDateValue={selectedDate}
              dateState={updateStartDateFromChild}
              disabledValue={false}
            />
          </Grid>
          <Grid item xs={12} sm={6} lg={6} xl={6}>
            <MWDatePicker
              label="End Date"
              selectedDateValue={selectedEndDate}
              dateState={updateEndDateFromChild}
              disabledValue={false}
            />
          </Grid>
        </Grid>
      </DialogContent>
      <div>{successModalDiv}</div>
      <div>{chiefErrorDiv}</div>
      <DialogActions>
        <Button variant="contained" onClick={ChiefComplaintSubmit}>
          Submit
        </Button>
      </DialogActions>
    </Dialog>
  );

  // View details markup
  const viewDocumentBody = (
    <Dialog open={viewDescriptionModalActive} maxWidth="lg">
      <DialogTitle>
        <Grid
          container
          direction="row"
          justifyContent="space-between"
          alignItems="flex-start"
          spacing={2}
        >
          <Grid item>View Immunization Details</Grid>
          <Grid item>
            <IconButton onClick={handleViewDescriptionToggle}>
              <CloseIcon />
            </IconButton>
          </Grid>
        </Grid>
      </DialogTitle>
      <DialogContent>
        <Grid
          container
          direction="row"
          justifyContent="flex-start"
          alignItems="flex-start"
          spacing={2}
        >
          <Grid item xs={12} sm={6} lg={6} xl={6}>
            <Typography style={{ marginBottom: "5px" }}>Mention your health problem</Typography>
            <MWTextField
              placeholder="Please enter description"
              disabled={true}
              multiline
              rows={12}
              fullWidth
              value={chiefDescription}
            />
          </Grid>
          <Grid item xs={12} sm={6} lg={6} xl={6}>
            <Typography style={{ marginBottom: "5px" }}>Upload Supporting Document</Typography>
            <Box
              style={{
                textAlign: "center",
                border: "1px #6574c4 solid",
                borderRadius: "3px",
              }}
              p={1}
            >
              <MWUploader
                documentType="patient-image"
                criteria="Document size should be maximum 10MB."
                updatedDataState={updateDataStateFromChild}
                title="View Immunization Document"
                visibility={true}
                image={imageInfo}
                deleteImgAction={delImgAction}
                previewImageData={viewPreviewImageData}
              />
            </Box>
          </Grid>
          <Grid item xs={12} sm={6} lg={6} xl={6}>
            <MWDatePicker
              label="Start Date"
              selectedDateValue={selectedDate}
              dateState={updateStartDateFromChild}
              disabledValue={true}
            />
          </Grid>
          <Grid item xs={12} sm={6} lg={6} xl={6}>
            <MWDatePicker
              label="End Date"
              selectedDateValue={selectedEndDate}
              dateState={updateEndDateFromChild}
              disabledValue={true}
            />
          </Grid>
        </Grid>
      </DialogContent>
      <DialogActions>
        <Button variant="contained" onClick={handleViewDescriptionToggle}>
          Close
        </Button>
      </DialogActions>
    </Dialog>
  );

  return (
    <Box>
      <Typography variant="h5">Immunization</Typography>
      {toSignin ? <Redirect to="/signin" /> : null}
      {CCLISTFROMAPI.loading ? loadingMarkup : ""}
      {CCADDLISTTOAPI.loading ? loadingMarkup : ""}
      {DELETEDOCUMENTFROMAPI.loading ? loadingMarkup : ""}
      {questionBody}
      {tableMarkup}
      {docViewerComponent}
      {deletechiefComponent}
      {uploadChiefComplaintDetails}
      {AddChiefComplaintStatus! === 1 ? toastAddChiefComplaintSuccessMarkup : ""}
      {AddChiefComplaintStatus! === 0 ? toastAddChiefComplaintErrorMarkup : ""}
      {deleteChiefComplaintStatus! === 1 ? toastDeleteChiefComplaintSuccessMarkup : ""}
      {deleteChiefComplaintStatus! === 0 ||
      deleteChiefComplaintStatus! === -1 ||
      deleteChiefComplaintStatus! === -2
        ? toastDeleteChiefComplaintErrorMarkup
        : ""}
      {viewDocumentBody}
    </Box>
  );
}
