import {
  AddPatientDetails,
  PatientAddPointDataActionTypes,
  ADD_DATA_POINT_FAIL,
  ADD_DATA_POINT_LOADING,
  ADD_DATA_POINT_SUCCESS,
} from "./ActionTypes";
import { AddPatientRes } from "./Model";

const initialStateGetPosts: AddPatientDetails = {
  loading: false,
  addPatientRes: {} as AddPatientRes,
  errRes: "",
};
export const addHealthcampPatientReducer = (
  state = initialStateGetPosts,
  action: PatientAddPointDataActionTypes,
): AddPatientDetails => {
  switch (action.type) {
    case ADD_DATA_POINT_SUCCESS:
      return {
        ...state,
        loading: false,
        addPatientRes: action.payload,
        errRes: "",
      };
    case ADD_DATA_POINT_LOADING:
      return {
        ...state,
        loading: true,
        addPatientRes: {} as AddPatientRes,
      };
    case ADD_DATA_POINT_FAIL:
      return {
        ...state,
        addPatientRes: {} as AddPatientRes,
        loading: false,
        errRes: action.errRes,
      };
    default:
      return state;
  }
};
