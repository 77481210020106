import { SleepScheduleObservationTypeRes } from "./Model";
export const SLEEP_SCHEDULE_OBSERVATION_TYPE_LOADING = "SLEEP_SCHEDULE_OBSERVATION_TYPE_LOADING";
export const SLEEP_SCHEDULE_OBSERVATION_TYPE_SUCCESS = "SLEEP_SCHEDULE_OBSERVATION_TYPE_SUCCESS";
export const SLEEP_SCHEDULE_OBSERVATION_TYPE_FAIL = "SLEEP_SCHEDULE_OBSERVATION_TYPE_FAIL";
export const SLEEP_SCHEDULE_OBSERVATION_TYPE_UPDATE_API_MSG =
  "SLEEP_SCHEDULE_OBSERVATION_TYPE_UPDATE_API_MSG";
export const SLEEP_SCHEDULE_OBSERVATION_TYPE_UPDATE_API_RES =
  "SLEEP_SCHEDULE_OBSERVATION_TYPE_UPDATE_API_RES";

export interface SleepScheduleObservationTypeState {
  sleepScheduleObservationTypeRes: SleepScheduleObservationTypeRes;
  loading: boolean;
  successMsg: string;
  errorMsg: string;
  status: number;
}
export interface SleepScheduleObservationTypeLoading {
  type: typeof SLEEP_SCHEDULE_OBSERVATION_TYPE_LOADING;
  loading: boolean;
}
export interface SleepScheduleObservationTypeSuccess {
  type: typeof SLEEP_SCHEDULE_OBSERVATION_TYPE_SUCCESS;
  payload: SleepScheduleObservationTypeRes;
  successMsg: string;
}
export interface SleepScheduleObservationTypeFail {
  type: typeof SLEEP_SCHEDULE_OBSERVATION_TYPE_FAIL;
  payload: SleepScheduleObservationTypeRes;
  errorMsg: string;
  status: number;
}
export interface SleepScheduleObservationTypeUpdateAPIMsg {
  type: typeof SLEEP_SCHEDULE_OBSERVATION_TYPE_UPDATE_API_MSG;
  payload: SleepScheduleObservationTypeRes;
  successMsg: string;
  errorMsg: string;
  status: number;
}
export interface SleepScheduleObservationTypeUpdateAPIRes {
  type: typeof SLEEP_SCHEDULE_OBSERVATION_TYPE_UPDATE_API_RES;
  payload: SleepScheduleObservationTypeRes;
  successMsg: string;
  errorMsg: string;
  status: number;
}

export type SleepScheduleObservationTypeActionTypes =
  | SleepScheduleObservationTypeLoading
  | SleepScheduleObservationTypeSuccess
  | SleepScheduleObservationTypeFail
  | SleepScheduleObservationTypeUpdateAPIMsg
  | SleepScheduleObservationTypeUpdateAPIRes;
