import { useEffect, useState } from "react";
import * as am5 from "@amcharts/amcharts5";
import * as am5xy from "@amcharts/amcharts5/xy";
import am5themes_Animated from "@amcharts/amcharts5/themes/Animated";
import am5themes_Responsive from "@amcharts/amcharts5/themes/Responsive";
import am5themes_Material from "@amcharts/amcharts5/themes/Material";
import { Box } from "@mui/material";
import { useDispatch, useSelector } from "react-redux";
import { useParams } from "react-router-dom";
import {
  DataListByObservationTypeMappingListBody,
  PatientObservationMappingList,
} from "redux/PatientDashboardUiComponentObservationTypeMappingList/Model";
import { dataListByObservationTypeMappingList } from "redux/PatientDashboardUiComponentObservationTypeMappingList/ApiCall";
import { AppState } from "redux/store/Store";
import { HearRateData } from "../Model";
import { TimeUnit } from "@amcharts/amcharts5/.internal/core/util/Time";
import React from "react";

type Props = {
  data?: HearRateData[] | any;
  unit?: TimeUnit;
};
export default function SleepTrends({ data, unit }: Props) {
  const [chartData, setChartData] = useState([] as HearRateData[]);
  console.log("chartData===", chartData);
  const dispatch = useDispatch();
  const { patientid } = useParams() as {
    patientid: string;
  };
  const [obsevationTypeName, setObsevationTypeName] = useState("");
  console.log("obsevationTypeName===", obsevationTypeName);
  useEffect(() => {
    if (data !== undefined) {
      setChartData(data);
    } else setChartData([] as HearRateData[]);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [data]);

  useEffect(() => {
    let mappingListBody = {
      patientId: patientid,
      patientDashboardUiComponent: "sleep",
    } as DataListByObservationTypeMappingListBody;
    dispatch(dataListByObservationTypeMappingList(mappingListBody));
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);
  //observationTypeMappingListValue
  const patientDetailsValue = useSelector(
    (state: AppState) => state.observationTypeMappingListValue,
  );

  useEffect(() => {
    if (
      patientDetailsValue?.dataListByObservationTypeMappingListRes.patientObservationMappingList !==
      undefined
    ) {
      let obsType =
        patientDetailsValue.dataListByObservationTypeMappingListRes.patientObservationMappingList.map(
          (element: PatientObservationMappingList) => ({
            patientObservationType: element.patientObservationType,
          }),
        );
      setObsevationTypeName(JSON.stringify(obsType));
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [patientDetailsValue]);

  useEffect(() => {
    // Create root and chart
    var root = am5.Root.new("sleepChart");

    root.setThemes([
      am5themes_Animated.new(root),
      am5themes_Responsive.new(root),
      am5themes_Material.new(root),
      
    ]);

    var chart = root.container.children.push(
      am5xy.XYChart.new(root, {
        panY: false,
        wheelY: "zoomX",
        layout: root.verticalLayout,
      }),
    );

    // Scrollbar X
    var scrollbarX = am5.Scrollbar.new(root, {
      orientation: "horizontal",
    });

    chart.set("scrollbarX", scrollbarX);
    chart.topAxesContainer.children.push(scrollbarX);

    // Define data
    var data = [
      {
        date: new Date(2021, 0, 1).getTime(),
        values: 1380,
        openValues: 1800,
        duration: 10,
      },
      {
        date: new Date(2021, 0, 2).getTime(),
        values: 1500,
        openValues: 2100,
      },
      {
        date: new Date(2021, 0, 3).getTime(),
        values: 1400,
        openValues: 2100,
      },
      {
        date: new Date(2021, 0, 4).getTime(),
        values: 1420,
        openValues: 2000,
      },
      {
        date: new Date(2021, 0, 5).getTime(),
        values: 1500,
        openValues: 2100,
      },
      {
        date: new Date(2021, 0, 6).getTime(),
        values: 1400,
        openValues: 2000,
      },
      {
        date: new Date(2021, 0, 7).getTime(),
        values: 1320,
        openValues: 1800,
      },
    ];
    console.log("sample data===", data);

    // Create Y-axis
    var yAxis = chart.yAxes.push(
      am5xy.DurationAxis.new(root, {
        baseUnit: "minute",
        min: 720,
        max: 2160,
        renderer: am5xy.AxisRendererY.new(root, {}),
      }),
    );
    yAxis.children.unshift(
      am5.Label.new(root, {
        rotation: -90,
        text: "Time Asleep",
        fontSize: 18,
        fontWeight: "bold",
        y: am5.p50,
        centerX: am5.p50,
      }),
    );
    // Create X-Axis
    var xAxis = chart.xAxes.push(
      am5xy.DateAxis.new(root, {
        baseInterval: { timeUnit: `${unit!}`, count: 1 },
        renderer: am5xy.AxisRendererX.new(root, {
          minGridDistance: 20,
        }),
      }),
    );

    // Create series
    function createSeries(name: any, field: any, openField: any) {
      // Sample Demo Data Column

      // var series = chart.series.push(
      //   am5xy.ColumnSeries.new(root, {
      //     name: name,
      //     xAxis: xAxis,
      //     yAxis: yAxis,
      //     valueYField: field,
      //     valueXField: "date",
      //     openValueYField: openField,
      //     clustered: false,
      //   }),
      // );

      var series = chart.series.push(
        am5xy.ColumnSeries.new(root, {
          name: name,
          xAxis: xAxis,
          yAxis: yAxis,
          valueYField: field,
          valueXField: "modifiedStartDate",
          openValueYField: openField,
          clustered: false,
          interactive: true,
          tooltip: am5.Tooltip.new(root, {
            pointerOrientation: "horizontal",
          }),
          maskBullets: false,
        }),
      );

      let a = {} as any;
      series.columns.template.events.on("pointerover", function (ev) {
        let dataItem = ev.target.dataItem;
        if (dataItem) {
          let data = dataItem.dataContext; // Full data object
          a = data;
          console.log("Hovered data:", data);
          // You can now use the data object for your logic
        }
      });

      // series.columns.template.events.on("pointerover", (event) => {
      //   const dataItem = event.target.dataItem!;
      //   console.log("dataItem", dataItem?.dataContext)
      //   // const customValue = dataItem?.valueY * 2; // Example calculation
      //   event.target.tooltipText = `${dataItem.name}, ${dataItem.categoryX}: ${customValue}`;
      // });

      series.get("tooltip")!.label.adapters.add("text", function (text: any, _target: any) {
        var tooltipDataItem = a;
        // let a = {} as any;
        // a = tooltipDataItem?.dataContext;
        console.log("COlumn Data", tooltipDataItem);
        console.log("text", text);
        // var value: any = a?.renewalNo! / renewalNoRatio!;
        // text = `Renewal Count: ${value}`;
        return "Hi";
      });
      series.appear(1000);
      series.data.setAll(chartData);
      series.columns.template.setAll({
        width: am5.percent(20),
        fillOpacity: 0.5,
        strokeWidth: 2,
      });
      series.columns.template.states.create("riseFromPrevious", {
        fill: am5.color(0xe4572e),
        stroke: am5.color(0xe4572e),
      });
      series.columns.template.states.create("dropFromPrevious", {
        fill: am5.color(0x76b041),
        stroke: am5.color(0x76b041),
      });
    }

    // Add cursor
    // https://www.amcharts.com/docs/v5/charts/xy-chart/cursor/
    var cursor = chart.set(
      "cursor",
      am5xy.XYCursor.new(root, {
        xAxis: xAxis,
      }),
    );
    cursor.lineY.set("visible", false);

    createSeries("Series #1", "sleepStartTimeInMin", "sleepStartEndInMin");

    // Function to add process control ranges
    function addLimits(lower: any, upper: any) {
      // Add range fill
      createRange(lower, upper, undefined, am5.color(0xffce00), false);

      // Add upper/average/lower lines
      createRange(lower, undefined, "Bed Time", am5.color(0x4d00ff), false);
      createRange(upper, undefined, "Wake Time", am5.color(0x4d00ff), false);
      createRange(1440, undefined, "Sleep Schedule", am5.color(0x4d00ff), true);
    }

    addLimits(1320, 1800);

    function createRange(value: any, endValue: any, label: any, color: any, dashed: any) {
      var rangeDataItem = yAxis.makeDataItem({
        value: value,
        endValue: endValue,
      });

      var range = yAxis.createAxisRange(rangeDataItem);

      if (endValue) {
        range.get("axisFill")!.setAll({
          fill: color,
          fillOpacity: 0.2,
          visible: true,
        });
      } else {
        range.get("grid")!.setAll({
          stroke: color,
          strokeOpacity: 1,
          strokeWidth: 2,
          location: 1,
        });

        if (dashed) {
          range.get("grid")!.set("strokeDasharray", [5, 3]);
        }
      }

      if (label) {
        range.get("label")!.setAll({
          text: label,
          location: 1,
          fontSize: 12,
          inside: true,
          centerX: am5.p0,
          centerY: am5.p100,
        });
      }
    }
    return () => root.dispose();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [chartData, unit]);

  return <Box id="sleepChart" style={{ width: "100%", height: "550px", borderRadius: 20 }} />;
}
