import React, { useState, useEffect } from "react";
import { useHistory, useParams } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import {
  Box,
  Button,
  Card,
  CardContent,
  CardHeader,
  Container,
  Grid,
  Pagination,
  Stack,
  Typography,
} from "@mui/material";
import VisibilityIcon from "@mui/icons-material/Visibility";
import { DataGrid, GridCellParams, GridColDef, GridToolbar } from "@mui/x-data-grid";
import dayjs from "dayjs";
import { PatientList } from "../../redux/patientListOfHealthcamp/Model";
import { AppState } from "../../redux/store/Store";
import { getPatientListByHealthcamp } from "../../redux/patientListOfHealthcamp/API";
import { getHealthcampListValue } from "../../redux/healthcampList/API";
import { HealthCampList } from "../../redux/healthcampList/Model";
import MWPageTitle from "../../components/MWPageTitle";
import MWExceptionList from "../../components/MWExceptionList";
import PatientLoader from "../PatientLoader";

export default function PatientListByHealthCamp() {
  /*  create a piece of state (toSignin)
  with a method to update that state (setToHome)
  and give it a default value of false */
  const dispatch = useDispatch();
  const loadingMarkup = <PatientLoader />;
  const { healthcampid } = useParams() as {
    healthcampid: string;
  };
  const AdminAllHealthcampList = useSelector((state: AppState) => state.healthcampListValue);
  const history = useHistory();
  const [page, setPage] = useState(1);
  const handleChange = (_event: React.ChangeEvent<unknown>, value: number) => {
    setPage(value);
    dispatch(getPatientListByHealthcamp(healthcampid, value));
  };
  const [campName, setCampName] = useState("");
  const [listCount, setListCount] = React.useState("");
  function gotoDetails(patientId: number) {
    history.push(`/patientdetails/${patientId}`);
  }
  useEffect(() => {
    dispatch(getPatientListByHealthcamp(healthcampid, page));
    dispatch(getHealthcampListValue());
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [dispatch]);

  useEffect(() => {
    if (AdminAllHealthcampList?.healthcampListValue?.HealthCampList !== undefined) {
      var result = AdminAllHealthcampList?.healthcampListValue?.HealthCampList.find(
        (obj: HealthCampList) => {
          return obj.id === Number(healthcampid);
        },
      )?.displayName;
      if (result !== null) {
        setCampName(result! + " Patient List");
      }
    }
    // eslint-disable-next-line
  }, [AdminAllHealthcampList]);

  function patientListApi() {
    dispatch(getPatientListByHealthcamp(healthcampid, page));
  }
  const truncate = (str: string) => {
    return str.length > 20 ? str.substring(0, 25) + "..." : str;
  };

  // call Store
  const AdminPatientList = useSelector((state: AppState) => state.healthcampPatientListValue);
  const allcaseValues = AdminPatientList.healthcampPatientValue.patientList;
  const [dataGridArray, setDataGridArray] = useState([] as any[]);
  const [pageListCount, setPageListCount] = React.useState("");
  useEffect(() => {
    AdminPatientList?.healthcampPatientValue?.numberOfPages !== undefined
      ? setPageListCount(String(AdminPatientList?.healthcampPatientValue?.numberOfPages))
      : setPageListCount("0");
    if (AdminPatientList?.healthcampPatientValue?.count !== undefined) {
      setListCount(String(AdminPatientList?.healthcampPatientValue?.count));
    }
    if (allcaseValues !== undefined) {
      let dataPointArr = allcaseValues.map((element: PatientList) => ({
        id: element.id,
        firstname: element.firstname,
        lastname: element.lastname,
        gender: element.gender?.label,
        patientId: element.id,
        description: truncate(element.description),
        dob: dayjs(element.dob).format("MMMM D, YYYY"),
        bloodGroup: element.bloodGroup?.label,
        patientRelationshipWithOwner: element.patientRelationshipWithOwner?.displayValue,
      }));
      setDataGridArray(dataPointArr);
    } else {
      setPageListCount(String(AdminPatientList?.healthcampPatientValue?.numberOfPages));
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [AdminPatientList]);

  const columns: GridColDef[] = [
    {
      field: "id",
      headerName: "View",
      width: 100,
      align: "left",
      flex: 1,
      renderCell: (params: GridCellParams) => (
        <Button
          variant="outlined"
          startIcon={<VisibilityIcon />}
          onClick={() => {
            gotoDetails(params.value);
          }}
          sx={{ textTransform: "none" }}
        >
          View
        </Button>
      ),
    },
    {
      field: "patientId",
      headerName: "Patient Id",
      width: 150,
      align: "left",
      flex: 1,
    },
    {
      field: "firstname",
      headerName: "First name",
      editable: true,
      width: 200,
      align: "left",
      flex: 1,
    },
    {
      field: "lastname",
      headerName: "Last name",
      editable: true,
      width: 200,
      align: "left",
      flex: 1,
    },
    {
      field: "dob",
      headerName: "DOB",
      type: "string",
      editable: true,
      width: 200,
      align: "left",
      flex: 1,
    },
    {
      field: "patientRelationshipWithOwner",
      headerName: "Relationship",
      description: "This column has a value getter and is not sortable.",
      sortable: false,
      width: 100,
      align: "left",
      flex: 1,
    },
    {
      field: "gender",
      headerName: "Gender",
      editable: true,
      width: 100,
      align: "left",
      flex: 1,
    },
    {
      field: "bloodGroup",
      headerName: "BloodGroup",
      editable: true,
      width: 100,
      align: "left",
      flex: 1,
    },
    {
      field: "description",
      headerName: "Description",
      editable: true,
      width: 200,
      align: "left",
      flex: 1,
    },
  ];
  const PatientListOfHealthcamp = (
    <Card>
      <CardHeader
        title={
          <Grid
            container
            direction="row"
            justifyContent="flex-end"
            alignItems="flex-start"
            spacing={2}
          >
            <Grid item>
              <Grid
                container
                direction="row"
                justifyContent="space-between"
                alignItems="center"
                spacing={2}
              >
                <Grid item>
                  <Typography>Page: {page}</Typography>
                </Grid>
                <Grid item>
                  <Pagination
                    color="primary"
                    count={Number(pageListCount)}
                    page={page}
                    onChange={handleChange}
                  />
                </Grid>
              </Grid>
            </Grid>
          </Grid>
        }
      />
      <CardContent>
        {allcaseValues && allcaseValues.length ? (
          <DataGrid
            rows={dataGridArray}
            columns={columns}
            disableSelectionOnClick
            hideFooter
            hideFooterPagination
            experimentalFeatures={{ newEditingApi: true }}
            autoHeight
            components={{ Toolbar: GridToolbar }}
            componentsProps={{
              toolbar: {
                csvOptions: { disableToolbarButton: true },
                printOptions: { disableToolbarButton: true },
                showQuickFilter: true,
                quickFilterProps: { debounceMs: 250 },
              },
            }}
            disableColumnFilter
            disableColumnSelector
            disableDensitySelector
          />
        ) : (
          <MWExceptionList />
        )}
      </CardContent>
    </Card>
  );
  return (
    <Box>
      {AdminPatientList.loading === true ? loadingMarkup : ""}
      <Container maxWidth="xl">
        <Stack direction="column" justifyContent="flex-start" alignItems="stretch" spacing={2}>
          <Grid container direction="row" justifyContent="space-between" alignItems="flex-start">
            <Grid item>
              <MWPageTitle
                backButton={true}
                title={campName}
                enableCount={true}
                count={listCount}
                reload={true}
                reloadAction={patientListApi}
              />
            </Grid>
          </Grid>
          {PatientListOfHealthcamp}
        </Stack>
      </Container>
    </Box>
  );
}
