import * as React from "react";
import {
  Box,
  Button,
  Card,
  CardActions,
  CardContent,
  CardHeader,
  Grid,
  Typography,
} from "@mui/material";
import ArrowRightAltIcon from "@mui/icons-material/ArrowRightAlt";
import CarePlanList from "./CarePlanList.json";

export default function CarePlan() {
  return (
    <Card style={{ borderRadius: 20, height: "100%" }}>
      <CardHeader
        title={
          <Grid container direction="row" justifyContent="flex-start" alignItems="flex-start">
            <Grid item>
              <Typography variant="subtitle1" style={{ fontWeight: "bold" }}>
                Care Plan
              </Typography>
            </Grid>
          </Grid>
        }
      />
      <CardContent>
        <Box>
          {CarePlanList.length > 0 ? (
            CarePlanList.map((element) => {
              return (
                <Box key={element.id} p={2} my={1} border={2} sx={{ borderColor: "#c7c1ac" }}>
                  <Typography variant="body1">{element.name}</Typography>
                </Box>
              );
            })
          ) : (
            <Box>
              <Typography variant="h6">Not Set</Typography>
            </Box>
          )}
        </Box>
      </CardContent>
      <CardActions>
        <Grid
          container
          direction="row"
          justifyContent="flex-end"
          alignItems="flex-start"
          spacing={1}
        >
          <Grid item>
            <Grid container direction="row" justifyContent="center" alignItems="center" spacing={1}>
              <Grid item>
                <Button
                  variant="text"
                  endIcon={<ArrowRightAltIcon />}
                  sx={{ textTransform: "none" }}
                >
                  View All
                </Button>
              </Grid>
            </Grid>
          </Grid>
        </Grid>
      </CardActions>
    </Card>
  );
}
