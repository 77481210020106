import React from "react";
import { Box, Chip, Grid, IconButton, Typography } from "@mui/material";
import { DataGrid, GridColDef, GridToolbar } from "@mui/x-data-grid";
import RefreshIcon from "@mui/icons-material/Refresh";

export default function DoctorVisitByCaregiver() {
  const columns: GridColDef[] = [
    {
      field: "id",
      headerName: "No of Doctor Visit",
      width: 150,
    },
    { field: "docName", headerName: "Doctor Name", width: 150 },
    { field: "vDate", headerName: "Visit Date", width: 150 },
    { field: "patientName", headerName: "Patient Name", width: 207 },
    { field: "problemName", headerName: "Problem Description", width: 250 },
  ];

  const rows = [
    {
      id: 1,
      vDate: "2nd jan,2024",
      patientName: "Shyamapriya Das",
      problemName: "High Feaver",
      docName: "DR. Koushik Das",
    },
    {
      id: 2,
      vDate: "2nd feb,2024",
      patientName: "Shyamapriya Das",
      problemName: "Regular Health Checkup",
      docName: "DR. Koushik Das",
    },
    {
      id: 3,
      vDate: "2nd feb,2024",
      patientName: "Shyamapriya Das",
      problemName: "Regular Health Checkup",
      docName: "DR. Koushik Das",
    },
  ];

  return (
    <Box>
      <Grid container direction="row" justifyContent="flex-start" alignItems="center" spacing={1}>
        <Grid item>
          <Typography variant="h5">Doctor Visit Accompanied by The CareGiver</Typography>
        </Grid>
        <Grid item>
          <Chip label="Inactive" color="secondary" variant="outlined" size="small" />
        </Grid>
        <Grid item>
          <IconButton aria-label="refresh">
            <RefreshIcon />
          </IconButton>
        </Grid>
      </Grid>
      <DataGrid
        rows={rows}
        columns={columns}
        getRowId={(row) => row.id}
        hideFooter
        hideFooterPagination
        disableSelectionOnClick
        experimentalFeatures={{ newEditingApi: true }}
        autoHeight
        components={{ Toolbar: GridToolbar }}
        componentsProps={{
          toolbar: {
            csvOptions: { disableToolbarButton: true },
            printOptions: { disableToolbarButton: true },
            showQuickFilter: true,
            quickFilterProps: { debounceMs: 250 },
          },
        }}
        disableColumnFilter
        disableColumnSelector
        disableDensitySelector
      />
    </Box>
  );
}
