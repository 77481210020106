import {
  AddPatientCaseActionTypes,
  AddPatientCaseStateType,
  ADD_PATIENT_CASE_FAIL,
  ADD_PATIENT_CASE_LOADING,
  ADD_PATIENT_CASE_SUCCESS,
  AdminGetConsultationTypeListActionTypes,
  AdminGetOpinionTypeListActionTypes,
  AdminGetSecondOpinionListActionTypes,
  ConsultationTypeListStateType,
  CONSULTATION_TYPE_LIST_FAIL,
  CONSULTATION_TYPE_LIST_LOADING,
  CONSULTATION_TYPE_LIST_SUCCESS,
  EditPatientCaseActionTypes,
  EditPatientCaseStateType,
  EDIT_PATIENT_CASE_FAIL,
  EDIT_PATIENT_CASE_LOADING,
  EDIT_PATIENT_CASE_SUCCESS,
  OpinionTypeListStateType,
  OPINIONTYPELIST_FAIL,
  OPINIONTYPELIST_LOADING,
  OPINIONTYPELIST_SUCCESS,
  PatientCaseDetailsActionTypes,
  PatientCaseDetailsStateType,
  PATIENT_CASE_DETAILS_FAIL,
  PATIENT_CASE_DETAILS_LOADING,
  PATIENT_CASE_DETAILS_SUCCESS,
  ResponseTimeActionTypes,
  ResponseTimeStateType,
  RESPONSE_TIME_FAIL,
  RESPONSE_TIME_LOADING,
  RESPONSE_TIME_SUCCESS,
  SecondOpinionListStateType,
  SECOND_OPINION_LIST_FAIL,
  SECOND_OPINION_LIST_LOADING,
  SECOND_OPINION_LIST_SUCCESS,
} from "./ActionTypes";

const initialStateGetOpinionTypeList: OpinionTypeListStateType = {
  loading: false,
  opinionTypeListValue: [],
  message: Object.assign({}),
  status: Object.assign({}),
};

export const getOpinionTypeListReducer = (
  state = initialStateGetOpinionTypeList,
  action: AdminGetOpinionTypeListActionTypes,
): OpinionTypeListStateType => {
  switch (action.type) {
    case OPINIONTYPELIST_SUCCESS:
      return {
        ...state,
        loading: false,
        opinionTypeListValue: action.payload,
        status: action.status,
        message: action.message,
      };
    case OPINIONTYPELIST_LOADING:
      return {
        ...state,
        loading: true,
        opinionTypeListValue: [],
      };
    case OPINIONTYPELIST_FAIL:
      return {
        ...state,
        opinionTypeListValue: [],
        loading: false,
        status: action.status,
        message: action.message,
      };
    default:
      return state;
  }
};

// 2nd opinion list
const initialStateSecondOpinionList: SecondOpinionListStateType = {
  loading: false,
  secondOpinionListValue: [],
  message: Object.assign({}),
  status: Object.assign({}),
};

export const secondOpinionListReducer = (
  state = initialStateSecondOpinionList,
  action: AdminGetSecondOpinionListActionTypes,
): SecondOpinionListStateType => {
  switch (action.type) {
    case SECOND_OPINION_LIST_SUCCESS:
      return {
        ...state,
        loading: false,
        secondOpinionListValue: action.payload,
        status: action.status,
        message: action.message,
      };
    case SECOND_OPINION_LIST_LOADING:
      return {
        ...state,
        loading: true,
        secondOpinionListValue: [],
      };
    case SECOND_OPINION_LIST_FAIL:
      return {
        ...state,
        secondOpinionListValue: [],
        loading: false,
        status: action.status,
        message: action.message,
      };
    default:
      return state;
  }
};
//consultation type ..
const initialStateConsultationTypeList: ConsultationTypeListStateType = {
  loading: false,
  consultationTypeListValue: [],
  message: Object.assign({}),
  status: Object.assign({}),
};

export const consultationTypeListReducer = (
  state = initialStateConsultationTypeList,
  action: AdminGetConsultationTypeListActionTypes,
): ConsultationTypeListStateType => {
  switch (action.type) {
    case CONSULTATION_TYPE_LIST_SUCCESS:
      return {
        ...state,
        loading: false,
        consultationTypeListValue: action.payload,
        status: action.status,
        message: action.message,
      };
    case CONSULTATION_TYPE_LIST_LOADING:
      return {
        ...state,
        loading: true,
        consultationTypeListValue: [],
      };
    case CONSULTATION_TYPE_LIST_FAIL:
      return {
        ...state,
        consultationTypeListValue: [],
        loading: false,
        status: action.status,
        message: action.message,
      };
    default:
      return state;
  }
};
// response time ..
const initialStateResponseTimeList: ResponseTimeStateType = {
  loading: false,
  responseTimeTypeList: [],
};

export const responseTimeReducer = (
  state = initialStateResponseTimeList,
  action: ResponseTimeActionTypes,
): ResponseTimeStateType => {
  switch (action.type) {
    case RESPONSE_TIME_SUCCESS:
      return {
        ...state,
        loading: false,
        responseTimeTypeList: action.payload,
      };
    case RESPONSE_TIME_LOADING:
      return {
        ...state,
        loading: true,
      };
    case RESPONSE_TIME_FAIL:
      return {
        ...state,
        loading: false,
      };
    default:
      return state;
  }
};
//For Add patient case
const initialStateAddPatientCase: AddPatientCaseStateType = {
  loading: false,
  response: Object.assign({}),
};

export const addPatientCaseReducer = (
  state = initialStateAddPatientCase,
  action: AddPatientCaseActionTypes,
): AddPatientCaseStateType => {
  switch (action.type) {
    case ADD_PATIENT_CASE_SUCCESS:
      return {
        ...state,
        loading: false,
        response: action.payload,
      };
    case ADD_PATIENT_CASE_LOADING:
      return {
        ...state,
        loading: true,
      };
    case ADD_PATIENT_CASE_FAIL:
      return {
        ...state,
        loading: false,
        response: action.payload,
      };
    default:
      return state;
  }
};
//For Edit patient case
const initialStateEditPatientCase: EditPatientCaseStateType = {
  loading: false,
  response: Object.assign({}),
};

export const editPatientCaseReducer = (
  state = initialStateEditPatientCase,
  action: EditPatientCaseActionTypes,
): EditPatientCaseStateType => {
  switch (action.type) {
    case EDIT_PATIENT_CASE_SUCCESS:
      return {
        ...state,
        loading: false,
        response: action.payload,
      };
    case EDIT_PATIENT_CASE_LOADING:
      return {
        ...state,
        loading: true,
      };
    case EDIT_PATIENT_CASE_FAIL:
      return {
        ...state,
        loading: false,
        response: action.payload,
      };
    default:
      return state;
  }
};
//For get patient case details
const initialStateGetPatientCaseDetails: PatientCaseDetailsStateType = {
  loading: false,
  patientCaseDetail: Object.assign({}),
  status: Object.assign({}),
};

export const getPatientCaseDetailsReducer = (
  state = initialStateGetPatientCaseDetails,
  action: PatientCaseDetailsActionTypes,
): PatientCaseDetailsStateType => {
  switch (action.type) {
    case PATIENT_CASE_DETAILS_SUCCESS:
      return {
        ...state,
        loading: false,
        patientCaseDetail: action.patientCaseDetail,
        status: action.status,
      };
    case PATIENT_CASE_DETAILS_LOADING:
      return {
        ...state,
        loading: true,
      };
    case PATIENT_CASE_DETAILS_FAIL:
      return {
        ...state,
        loading: false,
        patientCaseDetail: action.patientCaseDetail,
        status: action.status,
      };
    default:
      return state;
  }
};
