import {
  Alert,
  AlertColor,
  Box,
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Divider,
  Grid,
  IconButton,
  TextField,
  Typography,
} from "@mui/material";
import CloseIcon from "@mui/icons-material/Close";
import React, { useEffect, useState } from "react";
import { AddNoteBody } from "../../../../redux/Notes/addNotes/Model";
import { useParams } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import {
  addNote,
  clearAddNoteAPIRes,
  updateAddNoteAPIResMsg,
} from "../../../../redux/Notes/addNotes/API";
import { AppState } from "../../../../redux/store/Store";
import AdminLoader from "../../../../components/MWLoader";
type Props = {
  addNoteDialog: boolean | any;
  updateAddNoteDialogUpdateState: boolean | any;
};
export default function AddPatientNote({ addNoteDialog, updateAddNoteDialogUpdateState }: Props) {
  const { profileId } = useParams() as {
    profileId: string;
  };
  const dispatch = useDispatch();
  const [activeDialog, setActiveDialog] = useState(false);
  const closeDialog = () => {
    setActiveDialog(false);
    dispatch(clearAddNoteAPIRes());
    updateAddNoteDialogUpdateState(false);
  };
  useEffect(() => {
    setActiveDialog(addNoteDialog);
  }, [addNoteDialog]);
  const [note, setNote] = useState("");
  const [noteError, setNoteError] = useState("");

  const addNoteBody = {
    patientId: profileId,
    note: note,
  } as AddNoteBody;
  const addPatientNote = () => {
    if (note === "") {
      setNoteError("Please add a note");
    } else {
      setNoteError("");
      dispatch(addNote(addNoteBody));
    }
  };
  const [addNoteErrMsg, setAddNoteErrMsg] = useState("");
  const [addNoteErrMsgType, setAddNoteErrMsgType] = useState<AlertColor>("error");
  // Add Notes Api Value from store
  const addNotesVal = useSelector((state: AppState) => state.addNoteRes);
  useEffect(() => {
    if (addNotesVal?.successMsg !== "") {
      setActiveDialog(false);
      updateAddNoteDialogUpdateState(false);
    }
    if (addNotesVal?.errorMsg !== "") {
      setAddNoteErrMsgType("error");
      setAddNoteErrMsg(addNotesVal?.errorMsg);
    }
    if (addNotesVal?.errorMsg === "") {
      setAddNoteErrMsg(addNotesVal?.errorMsg);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [addNotesVal]);
  return (
    <Dialog open={activeDialog} onClose={closeDialog} fullWidth>
      {addNotesVal?.loading ? <AdminLoader /> : null}
      <DialogTitle>
        <Grid container direction="row" justifyContent="space-between" alignItems="flex-start">
          <Grid item>
            <Typography variant="h6">Add Note</Typography>
          </Grid>
          <Grid item>
            <IconButton onClick={closeDialog}>
              <CloseIcon />
            </IconButton>
          </Grid>
        </Grid>
      </DialogTitle>
      <Divider />
      <DialogContent>
        <TextField
          id="notesID"
          value={note}
          label="Patient Notes"
          placeholder="Please provide notes"
          onChange={(e) => {
            setNote(e.target.value);
          }}
          multiline
          rows={4}
          maxRows={4}
          fullWidth
          disabled={addNotesVal?.loading}
        />
        {noteError !== "" ? <Typography sx={{ color: "#c62828" }}>{noteError}</Typography> : null}
      </DialogContent>
      <Divider />
      {addNoteErrMsg !== "" ? (
        <Box>
          <Alert
            severity={addNoteErrMsgType}
            onClose={() => {
              dispatch(updateAddNoteAPIResMsg());
            }}
          >
            {addNoteErrMsg}
          </Alert>
        </Box>
      ) : null}
      <DialogActions>
        <Grid container direction="row" justifyContent="flex-end" alignItems="flex-end" spacing={1}>
          <Grid item>
            <Button variant="outlined" onClick={closeDialog} sx={{ textTransform: "none" }}>
              Cancel
            </Button>
          </Grid>
          <Grid item>
            <Button
              variant="contained"
              onClick={addPatientNote}
              disabled={addNotesVal?.loading}
              sx={{ textTransform: "none" }}
            >
              Submit
            </Button>
          </Grid>
        </Grid>
      </DialogActions>
    </Dialog>
  );
}
