import { CheckPaymentStatusRes } from "./Model";

export const CHECK_PAYMENT_STATUS_LOADING = "CHECK_PAYMENT_STATUS_LOADING";
export const CHECK_PAYMENT_STATUS_SUCCESS = "CHECK_PAYMENT_STATUS_SUCCESS";
export const CHECK_PAYMENT_STATUS_FAIL = "CHECK_PAYMENT_STATUS_FAIL";
export const CHECK_PAYMENT_STATUS_DATA = "CHECK_PAYMENT_STATUS_DATA";

export interface CheckPaymentStatusDetails {
  checkPaymentStatusRes: CheckPaymentStatusRes;
  loading: boolean;
  errRes: string;
}
export interface CheckPaymentStatusDetailsActionLoading {
  type: typeof CHECK_PAYMENT_STATUS_LOADING;
}
export interface CheckPaymentStatusDetailsActionSuccess {
  type: typeof CHECK_PAYMENT_STATUS_SUCCESS;
  payload: CheckPaymentStatusRes;
  errRes: string;
}
export interface CheckPaymentStatusDetailsActionFail {
  type: typeof CHECK_PAYMENT_STATUS_FAIL;
  payload: CheckPaymentStatusRes;
  errRes: string;
}
interface CheckPaymentStatusDetailsActionAction {
  type: typeof CHECK_PAYMENT_STATUS_DATA;
  payload: CheckPaymentStatusRes;
  errRes: string;
}

export type CheckPaymentStatusDetailsActionTypes =
  | CheckPaymentStatusDetailsActionAction
  | CheckPaymentStatusDetailsActionLoading
  | CheckPaymentStatusDetailsActionSuccess
  | CheckPaymentStatusDetailsActionFail;
