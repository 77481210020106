import React, { useEffect, useState } from "react";
import { Box, Grid, Tab, Tabs, Typography } from "@mui/material";
import { useHistory, useLocation } from "react-router-dom";
import UserProfile from "./UserProfile/Userprofile";
import Settings from "./Settings";
interface TabPanelProps {
  children?: React.ReactNode;
  dir?: string;
  index: number;
  value: number;
}

function TabPanel(props: TabPanelProps) {
  const { children, value, index, ...other } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`full-width-tabpanel-${index}`}
      aria-labelledby={`full-width-tab-${index}`}
      {...other}
    >
      {value === index && (
        <Box sx={{ p: 3 }}>
          <Typography>{children}</Typography>
        </Box>
      )}
    </div>
  );
}

function a11yProps(index: number) {
  return {
    id: `full-width-tab-${index}`,
    "aria-controls": `full-width-tabpanel-${index}`,
  };
}

function UseQuery() {
  const { search } = useLocation();
  return React.useMemo(() => new URLSearchParams(search), [search]);
}

export default function UserDetails() {
  let query = UseQuery();
  const tabId = query.get("section");
  const history = useHistory();
  const [selected, setSelected] = React.useState(0);

  const [tabValue, setTabValue] = useState(0);

  const handleTabChange = (_event: React.SyntheticEvent, newValue: number) => {
    setSelected(newValue);
    window.scrollTo(0, 0);
    if (newValue === 0) {
      history.push(`${window.location.pathname + "?section=userprofile"}`);
    } else if (newValue === 1) {
      history.push(`${window.location.pathname + "?section=delete-account"}`);
    }
  };

  const selectedYearRef = React.useRef(selected);
  useEffect(() => {
    selectedYearRef.current = selected;
  }, [selected]);

  useEffect(() => {
    if (tabId === "userprofile") {
      setTabValue(0);
    } else if (tabId === "delete-account") {
      setTabValue(1);
    }
    // eslint-disable-next-line
  }, [tabId]);

  const UserdetailsPage = (
    <Grid>
      <Grid item>
        <Box>
          <Tabs
            value={tabValue}
            onChange={handleTabChange}
            indicatorColor="primary"
            textColor="inherit"
            variant="scrollable"
            aria-label="full width tabs example"
            scrollButtons
          >
            <Tab
              label={
                <Grid
                  container
                  direction="row"
                  justifyContent="center"
                  alignItems="center"
                  spacing={1}
                >
                  <Grid item>
                    <Typography>User Profile</Typography>
                  </Grid>
                </Grid>
              }
              {...a11yProps(0)}
            />
            <Tab
              label={
                <Grid
                  container
                  direction="row"
                  justifyContent="center"
                  alignItems="center"
                  spacing={1}
                >
                  <Grid item>
                    <Typography>Settings</Typography>
                  </Grid>
                </Grid>
              }
              {...a11yProps(1)}
            />
          </Tabs>
          <Box>
            <TabPanel value={tabValue} index={0}>
              <UserProfile />
            </TabPanel>
            <TabPanel value={tabValue} index={1}>
              <Settings />
            </TabPanel>
          </Box>
        </Box>
      </Grid>
    </Grid>
  );
  return <Box>{UserdetailsPage}</Box>;
}
