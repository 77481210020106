import { DataListByObservationTypeMappingListRes } from "./Model";
export const DATALIST_BY_OBSERVATION_TYPE_MAPPING_LIST_LOADING = "DATALIST_BY_OBSERVATION_TYPE_MAPPING_LIST_LOADING";
export const DATALIST_BY_OBSERVATION_TYPE_MAPPING_LIST_SUCCESS = "DATALIST_BY_OBSERVATION_TYPE_MAPPING_LIST_SUCCESS";
export const DATALIST_BY_OBSERVATION_TYPE_MAPPING_LIST_FAIL = "DATALIST_BY_OBSERVATION_TYPE_MAPPING_LIST_FAIL";
export const DATALIST_BY_OBSERVATION_TYPE_MAPPING_LIST_UPDATE_API_MSG =
  "DATALIST_BY_OBSERVATION_TYPE_MAPPING_LIST_UPDATE_API_MSG";
export const DATALIST_BY_OBSERVATION_TYPE_MAPPING_LIST_UPDATE_API_RES =
  "DATALIST_BY_OBSERVATION_TYPE_MAPPING_LIST_UPDATE_API_RES";

export interface DataListByObservationTypeMappingListState {
  dataListByObservationTypeMappingListRes: DataListByObservationTypeMappingListRes;
  loading: boolean;
  successMsg: string;
  errorMsg: string;
  status: number;
}
export interface DataListByObservationTypeMappingListLoading {
  type: typeof DATALIST_BY_OBSERVATION_TYPE_MAPPING_LIST_LOADING;
  loading: boolean;
}
export interface DataListByObservationTypeMappingListSuccess {
  type: typeof DATALIST_BY_OBSERVATION_TYPE_MAPPING_LIST_SUCCESS;
  payload: DataListByObservationTypeMappingListRes;
  successMsg: string;
}
export interface DataListByObservationTypeMappingListFail {
  type: typeof DATALIST_BY_OBSERVATION_TYPE_MAPPING_LIST_FAIL;
  payload: DataListByObservationTypeMappingListRes;
  errorMsg: string;
  status: number;
}
export interface DataListByObservationTypeMappingListUpdateAPIMsg {
  type: typeof DATALIST_BY_OBSERVATION_TYPE_MAPPING_LIST_UPDATE_API_MSG;
  payload: DataListByObservationTypeMappingListRes;
  successMsg: string;
  errorMsg: string;
  status: number;
}
export interface DataListByObservationTypeMappingListUpdateAPIRes {
  type: typeof DATALIST_BY_OBSERVATION_TYPE_MAPPING_LIST_UPDATE_API_RES;
  payload: DataListByObservationTypeMappingListRes;
  successMsg: string;
  errorMsg: string;
  status: number;
}

export type DataListByObservationTypeMappingListActionTypes =
  | DataListByObservationTypeMappingListLoading
  | DataListByObservationTypeMappingListSuccess
  | DataListByObservationTypeMappingListFail
  | DataListByObservationTypeMappingListUpdateAPIMsg
  | DataListByObservationTypeMappingListUpdateAPIRes;
