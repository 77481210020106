import { GetAppointmentSelectedDoctorListRes } from "./Model";

export const GET_APPOINTMENT_SELECTED_DOCTOR_LIST_LOADING =
  "GET_APPOINTMENT_SELECTED_DOCTOR_LIST_LOADING";
export const GET_APPOINTMENT_SELECTED_DOCTOR_LIST_SUCCESS =
  "GET_APPOINTMENT_SELECTED_DOCTOR_LIST_SUCCESS";
export const GET_APPOINTMENT_SELECTED_DOCTOR_LIST_FAIL =
  "GET_APPOINTMENT_SELECTED_DOCTOR_LIST_FAIL";
export const GET_APPOINTMENT_SELECTED_DOCTOR_LIST_DATA =
  "GET_APPOINTMENT_SELECTED_DOCTOR_LIST_DATA";

export interface AppointmentSelectedDoctorListDetails {
  getAppointmentSelectedDoctorListRes: GetAppointmentSelectedDoctorListRes;
  loading: boolean;
  errRes: string;
}
export interface GetAppointmentSelectedDoctorListActionLoading {
  type: typeof GET_APPOINTMENT_SELECTED_DOCTOR_LIST_LOADING;
}
export interface GetAppointmentSelectedDoctorListActionSuccess {
  type: typeof GET_APPOINTMENT_SELECTED_DOCTOR_LIST_SUCCESS;
  payload: GetAppointmentSelectedDoctorListRes;
  errRes: string;
}
export interface GetAppointmentSelectedDoctorListActionFail {
  type: typeof GET_APPOINTMENT_SELECTED_DOCTOR_LIST_FAIL;
  payload: GetAppointmentSelectedDoctorListRes;
  errRes: string;
}
interface GetAppointmentSelectedDoctorListActionAction {
  type: typeof GET_APPOINTMENT_SELECTED_DOCTOR_LIST_DATA;
  payload: GetAppointmentSelectedDoctorListRes;
  errRes: string;
}

export type GetAppointmentSelectedDoctorListActionTypes =
  | GetAppointmentSelectedDoctorListActionAction
  | GetAppointmentSelectedDoctorListActionLoading
  | GetAppointmentSelectedDoctorListActionSuccess
  | GetAppointmentSelectedDoctorListActionFail;
