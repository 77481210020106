import { DoctorlistResponse } from "./Model";

export const DOCTOR_LIST_LOADING = "DOCTOR_LIST_LOADING";
export const DOCTOR_LIST_SUCCESS = "DOCTOR_LIST_SUCCESS";
export const DOCTOR_LIST_FAIL = "DOCTOR_LIST_FAIL";
export const DOCTOR_LIST = "DOCTOR_LIST";

export interface doctorlistDetails {
  doctorsList: DoctorlistResponse;
  loading: boolean;
  errRes: string;
}
export interface doctorlistLoading {
  type: typeof DOCTOR_LIST_LOADING;
}
export interface doctorlistSuccess {
  type: typeof DOCTOR_LIST_SUCCESS;
  payload: DoctorlistResponse;
  errRes: string;
}
export interface doctorlistFail {
  type: typeof DOCTOR_LIST_FAIL;
  errRes: string;
}

interface doctorlistAction {
  type: typeof DOCTOR_LIST;
  payload: DoctorlistResponse;
  errRes: string;
}

export type doctorlistActionTypes =
  | doctorlistAction
  | doctorlistLoading
  | doctorlistSuccess
  | doctorlistFail;
