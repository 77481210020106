import React, { useState, useEffect } from "react";
import { useParams } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { AppState } from "../../../../redux/store/Store";
import {
  Alert,
  AlertColor,
  AlertTitle,
  Box,
  Button,
  Card,
  CardActions,
  CardContent,
  CardHeader,
  FormControl,
  FormControlLabel,
  Grid,
  Radio,
  RadioGroup,
} from "@mui/material";
import { getDoctorTeamTypeList } from "../../../../redux/Patient/AppointmentSelectDoctor/DoctorTeamTypeList/ApiCall";
import { GetDoctorTeamTypeListBody } from "../../../../redux/Patient/AppointmentSelectDoctor/DoctorTeamTypeList/Model";
import { getDoctorTeamTypeForCase } from "../../../../redux/Patient/AppointmentSelectDoctor/DoctorTeamTypeForCase/ApiCall";
import { GetDoctorTeamTypeForCaseBody } from "../../../../redux/Patient/AppointmentSelectDoctor/DoctorTeamTypeForCase/Model";
import {
  clearEditDoctorTeamTypeListAPIRes,
  editDoctorTeamType,
} from "../../../../redux/Patient/AppointmentSelectDoctor/EditDoctorTeamType/ApiCall";
import { EditDoctorTeamTypeListBody } from "../../../../redux/Patient/AppointmentSelectDoctor/EditDoctorTeamType/Model";
import { toggleAppBarLoading } from "redux/appLoader/Actions";

export default function AppointmentSelectDoctor() {
  const dispatch = useDispatch();

  const { caseid } = useParams() as {
    caseid: string;
  };

  const [value, setValue] = useState<any>(0);

  const [isEdit, setIsEdit] = useState(true);
  const [isEnableSubmit, setEnableSubmit] = useState(true);

  useEffect(() => {
    dispatch(getDoctorTeamTypeList({ caseId: caseid } as GetDoctorTeamTypeListBody));
    dispatch(
      getDoctorTeamTypeForCase({
        caseId: caseid,
      } as GetDoctorTeamTypeForCaseBody),
    );
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);
  const [doctorTeamTypeList, setDoctorTeamTypeList] = useState([] as any[]);
  // Get Doctor team Type list store
  const DoctorTeamTypeListFromAPI = useSelector((state: AppState) => state.doctorTeamTypeListValue);

  useEffect(() => {
    dispatch(toggleAppBarLoading(DoctorTeamTypeListFromAPI?.loading));
    if (DoctorTeamTypeListFromAPI?.getDoctorTeamTypeListRes?.doctorTeamTypeList !== undefined) {
      if (DoctorTeamTypeListFromAPI?.getDoctorTeamTypeListRes?.doctorTeamTypeList.length > 0) {
        let doctorTeamTypeListArray: Array<{ value: number; label: string }> = [];
        DoctorTeamTypeListFromAPI?.getDoctorTeamTypeListRes?.doctorTeamTypeList.forEach(
          (element: { value: number; label: string }) => {
            doctorTeamTypeListArray.push({
              value: element.value,
              label: element.label,
            });
          },
        );
        setDoctorTeamTypeList(doctorTeamTypeListArray);
      } else setDoctorTeamTypeList([] as any[]);
    } else setDoctorTeamTypeList([] as any[]);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [DoctorTeamTypeListFromAPI]);

  const getDoctorTeamTypeForCaseRes = useSelector(
    (state: AppState) => state.getDoctorTeamTypeForCaseRes,
  );
  useEffect(() => {
    dispatch(toggleAppBarLoading(getDoctorTeamTypeForCaseRes?.loading));
    if (
      getDoctorTeamTypeForCaseRes?.getDoctorTeamTypeForCaseRes?.caseDoctorTeamTypelist !== undefined
    ) {
      if (
        getDoctorTeamTypeForCaseRes?.getDoctorTeamTypeForCaseRes?.caseDoctorTeamTypelist.length > 0
      ) {
        setValue(
          getDoctorTeamTypeForCaseRes?.getDoctorTeamTypeForCaseRes?.caseDoctorTeamTypelist[0]
            .value!,
        );
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [getDoctorTeamTypeForCaseRes]);

  const [editDocTeamTypeMsg, setEditDocTeamTypeMsg] = useState("");
  const [editDocTeamTypeMsgType, setEditDocTeamTypeMsgType] = useState<AlertColor>("success");
  const editDoctorTeamTypeRes = useSelector((state: AppState) => state.editDoctorTeamTypeRes);
  useEffect(() => {
    dispatch(toggleAppBarLoading(editDoctorTeamTypeRes?.loading));
    if (editDoctorTeamTypeRes?.successMsg !== "") {
      setEditDocTeamTypeMsg(editDoctorTeamTypeRes?.successMsg);
      setEditDocTeamTypeMsgType("success");
    }
    if (editDoctorTeamTypeRes?.successMsg === "") {
      setEditDocTeamTypeMsg("");
    }
    if (editDoctorTeamTypeRes?.errorMsg !== "") {
      setEditDocTeamTypeMsg(editDoctorTeamTypeRes?.errorMsg);
      setEditDocTeamTypeMsgType("error");
    }
    if (editDoctorTeamTypeRes?.errorMsg !== "") {
      setEditDocTeamTypeMsg("");
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [editDoctorTeamTypeRes]);

  return (
    <Card>
      <CardHeader title="Doctor Team Type" />
      <CardContent>
        {doctorTeamTypeList.map((doctorTeam) => (
          <FormControl key={doctorTeam.value} disabled={isEdit} fullWidth variant="outlined">
            <RadioGroup
              value={value}
              onChange={(event: React.ChangeEvent<HTMLInputElement>) =>
                setValue((event.target as HTMLInputElement).value)
              }
            >
              <FormControlLabel
                value={doctorTeam.value}
                control={<Radio />}
                label={doctorTeam.label}
              />
            </RadioGroup>
          </FormControl>
        ))}
        {editDocTeamTypeMsg !== "" ? (
          <Box my={2}>
            <Alert
              severity={editDocTeamTypeMsgType}
              onClose={() => {
                dispatch(clearEditDoctorTeamTypeListAPIRes());
              }}
            >
              <AlertTitle>{editDocTeamTypeMsg}</AlertTitle>
            </Alert>
          </Box>
        ) : null}
      </CardContent>

      <CardActions>
        <Grid container direction="row" justifyContent="flex-end" alignItems="flex-end" spacing={2}>
          <Grid item>
            <Button
              variant="outlined"
              size="medium"
              onClick={() => {
                setIsEdit((isEdit) => !isEdit);
                setEnableSubmit((isEnableSubmit) => !isEnableSubmit);
              }}
            >
              {isEdit ? "Edit" : "Cancel"}
            </Button>
          </Grid>
          <Grid item>
            <Button
              variant="contained"
              size="medium"
              onClick={() =>
                dispatch(
                  editDoctorTeamType({
                    caseId: caseid,
                    doctorTeamTypeId: value,
                  } as EditDoctorTeamTypeListBody),
                )
              }
              disabled={isEnableSubmit || editDoctorTeamTypeRes?.loading}
            >
              Update
            </Button>
          </Grid>
        </Grid>
      </CardActions>
    </Card>
  );
}
