import {
  GetInsuranceDetalsByPatientDetails,
  getInsuranceDetailsByPatientActionTypes,
  GET_INSURANCE_DETAILS_BY_PATIENT_FAIL,
  GET_INSURANCE_DETAILS_BY_PATIENT_LOADING,
  GET_INSURANCE_DETAILS_BY_PATIENT_SUCCESS,
} from "./ActionTypes";
import { GetInsuranceDetalsByPatientValue } from "./Model";

const initialStateGetPosts: GetInsuranceDetalsByPatientDetails = {
  loading: false,
  getInsuranceDetailsRes: {} as GetInsuranceDetalsByPatientValue,
  errRes: "",
};
export const getInsuranceDetailsReducer = (
  state = initialStateGetPosts,
  action: getInsuranceDetailsByPatientActionTypes,
): GetInsuranceDetalsByPatientDetails => {
  switch (action.type) {
    case GET_INSURANCE_DETAILS_BY_PATIENT_SUCCESS:
      return {
        ...state,
        loading: false,
        getInsuranceDetailsRes: action.payload,
        errRes: "",
      };
    case GET_INSURANCE_DETAILS_BY_PATIENT_LOADING:
      return {
        ...state,
        loading: true,
        getInsuranceDetailsRes: {} as GetInsuranceDetalsByPatientValue,
      };
    case GET_INSURANCE_DETAILS_BY_PATIENT_FAIL:
      return {
        ...state,
        getInsuranceDetailsRes: {} as GetInsuranceDetalsByPatientValue,
        loading: false,
        errRes: action.errRes,
      };
    default:
      return state;
  }
};
