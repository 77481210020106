import { requestBookAppoiontmentValue } from "./Model";

export const REQUEST_BOOK_APPOINTMENT_LOADING = "REQUEST_BOOK_APPOINTMENT_LOADING";
export const REQUEST_BOOK_APPOINTMENT_SUCCESS = "REQUEST_BOOK_APPOINTMENT_SUCCESS";
export const REQUEST_BOOK_APPOINTMENT_FAIL = "REQUEST_BOOK_APPOINTMENT_FAIL";
export const REQUEST_BOOK_APPOINTMENT_LIST = "REQUEST_BOOK_APPOINTMENT_LIST";
export const REQUEST_BOOK_APPOINTMENT_UPDATE_LIST = "REQUEST_BOOK_APPOINTMENT_UPDATE_LIST";

export interface requestBookAppoiontmentDetails {
  bookAppoinmentRes: requestBookAppoiontmentValue;
  loading: boolean;
}
export interface SharePatientListLoading {
  type: typeof REQUEST_BOOK_APPOINTMENT_LOADING;
}
export interface SharePatientListSuccess {
  type: typeof REQUEST_BOOK_APPOINTMENT_SUCCESS;
  payload: requestBookAppoiontmentValue;
}
export interface SharePatientListFail {
  type: typeof REQUEST_BOOK_APPOINTMENT_FAIL;
}

export interface UpdateSharePatientList {
  type: typeof REQUEST_BOOK_APPOINTMENT_UPDATE_LIST;
  payload: requestBookAppoiontmentValue;
}
interface SharePatientListAction {
  type: typeof REQUEST_BOOK_APPOINTMENT_LIST;
  payload: requestBookAppoiontmentValue;
}

export type requestBookAppoiontmentActionTypes =
  | SharePatientListAction
  | SharePatientListLoading
  | SharePatientListSuccess
  | SharePatientListFail
  | UpdateSharePatientList;
