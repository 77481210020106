import { SleepQualityObservationTypeRes } from "./Model";
export const SLEEP_QUALITY_OBSERVATION_TYPE_LOADING = "SLEEP_QUALITY_OBSERVATION_TYPE_LOADING";
export const SLEEP_QUALITY_OBSERVATION_TYPE_SUCCESS = "SLEEP_QUALITY_OBSERVATION_TYPE_SUCCESS";
export const SLEEP_QUALITY_OBSERVATION_TYPE_FAIL = "SLEEP_QUALITY_OBSERVATION_TYPE_FAIL";
export const SLEEP_QUALITY_OBSERVATION_TYPE_UPDATE_API_MSG =
  "SLEEP_QUALITY_OBSERVATION_TYPE_UPDATE_API_MSG";
export const SLEEP_QUALITY_OBSERVATION_TYPE_UPDATE_API_RES =
  "SLEEP_QUALITY_OBSERVATION_TYPE_UPDATE_API_RES";

export interface SleepQualityObservationTypeState {
  sleepQualityObservationTypeRes: SleepQualityObservationTypeRes;
  loading: boolean;
  successMsg: string;
  errorMsg: string;
  status: number;
}
export interface SleepQualityObservationTypeLoading {
  type: typeof SLEEP_QUALITY_OBSERVATION_TYPE_LOADING;
  loading: boolean;
}
export interface SleepQualityObservationTypeSuccess {
  type: typeof SLEEP_QUALITY_OBSERVATION_TYPE_SUCCESS;
  payload: SleepQualityObservationTypeRes;
  successMsg: string;
}
export interface SleepQualityObservationTypeFail {
  type: typeof SLEEP_QUALITY_OBSERVATION_TYPE_FAIL;
  payload: SleepQualityObservationTypeRes;
  errorMsg: string;
  status: number;
}
export interface SleepQualityObservationTypeUpdateAPIMsg {
  type: typeof SLEEP_QUALITY_OBSERVATION_TYPE_UPDATE_API_MSG;
  payload: SleepQualityObservationTypeRes;
  successMsg: string;
  errorMsg: string;
  status: number;
}
export interface SleepQualityObservationTypeUpdateAPIRes {
  type: typeof SLEEP_QUALITY_OBSERVATION_TYPE_UPDATE_API_RES;
  payload: SleepQualityObservationTypeRes;
  successMsg: string;
  errorMsg: string;
  status: number;
}

export type SleepQualityObservationTypeActionTypes =
  | SleepQualityObservationTypeLoading
  | SleepQualityObservationTypeSuccess
  | SleepQualityObservationTypeFail
  | SleepQualityObservationTypeUpdateAPIMsg
  | SleepQualityObservationTypeUpdateAPIRes;
