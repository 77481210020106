export const APP_LOADER_LOADING = "APP_LOADER_LOADING";

export interface AppLoaderState {
  loading: boolean;
}
export interface AppLoaderLoading {
  type: typeof APP_LOADER_LOADING;
  loading: boolean;
}

export type AppLoaderActionTypes = AppLoaderLoading;
