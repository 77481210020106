import {
  PatientDemographicsActionTypes,
  PATIENT_DEMOGRAPHICS_FAIL,
  PATIENT_DEMOGRAPHICS_LOADING,
  PATIENT_DEMOGRAPHICS_SUCCESS,
  GetPatientDemographicsState,
} from "./ActionTypes";
import { PatientDemographicsDetails } from "./Model";

const initialStateGetPosts: GetPatientDemographicsState = {
  loading: false,
  patientDemographicsRes: {} as PatientDemographicsDetails,
};
export const getPatientDemographicsDetailsReducer = (
  state = initialStateGetPosts,
  action: PatientDemographicsActionTypes,
): GetPatientDemographicsState => {
  switch (action.type) {
    case PATIENT_DEMOGRAPHICS_SUCCESS:
      return {
        ...state,
        loading: false,
        patientDemographicsRes: action.payload,
      };
    case PATIENT_DEMOGRAPHICS_LOADING:
      return {
        ...state,
        loading: true,
        patientDemographicsRes: {} as PatientDemographicsDetails,
      };
    case PATIENT_DEMOGRAPHICS_FAIL:
      return {
        ...state,
        patientDemographicsRes: {} as PatientDemographicsDetails,
        loading: false,
      };
    default:
      return state;
  }
};
