import * as React from "react";
import {
  Box,
  Button,
  Card,
  CardActions,
  CardContent,
  CardHeader,
  Grid,
  Typography,
} from "@mui/material";
import ArrowRightAltIcon from "@mui/icons-material/ArrowRightAlt";
import { Link, useHistory, useParams } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { AppState } from "../../../redux/store/Store";
import MWResourceList from "../../../components/MWResourceList";
import MWExceptionList from "../../../components/MWExceptionList";
import { getPatientCaseList } from "../../../redux/effects/ApiCall";

export default function CaseList() {
  const history = useHistory();
  const dispatch = useDispatch();
  const { patientid } = useParams() as {
    patientid: string;
  };
  const gotoPatientCaseList = () => {
    history.push(`/patientcaselist/${patientid}`);
  };
  React.useEffect(() => {
    dispatch(getPatientCaseList(patientid));
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const caseHistory = useSelector((state: AppState) => state.caseValue);
  const pagebody = (
    <Box>
      {caseHistory?.caseValue !== undefined ? (
        caseHistory?.caseValue.length > 0 ? (
          caseHistory?.caseValue.map((element: any) => (
            <Box key={element.id} style={{ textDecoration: "none", textTransform: "none" }}>
              <Link
                to={`/casedetails/${element.patientId}/${element.caseId}`}
                style={{ textDecoration: "none" }}
              >
                <MWResourceList
                  icon={true}
                  title={element?.whyWant2ndOpinion}
                  showSubtitle={true}
                  subtitle={element?.secondOpinionReasons}
                  showReason={true}
                  reason={element?.consultationType}
                  showNavigation={true}
                />
              </Link>
            </Box>
          ))
        ) : (
          <MWExceptionList />
        )
      ) : (
        <MWExceptionList />
      )}
    </Box>
  );
  return (
    <Card style={{ borderRadius: 20, height: "100%" }}>
      <CardHeader
        title={
          <Grid container direction="row" justifyContent="flex-start" alignItems="flex-start">
            <Grid item>
              <Typography variant="h6" sx={{ fontWeight: "bold" }}>
                Case History List
              </Typography>
            </Grid>
          </Grid>
        }
      />
      <CardContent>
        <Box sx={{ height: "120px", overflow: "auto" }}>{pagebody}</Box>
      </CardContent>
      <CardActions>
        <Grid container direction="row" justifyContent="flex-end" alignItems="flex-end" spacing={1}>
          <Grid item>
            <Button
              variant="text"
              endIcon={<ArrowRightAltIcon />}
              sx={{ textTransform: "none" }}
              onClick={() => gotoPatientCaseList()}
            >
              View All
            </Button>
          </Grid>
        </Grid>
      </CardActions>
    </Card>
  );
}
