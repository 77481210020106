import axios from "axios";
import { Dispatch } from "redux";
import Url from "../../../Url";
import { setupInterceptorsTo } from "../../Authentication/Interceptors";
import {
  doctorlistActionTypes,
  DOCTOR_LIST_FAIL,
  DOCTOR_LIST_LOADING,
  DOCTOR_LIST_SUCCESS,
} from "./ActionTypes";
import { DoctorlistResponse } from "./Model";
import { headers } from "components/Utility";

export const doctorListApi = (payload: any) => {
  return function (dispatch: Dispatch<doctorlistActionTypes>) {
    dispatch({
      type: DOCTOR_LIST_LOADING,
    });

    setupInterceptorsTo(axios)
      .post(Url.apiURL + `/patientapp/api/search-doctor/`, payload, { headers: headers })
      .then((res) => {
        dispatch({
          type: DOCTOR_LIST_SUCCESS,
          payload: res.data,
          errRes: "",
        });
      })
      .catch((error) => {
        dispatch({
          type: DOCTOR_LIST_FAIL,
          payload: {} as DoctorlistResponse,
          errRes: error.response.data.message,
        });
      });
  };
};
