import {
  AddResponse,
  CaseHistory,
  CaseList,
  ChiefComplaintList,
  DoctorList,
  PatientList,
  QuestionList,
  SetChiefComplaint,
  SetDeleteDocument,
  SetDeletePatient,
  SharedPatientList,
  TaskList,
  OwnerAppoinmentList,
  CaseAppoinmentList,
  AdminOrganizationList,
  OrganizationPatientList,
} from "../interfaces/Model";

// API call state
export const GET_LIST = "GET_LIST";
export const GET_PATIENT_LIST = "GET_PATIENT_LIST";
export const GET_SHARED_PATIENT_LIST = "GET_SHARED_PATIENT_LIST";
export const GET_CASE_LIST = "GET_CASE_LIST";
export const GET_QUESTION_LIST = "GET_QUESTION_LIST";
export const GET_CC_LIST = "GET_CC_LIST";
export const ADD_CC = "ADD_CC";
export const DELETE_DOCUMENT = "DELETE_DOCUMENT";
export const DELETE_PATIENT = "DELETE_PATIENT";

// Doctor Loading State
export const DOCTOR_LIST_LOADING = "DOCTOR_LIST_LOADING";
export const DOCTOR_LIST_SUCCESS = "DOCTOR_LIST_SUCCESS";
export const DOCTOR_LIST_FAIL = "DOCTOR_LIST_FAIL";

// Task List types
export const GET_TASK_LIST = "GET_TASK_LIST";
export const TASK_LIST_LOADING = "TASK_LIST_LOADING";
export const TASK_LIST_SUCCESS = "TASK_LIST_SUCCESS";
export const TASK_LIST_FAIL = "TASK_LIST_FAIL";

// Owner Appoinment List types
export const OWNER_APPOINMENT_LIST = "OWNER_APPOINMENT_LIST";
export const OWNER_APPOINMENT_LIST_LOADING = "OWNER_APPOINMENT_LIST_LOADING";
export const OWNER_APPOINMENT_LIST_SUCCESS = "OWNER_APPOINMENT_LIST_SUCCESS";
export const OWNER_APPOINMENT_LIST_FAIL = "OWNER_APPOINMENT_LIST_FAIL";

// PATIENT CASE Appoinment List types
export const CASE_APPOINMENT_LIST = "CASE_APPOINMENT_LIST";
export const CASE_APPOINMENT_LIST_LOADING = "CASE_APPOINMENT_LIST_LOADING";
export const CASE_APPOINMENT_LIST_SUCCESS = "CASE_APPOINMENT_LIST_SUCCESS";
export const CASE_APPOINMENT_LIST_FAIL = "CASE_APPOINMENT_LIST_FAIL";

// Organization List types
export const GET_ORGANIZATION_LIST = "GET_ORGANIZATION_LIST";
export const ORGANIZATION_LIST_LOADING = "ORGANIZATION_LIST_LOADING";
export const ORGANIZATION_LIST_SUCCESS = "ORGANIZATION_LIST_SUCCESS";
export const ORGANIZATION_LIST_FAIL = "ORGANIZATION_LIST_FAIL";

export interface GetDoctorState {
  doctorValue: DoctorList[];
  loading: boolean;
}
export interface DoctorListLoading {
  type: typeof DOCTOR_LIST_LOADING;
}
export interface DoctorListSuccess {
  type: typeof DOCTOR_LIST_SUCCESS;
  payload: DoctorList[];
}
export interface DoctorListFail {
  type: typeof DOCTOR_LIST_FAIL;
}
interface GetDoctorAction {
  type: typeof GET_LIST;
  payload: DoctorList[];
}

// Patient Loading State
export const PATIENT_LIST_LOADING = "PATIENT_LIST_LOADING";
export const PATIENT_LIST_SUCCESS = "PATIENT_LIST_SUCCESS";
export const PATIENT_LIST_FAIL = "PATIENT_LIST_FAIL";

export interface GetPatientState {
  patientValue: PatientList[];
  loading: boolean;
}
export interface PatientListLoading {
  type: typeof PATIENT_LIST_LOADING;
}
export interface PatientListSuccess {
  type: typeof PATIENT_LIST_SUCCESS;
  payload: PatientList[];
}
export interface PatientListFail {
  type: typeof PATIENT_LIST_FAIL;
}
interface GetPatientAction {
  type: typeof GET_PATIENT_LIST;
  payload: PatientList[];
}

// Patient Loading State
export const SHARED_PATIENT_LIST_LOADING = "SHARED_PATIENT_LIST_LOADING";
export const SHARED_PATIENT_LIST_SUCCESS = "SHARED_PATIENT_LIST_SUCCESS";
export const SHARED_PATIENT_LIST_FAIL = "SHARED_PATIENT_LIST_FAIL";

export interface GetSharedPatientState {
  sharedPatientValue: SharedPatientList[];
  loading: boolean;
}
export interface SharedPatientListLoading {
  type: typeof SHARED_PATIENT_LIST_LOADING;
}
export interface SharedPatientListSuccess {
  type: typeof SHARED_PATIENT_LIST_SUCCESS;
  payload: SharedPatientList[];
}
export interface SharedPatientListFail {
  type: typeof SHARED_PATIENT_LIST_FAIL;
}
interface GetSharedPatientAction {
  type: typeof GET_SHARED_PATIENT_LIST;
  payload: SharedPatientList[];
}

// Case Loading State
export const CASE_LIST_LOADING = "CASE_LIST_LOADING";
export const CASE_LIST_SUCCESS = "CASE_LIST_SUCCESS";
export const CASE_LIST_FAIL = "CASE_LIST_FAIL";

export interface GetCaseStateType {
  caseValue: CaseList[];
  loading: boolean;
  patientname: CaseHistory;
}
export interface CaseListLoading {
  type: typeof CASE_LIST_LOADING;
}
export interface CaseListSuccess {
  type: typeof CASE_LIST_SUCCESS;
  payload: CaseList[];
  patientname: CaseHistory;
}
export interface CaseListFail {
  type: typeof CASE_LIST_FAIL;
}

interface GetCaseActionType {
  type: typeof GET_CASE_LIST;
  payload: CaseList[];
  patientname: CaseHistory;
}

// Question Loading State
export const QUESTION_LIST_LOADING = "QUESTION_LIST_LOADING";
export const QUESTION_LIST_SUCCESS = "QUESTION_LIST_SUCCESS";
export const QUESTION_LIST_FAIL = "QUESTION_LIST_FAIL";

export interface GetQuestionStateType {
  questionValue: QuestionList[];
  loading: boolean;
}
export interface QuestionListLoading {
  type: typeof QUESTION_LIST_LOADING;
}
export interface QuestionListSuccess {
  type: typeof QUESTION_LIST_SUCCESS;
  payload: QuestionList[];
}
export interface QuestionListFail {
  type: typeof QUESTION_LIST_FAIL;
}

interface GetQuestionActionType {
  type: typeof GET_QUESTION_LIST;
  payload: QuestionList[];
}

// Chief Complaint Loading State
export const CC_LIST_LOADING = "CC_LIST_LOADING";
export const CC_LIST_SUCCESS = "CC_LIST_SUCCESS";
export const CC_LIST_FAIL = "CC_LIST_FAIL";

export interface GetChiefComplaintStateType {
  ccValue: ChiefComplaintList[];
  loading: boolean;
}
export interface CCListLoading {
  type: typeof CC_LIST_LOADING;
}
export interface CCListSuccess {
  type: typeof CC_LIST_SUCCESS;
  payload: ChiefComplaintList[];
}
export interface CCListFail {
  type: typeof CC_LIST_FAIL;
}

interface GetCCActionType {
  type: typeof GET_CC_LIST;
  payload: ChiefComplaintList[];
}

// ADD CHIEF COMPLAINT
export const CC_ADD_LIST_LOADING = "CC_ADD_LIST_LOADING";
export const CC_ADD_LIST_SUCCESS = "CC_ADD_LIST_SUCCESS";
export const CC_ADD_LIST_FAIL = "CC_ADD_LIST_FAIL";

export interface SetAddChiefComplaintStateType {
  addCC: SetChiefComplaint[];
  loading: boolean;
  response: AddResponse;
}
export interface CCAddLoading {
  type: typeof CC_ADD_LIST_LOADING;
}
export interface CCAddSuccess {
  type: typeof CC_ADD_LIST_SUCCESS;
  payload: AddResponse;
}
export interface CCAddFail {
  type: typeof CC_ADD_LIST_FAIL;
  payload: AddResponse;
}

interface SetAddChiefComplaintType {
  type: typeof ADD_CC;
  payload: SetChiefComplaint[];
}

// DELETE Document
export const DELETE_DOCUMENT_LIST_LOADING = "DELETE_DOCUMENT_LIST_LOADING";
export const DELETE_DOCUMENT_LIST_SUCCESS = "DELETE_DOCUMENT_LIST_SUCCESS";
export const DELETE_DOCUMENT_LIST_FAIL = "DELETE_DOCUMENT_LIST_FAIL";

export interface SetDeleteDocumentStateType {
  deleteDoc: SetDeleteDocument[];
  loading: boolean;
  response: AddResponse;
}
export interface DeleteDocumentLoading {
  type: typeof DELETE_DOCUMENT_LIST_LOADING;
}
export interface DeleteDocumentSuccess {
  type: typeof DELETE_DOCUMENT_LIST_SUCCESS;
  payload: AddResponse;
}
export interface DeleteDocumentFail {
  type: typeof DELETE_DOCUMENT_LIST_FAIL;
  payload: AddResponse;
}

interface SetDeleteDocumentType {
  type: typeof DELETE_DOCUMENT;
  payload: SetDeleteDocument[];
}

// DELETE Patient
export const DELETE_PATIENT_LIST_LOADING = "DELETE_PATIENT_LIST_LOADING";
export const DELETE_PATIENT_LIST_SUCCESS = "DELETE_PATIENT_LIST_SUCCESS";
export const DELETE_PATIENT_LIST_FAIL = "DELETE_PATIENT_LIST_FAIL";

export interface SetDeletePatientStateType {
  deletePatient: SetDeletePatient[];
  loading: boolean;
  response: AddResponse;
}
export interface DeletePatientLoading {
  type: typeof DELETE_PATIENT_LIST_LOADING;
}
export interface DeletePatientSuccess {
  type: typeof DELETE_PATIENT_LIST_SUCCESS;
  payload: AddResponse;
}
export interface DeletePatientFail {
  type: typeof DELETE_PATIENT_LIST_FAIL;
}

interface SetDeletePatientType {
  type: typeof DELETE_PATIENT;
  payload: SetDeletePatient[];
}
// Task List Interfaces
export interface TaskLoading {
  type: typeof TASK_LIST_LOADING;
}
export interface TaskSuccess {
  type: typeof TASK_LIST_SUCCESS;
  payload: TaskList[];
}
export interface TaskFail {
  type: typeof TASK_LIST_FAIL;
}

export interface GetTaskStateType {
  taskValue: TaskList[];
  loading: boolean;
}
// Task Loading State
interface GetTaskActionType {
  type: typeof GET_TASK_LIST;
  payload: TaskList[];
}

// Owner Appoinment List Interfaces
export interface OwnerAppoinmentListLoading {
  type: typeof OWNER_APPOINMENT_LIST_LOADING;
}
export interface OwnerAppoinmentListSuccess {
  type: typeof OWNER_APPOINMENT_LIST_SUCCESS;
  payload: OwnerAppoinmentList[];
}
export interface OwnerAppoinmentListFail {
  type: typeof OWNER_APPOINMENT_LIST_FAIL;
}

export interface OwnerAppoinmentStateType {
  OwnerAppoinmentListValue: OwnerAppoinmentList[];
  loading: boolean;
}
// Owner Appoinment List Loading State
interface OwnerAppoinmentActionType {
  type: typeof OWNER_APPOINMENT_LIST;
  payload: OwnerAppoinmentList[];
}

// patient case Appoinment List Interfaces
export interface CaseAppoinmentListLoading {
  type: typeof CASE_APPOINMENT_LIST_LOADING;
}
export interface CaseAppoinmentListSuccess {
  type: typeof CASE_APPOINMENT_LIST_SUCCESS;
  payload: CaseAppoinmentList[];
}
export interface CaseAppoinmentListFail {
  type: typeof CASE_APPOINMENT_LIST_FAIL;
}

export interface CaseAppoinmentStateType {
  CaseAppoinmentListValue: CaseAppoinmentList[];
  loading: boolean;
}
// Owner Appoinment List Loading State
interface CaseAppoinmentActionType {
  type: typeof CASE_APPOINMENT_LIST;
  payload: CaseAppoinmentList[];
}

export interface GetOrganizationListStateType {
  organizationListValue: AdminOrganizationList[];
  loading: boolean;
}

interface AdminGetOrganizationListActionType {
  type: typeof GET_ORGANIZATION_LIST;
  payload: AdminOrganizationList[];
}

export interface OrganizationListLoading {
  type: typeof ORGANIZATION_LIST_LOADING;
}
export interface OrganizationListSuccess {
  type: typeof ORGANIZATION_LIST_SUCCESS;
  payload: AdminOrganizationList[];
}
export interface OrganizationListFail {
  type: typeof ORGANIZATION_LIST_FAIL;
}

// Organization Patient Loading State
export const GET_ORGANIZATIONPATIENT_LIST = "GET_ORGANIZATIONPATIENT_LIST";
export const ORGPATIENT_LIST_LOADING = "ORGPATIENT_LIST_LOADING";
export const ORGPATIENT_LIST_SUCCESS = "ORGPATIENT_LIST_SUCCESS";
export const ORGPATIENT_LIST_FAIL = "ORGPATIENT_LIST_FAIL";

export interface GetOrganizationPatientStateType {
  organizationPatientValue: OrganizationPatientList;
  loading: boolean;
}
export interface OrganizationPatientListLoading {
  type: typeof ORGPATIENT_LIST_LOADING;
}
export interface OrganizationPatientListSuccess {
  type: typeof ORGPATIENT_LIST_SUCCESS;
  payload: OrganizationPatientList;
}
export interface OrganizationPatientListFail {
  type: typeof ORGPATIENT_LIST_FAIL;
}

interface GetOrganizationPatientActionType {
  type: typeof GET_ORGANIZATIONPATIENT_LIST;
  payload: OrganizationPatientList;
}

export type DoctorActionTypes =
  | GetDoctorAction
  | DoctorListLoading
  | DoctorListSuccess
  | DoctorListFail;
export type PatientActionTypes =
  | GetPatientAction
  | PatientListLoading
  | PatientListSuccess
  | PatientListFail;
export type SharedPatientActionTypes =
  | GetSharedPatientAction
  | SharedPatientListLoading
  | SharedPatientListSuccess
  | SharedPatientListFail;
export type CaseGetActionTypes =
  | GetCaseActionType
  | CaseListLoading
  | CaseListSuccess
  | CaseListFail;
export type QuestionGetActionTypes =
  | GetQuestionActionType
  | QuestionListLoading
  | QuestionListSuccess
  | QuestionListFail;
export type ChiefComplaintGetActionTypes =
  | GetCCActionType
  | CCListLoading
  | CCListSuccess
  | CCListFail;
export type AddChiefComplaintTypes =
  | SetAddChiefComplaintType
  | CCAddLoading
  | CCAddSuccess
  | CCAddFail
  | GetCCActionType
  | CCListLoading
  | CCListSuccess
  | CCListFail;
export type DeleteDocumentTypes =
  | SetDeleteDocumentType
  | DeleteDocumentLoading
  | DeleteDocumentSuccess
  | DeleteDocumentFail
  | GetCCActionType
  | CCListLoading
  | CCListSuccess
  | CCListFail;
export type DeletePatientTypes =
  | SetDeletePatientType
  | DeletePatientLoading
  | DeletePatientSuccess
  | DeletePatientFail
  | GetPatientAction
  | PatientListLoading
  | PatientListSuccess
  | PatientListFail;
export type TaskGetActionTypes = GetTaskActionType | TaskLoading | TaskSuccess | TaskFail;
export type OwnerAppoinmentListActionTypes =
  | OwnerAppoinmentActionType
  | OwnerAppoinmentListLoading
  | OwnerAppoinmentListSuccess
  | OwnerAppoinmentListFail;
export type CaseAppoinmentListActionTypes =
  | CaseAppoinmentActionType
  | CaseAppoinmentListLoading
  | CaseAppoinmentListSuccess
  | CaseAppoinmentListFail;
export type AdminGetOrganizationListActionTypes =
  | AdminGetOrganizationListActionType
  | OrganizationListLoading
  | OrganizationListSuccess
  | OrganizationListFail;
export type OrganizationPatientGetActionTypes =
  | GetOrganizationPatientActionType
  | OrganizationPatientListLoading
  | OrganizationPatientListSuccess
  | OrganizationPatientListFail;
