import {
  ReviewNeededActionTypes,
  REVIEW_NEEDED_FAIL,
  REVIEW_NEEDED_LOADING,
  REVIEW_NEEDED_SUCCESS,
  PaymentStatusState,
  REVIEW_NEEDED_UPDATE_API_MSG,
  REVIEW_NEEDED_UPDATE_API_RES,
} from "./ActionTypes";
import { NeededReviewResponse } from "./Model";

const initialStateGetPosts: PaymentStatusState = {
  loading: false,
  reviewNeededRes: {} as NeededReviewResponse,
  successMsg: "",
  errorMsg: "",
  status: 0,
};
export const reviewNeededReducer = (
  state = initialStateGetPosts,
  action: ReviewNeededActionTypes,
): PaymentStatusState => {
  switch (action.type) {
    case REVIEW_NEEDED_LOADING:
      return {
        ...state,
        loading: true,
        reviewNeededRes: {} as NeededReviewResponse,
      };
    case REVIEW_NEEDED_SUCCESS:
      return {
        ...state,
        loading: false,
        reviewNeededRes: action.payload,
        successMsg: action.successMsg,
        status: action.status,
      };
    case REVIEW_NEEDED_FAIL:
      return {
        ...state,
        loading: false,
        reviewNeededRes: {} as NeededReviewResponse,
        errorMsg: action.errorMsg,
        status: action.status,
      };
    case REVIEW_NEEDED_UPDATE_API_MSG:
      return {
        ...state,
        loading: false,
        reviewNeededRes: action.payload,
        errorMsg: action.errorMsg,
        status: action.status,
      };
    case REVIEW_NEEDED_UPDATE_API_RES:
      return {
        ...state,
        loading: false,
        reviewNeededRes: {} as NeededReviewResponse,
        errorMsg: action.errorMsg,
        status: action.status,
      };
    default:
      return state;
  }
};
