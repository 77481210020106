import * as React from "react";
import {
  Button,
  Card,
  CardActions,
  CardContent,
  CardHeader,
  Grid,
  Typography,
} from "@mui/material";
import ArrowRightAltIcon from "@mui/icons-material/ArrowRightAlt";
import medicineImg from "../../../image/design-assets/medicine.svg";
import { Avatar } from "@mui/material";

export default function MedicationSummary() {
  return (
    <Card style={{ borderRadius: 20, height: "100%" }}>
      <CardHeader
        title={
          <Grid
            container
            direction="row"
            justifyContent="flex-start"
            alignItems="flex-start"
          >
            <Grid item>
              <Typography variant="subtitle1" style={{ fontWeight: "bold" }}>
                Medication Summary
              </Typography>
            </Grid>
          </Grid>
        }
      />
      <CardContent>
        <Grid
          container
          direction="row"
          justifyContent="flex-start"
          alignItems="stretch"
          spacing={6}
        >
          <Grid item>
            <Avatar>
              <img
                alt="medicine Life"
                src={medicineImg}
                style={{
                  width: 60,
                  height: 130,
                }}
              />
            </Avatar>
          </Grid>
          <Grid item>
            <Typography variant="body1" style={{ fontWeight: "bold" }}>
              As Per Patient Input <br /> (Prescription Shared BY Patient)
            </Typography>
          </Grid>
        </Grid>
      </CardContent>
      <CardActions>
        <Grid
          container
          direction="row"
          justifyContent="flex-end"
          alignItems="flex-start"
          spacing={1}
        >
          <Grid item>
            <Grid
              container
              direction="row"
              justifyContent="center"
              alignItems="center"
              spacing={1}
            >
              <Grid item>
                <Button
                  variant="text"
                  endIcon={<ArrowRightAltIcon />}
                  sx={{ textTransform: "none" }}
                >
                  View All
                </Button>
              </Grid>
            </Grid>
          </Grid>
        </Grid>
      </CardActions>
    </Card>
  );
}
