import axios from "axios";
import { Dispatch } from "redux";
import Url from "../../../../Url";
import { EditDoctorTeamTypeListActionTypes } from "./ActionTypes";
import { EditDoctorTeamTypeListBody, EditDoctorTeamTypeListRes } from "./Model";
import {
  editDoctorTeamTypeAPIResClearAction,
  editDoctorTeamTypeErrorAction,
  editDoctorTeamTypeLoadingAction,
  editDoctorTeamTypeSuccessAction,
  editDoctorTeamTypeUpdateAPIMsgAction,
} from "./Actions";
import { setupInterceptorsTo } from "../../../Authentication/Interceptors";
import { headers } from "components/Utility";

let apiRes = {} as EditDoctorTeamTypeListRes;

export const editDoctorTeamType = (payload: EditDoctorTeamTypeListBody) => {
  return function (dispatch: Dispatch<EditDoctorTeamTypeListActionTypes>) {
    dispatch(editDoctorTeamTypeLoadingAction(true));
    setupInterceptorsTo(axios)
      .post(Url.apiURL + `/patientapp/api/patient/edit_patient_case`, payload, { headers: headers })
      .then((res) => {
        apiRes = res.data;
        dispatch(editDoctorTeamTypeLoadingAction(false));
        dispatch(
          editDoctorTeamTypeSuccessAction(
            res.data !== undefined ? res.data : ({} as EditDoctorTeamTypeListRes),
            res.data.message !== undefined
              ? res.data.message
              : "Doctor team type list has been fetched successfully",
          ),
        );
      })
      .catch((error) => {
        dispatch(editDoctorTeamTypeLoadingAction(false));
        dispatch(
          editDoctorTeamTypeErrorAction(
            {} as EditDoctorTeamTypeListRes,
            error.response !== undefined
              ? error.response.data.message || error.response.data.detail
              : "Something went wrong",
            error.status !== undefined ? error.response.data.status || 0 : 0,
          ),
        );
      });
  };
};

export const updateEditDoctorTeamTypeAPIResMsg = () => {
  return function (dispatch: Dispatch<EditDoctorTeamTypeListActionTypes>) {
    dispatch(editDoctorTeamTypeUpdateAPIMsgAction(apiRes as EditDoctorTeamTypeListRes, "", "", 0));
  };
};

export const clearEditDoctorTeamTypeListAPIRes = () => {
  return function (dispatch: Dispatch<EditDoctorTeamTypeListActionTypes>) {
    dispatch(editDoctorTeamTypeAPIResClearAction({} as EditDoctorTeamTypeListRes, "", "", 0));
  };
};
