import {
  AddDemographicsDetailsActionTypes,
  AddPatientDemographicsDetailsState,
  ADD_PATIENT_DEMOGRAPHICS_LOADING,
  ADD_PATIENT_DEMOGRAPHICS_SUCCESS,
  ADD_PATIENT_DEMOGRAPHICS_FAIL,
  ADD_PATIENT_DEMOGRAPHICS_UPDATE_API_MSG,
  ADD_PATIENT_DEMOGRAPHICS_UPDATE_API_RES,
} from "./ActionTypes";
import { AddDemographicsDetailsRes } from "./Model";

const initialStateGetPosts: AddPatientDemographicsDetailsState = {
  loading: false,
  addPatientDemographicsRes: {} as AddDemographicsDetailsRes,
  successMsg: "",
  errorMsg: "",
  status: 0,
};
export const addDemographicsReducer = (
  state = initialStateGetPosts,
  action: AddDemographicsDetailsActionTypes,
): AddPatientDemographicsDetailsState => {
  switch (action.type) {
    case ADD_PATIENT_DEMOGRAPHICS_LOADING:
      return {
        ...state,
        loading: action.loading,
      };
    case ADD_PATIENT_DEMOGRAPHICS_SUCCESS:
      return {
        ...state,
        addPatientDemographicsRes: action.payload,
        successMsg: action.successMsg,
      };
    case ADD_PATIENT_DEMOGRAPHICS_FAIL:
      return {
        ...state,
        addPatientDemographicsRes: action.payload,
        errorMsg: action.errorMsg,
        status: action.status,
      };
    case ADD_PATIENT_DEMOGRAPHICS_UPDATE_API_MSG:
      return {
        ...state,
        addPatientDemographicsRes: action.payload,
        successMsg: action.successMsg,
        errorMsg: action.errorMsg,
        status: action.status,
      };
    case ADD_PATIENT_DEMOGRAPHICS_UPDATE_API_RES:
      return {
        ...state,
        addPatientDemographicsRes: action.payload,
        successMsg: action.successMsg,
        errorMsg: action.errorMsg,
        status: action.status,
      };
    default:
      return state;
  }
};
