import {
  SleepScheduleObservationTypeActionTypes,
  SLEEP_SCHEDULE_OBSERVATION_TYPE_FAIL,
  SLEEP_SCHEDULE_OBSERVATION_TYPE_LOADING,
  SLEEP_SCHEDULE_OBSERVATION_TYPE_SUCCESS,
  SleepScheduleObservationTypeState,
  SLEEP_SCHEDULE_OBSERVATION_TYPE_UPDATE_API_MSG,
  SLEEP_SCHEDULE_OBSERVATION_TYPE_UPDATE_API_RES,
} from "./ActionTypes";
import { SleepScheduleObservationTypeRes } from "./Model";

const initialStateGetPosts: SleepScheduleObservationTypeState = {
  loading: false,
  sleepScheduleObservationTypeRes: {} as SleepScheduleObservationTypeRes,
  successMsg: "",
  errorMsg: "",
  status: 0,
};
export const sleepScheduleObservationTypeReducer = (
  state = initialStateGetPosts,
  action: SleepScheduleObservationTypeActionTypes,
): SleepScheduleObservationTypeState => {
  switch (action.type) {
    case SLEEP_SCHEDULE_OBSERVATION_TYPE_LOADING:
      return {
        ...state,
        loading: action.loading,
      };
    case SLEEP_SCHEDULE_OBSERVATION_TYPE_SUCCESS:
      return {
        ...state,
        sleepScheduleObservationTypeRes: action.payload,
        successMsg: action.successMsg,
      };
    case SLEEP_SCHEDULE_OBSERVATION_TYPE_FAIL:
      return {
        ...state,
        sleepScheduleObservationTypeRes: action.payload,
        errorMsg: action.errorMsg,
        status: action.status,
      };
    case SLEEP_SCHEDULE_OBSERVATION_TYPE_UPDATE_API_MSG:
      return {
        ...state,
        sleepScheduleObservationTypeRes: action.payload,
        successMsg: action.successMsg,
        errorMsg: action.errorMsg,
        status: action.status,
      };
    case SLEEP_SCHEDULE_OBSERVATION_TYPE_UPDATE_API_RES:
      return {
        ...state,
        sleepScheduleObservationTypeRes: action.payload,
        successMsg: action.successMsg,
        errorMsg: action.errorMsg,
        status: action.status,
      };
    default:
      return state;
  }
};
