import {
  GetDoctorDetailsListDetails,
  getDoctorDetailsActionTypes,
  GET_DOCTOR_DETAILS_SUCCESS,
  GET_DOCTOR_DETAILS_LOADING,
  GET_DOCTOR_DETAILS_FAIL,
} from "./ActionTypes";
import { GetDoctorDetailsValue } from "./Model";

const initialStateGetPosts: GetDoctorDetailsListDetails = {
  loading: false,
  getDoctorDetailsRes: {} as GetDoctorDetailsValue,
  errRes: "",
};
export const getDoctorDetailsReducer = (
  state = initialStateGetPosts,
  action: getDoctorDetailsActionTypes,
): GetDoctorDetailsListDetails => {
  switch (action.type) {
    case GET_DOCTOR_DETAILS_SUCCESS:
      return {
        ...state,
        loading: false,
        getDoctorDetailsRes: action.payload,
        errRes: "",
      };
    case GET_DOCTOR_DETAILS_LOADING:
      return {
        ...state,
        loading: true,
        getDoctorDetailsRes: {} as GetDoctorDetailsValue,
      };
    case GET_DOCTOR_DETAILS_FAIL:
      return {
        ...state,
        getDoctorDetailsRes: {} as GetDoctorDetailsValue,
        loading: false,
        errRes: "",
      };
    default:
      return state;
  }
};
