import React, { useState, useEffect } from "react";
import { Box, Typography, Autocomplete, Alert, AlertTitle, Grid, Stack } from "@mui/material";
import { useParams } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { t } from "i18next";
import MWLoader from "../components/MWLoader";
import { AppState } from "../redux/store/Store";
import MWTextField from "../components/MWTextField";
import { patientDropdownListApi } from "../redux/patientListDropdown/API";
import { toggleAppBarLoading } from "redux/appLoader/Actions";
export default function AppointmentSelectPatient(_props: any) {
  const { patientid } = useParams() as {
    patientid: string;
  };
  const dispatch = useDispatch();
  const [isLoading] = useState(false);
  const loadingMarkup = isLoading ? <MWLoader /> : null;
  const [title, setTitle] = useState(`${t("appointment.patAppo", { ns: ["home"] })!}`);
  const [alertbody, setalertbody] = useState(`${t("appointment.selBooking", { ns: ["home"] })!}`);

  const [selectedPatientName, setSelectedPatientName] = useState(0);
  const filterCaseStatusId = (dropdownPatientName: any, _e: any) => {
    setSelectedPatientName(dropdownPatientName.value);
  };

  const closeAlert = () => {
    setalertbody("");
    setTitle("");
  };

  const alertmarkup = alertbody ? (
    <Alert
      variant="outlined"
      severity="info"
      onClose={() => {
        closeAlert();
      }}
    >
      <AlertTitle>
        <strong>{title}</strong>
      </AlertTitle>
      {alertbody}
    </Alert>
  ) : (
    ""
  );

  const getPatientList = () => {
    dispatch(patientDropdownListApi());
  };

  useEffect(() => {
    // Do api call here....
    window.scrollTo(0, 0);
    getPatientList();
    if (patientid !== null || patientid !== undefined) {
      setSelectedPatientName(Number(patientid));
    } else {
      setSelectedPatientName(0);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);
  const getPatientDropdownList = useSelector((state: AppState) => state.getPatientDropdownList);
  useEffect(() => {
    dispatch(toggleAppBarLoading(getPatientDropdownList?.loading));
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [getPatientDropdownList]);
  const patientName = getPatientDropdownList.patientDropdownListRes.patientList;

  const symptomsAddInfo = (
    <Stack direction="column" justifyContent="flex-start" alignItems="stretch" spacing={4}>
      <Box>
        <Grid item>{alertmarkup}</Grid>
      </Box>
      <Grid container direction="row" justifyContent="space-between" alignItems="flex-start">
        <Grid item>
          <Stack spacing={2}>
            <Typography style={{ fontWeight: "bold" }}>
              {t("addpatientpage.selpatient", { ns: ["home"] })!}
            </Typography>
            <Typography style={{ color: "#3F5CCF" }}>
              {t("appointment.selPatappo", { ns: ["home"] })!}
            </Typography>
          </Stack>
        </Grid>
        <Grid item>
          <Box>
            <Typography style={{ marginBottom: "5px" }}>
              {t("appointment.patName", { ns: ["home"] })}
            </Typography>
            <Autocomplete
              value={
                patientName !== undefined
                  ? patientName.find((element) => element.value === selectedPatientName) || null
                  : null
              }
              id="controllable-states-demo"
              options={patientName}
              onChange={(event: any, newValue: any) => {
                filterCaseStatusId(newValue, event);
              }}
              sx={{ width: 600 }}
              renderInput={(params) => (
                <MWTextField
                  {...params}
                  placeholder={t("addpatientpage.selpatient", { ns: ["home"] })!}
                />
              )}
            />
          </Box>
        </Grid>
      </Grid>
    </Stack>
  );
  return (
    <Box>
      {loadingMarkup}
      {symptomsAddInfo}
    </Box>
  );
}
