import {
  AddDrNoteStateType,
  AddDrNotesTypes,
  AddNotesForDrActionTypes,
  AddNotesForDrStateType,
  ADD_DR_NOTE_FAIL,
  ADD_DR_NOTE_LOADING,
  ADD_DR_NOTE_SUCCESS,
  ADD_NOTES_FOR_DR_FAIL,
  ADD_NOTES_FOR_DR_LOADING,
  ADD_NOTES_FOR_DR_SUCCESS,
  ChangeDoctorNotesActionTypes,
  ChangeDrNoteStatusTypes,
  ChangeNotesForDrStateType,
  CHANGE_DR_NOTE_STATUS_FAIL,
  CHANGE_DR_NOTE_STATUS_LOADING,
  CHANGE_DR_NOTE_STATUS_SUCCESS,
  CHANGE_NOTES_FOR_DR_FAIL,
  CHANGE_NOTES_FOR_DR_LOADING,
  CHANGE_NOTES_FOR_DR_SUCCESS,
  DoctorNotesActionTypes,
  DRNoteStatusChangeStateType,
  DRNOTE_LIST_FAIL,
  DRNOTE_LIST_LOADING,
  DRNOTE_LIST_SUCCESS,
  GetDrNoteStateType,
  GetNoteForDrStateType,
  NotesForDoctorActionTypes,
  NOTE_LIST_FOR_DR_FAIL,
  NOTE_LIST_FOR_DR_LOADING,
  NOTE_LIST_FOR_DR_SUCCESS,
} from "./ActionTypes";

const initialStateGetDrNote: GetDrNoteStateType = { loading: false, patientCaseNoteListRes: [] };
const initialStateAddDrNote: AddDrNoteStateType = {
  loading: false,
  addDrNoteList: [],
  response: Object.assign({}),
};
const initialStateChangeDrNote: DRNoteStatusChangeStateType = {
  loading: false,
  response: Object.assign({}),
};
const initialStateNoteForDr: GetNoteForDrStateType = {
  loading: false,
  noteList: [],
  message: Object.assign({}),
  status: Object.assign({}),
};
const initialStateAddNotesDr: AddNotesForDrStateType = {
  loading: false,
  message: Object.assign({}),
  status: Object.assign({}),
};
const initialStateChangeNotesDr: ChangeNotesForDrStateType = {
  loading: false,
  message: Object.assign({}),
  status: Object.assign({}),
};

export const getDrNoteReducer = (
  state = initialStateGetDrNote,
  action: DoctorNotesActionTypes,
): GetDrNoteStateType => {
  switch (action.type) {
    case DRNOTE_LIST_SUCCESS:
      return {
        ...state,
        loading: false,
        patientCaseNoteListRes: action.payload,
      };
    case DRNOTE_LIST_LOADING:
      return {
        ...state,
        loading: true,
        patientCaseNoteListRes: [],
      };
    case DRNOTE_LIST_FAIL:
      return {
        ...state,
        patientCaseNoteListRes: [],
        loading: false,
      };
    default:
      return state;
  }
};

export const addDrNotes = (
  state = initialStateAddDrNote,
  action: AddDrNotesTypes,
): AddDrNoteStateType => {
  switch (action.type) {
    case ADD_DR_NOTE_SUCCESS:
      return {
        ...state,
        loading: false,
        response: action.payload,
      };
    case ADD_DR_NOTE_LOADING:
      return {
        ...state,
        loading: true,
        addDrNoteList: [],
      };
    case ADD_DR_NOTE_FAIL:
      return {
        ...state,
        loading: false,
        addDrNoteList: [],
      };
    default:
      return state;
  }
};

export const changeDrNoteStatus = (
  state = initialStateChangeDrNote,
  action: ChangeDrNoteStatusTypes,
): DRNoteStatusChangeStateType => {
  switch (action.type) {
    case CHANGE_DR_NOTE_STATUS_SUCCESS:
      return {
        ...state,
        loading: false,
        response: action.payload,
      };
    case CHANGE_DR_NOTE_STATUS_LOADING:
      return {
        ...state,
        loading: true,
      };
    case CHANGE_DR_NOTE_STATUS_FAIL:
      return {
        ...state,
        loading: false,
      };
    default:
      return state;
  }
};

export const NotesForDrReducer = (
  state = initialStateNoteForDr,
  action: NotesForDoctorActionTypes,
): GetNoteForDrStateType => {
  switch (action.type) {
    case NOTE_LIST_FOR_DR_SUCCESS:
      return {
        ...state,
        loading: false,
        noteList: action.noteList,
        message: action.message,
        status: action.status,
      };
    case NOTE_LIST_FOR_DR_LOADING:
      return {
        ...state,
        loading: true,
      };
    case NOTE_LIST_FOR_DR_FAIL:
      return {
        ...state,
        loading: false,
        noteList: [],
        message: action.message,
        status: action.status,
      };
    default:
      return state;
  }
};
export const addNotesForDrReducer = (
  state = initialStateAddNotesDr,
  action: AddNotesForDrActionTypes,
): AddNotesForDrStateType => {
  switch (action.type) {
    case ADD_NOTES_FOR_DR_SUCCESS:
      return {
        ...state,
        loading: false,
        message: action.message,
        status: action.status,
      };
    case ADD_NOTES_FOR_DR_LOADING:
      return {
        ...state,
        loading: true,
      };
    case ADD_NOTES_FOR_DR_FAIL:
      return {
        ...state,
        loading: false,
        message: action.message,
        status: action.status,
      };
    default:
      return state;
  }
};
export const changeNoteStatusForDrReducer = (
  state = initialStateChangeNotesDr,
  action: ChangeDoctorNotesActionTypes,
): ChangeNotesForDrStateType => {
  switch (action.type) {
    case CHANGE_NOTES_FOR_DR_SUCCESS:
      return {
        ...state,
        loading: false,
        message: action.message,
        status: action.status,
      };
    case CHANGE_NOTES_FOR_DR_LOADING:
      return {
        ...state,
        loading: true,
      };
    case CHANGE_NOTES_FOR_DR_FAIL:
      return {
        ...state,
        loading: false,
        message: action.message,
        status: action.status,
      };
    default:
      return state;
  }
};
