import React, { useState, useCallback, useEffect } from "react";
import { Redirect, useHistory } from "react-router-dom";
import PatientFlow from "../image/design-assets/patient-flow-chart.png";
import { useDispatch, useSelector } from "react-redux";
import ShareIcon from "@mui/icons-material/Share";
import MoreVertIcon from "@mui/icons-material/MoreVert";
import { deletePatient, getSharedPatientListValue } from "../redux/effects/ApiCall";
import { AppState } from "../redux/store/Store";
import { SharedPatientList } from "../redux/interfaces/Model";
import CloseIcon from "@mui/icons-material/Close";
import {
  Dialog,
  Link,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  OutlinedInput,
  Typography,
  useMediaQuery,
  IconButton,
  Card,
  CardHeader,
  CardContent,
  CardActions,
  Divider,
  Box,
  Button,
  Grid,
  useTheme,
  Stack,
  Alert,
} from "@mui/material";
import {
  requestBookAppointmentApi,
  updateApiResponse,
} from "../redux/requestAppointmentByPatient/API";
import { t } from "i18next";
export default function SharePatientList(loader: boolean | any) {
  const { sharedPatientListLoader } = loader;
  const [toSignIn] = useState(false);
  const [modalActive, setModalActive] = useState(false);
  const [shareHealthRecord, setShareHealthRecord] = useState("");

  const history = useHistory();
  const requestBookAppointmentResult = useSelector(
    (state: AppState) => state.bookAppointmentRequest,
  );
  const truncate = (str: string) => {
    return str.length > 10 ? str.substring(0, 20) + "..." : str;
  };

  const theme = useTheme();
  const fullScreen = useMediaQuery(theme.breakpoints.down("md"));

  const DATE_OPTIONS = {
    year: "numeric",
    month: "short",
    day: "numeric",
  } as const;
  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(getSharedPatientListValue());
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [dispatch]);

  const shareRecord = () => {
    setModalActive((modalActive) => !modalActive);
    setShareHealthRecord("");
  };

  const [deleteRecordModalMarkup, setDeleteModalActive] = useState(false);
  const handleDeleteRecordModalChange = useCallback(
    () => setDeleteModalActive(!deleteRecordModalMarkup),
    [deleteRecordModalMarkup],
  );

  let [patientId, setPatientId] = useState();
  function deleteRecord(patientId: any) {
    setDeleteModalActive((deleteRecordModalMarkup) => !deleteRecordModalMarkup);
    setPatientId(patientId);
  }
  const handleMessageChangeHr = useCallback((value: any) => setShareHealthRecord(value), []);
  const closeSuccess = () => {
    setDeleteRecordSuccess("");
    setDeleteRecordError("");
  };
  const [deleteRecordSuccess, setDeleteRecordSuccess] = useState("");
  const successDelRecordDiv = deleteRecordSuccess ? (
    <Alert variant="standard" severity="success" onClose={() => closeSuccess()}>
      {deleteRecordSuccess}
    </Alert>
  ) : (
    ""
  );

  const [recordDeleteError, setDeleteRecordError] = useState("");
  const errorDelRecordDiv = recordDeleteError ? (
    <Alert variant="standard" severity="error" onClose={() => closeSuccess()}>
      {recordDeleteError}
    </Alert>
  ) : (
    ""
  );

  const deleteBody = {
    patientId: patientId,
    isActive: false,
  };

  const DeleteRecordSubmit = () => {
    setDeleteModalActive((deleteRecordModalMarkup) => !deleteRecordModalMarkup);
    dispatch(deletePatient(deleteBody));
  };

  function BookAppointment(patientId: any) {
    dispatch(requestBookAppointmentApi(patientId));
  }

  useEffect(() => {
    sharedPatientListLoader(requestBookAppointmentResult?.loading);
    if (
      requestBookAppointmentResult?.bookAppoinmentRes?.patientId !== undefined &&
      requestBookAppointmentResult?.bookAppoinmentRes?.caseId !== undefined &&
      requestBookAppointmentResult?.bookAppoinmentRes?.whyWant2ndOpinion !== undefined
    ) {
      history.push(
        `/filepreparation/${requestBookAppointmentResult.bookAppoinmentRes.patientId}/${requestBookAppointmentResult.bookAppoinmentRes.caseId}`,
      );
      dispatch(updateApiResponse());
    } else if (
      requestBookAppointmentResult?.bookAppoinmentRes?.patientId !== undefined &&
      requestBookAppointmentResult?.bookAppoinmentRes?.caseId === undefined
    ) {
      history.push(`/filepreparation/${requestBookAppointmentResult.bookAppoinmentRes.patientId}`);
      dispatch(updateApiResponse());
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [requestBookAppointmentResult]);

  const deleteSharedPtValue = useSelector((state: AppState) => state.deletePatientValues);
  useEffect(() => {
    sharedPatientListLoader(deleteSharedPtValue?.loading);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [deleteSharedPtValue]);
  const emptyPageMarkup = (
    <Grid container spacing={{ xs: 2, md: 3 }}>
      <Grid item xs={6} md={6} container alignItems="center" style={{ height: "50vh" }}>
        <Box pt={5} mt={5}>
          <Typography variant="h3">{t("perHealthRecPage.seekCon", { ns: ["home"] })}</Typography>
          <p>
            <br />
            {t("perHealthRecPage.oneStopSol", { ns: ["home"] })}.
            <br />
            <br />
          </p>
          <div>
            <Link href="/demographicdetails">
              <Button variant="contained">{t("menu.patient", { ns: ["home"] })}</Button>
            </Link>
          </div>
        </Box>
      </Grid>
      <Grid item xs={6} md={6}>
        <Box pt={5} mt={5}>
          <div>
            <img
              width="100%"
              height="100%"
              style={{
                objectFit: "cover",
                objectPosition: "center",
              }}
              src={PatientFlow}
              alt=""
            />
          </div>
        </Box>
      </Grid>
    </Grid>
  );

  const SharedPatientListFromAPI = useSelector((state: AppState) => state.sharedPatientValue);
  useEffect(() => {
    sharedPatientListLoader(SharedPatientListFromAPI?.loading);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [SharedPatientListFromAPI]);
  const actualPageMarkup = (
    <Grid container direction="row" justifyContent="flex-start" alignItems="flex-start" spacing={2}>
      {SharedPatientListFromAPI.sharedPatientValue.map(
        (patientItem: SharedPatientList, index: number) => (
          <Grid item xs={12} sm={4} lg={4} key={index}>
            <Card>
              <CardHeader
                key={"card-header-" + index}
                avatar={
                  <img
                    id={`${patientItem.id}`}
                    src={
                      patientItem.profilePicture === null
                        ? "medical.png"
                        : patientItem.profilePicture.document
                    }
                    alt=""
                    width="64"
                    height="64"
                    style={{
                      objectFit: "cover",
                      objectPosition: "center",
                      borderRadius: "32px",
                    }}
                  />
                }
                action={[
                  <Box mt={1} key={"card-action-" + index}>
                    <Link onClick={shareRecord}>
                      <IconButton>
                        <ShareIcon />
                      </IconButton>
                    </Link>
                    ,
                    <Link onClick={() => deleteRecord(patientItem.id)}>
                      <IconButton>
                        <MoreVertIcon />
                      </IconButton>
                    </Link>
                  </Box>,
                ]}
                title={
                  <Typography variant="h6" fontWeight="bold" key={"card-title-" + index}>
                    {patientItem.firstname} {patientItem.lastname}
                  </Typography>
                }
                subheader={
                  <Typography variant="subtitle1" key={"card-subtitle-" + index}>
                    {patientItem.patientRelationshipWithOwner?.displayValue}
                  </Typography>
                }
              />
              <CardContent key={"card-content-" + index}>
                <Typography variant="body1" align="left">
                  {patientItem.description ? truncate(patientItem.description) : "Not Set"}
                </Typography>
                <Box my={2}>
                  <Divider />
                </Box>
                <Grid
                  container
                  direction="row"
                  justifyContent="space-between"
                  alignItems="flex-start"
                >
                  <Grid item>
                    <Stack
                      direction="column"
                      justifyContent="space-between"
                      alignItems="center"
                      spacing={2}
                    >
                      <Typography>{t("perHealthRecPage.gen", { ns: ["home"] })!}</Typography>
                      <Typography fontWeight="bold">{patientItem.gender?.label}</Typography>
                    </Stack>
                  </Grid>
                  <Divider orientation="vertical" flexItem />
                  <Grid item>
                    <Stack
                      direction="column"
                      justifyContent="space-between"
                      alignItems="center"
                      spacing={2}
                    >
                      <Typography>
                        {
                          t("addpatientpage.bloodgroup", {
                            ns: ["home"],
                          })!
                        }
                      </Typography>
                      <Typography fontWeight="bold">
                        {patientItem.bloodGroup === null
                          ? "Not Known"
                          : patientItem.bloodGroup.label}
                      </Typography>
                    </Stack>
                  </Grid>
                  <Divider orientation="vertical" flexItem />
                  <Grid item>
                    <Stack
                      direction="column"
                      justifyContent="space-between"
                      alignItems="center"
                      spacing={2}
                    >
                      <Typography>
                        {t("perHealthRecPage.patDob", {
                          ns: ["home"],
                        })}
                      </Typography>
                      <Typography fontWeight="bold">
                        {new Date(patientItem.dob).toLocaleDateString("en-US", DATE_OPTIONS)}
                      </Typography>
                    </Stack>
                  </Grid>
                </Grid>
                <Box my={2}>
                  <Divider orientation="horizontal" flexItem />
                </Box>
              </CardContent>
              <CardActions>
                <Grid
                  container
                  direction="row"
                  justifyContent="flex-start"
                  alignItems="flex-start"
                  spacing={2}
                >
                  <Grid item>
                    <Link href={`/patientdetails/${patientItem.id}`}>
                      <Button variant="contained" size="medium">
                        {t("perHealthRecPage.caseDet", {
                          ns: ["home"],
                        })}
                      </Button>
                    </Link>
                  </Grid>
                  <Grid item>
                    <Link>
                      <Button
                        variant="outlined"
                        size="medium"
                        onClick={() => BookAppointment(patientItem.id)}
                      >
                        {t("finddoc.bookAppo", { ns: ["home"] })}
                      </Button>
                    </Link>
                  </Grid>
                </Grid>
              </CardActions>
            </Card>
          </Grid>
        ),
      )}
    </Grid>
  );

  const modalMarkup = (
    <Dialog open={modalActive} onClose={shareRecord}>
      <DialogTitle>
        <Grid container direction="row" justifyContent="space-between" alignItems="flex-start">
          <Grid item>
            <Typography>{t("perHealthRecPage.shareHealthRec", { ns: ["home"] })!}</Typography>
          </Grid>
          <Grid item>
            <IconButton
              aria-label="close"
              onClick={shareRecord}
              sx={{
                position: "absolute",
                right: 8,
                top: 8,
                color: (theme) => theme.palette.grey[500],
              }}
            >
              <CloseIcon />
            </IconButton>
          </Grid>
        </Grid>
      </DialogTitle>
      <Divider />
      <DialogContent>
        <DialogContentText>
          <Box style={{ marginBottom: "5px" }}>
            {t("perHealthRecPage.sharePeoGrp", { ns: ["home"] })}
          </Box>
          <OutlinedInput
            value={shareHealthRecord}
            placeholder={t("perHealthRecPage.addPeoGrp", { ns: ["home"] })!}
            id="sharehealthrecord"
            onChange={handleMessageChangeHr}
            fullWidth
          />
        </DialogContentText>
      </DialogContent>
      <DialogActions>
        <Button variant="contained" size="medium">
          {t("perHealthRecPage.share", { ns: ["home"] })}
        </Button>
      </DialogActions>
    </Dialog>
  );

  const deleteRecordModal = (
    <Dialog
      open={deleteRecordModalMarkup}
      onClose={handleDeleteRecordModalChange}
      fullScreen={fullScreen}
    >
      <DialogTitle>
        <Grid container direction="row" justifyContent="space-between" alignItems="flex-start">
          <Grid item>
            <Typography>{t("perHealthRecPage.delPat", { ns: ["home"] })!}</Typography>
          </Grid>
          <Grid item>
            <IconButton
              aria-label="close"
              onClick={handleDeleteRecordModalChange}
              sx={{
                position: "absolute",
                right: 8,
                top: 8,
                color: (theme) => theme.palette.grey[500],
              }}
            >
              <CloseIcon />
            </IconButton>
          </Grid>
        </Grid>
      </DialogTitle>
      <Divider />
      <DialogContent>
        <DialogContentText>
          <Typography variant="h6" sx={{ fontWeight: "bold" }}>
            {t("perHealthRecPage.qdelPat", { ns: ["home"] })}
          </Typography>
        </DialogContentText>
      </DialogContent>
      <DialogActions>
        <Button variant="outlined" onClick={handleDeleteRecordModalChange}>
          No
        </Button>
        <Button variant="contained" size="medium" onClick={DeleteRecordSubmit}>
          {t("common.yes", { ns: ["home"] })}
        </Button>
      </DialogActions>
      <div>{successDelRecordDiv}</div>
      <div>{errorDelRecordDiv}</div>
    </Dialog>
  );
  return (
    <Box>
      {toSignIn ? <Redirect to="/signin" /> : null}
      {SharedPatientListFromAPI.sharedPatientValue.length === 0
        ? emptyPageMarkup
        : actualPageMarkup}
      {modalMarkup}
      {deleteRecordModal}
    </Box>
  );
}
