import axios from "axios";
import { Dispatch } from "redux";
import Url from "../../Url";
import { setupInterceptorsTo } from "../Authentication/Interceptors";
import {
  setEmailPhoneActionTypes,
  SET_EMAIL_PHONE_FAIL,
  SET_EMAIL_PHONE_LOADING,
  SET_EMAIL_PHONE_SUCCESS,
} from "./ActionTypes";
import { SetEmailPhoneDetails, SetEmailPhoneValue } from "./Model";
import { headers } from "components/Utility";

export const setEmailOrPhoneApi = (body: SetEmailPhoneDetails) => {
  return function (dispatch: Dispatch<setEmailPhoneActionTypes>) {
    dispatch({
      type: SET_EMAIL_PHONE_LOADING,
    });

    setupInterceptorsTo(axios)
      .post(Url.apiURL + `/profileapp/api/userDetails/set_email_or_phone`, body, {
        headers: headers,
      })
      .then((res) => {
        dispatch({
          type: SET_EMAIL_PHONE_SUCCESS,
          payload: res.data,
          errRes: "",
        });
        localStorage.setItem("token", res.data.token);
        localStorage.setItem("email", res.data.user.email);
        localStorage.setItem("firstname", res.data.user.first_name);
        localStorage.setItem("lastname", res.data.user.last_name);
      })
      .catch((error) => {
        dispatch({
          type: SET_EMAIL_PHONE_FAIL,
          payload: {} as SetEmailPhoneValue,
          errRes: error.response.data.message,
        });
      });
  };
};
