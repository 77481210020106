import { GET_QUESTION_LIST, getQuestionListActionTypes } from "./ActionTypes";
import { getQuestionListValue } from "./Model";

export const getQuestionListAction = (
  getQuestionListDetails: getQuestionListValue,
): getQuestionListActionTypes => {
  return {
    type: GET_QUESTION_LIST,
    payload: getQuestionListDetails,
  };
};
