import {
  PatientPointDataListActionType,
  PATIENT_DATA_DETAILS_POINT_FAIL,
  PATIENT_DATA_DETAILS_POINT_LOADING,
  PATIENT_DATA_DETAILS_POINT_SUCCESS,
  AddPatientPointDataState,
} from "./ActionTypes";
import { PatientDataDetailsRes } from "./Model";

const initialStateGetPosts: AddPatientPointDataState = {
  loading: false,
  getPatientDataDetailsRes: {} as PatientDataDetailsRes,
  pageCount: 0,
};
export const getPatientDataDetailsListReducer = (
  state = initialStateGetPosts,
  action: PatientPointDataListActionType,
): AddPatientPointDataState => {
  switch (action.type) {
    case PATIENT_DATA_DETAILS_POINT_SUCCESS:
      return {
        ...state,
        loading: false,
        getPatientDataDetailsRes: action.payload,
        pageCount: action.pageCount,
      };
    case PATIENT_DATA_DETAILS_POINT_LOADING:
      return {
        ...state,
        loading: true,
        getPatientDataDetailsRes: {} as PatientDataDetailsRes,
      };
    case PATIENT_DATA_DETAILS_POINT_FAIL:
      return {
        ...state,
        getPatientDataDetailsRes: {} as PatientDataDetailsRes,
        loading: false,
      };
    default:
      return state;
  }
};
