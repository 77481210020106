import * as React from "react";
import {
  Box,
  Button,
  Card,
  CardContent,
  CardHeader,
  Grid,
  Tab,
  Tabs,
  ToggleButton,
  ToggleButtonGroup,
  Typography,
} from "@mui/material";
import { useParams } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import {
  DataListByObservationTypeBody,
  PatientObservationsList,
} from "../../../redux/patientDashboardDataListByObservationType/Model";
import { dataListByObservationType } from "../../../redux/patientDashboardDataListByObservationType/ApiCall";
import { AppState } from "../../../redux/store/Store";
import dayjs from "dayjs";
import ObservationChart from "./Chart";
import AddDataPointDialog from "../../PatientDataDetails/AddDataPointDialog";
import MWLoader from "../../../components/MWLoader";
import PageLayout from "../../../components/PageLayout";
import MWPageTitle from "../../../components/MWPageTitle";
import ObservationDetailsTable from "./ObservationDetailsTable";
import SleepSchedule from "./SleepSchedule";
import SleepQuality from "./SleepQuality";
import { sleepScoreObservationType } from "redux/SleepAppApi/SleepScoreObservationTypeApi/ApiCall";
import { SleepScoreObservationTypeBody } from "redux/SleepAppApi/SleepScoreObservationTypeApi/Model";
import { sleepTimeObservationType } from "redux/SleepAppApi/SleepTimeObservationTypeApi/ApiCall";
import { SleepTimeObservationTypeBody } from "redux/SleepAppApi/SleepTimeObservationTypeApi/Model";
import { sleepScheduleObservationType } from "redux/SleepAppApi/SleepScheduleObservationTypeApi/ApiCall";
import { SleepScheduleObservationTypeBody } from "redux/SleepAppApi/SleepScheduleObservationTypeApi/Model";

interface TabPanelProps {
  children?: React.ReactNode;
  dir?: string;
  index: number;
  value: number;
}

function TabPanel(props: TabPanelProps) {
  console.log("props", props)
  const { children, value, index, ...other } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`full-width-tabpanel-${index}`}
      aria-labelledby={`full-width-tab-${index}`}
      {...other}
    >
      {value === index && (
        <Box sx={{ p: 3 }}>
          <Typography>{children}</Typography>
        </Box>
      )}
    </div>
  );
}

function a11yProps(index: number) {
  return {
    id: `full-width-tab-${index}`,
    "aria-controls": `full-width-tabpanel-${index}`,
  };
}

export default function ObservationDetails() {
  const { patientid } = useParams() as {
    patientid: string;
  };
  const { observationtype } = useParams() as {
    observationtype: string;
  };
  const dispatch = useDispatch();
  const [tabValue, setTabValue] = React.useState(0);
  const handleTabChange = (_event: React.SyntheticEvent, newValue: number) => {
    setTabValue(newValue);
  };
  React.useEffect(() => {
    if (observationtype === "sleep") {
      dispatch(
        sleepScoreObservationType({
          patientId: patientid,
          observationType: "sleepscore",
        } as SleepScoreObservationTypeBody),
      );
      dispatch(
        sleepTimeObservationType({
          patientId: patientid,
          observationType: "sleephours",
        } as SleepTimeObservationTypeBody),
      );
      dispatch(
        sleepScheduleObservationType({
          patientId: patientid,
          observationType: "sleepschedule",
        } as SleepScheduleObservationTypeBody),
      );
    } else {
      dispatch(
        dataListByObservationType({
          patientId: patientid,
          observationType: observationtype,
        } as DataListByObservationTypeBody),
      );
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);
  const dataListByObservationTypeResponse = useSelector(
    (state: AppState) => state.dataListByObservationTypeRes,
  );
  const sleepScoreObservationTypeValueList = useSelector(
    (state: AppState) => state.sleepScoreObservationTypeValue,
  );

  const [observationListByType, setObservationListByType] = React.useState([] as any[]);

  const [isLoading, setIsLoading] = React.useState(false);
  const [count, setCount] = React.useState("0");
  const [title, setTitle] = React.useState("");
  const reloadActionFromChild = (passedVal: boolean) => {
    if (passedVal) {
      dispatch(
        dataListByObservationType({
          patientId: patientid,
          observationType: observationtype,
        } as DataListByObservationTypeBody),
      );
    }
  };
  const [showAddDataPointDialog, setShowAddDataPointDialog] = React.useState(false);
  const updateDialogState = () => {
    setShowAddDataPointDialog(true);
  };
  const updateDataPointDialogValFromChild = (passedVal: boolean) => {
    setShowAddDataPointDialog(passedVal);
  };

  React.useEffect(() => {
    setIsLoading(dataListByObservationTypeResponse?.loading);
    if (
      dataListByObservationTypeResponse?.dataListByObservationTypeRes?.patientObservationsList !==
      undefined
    ) {
      setCount(
        String(
          dataListByObservationTypeResponse?.dataListByObservationTypeRes?.patientObservationsList
            .length,
        ),
      );
      if (
        dataListByObservationTypeResponse?.dataListByObservationTypeRes?.patientObservationsList
          .length > 0
      ) {
        let modifiedObservationList =
          dataListByObservationTypeResponse?.dataListByObservationTypeRes?.patientObservationsList.map(
            (element: PatientObservationsList) => ({
              id: element.id,
              name: element.patientObservationType.displayValue,
              value: element.value,
              startDate:
                element.startDate !== null
                  ? dayjs(element.startDate).format("ddd, DD/MM/YYYY")
                  : "N/A",
              createDate:
                element.createDate !== null
                  ? dayjs(element.createDate).format("ddd, DD/MM/YYYY")
                  : "N/A",
              modifiedDate:
                element.modifiedDate !== null
                  ? dayjs(element.modifiedDate).format("ddd, DD/MM/YYYY")
                  : "N/A",
              endDate:
                element.endDate !== null ? dayjs(element.endDate).format("ddd, DD/MM/YYYY") : "N/A",
              isActive: element.isActive === true ? "Active" : "Inactive",
            }),
          );
        setObservationListByType(modifiedObservationList);
      } else {
        setObservationListByType([] as any[]);
        setCount("0");
      }
    } else {
      setObservationListByType([] as any[]);
      setCount("0");
    }
  }, [dataListByObservationTypeResponse]);
  React.useEffect(() => {
    if (observationtype === "sleep") {
      setIsLoading(sleepScoreObservationTypeValueList?.loading);
      if (
        sleepScoreObservationTypeValueList?.sleepScoreObservationTypeRes
          ?.patientObservationsList !== undefined
      ) {
        setCount(
          String(
            sleepScoreObservationTypeValueList?.sleepScoreObservationTypeRes
              ?.patientObservationsList.length,
          ),
        );
        if (
          sleepScoreObservationTypeValueList?.sleepScoreObservationTypeRes
            ?.patientObservationsList !== undefined
        ) {
          let modifiedSleepObservationList =
            sleepScoreObservationTypeValueList?.sleepScoreObservationTypeRes?.patientObservationsList.map(
              (element: PatientObservationsList) => ({
                id: element.id,
                name: element.patientObservationType.displayValue,
                value: element.value,
                startDate:
                  element.startDate !== null
                    ? dayjs(element.startDate).format("ddd, DD/MM/YYYY")
                    : "N/A",
                createDate:
                  element.createDate !== null
                    ? dayjs(element.createDate).format("ddd, DD/MM/YYYY")
                    : "N/A",
                modifiedDate:
                  element.modifiedDate !== null
                    ? dayjs(element.modifiedDate).format("ddd, DD/MM/YYYY")
                    : "N/A",
                endDate:
                  element.endDate !== null
                    ? dayjs(element.endDate).format("ddd, DD/MM/YYYY")
                    : "N/A",
                isActive: element.isActive === true ? "Active" : "Inactive",
              }),
            );
          setObservationListByType(modifiedSleepObservationList);
        } else {
          setObservationListByType([] as any[]);
          setCount("0");
        }
      } else {
        setObservationListByType([] as any[]);
        setCount("0");
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [sleepScoreObservationTypeValueList]);
  React.useEffect(() => {
    if (observationtype !== undefined) {
      setTitle(observationtype.charAt(0).toUpperCase() + observationtype.slice(1));
    }
  }, [observationtype]);

  const [alignment, setAlignment] = React.useState("day");
  const handleChange = (_event: React.MouseEvent<HTMLElement>, newAlignment: string) => {
    setAlignment(newAlignment);
  };

  return (
    <Box>
      {isLoading ? <MWLoader /> : null}
      <PageLayout>
        <Grid container direction="row" justifyContent="space-between" alignItems="flex-start">
          <Grid item>
            <MWPageTitle
              backButton={true}
              title={`${title} Observation Details`}
              enableCount={true}
              count={count}
              reload={true}
              reloadAction={reloadActionFromChild}
            />
          </Grid>

          <Grid item>
            <Button variant="contained" onClick={() => updateDialogState()}>
              Add Patient Details
            </Button>
          </Grid>
        </Grid>
        <Card>
          <CardHeader
            title={
              <Grid
                container
                direction="row"
                justifyContent="flex-start"
                alignItems="flex-start"
                spacing={2}
              >
                <Grid item>
                  <ToggleButtonGroup
                    color="primary"
                    value={alignment}
                    exclusive
                    onChange={handleChange}
                    aria-label="Platform"
                  >
                    {observationtype === "sleep" ? null : (
                      <ToggleButton value="minute">Minute</ToggleButton>
                    )}
                    {observationtype === "sleep" ? null : (
                      <ToggleButton value="hour">Hour</ToggleButton>
                    )}
                    <ToggleButton value="day">Day</ToggleButton>
                    <ToggleButton value="week">Week</ToggleButton>
                    <ToggleButton value="month">Month</ToggleButton>
                    <ToggleButton value="year">Year</ToggleButton>
                  </ToggleButtonGroup>
                </Grid>
              </Grid>
            }
          />
          <CardContent>
            <Grid
              container
              direction="row"
              justifyContent="space-between"
              alignItems="flex-start"
              spacing={1}
            >
              <Grid item xs={7}>
                {observationListByType[0] !== undefined ? (
                  <ObservationChart heading={observationListByType[0].name} unit={alignment} />
                ) : null}
              </Grid>
              <Grid item xs={5}>
                {observationtype === "sleep" ? (
                  <Box sx={{ borderBottom: 1, borderColor: "divider" }}>
                    <Tabs
                      value={tabValue}
                      onChange={handleTabChange}
                      aria-label="basic tabs example"
                    >
                      <Tab label="General" {...a11yProps(0)} />

                      <Tab label="Sleep Schedule" {...a11yProps(1)} />

                      <Tab label="Sleep Quality" {...a11yProps(2)} />
                    </Tabs>
                  </Box>
                ) : (
                  <ObservationDetailsTable />
                )}
                {observationtype === "sleep" ? (
                  <TabPanel value={tabValue} index={0}>
                    <ObservationDetailsTable />
                  </TabPanel>
                ) : null}
                <TabPanel value={tabValue} index={1}>
                  <SleepSchedule observationtype={observationtype} patientid={patientid} />
                </TabPanel>
                <TabPanel value={tabValue} index={2}>
                  <SleepQuality observationtype={observationtype} patientid={patientid} />
                </TabPanel>
              </Grid>
            </Grid>
          </CardContent>
        </Card>
      </PageLayout>
      {showAddDataPointDialog ? (
        <AddDataPointDialog
          addDataPointDialogEnable={showAddDataPointDialog}
          addDataPointDialogUpdateState={updateDataPointDialogValFromChild}
        />
      ) : null}
    </Box>
  );
}
