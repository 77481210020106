import {
    PATIENT_ENCOUNTER_LIST_FAIL,
    PATIENT_ENCOUNTER_LIST_LOADING,
    PATIENT_ENCOUNTER_LIST_SUCCESS,
    PATIENT_ENCOUNTER_LIST_UPDATE_API_MSG,
    PATIENT_ENCOUNTER_LIST_UPDATE_API_RES,
    GetPatientEncounterListActionTypes,
  } from "./ActionTypes";
  import { GetPatientEncounterListRes } from "./Model";
  
  export const patientEncounterListLoadingAction = (
    loading: boolean,
  ): GetPatientEncounterListActionTypes => {
    return {
      type: PATIENT_ENCOUNTER_LIST_LOADING,
      loading: loading,
    };
  };
  
  export const patientEncounterListSuccessAction = (
   patientEncounterListResponse: GetPatientEncounterListRes,
    successMsg: string,
  ): GetPatientEncounterListActionTypes => {
    return {
      type: PATIENT_ENCOUNTER_LIST_SUCCESS,
      payload:patientEncounterListResponse,
      successMsg: successMsg,
    };
  };
  
  export const patientEncounterListErrorAction = (
   patientEncounterListResponse: GetPatientEncounterListRes,
    errMsg: string,
    status: number,
  ): GetPatientEncounterListActionTypes => {
    return {
      type: PATIENT_ENCOUNTER_LIST_FAIL,
      payload:patientEncounterListResponse,
      errorMsg: errMsg,
      status: status,
    };
  };
  export const patientEncounterListUpdateAPIMsgAction = (
   patientEncounterListResponse: GetPatientEncounterListRes,
    successMsg: string,
    errMsg: string,
    status: number,
  ): GetPatientEncounterListActionTypes => {
    return {
      type: PATIENT_ENCOUNTER_LIST_UPDATE_API_MSG,
      payload:patientEncounterListResponse,
      successMsg: successMsg,
      errorMsg: errMsg,
      status: status,
    };
  };
  
  export const patientEncounterListAPIResClearAction = (
   patientEncounterListResponse: GetPatientEncounterListRes,
    successMsg: string,
    errMsg: string,
    status: number,
  ): GetPatientEncounterListActionTypes => {
    return {
      type: PATIENT_ENCOUNTER_LIST_UPDATE_API_RES,
      payload:patientEncounterListResponse,
      successMsg: successMsg,
      errorMsg: errMsg,
      status: status,
    };
  };
  