import axios from "axios";
import { Dispatch } from "redux";
import Url from "../../Url";
import { setupInterceptorsTo } from "../Authentication/Interceptors";
import { requestBookAppoiontmentAction } from "./Action";
import {
  PatientDropdownListActionTypes,
  PATIENT_LIST_DROPDOWN_SUCCESS,
  PATIENT_LIST_DROPDOWN_LOADING,
  PATIENT_LIST_DROPDOWN_FAIL,
} from "./ActionTypes";
import { headers } from "components/Utility";

export const patientDropdownListApi = () => {
  return function (dispatch: Dispatch<PatientDropdownListActionTypes>) {
    dispatch({
      type: PATIENT_LIST_DROPDOWN_LOADING,
    });

    setupInterceptorsTo(axios)
      .get(Url.apiURL + `/patientapp/api/patient/patient_list_dropdown`, {
        headers: headers,
      })
      .then((res) => {
        dispatch(requestBookAppoiontmentAction(res.data));
        dispatch({
          type: PATIENT_LIST_DROPDOWN_SUCCESS,
          payload: res.data,
        });
      })
      .catch((_error) => {
        dispatch({
          type: PATIENT_LIST_DROPDOWN_FAIL,
        });
      });
  };
};
