import axios from "axios";
import { Dispatch } from "redux";
import Url from "../../../Url";
import { setupInterceptorsTo } from "../../Authentication/Interceptors";
import {
  categoryActionTypes,
  CATEGORY_LIST_FAIL,
  CATEGORY_LIST_LOADING,
  CATEGORY_LIST_SUCCESS,
} from "./ActionTypes";
import { CategoryResponse } from "./Model";
import { headers } from "components/Utility";

export const categoryListApi = () => {
  return function (dispatch: Dispatch<categoryActionTypes>) {
    dispatch({
      type: CATEGORY_LIST_LOADING,
    });

    setupInterceptorsTo(axios)
      .get(Url.apiURL + `/secondopinionapp/api/doctorCategory/doctor_category_list`, {
        headers: headers,
      })
      .then((res) => {
        dispatch({
          type: CATEGORY_LIST_SUCCESS,
          payload: res.data,
          errRes: "",
        });
      })
      .catch((error) => {
        dispatch({
          type: CATEGORY_LIST_FAIL,
          payload: {} as CategoryResponse,
          errRes: error.response.data.message,
        });
      });
  };
};
