import * as React from "react";
import { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { AppState } from "../../redux/store/Store";
import { useParams } from "react-router-dom";
import {
  Pagination,
  Typography,
  Box,
  Grid,
  Stack,
  Card,
  CardHeader,
  CardContent,
} from "@mui/material";
import { DataGrid, GridColDef, GridToolbar } from "@mui/x-data-grid";
import MWPageTitle from "../../components/MWPageTitle";
import { NameGenerate } from "../../components/Utility";
import MWLoader from "../../components/MWLoader";
import { getPatientEncounterListApi } from "redux/PatientEncounterList/API";
import { GetPatientEncounterListBody, PatientStayList } from "redux/PatientEncounterList/Model";
import dayjs from "dayjs";
import MWExceptionList from "components/MWExceptionList";
type Props = {
  toggleState: boolean | any;
};

export default function PatientEncounterList({ toggleState }: Props) {
  const dispatch = useDispatch();
  const loadingMarkup = <MWLoader />;
  const { patientid } = useParams() as {
    patientid: string;
  };
  const [page, setPage] = React.useState(1);
  // api store call..
  const getExtractObservationValue = useSelector(
    (state: AppState) => state.getExtractObservationValue,
  );

  const getEncounterList = useSelector((state: AppState) => state.patientEncounterListValues);

  // Encounter list api call here ...
  useEffect(() => {
    dispatch(
      getPatientEncounterListApi({
        patientId: patientid,
        pageNo: 1,
      } as GetPatientEncounterListBody),
    );
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [dispatch]);

  const caseHistoryPtName = useSelector(
    (state: AppState) => state.caseValue.patientname.patientName,
  );
  const [pageTitle, setPageTitle] = React.useState("");
  const [pageListCount, setPageListCount] = React.useState("");
  console.log(setPageListCount);

  const [listCount, setListCount] = React.useState("0");
  console.log(setListCount);

  const reloadActionFromChild = (passedVal: boolean) => {
    if (passedVal) {
      //   dispatch(getExtractObservationByPatientId(patientid, page));
      console.log("passedVal====", passedVal);
    }
  };

  useEffect(() => {
    setPageTitle(
      "Patient Encounter List of " + NameGenerate.changeName(patientid, caseHistoryPtName),
    );
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [toggleState]);

  const handlePageChange = (_event: React.ChangeEvent<unknown>, value: number) => {
    setPage(value);
  };

  const [dataGridArray, setDataArray] = React.useState([] as any[]);
  useEffect(() => {
    getEncounterList?.patientEncounterListRes?.patientStayList !== undefined
      ? setListCount(String(getEncounterList?.patientEncounterListRes?.patientStayList.length))
      : setListCount("0");

    if (getEncounterList?.patientEncounterListRes?.patientStayList !== undefined) {
      let dataPointArr = getEncounterList?.patientEncounterListRes?.patientStayList.map(
        (element: PatientStayList) => ({
          id: element.iep,
          etablissement: element.etablissement,
          uf: element.uf,
          startDate: dayjs(element.datedeb).format("dddd, MMMM D, YYYY"),
          endDate: dayjs(element.datefin).format("dddd, MMMM D, YYYY"),
        }),
      );
      setDataArray(dataPointArr);
    } else {
      setDataArray([] as any[]);
      setPageListCount(String(getExtractObservationValue?.pageCount));
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [getEncounterList]);

  const columns: GridColDef[] = [
    {
      field: "id",
      headerName: "Encounter Id",
      align: "left",
      flex: 0.5,
    },
    {
      field: "etablissement",
      headerName: "Etablissement",
      type: "string",
      editable: true,
      sortable: false,
      disableColumnMenu: true,
      width: 90,
      align: "left",
      flex: 1,
    },
    {
      field: "uf",
      headerName: "Department",
      type: "string",
      editable: true,
      sortable: false,
      width: 250,
      disableColumnMenu: true,
      align: "left",
      flex: 1,
    },
    {
      field: "startDate",
      headerName: "Start Date",
      type: "string",
      editable: true,
      sortable: false,
      width: 130,
      disableColumnMenu: true,
      align: "left",
      flex: 1,
    },
    {
      field: "endDate",
      headerName: "End Date",
      type: "string",
      editable: true,
      sortable: false,
      width: 130,
      disableColumnMenu: true,
      align: "left",
      flex: 1,
    },
  ];

  const patientEncounterListTable = (
    <Card>
      <CardHeader
        title={
          <Grid
            container
            direction="row"
            justifyContent="flex-end"
            alignItems="flex-start"
            spacing={1}
            pt={2}
          >
            <Typography sx={{ paddingTop: "5px" }}>Page: {page}</Typography>
            <Pagination
              color="primary"
              count={Number(pageListCount)}
              page={page}
              onChange={handlePageChange}
            />
          </Grid>
        }
      />
      <CardContent>
        {dataGridArray && dataGridArray.length ? (
          <DataGrid
            rows={dataGridArray}
            columns={columns}
            hideFooter
            hideFooterPagination
            disableSelectionOnClick
            experimentalFeatures={{ newEditingApi: true }}
            autoHeight
            components={{ Toolbar: GridToolbar }}
            componentsProps={{
              toolbar: {
                csvOptions: { disableToolbarButton: true },
                printOptions: { disableToolbarButton: true },
                showQuickFilter: true,
                quickFilterProps: { debounceMs: 250 },
              },
            }}
            disableColumnFilter
            disableColumnSelector
            disableDensitySelector
          />
        ) : (
          <MWExceptionList />
        )}
      </CardContent>
    </Card>
  );

  return (
    <Box>
      {getExtractObservationValue.loading === true ? loadingMarkup : ""}
      <Stack direction="column" justifyContent="flex-start" alignItems="stretch" spacing={2} pt={2}>
        <Grid container direction="row" justifyContent="space-between" alignItems="flex-start">
          <Grid item>
            <MWPageTitle
              backButton={true}
              title={pageTitle}
              enableCount={true}
              count={listCount}
              reload={true}
              reloadAction={reloadActionFromChild}
            />
          </Grid>
        </Grid>
        {patientEncounterListTable}
      </Stack>
    </Box>
  );
}
