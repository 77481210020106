import {
  SLEEP_QUALITY_OBSERVATION_TYPE_FAIL,
  SLEEP_QUALITY_OBSERVATION_TYPE_LOADING,
  SLEEP_QUALITY_OBSERVATION_TYPE_SUCCESS,
  SLEEP_QUALITY_OBSERVATION_TYPE_UPDATE_API_MSG,
  SLEEP_QUALITY_OBSERVATION_TYPE_UPDATE_API_RES,
  SleepQualityObservationTypeActionTypes,
} from "./ActionTypes";
import { SleepQualityObservationTypeRes } from "./Model";

export const sleepQualityObservationTypeLoadingAction = (
  loading: boolean,
): SleepQualityObservationTypeActionTypes => {
  return {
    type: SLEEP_QUALITY_OBSERVATION_TYPE_LOADING,
    loading: loading,
  };
};

export const sleepQualityObservationTypeSuccessAction = (
  sleepQualityObservationTypeRes: SleepQualityObservationTypeRes,
  successMsg: string,
): SleepQualityObservationTypeActionTypes => {
  return {
    type: SLEEP_QUALITY_OBSERVATION_TYPE_SUCCESS,
    payload: sleepQualityObservationTypeRes,
    successMsg: successMsg,
  };
};

export const sleepQualityObservationTypeErrorAction = (
  sleepQualityObservationTypeRes: SleepQualityObservationTypeRes,
  errMsg: string,
  status: number,
): SleepQualityObservationTypeActionTypes => {
  return {
    type: SLEEP_QUALITY_OBSERVATION_TYPE_FAIL,
    payload: sleepQualityObservationTypeRes,
    errorMsg: errMsg,
    status: status,
  };
};
export const sleepQualityObservationTypeUpdateAPIMsgAction = (
  sleepQualityObservationTypeRes: SleepQualityObservationTypeRes,
  successMsg: string,
  errMsg: string,
  status: number,
): SleepQualityObservationTypeActionTypes => {
  return {
    type: SLEEP_QUALITY_OBSERVATION_TYPE_UPDATE_API_MSG,
    payload: sleepQualityObservationTypeRes,
    successMsg: successMsg,
    errorMsg: errMsg,
    status: status,
  };
};

export const sleepQualityObservationTypeAPIResClearAction = (
  sleepQualityObservationTypeRes: SleepQualityObservationTypeRes,
  successMsg: string,
  errMsg: string,
  status: number,
): SleepQualityObservationTypeActionTypes => {
  return {
    type: SLEEP_QUALITY_OBSERVATION_TYPE_UPDATE_API_RES,
    payload: sleepQualityObservationTypeRes,
    successMsg: successMsg,
    errorMsg: errMsg,
    status: status,
  };
};
