import {
  ConnectEndPointActionTypes,
  CONNECT_END_POINT_FAIL,
  CONNECT_END_POINT_LOADING,
  CONNECT_END_POINT_SUCCESS,
  ConnectEndPointState,
  CONNECT_END_POINT_UPDATE_API_MSG,
  CONNECT_END_POINT_UPDATE_API_RES,
} from "./ActionTypes";
import { ConnectEndPointRes } from "./Model";

const initialStateGetPosts: ConnectEndPointState = {
  loading: false,
  connectOpenEpicRes: {} as ConnectEndPointRes,
  successMsg: "",
  errorMsg: "",
  status: 0,
};
export const connectOpenEpicReducer = (
  state = initialStateGetPosts,
  action: ConnectEndPointActionTypes,
): ConnectEndPointState => {
  switch (action.type) {
    case CONNECT_END_POINT_LOADING:
      return {
        ...state,
        loading: true,
        connectOpenEpicRes: {} as ConnectEndPointRes,
      };
    case CONNECT_END_POINT_SUCCESS:
      return {
        ...state,
        loading: false,
        connectOpenEpicRes: action.payload,
        successMsg: action.successMsg,
        status: action.status,
      };
    case CONNECT_END_POINT_FAIL:
      return {
        ...state,
        loading: false,
        connectOpenEpicRes: {} as ConnectEndPointRes,
        errorMsg: action.errorMsg,
        status: action.status,
      };
    case CONNECT_END_POINT_UPDATE_API_MSG:
      return {
        ...state,
        loading: false,
        connectOpenEpicRes: action.payload,
        errorMsg: action.errorMsg,
        status: action.status,
      };
    case CONNECT_END_POINT_UPDATE_API_RES:
      return {
        ...state,
        loading: false,
        connectOpenEpicRes: {} as ConnectEndPointRes,
        errorMsg: action.errorMsg,
        status: action.status,
      };
    default:
      return state;
  }
};
