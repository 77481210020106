import axios from "axios";
import { Dispatch } from "redux";
import Url from "../../../Url";
import { SleepScheduleObservationTypeActionTypes } from "./ActionTypes";
import { SleepScheduleObservationTypeBody, SleepScheduleObservationTypeRes } from "./Model";
import {
  sleepScheduleObservationTypeAPIResClearAction,
  sleepScheduleObservationTypeErrorAction,
  sleepScheduleObservationTypeLoadingAction,
  sleepScheduleObservationTypeSuccessAction,
  sleepScheduleObservationTypeUpdateAPIMsgAction,
} from "./Actions";
import { headers } from "../../../components/Utility";

let apiRes = {} as SleepScheduleObservationTypeRes;
export const sleepScheduleObservationType = (payload: SleepScheduleObservationTypeBody) => {
  return function (dispatch: Dispatch<SleepScheduleObservationTypeActionTypes>) {
    dispatch(sleepScheduleObservationTypeLoadingAction(true));
    axios
      .post(
        Url.apiURL + `/patientapp/api/patientdashboard/patientDashboardDataListByObservationType`,
        payload,
        { headers: headers },
      )
      .then((res) => {
        apiRes = res.data;
        dispatch(sleepScheduleObservationTypeLoadingAction(false));
        dispatch(
          sleepScheduleObservationTypeSuccessAction(
            res.data,
            res.data.message !== undefined
              ? res.data.message
              : "patient sleep score is showing successfully",
          ),
        );
      })
      .catch((error) => {
        dispatch(sleepScheduleObservationTypeLoadingAction(false));
        dispatch(
          sleepScheduleObservationTypeErrorAction(
            {} as SleepScheduleObservationTypeRes,
            error.response !== undefined
              ? error.response.data.message || error.response.data.detail
              : "Something went wrong",
            error.status !== undefined ? error.response.data.status || -1 : -1,
          ),
        );
      });
  };
};

export const updateSleepScheduleObservationTypeAPIResMsg = () => {
  return function (dispatch: Dispatch<SleepScheduleObservationTypeActionTypes>) {
    dispatch(
      sleepScheduleObservationTypeUpdateAPIMsgAction(
        apiRes as SleepScheduleObservationTypeRes,
        "",
        "",
        0,
      ),
    );
  };
};

export const clearSleepScheduleObservationTypeAPIRes = () => {
  return function (dispatch: Dispatch<SleepScheduleObservationTypeActionTypes>) {
    dispatch(
      sleepScheduleObservationTypeAPIResClearAction(
        {} as SleepScheduleObservationTypeRes,
        "",
        "",
        0,
      ),
    );
  };
};
