import { NeededReviewResponse } from "./Model";
export const REVIEW_NEEDED_LOADING = "REVIEW_NEEDED_LOADING";
export const REVIEW_NEEDED_SUCCESS = "REVIEW_NEEDED_SUCCESS";
export const REVIEW_NEEDED_FAIL = "REVIEW_NEEDED_FAIL";
export const REVIEW_NEEDED_DATA = "REVIEW_NEEDED_DATA";
export const REVIEW_NEEDED_UPDATE_API_MSG = "REVIEW_NEEDED_UPDATE_API_MSG";
export const REVIEW_NEEDED_UPDATE_API_RES = "REVIEW_NEEDED_UPDATE_API_RES";

export interface PaymentStatusState {
  reviewNeededRes: NeededReviewResponse;
  loading: boolean;
  successMsg: string;
  errorMsg: string;
  status: number;
}
export interface PaymentStatusLoading {
  type: typeof REVIEW_NEEDED_LOADING;
  loading: boolean;
}
export interface PaymentStatusSuccess {
  type: typeof REVIEW_NEEDED_SUCCESS;
  payload: NeededReviewResponse;
  successMsg: string;
  status: number;
}
export interface PaymentStatusFail {
  type: typeof REVIEW_NEEDED_FAIL;
  payload: NeededReviewResponse;
  errorMsg: string;
  status: number;
}
export interface PaymentStatusUpdateAPIMsg {
  type: typeof REVIEW_NEEDED_UPDATE_API_MSG;
  payload: NeededReviewResponse;
  errorMsg: string;
  status: number;
}
export interface PaymentStatusUpdateAPIRes {
  type: typeof REVIEW_NEEDED_UPDATE_API_RES;
  payload: NeededReviewResponse;
  errorMsg: string;
  status: number;
}
interface PaymentStatusAction {
  type: typeof REVIEW_NEEDED_DATA;
  payload: NeededReviewResponse;
}

export type ReviewNeededActionTypes =
  | PaymentStatusAction
  | PaymentStatusLoading
  | PaymentStatusSuccess
  | PaymentStatusFail
  | PaymentStatusUpdateAPIMsg
  | PaymentStatusUpdateAPIRes;
